const FormItems = (t) => {
  return [
    {
      key: "Header",
      name: t("SurveyIntl.header-text"),
      icon: "fas fa-heading",
      fieldProps: {
        content: t("SurveyIntl.header-content"),
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
      },
    },
    {
      key: "Paragraph",
      name: t("SurveyIntl.paragraph"),
      icon: "fas fa-paragraph",
      fieldProps: {
        content: t("SurveyIntl.paragraph-content"),
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
      },
    },
    {
      key: "LineBreak",
      name: t("SurveyIntl.line-break"),
      icon: "fas fa-arrows-alt-h",
      fieldProps: {
        content: "***",
      },
    },
    {
      key: "Image",
      name: t("SurveyIntl.image"),
      icon: "far fa-image",
      fieldProps: {
        field_name: "image_",
        src: "",
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
      },
    },
    {
      key: "TextInput",
      name: t("SurveyIntl.text-input"),
      icon: "fas fa-font",
      info: t("Survey.CreatingATextField"),
      fieldProps: {
        field_name: "text_input_",
        label: t("Survey.TextField"),
        placeholder: "",

        rules: [
          {
            required: false,
            message: t("SurveyIntl.validation-msg-Req"),
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
      },
    },
    {
      key: "NumberInput",
      name: t("SurveyIntl.number-input"),
      icon: "fas fa-plus",
      info: t("Survey.NumberField"),

      fieldProps: {
        field_name: "number_input_",
        label: t("Survey.NumericField"),
        placeholder: "123...",
        rules: [
          {
            required: false,
            message: t("SurveyIntl.validation-msg-Req"),
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
      },
    },
    {
      key: "TextArea",
      name: t("SurveyIntl.TextArea"),
      icon: "fas fa-text-height",
      info: t("Survey.CreateATextBox"),
      fieldProps: {
        field_name: "text_area_",
        label: t("Survey.ZoneText"),
        placeholder: "",
        rules: [
          {
            required: false,
            message: t("SurveyIntl.validation-msg-Req"),
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
      },
    },
    {
      key: "Dropdown",
      name: t("SurveyIntl.dropdown"),
      icon: "far fa-caret-square-down",
      info: t("Survey.DropDownList"),

      fieldProps: {
        label: t("Survey.DropdownList"),
        Multiple: false,
        options: [
          { label: "Option 1", value: "Option 1" },
          { label: "Option 2", value: "Option 2" },
        ],
        rules: [
          {
            required: false,
            message: t("SurveyIntl.validation-msg-Req"),
          },
        ],

        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
        field_name: "dropdown_",
      },
    },
    {
      key: "Checkboxes",
      name: t("SurveyIntl.checkboxes"),
      icon: "far fa-check-square",
      info: t("Survey.Checkbox"),

      fieldProps: {
        field_name: "checkboxes_",
        label: t("Survey.MultipleChoice"),
        options: [
          { label: "Option 1", value: "Option 1" },
          { label: "Option 2", value: "Option 2" },
        ],
        rules: [
          {
            required: false,
            message: t("SurveyIntl.validation-msg-Req"),
          },
        ],

        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
      },
    },
    {
      key: "RadioButtons",
      name: t("SurveyIntl.RadioButtons"),
      icon: "far fa-dot-circle",
      info: t("Survey.RadioButton"),

      fieldProps: {
        field_name: "radiobuttons_",
        label: t("Survey.SimpleChoice"),
        options: [
          { label: "Option 1", value: "Option 1" },
          { label: "Option 2", value: "Option 2" },
        ],
        rules: [
          {
            required: false,
            message: t("SurveyIntl.validation-msg-Req"),
          },
        ],

        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
      },
    },
    {
      key: "TableField",
      name: t("SurveyIntl.TableField"),
      icon: "fas fa-table",
      info: t("Survey.questionsGroup"),

      fieldProps: {
        field_name: "tablefield_",
        label: t("Survey.Gate"),
        lignes: [{ label: "Question 1" }],
        options: [
          { label: "Colonne 1", value: "Colonne 1" },
          { label: "Colonne 2", value: "Colonne 2" },
          { label: "Colonne 3", value: "Colonne 3" },
        ],

        rules: [
          {
            required: false,
            message: t("SurveyIntl.validation-msg-Req"),
          },
        ],

        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
      },
    },
    {
      key: "Photo",
      name: t("SurveyIntl.photo"),
      icon: "fa fa-camera",
      fieldProps: {
        field_name: "photo_",
        label: t("SurveyIntl.photo"),
        Multiple: false,
        accept: "image/png, image/jpeg",
        rules: [
          {
            required: false,
            message: t("SurveyIntl.validation-msg-Req"),
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
      },
    },
    {
      key: "DatePicker",
      name: t("SurveyIntl.date"),
      icon: "far fa-calendar-alt",
      info: t("Survey.CreatingDateField"),
      fieldProps: {
        field_name: "date_picker_",
        label: t("Survey.Date"),
        format: "DD/MM/YYYY",
        rules: [
          {
            required: false,
            message: t("SurveyIntl.validation-msg-Req"),
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },

        // allowClear: true,
      },
    },
    {
      key: "TimePicker",
      name: t("SurveyIntl.time"),
      icon: "far fa-clock",
      fieldProps: {
        field_name: "time_picker_",
        label: t("SurveyIntl.time"),
        format: "hh:mm a",
        rules: [
          {
            required: false,
            message: t("SurveyIntl.validation-msg-Req"),
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
      },
    },
    {
      key: "Rating",
      name: t("SurveyIntl.rating"),
      icon: "fas fa-star",
      fieldProps: {
        field_name: "rating_",
        label: t("Survey.Note"),
        // allowClear: true,
        // allowHalf: false,
        count: 5,
        rules: [
          {
            required: false,
            message: t("SurveyIntl.validation-msg-Req"),
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
      },
    },
    {
      key: "Signature",
      name: t("SurveyIntl.signature"),
      icon: "fas fa-pen-square",
      fieldProps: {
        field_name: "signature_",
        label: t("SurveyIntl.signature"),
        rules: [
          {
            required: false,
            message: t("SurveyIntl.validation-msg-Req"),
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
      },
    },
    {
      key: "Range",
      name: t("SurveyIntl.range"),
      icon: "fas fa-sliders-h",
      info: t("Survey.CreationOfScaleNumeric"),
      fieldProps: {
        field_name: "range_",
        label: t("Survey.CursorField"),
        step: 1,
        min: 1,
        max: 5,
        minLabel: t("SurveyIntl.Easy"),
        maxLabel: t("SurveyIntl.Difficult"),
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
      },
    },
  ];
};
const CustomItems = (t) => {
  return [
    {
      key: "ident",
      custom: true,
      name: t("Survey.Identify"),
      icon: "fas fa-user-tie",
      fieldProps: {
        field_name: "text_input_",
        label: t("Survey.Identify"),

        rules: [
          {
            required: false,
            message: t("SurveyIntl.validation-msg-Req"),
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [{ filedTocompare: "", ifCondition: "", value: "" }],
        },

        fullName: true,
        email: true,
        numTel: true,
        numSociale: true,
      },
    },

    {
      key: "fullAdress",
      custom: true,
      name: t("SurveyIntl.Address"),
      icon: "fas fa-map-marked",
      fieldProps: {
        field_name: "text_input_",
        label: t("Survey.FullAddress"),

        rules: [
          {
            required: false,
            message: t("SurveyIntl.validation-msg-Req"),
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [{ filedTocompare: "", ifCondition: "", value: "" }],
        },
        pays: true,
        ville: true,
        zipCode: true,
        adresse: true,
        departement: false,
      },
    },
    {
      key: "Email",
      name: "Email",
      custom: true,
      icon: "fas fa-at",
      fieldProps: {
        field_name: "email_",
        label: t("Survey.Email"),
        placeholder: "jhon@doe.com",

        rules: [
          {
            required: false,
            message: t("SurveyIntl.validation-msg-Req"),
          },
          {
            type: "email",
            message: "email non valide",
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [{ filedTocompare: "", ifCondition: "", value: "" }],
        },
      },
    },

    {
      key: "captcha",
      custom: true,
      name: "Captcha",
      icon: "fas fa-shield-alt",
      fieldProps: {
        field_name: "captcha_input_",

        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [{ filedTocompare: "", ifCondition: "", value: "" }],
        },
      },
    },
  ];
};

export { CustomItems, FormItems };
