import { useEffect, useState } from "react";
import io from "socket.io-client";
import { useAtom } from "jotai";
import { useLocation } from "react-router-dom";
import { updatePatient } from "../api/patient";
import { socketAtom } from "../state/socket";
import timeZone from "../helper/timeZone";
import SideBar from "../components/SideBar/SideBarPatient";
import "./styles-layout-patient.scss";

const KatomiEndPoint =
  process.env.REACT_APP_KATOMI_API_URL || "http://localhost:8080";

const LayoutPatient = ({ children }) => {
  const [showSideBar, setShowSideBar] = useState(true);
  const [socket, setSocket] = useAtom(socketAtom);

  const setupSocket = () => {
    const newSocket = io(KatomiEndPoint, {
      withCredentials: true,
    });
    newSocket.on("disconnect", () => {
      setSocket(null);
    });
    newSocket.on("connection", () => {});
    newSocket.on("me", (id) => {
      localStorage.setItem("socketId", id);
    });
    setSocket(newSocket);
  };

  const _savePatientTimeZone = async () => {
    try {
      const payload = {
        timeZone,
      };
      await updatePatient(payload);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    _savePatientTimeZone();
    if (!socket) {
      setupSocket();
    }
    //eslint-disable-next-line
  }, []);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.slice(0, 17) === "/teleconsultation")
      setShowSideBar(false);
    else setShowSideBar(true);
  }, [location.pathname]);
  return (
    <div className="layout-patient flex">
      {showSideBar && <SideBar />}
      <div className="w-full p-2 md:pl-4">{children}</div>
    </div>
  );
};

export default LayoutPatient;
