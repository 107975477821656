/* eslint-disable eqeqeq */
import _ from "lodash";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import cake from "../../../assets/images/cake.png";
import CountryWithPhoneSuffix from "../../../config/constants/pays.json";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { useTranslation } from "react-i18next";
registerLocale("fr", fr);
setDefaultLocale("fr");
const Step3 = ({
  errors,
  values,
  handleChange,
  setFieldValue,
  handleBlur,
  valueConfirmed,
  startDate,
  setStartDate,
  requireInput,
  selectedRegionState,
  setSelectedRegionState,
  regionState,
  selectedDepartementState,
  setSelectedDepartementState,
  departementState,
  selectedCommunesState,
  setSelectedCommunesState,
  communesState,
  villeTnState,
  countriesTn,
  setVilleTnState,
  delegationTnState,
  delegationTn,
  setDelegationTnState,
}) => {
  const { t } = useTranslation() as any;
  return (
    <>
      <Input
        id="firstName"
        placeholder={t("AddPatient.FirstName")}
        type="text"
        error={errors.firstName}
        value={values.firstName}
        onChange={(e) => {
          handleChange(e);
          if (_.isEmpty(values.responseINS))
            setFieldValue("firstNameINS", e.target.value.split(" ").join(","));
        }}
        onBlur={(e) => {
          handleBlur(e);
          // handleVerifyINS(values, setFieldValue);
        }}
        className="custom-Input mb-2.5"
        inlineLabel={false}
        onfocus={true}
        icon="user"
        iconColor="white"
        isIcon={true}
        image="require"
        right={true}
        stylesDiv="relative"
        imageCLS="w-4 max-h-1"
        disabled={valueConfirmed}
      />
      <Input
        id="lastName"
        placeholder={t("AddPatient.LastName")}
        type="text"
        error={errors.lastName}
        value={values.lastName}
        onChange={(e) => {
          handleChange(e);
          if (_.isEmpty(values.responseINS))
            setFieldValue("NameINS", e.target.value);
        }}
        onBlur={(e) => {
          handleBlur(e);
          // handleVerifyINS(values, setFieldValue);
        }}
        className="custom-Input mb-2.5"
        inlineLabel={false}
        onfocus={true}
        icon="user"
        iconColor="white"
        isIcon={true}
        image="require"
        right={true}
        stylesDiv="relative"
        imageCLS="w-4 max-h-1"
        disabled={valueConfirmed}
      />
      <div className="relative">
        <DatePicker
          id="birthDate"
          locale="fr"
          className="custom-Input mb-8"
          selected={startDate}
          dateFormat="dd/MM/yyyy"
          onChange={(date) => {
            setStartDate(date);
          }}
          // onBlur={() => handleVerifyINS(values, setFieldValue)}
          placeholderText={t("AddPatient.BirthDate")}
          autoComplete="off"
          showYearDropdown
          disabled={valueConfirmed}
        />
        <img
          crossOrigin="anonymous"
          referrerPolicy="origin"
          src={cake}
          alt="cake"
          className="absolute top-3 left-3"
          width="18"
        />
        <img
          crossOrigin="anonymous"
          referrerPolicy="origin"
          src={requireInput}
          alt="require"
          className="absolute right-3 top-3 w-1.5 h-1.5"
        />
      </div>

      <div className="flex flex-row mb-6">
        <div className="sexe">
          <div className="flex space-x-2">
            <p className="mb-2">{t("AddPatient.Sexe")}</p>
            <img
              crossOrigin="anonymous"
              referrerPolicy="origin"
              src={requireInput}
              alt="require"
              className=" w-1.5 h-1.5 self-start"
            />
          </div>
          <Input
            name="sexe"
            type="checkbox"
            onChange={() => {
              setFieldValue("sexe", ["woman"]);
              setFieldValue("INSSexe", "F");
            }}
            value="woman"
            labelText={t("AddPatient.F")}
            checked={values.sexe.includes("woman")}
            labelProps={{ className: "check" }}
            stylesDiv={"inputTest"}
            disabled={valueConfirmed}
          />
          <Input
            name="sexe"
            type="checkbox"
            onChange={() => {
              setFieldValue("sexe", ["man"]);
              setFieldValue("INSSexe", "M");
            }}
            value="man"
            labelText={t("AddPatient.M")}
            labelProps={{ className: "check" }}
            checked={values.sexe.includes("man")}
            stylesDiv={"inputTest"}
            disabled={valueConfirmed}
          />
        </div>
        {values.sexe.includes("woman") && (
          <div className="flex flex-col mx-2">
            <p className="mb-2">{t("AddPatient.Pregnancy")}</p>
            <Input
              id="semaineNBR"
              placeholder={t("AddPatient.WeekNumber")}
              type="number"
              value={values.semaineNBR}
              onChange={handleChange}
              onBlur={handleBlur}
              className="mb-2.5 max-w-10"
            />
          </div>
        )}
        <div className="flex flex-col">
          <div>
            <p className="mb-2"> {t("AddPatient.Handicap")}</p>
          </div>
          <div className="flex particularite-container">
            <div className="particularite">
              <Input
                name="handicap"
                type="checkbox"
                onChange={handleChange}
                value="Moteur"
                labelText={t("AddPatient.Physical")}
                labelProps={{ className: "check" }}
                stylesDiv={"inputTest"}
              />
              <Input
                name="handicap"
                type="checkbox"
                onChange={handleChange}
                value="Mental"
                labelText={t("AddPatient.Mental")}
                labelProps={{ className: "check" }}
                stylesDiv={"inputTest"}
              />
              <Input
                name="handicap"
                type="checkbox"
                onChange={handleChange}
                value="Aidant"
                labelText={t("AddPatient.Helping")}
                labelProps={{ className: "check" }}
                stylesDiv={"inputTest"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pl-2">
        <p className="my-2">{t("AddPatient.adressOfBirth")}</p>
      </div>
      <Select
        id="country"
        value={values.country}
        // className="mr-5"
        divProps="w-full"
        className="mb-2.5 w-full rounded-lg pl-3"
        options={CountryWithPhoneSuffix.map((el) => ({
          value: el.name,
          label: el.name,
        }))}
        onChange={handleChange}
        onBlur={handleBlur}
        text={t("AddPatient.pays")}
        required
      />
      {values.country === "France" && (
        <>
          <Select
            text={t("AddPatient.regions")}
            className="mb-2.5 w-full rounded-lg pl-3"
            // labelProps={{ className: "w-40" }}
            value={selectedRegionState ? selectedRegionState.value : ""}
            divProps="w-full"
            options={regionState}
            onChange={(e) => {
              setSelectedRegionState(
                regionState.find((elm) => elm.value == e.target.value)
              );
            }}
          />
          {selectedRegionState?.value && (
            <Select
              text={t("AddPatient.departments")}
              className="mb-2.5 w-full rounded-lg pl-3"
              divProps="w-full"
              value={
                selectedDepartementState ? selectedDepartementState.value : ""
              }
              options={departementState}
              onChange={(e) => {
                setSelectedDepartementState(
                  departementState.find((elm) => elm.value == e.target.value)
                );
              }}
            />
          )}
          {selectedDepartementState?.value && (
            <Select
              text={t("AddPatient.commune")}
              className="mb-2.5 w-full rounded-lg pl-3"
              divProps="w-full"
              value={selectedCommunesState ? selectedCommunesState.value : ""}
              options={communesState}
              onChange={(e) => {
                setSelectedCommunesState(
                  communesState.find((elm) => elm.value == e.target.value)
                );
                setFieldValue(
                  "birthAddressINS",
                  communesState.find((elm) => elm.value == e.target.value)?.code
                );
              }}
            />
          )}
        </>
      )}
      {values.country === "Tunisia" && (
        <>
          <Select
            text={t("AddPatient.Gouvernorat")}
            className="mb-2.5 w-full rounded-lg pl-3"
            divProps="w-full"
            value={villeTnState}
            options={Object.keys(countriesTn).map((elm) => ({
              label: elm,
              value: elm,
            }))}
            onChange={(e) => {
              setVilleTnState(e.target.value);
            }}
          />

          {villeTnState && (
            <Select
              text={t("AddPatient.Gouvernorat")}
              className="mb-2.5 w-full rounded-lg pl-3"
              divProps="w-full"
              value={delegationTnState}
              options={delegationTn}
              onChange={(e) => {
                setDelegationTnState(e.target.value);
              }}
            />
          )}
        </>
      )}
      <div className="w-full pl-2">
        <p className="my-2">{t("AddPatient.actualData")}</p>
      </div>
      <Input
        id="phone"
        placeholder={t("AddPatient.PhoneNumber")}
        type="text"
        error={errors.phone}
        value={values.phone}
        onChange={handleChange}
        onBlur={(e) => {
          handleBlur(e);
        }}
        className="mb-2.5"
        image2="phone-white"
        image="require"
        right={true}
        stylesDiv="relative"
        imageCLS="w-4 max-h-1"
      />
      <Input
        id="adresse"
        placeholder={t("AddPatient.Address")}
        type="text"
        error={errors.adresse}
        value={values.adresse}
        onChange={handleChange}
        onBlur={(e) => {
          handleBlur(e);
        }}
        className="mb-2.5"
        icon="home"
        iconColor="white"
        isIcon={true}
        image="require"
        right={true}
        stylesDiv="relative"
        imageCLS="w-4 max-h-1"
      />
      <Input
        id="postalCode"
        placeholder={t("AddPatient.postalCode")}
        type="number"
        error={errors.postalCode}
        value={values.postalCode}
        onChange={handleChange}
        onBlur={(e) => {
          handleBlur(e);
        }}
        className="mb-2.5"
        icon="home"
        iconColor="white"
        isIcon={true}
        image="require"
        right={true}
        stylesDiv="relative"
        imageCLS="w-4 max-h-1"
      />
      <Input
        id="email"
        placeholder={t("AddPatient.email")}
        type="text"
        error={errors.email}
        value={values.email}
        onChange={handleChange}
        onBlur={(e) => {
          handleBlur(e);
        }}
        className="mb-2.5"
        image2="mail-white"
        image="require"
        right={true}
        stylesDiv="relative"
        imageCLS="w-4 max-h-1"
      />

      <Input
        id="numSecSocial"
        placeholder={t("AddPatient.SocialSecurityNumber")}
        type="text"
        error={errors.numSecSocial}
        value={values.numSecSocial}
        onChange={handleChange}
        onBlur={(e) => {
          handleBlur(e);
        }}
        className="mb-2.5"
        image2="securite-sociale"
        image="require"
        right={true}
        stylesDiv="relative"
        imageCLS="w-4 max-h-1"
      />
      <div className="w-full pl-2">
        <p className="my-2">{t("AddPatient.TreatingDoctor")}</p>
      </div>
      <Input
        id="medecin"
        placeholder={t("AddPatient.TreatingDoctor")}
        type="text"
        error={errors.medecin}
        value={values.medecin}
        onChange={handleChange}
        onBlur={handleBlur}
        className="mb-2.5"
        image="medecin"
      />
      <Input
        id="telMedecin"
        placeholder={t("AddPatient.PhoneTreatingDoctor")}
        type="text"
        error={errors.telMedecin}
        value={values.telMedecin}
        onChange={handleChange}
        onBlur={handleBlur}
        className="mb-2.5"
        image="phone-white"
      />
      <Input
        id="addressMedecin"
        placeholder={t("AddPatient.AddressTreatingDoctor")}
        type="text"
        error={errors.addressMedecin}
        value={values.addressMedecin}
        onChange={handleChange}
        onBlur={handleBlur}
        className="mb-2.5"
        icon="home"
        iconColor="white"
        isIcon={true}
      />
    </>
  );
};

export default Step3;
