import axios from "axios";
import {
  ADD_TREATEMENS_TO_PATIENT_BY_DOCTOR,
  ADD_OBSERVATION_TO_PATIENT_BY_DOCTOR,
  END_TREATMENT,
} from "../config/api";

export const addTreatementsToPatientByDoctor = async ({ ...values }) => {
  try {
    const res = await axios.post(ADD_TREATEMENS_TO_PATIENT_BY_DOCTOR, values, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addObservationToTreatements = async ({ ...values }) => {
  try {
    const res = await axios.post(ADD_OBSERVATION_TO_PATIENT_BY_DOCTOR, values, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const endTreatements = async (id) => {
  try {
    const res = await axios.put(
      `${END_TREATMENT}/${id}`,
      {},
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
