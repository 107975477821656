/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
//API
import {
  getUpcomingConsultation,
  getMyTreatingDoctor,
  patientGetAllDocument,
} from "../../api/patient";
import { getOpenTimeSlots } from "../../api/doctor";
import { loadImage } from "../../api/documents";
import moment from "../../helper/moment";
//Components
import Button from "../../components/Button";
import Modal from "../../components/ModalUI";
import Icon from "../../components/Icon";
import DoctorTimeSlot from "../../components/DoctorTimeSlots";
import ChangeConsultationDate from "../../components/ChangeConsultationDate";
import DoctorCard from "../../components/DoctorDetails";
import ConfirmModal from "../../components/DoctorDetails/ConfirmMeet";
import TakeMeet from "../../components/DoctorDetails/TakeMeet";
import ConfirmTeleConsultation from "../../components/DoctorDetails/ConfirmTeleConsultation";
import ChnageDoctorModal from "./ChangeDoctorModal";
//Assets
import Camera from "../../assets/images/camera.png";
import Handshake from "../../assets/images/poigne-de-main.png";
import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";
import "./styles.scss";
import { handleSpeciality } from "../../helper/handleSpeciality";

const colorAvatar = [
  "#ccd2ff",
  "#d5f0f7",
  "#ffecba",
  "#fff7ab",
  "#ffccd2",
  "#ffe2c8",
];
const DashboardPatient: React.FC = () => {
  const { t, i18n } = useTranslation() as any;
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });
  const [updateComponent, setUpdateComponent] = useState(false);
  const [firstTs, setFirstTs] = useState();
  const [dateNow, setDateNow] = useState(moment());
  const [upcomingConsultation, setUpcomingConsultations] = useState<any>([]);
  const [reports, setReports] = useState<any>([]);
  const [prescriptions, setPrescriptions] = useState<any>([]);
  const [myDoctorTreating, setMyDoctorTreating] = useState<any>();
  const [, setloadingTimeSlot] = useState(false);
  const [openTimeSlots, setOpenTimeSlots] = useState([]) as any;
  const [changeMyDoctorModal, setChangeMydoctorModal] = useState(false);
  const [updateConsultationDetails, setUpdateConsultationDetails] =
    useState() as any;
  const [changeConsultationDate, setChangeConsultationDate] = useState(
    false
  ) as any;
  const [meetData, setMeetData] = useState();
  const [addedConsultation, setAddedConsultation] = useState();
  // const [selectSpecialist, setSelectSpecialist] = useState();
  const [picketDateReservation, setPicketDateReservation] = useState({});
  // Modal State
  const [DoctorCardModal, setDoctorCardModal] = useState(false);
  const [takeMeetModal, setTakeMeetModal] = useState(false);
  const [confirmTeleconsultationModal, setConfirmTeleconsultationModal] =
    useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const history = useHistory();

  const _getUpcomingConsultation = async () => {
    try {
      const result = await getUpcomingConsultation();

      if (result.consultations && !_.isEmpty(result.consultations)) {
        setUpcomingConsultations(result.consultations);
      }
    } catch (error) {}
  };

  const _getMyTreatingDoctor = async () => {
    try {
      const result = await getMyTreatingDoctor();
      if (result.doctor) {
        setMyDoctorTreating(result.doctor);
      }
    } catch (error) {}
  };

  const _getAllDocument = async () => {
    try {
      const result = await patientGetAllDocument();

      if (result.reports) {
        setReports(result.reports);
      }
      if (result.prescriptions) {
        setPrescriptions(result.prescriptions);
      }
    } catch (error) {}
  };

  const _getOpenTimeSlots = async () => {
    try {
      setloadingTimeSlot(true);
      const response = await getOpenTimeSlots(myDoctorTreating.agendaId);

      if (response?.data) {
        const groups = response.data.reduce((groups, el) => {
          const date = moment(el.startTime).format("YYYY-MM-DD");
          if (!groups[date]) {
            groups[date] = [];
          }

          groups[date].push(el);
          return groups;
        }, {});

        // // Edit: to add it in the array format instead
        const groupArrays = Object.keys(groups).map((date) => ({
          date,
          data: groups[date],
        }));
        setOpenTimeSlots(
          groupArrays.sort(
            (a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf()
          )
        );
      }
      setloadingTimeSlot(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleStartTeleConsultation = (rdv, meetingIsNow) => {
    if (meetingIsNow() && rdv.type === "teleconsultation")
      history.push(`teleconsultation/${rdv._id}`);
    else {
      setChangeConsultationDate(true);
      setUpdateConsultationDetails(rdv);
    }
  };

  useEffect(() => {
    _getUpcomingConsultation();
    _getMyTreatingDoctor();
    _getAllDocument();
  }, []);

  useEffect(() => {
    if (updateComponent) {
      _getUpcomingConsultation();
      _getMyTreatingDoctor();
    }
    return () => setUpdateComponent(false);
  }, [updateComponent]);

  const firstOpenTimeSlot = () => {
    for (const openTimeSlot of openTimeSlots)
      for (const slotOfTheDay of openTimeSlot.data)
        if (
          slotOfTheDay.eventStatus === "open" &&
          moment(slotOfTheDay.startTime).isAfter(moment())
        )
          return slotOfTheDay.startTime;
    return null;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setDateNow(moment());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (myDoctorTreating) _getOpenTimeSlots();
  }, [myDoctorTreating]);

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);

  return (
    <>
      <Modal
        closeModal={() => setChangeMydoctorModal(false)}
        showModal={changeMyDoctorModal}
      >
        <ChnageDoctorModal
          closeModal={() => {
            setChangeMydoctorModal(false);
            setUpdateComponent(true);
          }}
        />
      </Modal>
      <Modal
        showModal={confirmModal}
        closeModal={() => {
          setConfirmModal(false);
        }}
      >
        <ConfirmModal
          closeModal={() => {
            setConfirmModal(false);
            setUpdateComponent(true);
          }}
          addedConsultation={addedConsultation}
        />
      </Modal>
      <Modal
        showModal={confirmTeleconsultationModal}
        // showModal={true}
        slidemodal
        closeModal={() => {
          setConfirmTeleconsultationModal(false);
        }}
      >
        <ConfirmTeleConsultation
          closeModal={() => {
            setConfirmTeleconsultationModal(false);
          }}
          meetData={meetData}
          selectSpecialist={myDoctorTreating}
          openConfirmMeetModal={(addedConsultation) => {
            setAddedConsultation(addedConsultation);
            _getOpenTimeSlots();
            setConfirmModal(true);
          }}
        />
      </Modal>
      <Modal
        showModal={takeMeetModal}
        slidemodal
        closeModal={() => setTakeMeetModal(false)}
      >
        <TakeMeet
          maxRow={windowDimenion.winWidth > 768 ? 7 : 3}
          closeModal={() => setTakeMeetModal(false)}
          picketDateReservation={picketDateReservation}
          selectSpecialist={myDoctorTreating}
          takeMeetModal={takeMeetModal}
          openTimeSlots={openTimeSlots}
          openConfirmMeetModal={(addedConsultation) => {
            setAddedConsultation(addedConsultation);
            _getOpenTimeSlots();
            setConfirmModal(true);
          }}
          openConfirmTeleconsultationModal={(takeMeetData) => {
            setMeetData(takeMeetData);
            setTakeMeetModal(false);
            setConfirmTeleconsultationModal(true);
          }}
        />
      </Modal>
      <Modal
        showModal={DoctorCardModal}
        slidemodal
        closeModal={() => setDoctorCardModal(false)}
      >
        <DoctorCard
          closeModal={() => setDoctorCardModal(false)}
          selectSpecialist={myDoctorTreating}
          openTakeMeetModal={() => {
            setDoctorCardModal(false);
            setTakeMeetModal(true);
          }}
          firstOpenMeet={firstOpenTimeSlot()}
        />
      </Modal>
      <Modal
        showModal={changeConsultationDate}
        closeModal={() => setChangeConsultationDate(false)}
      >
        <ChangeConsultationDate
          consultationDetails={updateConsultationDetails}
          closeModal={() => {
            setChangeConsultationDate(false);
            setUpdateComponent(true);
          }}
        />
      </Modal>

      <div className="dashboard-Patient ">
        <div className="dashboard-title">
          <h1>{t("DashboardPatient.title")}</h1>
          <span>
            {" "}
            {t("DashboardPatient.TodayIs")} {dateNow.format("LLLL")}
          </span>
        </div>
        <div className="gridContainer flex flex-col lg:flex-row lg:justify-between">
          <div className="lg:w-7/12 w-full">
            <h3>{t("DashboardPatient.UpcomingConsultations")}</h3>
            {upcomingConsultation && !_.isEmpty(upcomingConsultation) ? (
              <div className="rdv-container flex flex-wrap md:justify-start justify-center">
                {upcomingConsultation
                  .sort(
                    (a, b) =>
                      new Date(a.dateConsultation).valueOf() -
                      new Date(b.dateConsultation).valueOf()
                  )
                  .slice(0, 2)
                  .map((rdv, i) => {
                    const meetingIsNow = () => {
                      if (
                        i === 0
                        //  &&
                        // moment(rdv.dateConsultation).isAfter(
                        //   moment().subtract(15, "minutes")
                        // ) &&
                        // moment(rdv.dateConsultation).isBefore(
                        //   moment().add(15, "minutes")
                        // )
                      )
                        return true;
                      return false;
                    };
                    return (
                      <div
                        className={`rdv-bloc flex flex-col justify-between ${
                          meetingIsNow() ? "hasBackgounrd" : "bg-white"
                        }`}
                      >
                        <div>
                          <div className="header flex justify-between">
                            <div className="flexCenter">
                              <h2
                                className={`${
                                  meetingIsNow() ? "text-white" : "text-black"
                                }`}
                              >
                                {`${rdv.doctorId?.firstName}  ${rdv.doctorId?.lastName}`}
                              </h2>
                              {rdv.connected && (
                                <span className="rounded-full"></span>
                              )}
                            </div>
                            {rdv.type === "teleconsultation" ? (
                              <img
                                crossOrigin="anonymous"
                                referrerPolicy="origin"
                                src={Camera}
                                alt="teleconsultation"
                                width={45}
                                style={{
                                  filter: meetingIsNow()
                                    ? " invert(100%) sepia(0%) saturate(7484%) hue-rotate(53deg) brightness(94%) contrast(110%)"
                                    : "invert(32%) sepia(89%) saturate(2276%) hue-rotate(221deg) brightness(98%) contrast(97%)",
                                }}
                              />
                            ) : (
                              <img
                                crossOrigin="anonymous"
                                referrerPolicy="origin"
                                src={Handshake}
                                alt="presentiel"
                                width={60}
                                style={{
                                  filter: meetingIsNow()
                                    ? " invert(100%) sepia(0%) saturate(7484%) hue-rotate(53deg) brightness(94%) contrast(110%)"
                                    : "invert(32%) sepia(89%) saturate(2276%) hue-rotate(221deg) brightness(98%) contrast(97%)",
                                }}
                              />
                            )}
                          </div>
                          <div className="box-motif ">
                            <p
                              style={{
                                color: meetingIsNow() ? "#F8F8FF" : "#A6A9C8",
                              }}
                            >
                              {rdv.motif}
                            </p>
                          </div>
                        </div>
                        <div>
                          <div
                            className={`date flexCenter ${
                              meetingIsNow() ? "text-white" : "text-black"
                            }`}
                          >
                            {/* {moment(rdv.dateConsultation).calendar()} */}
                            {moment(rdv.dateConsultation).format(
                              "DD MMMM YYYY | HH:mm"
                            )}
                          </div>
                          <Button
                            status="basic"
                            className={`rounded-lg bg-white  bg-opacity-20 hover:border-blue-500 ${
                              meetingIsNow()
                                ? "text-white"
                                : "text-blue-500 border-blue-500"
                            }`}
                            onClick={() =>
                              handleStartTeleConsultation(rdv, meetingIsNow)
                            }
                          >
                            {meetingIsNow()
                              ? rdv.type === "teleconsultation"
                                ? t("DashboardPatient.Start")
                                : t("DashboardPatient.Update")
                              : t("DashboardPatient.Update")}
                          </Button>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div className="rdv-container flexCenter">
                <span className="card-subtitle">
                  {t("DashboardPatient.Noconsultation")}
                </span>
              </div>
            )}
            <div className="flex justify-between medecinTraitant items-center mr-5 xl:mr-0">
              <h3> {t("DashboardPatient.MyDoctor")}</h3>

              <p
                className="link cursor-pointer"
                onClick={() => {
                  setChangeMydoctorModal(true);
                }}
              >
                {t("DashboardPatient.Update")}
              </p>
            </div>
            {myDoctorTreating && (
              <div className="my-doctor px-5 pb-3 ">
                <div className="flex justify-between header min-w-full ">
                  <div
                    className="flex cursor-pointer"
                    onClick={() => {
                      setPicketDateReservation({});
                      setDoctorCardModal(true);
                      // setOpenTimeSlots(openTimeSlots);
                      // setFirstOpenMeet(firstOpenMeet);
                    }}
                  >
                    <div
                      className="rounded-full flexCenter my-auto doctor-image"
                      style={{
                        backgroundColor: myDoctorTreating?.business_photo
                          ? ""
                          : colorAvatar[
                              Math.floor(Math.random() * colorAvatar.length)
                            ],
                      }}
                    >
                      {myDoctorTreating?.business_photo ? (
                        <img
                          crossOrigin="anonymous"
                          referrerPolicy="origin"
                          alt="profile"
                          src={
                            myDoctorTreating?.business_photo.slice(0, 4) ===
                            "http"
                              ? myDoctorTreating?.business_photo
                              : loadImage(
                                  myDoctorTreating?._id,
                                  "profile",
                                  "thumbnail",
                                  myDoctorTreating?.business_photo
                                )
                          }
                          className="w-16 h-16 rounded-full shadow-md object-fill"
                        />
                      ) : myDoctorTreating?.sexe === "woman" ? (
                        <WomanPatient />
                      ) : (
                        <ManPatient />
                      )}
                    </div>
                    <div className="flex flex-col justify-center name">
                      <p className="font-bold uppercase">
                        {`${myDoctorTreating?.firstName}  ${myDoctorTreating?.lastName}`}
                      </p>
                      <span>
                        {handleSpeciality(
                          myDoctorTreating?.speciality,
                          i18n.language.includes("en")
                        )}
                      </span>
                    </div>
                  </div>
                  {firstTs && windowDimenion.winWidth > 640 && (
                    <div className="next-rdv flexCenter leading-none ">
                      <p className="">
                        {t("DashboardPatient.NextAvailableConsultation")}
                        <br />
                        <strong>
                          {moment(firstTs).format("dddd DD MMMM - HH:mm")}
                        </strong>
                      </p>
                    </div>
                  )}
                </div>
                <hr />
                <DoctorTimeSlot
                  handlePickDate={(picked) => {
                    setPicketDateReservation(picked);
                    setTakeMeetModal(true);
                  }}
                  openTimeSlots={openTimeSlots}
                  doctorDetails={myDoctorTreating}
                  maxCol={
                    windowDimenion.winWidth > 1200
                      ? 5
                      : windowDimenion.winWidth > 640
                      ? 4
                      : 3
                  }
                  firstOpenTimeSlot={(ts) => setFirstTs(ts)}
                />
              </div>
            )}
          </div>
          {/* <div className="w-1/4"></div> */}
          <div className="lg:w-1/3 w-full mt-10 lg:mt-0 max-h-screen overflow-y-scroll ">
            <h3>{t("DashboardPatient.MyRecentDocuments")}</h3>

            {(reports && !_.isEmpty(reports)) ||
            (reports && !_.isEmpty(prescriptions)) ? (
              <div className="recent-docs">
                {reports.map((d) => {
                  return (
                    <Link to={`/previewCompteRendu/${d._id}`}>
                      <div className="recent-bloc cursor-pointer hover:border-blue-500 border">
                        <div className="flex justify-between">
                          <div className="">
                            <p className="card-title">
                              {" "}
                              {t("DashboardPatient.ConsultationOf")}
                            </p>
                            {moment(d.dateConsultation).format("L")}
                          </div>
                          {/* <IconTroisPoints /> */}
                        </div>
                        <p className="titleHelper">
                          {t("DashboardPatient.Update2")}{" "}
                          {moment(d.updatedAt).calendar()}
                        </p>
                        <div className="specialite max-w-max">
                          <p>{`${d.doctorId?.firstName} ${
                            d.doctorId?.lastName
                          } - ${handleSpeciality(
                            d.doctorId?.speciality,
                            i18n.language.includes("en")
                          )}`}</p>
                        </div>
                      </div>
                    </Link>
                  );
                })}
                {prescriptions.map((d) => {
                  return (
                    <div className="recent-bloc hover:border-blue-500 border">
                      <div className="flex justify-between">
                        <div className="">
                          <p className="card-title">
                            {" "}
                            {t("DashboardPatient.ConsultationOf")}
                          </p>
                          {moment(d.dateConsultation).format("L")}
                        </div>
                        {/* <IconTroisPoints /> */}
                      </div>
                      <p className="titleHelper">
                        {t("DashboardPatient.Update2")}{" "}
                        {moment(d.updatedAt).calendar()}
                      </p>
                      <div className="specialite max-w-max">
                        <p>{`${d.doctorId?.firstName} ${
                          d.doctorId?.lastName
                        } - ${handleSpeciality(
                          d.doctorId?.speciality,
                          i18n.language.includes("en")
                        )}`}</p>
                      </div>
                      <div className="mt-2 flex flex-wrap">
                        {d.prescriptions.map((el, i) => (
                          <Link to={`/preview/${d._id}/${el._id}`}>
                            <div className="flexCenter space-x-2 relative mr-4">
                              <Icon isIcon={false} name="capsule" />
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="flexCenter">
                <span className="card-subtitle">
                  {t("DashboardPatient.NoDoc")}{" "}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardPatient;
