/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { userAtom } from "../../state/auth";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import moment from "../../helper/moment";
import { previousState } from "../../state/previousPageState";
import { getListConsultationByIdPatient } from "../../api/consultation";
import Spinner from "../../components/Spinner";
import pen from "../../assets/images/pen.png";

import Icon from "../Icon";

interface Props {
  patientData: any;
  refreshPrescription: boolean;
}

const Historiques: React.FC<Props> = ({ patientData, refreshPrescription }) => {
  const { t } = useTranslation() as any;
  const history = useHistory();
  const [, setPreviousStates] = useAtom(previousState) as any;
  const [user] = useAtom(userAtom) as any;
  const [listConsultation, setListConsultation] = useState([]) as any;
  const [lodaing, setLoading] = useState<boolean>(false);

  const _getListConsultation = async () => {
    try {
      setLoading(true);
      const response = await getListConsultationByIdPatient(patientData._id);
      setListConsultation(
        response?.consultations?.consultations?.filter(
          (el) => el?.status === "done" && el?.doctorId == user._id
        )
      );
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    _getListConsultation();
  }, [refreshPrescription]);

  return lodaing ? (
    <Spinner nhs={true} classNames="h-72" />
  ) : (
    <div className="historiques mt-10">
      {!_.isEmpty(listConsultation) &&
        listConsultation
          ?.sort(
            (a, b) =>
              +new Date(b.dateConsultation) - +new Date(a.dateConsultation)
          )
          .map((c) => {
            return (
              <div className="relative">
                <div
                  className="card-histoique col-start-4 col-end-12 p-4 rounded-xl my-4 mr-auto shadow-md w-full "
                  key={c?._id}
                >
                  <h3>{t("MyPatient.ConsultationOf")}</h3>
                  <div className="flex w-full justify-between	">
                    <h3 className="font-semibold text-black text-base mb-1 capitalize">
                      {patientData?.firstName} {patientData?.lastName}
                    </h3>
                    <p className="leading-tight text-justify ">
                      {moment(c.dateConsultation).format(t("Moment.Y"))}
                    </p>
                  </div>
                  <span className=" card-subtitle">{c?.motif}</span>

                  <div className="flex  gap-3 flex-wrap">
                    {!_.isEmpty(c?.prescriptions) &&
                      c?.prescriptions.map((el: any, i) => (
                        <span
                          className="px-4 py-2 bg-gray-200 bg-opacity-50 rounded-2xl shaddow cursor-pointer text-gray-600 flex gap-2"
                          onClick={() => {
                            setPreviousStates({
                              patientFile: {
                                patientId: c?.patientId,
                                openTab: 5,
                              },
                            });
                            history.push(
                              `/preview/${
                                patientData?.tasks?.find(
                                  (el) => el?.consultation?._id == c?._id
                                )?._id
                              }/${el?._id}`
                            );
                          }}
                        >
                          <Icon name={"capsule"} height={20} width={20} />{" "}
                          {t("MyPatient.Prescriptions")}{" "}
                          {c?.prescriptions.length > 1 && <span>{i + 1}</span>}
                        </span>
                      ))}

                    {c?.rapport &&
                      patientData?.tasks?.find(
                        (el) =>
                          el?.consultation?._id == c?._id && el.type == "report"
                      )?.status === "done" && (
                        <span
                          className="px-4 py-2 bg-gray-200 bg-opacity-50 rounded-2xl shaddow cursor-pointer text-gray-600 flex gap-2"
                          onClick={() => {
                            setPreviousStates({
                              patientFile: {
                                patientId: c?.patientId,
                                openTab: 5,
                              },
                            });
                            history.push(`/previewCompteRendu/${c?._id}`);
                          }}
                        >
                          <img
                            crossOrigin="anonymous"
                            referrerPolicy="origin"
                            src={pen}
                            alt="pen"
                            width="20px"
                            height="20px"
                          />
                          {t("MyPatient.Report")}
                        </span>
                      )}
                  </div>
                </div>
              </div>
            );
            // });
          })}
      {_.isEmpty(listConsultation) && (
        <div className="flexCenter w-full anEmptyBox">
          <p className="card-subtitle"> {t("MyPatient.noHistory")}</p>
        </div>
      )}
    </div>
  );
};

export default Historiques;
