import { Formik } from "formik";
import {
  AddAFKOrHoliday,
  cancelConsultationBetweenTimeRange,
  checkIfExistConsultationBetweenTimeRange,
} from "../../../api/agenda";
import React, { useState } from "react";
import TimePicker from "react-time-picker";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "../styles.scss";
import moment from "../../../helper/moment";
import Button from "../../ButtonUI";
import Modal from "../../ModalUI";
import makeToast from "../../Snackbar";

interface Props {
  setShowAgendaModal: Function;
}

const Indisponibilite: React.FC<Props> = ({ setShowAgendaModal }) => {
  const { t } = useTranslation() as any;
  const [startDate, setStartDate] = useState() as any;
  const [openConfirm, setOpenPopConfirm] = useState(false);
  const [endDate, setEndDate] = useState() as any;
  const [startTime, setStartTime] = useState("08:00") as any;
  const [endTime, setEndTime] = useState("18:00") as any;
  const [loadingBtn, setLoadingBtn] = useState(false);

  const _handleCancelMeet = async (dateStart, dateEnd) => {
    try {
      const result = await cancelConsultationBetweenTimeRange(
        dateStart,
        dateEnd
      );
      return result?.status;
    } catch (error) {
      console.error(error);
    }
  };

  const _addIndisponibility = async () => {
    try {
      const startYear = moment(startDate).year();
      const startMonth = startDate?.getMonth();
      const startDay: number = parseInt(moment(startDate).format("DD"));

      const endYear = moment(endDate).year();
      const endMonth = endDate?.getMonth();
      const endDay: number = parseInt(moment(endDate).format("DD"));

      const dateStart = new Date(
        startYear,
        startMonth,
        startDay,
        startTime.slice(0, 2),
        startTime.slice(3)
      );

      const dateEnd = new Date(
        endYear,
        endMonth,
        endDay,
        endTime.slice(0, 2),
        endTime.slice(3)
      );
      setLoadingBtn(true);

      await _handleCancelMeet(dateStart, dateEnd);
      await AddAFKOrHoliday(dateStart, dateEnd);
      setShowAgendaModal("");
      setLoadingBtn(false);
    } catch (error) {
      setLoadingBtn(false);
      console.error(error);
    }
  };

  const _checkConsultationBetweenTimeRange = async (dateStart, dateEnd) => {
    try {
      setLoadingBtn(true);

      const result = await checkIfExistConsultationBetweenTimeRange(
        dateStart,
        dateEnd
      );

      if (result?.message === "success") {
        setOpenPopConfirm(true);
        setLoadingBtn(false);
      } else if (result?.message === "404") {
        _addIndisponibility();
        setLoadingBtn(false);
      }
    } catch (error) {
      setLoadingBtn(false);
      console.error(error);
    }
  };

  const handleAddIndisponibility = async (values) => {
    try {
      if (!startDate || !endDate || !startTime || !endTime)
        return makeToast("warning", t("Agenda.fillDateIndis"));

      const startYear = moment(startDate).year();
      const startMonth = startDate?.getMonth();
      const startDay: number = parseInt(moment(startDate).format("DD"));

      const endYear = moment(endDate).year();
      const endMonth = endDate?.getMonth();
      const endDay: number = parseInt(moment(endDate).format("DD"));

      const dateStart = new Date(
        startYear,
        startMonth,
        startDay,
        startTime.slice(0, 2),
        startTime.slice(3)
      );

      const dateEnd = new Date(
        endYear,
        endMonth,
        endDay,
        endTime.slice(0, 2),
        endTime.slice(3)
      );

      await _checkConsultationBetweenTimeRange(dateStart, dateEnd);
      // setShowAgendaModal("");
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      <Modal
        showModal={openConfirm}
        closeModal={() => setOpenPopConfirm(false)}
      >
        <div className="px-3 py-7 rounded-xl w-96">
          <h3 className="mb-5">{t("Agenda.ConfirmCancelMeeting")}</h3>
          <div className="flexCenter space-x-5">
            <Button
              width="140px"
              height="40px"
              status="secondary"
              onClick={() => setOpenPopConfirm(false)}
            >
              {t("Agenda.Cancel")}
            </Button>
            <Button
              width="140px"
              height="40px"
              onClick={() => {
                // _DeleteExistConsultationBetweenTimeRange();
                _addIndisponibility();
              }}
              disabled={loadingBtn}
            >
              <div className="relative">
                {loadingBtn && (
                  <div className="absolute h-full w-full flexCenter">
                    <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-purple-500"></div>
                  </div>
                )}
                {t("Agenda.Confirm")}
              </div>
            </Button>
          </div>
        </div>
      </Modal>
      <Formik
        initialValues={{
          timeStart: "08:00",
          timeEnd: "12:00",
          startDate: "",
          EndDate: "",
        }}
        onSubmit={() => {}}
        //validationSchema={validationSchema}
      >
        {(props) => {
          const {
            values,
            // errors,
            // isSubmitting,
            // handleChange,
            // handleBlur,
            handleSubmit,
          } = props;

          return (
            <>
              <form
                onSubmit={handleSubmit}
                className="form-login flexCenter mt-10 "
              >
                <div className="flexColCenter">
                  <p className="self-start mb-5">
                    {t("Agenda.DateAndStartUnavailability")}
                  </p>
                  <div className="flex flex-col md:flex-row mb-5 md:mb-auto space-x-10">
                    <DatePicker
                      id="date"
                      locale="fr"
                      className="mb-8 border boder-gray-300 px-3 w-10/12"
                      selected={startDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={(e) => setStartDate(e)}
                      placeholderText={t("Agenda.StartDatUnavailability")}
                      autoComplete="off"
                      showYearDropdown
                    />
                    <div className="flexCenter sub-values-act ">
                      <TimePicker
                        id="timeStart"
                        onChange={setStartTime}
                        format="HH:mm"
                        value={startTime}
                        onClockClose={() => {
                          //setSelectedInput("");
                        }}
                        onClockOpen={() => {}}
                      />
                    </div>
                  </div>

                  <p className="self-start mb-5">
                    {t("Agenda.DateAndEndUnavailability")}
                  </p>
                  <div className="flex flex-col md:flex-row  space-x-10">
                    <DatePicker
                      id="date"
                      locale="fr"
                      className="custom-Input mb-8 border boder-gray-300 px-3"
                      selected={endDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={(e) => setEndDate(e)}
                      placeholderText={t("Agenda.EndDatUnavailability")}
                      autoComplete="off"
                      showYearDropdown
                    />
                    <div className="flexCenter sub-values-act ">
                      <TimePicker
                        id="timeEnd"
                        onChange={setEndTime}
                        format="HH:mm"
                        value={endTime}
                        // onClockClose={() => {
                        //   setSelectedInput("");
                        // }}
                        onClockOpen={() => {}}
                      />
                    </div>
                  </div>
                  {/* <div className="plageHoraire flexCenter gap-x-2 max-w-max mt-8 mb-8">
                  <p>Période de l'indisponibilité :</p>
                  <div className="flexCenter sub-values-act ">
                    <TimePicker
                      onChange={handleChange}
                      format="HH:mm"
                      value={values.timeStart}
                      onClockClose={() => {
                        //setSelectedInput("");
                      }}
                      onClockOpen={() => {}}
                    />
                  </div>
                  <p>à :</p>
                  <div className="flexCenter sub-values-act ">
                    <TimePicker
                      onChange={handleChange}
                      format="HH:mm"
                      value={values.timeEnd}
                      // onClockClose={() => {
                      //   setSelectedInput("");
                      // }}
                      onClockOpen={() => {}}
                    />
                  </div>
                </div> */}
                  <div className="flexCenter">
                    <Button
                      status="primary"
                      type="submit"
                      width="170px"
                      height="50px"
                      className="mt-5"
                      onClick={() => handleAddIndisponibility(values)}
                      disabled={loadingBtn}
                    >
                      <div className="relative">
                        {loadingBtn && (
                          <div className="absolute h-full w-full flexCenter">
                            <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-purple-500"></div>
                          </div>
                        )}
                        {t("Agenda.Save")}
                      </div>
                    </Button>
                  </div>
                </div>
              </form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default Indisponibilite;
