/* eslint-disable no-new-object */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */

enum Operator {
  EQ = "equal",
  NEQ = "not_equal",
  EQ_SUP = "equal_or_superior",
  EQ_INF = "equal_or_inferior",
  SUP = "superior",
  INF = "inferior",
  PAT = "pattern",
}
const isPattern = (current: String = "", valueToCompare: String = "") => {
  if (current == null) {
    return false;
  } else {
    const currentWord = current.toLowerCase();
    const patternWord = valueToCompare.toLowerCase();
    const result = currentWord.includes(patternWord);
    return result;
  }
};
function evaluate(c: any, values: any[]): boolean {
  const { fieldToCompare, ifCondition, value } = c;
  const myArray = fieldToCompare.split("||");
  const entires = Object.keys(values).map(function (key) {
    return { key: key, value: values[key] };
  });

  const answer = entires.find(
    (element) => element.key === c.fieldToCompare || element.key === myArray[0]
  );

  if (!answer) return false;

  switch (ifCondition) {
    case Operator.EQ:
      return myArray[1]
        ? answer.value[myArray[1]] === value
        : answer.value === value;
    case Operator.NEQ:
      return answer.value !== value;
    case Operator.EQ_SUP:
      return Number(answer.value) >= Number(value);
    case Operator.EQ_INF:
      return Number(answer.value) <= Number(value);
    case Operator.SUP:
      return Number(answer.value) > Number(value);
    case Operator.INF:
      return Number(answer.value) < Number(value);
    case Operator.PAT:
      return isPattern(answer.value, value);

    default:
      return false;
  }
}
export const resolver = (conditionalStatment: any, values) => {
  const { status, conditions, operator, action } = conditionalStatment;
  let shouldShow = true;
  let temp: boolean[] = [];

  if (status) {
    conditions.map((c) => {
      temp.push(evaluate(c, values));
    });
    if (operator === "all") {
      temp.map((t) => {
        if (t == false) {
          shouldShow = false;
        }
      });
    } else if (operator === "one") {
      let b = false;

      temp.map((t) => {
        if (t == true) {
          b = true;
        }
      });
      b ? (shouldShow = true) : (shouldShow = false);
    }
  }

  switch (action) {
    case "S":
      return shouldShow;
    case "H":
      return !shouldShow;
    default:
      return shouldShow;
  }
};
export const filterInputs = (elements: any[], elId: string) => {
  let filterdInputs: any[] = [];
  elements.map((e) => {
    if (
      e.id !== elId &&
      !e.content &&
      e.type !== "Image" &&
      e.type !== "Photo" &&
      e.type !== "ident" &&
      e.type !== "fullAdress" &&
      e.type !== "Signature" &&
      e.type !== "captcha"
    ) {
      if (e.type === "TableField") {
        e.lignes.map((l) => {
          let obj = new Object();
          obj["label"] = "grille_" + l.label;
          obj["field_name"] = `${e.field_name}||${l.label}`;
          filterdInputs.push(obj);
        });
      } else filterdInputs.push(e);
    }
  });
  return filterdInputs;
};
export const filterInputsForPage = (elements: any[]) => {
  let filterdInputs: any[] = [];
  elements.map((e) => {
    if (
      !e.content &&
      e.type !== "Image" &&
      e.type !== "Photo" &&
      e.type !== "ident" &&
      e.type !== "fullAdress" &&
      e.type !== "Signature" &&
      e.type !== "captcha"
    ) {
      if (e.type === "TableField") {
        e.lignes.map((l) => {
          let obj = new Object();
          obj["label"] = "grille_" + l.label;
          obj["field_name"] = `${e.field_name}||${l.label}`;

          filterdInputs.push(obj);
        });
      } else filterdInputs.push(e);
    }
  });

  return filterdInputs;
};
