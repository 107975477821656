/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useAtom } from "jotai";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { Formik } from "formik";
import countriesTn from "../../config/constants/countriesTn.json";
import CountryWithPhoneSuffix from "../../config/constants/pays.json";
import { signupPatientPatientelo } from "../../api/patient";
import { userAtom } from "../../state/auth";
import validationSchema from "./validateSchema";

import Button from "../../components/ButtonUI";
import Select from "../../components/Select";
import Input from "../../components/Input";
import makeToast from "../../components/Snackbar";

import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";
import cake from "../../assets/images/cake.png";
import requireInput from "../../assets/images/require.png";

import "./styles.scss";

import fr from "date-fns/locale/fr";

registerLocale("fr", fr);
setDefaultLocale("fr");

interface IProps {
  openModal: Boolean;
  setOpenModal: Function;
  message?: String;
  type?: String;
  url?: string;
  setPatientAdded: boolean | any;
  setSelectedPatient: Function;
  setPatientData: Function;
  setOpenModalInfoPatient: Function;
  addExistUser: Function;
  setRefresh?: any;
}

const AddPatient: React.FC<IProps> = ({
  openModal,
  setOpenModal,
  setPatientAdded,
  setSelectedPatient,
  setPatientData,
  setOpenModalInfoPatient,
  addExistUser,
  setRefresh,
}) => {
  const [user, setUser] = useAtom(userAtom);

  const [startDate, setStartDate] = useState() as any;

  const [loadingBtn, setLoadingbtn] = useState(false);
  const [valueConfirmed] = useState(false);
  const [loadingINS] = useState(false);
  const [paysState] = useState("France");
  const [regionState, setRegionState] = useState([]) as any;
  const [selectedRegionState, setSelectedRegionState] = useState() as any;
  const [departementState, setDepartementState] = useState([]) as any;
  const [selectedDepartementState, setSelectedDepartementState] =
    useState() as any;
  const [communesState, setCommunesState] = useState([]) as any;
  const [selectedCommunesState, setSelectedCommunesState] = useState() as any;

  const [villeTnState, setVilleTnState] = useState("");
  const [delegationTn, setDelegationTn] = useState([]) as any;
  const [delegationTnState, setDelegationTnState] = useState("");

  const _getRegion = async () => {
    try {
      fetch("https://geo.api.gouv.fr/regions")
        .then((response) => response.json())
        .then((data) =>
          setRegionState(
            data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
          )
        );
    } catch (error) {}
  };

  const _getDepartementState = async () => {
    try {
      fetch(
        `https://geo.api.gouv.fr/regions/${selectedRegionState?.code}/departements`
      )
        .then((response) => response.json())
        .then((data) =>
          setDepartementState(
            data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
          )
        );
    } catch (error) {}
  };

  const _getCommunesState = async () => {
    try {
      fetch(
        `https://geo.api.gouv.fr/departements/${selectedDepartementState?.code}/communes`
      )
        .then((response) => response.json())
        .then((data) =>
          setCommunesState(
            data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
          )
        );
    } catch (error) {}
  };

  // // Init
  useEffect(() => {
    _getRegion();
  }, []);

  // // Init
  useEffect(() => {
    if (selectedRegionState?.code) _getDepartementState();
  }, [selectedRegionState]);

  // // Init
  useEffect(() => {
    if (selectedDepartementState?.code) _getCommunesState();
  }, [selectedDepartementState]);

  // // Init
  useEffect(() => {
    if (villeTnState) {
      setDelegationTn(
        countriesTn[villeTnState].map((el) => ({
          ...el,
          value: el.delegation,
          label: el.delegation,
        }))
      );
    }
  }, [villeTnState]);

  const handleAddPatient = async (values) => {
    try {
      const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (
        !values.email ||
        !values.lastName ||
        !values.firstName ||
        !values.sexe ||
        !values.phone ||
        !values.country ||
        !values.adresse ||
        !values.numSecSocial ||
        !values.postalCode ||
        !startDate
      ) {
        return makeToast(
          "warning",
          "Veuillez remplir tous les champs obligatoires"
        );
      }
      if (!validEmailRegex.test(values.email))
        return makeToast("warning", "Veuillez entrer un email valide ");
      if (values.numSecSocial?.length !== 15)
        return makeToast(
          "warning",
          "Numéro de sécurité social doit comporter 15 chiffres"
        );

      values.addressDetails = {
        country: paysState,
        region: selectedRegionState?.value,
        departement: selectedDepartementState?.value,
        commune: selectedCommunesState?.value,
        gouvernement: villeTnState,
        delegation: delegationTnState,
      };

      if (startDate) values.birthDate = startDate;
      values.phoneSuffixe = CountryWithPhoneSuffix.find(
        (el) => el.name === values.country
      )?.dial_code as any;
      setLoadingbtn(true);

      const response = await signupPatientPatientelo({
        ...values,
        doctor: user?._id,
      });
      if (response?.message === "Max Patient Limit Reached") {
        makeToast(
          "warning",
          "Vous avez atteint la limite des patients de votre palier"
        );
        setOpenModal(!openModal);
        setLoadingbtn(false);
        return;
      }

      if (response?.response === "Patient already exist") {
        addExistUser(response.patient);
        setSelectedPatient(response.patient._id);
        setPatientData(response.patient);
        setLoadingbtn(false);

        return;
      }
      if (response.message !== "Failure") {
        makeToast("success", "Patient ajouté avec succès");
        setOpenModal(!openModal);
        setSelectedPatient(response.patient._id);
        setPatientAdded(true);
        setRefresh(uuidv4());
        setPatientData(response.patient);
        setUser({ ...user, ...response.doctor });
        setOpenModalInfoPatient(true);
        setLoadingbtn(false);
      }
    } catch (error) {
      setLoadingbtn(false);
      makeToast("error", "Ce Patient existe déjà..");
    }
  };

  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div
        className="absolute  inset-y-0 right-0  form-add-patient  z-40 transition ease-in duration-700 patient-modal-info"
        //   onClick={() => setOpenModal(!openModal)}
      >
        <Button
          status="icon-secondary"
          className="absolute right-0 mt-2 mr-2"
          onClick={() => setOpenModal(!openModal)}
        >
          <Close />
        </Button>
        <h1 className="title flexCenter">Nouveau patient</h1>
        <div className="form-container flexCenter">
          <Formik
            initialValues={{
              email: "",
              lastName: "",
              firstName: "",
              birthDate: "",
              sexe: [] as any,
              handicap: [],
              phone: "",
              adresse: "",
              numSecSocial: "",
              medecin: "",
              telMedecin: "",
              addressMedecin: "",
              postalCode: "",
              country: "",
              pregnant: false,
              semaineNBR: "",
              addressDetails: {},

              matriculeINS: "" as any,
              INSSexe: "",
              NameINS: "",
              firstNameINS: "",
              birthAddressINS: "",
              birthDateINS: "",
              INS: {},
              responseINS: {},
              INSStaus: "",
              Idfictional: false as any,
              IDDoting: false as any,
            }}
            onSubmit={async (values) => {
              try {
              } catch (error) {
                makeToast("[error] ", error);
              }
            }}
            validationSchema={validationSchema}
          >
            {(props) => {
              const {
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              } = props;

              return (
                <>
                  <form
                    onSubmit={handleSubmit}
                    className="form-login flexCenter flex-col fixingColor"
                  >
                    <Input
                      id="firstName"
                      placeholder="Prénom"
                      type="text"
                      error={errors.firstName}
                      value={values.firstName}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      className="custom-Input mb-2.5"
                      inlineLabel={false}
                      onfocus={true}
                      icon="user"
                      iconColor="white"
                      isIcon={true}
                      image="require"
                      right={true}
                      stylesDiv="relative"
                      imageCLS="w-4 max-h-1"
                      disabled={valueConfirmed}
                    />
                    <Input
                      id="lastName"
                      placeholder="Nom"
                      type="text"
                      error={errors.lastName}
                      value={values.lastName}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      className="custom-Input mb-2.5"
                      inlineLabel={false}
                      onfocus={true}
                      icon="user"
                      iconColor="white"
                      isIcon={true}
                      image="require"
                      right={true}
                      stylesDiv="relative"
                      imageCLS="w-4 max-h-1"
                      disabled={valueConfirmed}
                    />
                    <div className="relative">
                      <DatePicker
                        id="birthDate"
                        locale="fr"
                        className="custom-Input mb-8"
                        selected={startDate}
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) => {
                          setStartDate(date);
                        }}
                        placeholderText="Date de naissance (JJ/MM/AAAA)"
                        autoComplete="off"
                        showYearDropdown
                        disabled={valueConfirmed}
                      />
                      <img
                        crossOrigin="anonymous"
                        referrerPolicy="origin"
                        src={cake}
                        alt="cake"
                        className="absolute top-3 left-3"
                        width="18"
                      />
                      <img
                        crossOrigin="anonymous"
                        referrerPolicy="origin"
                        src={requireInput}
                        alt="require"
                        className="absolute right-3 top-3 w-1.5 h-1.5"
                      />
                    </div>
                    <div className="flex flex-row mb-6">
                      <div className="sexe">
                        <div className="flex space-x-2">
                          <p className="mb-2">Sexe</p>
                          <img
                            crossOrigin="anonymous"
                            referrerPolicy="origin"
                            src={requireInput}
                            alt="require"
                            className=" w-1.5 h-1.5 self-start"
                          />
                        </div>
                        <Input
                          name="sexe"
                          type="checkbox"
                          onChange={() => {
                            setFieldValue("sexe", ["woman"]);
                          }}
                          value="woman"
                          labelText="Féminin"
                          checked={values.sexe.includes("woman")}
                          labelProps={{ className: "check" }}
                          stylesDiv={"inputTest"}
                          disabled={valueConfirmed}
                        />
                        <Input
                          name="sexe"
                          type="checkbox"
                          onChange={() => {
                            setFieldValue("sexe", ["man"]);
                          }}
                          value="man"
                          labelText="Masculin"
                          labelProps={{ className: "check" }}
                          checked={values.sexe.includes("man")}
                          stylesDiv={"inputTest"}
                          disabled={valueConfirmed}
                        />
                      </div>
                      {values.sexe.includes("woman") && (
                        <div className="flex flex-col mx-2">
                          <p className="mb-2">Grossesse</p>
                          <Input
                            id="semaineNBR"
                            placeholder="Nombre de semaine"
                            type="number"
                            value={values.semaineNBR}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="mb-2.5 max-w-10"
                          />
                        </div>
                      )}
                      <div className="flex flex-col">
                        <div>
                          <p className="mb-2">Handicap</p>
                        </div>
                        <div className="flex particularite-container">
                          <div className="particularite">
                            <Input
                              name="handicap"
                              type="checkbox"
                              onChange={handleChange}
                              value="Moteur"
                              labelText="Moteur"
                              labelProps={{ className: "check" }}
                              stylesDiv={"inputTest"}
                            />
                            <Input
                              name="handicap"
                              type="checkbox"
                              onChange={handleChange}
                              value="Mental"
                              labelText="Mental"
                              labelProps={{ className: "check" }}
                              stylesDiv={"inputTest"}
                            />
                            <Input
                              name="handicap"
                              type="checkbox"
                              onChange={handleChange}
                              value="Aidant"
                              labelText="Aidant"
                              labelProps={{ className: "check" }}
                              stylesDiv={"inputTest"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <Select
                      id="country"
                      value={values.country}
                      divProps="w-full"
                      className="mb-2.5 w-full rounded-lg pl-3"
                      options={CountryWithPhoneSuffix.map((el) => ({
                        value: el.name,
                        label: el.name,
                      }))}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      text="pays"
                      required
                    />
                    {values.country === "France" && (
                      <>
                        <Select
                          text="Régions"
                          className="mb-2.5 w-full rounded-lg pl-3"
                          value={
                            selectedRegionState ? selectedRegionState.value : ""
                          }
                          divProps="w-full"
                          options={regionState}
                          onChange={(e) => {
                            setSelectedRegionState(
                              regionState.find(
                                (elm) => elm.value == e.target.value
                              )
                            );
                          }}
                        />
                        {selectedRegionState?.value && (
                          <Select
                            text="Départements"
                            className="mb-2.5 w-full rounded-lg pl-3"
                            divProps="w-full"
                            value={
                              selectedDepartementState
                                ? selectedDepartementState.value
                                : ""
                            }
                            options={departementState}
                            onChange={(e) => {
                              setSelectedDepartementState(
                                departementState.find(
                                  (elm) => elm.value == e.target.value
                                )
                              );
                            }}
                          />
                        )}
                        {selectedDepartementState?.value && (
                          <Select
                            text="Commune"
                            className="mb-2.5 w-full rounded-lg pl-3"
                            divProps="w-full"
                            value={
                              selectedCommunesState
                                ? selectedCommunesState.value
                                : ""
                            }
                            options={communesState}
                            onChange={(e) => {
                              setSelectedCommunesState(
                                communesState.find(
                                  (elm) => elm.value == e.target.value
                                )
                              );
                              setFieldValue(
                                "birthAddressINS",
                                communesState.find(
                                  (elm) => elm.value == e.target.value
                                )?.code
                              );
                            }}
                          />
                        )}
                      </>
                    )}
                    {values.country === "Tunisia" && (
                      <>
                        <Select
                          text="Gouvernorat"
                          className="mb-2.5 w-full rounded-lg pl-3"
                          divProps="w-full"
                          value={villeTnState}
                          options={Object.keys(countriesTn).map((elm) => ({
                            label: elm,
                            value: elm,
                          }))}
                          onChange={(e) => {
                            setVilleTnState(e.target.value);
                          }}
                        />

                        {villeTnState && (
                          <Select
                            text="Délégation"
                            className="mb-2.5 w-full rounded-lg pl-3"
                            divProps="w-full"
                            value={delegationTnState}
                            options={delegationTn}
                            onChange={(e) => {
                              setDelegationTnState(e.target.value);
                            }}
                          />
                        )}
                      </>
                    )}
                    <Input
                      id="phone"
                      placeholder="Numéro de téléphone"
                      type="text"
                      error={errors.phone}
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      className="mb-2.5"
                      image2="phone-white"
                      image="require"
                      right={true}
                      stylesDiv="relative"
                      imageCLS="w-4 max-h-1"
                    />
                    <Input
                      id="adresse"
                      placeholder="Adresse"
                      type="text"
                      error={errors.adresse}
                      value={values.adresse}
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      className="mb-2.5"
                      icon="home"
                      iconColor="white"
                      isIcon={true}
                      image="require"
                      right={true}
                      stylesDiv="relative"
                      imageCLS="w-4 max-h-1"
                    />
                    <Input
                      id="postalCode"
                      placeholder="Code postal"
                      type="number"
                      error={errors.postalCode}
                      value={values.postalCode}
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      className="mb-2.5"
                      icon="home"
                      iconColor="white"
                      isIcon={true}
                      image="require"
                      right={true}
                      stylesDiv="relative"
                      imageCLS="w-4 max-h-1"
                    />
                    <Input
                      id="email"
                      placeholder="Adresse mail"
                      type="text"
                      error={errors.email}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      className="mb-2.5"
                      image2="mail-white"
                      image="require"
                      right={true}
                      stylesDiv="relative"
                      imageCLS="w-4 max-h-1"
                    />

                    <Input
                      id="numSecSocial"
                      placeholder="Numéro de sécurité sociale"
                      type="text"
                      error={errors.numSecSocial}
                      value={values.numSecSocial}
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      className="mb-2.5"
                      image2="securite-sociale"
                      image="require"
                      right={true}
                      stylesDiv="relative"
                      imageCLS="w-4 max-h-1"
                    />
                    <div className="w-full pl-2">
                      <p className="my-2">Médecin traitant</p>
                    </div>
                    <Input
                      id="medecin"
                      placeholder="Médecin traitant"
                      type="text"
                      error={errors.medecin}
                      value={values.medecin}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2.5"
                      image="medecin"
                    />
                    <Input
                      id="telMedecin"
                      placeholder="Téléphone médecin traitant"
                      type="text"
                      error={errors.telMedecin}
                      value={values.telMedecin}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2.5"
                      image="phone-white"
                    />
                    <Input
                      id="addressMedecin"
                      placeholder="Adresse médecin traitant"
                      type="text"
                      error={errors.addressMedecin}
                      value={values.addressMedecin}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2.5"
                      icon="home"
                      iconColor="white"
                      isIcon={true}
                    />

                    <div className="flexCenter">
                      <Button
                        status="primary"
                        className="btn mb-2.5"
                        onClick={() => handleAddPatient(values)}
                        disabled={loadingBtn || loadingINS}
                      >
                        <div className="relative">
                          {(loadingBtn || loadingINS) && (
                            <div className="absolute h-full w-full flexCenter">
                              <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-purple-500"></div>
                            </div>
                          )}
                          Enregistrer
                        </div>
                      </Button>
                    </div>
                  </form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddPatient;
