/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef } from "react";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import moment from "../../helper/moment";
import { userAtom } from "../../state/auth";
import { socketAtom } from "../../state/socket";
import { getOldMessages } from "../../api/chat";

import Button from "../ButtonUI";
import { ReactComponent as IconFolder } from "../../assets/folder.svg";
import { handleSpeciality } from "../../helper/handleSpeciality";

interface IProps {
  chatWithData: any;
  handlePreviewSharedFolder?: any;
  handlePreviewSharedConsultation?: any;
}

const Chat: React.FC<IProps> = ({
  chatWithData,
  handlePreviewSharedFolder,
  // handlePreviewSharedConsultation,
}) => {
  const { t, i18n } = useTranslation() as any;
  const history = useHistory();
  const [user] = useAtom<any>(userAtom);
  const [socket] = useAtom<any>(socketAtom);
  const [messages, setMessages] = useState<any>([]);
  const [displayMessage, setDisplayMessage] = useState<any>([]);
  const messagesRef = useRef<any>();
  const blockMessageRef = useRef<any>();
  const chatroomId = [chatWithData?._id, user?._id].sort().join("");

  // const scrollToBottom = () => {
  //   blockMessageRef?.current?.scrollIntoView({
  //     behavior: "smooth",
  //     block: "center",
  //   });
  // };

  const textTruncate = (text) => {
    const words = text.split(" ");
    const truncatedText = words.slice(0, 2).join(" ") + "...";

    return truncatedText;
  };

  const sendMessage = () => {
    if (socket) {
      socket.emit("chatroomMessage", {
        sentFrom: user?._id,
        sentTo: chatWithData?._id,
        type: "text",
        message: messagesRef.current.value,
        onModelFrom: user.role === "DOCTOR" ? "Doctor" : "Patient",
        onModelTo: chatWithData.role === "DOCTOR" ? "Doctor" : "Patient",
        chatroomId,
      });
      messagesRef.current.value = "";
    }
  };

  //get All Old Messages
  useEffect(() => {
    async function _getOldMessage() {
      const response = await getOldMessages(chatroomId);
      setMessages(response);
    }
    _getOldMessage();
  }, [chatroomId]);

  // Group Message By Date
  useEffect(() => {
    if (messages && messages.length > 0) {
      const groups = messages.reduce((groups, el) => {
        // const date = moment(el.createdAt).format("YYYY/MM/DD");
        const date = moment(el.createdAt).format("YYYY/MM/DD");
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(el);
        return groups;
      }, {});
      const groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          data: groups[date],
        };
      });
      setDisplayMessage(groupArrays);
    }
  }, [messages]);

  // get RealTime New Messages
  useEffect(() => {
    if (socket) {
      socket.on("newMessage", (message) => {
        const newMessages = [...messages, message];
        setMessages(newMessages);
      });
    }

    //eslint-disable-next-line
  }, [messages, socket]);

  //Socket Join Chat Room
  useEffect(() => {
    if (socket) {
      socket.emit("joinRoom", {
        chatroomId,
      });
    }
    return () => {
      //Component Unmount
      if (socket) {
        socket.emit("leaveRoom", {
          chatroomId,
        });
      }
    };
    //eslint-disable-next-line
  }, [socket]);

  // useEffect(() => scrollToBottom(), [messages, blockMessageRef]);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    blockMessageRef.current?.scrollIntoView({});
  }, [messages, displayMessage, blockMessageRef]);

  return (
    <div className="chat-box w-full h-full m-2 flex flex-col pr-3  relative">
      <div
        className="flex flex-col justify-end m-3 space-y-3 right-0 absolute bottom-0 mb-24 w-full "
        style={{ height: "85%" }}
      >
        <div className="overflow-y-auto ">
          {displayMessage &&
            displayMessage.length > 0 &&
            displayMessage.map((el, i) => (
              <div className="relative">
                <hr
                  className="hr-center-text flex-1"
                  // data-content={el.date}
                  data-content={moment(el.date).format("dddd DD MMMM")}
                />
                {el.data.map(
                  ({
                    from,
                    date,
                    type,
                    message,
                    folder,
                    avatar,
                    sharedFolder,
                    sharedTask,
                    ...rest
                  }) => {
                    return (
                      <div>
                        <div
                          className={`flex items-center space-x-2 my-2  ${
                            rest?.sentFrom == user?._id && "flex-row-reverse "
                          }`}
                        >
                          {rest?.sentFrom != user?._id && (
                            <span className="avatar-chat">
                              {/* <img crossOrigin = "anonymous"  referrerPolicy="origin" src={""} alt="doctor" /> */}
                            </span>
                          )}
                          {type === "text" && (
                            <span
                              className={`message-chat max-w-max  ${
                                rest?.sentFrom == user?._id &&
                                "text-right p-2 you"
                              }`}
                            >
                              {message}
                            </span>
                          )}
                          {type === "sharedFolder" && (
                            <div
                              className={`flex items-center ${
                                rest?.sentFrom == user?._id &&
                                "flex-row-reverse"
                              }`}
                              onClick={() =>
                                handlePreviewSharedFolder({
                                  from,
                                  date,
                                  type,
                                  message,
                                  folder,
                                  avatar,
                                  sharedFolder,
                                  sharedTask,
                                  ...rest,
                                })
                              }
                            >
                              <div className="relative cursor-pointer">
                                <IconFolder />
                                <div className="absolute top-7 left-4">
                                  <span className="block text-xs mb-2 text-white font-bold">
                                    {user.firstName} {user.lastName}
                                  </span>
                                  <span className="block rounded-lg text-white text-sm p-1 bg-gray-500 bg-opacity-10">
                                    {textTruncate(
                                      handleSpeciality(
                                        user.speciality,
                                        i18n.language.includes("en")
                                      )
                                    )}
                                  </span>
                                </div>
                              </div>
                              {/* <div className="mx-3">
                            <IconDownload />
                          </div> */}
                            </div>
                          )}
                          {type === "shareOrder" && (
                            <div
                              className={`flex items-center ${
                                rest?.sentFrom == user?._id &&
                                "flex-row-reverse"
                              }`}
                              onClick={() =>
                                history.push(
                                  `/preview/${sharedTask.consultationId}/${sharedTask.prescriptionId}`
                                )
                              }
                            >
                              <div className="relative cursor-pointer">
                                <IconFolder />
                                <div className="absolute top-7 left-4">
                                  <span className="block text-xs mb-2 text-white font-bold">
                                    {user.firstName} {user.lastName}
                                  </span>
                                  <span className="block rounded-lg text-white text-sm p-1 bg-gray-500 bg-opacity-10">
                                    {chatWithData.role === "DOCTOR"
                                      ? handleSpeciality(
                                          chatWithData.speciality,
                                          i18n.language.includes("en")
                                        )
                                      : handleSpeciality(
                                          user.speciality,
                                          i18n.language.includes("en")
                                        )}
                                  </span>
                                </div>
                              </div>
                              {/* <div className="mx-3">
                            <IconDownload />
                          </div> */}
                            </div>
                          )}
                          {type === "shareReport" && (
                            <div
                              className={`flex items-center ${
                                rest?.sentFrom == user?._id &&
                                "flex-row-reverse"
                              }`}
                              onClick={() =>
                                history.push(
                                  `/previewCompteRendu/${sharedTask?.consultationId}`
                                )
                              }
                            >
                              <div className="relative cursor-pointer ">
                                <IconFolder />
                                <div className="absolute top-7 left-4">
                                  <span className="block text-xs mb-2 text-white font-bold">
                                    {user.firstName} {user.lastName}
                                  </span>
                                  <span className="block rounded-lg text-white text-sm p-1 bg-gray-500 bg-opacity-10">
                                    {chatWithData.role === "DOCTOR"
                                      ? textTruncate(
                                          handleSpeciality(
                                            chatWithData.speciality,
                                            i18n.language.includes("en")
                                          )
                                        )
                                      : textTruncate(
                                          handleSpeciality(
                                            user.speciality,
                                            i18n.language.includes("en")
                                          )
                                        )}
                                  </span>
                                </div>
                              </div>
                              {/* <div className="mx-3">
                            <IconDownload />
                          </div> */}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            ))}
          <div ref={blockMessageRef}></div>
        </div>
      </div>

      <div className="flex items-center w-full mb-4 absolute bottom-0 right-0 mr-3 ">
        <input
          type="text"
          name="chat"
          className="input-chat-box w-full mx-2 pl-5"
          style={{ height: "45px" }}
          placeholder={t("Chat.TypeMsg")}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              sendMessage();
            }
          }}
          ref={messagesRef}
        />
        <Button className="p-2" onClick={sendMessage}>
          {t("Chat.Envoyer")}
        </Button>
      </div>
      {/* <div style={{ float: "left", clear: "both" }} ref={blockMessageRef}></div> */}
    </div>
  );
};
export default Chat;
