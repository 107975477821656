import React, {
  DetailedHTMLProps,
  SelectHTMLAttributes,
  LabelHTMLAttributes,
} from "react";
import classNames from "classnames";
import Icon from "../Icon";
import requireInput from "../../assets/images/require.png";
import "./styles.scss";
import { useTranslation } from "react-i18next";

interface option {
  label: string;
  labelEn?: string;
  value: string;
}
type Props = DetailedHTMLProps<
  SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  error?: string;
  labelText?: string;
  labelProps?: DetailedHTMLProps<
    LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >;
  options: option[];
  text?: String;
  setText?: Boolean;
  icon?: string;
  iconColor?: string;
  image?: string;
  divProps?: string;
  isIcon?: boolean;
  required?: boolean;
};

const SelectComp: React.FC<Props> = ({
  error,
  labelText,
  labelProps,
  text,
  setText,
  options,
  icon,
  iconColor,
  image,
  isIcon,
  required,
  divProps,
  ...props
}) => {
  const { t } = useTranslation() as any;
  const { className: labelClassName, ...restOfLabelProps } = labelProps!;
  const { className: inputClassName, ...inputProps } = props;
  return (
    <div className={`relative ${divProps}`}>
      {labelText ? (
        <label
          className={classNames(
            "pl-2 h-12 flex items-center",
            labelClassName,
            error ? "text-blue-500" : null
          )}
          htmlFor={inputProps.id}
          {...restOfLabelProps}
        >
          {labelText}
        </label>
      ) : null}
      {icon &&
        (isIcon ? (
          <Icon
            name={icon}
            isIcon={true}
            color={iconColor}
            width={15}
            height={15}
          />
        ) : (
          <Icon
            name={icon}
            isIcon={false}
            color={iconColor}
            width={15}
            height={15}
          />
        ))}
      {image ? (
        <img
          crossOrigin="anonymous"
          referrerPolicy="origin"
          src={require(`../../assets/images/${image}.png`).default}
          alt="katomi"
          className="absolute left-0"
          style={{
            width: "18px",
            height: "18px",
            marginTop: "14px",
            marginLeft: "9px",
          }}
        />
      ) : null}
      <select
        className={classNames(
          "h-12 border bg-white rounded  outline-none cursor-pointer",
          error ? "text-blue-500 border-blue-300" : null,
          icon || image ? "pl-8" : null,
          inputClassName
        )}
        {...inputProps}
      >
        {setText && (
          <option /*key={null}*/ value="">
            {text ? text : t("Component.SelectDefaultOption")}
          </option>
        )}
        {options.map((option) => {
          return (
            <option /*key={option.value}*/ value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
      {required ? (
        <div className={`absolute right-2 top-3 ${props.className} max-w-max`}>
          <img
            crossOrigin="anonymous"
            referrerPolicy="origin"
            src={requireInput}
            alt="require"
            className="w-2 h-2"
          />
        </div>
      ) : null}
    </div>
  );
};
SelectComp.defaultProps = {
  labelProps: {},
  setText: true,
};
export default SelectComp;
