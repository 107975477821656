import { useAtom } from "jotai";
import _ from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { userAtom } from "../../state/auth";
import makeToast from "../Snackbar";
import "./styles.scss";
import Acte from "./tabs/Acte";
import Conges from "./tabs/Conges";
import Indisponibilite from "./tabs/Indisponibilite";
import Presentiel from "./tabs/Presentiel";
import Teleconsultation from "./tabs/Teleconsultation";

interface Props {
  setShowAgendaModal: any;
  selectedTimeSlot?: any;
  resetTimeSlots?: any;
  tabIndex?: number | null | undefined;
}

const NewAgendaEventForm: React.FC<Props> = ({
  setShowAgendaModal,
  selectedTimeSlot,
  resetTimeSlots,
  tabIndex,
}) => {
  const { t } = useTranslation();
  const [user] = useAtom(userAtom) as any;
  const [openTab, setOpenTab] = useState(1);
  const [openTabHeader, setOpenTabHeader] = useState(1);

  const tabs = [
    {
      id: 1,
      name: t("Agenda.Presential"),
    },
    {
      id: 2,
      name: t("Agenda.Teleconsultation"),
    },
    {
      id: 3,
      name: t("Agenda.Acts"),
    },
  ];
  const tabsHeader = [
    {
      id: 1,
      name: t("Agenda.meeting"),
    },
    {
      id: 2,
      name: t("Agenda.unavailability"),
    },
    {
      id: 3,
      name: t("Agenda.leave"),
    },
  ];

  React.useEffect(() => {
    if (tabIndex) setOpenTab(tabIndex);
  }, [tabIndex]);

  React.useEffect(() => {
    if (_.isEmpty(user.workingLocation)) {
      makeToast("warning", t("Agenda.ErrorConfigAgenda"));
      setShowAgendaModal("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className="new-event-form flex flex-col items-center">
      <h1>{t("Agenda.NewEvent")}</h1>
      <div className="tabs-Container mt-8">
        <div className="patient-tabs ">
          <div className="tabs flex items-center justify-between">
            {tabsHeader.map((tab) => {
              return (
                <div
                  className={
                    openTabHeader === tab.id
                      ? "atab selected flexCenter cursor-pointer "
                      : "atab flexCenter cursor-pointer "
                  }
                  onClick={() => setOpenTabHeader(tab.id)}
                >
                  {tab.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {openTabHeader !== 2 && openTabHeader !== 3 && (
        <div className="tabs-Container">
          <div className="patient-tabs ">
            <div className="tabs flex items-center justify-between">
              {tabs.map((tab) => {
                return (
                  <div
                    className={
                      openTab === tab.id
                        ? "atab selected flexCenter cursor-pointer"
                        : "atab flexCenter cursor-pointer"
                    }
                    onClick={() => setOpenTab(tab.id)}
                  >
                    {tab.name}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      <div>
        {openTab === 1 && openTabHeader !== 2 && openTabHeader !== 3 && (
          <div className="flexColCenter ">
            <Presentiel
              setShowAgendaModal={setShowAgendaModal}
              selectedTimeSlot={selectedTimeSlot}
              resetTimeSlots={resetTimeSlots}
            />
          </div>
        )}
        {openTab === 2 && openTabHeader !== 2 && openTabHeader !== 3 && (
          <div className="flexColCenter">
            {process.env.REACT_APP_KATOMI_TENANT === "katomiTN" ? (
              <div className="flexCenter h-40 font-medium">
                {t("Agenda.availableSoon")}
              </div>
            ) : (
              <Teleconsultation
                setShowAgendaModal={setShowAgendaModal}
                selectedTimeSlot={selectedTimeSlot}
                resetTimeSlots={resetTimeSlots}
              />
            )}
          </div>
        )}
        {openTab === 3 && openTabHeader !== 2 && openTabHeader !== 3 && (
          <div className="flexColCenter ">
            <Acte setShowAgendaModal={setShowAgendaModal} />
          </div>
        )}
        {openTabHeader === 2 && (
          <div className="flexColCenter ">
            <Indisponibilite setShowAgendaModal={setShowAgendaModal} />
          </div>
        )}
        {openTabHeader === 3 && (
          <div className="flexColCenter ">
            <Conges setShowAgendaModal={setShowAgendaModal} />
          </div>
        )}
      </div>
    </div>
  );
};

export default NewAgendaEventForm;
