/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import Modal from "../../../components/ModalUI";
import ObservationModal from "../modal/ObservationModal";
import { userAtom } from "../../../state/auth";
import { useAtom } from "jotai";
import moment from "../../../helper/moment";

interface IProps {
  setOpenModal: Function;
  filerObject: any;
  setFilterObject: Function;
  setSearch: Function;
}

const AntecedentTab: React.FC<IProps> = ({
  setSearch,
  setOpenModal,
  filerObject,
  setFilterObject,
}) => {
  // const filterList = [
  //   "Tous les médecins",
  //   "Antécédents médicaux et chirurgicaux",
  // ];
  const { t } = useTranslation() as any;
  const [observationModal, setObservationModal] = useState(false);
  const [user] = useAtom(userAtom) as any;
  const [fussionedAntecedent, setFussionedAntecedent] = useState<any>([]);
  const [selectedObservation, setSelectedObservation] = useState<any>("");
  const toggleObservatioModal = () => setObservationModal(!observationModal);

  useEffect(() => {
    if (user) {
      setFussionedAntecedent([
        ...user.antecedents,
        ...user.antecedentsChirugical,
      ]);
    }
  }, []);
  // useEffect(() => {
  //   if (filerObject) {
  //     let o = Object.fromEntries(
  //       Object.entries(filerObject).filter(([_, v]) => v != "")
  //     );
  //   }
  // }, [filerObject]);

  return (
    <>
      <Modal showModal={observationModal} slidemodal={true}>
        <ObservationModal
          closeModal={toggleObservatioModal}
          selectedAntecedent={fussionedAntecedent.find(
            (e) => e._id === selectedObservation
          )}
        />
      </Modal>
      <div>
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-5">
            {Object.entries(filerObject).map(([key, value]) => {
              if (value !== "") {
                return (
                  <span className="box flex space-x-3">
                    {key === "doctor" ? (
                      <span>
                        {value !== t("MyFolder.AllMyDoctors")
                          ? fussionedAntecedent.find(
                              (x) => x.doctorId._id === value
                            ).doctorId.firstName
                          : t("MyFolder.AllMyDoctors")}
                      </span>
                    ) : (
                      <span>{value as any}</span>
                    )}
                    <span
                      onClick={() => {
                        delete filerObject[key];
                        setFilterObject({ ...filerObject });
                      }}
                    >
                      x
                    </span>
                  </span>
                );
              }
            })}
            {/* <Button
              status="secondary"
              width="146px"
              height="35px"
              onClick={() => setOpenModal(true)}
            >
              + Autres filtres
            </Button> */}
          </div>
          {/* <div className="flex items-center space-x-3">
            <Icon isIcon={true} name="monTableau-select" />
            <span className="card-subtitle">|</span>
            <Icon isIcon={true} name="monTableau-select" />
          </div> */}
        </div>

        <div className="flex flex-wrap gap-2 mt-5">
          {_.isEmpty(fussionedAntecedent) ? (
            <div className="m-auto">
              <h3>{t("MyFolder.NoAntecedent")}</h3>
            </div>
          ) : (
            fussionedAntecedent?.map((el) => {
              return (
                <div className="bg-white flex flex-col pt-5 pb-2 pr-3 pl-3 rounded-2xl boxAntecedent justify-between">
                  <span className="text-sm font-bold  leading-none">
                    {el.nature} :
                  </span>
                  <span className="text-sm font-normal leading-none">
                    {el.type}
                  </span>
                  <span className="my-5 card-subtitle leading-none">
                    {t("MyFolder.UpdatedAt")}{" "}
                    <span className="font-bold">
                      {moment(el.updatedAt).format(t("Moment.V"))}
                    </span>{" "}
                    {/* {t("MyFolder.ByThe")} */}
                    <br />
                    <span className="font-bold">
                      {/* {t("MyFolder.Dr")}{" "} */}
                      Dr {`${el.doctorId.firstName} ${el.doctorId.lastName}`}
                    </span>
                  </span>
                  <div className="flex justify-end">
                    {/* <div className="flex space-x-4 imtes-center">
                      <Icon isIcon={false} name="capsule" />
                      <span className="card-subtitle">
                        {el.traitements.map((el) => (
                          <span>{el.traitement}</span>
                        ))}
                      </span>
                    </div> */}
                    <span
                      className="text-blue-700 cursor-pointer"
                      onClick={() => {
                        toggleObservatioModal();
                        setSelectedObservation(el._id);
                      }}
                    >
                      {el.observationsDoctor.length} {t("MyFolder.observation")}
                    </span>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default AntecedentTab;
