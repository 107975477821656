import {
  AspectRatio,
  Box,
  Center,
  Flex,
  Heading,
  Stack,
} from "@chakra-ui/react";
import { useForm } from "../../../../hooks/FormGeneratorHooks";

const DotsStepper = (props) => {
  const form = useForm({ subscribe: "form" });
  const spacing = 4;

  return (
    <Flex direction={"column"}>
      <Stack
        direction="row"
        display="flex"
        alignItems="center"
        justifyContent="center"
        spacing={spacing}
        {...props}
      >
        {form.steps?.map((step) => {
          const inactiveProps = !step.isVisited
            ? {
                bg: "gray.100",
                color: "gray.400",
              }
            : {};

          const visitedProps: any =
            step.isVisited && !step.isCurrent
              ? {
                  bg: "white",
                  color: "blue",
                  borderColor: "blue",
                  as: "button",
                  type: "button",
                  onClick: () => form.goToStep(step.name),
                  _hover: {
                    color: "blue",
                    bg: "gray.200",
                    borderColor: "gray.200",
                  },
                  _focus: {
                    boxShadow: "outline",
                  },
                }
              : {};

          const currentProps = step.isCurrent
            ? {
                zIndex: 1,
                bg: "blue",
                color: "white",
              }
            : {};

          return (
            <Flex direction={"column"}>
              <AspectRatio key={step.name} w="6" h={6} ratio={1}>
                <Box
                  zIndex={0}
                  borderRadius={"full"}
                  border="2px solid transparent"
                  fontWeight={step.isCurrent || step.isVisited ? "bold" : null}
                  outline="none"
                  fontSize="xs"
                  overflow="visible"
                  transition="0.2s"
                  _after={
                    step.index !== 0
                      ? {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          mt: "-1px",
                          mr: "2px",
                          top: "50%",
                          right: "100%",
                          bg:
                            step.isVisited || step.isCurrent
                              ? "blue"
                              : "gray.200",
                          h: "2px",
                          w: spacing,
                        }
                      : null
                  }
                  {...inactiveProps}
                  {...visitedProps}
                  {...currentProps}
                >
                  {step.index + 1}
                </Box>
              </AspectRatio>
            </Flex>
          );
        })}
      </Stack>
      <Center p={2} my={2}>
        {" "}
        {form.steps?.map((step) => {
          // const inactiveProps = !step.isVisited
          //   ? {
          //       bg: "gray.100",
          //       color: "gray.400",
          //     }
          //   : {};
          // const currentProps = step.isCurrent
          //   ? {
          //       zIndex: 1,
          //       bg: "blue",
          //       color: "white",
          //     }
          //   : {};
          return (
            step.isCurrent && (
              <Heading
                outline={"blue"}
                className=""
                size="sm"
                px={0.5}
                children={step.label}
              />
            )
          );
        })}
      </Center>
    </Flex>
  );
};
export default DotsStepper;
