import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import classNames from "classnames";
import _ from "lodash";
import { WidthProvider, Responsive } from "react-grid-layout";
import { userAtom } from "../../state/auth";
// import { dmpAuthAtom } from "../../state/dmp";
import { widgetAtom } from "../../state/widget";
import { updateDoctorData } from "../../api/doctor";
import PrescriptionModal from "../PrescriptionModal";
import Modal from "../Modal";
import {
  PatientOfTheDayWidget,
  TaskOfTheDayWidget,
  AgendaWidget,
  MessagerieWidget,
  SurveyWidget,
  CardReaderWidget,
} from "./DashbordWidget";
import "./dragAndDrop.scss";
import PatientInfo from "../SharedPatientDetails";
const ResponsiveGridLayout = WidthProvider(Responsive);

const App = () => {
  const [user, setUser] = useAtom(userAtom) as any;
  const [editWidget] = useAtom(widgetAtom) as any;

  const [widget, setWidget] = useState([]) as any;
  const [layout, setlayout] = useState([]) as any;

  //prescription modal
  const [openPrescriptionModal, setOpenPrescriptionModal] = useState(false);
  const [taskDetails, settaskDetails] = useState() as any;
  const [reFetchTask, setReFetchTask] = useState(false) as any;
  const [PatientInfoData, setPatientInfoData] = useState() as any;
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (user.dashboardConfig) {
      setWidget(
        user.dashboardConfig
          .filter(
            (el) =>
              el.active &&
              (!el.tenant || el.tenant === process.env.REACT_APP_KATOMI_TENANT)
          )
          .map((el) => ({ ...el, id: el._id }))
      );
      setlayout(
        user.dashboardConfig
          .filter((el) => el.active)
          .map((el) => ({
            i: el._id,
            x: +el.position.x,
            y: +el.position.y,
            w: +el.width,
            h: +el.height,
            static: !editWidget,
          }))
      );
    }
  }, [user, editWidget]);

  const handleWidget = (name) => {
    switch (name) {
      case "PatientOfTheDayWidget":
        return <PatientOfTheDayWidget />;
      case "TaskOfTheDayWidget":
        return (
          <TaskOfTheDayWidget
            openPrescriptionModal={(task) => {
              setOpenPrescriptionModal(true);
              settaskDetails(task);
            }}
            reFetchTask={reFetchTask}
          />
        );
      case "AgendaWidget":
        return <AgendaWidget />;
      case "ChatWidget":
        return <MessagerieWidget />;
      case "SurveyWidget":
        return <SurveyWidget />;
      case "CardReaderWidget":
        return <CardReaderWidget />;
      default:
        return <></>;
    }
  };

  const handleChangeLayout = async (lyt) => {
    const newWidgetStatus = user.dashboardConfig.map((el) =>
      lyt.some((elm) => String(elm.i) === String(el._id))
        ? {
            ...el,
            width: lyt.find((elm) => String(elm.i) === String(el._id)).w,
            height:
              lyt.find((elm) => String(elm.i) === String(el._id)).h > 2
                ? lyt.find((elm) => String(elm.i) === String(el._id)).h
                : 2,
            position: {
              x: lyt.find((elm) => String(elm.i) === String(el._id)).x,
              y: lyt.find((elm) => String(elm.i) === String(el._id)).y,
            },
          }
        : el
    );

    const updatedUser = await updateDoctorData({
      dashboardConfig: newWidgetStatus,
    });
    setUser({ ...user, ...updatedUser });
  };

  return !_.isEmpty(widget) && !_.isEmpty(layout) ? (
    <div className="relative">
      {PatientInfoData?.patientData && modal && (
        <PatientInfo
          setOpenModal={setModal}
          patientData={PatientInfoData?.patientData}
          selectedPatient={PatientInfoData?.patientData?._id}
          setModal={setModal}
          setPatientData={PatientInfoData?.setPatientData}
        />
      )}
      <Modal showModal={openPrescriptionModal}>
        <PrescriptionModal
          closeModal={() => {
            setOpenPrescriptionModal(false);
            setReFetchTask(!reFetchTask);
          }}
          consultationDetails={{
            ...taskDetails?.consultation,
            patientId: taskDetails?.patient,
          }}
          setPatientInfoData={(data) => {
            setPatientInfoData(data);
          }}
          setModal={setModal}
          // patientData={patientData}
        />
      </Modal>

      <ResponsiveGridLayout
        layouts={{ lg: layout }}
        onLayoutChange={handleChangeLayout}
        measureBeforeMount
        className={classNames("layout", editWidget && "edit-widget")}
        isDragable
        isResizable
        margin={[20, 20]}
      >
        {widget.map((item, i) => {
          return <div key={item.id}>{handleWidget(item.name)}</div>;
        })}
      </ResponsiveGridLayout>
    </div>
  ) : null;
};

export default App;
