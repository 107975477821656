import { Redirect, RouteProps } from "react-router";
import MyAccountPatient from "../pages/MyAccountPatient";
import Agenda from "../pages/Agenda";
import DashboardPatient from "../pages/DashboardPatient";
import MyFolderPatient from "../pages/MyFolderPatient";
import SearchPatient from "../pages/SearchPatient";
import MyDoctors from "../pages/MyDoctors";
import AnnuaireDoctor from "../pages/AnnuairePatient";
import TeleConsultation from "../pages/TeleconsultationPatient";
import Consultations from "../pages/MyConsultation";
import PreviewOrdonnnance from "../components/PreviewOrdonannce";
import PreviewRapport from "../components/PreviewRapport";
import PatientSurvey from "../pages/PatientSurvey";
import ExternalBokking from "../pages/ExternalBooking";

const patientRoutes: RouteProps[] = [
  {
    path: "/",
    component: DashboardPatient,
    exact: true,
  },
  {
    path: "/mon-compte-patient",
    component: MyAccountPatient,
    exact: true,
  },
  {
    path: "/mon-dossier-patient",
    component: MyFolderPatient,
    exact: true,
  },
  {
    path: "/agenda",
    component: Agenda,
    exact: true,
  },
  {
    path: "/search",
    component: SearchPatient,
    exact: true,
  },
  {
    path: "/myDoctors",
    component: MyDoctors,
    exact: true,
  },
  {
    path: "/consultations",
    component: Consultations,
    exact: true,
  },
  {
    path: "/annuaire",
    component: AnnuaireDoctor,
    exact: true,
  },
  {
    path: "/teleconsultation/:idConsultation",
    component: TeleConsultation,
    exact: true,
  },
  {
    path: "/preview/:taskId/:prescriptionId",
    component: PreviewOrdonnnance,
    exact: true,
  },
  {
    path: "/previewCompteRendu/:consultationId",
    component: PreviewRapport,
    exact: true,
  },
  {
    path: "/survey-patient",
    component: PatientSurvey,
    exact: true,
  },
  {
    path: "/rdv",
    component: ExternalBokking,
    exact: true,
  },
  { render: () => <Redirect to="/" /> },
];

export default patientRoutes;
