export const AuthentificationCheck = {
  fr: {
    login: "Se connecter",
    doctor: "un professionel de santé",
    im: "Je suis",
    patient: "un patient",
  },
  en: {
    login: "Login",
    doctor: "a doctor",
    im: "I'm",
    patient: "a patient",
  },
};
