/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
/** API & HELPERS */
import * as stripeConfig from "../../config/stripe";
import { patientBookMeeting, setStripeCustomer } from "../../api/patient";
import { loadImage } from "../../api/documents";
import { userAtom } from "../../state/auth";
/** COMPONENTS */
import { AllCards } from "../PaymentCards";
import StripeForm from "./StripeForm";
import Button from "../ButtonUI";
import Icon from "../Icon";
import makeToast from "../Snackbar";
/** ASSETS */
import info from "../../assets/images/info.png";
import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";
import { handleSpeciality } from "../../helper/handleSpeciality";

const stripePromise = loadStripe(stripeConfig.STRIPE_PUBLIC_KEY);
const DoctorCard = ({
  closeModal,
  meetData,
  selectSpecialist,
  openConfirmMeetModal,
}) => {
  const { t, i18n } = useTranslation() as any;
  const tenant = process.env.REACT_APP_KATOMI_TENANT;
  const [user] = useAtom(userAtom);
  const [cgu, setCGU] = useState(false) as any;
  const [ppd, setPPD] = useState(false) as any;
  const [btnSubmitStatusLoading, setbtnSubmitStatusLoading] = useState(false);
  const [emailValidated, setEmailValidated] = useState(false);

  const handleValidMeet = async (setupIntent) => {
    try {
      setbtnSubmitStatusLoading(true);
      const response = await patientBookMeeting({
        ...meetData,
        consultationIntent: setupIntent,
      });
      setbtnSubmitStatusLoading(false);
      if (response.message === "Max Patient Limit Reached") {
        makeToast("warning", t("MyDoctors.ErrorMaxReached"));
        closeModal();
        return;
      }
      if (response) {
        openConfirmMeetModal(response?.consultation);
        closeModal();
      }
    } catch (error) {
      console.error(error);
      setbtnSubmitStatusLoading(false);
    }
  };

  const prepareWidth = (i) => {
    switch (i) {
      case 6:
        return 60;
      case 7:
        return 45;
      default:
        return 30;
    }
  };

  const _setStripeCustomer = async () => {
    try {
      await setStripeCustomer();
    } catch (error) {}
  };

  useEffect(() => {
    _setStripeCustomer();
  }, []);

  // test tenant KatomiTN
  useEffect(() => {
    if (tenant === "katomiTN") {
      makeToast("warning", t("MyDoctors.AvailableSoon"));
      closeModal();
    }
  }, []);

  return (
    <>
      <div className="p-5">
        <div className="flex justify-between">
          <div className="flex flex-1 items-center space-x-4  p-4 mb-2 rounded-2xl">
            <div className="h-16 w-16 rounded-lg">
              {selectSpecialist?.business_photo ? (
                <img
                  crossOrigin="anonymous"
                  referrerPolicy="origin"
                  alt="profile"
                  src={
                    selectSpecialist?.business_photo.slice(0, 4) === "http"
                      ? selectSpecialist?.business_photo
                      : loadImage(
                          selectSpecialist?._id,
                          "profile",
                          "thumbnail",
                          selectSpecialist?.business_photo
                        )
                  }
                  className="w-16 h-16 rounded-full shadow-md object-fill"
                />
              ) : selectSpecialist?.sexe === "woman" ? (
                <WomanPatient className="h-16 w-16 bg-gray-300" />
              ) : (
                <ManPatient className="h-16 w-16 bg-gray-300" />
              )}
            </div>
            <div className="flex flex-col">
              <span className="card-title">{`${selectSpecialist?.firstName} ${selectSpecialist?.lastName}`}</span>
              <span className="card-subtitle">
                {" "}
                {handleSpeciality(
                  selectSpecialist?.speciality,
                  i18n.language.includes("en")
                )}
              </span>
            </div>
          </div>
          <Icon
            isIcon={true}
            name="close"
            onClick={closeModal}
            className="close-modal"
          />
        </div>
        <h2 className="mb-5">{t("MyDoctors.FinalizAppointment")}</h2>

        <div className="flex w-full">
          <div className="w-1/6 flex flex-col items-center">
            <div className="w-11 h-11 border border-blue-500 flexCenter rounded-lg">
              1
            </div>
            {emailValidated && (
              <>
                <div className="h-72 border-dashed border border-blue-400"></div>
                <div className="w-11 h-11 border border-blue-500 flexCenter rounded-lg">
                  2
                </div>
              </>
            )}
          </div>
          <div className="w-5/6">
            <div className="">
              <p className="font-semibold">
                {" "}
                {t("MyDoctors.NoAmountWillDebited")}
              </p>
              <ul style={{ listStyleType: "disc" }}>
                {" "}
                {t("MyDoctors.maximumAmount")}
                <li></li>
                <li> {t("MyDoctors.exactAmount")}</li>
                <li> {t("MyDoctors.consultationDoesNotTakePlace")}</li>
                <li> {t("MyDoctors.DecideNotCharge")}</li>
              </ul>
              <div className="mt-5 flex space-x-2">
                <div className="relative">
                  <input
                    name="cgu"
                    type="checkbox"
                    checked={cgu}
                    className="mr-3"
                  />
                  <div
                    className="absolute inset-0"
                    onClick={() => setCGU(!cgu)}
                  ></div>
                </div>{" "}
                {t("MyDoctors.TOS")}
              </div>
              <div className="flex space-x-2">
                <div className="relative">
                  <input
                    name="ppd"
                    type="checkbox"
                    checked={ppd}
                    className="mr-3"
                  />
                  <div
                    className="absolute inset-0"
                    onClick={() => setPPD(!ppd)}
                  ></div>
                </div>{" "}
                {t("MyDoctors.ProtectionPolicy")}
              </div>

              <div className="flexCenter mt-5">
                <Button
                  width="122.7px"
                  height="50px"
                  onClick={() => {
                    if (cgu && ppd) setEmailValidated(true);
                    else
                      makeToast(
                        "warning",
                        t("MyDoctors.AcceptGeneralConditions")
                      );
                    // if (validEmailRegex.test(email)) setEmailValidated(true);
                    // else makeToast("warning", "invalid email");
                  }}
                >
                  {t("MyDoctors.Confirm")}
                </Button>
              </div>
            </div>
            {emailValidated && (
              <div className="mt-5">
                <h3 className="uppercase text-primary-blue font-bold text-sm">
                  {t("MyDoctors.bankingInformation")}
                </h3>
                <p className="max-w-lg">
                  {t("MyDoctors.EnterYourBankDetails")}
                  <span className="font-bold"> {t("MyDoctors.madeAfter")}</span>
                </p>
              </div>
            )}
          </div>
        </div>

        {emailValidated && (
          <>
            <div className="bg-gray-100 rounded-2xl p-3 mt-7 flex justify-between">
              <div className="">
                <p className="flex items-center space-x-2">
                  <Icon name="cadenas" isIcon={true} />
                  <span>
                    {t("MyDoctors.PaiementSecurity")}
                    <span className="font-bold">
                      {selectSpecialist?.pricing} {selectSpecialist?.currency}
                    </span>
                  </span>
                </p>
                <p>
                  {t("MyDoctors.at")}{" "}
                  <span className="font-bold capitalize">
                    {t("MyDoctors.Dr")}{" "}
                    {`${selectSpecialist?.firstName} ${selectSpecialist?.lastName}`}
                  </span>
                </p>
              </div>
              <div className="pr-7 flex max-w-max">
                <img
                  crossOrigin="anonymous"
                  referrerPolicy="origin"
                  src={info}
                  alt=""
                  className="w-6 h-6 mx-2"
                  style={{
                    filter:
                      "invert(31%) sepia(69%) saturate(3171%) hue-rotate(223deg) brightness(100%) contrast(93%)",
                  }}
                />
                <p className="courant-text text-primary-blue font-bold">
                  {t("MyDoctors.FindOutMore")}
                </p>
              </div>
            </div>
            <div className="flex my-5">
              {AllCards.map((CardComponent, i) => (
                <CardComponent
                  key={i.toString()}
                  style={{
                    width: prepareWidth(i),
                    height: 30,
                    margin: "0 8px",
                  }}
                />
              ))}
            </div>

            <Elements stripe={stripePromise}>
              <StripeForm
                user={user}
                setbtnSubmitStatusLoading={setbtnSubmitStatusLoading}
                btnSubmitStatusLoading={btnSubmitStatusLoading}
                handleNext={(setupIntent) => handleValidMeet(setupIntent)}
              />
            </Elements>
          </>
        )}
      </div>
    </>
  );
};

export default DoctorCard;
