/* eslint-disable react-hooks/exhaustive-deps */
import { Stage } from "react-konva";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import "./styles.scss";
import ItemsArrayJson from "./StaticData/ItemArray.json";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { checkDeselect, printCharacters } from "./Helper/functions";
import { v4 as uuidv4 } from "uuid";
import { useLocation } from "react-router-dom";
import htmlToDraft from "html-to-draftjs";
import { EditorState, ContentState } from "draft-js";
import ReactHtmlParser from "react-html-parser";
import history from "../../history";
import { useAtom } from "jotai";
import { userAtom } from "../../state/auth";
import SavePdfComponent from "./savePdf";
import Icon from "../Icon";
import { AddTemplate } from "./Helper/api";
import { ImageEditModal } from "./Modal/imageEditModal";
import { TextEditModal } from "./Modal/textEditModal";
import { OutilsItems } from "./Components/outilsItems";
import { LayerComponent } from "./Components/layerComponent";
import { useTranslation } from "react-i18next";

const cover = {
  marginTop: "20px",
  marginRight: "20px",
};

const CustomPDFIO = () => {
  const { t } = useTranslation();
  const stageRef = useRef() as any;
  const [inputValue, setInputValue] = useState("");
  const [, setPosition] = useState({ x: 0, y: 0 });
  const [selectedInputId, setSelectedInputId] = useState("");
  const [didEdit, setDidEdit] = useState(false);
  const [isSelected, setIselected] = useState(false);
  const [ItemsArray] = useState(ItemsArrayJson);
  const [textColor, setTextColor] = useState();
  const [dataSelectedObj, setDataSelectedObj] = useState({}) as any;
  const [editorState, setEditorState] = useState<any>();
  const [sharedY, setSharedY] = useState();
  const location: any = useLocation();
  const [linkPdf, setLinkPdf] = useState();
  const [user] = useAtom(userAtom) as any;
  const [showModalTextOption, setShowModalTextOption] =
    useState<boolean>(false);
  const [showModalImageOption, setShowModalImageOption] =
    useState<boolean>(false);
  const [listData, setListData] = useState([]) as any;
  const { templateName, modalType } = location?.state;

  useEffect(() => {
    setListData([
      ...listData,
      {
        ...ItemsArray["redaction"],
        id: uuidv4(),
      },
      {
        ...ItemsArray["endRedaction"],
        id: uuidv4(),
      },
    ]);
  }, []);

  useEffect(() => {
    if (selectedInputId.length > 0 || selectedInputId !== "") {
      const getTypeSelectedItem = listData.find(
        (e) => e.id === selectedInputId
      );
      if (getTypeSelectedItem?.type === "text") {
        setInputValue(getTypeSelectedItem.title);
      }
    }
  }, [selectedInputId]);

  useEffect(() => {
    if (
      (selectedInputId === "" ||
        selectedInputId === undefined ||
        selectedInputId === null) &&
      listData.length > 0
    ) {
      setSelectedInputId(listData[listData.length - 1].id);
    }
    const data = listData.find(
      (e) => e.type === "redaction" && e.spec === "add"
    );
    if (data) {
      setSharedY(data.y);
    }
  }, [listData]);

  useEffect(() => {
    if (
      selectedInputId === "" ||
      selectedInputId === undefined ||
      selectedInputId === null
    ) {
      setDidEdit(false);
    }
  }, [selectedInputId]);

  useEffect(() => {
    if (location?.state?.selectedTemplate?._id) {
      setListData([...location?.state?.selectedTemplate?.listData]);
      if (location?.state?.selectedTemplate.text) {
        const contentBlock = htmlToDraft(
          ReactHtmlParser(String(location?.state?.selectedTemplate.text))[0]
        );
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          setEditorState(EditorState.createWithContent(contentState));
        }
      }
    }
  }, [location]);

  return (
    <>
      <header className="flex justify-between md:items-center w-full flex-col md:flex-row mb-2">
        <div
          className="icon cursor-pointer"
          onClick={() => history.push("/pdf")}
        >
          <Icon name="direction-gauche" width={50} height={50} isIcon={true} />
        </div>
        {location?.state?.selectedTemplate?._id ? (
          <SavePdfComponent
            listData={listData}
            editorState={editorState}
            printCharacters={printCharacters}
            sharedY={sharedY}
            templateName={templateName}
            modalType={modalType}
            isPatient={false}
            setLinkPdf={setLinkPdf}
            linkPdf={linkPdf}
            AddTemplate={() =>
              AddTemplate(
                templateName,
                modalType,
                listData,
                user,
                editorState,
                t
              )
            }
            update={true}
          />
        ) : (
          <SavePdfComponent
            listData={listData}
            editorState={editorState}
            printCharacters={printCharacters}
            sharedY={sharedY}
            templateName={templateName}
            modalType={modalType}
            isPatient={false}
            setLinkPdf={setLinkPdf}
            linkPdf={linkPdf}
            AddTemplate={() =>
              AddTemplate(
                templateName,
                modalType,
                listData,
                user,
                editorState,
                t
              )
            }
            update={false}
          />
        )}
      </header>
      <div
        className="bg-white flex shadow-md rounded-lg px-4"
        style={{ width: "100%" }}
        onKeyDown={(e) => {
          if (e.keyCode === 46) {
            if (isSelected && !_.isEmpty(dataSelectedObj)) {
              // const getObjToDelete = listData.find(
              //   (e) => e.id === selectedInputId
              // );
              const copyData = listData.filter((e) => e.id !== selectedInputId);
              setListData([...copyData]);
              setSelectedInputId("");
              setIselected(false);
              setDataSelectedObj({});
            }
          }
        }}
        tabIndex={0}
      >
        <OutilsItems setListData={setListData} listData={listData} />
        <div className="back">
          <Stage
            width={210 * 3}
            height={297 * 3}
            id="stageOfKonva"
            style={{
              backgroundColor: "white",
              maxWidth: "fit-content",
              margin: 10,
            }}
            onMouseDown={(e) =>
              checkDeselect(
                e,
                setSelectedInputId,
                setIselected,
                setDataSelectedObj
              )
            }
            ref={stageRef}
          >
            <LayerComponent
              listData={listData}
              setListData={setListData}
              setPosition={setPosition}
              setSelectedInputId={setSelectedInputId}
              setDidEdit={setDidEdit}
              selectedInputId={selectedInputId}
              isSelected={isSelected}
              setIselected={setIselected}
              setTextColor={setTextColor}
              dataSelectedObj={dataSelectedObj}
              setDataSelectedObj={setDataSelectedObj}
              setShowModalTextOption={setShowModalTextOption}
              setShowModalImageOption={setShowModalImageOption}
            />
          </Stage>
        </div>
        <div className="w-full p-10">
          <div style={cover} />
          <ImageEditModal
            showModalImageOption={showModalImageOption}
            setShowModalImageOption={setShowModalImageOption}
            dataSelectedObj={dataSelectedObj}
            setListData={setListData}
            listData={listData}
          />
          <TextEditModal
            showModalTextOption={showModalTextOption}
            setShowModalTextOption={setShowModalTextOption}
            dataSelectedObj={dataSelectedObj}
            isSelected={isSelected}
            listData={listData}
            setListData={setListData}
            setTextColor={setTextColor}
            textColor={textColor}
            selectedInputId={selectedInputId}
            setDataSelectedObj={setDataSelectedObj}
            setInputValue={setInputValue}
            setDidEdit={setDidEdit}
            didEdit={didEdit}
            inputValue={inputValue}
          />
        </div>
      </div>
    </>
  );
};
export default CustomPDFIO;
