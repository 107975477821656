/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import _ from "lodash";
import classNames from "classnames";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { useSwipeable } from "react-swipeable";
import { Link, useHistory } from "react-router-dom";

import Icon from "../Icon";
import Input from "../Input";
import Button from "../ButtonUI";
import { logout } from "../../api/auth";
import { doctorSeenNotification } from "../../api/doctor";
import { colorAvatarAtom } from "../../state/constants";
import { userAtom } from "../../state/auth";
import { ActiveRouteAtom } from "../../state/route";
import { loadImage } from "../../api/documents";
import { getUnreadMsg } from "../../api/messages";
import ChatModal from "../SharedChat";
import { screenBreakpoints } from "../../config/theme";

import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";
import { ReactComponent as IconSearch } from "../../assets/icons/icon-search.svg";

import "./styles.scss";
import { handleSpeciality } from "../../helper/handleSpeciality";

const SideBarDoctor: React.FC = () => {
  const { t, i18n } = useTranslation() as any;
  const [user, setUser] = useAtom(userAtom) as any;
  const [patientName, setPatientName] = useState("");
  const [tryToLogout, setTryToLogout] = useState(false);
  const [colorAvatar] = useAtom<any>(colorAvatarAtom);
  const [path, setPath] = useAtom(ActiveRouteAtom);
  const [notification, setNotifications] = useState([]) as any;
  const [chatModal, setChatModal] = useState();
  const [menuToggleIsActive, setMenuToggleIsActive] = useState(
    window.innerWidth <= screenBreakpoints.md
  );
  const [forceMenuOpen, setForceMenuOpen] = useState(false);
  const [, setWidthPage] = useState(document.body.clientWidth);

  const swipeEventsHandlers = useSwipeable({
    onSwipedLeft: () => {
      setForceMenuOpen(false);
    },
    onSwipedRight: () => {
      setForceMenuOpen(true);
    },
  });

  const history = useHistory();

  const _getUnreadMsg = async () => {
    try {
      const response = await getUnreadMsg();
      setNotifications(_.uniqBy(response, "sentFrom._id"));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    _getUnreadMsg();
  }, [chatModal]);

  const extensions = [
    {
      icon: "list",
      text: t("SideBarMenu.surveys"),
      url: "/Forms",
      active: user?.extensions?.find((el) => el.name === "Mes questionnaires")
        ?.active,
    },
    {
      icon: "template",
      text: t("SideBarMenu.templates"),
      url: "/pdf",
      active: user?.extensions?.find((el) => el.name === "Mes templates")
        ?.active,
    },
  ];

  const menus = [
    {
      icon: "union",
      text: t("SideBarMenu.Dashboard"),
      url: "/",
    },
    {
      icon: "patient",
      text: t("SideBarMenu.Patients"),
      url: "/mes-patients",
    },
    {
      icon: "agenda",
      text: t("SideBarMenu.agenda"),
      url: "/agenda",
    },
    {
      icon: "lesSoignants",
      text: t("SideBarMenu.Specialists"),
      url: "/specialist",
    },
    {
      icon: "video",
      text: t("SideBarMenu.teleconsultations"),
      url: "/mes-teleconsultations",
    },
    {
      icon: "plugin",
      text: t("SideBarMenu.Extensions"),
      url: "/extension",
    },
    ...extensions?.filter((el) => el.active),
    {
      icon: "monCompte",
      text: t("SideBarMenu.Profile"),
      url: "/profile",
    },
  ];

  const handleLogout = async () => {
    try {
      await logout();
      setPath("/");
      window.location.href = "/";
      // localStorage.clear();
    } catch (error) {
      window.location.href = "/";
      //  localStorage.clear();
      console.error(error);
    }
  };

  const _doctorSeenNotification = async (id) => {
    try {
      const response = await doctorSeenNotification(id);
      setUser({ ...user, notification: response.notification });
    } catch (error) {}
  };

  const parseImage = (url, doctorId, type) => ({
    original: loadImage(doctorId, type, "lossless", url),
  });

  useEffect(() => {
    setPath(window.location.pathname);

    const handleWindowResize = () => {
      setMenuToggleIsActive(window.innerWidth <= screenBreakpoints.md);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && _.isEmpty(user.stripeAccount)) {
      history.push("/profile");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setWidthPage(document.body.clientWidth);
  }, [document.body.clientWidth]);

  return (
    <>
      <ChatModal
        chatWithId={chatModal}
        showModal={!!chatModal}
        closeChatModal={() => setChatModal(undefined)}
      />
      <div className="sidebar-doctor sidebar z-40 ">
        <div
          className={`swipe-events-container ${
            !menuToggleIsActive || !forceMenuOpen ? "" : "swipeable-fullscreen"
          }`}
          {...swipeEventsHandlers}
        />
        <div
          className={`sidebar-container absolute transform md:relative md:translate-x-0 transition duration-200 ease-in-out ${
            menuToggleIsActive ? "mobile-sidebar" : "bg-transparent"
          } ${menuToggleIsActive && !forceMenuOpen ? "-translate-x-full" : ""}`}
        >
          <div className="relative">
            {/* <!-- SIDEBAR --> */}
            {/* mobile menu */}
            <div className="flex justify-between md:hidden absolute mobile-menu-burger">
              <button
                className="p-4 focus:outline-none"
                onClick={() => {
                  setForceMenuOpen(!forceMenuOpen);
                }}
              >
                <Icon isIcon={true} name={forceMenuOpen ? "close" : "menu"} />
              </button>
            </div>
            {/* mobile menu END */}
            <div className="flex items-center space-x-3 relative notification ">
              {user?.business_photo ? (
                <div className="avatar-doctor relative">
                  <img
                    crossOrigin="anonymous"
                    referrerPolicy="origin"
                    src={
                      parseImage(user.business_photo, user._id, "profile")
                        .original
                    }
                    alt="doctor avatar relative "
                    className="rounded-lg w-full h-full object-cover"
                  />
                  {(!_.isEmpty(notification) ||
                    !_.isEmpty(
                      user?.notification?.filter((el) => !el.seen)
                    )) && (
                    <Icon
                      isIcon={true}
                      className="absolute -right-3 -top-3"
                      name="notif-deconnexion"
                    />
                  )}
                </div>
              ) : (
                <div
                  className="avatar-doctor relative"
                  style={{ backgroundColor: colorAvatar }}
                >
                  {user?.sexe === "woman" ? (
                    <WomanPatient className="-mt-2" />
                  ) : (
                    <ManPatient className="-mt-2" />
                  )}
                  {(!_.isEmpty(notification) ||
                    !_.isEmpty(
                      user?.notification?.filter((el) => !el.seen)
                    )) && (
                    <Icon
                      isIcon={true}
                      className="absolute -right-3 -top-3"
                      name="notif-deconnexion"
                    />
                  )}
                </div>
              )}

              <div className="w-full">
                <span className="menu capitalize">
                  {user?.firstName} {user?.lastName}
                </span>
                <p className="docter-center">
                  {handleSpeciality(
                    user?.speciality,
                    i18n.language.includes("en")
                  )}
                </p>
              </div>

              {(!_.isEmpty(notification) ||
                !_.isEmpty(user?.notification?.filter((el) => !el.seen))) &&
                !_.isEmpty(user.stripeAccount) && (
                  <div className="w-64 max-h-96 bg-white absolute top-10 overflow-auto rounded-2xl  notification-list  ">
                    {!_.isEmpty(notification) &&
                      notification.map((el) => {
                        return (
                          <div
                            className=" flex space-x-3 items-center p-2 hover:bg-blue-100 cursor-pointer"
                            onClick={() => setChatModal(el?.sentFrom?._id)}
                            key={el?._id}
                          >
                            <p className="w-14 h-10  rounded-full bg-blue-500 flexCenter text-white text-bold">
                              {(el?.sentFrom?.lastName ||
                                "")[0]?.toUpperCase() +
                                (el?.sentFrom?.firstName ||
                                  "")[0]?.toUpperCase()}
                            </p>
                            <p>
                              {el?.sentFrom?.lastName +
                                " " +
                                el?.sentFrom?.firstName}{" "}
                              {t("SideBarMenu.sentMessage")}
                            </p>
                          </div>
                        );
                      })}

                    {!_.isEmpty(user?.notification?.filter((el) => !el.seen)) &&
                      user.notification
                        .filter((el) => !el.seen)
                        .map((el) => (
                          <div
                            className=" flex space-x-3 items-center p-2 hover:bg-blue-100 cursor-pointer"
                            onClick={() => {
                              _doctorSeenNotification(el.surveyId);
                              history.push("/forms");
                            }}
                          >
                            <p className="w-14 h-10  rounded-full bg-blue-500 flexCenter text-white text-bold">
                              {el.patientFirstName[0].toUpperCase() +
                                el.patientLastName[0].toUpperCase()}
                            </p>
                            <p>
                              {el.patientFirstName + " " + el.patientLastName} a
                              {t("SideBarMenu.AnsweredYourSurvey")}{" "}
                              {el.surveyName}
                            </p>
                          </div>
                        ))}
                  </div>
                )}
            </div>

            <div className="relative flex w-full flex-wrap items-stretch md:mb-3 md:mt-6 mb-2 mt-3">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 bg-transparent rounded text-base items-center justify-center w-8 pl-1 py-1">
                <IconSearch />
              </span>
              <Input
                type="text"
                placeholder={t("Survey.FindPatient")}
                width="w-full"
                onChange={(e) => setPatientName(e.target.value)}
                onKeyPress={(event) => {
                  if (event.key === "Enter")
                    history.push({
                      pathname: "/mes-patients",
                      state: patientName,
                    });
                }}
                disabled={_.isEmpty(user.stripeAccount)}
                className={classNames(
                  "rounded-xl px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white text-sm outline-none focus:border-blue-500  border-blue-100 w-full pl-10",
                  _.isEmpty(user.stripeAccount) && "cursor-not-allowed"
                )}
              />
            </div>

            <nav
              className={classNames(
                "mt-3",
                _.isEmpty(user.stripeAccount) && "cursor-not-allowed"
              )}
            >
              {menus.map((menu) => {
                return (
                  <div className={path === menu.url ? "selected-menu" : ""}>
                    <Link
                      to={menu.url}
                      className={classNames(
                        "flex items-center space-x-3 md:py-3 -py-1",
                        _.isEmpty(user.stripeAccount) && " pointer-events-none"
                      )}
                      onClick={() => setPath(menu.url)}
                    >
                      <div className="w-12 h-12 flexCenter ">
                        {menu.icon === "template" ? (
                          <img
                            crossOrigin="anonymous"
                            referrerPolicy="origin"
                            src={require("../../assets/templates.png").default}
                            width={25}
                            alt="template"
                          />
                        ) : menu.icon === "list" ? (
                          <img
                            crossOrigin="anonymous"
                            referrerPolicy="origin"
                            src={require("../../assets/list.svg").default}
                            width={25}
                            alt="template"
                          />
                        ) : (
                          <Icon isIcon={true} name={menu.icon} />
                        )}
                      </div>
                      <span className="my-auto menu">{menu.text}</span>
                    </Link>
                  </div>
                );
              })}
              <div className={""}>
                <span
                  className="flex items-center space-x-3 md:py-3 py-1 cursor-pointer"
                  onClick={() => {
                    setTryToLogout(true);
                  }}
                >
                  <div className="w-12 h-12 flexCenter">
                    <img
                      crossOrigin="anonymous"
                      referrerPolicy="origin"
                      src={require("../../assets/images/logout.png").default}
                      width="22"
                      alt="logout"
                    />
                  </div>
                  <span className="my-auto menu">
                    {" "}
                    {t("SideBarMenu.disconnect")}
                  </span>
                </span>
              </div>
              {tryToLogout && (
                <div className="w-full  bg-white mt-1 md:mt-3 shadow p-2 rounded-lg slide-left">
                  <p className="card-subtitle text-center mb-3">
                    {t("SideBarMenu.ConfirmLogout")}
                  </p>
                  <div className="flexCenter space-x-3">
                    <Button
                      status="secondary"
                      width="100px"
                      height="35px"
                      onClick={() => setTryToLogout(false)}
                    >
                      {t("SideBarMenu.Cancel")}
                    </Button>
                    <Button width="100px" height="35px" onClick={handleLogout}>
                      {t("SideBarMenu.Confirm")}
                    </Button>
                  </div>
                </div>
              )}
              {/* {extraMenu()} */}
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBarDoctor;
