import { Button, Heading, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useForm } from "../../../../hooks/FormGeneratorHooks";

export const PageHeader = ({ children, onReset = () => {} }) => {
  const form = useForm({ subscribe: false });
  const { t } = useTranslation() as any;
  return (
    <Box mb="6" data-test="header">
      <Heading alignItems="center">{children}</Heading>
      <Button
        onClick={() => {
          form.reset();
          onReset();
        }}
        ml="auto"
        size="sm"
      >
        {t("Survey.ResetForm")}
      </Button>
    </Box>
  );
};
