import { Grid, GridItem } from "@chakra-ui/react";
import Rules from "./RulesValidations";
import { Repeater } from "./RepeaterOptions";
import { RepeaterForLignes } from "./RepeaterLgnes";
import { FormElement } from "../../Elements";
import { SwitchInput } from "../../Elements/FieldSwitcher";
import { FormMethods } from "../../../../hooks/FormGeneratorHooks/types/form.types";

const camel2title = (camelCase) => {
  if (Array.isArray(camelCase)) camelCase = camelCase[camelCase.length - 1];
  if (typeof camelCase === "string")
    return camelCase
      .replace(/([A-Z])/g, (match) => ` ${match}`)
      .replace(/^./, (match) => match.toUpperCase())
      .trim();
  else return camelCase;
};
interface Props {
  name: any;
  value: any;
  form?: FormMethods;
  elements?: any;
  selectedElm?: any;
  t?: any;
  setField?: any;
  elment?: any;
}
const RenderField = ({
  name,
  value,
  form,
  elements,
  selectedElm,
  t,
  setField,
  elment,
}: Props) => {
  let type = "TextArea";
  let options: any = [];
  switch (typeof value) {
    case "boolean":
      options = [
        { label: t("SurveyIntl.Yes"), value: true },
        { label: t("SurveyIntl.No"), value: false },
      ];
      return (
        <Grid
          width={"60%"}
          templateColumns="repeat(2, 1fr)"
          alignItems={"baseline"}
          gap={1}
        >
          <GridItem fontSize={"16px"} w="170px" h="10">
            {" "}
            <span className=" ">{t("SurveyIntl.name")}</span>
          </GridItem>
          <GridItem w="100%" h="10" className="flex  text-xs text-gray-500  ">
            <GridItem>
              <span className="p-2  card-subtitle">
                {t("Survey.Deactivate")}
              </span>
            </GridItem>
            <GridItem>
              {" "}
              <SwitchInput value={value} name={name} />
            </GridItem>
            <GridItem>
              <span className="p-2 card-subtitle">{t("Survey.Activate")}</span>
            </GridItem>
          </GridItem>
        </Grid>
      );
    case "string":
      if (name === "content") type = "Editor";
      else if (name === "src") {
        type = "Photo2";
      } else type = "TextInput";

      return (
        <FormElement
          element={{
            field_name: name,
            options: options,
            label: t("SurveyIntl.name"),
            type: type,
          }}
          selectedElm={selectedElm}
          value={value}
          readOnly={false}
          form={form}
          required={name === "label" ? true : false}
        />
      );
    case "number":
      type = "NumberInput";
      return (
        <FormElement
          key={name}
          value={value}
          element={{
            field_name: name,
            options: options,
            label: camel2title(t("SurveyIntl.name")),
            type: type,
          }}
          readOnly={false}
          form={form}
        />
      );
    case "object":
      if (name === "options") {
        return <Repeater key={name} form={form} value={value} />;
      }
      if (name === "lignes") {
        return <RepeaterForLignes key={name} form={form} value={value} />;
      }

      if (name === "conditionLogic") {
        type = "conditionLogic";
        options = [
          { label: t("SurveyIntl.Yes"), value: "true" },
          { label: t("SurveyIntl.No"), value: "false" },
        ];

        return (
          <FormElement
            selectedElm={selectedElm}
            elements={elements}
            value={value}
            element={{
              field_name: name,
              label: t("SurveyIntl.name"),
              type: type,
            }}
            readOnly={false}
            form={form}
          />
        );
      } else if (name === "src") {
        type = "Photo2";
        return (
          <FormElement
            element={{
              field_name: name,
              options: options,
              label: t("SurveyIntl.name"),
              type: type,
            }}
            selectedElm={selectedElm}
            value={value}
            readOnly={false}
            form={form}
            required={name === "label" ? true : false}
          />
        );
      } else if (name === "rules") {
        return (
          <Rules
            key={name}
            name={name}
            disabled={false}
            value={value}
            element={elment}
            selectedElm={selectedElm}
            elements={elements}
            form={form}
          />
        );
      } else break;

    default:
      type = "TextArea";
      return (
        <FormElement
          key={name}
          value={value}
          element={{
            field_name: name,
            options: options,
            label: camel2title(name),
            type: type,
          }}
          readOnly={undefined}
          form={form}
        />
      );
  }
};

export { RenderField };
