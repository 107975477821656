import React, { useEffect, useRef, useState, MutableRefObject } from "react";
import io from "socket.io-client";
import { isEmpty } from "lodash";
import Peer from "simple-peer";
import styled from "styled-components";
import { FiVideoOff, FiVideo } from "react-icons/fi";
import { AiOutlineAudioMuted, AiOutlineAudio } from "react-icons/ai";

const KatomiEndPoint =
  process.env.REACT_APP_KATOMI_API_URL || "http://localhost:8080";

const Container = styled.div`
  height: 100vh;
  width: 20%;
`;

const Controls = styled.div`
  margin: 3px;
  padding: 5px;
  height: 27px;
  width: 98%;
  background-color: rgba(255, 226, 104, 0.1);
  margin-top: -8.5vh;
  filter: brightness(1);
  z-index: 1;
  border-radius: 6px;
`;

const ControlSmall = styled.div`
  margin: 3px;
  padding: 5px;
  height: 16px;
  width: 98%;
  margin-top: -6vh;
  filter: brightness(1);
  z-index: 1;
  border-radius: 6px;
  display: flex;
  justify-content: center;
`;

const ImgComponent = styled.img`
  cursor: pointer;
  height: 25px;
`;

const ImgComponentSmall = styled.img`
  height: 15px;
  text-align: left;
  opacity: 0.5;
`;

const StyledVideo = styled.video`
  width: 100%;
  position: static;
  border-radius: 10px;
  overflow: hidden;
  margin: 1px;
  border: 5px solid gray;
`;

const Video = (props) => {
  const ref: MutableRefObject<HTMLVideoElement | null | undefined> = useRef();

  useEffect(() => {
    !isEmpty(props.peer) &&
      props.peer.on("stream", (stream) => {
        if (ref?.current) ref.current.srcObject = stream;
      });
  }, []);

  return <StyledVideo playsInline autoPlay ref={ref} />;
};

const Room = (props) => {
  const [peers, setPeers] = useState([]) as any;
  const [audioFlag, setAudioFlag] = useState(true);
  const [videoFlag, setVideoFlag] = useState(true);
  const [userUpdate, setUserUpdate] = useState([]) as any;
  const socketRef = useRef() as any;
  const userVideo = useRef() as any;
  const peersRef = useRef([]) as any;
  const roomID = "ROOM_ID";
  const socketIO = io as any;

  const videoConstraints = {
    minAspectRatio: 1.333,
    minFrameRate: 60,
    height: window.innerHeight / 1.8,
    width: window.innerWidth / 2,
  };
  useEffect(() => {
    socketRef.current = socketIO.connect(KatomiEndPoint, {
      withCredentials: true,
    });
    createStream();
  }, []);

  function createStream() {
    navigator.mediaDevices
      .getUserMedia({ video: videoConstraints, audio: true })
      .then((stream) => {
        userVideo.current.srcObject = stream;
        socketRef.current.emit("join room", roomID);
        socketRef.current.on("all users", (users) => {
          console.log(users);
          const peers = [] as any;
          users.forEach((userID) => {
            const peer = createPeer(userID, socketRef.current.id, stream);
            peersRef.current.push({
              peerID: userID,
              peer,
            });
            peers.push({
              peerID: userID,
              peer,
            });
          });
          setPeers(peers);
        });
        socketRef.current.on("user joined", (payload) => {
          console.log("==", payload);
          const peer = addPeer(payload.signal, payload.callerID, stream);
          peersRef.current.push({
            peerID: payload.callerID,
            peer,
          });
          const peerObj = {
            peer,
            peerID: payload.callerID,
          };
          setPeers((users) => [...users, peerObj]);
        });

        socketRef.current.on("user left", (id) => {
          const peerObj = peersRef.current.find((p) => p.peerID === id);
          if (peerObj) {
            peerObj.peer.destroy();
          }
          const peers = peersRef.current.filter((p) => p.peerID !== id);
          peersRef.current = peers;
          setPeers(peers);
        });

        socketRef.current.on("receiving returned signal", (payload) => {
          const item = peersRef.current.find((p) => p.peerID === payload.id);
          item.peer.signal(payload.signal);
        });

        socketRef.current.on("change", (payload) => {
          setUserUpdate(payload);
        });
      });
  }

  function createPeer(userToSignal, callerID, stream) {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream,
    });

    peer.on("signal", (signal) => {
      socketRef.current.emit("sending signal", {
        userToSignal,
        callerID,
        signal,
      });
    });

    return peer;
  }

  function addPeer(incomingSignal, callerID, stream) {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream,
    });

    peer.on("signal", (signal) => {
      socketRef.current.emit("returning signal", { signal, callerID });
    });

    peer.signal(incomingSignal);

    return peer;
  }

  const audioFlagToggle = () => {
    if (userVideo.current.srcObject) {
      userVideo.current.srcObject.getTracks().forEach(function (track) {
        if (track.kind === "audio") {
          if (track.enabled) {
            socketRef.current.emit("change", [
              ...userUpdate,
              {
                id: socketRef.current.id,
                videoFlag,
                audioFlag: false,
              },
            ]);
            track.enabled = false;
            setAudioFlag(false);
          } else {
            socketRef.current.emit("change", [
              ...userUpdate,
              {
                id: socketRef.current.id,
                videoFlag,
                audioFlag: true,
              },
            ]);
            track.enabled = true;
            setAudioFlag(true);
          }
        }
      });
    }
  };

  const videoFlagToggle = () => {
    if (userVideo.current.srcObject) {
      userVideo.current.srcObject.getTracks().forEach(function (track) {
        if (track.kind === "video") {
          if (track.enabled) {
            socketRef.current.emit("change", [
              ...userUpdate,
              {
                id: socketRef.current.id,
                videoFlag: false,
                audioFlag,
              },
            ]);
            track.enabled = false;
            setVideoFlag(false);
          } else {
            socketRef.current.emit("change", [
              ...userUpdate,
              {
                id: socketRef.current.id,
                videoFlag: true,
                audioFlag,
              },
            ]);
            track.enabled = true;
            setVideoFlag(true);
          }
        }
      });
    }
  };

  return (
    <Container>
      <StyledVideo muted ref={userVideo} autoPlay playsInline />
      <Controls>
        {!videoFlag ? (
          <FiVideoOff onClick={videoFlagToggle} />
        ) : (
          <FiVideo onClick={videoFlagToggle} />
        )}
        {!audioFlag ? (
          <AiOutlineAudioMuted onClick={audioFlagToggle} />
        ) : (
          <AiOutlineAudio onClick={audioFlagToggle} />
        )}
      </Controls>
      <div className="mt-28"></div>
      {peers.map((peer, index) => {
        let audioFlagTemp = true;
        let videoFlagTemp = true;
        if (userUpdate) {
          userUpdate.forEach((entry) => {
            if (peer && peer.peerID && peer.peerID === entry.id) {
              audioFlagTemp = entry.audioFlag;
              videoFlagTemp = entry.videoFlag;
            }
          });
        }
        return (
          <div key={peer.peerID} className="border-4 border-red-400">
            <Video peer={peer.peer} />
            <ControlSmall>
              {videoFlagTemp ? (
                <FiVideoOff onClick={videoFlagToggle} />
              ) : (
                <FiVideo onClick={videoFlagToggle} />
              )}
              {/* <ImgComponentSmall src={videoFlagTemp ? webcam : webcamoff} /> */}
              &nbsp;&nbsp;&nbsp;
              {audioFlagTemp ? (
                <AiOutlineAudioMuted onClick={audioFlagToggle} />
              ) : (
                <AiOutlineAudio onClick={audioFlagToggle} />
              )}
              {/* <ImgComponentSmall src={audioFlagTemp ? micunmute : micmute} /> */}
            </ControlSmall>
          </div>
        );
      })}
    </Container>
  );
};

export default Room;
