export const MyTeleconsultation = {
  fr: {
    MyTeleconsultations: "Mes téléconsultations",
    NoTeleconsultationFound: "Vous n’avez effectué aucune téléconsultation.",
    ConsultationOf: "Consultation du",
    Billing: "Facturation",
    B: "F",

    SuccessPayment: "Le paiement a été effectué avec succès.",
    ErrorStripe:
      "Une erreur est survenue lors du paiement, Veuillez terminer la validation de votre compte stripe",
    ErrorPayment:
      "Une erreur est survenue lors du paiement. Veuillez réessayer ultérieurement.",
    SuccessCancel: "Le paiement a été annulé avec succès.",
    ErrorCancel:
      "Une erreur est survenue lors de l'annulation du paiement. Veuillez réessayer ultérieurement.",
    SuccessRefund: "Le remboursement a été effectué avec succès.",
    ErrorRefound:
      "Une erreur est survenue lors du remboursement. Veuillez réessayer ultérieurement.",

    Refound: "Rembourser",
    R: "R",
    Cancel: "Annuler",
    C: "A",
    ThirdPartyPayment: "Tiers Payant",
    TPP: "TPP",
  },
  en: {
    MyTeleconsultations: "My teleconsultations",
    NoTeleconsultationFound: "You have not carried out any teleconsultation.",
    ConsultationOf: "Consultation of",
    Billing: "Billing",
    B: "B",
    SuccessPayment: "The payment has been made successfully.",
    ErrorStripe:
      "An error occurred during payment, Please complete the validation of your stripe account",
    ErrorPayment: "An error occurred during payment. Please retry later.",
    SuccessCancel: "The payment was successfully cancelled.",
    ErrorCancel:
      "An error occurred while canceling the payment. Please retry later.",
    SuccessRefund: "The refund was successfully completed.",
    ErrorRefound: "An error occurred during the refund. Please retry later.",
    Refound: "Refound",
    R: "R",
    Cancel: "Cancel",
    C: "C",
    ThirdPartyPayment: "Third-party payment",
    TPP: "TPP",
  },
};
