import axios from "axios";
import {
  INITIATE_PAYMENT_SANDBOX,
  LOAD_GATEWAY_SANDBOX,
  CHECK_PAYMENT_SANDBOX,
  UPDATE_BILL,
} from "../config/api";

const TOKEN = process.env.REACT_APP_KATOMI_PAYMEE_TOKEN;

export const initiatePayment = async ({ vendor, amount, note }) => {
  try {
    const res = await axios.post(`${INITIATE_PAYMENT_SANDBOX}`, {
      vendor,
      amount,
      note,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// If the buyer uses the wallet to pay, the buyer_id will be filled. Otherwise buyer_id is 0.
export const checkPayment = async (token) => {
  try {
    const res = await axios.get(`${CHECK_PAYMENT_SANDBOX(token)}`, {
      headers: { Authorization: TOKEN },
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateBill = async (katomiTNBilled) => {
  try {
    const res = await axios.post(`${UPDATE_BILL}`, katomiTNBilled);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const gatewayIFrame = async (token) => {
  try {
    const res = await axios.get(`${LOAD_GATEWAY_SANDBOX(token)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
