import axios from "axios";

import {
  SEND_CONSULTATION_BY_PATIENT,
  GET_UNREAD_MSG,
  MESSAGE_PATIENTS_LIST,
  MESSAGE_DOCTORS_LIST,
} from "../config/api";

export async function sendConsultationToDoctorByPatient(values) {
  try {
    const res = await axios.post(SEND_CONSULTATION_BY_PATIENT, values, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getUnreadMsg() {
  try {
    const res = await axios.get(GET_UNREAD_MSG, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function messagesPatientLists() {
  try {
    const res = await axios.get(MESSAGE_PATIENTS_LIST, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function messagesDoctorLists() {
  try {
    const res = await axios.get(MESSAGE_DOCTORS_LIST, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
