import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
/**
 * Helper & API
 */
import { userAtom } from "../../state/auth";
import {
  addProfilePhotoToPatient,
  loadImage,
  DeletePatientProfilePicture,
} from "../../api/documents";
import moment from "../../helper/moment";
/**
 * Assets
 */
import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";
import birthday from "../../assets/images/birthday.png";
import telphoto from "../../assets/images/phone.png";
import home from "../../assets/images/home.png";
import UploadImg from "../../assets/images/upload.png";

const PatientDetails = ({ colorAvatar }) => {
  const { t } = useTranslation();
  const [patient, setPatient] = useAtom(userAtom) as any;

  const _uploadPicture = async (fileProfile) => {
    try {
      const response = await addProfilePhotoToPatient({
        patientId: patient?._id,
        file: fileProfile,
      });
      setPatient({
        ...patient,
        photo: response.patient.photo,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangePicture = (e) => {
    _uploadPicture(e?.target?.files[0]);
  };
  const handleDeleteProfilePicture = async (e) => {
    try {
      await DeletePatientProfilePicture();
      setPatient({
        ...patient,
        photo: null,
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="card-patient-details flex space-x-3">
      <div
        className="avatar rounded-full"
        style={{ backgroundColor: patient.photo ? "" : colorAvatar }}
      >
        <div className="photo-profile relative">
          {patient.photo ? (
            <img
              crossOrigin="anonymous"
              referrerPolicy="origin"
              src={loadImage(
                patient._id,
                "profile",
                "thumbnail",
                patient.photo
              )}
              alt="doctor avatar"
              className="rounded-full w-full h-full"
            />
          ) : patient?.sexe === "woman" ? (
            <WomanPatient />
          ) : (
            <ManPatient />
          )}
          <div className="hide text-center hidden absolute inset-0 bg-opacity-40 bg-blue-500 rounded-full">
            <div className="flexCenter h-full">
              <img
                crossOrigin="anonymous"
                referrerPolicy="origin"
                src={UploadImg}
                alt="upload"
                style={{ width: "20px", height: "20px" }}
              />
            </div>
            <input
              type="file"
              name="profile"
              className="absolute inset-0 opacity-0"
              onChange={handleChangePicture}
            />
          </div>
        </div>
        {patient.photo && (
          <p
            className="text-red-500 text-center  cursor-pointer"
            onClick={handleDeleteProfilePicture}
          >
            {t("ProfilePatient.Delete")}
          </p>
        )}
      </div>
      <div className="flex flex-col justify-between">
        <h2 className="capitalize">
          {patient?.firstName} {patient?.lastName}
        </h2>
        <div className="flex space-x-3">
          <div className="flexCenter img">
            <img
              crossOrigin="anonymous"
              referrerPolicy="origin"
              src={birthday}
              alt="birthday"
              width={15.5}
              height={3.8}
            />
          </div>

          <div className="courant-text">
            <p>
              {moment(patient?.birthDate).format("DD/MM/YYYY")} (
              {moment().diff(moment(patient?.birthDate), "years", false)} ans)
            </p>
          </div>
        </div>

        <div className="flex space-x-3">
          <span className="text-sm flex space-x-2 items-center">
            <img
              crossOrigin="anonymous"
              referrerPolicy="origin"
              src={telphoto}
              alt="phone"
              width="15.5px"
              height="15.5px"
            />
            <span>
              {`${patient?.phoneSuffixe ? patient?.phoneSuffixe : ""} ${
                patient?.phone ? patient?.phone : ""
              }`}
            </span>
          </span>
          <span className="text-sm flex space-x-2 items-center">
            <img
              crossOrigin="anonymous"
              referrerPolicy="origin"
              src={home}
              alt="home"
              width="14.64px"
              height="14.64px"
            />
            <span>{patient?.address}</span>
          </span>
        </div>
        {patient?.numSecSocial && (
          <div className="space-x-2 text-sm">
            <span className="font-medium">
              {t("ProfilePatient.SocialSecurityNumber")} :{" "}
            </span>
            <span>{patient?.numSecSocial}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PatientDetails;
