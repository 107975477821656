import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../../components/Icon";
import langue from "../../assets/images/langue.png";
import { userAtom } from "../../state/auth";
import { useAtom } from "jotai";
import { User } from "../../types/user";
import ModifySites from "./modal/ModifySites";
import Modal from "../../components/ModalUI";

const Sites: React.FC = () => {
  const { t } = useTranslation() as any;
  const [openModal, setOpenModal] = useState(false);
  const [user] = useAtom<User | null>(userAtom);

  return (
    <div className="sites">
      <div className="headerContainer flex items-center relative">
        <img
          crossOrigin="anonymous"
          referrerPolicy="origin"
          src={langue}
          className="svgClass"
          alt="chat"
        />
        <p className="header"> {t("Profile.SITESAndNETWORKS")}</p>
        <p
          className="absolute right-0	modifier"
          onClick={() => setOpenModal(true)}
        >
          {t("Profile.Update")}
        </p>
      </div>
      <div className="containerSites">
        <div className="boxSite flex">
          <div className="svgContainer">
            <Icon name={"laptop"} isIcon={false} />
          </div>
          <p>{user?.website}</p>
        </div>
        <div className="boxSite flex">
          <div className="svgContainer">
            <Icon name={"linkedin"} isIcon={false} />
          </div>
          <p>{user?.linkedin}</p>
        </div>
        <div className="boxSite flex">
          <div className="svgContainer">
            <Icon name={"facebook"} isIcon={false} />
          </div>
          <p>{user?.facebook}</p>
        </div>
        <div className="boxSite flex">
          <div className="svgContainer">
            <Icon name={"instagram"} isIcon={false} />
          </div>
          <p>{user?.instagram}</p>
        </div>
      </div>

      <Modal closeModal={() => setOpenModal(false)} showModal={openModal}>
        <ModifySites openModal={openModal} setOpenModal={setOpenModal} />
      </Modal>
    </div>
  );
};

export default Sites;
