import { loadImage } from "../../api/documents";
import moment from "../../helper/moment";
import _ from "lodash";

import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";

import birthday from "../../assets/images/birthday.png";
import phone from "../../assets/images/phone.png";
import homeIcon from "../../assets/images/home.png";
import mailIcon from "../../assets/images/mail.png";
import handiCap from "../../assets/images/handicap.png";

const PatientDetails = ({ user }) => {
  const {
    gender,
    age,
    numberPhone,
    adress,
    mail,
    firstName,
    lastName,
    photo,
    id,
    birthDate,
    handicap,
  } = user;
  return (
    <div className="card-patient-details flex space-x-3 ">
      <div className="avatar w-24 h-24 rounded-lg flexCenter">
        {photo ? (
          <img
            crossOrigin="anonymous"
            referrerPolicy="origin"
            alt="profile"
            src={loadImage(id, "profile", "thumbnail", photo)}
            className="w-24 h-24 rounded-lg shadow-md object-fill"
          />
        ) : gender === "woman" ? (
          <WomanPatient className="w-24 h-24 bg-purple-300" />
        ) : (
          <ManPatient className="w-24 h-24 bg-purple-300" />
        )}
      </div>
      <div className="flex flex-col justify-between">
        <div className="leading-none capitalize">
          <h2 className="mb-2">
            {firstName} {lastName}
          </h2>
          <div className="flex space-x-3">
            <div className="flexCenter img">
              <img
                crossOrigin="anonymous"
                referrerPolicy="origin"
                src={birthday}
                alt="birthday"
                width={15.5}
                height={3.8}
              />
            </div>
            <div className="courant-text">
              <p>
                {moment(birthDate).calendar()} ({age} ans)
              </p>
            </div>
          </div>
        </div>
        <div className="leading-none">
          <div className="flex lg:space-x-10 flex-wrap lg:flex-row flex-col">
            <span className="text-sm flex space-x-2 items-center">
              <img
                crossOrigin="anonymous"
                referrerPolicy="origin"
                src={phone}
                alt="phone"
                style={{ width: "15.5px", height: "15.5px" }}
              />
              <span>{numberPhone}</span>
            </span>
            <span className="text-sm flex space-x-2 items-center">
              <img
                crossOrigin="anonymous"
                referrerPolicy="origin"
                src={mailIcon}
                alt="mail"
                style={{ width: "15px", height: "14px" }}
              />
              <span>{mail}</span>
            </span>
          </div>
        </div>
        <span className="text-sm flex space-x-2 items-center">
          <img
            crossOrigin="anonymous"
            referrerPolicy="origin"
            src={homeIcon}
            alt="home"
            style={{ width: "14.64px", height: "14.64px" }}
          />
          <span>{adress}</span>
        </span>
        {!_.isEmpty(handicap) && (
          <div className="flex mb-2">
            <div className="flexCenter mt-1">
              <div className="flexCenter img">
                <img
                  crossOrigin="anonymous"
                  referrerPolicy="origin"
                  src={handiCap}
                  alt="handicap"
                  width="18px"
                  height="18px"
                />
              </div>
              <div>
                {handicap.map((el) => (
                  <span>{el}</span>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PatientDetails;
