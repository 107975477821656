/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "../ButtonUI";
import Icon from "../Icon";
import "./styles.scss";
import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";

import SharedChat from "../../components/SharedChat";
import { me } from "../../api/patient";
import { loadImage } from "../../api/documents";
import { sendConsultationToDoctorByPatient } from "../../api/messages";
import makeToast from "../Snackbar";
import Fuse from "fuse.js";
import Input from "../Input";
import { ReactComponent as IconSearch } from "../../assets/icons/icon-search.svg";
import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";
import { useTranslation } from "react-i18next";
import { handleSpeciality } from "../../helper/handleSpeciality";

interface Props {
  setModal: Function;
  openModal: boolean;
  data?: {} | any;
  taskId: string;
  setChatWithId: Function;
  prescriptionId: String;
  doctorIdOfConsultation: string;
}

const TransmettreDocumentPDF: React.FC<Props> = ({
  setModal,
  openModal,
  data,
  taskId,
  doctorIdOfConsultation,
  prescriptionId,
  setChatWithId,
}) => {
  const { i18n } = useTranslation();
  const [openChatModal, setOpenChatModal] = useState<boolean>(false);
  const [spcialityList, setSpecialityList] = useState<any>([]);
  const [loading, setLoading] = useState<any>(true);
  const [spcialityListFiltred, setSpecialityListFiltered] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>("");

  const [consultationToDoctor, setConsultationToDoctor] = useState<any>({
    taskId,
    doctorId: "",
    typeDocument: "shareOrder",
    prescriptionId,
  });

  useEffect(() => {
    async function getOtherDoctByPatientId() {
      const response = await me();
      setSpecialityList(response.doctors);
      setLoading(false);
    }
    getOtherDoctByPatientId();
  }, []);

  useEffect(() => {
    async function consultationDoctor() {
      await sendConsultationToDoctorByPatient(consultationToDoctor);
      setModal(false);
      setChatWithId(consultationToDoctor.doctorId);
      makeToast("success", "Transmis avec succès");
      setOpenChatModal(true);
    }
    if (consultationToDoctor.doctorId !== "") {
      consultationDoctor();
    }
  }, [consultationToDoctor]);

  const combineAllKeyValues = (obj, separator) => {
    separator = separator || " ";
    obj.fullName = obj.firstName + separator + obj.lastName;
    return obj;
  };
  const onSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    const doctors = spcialityList.map((s) => combineAllKeyValues(s, " "));
    const fuse = new Fuse(doctors, {
      threshold: 0.1,
      shouldSort: true,
      includeMatches: true,
      keys: ["firstName", "lastName", "fullName", "speciality"],
    });
    const newList = fuse.search(value).map((item) => item.item);
    setSpecialityListFiltered(newList);
  };

  // useEffect(() => {
  //   async function getOtherDoctByPatientId(){
  //     const response = await getOtherDoctors(user?._id)
  //     setSpecialityList(response.retrieveAllOtherDoctors)
  //     setLoading(false);
  //   }
  //   getOtherDoctByPatientId()
  // }, []);

  const parseImage = (url, id, type) => ({
    original: loadImage(id, type, "lossless", url),
  });

  const renderElements = (e) => {
    return (
      <div className="flex justify-between items-center mb-4 container gap-4">
        <div className="hidden md:block">
          {e?.business_photo ? (
            <img
              crossOrigin="anonymous"
              referrerPolicy="origin"
              src={parseImage(e.business_photo, e._id, "profile").original}
              alt="profile doctor"
            />
          ) : e?.sexe === "woman" ? (
            <WomanPatient />
          ) : (
            <ManPatient />
          )}
        </div>
        <div>
          <p className="card-title">
            {e.firstName} {e.lastName}
          </p>
          <h3 className="card-subtitle">
            {handleSpeciality(e.speciality, i18n.language.includes("en"))}
          </h3>
        </div>
        <div>
          <Button
            status="secondary"
            width="155"
            height="50"
            onClick={() => {
              setConsultationToDoctor({
                ...consultationToDoctor,
                doctorId: e._id,
              });
            }}
          >
            <div className="flexCenter">
              <Icon name="export" isIcon={true} />
              Transmettre
            </div>
          </Button>
        </div>
      </div>
    );
  };
  useEffect(() => {}, [openChatModal]);

  return (
    <>
      <SharedChat
        closeChatModal={setOpenChatModal}
        showModal={openChatModal}
        chatWithId={consultationToDoctor.doctorId}
      />
      <div className="absolute inset-0 z-50 outline-none focus:outline-none overflow-x-hidden bg-opacity-modal overflow-y-auto flexCenter">
        <div className="absolute  p-6 z-50 0 transition  ease-in duration-700 patient-transmettre-modal">
          <div className="header">
            <div className="flex justify-end">
              <Button
                status="icon-secondary"
                type="button"
                onClick={() => setModal(false)}
              >
                <Close />
              </Button>
            </div>
            <h1 style={{ fontSize: "26px" }}>Transmettre mon document</h1>
            <div className="search">
              {/* <SearchBar
            options={spcialityList.map((e) => e.firstName)}
            value={selectedDoctor}
            onChange={setSelectedDoctor}
            className="flex-1 w-full"
            placeholder="Médecin, spécialité, etc"
            prefix="impression"
          /> */}

              <div className="relative flex w-full flex-wrap items-stretch mb-3 mt-6 ">
                <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300  bg-transparent rounded text-base items-center justify-center w-8 pl-1 py-1">
                  <IconSearch />
                </span>
                <Input
                  name="search"
                  onChange={onSearch}
                  className="min-w-full rounded-xl px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white  text-sm outline-none focus:border-blue-500  border-blue-100  pl-10"
                  placeholder="Médecin, spécialité, etc"
                  stylesDiv="w-full"
                  type="text"
                />
              </div>
            </div>
            <h3 className="mt-4">Mes soignants</h3>
            <div className="w-full h-full flexCol">
              {loading ? (
                <div className="flexCenter min-h-full">
                  <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
                </div>
              ) : searchValue.length === 0 ? (
                spcialityList
                  ?.filter((e) => e._id !== doctorIdOfConsultation)
                  .map((e: any) => {
                    return renderElements(e);
                  })
              ) : searchValue !== "" && spcialityListFiltred.length === 0 ? (
                <div className="flexCenter mt-8">
                  <h3>{`Pas de soignant n'existe pour ${searchValue}.`}</h3>
                </div>
              ) : (
                spcialityListFiltred
                  ?.filter((e) => e._id !== doctorIdOfConsultation)
                  .map((e: any) => {
                    return renderElements(e);
                  })
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransmettreDocumentPDF;
