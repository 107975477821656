import axios from "axios";

import { GET_ALL_PALIER } from "../config/api";

export async function getAllPalier() {
  try {
    const res = await axios.get(GET_ALL_PALIER, {
      withCredentials: true,
    });

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
