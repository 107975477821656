/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import Tooltip from "../../components/toolTip";
import { Formik } from "formik";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { signupPatient } from "../../api/patient";
import history from "../../history";
import CountryWithPhoneSuffix from "../../config/constants/pays.json";
import Button from "../../components/ButtonUI";
import Input from "../../components/FloatInput";
import SelectComp from "../../components/Select";
import Icon from "../../components/Icon";
import makeToast from "../../components/Snackbar";
import countriesTn from "../../config/constants/countriesTn.json";
import voir from "../../assets/images/voir.png";
import smileSick from "../../assets/images/smileSick.png";
import "./styles.scss";
import { useTranslation } from "react-i18next";

import fr from "date-fns/locale/fr";
registerLocale("fr", fr);
setDefaultLocale("fr");

const dropdownSexeOptions = [
  { value: "men", label: "Homme" },
  { value: "woman", label: "Femme" },
];

// const dropdownSexeINSOptions = [
//   { value: "M", label: "M" },
//   { value: "F", label: "F" },
// ];

const SignupPatient: React.FC = () => {
  const { t } = useTranslation() as any;
  const [passwordFormType1, setPasswordFormType1] = useState("password");
  const [passwordFormType2, setPasswordFormType2] = useState("password");

  // const [paysState, setPaysState] = useState("France");
  const [, setRegionState] = useState([]) as any;
  const [selectedRegionState] = useState() as any;
  const [, setDepartementState] = useState([]) as any;
  const [selectedDepartementState] = useState() as any;
  const [, setCommunesState] = useState([]) as any;
  // const [selectedCommunesState, setSelectedCommunesState] = useState() as any;

  const [villeTnState] = useState("");
  const [, setDelegationTn] = useState([]) as any;
  // const [delegationTnState, setDelegationTnState] = useState("");

  const _getRegion = async () => {
    try {
      fetch("https://geo.api.gouv.fr/regions")
        .then((response) => response.json())
        .then((data) =>
          setRegionState(
            data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
          )
        );
    } catch (error) {}
  };

  const _getDepartementState = async () => {
    try {
      fetch(
        `https://geo.api.gouv.fr/regions/${selectedRegionState?.code}/departements`
      )
        .then((response) => response.json())
        .then((data) =>
          setDepartementState(
            data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
          )
        );
    } catch (error) {}
  };

  const _getCommunesState = async () => {
    try {
      fetch(
        `https://geo.api.gouv.fr/departements/${selectedDepartementState?.code}/communes`
      )
        .then((response) => response.json())
        .then((data) =>
          setCommunesState(
            data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
          )
        );
    } catch (error) {}
  };

  // // Init
  useEffect(() => {
    _getRegion();
  }, []);

  // // Init
  useEffect(() => {
    if (selectedRegionState?.code) _getDepartementState();
  }, [selectedRegionState]);

  // // Init
  useEffect(() => {
    if (selectedDepartementState?.code) _getCommunesState();
  }, [selectedDepartementState]);

  // // Init
  useEffect(() => {
    if (villeTnState) {
      setDelegationTn(
        countriesTn[villeTnState].map((el) => ({
          ...el,
          value: el.delegation,
          label: el.delegation,
        }))
      );
    }
  }, [villeTnState]);

  return (
    <div className="signup-patient min-h-screen flex justify-center">
      <div className="absolute inset-0 katomiWterMark" />
      <div className="auth-Patient-Signup-Container flexColCenter ">
        <div className="formLogin relative">
          <div className="absolute inset-0 pointer-events-none" />
          <div
            className="icon cursor-pointer"
            onClick={() => history.push("/")}
          >
            <Icon
              name="direction-gauche"
              width={50}
              height={50}
              isIcon={true}
            />
          </div>
          <div className="flexRowCenter img-top">
            <img
              crossOrigin="anonymous"
              referrerPolicy="origin"
              src={smileSick}
              width="46px"
              height="46px"
              alt="smileSick"
            />
          </div>
          <div className="title-container flexColCenter">
            <p>{t("SignUp.im")}</p>
            <h1 className="h1 flexRowCenter">{t("SignUp.patient")}</h1>
          </div>
          <div className="">
            <Formik
              initialValues={{
                phone: "",
                lname: "",
                fname: "",
                phone_prefix: "",
                fullname: "",
                password: "",
                confirmPassword: "",
                NumSecSocial: "",
                birthDate: "",
                email: "",
                country: "",
                patient_photo: "",
                sexe: "men",
                phoneSuffix: "",
                addressDetails: "",
                postalCode: "",
                city: "",

                INSSexe: "",
                NameINS: "",
                firstNameINS: "",
                birthAddressINS: "",
                birthDateINS: "",
                INS: "",
              }}
              onSubmit={async (values) => {
                const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                const regexPassword =
                  /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{10,}$/;
                if (!values.fname)
                  return makeToast("warning", t("SignUp.fillName"));
                if (!values.lname)
                  return makeToast("warning", t("SignUp.fillLastName"));
                if (!values.email)
                  return makeToast("warning", t("SignUp.fillEmail"));
                if (!values.password)
                  return makeToast("warning", t("SignUp.fillPSW"));
                if (!values.confirmPassword)
                  return makeToast("warning", t("SignUp.fillConfirmedPSW"));
                if (!values.country)
                  return makeToast("warning", t("SignUp.fillPays"));
                if (!values.phone)
                  return makeToast("warning", t("SignUp.fillPhone"));
                if (!values.birthDate)
                  return makeToast("warning", t("SignUp.fillBirthDate"));
                if (!values.sexe)
                  return makeToast("warning", t("SignUp.fillSexe"));
                if (!values.postalCode)
                  return makeToast("warning", t("SignUp.fillPostalCode"));
                if (!values.city)
                  return makeToast("warning", t("SignUp.fillCity"));

                if (!validEmailRegex.test(values.email))
                  return makeToast("warning", t("SignUp.fillValidEmail"));
                if (!regexPassword.test(values.password))
                  return makeToast("warning", t("SignUp.pswConstraint"));
                if (values.password !== values.confirmPassword)
                  return makeToast(
                    "wating",
                    t("SignUp.pswConfirmedConstraint")
                  );

                values.addressDetails = JSON.stringify({
                  country: values.country,
                });

                values.phoneSuffix = CountryWithPhoneSuffix.find(
                  (el) => el.name === values.country
                )?.dial_code as any;

                await new Promise((resolve) => setTimeout(resolve, 500));
                try {
                  const signedUp = await signupPatient(values);

                  if (signedUp?.status === "existEmail")
                    return makeToast(
                      "warning",
                      t("SignUp.existPatientwithemail")
                    );
                  if (signedUp?.status === "existPhone")
                    return makeToast(
                      "warning",
                      t("SignUp.existPatientwithPhoneNumber")
                    );
                  if (signedUp === "success") {
                    makeToast("success", t("SignUp.SuccessMsg"));
                    history.push({
                      pathname: `/confirm-email/${values.email}`,
                      state: { password: values.password },
                    });
                  } else {
                    makeToast(
                      "warning",
                      t("SignUp.existPatientwithPhoneNumber")
                    );
                  }
                } catch (err) {
                  makeToast("warning", t("SignUp.Error"));
                }
              }}
            >
              {(props) => {
                const {
                  values,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                } = props;
                return (
                  <>
                    <form onSubmit={handleSubmit} className="form-login">
                      <div className="flex bloc  flex-wrap">
                        <Input
                          id="lname"
                          labelText={t("SignUp.lastName")}
                          placeholder={t("SignUp.lastName")}
                          type="text"
                          value={values.lname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="mr-5"
                        />
                        <Input
                          id="fname"
                          labelText={t("SignUp.name")}
                          placeholder={t("SignUp.name")}
                          type="text"
                          value={values.fname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="flex bloc  flex-wrap">
                        <Input
                          id="email"
                          labelText={t("SignUp.email")}
                          placeholder={t("SignUp.email")}
                          type="text"
                          error={errors.email}
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="mr-5"
                        />
                        <Input
                          id="phone"
                          labelText={t("SignUp.numberPhone")}
                          placeholder={t("SignUp.numberPhone")}
                          type="text"
                          error={errors.phone}
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="flex bloc flex-wrap">
                        <div className="relative ">
                          <DatePicker
                            id="birthDate"
                            locale="fr"
                            className="custom-Input  mb-2.5 rounded-lg px-3 border border-gray-200  h-14 mr-5 font-date-picker"
                            selected={values.birthDate}
                            dateFormat="dd/MM/yyyy"
                            onChange={(e) => setFieldValue("birthDate", e)}
                            placeholderText={t("SignUp.birthDate")}
                            autoComplete="off"
                            showYearDropdown
                          />
                          {/* <div className={`absolute right-2 top-3 mr-5`}>
                            <img crossOrigin = "anonymous"  referrerPolicy="origin"
                              src={requireInput}
                              alt="require"
                              className="w-2 h-2"
                            />
                          </div> */}
                        </div>

                        <Input
                          id="NumSecSocial"
                          labelText={t("SignUp.SocialSecurityNumber")}
                          placeholder={t("SignUp.SocialSecurityNumber")}
                          type="text"
                          value={values.NumSecSocial}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="flex bloc gap-x-4 flex-wrap">
                        <div className="relative">
                          <Input
                            id="password"
                            labelText={t("SignUp.psw")}
                            placeholder="********"
                            type={passwordFormType1}
                            value={values.password}
                            onChange={handleChange}
                            // require
                          />
                          <div className="absolute right-10 top-5">
                            <Tooltip
                              position="top"
                              tooltipText={t("SignUp.pswConstraint")}
                              clsText={{ width: "220px", bottom: "200%" }}
                            >
                              <span className=" text-white text-xs font-bold bg-blue-500 rounded-full  w-5 h-5 cursor-pointer flexCenter">
                                i
                              </span>
                            </Tooltip>
                          </div>
                          <img
                            crossOrigin="anonymous"
                            referrerPolicy="origin"
                            src={voir}
                            alt="voir"
                            className="absolute right-3 top-6 w-4 h-3 cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              setPasswordFormType1(
                                passwordFormType1 === "password"
                                  ? "text"
                                  : "password"
                              );
                            }}
                          />
                        </div>
                        <div className="relative">
                          <Input
                            id="confirmPassword"
                            labelText={t("SignUp.confirmPSW")}
                            placeholder="********"
                            type={passwordFormType2}
                            // className="pr-10"
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // require
                          />
                          {/* <span className="absolute right-3 top-6 w-4 h-3 cursor-pointer">
                            i
                          </span> */}
                          <img
                            crossOrigin="anonymous"
                            referrerPolicy="origin"
                            src={voir}
                            alt="voir"
                            className="absolute right-3 top-6 w-4 h-3 cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              setPasswordFormType2(
                                passwordFormType2 === "password"
                                  ? "text"
                                  : "password"
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex bloc gap-x-4 flex-wrap">
                        <SelectComp
                          id="sexe"
                          value={values.sexe}
                          options={dropdownSexeOptions}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          text={t("SignUp.sexe")}
                          // required
                        />
                        <SelectComp
                          id="country"
                          value={values.country}
                          options={CountryWithPhoneSuffix.map((el) => ({
                            value: el.name,
                            label: el.name,
                          }))}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          text={t("SignUp.country")}
                          // required
                        />
                      </div>

                      <div className="flex gap-x-4	flex-wrap">
                        <Input
                          id="postalCode"
                          labelText={t("SignUp.postalCode")}
                          placeholder={t("SignUp.postalCode")}
                          type="text"
                          // error={errors.postalCode}
                          value={values.postalCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // require
                        />
                        <Input
                          id="city"
                          labelText={t("SignUp.city")}
                          placeholder={t("SignUp.city")}
                          type="text"
                          // error={errors.city}
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // require
                        />
                      </div>

                      <div className="flexRowCenter">
                        <Button
                          type="submit"
                          disabled={
                            isSubmitting || Object.keys(errors).length > 0
                          }
                          status="primary"
                          className="btn mb-6"
                        >
                          {t("SignUp.register")}
                        </Button>
                      </div>
                    </form>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupPatient;
