import { Formik } from "formik";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";

import React from "react";
import Button from "../../ButtonUI";
import Input from "../../Input";
import FloatInput from "../../FloatInput";
import "./styles.scss";
import { addTreatementsToPatientByDoctor } from "../../../api/treatements";
import { useAtom } from "jotai";
import { userAtom } from "../../../state/auth";
// import { User } from "../../../types/user";
import { ReactComponent as Close } from "../../../assets/icons/icon-close.svg";
import { ITreatements } from "../../../types/patient";
import makeToast from "../../Snackbar";
import fr from "date-fns/locale/fr";
import { useTranslation } from "react-i18next";

registerLocale("fr", fr);
setDefaultLocale("fr");
interface Props {
  openModal: boolean;
  setOpenModal: Function;
  doctorId?: string;
  selectedPatient: string;
  treatementType: string;
  setTreatementEnCours: Function;
  setTreatementsAncien: Function;
  treatementEnCours: ITreatements[];
  treatementsAncien: ITreatements[];
}

const AddTraitement: React.FC<Props> = ({
  openModal,
  setOpenModal,
  selectedPatient,
  treatementType,
  setTreatementEnCours,
  setTreatementsAncien,
  treatementEnCours,
  treatementsAncien,
}) => {
  const { t } = useTranslation() as any;
  const [user] = useAtom(userAtom);
  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute bg-white z-40 top-1/3   addTraitementModal">
        <div className="relative ">
          <div className="absolute right-0 mt-2 mr-2">
            <Button status="icon-secondary" onClick={() => setOpenModal(false)}>
              <Close />
            </Button>
          </div>
        </div>
        <Formik
          initialValues={{
            nature: "Traitement",
            type: "",
            date: "",
            observation: "",
            posologie: "",
            traitement:
              treatementType === "En cours"
                ? "Traitement en cours"
                : "Ancien Traitement",
          }}
          onSubmit={async (values) => {
            try {
              if (!values.type)
                return makeToast(
                  "warning",
                  t("ModalFichePatient.EnterTreamentName")
                );
              const res = await addTreatementsToPatientByDoctor({
                ...values,
                doctorId: user && user._id,
                patientId: selectedPatient,
              });
              const { treatement } = res;
              if (treatement.traitement === "Traitement en cours") {
                setTreatementEnCours([...treatementEnCours, treatement]);
                setOpenModal(false);
              } else {
                setTreatementsAncien([...treatementsAncien, treatement]);
                setOpenModal(false);
              }
              if (res.message === "Success") {
                makeToast(
                  "success",
                  t("ModalFichePatient.SuccessAddedObservation")
                );
              } else {
                makeToast(
                  "error",
                  t("ModalFichePatient.SuccessFailedTreatment")
                );
              }
            } catch (err) {
              console.error(err);
            }
          }}
          //validationSchema={SchemaAddTraitement}
        >
          {(props) => {
            const {
              values,
              // errors,
              // isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = props;

            return (
              <form
                onSubmit={handleSubmit}
                className="flex-auto py-7 px-8 min-w-full	"
              >
                <div className="flex flex-wrap items-baseline">
                  <h1 className="mb-10">
                    {t("ModalFichePatient.AddTreatment")}
                  </h1>
                </div>
                <div className="">
                  <div className="flex justify-between mb-4">
                    <Input
                      name="traitement"
                      type="radio"
                      onChange={handleChange}
                      value="Traitement en cours"
                      labelText={t("ModalFichePatient.OngoingTreatment")}
                      labelProps={{ className: "pl-2" }}
                      //stylesDiv={"inputTest"}
                      checked={treatementType === "En cours" ? true : false}
                      disabled
                    />
                    <Input
                      name="traitement"
                      type="radio"
                      onChange={handleChange}
                      value="Ancien Traitement"
                      labelText={t("ModalFichePatient.OldTreatment")}
                      labelProps={{ className: "pl-2" }}
                      //stylesDiv={"inputTest"}
                      checked={treatementType !== "En cours" ? true : false}
                      disabled
                    />
                  </div>
                  <FloatInput
                    id="type"
                    placeholder={t("ModalFichePatient.TradeName")}
                    labelText={t("ModalFichePatient.TradeName")}
                    type="text"
                    //error={values.pricingNight}
                    value={values.type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inlineLabel={false}
                    onfocus={true}
                  />
                  <FloatInput
                    id="posologie"
                    placeholder={t("ModalFichePatient.Dosage")}
                    labelText={t("ModalFichePatient.Dosage")}
                    type="text"
                    //error={values.pricingNight}
                    value={values.posologie}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inlineLabel={false}
                    onfocus={true}
                  />

                  <DatePicker
                    id="date"
                    locale="fr"
                    className="custom-Input w-full mb-2.5 rounded-lg px-3 border border-gray-200  h-14  font-date-picker placeholder-14"
                    selected={values.date}
                    dateFormat="dd/MM/yyyy"
                    onChange={(e) => setFieldValue("date", e)}
                    placeholderText={t("ModalFichePatient.StartDateTreatment")}
                    autoComplete="off"
                    showYearDropdown
                  />
                  <FloatInput
                    id="observation"
                    placeholder={t("ModalFichePatient.AddObservation")}
                    labelText={t("ModalFichePatient.Observation")}
                    type="text"
                    //error={values.pricingWeekEnd}
                    value={values.observation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inlineLabel={false}
                    onfocus={true}
                  />
                </div>
                <Button
                  status="secondary"
                  width="100px"
                  height="50px"
                  className="float-right mb-4"
                >
                  {t("ModalFichePatient.Confirm")}
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddTraitement;
