import axios from "axios";
import { ADD_PDF_TO_DOCTOR, GET_DOCTORS_TEMPLATE_PDF, GET_DOCTORS_TEMPLATE_PDF_PAGINAtION, GET_TEMPLATE_BY_ID, UPDATE_DOCTORS_TEMPLATE_PDF } from "../config/api";

export const createPDFToDoctor = async (values) => {
    try {
      const res = await axios.post(ADD_PDF_TO_DOCTOR, values, {
        withCredentials: true,
      });
      return res.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };
  
  export const getAllPdfOfDoctors = async (id) => {
    try {
      const res = await axios.get(`${GET_DOCTORS_TEMPLATE_PDF}/${id}`, {
        withCredentials: true,
      });
      return res.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };
  export const getAllPdfOfDoctorsPagination = async (page,limit,id) => {
    try {
      const res = await axios.get(`${GET_DOCTORS_TEMPLATE_PDF_PAGINAtION}/${id}?limit=${limit}&page=${page}`, {
        withCredentials: true,
      });
      return res.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const getTemplateById = async (id) => {
    try {
      const res = await axios.get(`${GET_TEMPLATE_BY_ID}/${id}`, {
        withCredentials: true,
      });
      return res.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const updateDoctortemplate = async (id,values) => {
    try {
      const res = await axios.put(`${UPDATE_DOCTORS_TEMPLATE_PDF}/${id}`, values, {
        withCredentials: true,
      });
      return res.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };