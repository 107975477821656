export const imcStatus = (weight: number, size: number, t) => {
  let imcStatus;
  if (weight && size) {
    const imc = (weight / Math.pow(size / 100, 2)).toFixed(2);
    if (+imc < 18.5) imcStatus = t("ProfilePatient.Underweight");
    else if (+imc >= 18.5 && +imc <= 24.99)
      imcStatus = t("ProfilePatient.Normal");
    else if (+imc >= 25 && +imc <= 29.99)
      imcStatus = t("ProfilePatient.Preoccupation");
    else if (+imc >= 30 && +imc <= 34.99)
      imcStatus = t("ProfilePatient.Obesity1");
    else if (+imc >= 35 && +imc <= 39.99)
      imcStatus = t("ProfilePatient.Obesity2");
    else if (+imc >= 40) imcStatus = t("ProfilePatient.Obesity3");
    return imcStatus;
  }
  return "N/A";
};
