export const Segure = {
  fr: {
    OpenDevBox:
      "Veuillez faire en sorte que DevBox soit en cours d'exécution sur votre machine, et votre carte CPS soit inserer.",
    Add: "+ Ajouter",
    NoDoc: "Aucun dossier patient trouvé ",
    NoVacc: "Liste des vacinations vide",
    INSNotQualified: "Ce patient n'as pas d'INS qualifiée",

    NameRequire:
      "Le nom du patient doit être écrit en majuscules et ne contenir aucun caractère spécial à l'exception d'une apostrophe ou d'un tiret",
    BirthplaceRequire: "Le code lieu de naissance doit être numérique",
    NoIdentityFound: "Aucune identité trouvée, modifiez votre recherche",
    SeveralIdentitiesFound:
      "Plusieurs identitées trouvées. Merci de saisir le code du lieu de naissance afin d'affiner la recherche.",
    uniqueIdentityFound: "Une identité unique a été trouvée",
    Failed: "Échec, vérifiez la connexion à votre carte.",

    NHI_INS: "L'Identité Nationale de Santé (INS)",

    ID: "Identifiant",
    BirthFName: "Prénom de naissance",
    InformationsINS: "Informations (INS)",
    Status: "Status",
    firstName: "Prénom",
    firstNameS2: "Prénoms (séparés par une virgule)",
    birthName: "Nom de naissance",
    pays: "pays",
    regions: "Régions",
    departments: "Départements",
    commune: "Commune",
    Gouvernorat: "Gouvernorat",
    Delegation: "Délégation",

    placeOfBirthTitle: "Lieu de naissance",
    placeOfBirth: "Votre lieu de naissance (code insee commune)",
    dateOfBirthTitle: "Date de naissance",

    dateOfBirth: "Date de naissance (JJMMAAAA)",
    Sexe: "Sexe",
    YourSex: "Votre sexe",
    MatriculeINS: "Matricule INS",
    INSNumber: "Votre matricule INS (0000000000000)",
    VerificationINS: "Vérification INS",
    SI: "Identité douteuse",
    FI: "Identité fictive",
    VI: "Identité valide",
    INV: "Identité non vérifié",
    Save: "Enregistrer",
    AuthList: "List DMP Actifs",
    dashboardURL: "td09TableauDeBordUrl",
  },
  en: {
    dashboardURL: "td09TableauDeBordUrl",
    AuthList: "Active DDMP List",
    OpenDevBox:
      "Please make sure that DevBox is running on your machine, and your CPS card is inserted.",
    Add: "+ Add",
    NoDoc: "No patient document found",
    NoVacc: "empty vaccination list",
    INSNotQualified: "This patient does not have a qualified INS",

    NHI_INS: "The National Health Identity (INS)",
    NameRequire:
      "The patient's name must be written in capital letters and contain no special characters except an apostrophe or a hyphen",
    BirthplaceRequire: "Birthplace code must be numeric",
    NoIdentityFound: "No identity found, modify your search",
    SeveralIdentitiesFound:
      "Several identities found. Please enter the birthplace code to refine the search.",
    uniqueIdentityFound: "A unique identity has been found",
    Failed: "Failed, check the connection to your card.",

    ID: "ID",
    BirthFName: "First name",
    InformationsINS: "Informations (INS)",
    Status: "Status",
    firstName: "First name",
    firstNameS2: "First names (separated by a comma)",
    birthName: "Last name",
    pays: "country",
    regions: "regions",
    departments: "departments",
    commune: "commune",
    Gouvernorat: "Governorate",
    Delegation: "Delegation",
    placeOfBirthTitle: "Place of birth",
    placeOfBirth: "Your place of birth (code insee commune)",
    dateOfBirthTitle: "Date of Birth",
    dateOfBirth: "Date of Birth (DDMMYYYY)",
    Sexe: "Sexe",
    YourSex: "Your sex",
    MatriculeINS: "Matricule INS",
    INSNumber: "Your INS number (00000000000000000)",
    VerificationINS: "Verification INS",
    SI: "Suspicious identity",
    FI: "Fictitious identity",
    VI: "Valid Identity",
    INV: "Not verified identity",
    Save: "Save",
  },
};
