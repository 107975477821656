/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

/**
 * Helper & Api
 */
import moment from "../../../helper/moment";

import "../CardPatient/styles.scss";
import Modal from "../../../components/Modal";
import DMP from "../../../components/SharedPatientDetails/DMP";

const colorAvatar = [
  "#ccd2ff",
  "#d5f0f7",
  "#ffecba",
  "#fff7ab",
  "#ffccd2",
  "#ffe2c8",
];

// interface IPatientDetails {
//   firstName: string;
//   lastName: string;
//   _id: string;
//   date: Date;
//   type: string;
//   gender: string;
//   photo?: string;
//   consultations?: [] | any;
//   INS: any;
//   sexe: string;
// }

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  patientDetails: any;
  openModal: boolean;
  setOpenModal: Function;
  setSelectedPatient: Function;

  selectedPatient: string;
  setColorIndex: Function;

  i: number;
  user: any;
};

const CardPatient: React.FC<Props> = ({
  patientDetails,
  openModal,
  setOpenModal,
  setSelectedPatient,
  selectedPatient,
  setColorIndex,
  user,
  i,
}) => {
  return (
    <>
      <Modal
        showModal={openModal}
        closeModal={() => setOpenModal(false)}
        slideModal
      >
        <div className="p-5">
          <div className="flex items-center border rounded-lg py-5 mb-5 bg-gray-100">
            <div
              className="rounded-full h-12 w-12 flexCenter mx-5 my-auto relative"
              style={{
                backgroundColor: colorAvatar[i % colorAvatar.length],
              }}
            >
              <span className="font-bold">
                {(patientDetails?.prenom || "")[0]}{" "}
                {(patientDetails?.nomPatronymique || "")[0]}
              </span>
            </div>

            <div className=" ">
              <p className="card-title capitalize">
                {patientDetails?.prenom} {patientDetails?.nomPatronymique}
                <span className="pl-5">({` ${patientDetails?.ins} `})</span>
              </p>

              <p className="capitalize text-xs " style={{ color: "#a6a9c8" }}>
                {patientDetails?.dateDeNaissance &&
                  moment(patientDetails?.dateDeNaissance, "YYYYMMDD").format(
                    "DD/MM/YYYY"
                  )}
              </p>
              <p className=" text-xs mt-2" style={{ color: "#a6a9c8" }}>
                {patientDetails?.lastUpdateDate && (
                  <span>{`Dernière mis à jour le : ${moment(
                    patientDetails?.lastUpdateDate
                  ).format("DD/MM/YYYY à HH:mm")}`}</span>
                )}
              </p>
            </div>
          </div>
          <DMP
            patientData={{ matriculeINS: patientDetails?.matriculeINS }}
            user={null}
            setPatientData={() => {}}
          />
        </div>
      </Modal>
      <div
        className={`relative cursor-pointer hover:shadow card-patient justify-between bg-white ${
          selectedPatient === patientDetails?._id && "selectedPatientDoctor"
        }`}
        style={{ zIndex: 0 }}
        onClick={(e) => {
          setSelectedPatient(patientDetails?._id);
          setOpenModal(!openModal);
          setColorIndex(i);
        }}
      >
        <div className="flex items-center patient-info">
          <div
            className="rounded-full h-12 w-12 flexCenter mx-5 my-auto relative"
            style={{
              backgroundColor: colorAvatar[i % colorAvatar.length],
            }}
          >
            <span className="font-bold">
              {(patientDetails?.prenom || "")[0]}{" "}
              {(patientDetails?.nomPatronymique || "")[0]}
            </span>
          </div>

          <div className=" ">
            <p className="card-title capitalize">
              {patientDetails?.prenom} {patientDetails?.nomPatronymique}
              <span className="pl-5">({` ${patientDetails?.ins} `})</span>
            </p>

            <p className="capitalize text-xs " style={{ color: "#a6a9c8" }}>
              {patientDetails?.dateDeNaissance &&
                moment(patientDetails?.dateDeNaissance, "YYYYMMDD").format(
                  "DD/MM/YYYY"
                )}
            </p>
            <p className=" text-xs mt-2" style={{ color: "#a6a9c8" }}>
              {patientDetails?.lastUpdateDate && (
                <span>{`Dernière mis à jour le : ${moment(
                  patientDetails?.lastUpdateDate
                ).format("DD/MM/YYYY à HH:mm")}`}</span>
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardPatient;
