import { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  cancelBillingTeleconsultationAsync,
  refundTeleconsultationAsync,
  billTeleconsultationAsync,
} from "../../api/teleconsultation";
import { loadImage } from "../../api/documents";
import moment from "../../helper/moment";
import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";
import Button from "../../components/ButtonUI";
import makeToast from "../../components/Snackbar";

type Props = {
  teleconsultationDetails: any;
  i: number;
  updateConsultationStatus: Function;
};

const colorAvatar = [
  "#ccd2ff",
  "#d5f0f7",
  "#ffecba",
  "#fff7ab",
  "#ffccd2",
  "#ffe2c8",
];

const CardTeleconsultation = (props: Props) => {
  const {
    i,
    teleconsultationDetails: {
      _id,
      patientId,
      motif,
      dateConsultation,
      billableAt,
      billedAt,
    },
    updateConsultationStatus,
  } = props;
  const { t } = useTranslation() as any;

  const [loading, setLoading] = useState(false);
  return (
    <div className="card-teleconsultation-patient bg-white">
      <div className="flex patient-info">
        <div
          className="rounded-full h-12 w-12 flexCenter mx-5 my-auto"
          style={{
            backgroundColor: patientId?.photo
              ? ""
              : colorAvatar[i % colorAvatar.length],
          }}
        >
          {patientId?.photo ? (
            <img
              crossOrigin="anonymous"
              referrerPolicy="origin"
              src={loadImage(
                patientId?._id,
                "profile",
                "thumbnail",
                patientId?.photo
              )}
              alt="patient"
            />
          ) : patientId.sexe === "woman" ? (
            <WomanPatient />
          ) : (
            <ManPatient />
          )}
        </div>
        <div className="details">
          <div className="flex-grow ">
            <p className="card-title capitalize">
              {patientId.firstName} {patientId.lastName}
            </p>
            <p className="card-subtitle">
              {t("MyTeleconsultation.ConsultationOf")}{" "}
              <span>{moment(dateConsultation).format(t("Moment.Z"))}</span>
            </p>
            <span className="box">{motif}</span>
          </div>
        </div>
      </div>
      <div className="patient-icons gap-2">
        <div className="flex flex-col md:flex-row gap-2">
          {/** Facturation */}
          <Button
            className={classNames(
              "px-2 m",
              billableAt && !billedAt && "btn-payment "
            )}
            status="icon-secondary"
            width="max-content"
            onClick={async (e) => {
              try {
                setLoading(true);
                e.stopPropagation();
                e.preventDefault();
                const res = await billTeleconsultationAsync(_id);
                if (res.status === "success")
                  makeToast("success", t("MyTeleconsultation.SuccessPayment"));
                else if (res.status === "stripeNotValid")
                  makeToast("error", t("MyTeleconsultation.ErrorStripe"));
                else makeToast("error", t("MyTeleconsultation.ErrorPayment"));
                setLoading(false);
                updateConsultationStatus();
              } catch (error) {
                setLoading(false);
                makeToast("error", t("MyTeleconsultation.ErrorPayment"));
              }
            }}
            // disabled={loading || !billableAt || billedAt}
          >
            <span className="hidden md:inline">
              {" "}
              {t("MyTeleconsultation.Billing")}
            </span>
            <span className="inline md:hidden p-2">
              {" "}
              {t("MyTeleconsultation.B")}
            </span>
          </Button>
          {/**  Tiers Payant */}
          <Button
            className={classNames(
              "px-2",
              billableAt && !billedAt && "btn-payment "
            )}
            status="icon-secondary"
            onClick={async (e) => {
              try {
                setLoading(true);
                e.stopPropagation();
                e.preventDefault();
                const res = await cancelBillingTeleconsultationAsync(_id, true);
                if (res.status === "success")
                  makeToast("success", "Le paiement a été annulé avec succès.");
                else makeToast("error", t("MyTeleconsultation.ErrorCancel"));
                setLoading(false);
                updateConsultationStatus();
              } catch (error) {
                setLoading(false);
                makeToast("error", t("MyTeleconsultation.ErrorCancel"));
              }
            }}
            width="max-content"
            disabled={loading || !billableAt || billedAt}
          >
            <span className="hidden md:inline">
              {t("MyTeleconsultation.ThirdPartyPayment")}
            </span>
            <span className="inline md:hidden p-2">
              {t("MyTeleconsultation.TPP")}
            </span>
          </Button>
        </div>
        <div className="flex flex-col md:flex-row gap-2">
          {/** Rembourser */}
          <Button
            className={classNames(
              "px-2",
              !billableAt && billedAt && "btn-payment "
            )}
            status="icon-secondary"
            onClick={async (e) => {
              try {
                setLoading(true);
                e.stopPropagation();
                e.preventDefault();
                const res = await refundTeleconsultationAsync(_id);
                if (res.status === "success")
                  makeToast("success", t("MyTeleconsultation.SuccessRefund"));
                else makeToast("error", t("MyTeleconsultation.ErrorRefound"));
                setLoading(false);
                updateConsultationStatus();
              } catch (error) {
                setLoading(false);
                makeToast("error", t("MyTeleconsultation.ErrorRefound"));
              }
            }}
            width="max-content"
            disabled={loading || billableAt || !billedAt}
          >
            <span className="hidden md:inline">
              {t("MyTeleconsultation.Refound")}
            </span>
            <span className="inline md:hidden p-2">
              {t("MyTeleconsultation.R")}
            </span>
          </Button>
          {/** Annuler */}
          <Button
            className={classNames(
              "px-2",
              billableAt && !billedAt && "btn-payment "
            )}
            status="icon-secondary"
            width="max-content"
            onClick={async (e) => {
              try {
                setLoading(true);
                e.stopPropagation();
                e.preventDefault();
                const res = await cancelBillingTeleconsultationAsync(
                  _id,
                  false
                );
                if (res.status === "success")
                  makeToast("success", t("MyTeleconsultation.SuccessCancel"));
                else makeToast("error", t("MyTeleconsultation.ErrorCancel"));
                setLoading(false);
                updateConsultationStatus();
              } catch (error) {
                setLoading(false);
                makeToast("error", t("MyTeleconsultation.ErrorCancel"));
              }
            }}
            disabled={loading || !billableAt || billedAt}
          >
            <span className="hidden md:inline ">
              {" "}
              {t("MyTeleconsultation.Cancel")}
            </span>
            <span className="inline md:hidden p-2">
              {" "}
              {t("MyTeleconsultation.C")}
            </span>
          </Button>{" "}
        </div>
      </div>
    </div>
  );
};

export default CardTeleconsultation;
