/* eslint-disable eqeqeq */
import Modal from "../../ModalUI";
import { useTranslation } from "react-i18next";
import Button from "../../ButtonUI";
import { ReactComponent as Close } from "../../../assets/icons/icon-close.svg";
import { fontArray } from "../Helper/constants";
import DataBaseKeysPatientJson from "../StaticData/DataBaseKeyPatient.json";
import DataBaseKeysJson from "../StaticData/DataBaseKeys.json";
import StyleComponent from "../Components/StyleComponent";
import { ChromePicker } from "react-color";
import { handleColorChange, TextAreaEdit } from "../Helper/functions";

export const TextEditModal = ({
  showModalTextOption,
  setShowModalTextOption,
  dataSelectedObj,
  isSelected,
  listData,
  setListData,
  setTextColor,
  textColor,
  selectedInputId,
  setDataSelectedObj,
  setInputValue,
  setDidEdit,
  didEdit,
  inputValue,
}) => {
  const { t } = useTranslation() as any;
  return (
    <>
      <Modal
        showModal={showModalTextOption}
        slidemodal
        closeModal={() => {
          setShowModalTextOption(false);
        }}
      >
        <div className="p-8">
          <div className="flex justify-end absolute right-2">
            <Button
              status="icon-secondary"
              type="button"
              onClick={() => setShowModalTextOption(false)}
            >
              <Close />
            </Button>
          </div>
          <h2>{t("TemplatePDF.EditProperties")}</h2>
          {dataSelectedObj.type === "text" && isSelected && (
            <div className=" w-full mt-4">
              <div>
                {/* <li style="color:red;font-size:30px">font</li> */}

                <h3 className="font-light text-black mb-2">
                  {t("TemplatePDF.Size")}
                </h3>
                <select
                  name="font"
                  id="setFont"
                  style={{ height: 50 }}
                  className="w-full border border-gray-200 mb-3 bg-white"
                  onChange={(e) => {
                    const CopyOfData = listData;
                    let foundIndex = listData.findIndex(
                      (x) => x.id == dataSelectedObj.id
                    );

                    CopyOfData[foundIndex] = {
                      ...listData[foundIndex],
                      size: parseInt(e.target.value),
                    };
                    setListData([...CopyOfData]);
                  }}
                >
                  <option value=""> {t("TemplatePDF.SelectSize")}</option>
                  {fontArray.map((e) => {
                    return dataSelectedObj.size === e ? (
                      <option value={e} selected>
                        {e}
                      </option>
                    ) : (
                      <option value={e}>{e}</option>
                    );
                  })}
                </select>
              </div>
              <div>
                <h3
                  style={{ fontWeight: 300 }}
                  className="font-light text-black mb-2"
                >
                  {t("TemplatePDF.Character")}
                </h3>
                <div
                  style={{ marginRight: "70px" }}
                  className="fontStylingText"
                >
                  <StyleComponent
                    setListData={setListData}
                    listData={listData}
                    selectedInputId={selectedInputId}
                  />
                </div>
              </div>
              <div>
                <h3 className="font-light text-black mb-2">
                  {t("TemplatePDF.Color")}{" "}
                </h3>
                <ChromePicker
                  disableAlpha
                  color={textColor}
                  onChange={(color) =>
                    handleColorChange(
                      color,
                      setTextColor,
                      listData,
                      selectedInputId,
                      setListData
                    )
                  }
                />
              </div>

              <div className="flex items-center gap-2">
                <div>
                  {dataSelectedObj.repeatText ? (
                    <input
                      name="repeat"
                      id="setFont"
                      type="checkbox"
                      style={{ height: 50 }}
                      value={dataSelectedObj.repeatText}
                      onChange={(e) => {
                        const CopyOfData = listData;
                        let foundIndex = listData.findIndex(
                          (x) => x.id == dataSelectedObj.id
                        );

                        CopyOfData[foundIndex] = {
                          ...listData[foundIndex],
                          repeatText: false,
                        };
                        setListData([...CopyOfData]);
                        setDataSelectedObj(CopyOfData[foundIndex]);
                      }}
                      checked
                    />
                  ) : (
                    <input
                      name="repeat"
                      id="setFont"
                      type="checkbox"
                      style={{ height: 50 }}
                      value={dataSelectedObj.repeatText}
                      onChange={(e) => {
                        const CopyOfData = listData;
                        let foundIndex = listData.findIndex(
                          (x) => x.id == dataSelectedObj.id
                        );
                        CopyOfData[foundIndex] = {
                          ...listData[foundIndex],
                          repeatText: true,
                        };

                        setListData([...CopyOfData]);
                        setDataSelectedObj(CopyOfData[foundIndex]);
                      }}
                    />
                  )}
                </div>
                <div className="-mt-2 text-xs	">
                  <p className="text-base">
                    {t("TemplatePDF.RepeatInAllText")}
                  </p>
                </div>
              </div>
            </div>
          )}
          <div>
            <textarea
              value={inputValue}
              style={{
                display: didEdit ? "block" : "none",

                width: "100%",
                height: "150px",
                border: "1px solid #827F9B",
                marginBottom: "20px",
              }}
              onChange={(e) => TextAreaEdit(e, setInputValue)}
              onKeyDown={(e) => {
                if (e.keyCode === 13 && inputValue.trim().length > 2) {
                  const Obj = listData.find((e) => e.id === selectedInputId);
                  Obj.title = inputValue;
                  const newArray = listData.filter((e) => e.id !== Obj.id);
                  newArray.push(Obj);
                  setListData(newArray);
                  setDidEdit(false);
                  setInputValue("");
                }
              }}
            />
          </div>
          {dataSelectedObj.type === "text" && isSelected && didEdit && (
            <>
              <div className="mb-8">
                <div className="box-tools">
                  <h3 className="text-black">
                    {t("TemplatePDF.MyInformation")}{" "}
                  </h3>
                  <ul className="ListItem px-5 py-3 h-64 overflow-y-auto">
                    {Object.entries(DataBaseKeysJson).map(([k, v]) => {
                      return (
                        <li
                          onClick={() => {
                            setInputValue(
                              `${inputValue + " {{" + v.abreviation + "}}"}`
                            );
                          }}
                        >
                          {v.text}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div>
                <div className="box-tools">
                  <h3 className="text-black">
                    {" "}
                    {t("TemplatePDF.InformationPatient")}{" "}
                  </h3>
                  <ul className="ListItem px-5 py-3 h-64 overflow-y-auto">
                    {Object.entries(DataBaseKeysPatientJson)
                      .filter(
                        ([k, v]) =>
                          (process.env.REACT_APP_KATOMI_TENANT === "katomi" &&
                            k === "Code INS") ||
                          k !== "Code INS"
                      )
                      .map(([k, v]) => {
                        return (
                          <li
                            onClick={() => {
                              setInputValue(
                                `${inputValue + " {{" + v.abreviation + "}}"}`
                              );
                            }}
                          >
                            {v.text}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};
