import { Formik } from "formik";
import { useAtom } from "jotai";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import pscImg from "../../assets/psc.png";

import { login } from "../../api/auth";
import Steto from "../../assets/images/steto.png";
import voir from "../../assets/images/voir.png";
import Button from "../../components/ButtonUI";
import Input from "../../components/FloatInput";
import Icon from "../../components/Icon";
import makeToast from "../../components/Snackbar";
import moment from "../../helper/moment";
import history from "../../history";
import { userAtom } from "../../state/auth";
import "./stylesSignin.scss";

const Signin: React.FC = () => {
  const { t } = useTranslation() as any;
  const [, setUser] = useAtom(userAtom);

  const [passwordFormType, setPasswordFormType] = useState("password");
  const onSubmit = async (values) => {
    const { email, password, rememberMe } = values;
    try {
      const response = await login({ email, password, rememberMe });

      if (response === "Inconfirmed") {
        makeToast("warning", t("SignIn.ErrorMsgConfirmedEmail"));
        history.push(`/confirm-email/${values.email}`);
      }

      if (response.status === "success") {
        makeToast("success", t("SignIn.SuccessConnectedMsg"));
      }
      values.rememberMe && localStorage.setItem("rememberMe", "true");
      values.rememberMe && localStorage.setItem("expireIn", moment().format());
      setUser(response.user);
    } catch (error) {
      makeToast("error", t("SignIn.ErrorMsgIncorrectCredential"));
      console.error("[error] Signin - onSubmit :", error);
    }
  };

  const responseType = "code";
  const scope = "openid%20scope_all";
  const clientId = "katomi-kore-bas";
  const redirect = "http://localhost:3000";
  const acr = "eidas1";

  const uri = `https://wallet.bas.psc.esante.gouv.fr/auth?response_type=${responseType}&scope=${scope}&client_id=${clientId}&redirect_uri=${redirect}&acr_values=${acr}`;

  return (
    <div className="login min-h-screen flex justify-center relative">
      <div className="absolute inset-0 katomiWterMark " />
      <div className="auth-login-Container flexColCenter">
        {/* <h1 className="h1 title flexRowCenter"> {t("SignIn.login")}</h1> */}
        <div className="formLogin">
          {/* start */}
          <div
            className="icon cursor-pointer"
            onClick={() => history.push("/check/user")}
          >
            <Icon
              name="direction-gauche"
              width={50}
              height={50}
              isIcon={true}
            />
          </div>
          <div className="flexRowCenter img-top">
            <img
              crossOrigin="anonymous"
              referrerPolicy="origin"
              src={Steto}
              width="46px"
              height="46px"
              alt="steto"
            />
          </div>
          <div className="title-container flexColCenter">
            <p>{t("SignIn.im")}</p>
            <h1 className="h1 flexRowCenter">{t("SignIn.doctor")}</h1>
          </div>
          <div className="">
            <Formik
              initialValues={{ email: "", password: "", rememberMe: false }}
              onSubmit={onSubmit}
              // validationSchema={validationSchema}
            >
              {(props) => {
                const {
                  values,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props;

                return (
                  <>
                    <form onSubmit={handleSubmit} className="form-login">
                      <Input
                        id="email"
                        placeholder={t("SignIn.email")}
                        labelText={t("SignIn.email")}
                        type="text"
                        // error={errors.email}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inlineLabel={false}
                        onfocus={true}
                      />
                      <div className="flex flex-col">
                        <div className="relative">
                          <Input
                            id="password"
                            placeholder={t("SignIn.psw")}
                            labelText={t("SignIn.psw")}
                            type={passwordFormType}
                            // error={errors.password}
                            className="pr-10"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <img
                            crossOrigin="anonymous"
                            referrerPolicy="origin"
                            src={voir}
                            alt={t("SignIn.voir")}
                            className="absolute right-3 top-6 w-4 h-3 cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              setPasswordFormType(
                                passwordFormType === "password"
                                  ? "text"
                                  : "password"
                              );
                            }}
                          />
                        </div>
                        <p
                          className="mdp cursor-pointer"
                          onClick={() => history.push("/forgetPassword")}
                        >
                          {t("SignIn.forgetPassword")}
                        </p>
                      </div>
                      <div className="flexRowCenter ">
                        <Button
                          type="submit"
                          disabled={
                            isSubmitting || Object.keys(errors).length > 0
                          }
                          status="primary"
                          className="btn"
                        >
                          {t("SignIn.login")}
                        </Button>
                      </div>
                      <div className="flexColCenter  my-6">
                        <h3 className="text-lg">{t("SignIn.orPSC")}</h3>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={uri}
                          onClick={() => {
                            localStorage.setItem("typePscLogin", "login");
                          }}
                        >
                          <img
                            src={pscImg}
                            alt="psc"
                            width={200}
                            className="mt-3 cursor-pointer"
                          />
                        </a>
                      </div>
                    </form>
                  </>
                );
              }}
            </Formik>
          </div>
          <div className="create-Account flexColCenter">
            <p className="check-account"> {t("SignIn.haventCompte")}</p>
            <p
              className="create cursor-pointer "
              onClick={() => history.push("/register")}
            >
              {t("SignIn.createCompte")}
            </p>
          </div>
          {/* bloc  */}
        </div>
      </div>
      {process.env.REACT_APP_KATOMI_ENV === "development" && (
        <div className="absolute bottom-5 right-3 flex items-center space-x-10 ">
          <div
            className=" rounded-full cursor-pointer flex flex-col justify-center items-center w-26"
            onClick={() =>
              onSubmit({
                email: process.env.REACT_APP_KATOMI_DOCTOR_EMAIL1,
                password: process.env.REACT_APP_KATOMI_DOCTOR_PSW1,
              })
            }
          >
            <h3>{process.env.REACT_APP_KATOMI_DOCTOR_Name1}</h3>
            <img
              crossOrigin="anonymous"
              referrerPolicy="origin"
              src={require("../../assets/password.png").default}
              alt="admin-login"
              width={64}
            />
          </div>

          <div
            className=" rounded-full cursor-pointer flex flex-col justify-center items-center w-26 "
            onClick={() =>
              onSubmit({
                email: process.env.REACT_APP_KATOMI_DOCTOR_EMAIL2,
                password: process.env.REACT_APP_KATOMI_DOCTOR_PSW2,
              })
            }
          >
            <h3>{process.env.REACT_APP_KATOMI_DOCTOR_Name2}</h3>
            <img
              crossOrigin="anonymous"
              referrerPolicy="origin"
              src={require("../../assets/password.png").default}
              alt="admin-login"
              width={64}
            />
          </div>

          <div
            className="rounded-full cursor-pointer flex flex-col justify-center items-center w-26"
            onClick={() =>
              onSubmit({
                email: process.env.REACT_APP_KATOMI_DOCTOR_EMAIL3,
                password: process.env.REACT_APP_KATOMI_DOCTOR_PSW3,
              })
            }
          >
            <h3>{process.env.REACT_APP_KATOMI_DOCTOR_Name3}</h3>
            <img
              crossOrigin="anonymous"
              referrerPolicy="origin"
              src={require("../../assets/password.png").default}
              alt="admin-login"
              width={64}
            />
          </div>

          <div
            className="rounded-full cursor-pointer flex flex-col justify-center items-center w-26"
            onClick={() =>
              onSubmit({
                email: process.env.REACT_APP_KATOMI_DOCTOR_EMAIL4,
                password: process.env.REACT_APP_KATOMI_DOCTOR_PSW4,
              })
            }
          >
            <h3>{process.env.REACT_APP_KATOMI_DOCTOR_Name4}</h3>
            <img
              crossOrigin="anonymous"
              referrerPolicy="origin"
              src={require("../../assets/password.png").default}
              alt="admin-login"
              width={64}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Signin;
