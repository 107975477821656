import * as Yup from "yup";

export default Yup.object().shape({
  sexe: Yup.array().required("At least one checkbox is required").min(1),
  email: Yup.string()
    .email("Please use a valid email address.")
    .required("This field is required"),
  lastName: Yup.string().required(),
  firstName: Yup.string().required(),
  birthDate: Yup.string().required(),
  particularites: Yup.array().required("At least one checkbox is required"),
  phone: Yup.string().required(),
  adresse: Yup.string().required(),
  numSecSocial: Yup.number().required(),
  medecin: Yup.string(),
});
