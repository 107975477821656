export const DoctorMoveMeeting = {
    fr: {
        selectDate: "Vous devez choisir votre nouvelle date de cconsultation",
        SuccessUpdate:  "La mise à jour de votre consultation a été effectuée avec succès",
        SomethingWentWrong:  "Quelque chose s'est mal passé. Veuillez réessayer plus tard",
        YourMeeting:    "Votre rendez-vous",
        DateAppointment:  "Date et heure de votre rendez-vous :",
        Comment:  "Commentaire :",
        ChangeTS:    "Vous pouvez changer la date de consultation en cliquant sur un créneau",
        ConsultationTeleconsultation: "la consultation sera réalisée en téléconsultation",
  Confirm: "Valider",
   Close:"Fermer",
  Move: "Déplacer",
   Motif:"Motif :",
   On:"Le",
   UpdatedOn:"Mise à jour le",
   ConsultationFor:"Consultation du :" ,
   Prescription:"Prescription",
   Report:"Compte rendu",
   Document:"Document",
   Images:"Images",
    },
    en:{
        
        selectDate:"You must choose your new consultation date",
SuccessUpdate:"Your consultation has been updated successfully",
        SomethingWentWrong  :   "Something went wrong. Please try again later",
      YourMeeting:  "Your meeting",
        DateAppointment:"Date and time of your appointment:",
        Comment:   "Comment :",
 ChangeTS:      "You can change the consultation date by clicking on a slot" ,
ConsultationTeleconsultation:"the consultation will be carried out by teleconsultation",
  Confirm: "Confirm",
   Close:"Close",
   Motif:"Motif :",
   On:"On",
   
   Move: "Move",

   UpdatedOn:"Updated on",
   ConsultationFor:"Consultation of :" ,
   Prescription:"Prescription",
   Report:"Report",
   Document:"Document",
   Images:"Images",

    }
}