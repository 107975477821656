export const DashboardPatient = {
  fr: {
    title: "Accueil",
    TodayIs: "Nous sommes le",
    UpcomingConsultations: "Mes rendez-vous à venir",
    Start: "Démarrer",
    Update: "Modifier",
    MyDoctor: "Mon médecin traitant",
    Noconsultation: "Vous n'avez pas de consultations",
    NextAvailableConsultation: "Prochain rendez-vous disponible :",
    MyRecentDocuments: "Mes documents récents",
    ConsultationOf: "Consultation du :",
    Update2: "Mise à jour",
    NoDoc: "Vous n'avez pas encore des documents",
    SuccessChangeDoctor: "Vous avez changé votre médecin traitant avec succès",
    SelectNewDoctor: "Veuillez sélectionner votre nouveau médecin",
    ConfirmChangeAttendingDoctor:
      "Vous êtes sur le point de changer votre médecin traitant",
    Confirm: "Valider",
    Doctor: "Médecin",

    //UpdateConsultation
    SelectConsultationDate:
      "Veuillez choisir votre nouvelle date de consultation",
    SuccessUpdateConsultation:
      "La mise à jour de votre consultation a été effectuée avec succès",
    ErrorTryLater:
      "Quelque chose s'est mal passé. Veuillez réessayer plus tard",
    ChooseNewConsultation: "choisissez une nouvelle date de consultation",
    ChangedConsultationTo:
      "Vous avez changé votre rendez-vous médical pour le :",
    At: "à :",
    Confirm2: "Confirmer",
  },
  en: {
    title: "Dashboard",
    TodayIs: "Today is",
    UpcomingConsultations: "My upcoming consultations",
    Start: "Start",
    Update: "Update",
    MyDoctor: "My attending doctor",
    Noconsultation: "You don't have consultations",
    NextAvailableConsultation: "Next available consultation:",
    MyRecentDocuments: "My recent documents",
    ConsultationOf: "Consultation of :",
    Update2: "Updated at",
    NoDoc: "You don't have any documents yet",
    SuccessChangeDoctor: "You have successfully changed your treating doctor",
    SelectNewDoctor: "Please select your new doctor",
    ConfirmChangeAttendingDoctor:
      "You are about to change your attending doctor",
    Confirm: "Confirm",
    Doctor: "Doctor",

    //UpdateConsultation
    SelectConsultationDate: "Please select your new consultation date",
    SuccessUpdateConsultation:
      "Your consultation has been successfully updated",
    ErrorTryLater: "Something went wrong. Please try again later",
    ChooseNewConsultation: "Choose a new consultation date",
    ChangedConsultationTo: "You have changed your medical consultation to:",
    At: "at :",
    Confirm2: "Confirm",
  },
};
