import { Formik } from 'formik';
import React from 'react'
import Button from '../../components/Button';
import Input from '../../components/Input';
import { userAtom } from '../../state/auth'
import { useAtom } from 'jotai';
import { updateMotifDoctor } from '../../api/motif';
import { useLocation, useParams } from 'react-router';
import { IValues,IMotif,IParams } from './interfaces';


const EditMotif : React.FC = ()=> {
    const [user]=useAtom(userAtom);
    const {id} = useParams<IParams>()
    const {state} = useLocation<IMotif>()
    
    return (
    <div className="m-4">
      <Formik
        initialValues={{
          name: state.name,
          doctorName: user?.firstName+" "+user?.lastName,
        }}
        onSubmit={async(
          values: IValues,
        ) => {
            await updateMotifDoctor({...values, id});
        }}
      >
        {(props) => {
          const {
            values,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <form onSubmit={handleSubmit} className="flex-auto py-7 px-8">
              <div className="space-x-2 flex flex-wrap text-sm font-light text-black">
                <Input
                  id="doctorName"
                  labelText="Nom du docteur"
                  placeholder=""
                  type="text"
                  error={errors.doctorName}
                  value={values.doctorName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelProps={{ className: "w-full" }}
                  disabled
                />
              </div>
              <div className="space-x-2 flex flex-wrap text-sm font-light text-black">
                <Input
                  id="name"
                  labelText="Motif du consultation"
                  placeholder="Entrez votre motif"
                  type="text"
                  error={errors.name}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelProps={{ className: "w-full" }}
                />
              </div>
              <div className="flex space-x-3 mt-3 text-sm font-semibold uppercase">
                <div className="flex-auto flex space-x-3 flex-row-reverse justify-center">
                  <Button
                    type="submit"
                    status="warning"
                    disabled={isSubmitting || Object.keys(errors).length > 0}
                  >
                    Modifier motif
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
    )
}

export default EditMotif