import { useEffect, useState } from "react";
import io from "socket.io-client";
import { useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import timeZone from "../helper/timeZone";
import { updateDoctorData } from "../api/doctor";
import makeToast from "../components/Snackbar";
import SideBar from "../components/SideBar/SideBarDoctor";
import SideBarTN from "../components/SideBar/SideBarDoctorTN";
import { socketAtom } from "../state/socket";

import "./styles-layout-doctor.scss";

const KatomiEndPoint =
  process.env.REACT_APP_KATOMI_API_URL || "http://localhost:8080";
const tenant = process.env.REACT_APP_KATOMI_TENANT;

const LayoutDoctor = ({ children }) => {
  const [socket, setSocket] = useAtom(socketAtom) as any;
  console.log(
    "🚀 ~ file: LayoutDoctor.tsx:20 ~ LayoutDoctor ~ socket:",
    socket?.id
  );
  const [showSideBar, setShowSideBar] = useState(true);

  const setupSocket = () => {
    const newSocket = io(KatomiEndPoint, {
      withCredentials: true,
    });
    newSocket.on("disconnect", () => {
      setSocket(null);
    });
    newSocket.on("connection", () => {
      makeToast("success", "Connected!");
    });
    newSocket.on("me", (id) => {
      localStorage.setItem("socketId", id);
    });
    setSocket(newSocket);
  };

  const _saveDoctorTimeZone = async () => {
    try {
      const payload = {
        timeZone,
      };
      await updateDoctorData(payload);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    _saveDoctorTimeZone();
    if (!socket) {
      setupSocket();
    }
    //eslint-disable-next-line
  }, []);

  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname.slice(0, 13) === "/consultation" ||
      location.pathname.slice(0, 8) === "/preview" ||
      location.pathname.slice(0, 17) === "/teleconsultation"
      // location.pathname.slice(0, 9) === "/creation"
    )
      setShowSideBar(false);
    else setShowSideBar(true);
  }, [location.pathname]);

  switch (tenant) {
    case "katomi":
      return (
        <div className="layout-doctor flex">
          {showSideBar && <SideBar />}
          <div className="w-full md:pl-4">{children}</div>
        </div>
      );
    case "katomiTN":
      return (
        <div className="layout-doctor flex">
          {showSideBar && <SideBarTN />}
          <div className="w-full md:pl-4">{children}</div>
        </div>
      );

    default:
      return (
        <div className="layout-doctor flex">
          {showSideBar && <SideBar />}
          <div className="w-full md:pl-4">{children}</div>
        </div>
      );
  }
};

export default LayoutDoctor;
