export const ModalFichePatient = {
  fr: {
    //AddAntecedentChirurgicaux
    selectSurgeryType: "Veuillez sélectionner le type de chirurgie",
    EnterAtLeastOneIntervention: "Veuillez saisir au moins une intervention",
    SuccessAddSurgicalHistory: "Antécédent chirurgical ajouté avec succès.",
    FailAddSurgicalHistory: "Ajout antécédent chirurgical a échouhé.",
    AddASurgicalHistory: "Ajouter un antécédent chirurgical",
    GeneralSurgery: "Chirurgie Générale",
    PlasticSurgery: "Chirurgie Esthétique",
    Intervention: "Intervention",
    Observation: "Observation",
    interventionDate: "Date d'intervention (jj/mm/aaaa)",
    EnterObservation: "Entrer votre observation",
    Add: "Ajouter",
    //  ModalFichePatient
    ChooseTypeAntecedent: "Veuillez choisir type d'antécédent",
    SelectMedicalAntecedent: "Veuillez sélectionner un antécédent médical",
    FillAllRequireFields: "Veuillez remplir les champs obligatoires",
    ChooseTypeOfAntecedent: "Veuillez saisir le type d'antécédent",
    SuccessMedicalAntecdent: "antécédent médical ajouté avec succès.",
    SuccessAddedAllergy: "Allergie ajoutée avec succès.",
    AddHistoryField: "Ajout antécédent médical a échoué.",
    AddHistory: "ajouter un antécédent médical ou une allergie",
    MedicalHistory: "Antécédent Médical",
    SurgicalHistory: "Antécédent chirurgical",
    medicalAntecedent: "Autre antécédent Médical",
    Allergy: "Allergie",
    DateOfOnsetOfIllness: "Date de début de la maladie (jj/mm/aaaa)",
    Treatment: "Traitement",
    Filter: "Filtrer",
    //addObservation
    SuccessAddSurvey: "Observation ajoutée avec succès.",
    FailAddObservation:
      "Un problème est survenu lors de l'ajout d'observation.",
    AddObservation: "Ajouter une observation",
    TypeObservation: "Tapez ici votre observation",
    Confirm: "Valider",
    //addTraitement
    EnterTreamentName: "Vous devez saisir le nom de traitement",
    SuccessAddedObservation: "Traitement ajouté avec succès.",
    SuccessFailedTreatment: "L'ajout du traitement a échoué.",
    AddTreatment: "Ajoutez un traitement",
    OngoingTreatment: "Traitement en cours",
    OldTreatment: "Ancien Traitement",
    TradeName: "Nom commercial",
    StartDateTreatment: "Date début du traitement",
    //DisplayPrescription
    ListPrescription: "Liste des prescriptions",
    Medication: "Medicament :",
    Dosage: "Dosage",
    EmptyReport: "Ce rapport est vide",
    //DisplayRaport
    DoctorReport: "Rapport du docteur",
    //FilterModal
    SelectCity: "select ville",
    Find: "Chercher",
    //ShowObservations
    Observations: "Observation(s)",
    ObservationsDoctor: "Observation du docteur",
    at: "le",
    NoObservationsExist: "N'existe aucune observation.",

    Diabetes: "Diabéte",
    CardiovascularDisease: "Maladie Cardiovasculaire",
    AutoimmuneDisease: "Maladie Autoimmune",
    HerpesOrFeverBlister: "Herpes ou Bouton de fièvre",
    Other: "Autres",

    AddAntecedent: "Ajout d'un antécédent médicaux",
    NameAntecdent: "Nom d'antécédent",
    Concentment:
      "Je confirme avoir obtenu le consentement de mon patient pour partager tout ou partie de son dossier avec un confrère",
    Cancel: "Annuler",
    Tunisia: "Tunisie",
    France: "France",
  },
  en: {
    //AddAntecedentChirurgicaux
    Filter: "Filtrer",
    Tunisia: "Tunisia",
    France: "France",
    selectSurgeryType: "select surgery type",
    EnterAtLeastOneIntervention: "Enter at least one intervention",
    SuccessAddSurgicalHistory: "Success add surgical history",
    FailAddSurgicalHistory: "Fail to add surgical history",
    AddASurgicalHistory: "Add surgical history",
    GeneralSurgery: "General surgery",
    PlasticSurgery: "Plastic surgery",
    Intervention: "Intervention",
    Observation: "Observation",
    interventionDate: "Intervention date (DD/MM/YYYY)",
    EnterObservation: "Enter your observation",
    Add: "Add",
    //  ModalFichePatient
    ChooseTypeAntecedent: "Please choose type of antecedent",
    SelectMedicalAntecedent: "Please select a medical antecedent",
    FillAllRequireFields: "Please complete all required fields",
    ChooseTypeOfAntecedent: "Please choose type of antecedent",
    SuccessMedicalAntecdent: "Medical antecedent added successfully.",
    SuccessAddedAllergy: "Allergy added successfully.",
    AddHistoryField: "Add medical antecedent failed.",
    AddHistory: "add a medical antecedent or an allergy",
    MedicalHistory: "Medical antecedent",
    medicalAntecedent: "Other medical antecedent",
    Allergy: "Allergy",
    DateOfOnsetOfIllness: "Date of onset of illness (jj/mm/aaaa)",
    Treatment: "Treatment",

    //addObservation
    SuccessAddSurvey: "Observation added successfully.",
    FailAddObservation: "There was a problem adding an observation.",
    AddObservation: "Add an observation",
    TypeObservation: "Type your observation here",
    Confirm: "Confirm",
    //addTraitement
    EnterTreamentName: "You must enter the treatment name",
    SuccessAddedObservation: "treatment successfully added.",
    SuccessFailedTreatment: "Failed to add treatment.",
    AddTreatment: "Add a treatment",
    OngoingTreatment: "Ongoing treatment",
    OldTreatment: "Old Treatment",
    TradeName: "Trade name",
    Dosage: "Dosage",
    StartDateTreatment: "Start date of treatment",
    //DisplayPrescription
    ListPrescription: "List of prescriptions",
    Medication: "Medication :",

    EmptyReport: "This report is empty",
    //DisplayRaport
    DoctorReport: "Doctor report",
    //FilterModal
    SelectCity: "select city",
    Find: "Find",
    //ShowObservations
    Observations: "Observation(s)",
    ObservationsDoctor: "Doctor Observation",
    NoObservationsExist: "No observations exist.",
    at: "at",

    Diabetes: "Diabetes",
    CardiovascularDisease: "Cardiovascular Disease",
    AutoimmuneDisease: "Autoimmune disease",
    HerpesOrFeverBlister: "Herpes or Fever Blister",
    Other: "Other",

    AddAntecedent: "Add a medical history",
    NameAntecdent: "Antecedent name",
    SurgicalHistory: "Surgical history",
    Concentment:
      "I confirm that I have obtained my patient's consent to share all or part of his file with a colleague",
    Cancel: "Cancel",
  },
};
