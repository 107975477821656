/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
import _ from "lodash";
import useCountDown from "react-countdown-hook";
import moment from "../../../helper/momentFr";
import { CPS, INS } from "../../../api/devbox";
import { useDropzone } from "react-dropzone";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import Button from "../../../components/ButtonUI";
import { INSType, statusINS } from "../../../helper/insHelper";
import { signupPatientPatientelo } from "../../../api/patient";
import CountryWithPhoneSuffix from "../../../config/constants/pays.json";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import Tooltip from "../../../components/toolTip";
import cake from "../../../assets/images/cake.png";
import { dropdownSexeOptions } from "../../../helper/insHelper";
import requireInput from "../../../assets/images/require.png";
import { getAllMAtriculeINS } from "../../../api/doctor";
import makeToast from "../../../components/Snackbar";
import fr from "date-fns/locale/fr";
import { useTranslation } from "react-i18next";
import { lot1, MLot1, MLot2, MLot3 } from "./HELPER_INSI";
registerLocale("fr", fr);
setDefaultLocale("fr");
const Step2 = ({
  values,
  handleChange,
  handleBlur,
  valueConfirmed,
  selectedRegionState,
  setSelectedRegionState,
  regionState,
  selectedDepartementState,
  setSelectedDepartementState,
  departementState,
  selectedCommunesState,
  setSelectedCommunesState,
  setFieldValue,
  communesState,
  startDateINS,
  setStartDateINS,
  loadingINS,
  handleVerifyINS,
  setSteps,
  setStartDate,
  loadingBtn,
  user,
}) => {
  const { t } = useTranslation() as any;
  const [, setMatriculeINS] = useState([]) as any;
  const [testIndex, setTestIndex] = useState(1) as any;
  const [file] = useState<any>({});
  const [minValue] = useState<number>(1);
  const [maxValue] = useState<number>(100);

  const [timeLeft, { start, pause, resume, reset }] = useCountDown(60, 1000);

  const [lotDetails, setLotDetails] = useState() as any;
  const [lotResult, setLotResult] = useState() as any;
  const { getRootProps, getInputProps } = useDropzone({
    // accept: "",
    multiple: false,
    onDrop: async (acceptedFiles) => {
      let formData = new FormData();
      const file = acceptedFiles[0] as any;
      formData.append("file", file);
      await INS.injection(formData);
      makeToast("success", "L'injection est réalisée avec succès.");
      //read file
      const reader = new FileReader() as any;
      reader.readAsText(file);
      reader.onloadend = (evt) => {
        const readerData = evt.target.result;
        const parser = new DOMParser();
        const doc = parser.parseFromString(readerData, "application/xml");
        const errorNode = doc.querySelector("parsererror");
        if (errorNode) {
          console.log("error while parsing");
        } else {
          let xmlLastName =
            doc?.getElementsByTagName("ns2:NomNaissance")[0]?.innerHTML;
          let xmlFirstName =
            doc?.getElementsByTagName("ns2:ListePrenom")[0]?.innerHTML;
          let xmlSexe = doc?.getElementsByTagName("ns2:Sexe")[0]?.innerHTML;
          let xmlBirthDate =
            doc?.getElementsByTagName("ns2:DateNaissance")[0]?.innerHTML;
          let xmlBirthPlace =
            doc?.getElementsByTagName("ns2:LieuNaissance")[0]?.innerHTML;
          let xmlId =
            doc?.getElementsByTagName("ns2:NumIdentifiant")[0]?.innerHTML;
          let xmlCode = doc?.getElementsByTagName("ns2:Cle")[0]?.innerHTML;

          if (xmlLastName && xmlFirstName && xmlSexe && xmlBirthDate) {
            setFieldValue("matriculeINS", String(xmlId) + String(xmlCode));
            setStartDateINS(
              new Date(moment(xmlBirthDate, "YYYY-MM-DD").format("MM/DD/YYYY"))
            );
            setFieldValue("birthAddressINS", xmlBirthPlace);
            setFieldValue("firstNameINS", xmlFirstName);
            setFieldValue("NameINS", xmlLastName);
            setFieldValue("INSSexe", xmlSexe);
          }
        }
      };
    },
  });
  const _getAllMAtriculeINS = async () => {
    try {
      const response = await getAllMAtriculeINS();
      if (!_.isEmpty(response)) setMatriculeINS(response);
    } catch (error) {}
  };

  useEffect(() => {
    _getAllMAtriculeINS();
  }, []);

  // const countdown = useCountdown({
  //     seconds: 60,
  //     onCompleted: () => alert("onCompleted"),
  //   });

  //  <div className="w-full mb-2">
  //         <h3 className="">Test Unitaire</h3>
  //         <Button type="button" height="40px" width="175px" onClick={async () => {
  //           // await Promise.all(lot1.map(async (el) => await INS.verification(lot1[0])))
  //           await INS.verification(lot1[0])
  //           await INS.verification(lot1[1])
  //           await INS.verification(lot1[2])
  //           await INS.verification(lot1[3])
  //           await INS.verification(lot1[4])
  //           await INS.verification(lot1[5])
  //           await INS.verification(lot1[6])
  //           await INS.verification(lot1[7])
  //           await INS.verification(lot1[8])
  //           await INS.verification(lot1[9])
  //           await INS.verification(lot1[10])
  //           await INS.verification(lot1[11])
  //           await INS.verification(lot1[12])
  //           await INS.verification(lot1[13])
  //           await INS.verification(lot1[14])
  //         }}>Start</Button>
  //       </div>

  return (
    <>
      <div className="w-full mb-2">
        <h3 className="">Test Unitaire</h3>
        {/* <input
              type="text"

              name="message"

              onKeyDown={async (e: any) => {
                if (e.key === 'Enter') {
                  // 👇 Get input value

                  await INS.verification(lot1[e.target.value])
                }
              }}
            /> */}
        <input
          type="text"
          name="message"
          value={testIndex}
          onChange={(e) => setTestIndex(e.target.value)}
        />
        <Button
          type="button"
          height="40px"
          width="100%"
          className="my-5"
          onClick={async () => {
            if (!_.isEmpty(lot1[testIndex - 1])) {
              const lotDetails = lot1[testIndex - 1];

              let controllNull = "";
              if (!lotDetails?.ins?.matriculeINS?.cle) controllNull += " cle";
              if (!lotDetails?.ins?.matriculeINS?.identifiantSysteme)
                controllNull += " identifiantSysteme";
              if (!lotDetails?.ins?.matriculeINS?.valeur)
                controllNull += " matriculeINS";
              if (!lotDetails?.ins?.traitsIdentite?.dateNaissance)
                controllNull += " dateNaissance";
              if (!lotDetails?.ins?.traitsIdentite?.nomFamille)
                controllNull += " nomFamille";
              if (!lotDetails?.ins?.traitsIdentite?.prenomActeNaissance)
                controllNull += " prenomActeNaissance";
              if (!lotDetails?.ins?.traitsIdentite?.prenomUtilise)
                controllNull += " prenomUtilise";
              if (!lotDetails?.ins?.traitsIdentite?.sexeAdministratif)
                controllNull += " sexeAdministratif";
              if (controllNull)
                return makeToast("warning", "Il manque " + controllNull);
            }
            try {
              await INS.verification(lot1[testIndex - 1]);
            } catch (error) {
              return makeToast("error", "La vérification a échouée");
            }
          }}
        >
          Vérification
        </Button>
        <Button
          type="button"
          height="40px"
          width="100%"
          className="my-5"
          onClick={async () => {
            // context: {
            //   structure: {
            //     identifiantFacturation: "123456789",
            //   },
            //   auteur: {},
            // },
            // ins: {
            //   matriculeINS: {
            //     cle: "60",
            //     identifiantSysteme: "1.2.250.1.213.1.4.8",
            //     valeur: "1010663220754",
            //   },
            //   traitsIdentite: {
            //     dateNaissance: "2001-06-17",
            //     lieuNaissance: "63220",
            //     nomFamille: "D'ARTAGNAN DE L'HERAULT",
            //     prenomActeNaissance: "PIERRE-ALAIN GUNTHER",
            //     prenomUtilise: "PIERRE-ALAIN GUNTHER",
            //     sexeAdministratif: "M",
            //   },
            // },
            // lot1[testIndex - 1]
            if (!_.isEmpty(lot1[testIndex - 1])) {
              const lotDetails = lot1[testIndex - 1];

              let controllNull = "";
              if (!lotDetails?.ins?.matriculeINS?.cle) controllNull += " cle";
              if (!lotDetails?.ins?.matriculeINS?.identifiantSysteme)
                controllNull += " identifiantSysteme";
              if (!lotDetails?.ins?.matriculeINS?.valeur)
                controllNull += " matriculeINS";
              if (!lotDetails?.ins?.traitsIdentite?.dateNaissance)
                controllNull += " dateNaissance";
              if (!lotDetails?.ins?.traitsIdentite?.lieuNaissance)
                controllNull += " lieuNaissance";
              if (!lotDetails?.ins?.traitsIdentite?.nomFamille)
                controllNull += " nomFamille";
              if (!lotDetails?.ins?.traitsIdentite?.prenomActeNaissance)
                controllNull += " prenomActeNaissance";
              if (!lotDetails?.ins?.traitsIdentite?.prenomUtilise)
                controllNull += " prenomUtilise";
              if (!lotDetails?.ins?.traitsIdentite?.sexeAdministratif)
                controllNull += " sexeAdministratif";
              if (controllNull)
                return makeToast("warning", "Il manque " + controllNull);
            }
            const data = await INS.verification(lot1[testIndex - 1]);
            if (!data || !_.isEmpty(data?.cause))
              makeToast("warning", "La vérification a échoué");
            const verifCard = await CPS.readerInfo();

            if (verifCard) {
              await INS.verification(lot1[testIndex - 1]);
              const INSPaylaod = {
                dateNaissance: startDateINS
                  ? moment(
                      lot1[testIndex - 1].ins.traitsIdentite.dateNaissance,
                      "YYYY-MM-DD"
                    ).format("DDMMYYYY")
                  : moment(
                      lot1[testIndex - 1].ins.traitsIdentite.dateNaissance,
                      "YYYY-MM-DD"
                    ).format("DDMMYYYY"),
                lieuNaissance:
                  lot1[testIndex - 1].ins.traitsIdentite.lieuNaissance,

                nomNaissance: lot1[testIndex - 1].ins.traitsIdentite.nomFamille,
                prenom: lot1[
                  testIndex - 1
                ].ins.traitsIdentite.prenomActeNaissance
                  .split(" ")
                  .join(","),
                sexe: lot1[testIndex - 1].ins.traitsIdentite.sexeAdministratif,
                prenoms:
                  lot1[
                    testIndex - 1
                  ].ins.traitsIdentite.prenomActeNaissance.split(" "),

                context: {
                  structure: { identifiantFacturation: "123456789" },
                  auteur: {},
                },
              };
              const responseINS = await INS.searchWithoutCard(INSPaylaod, true);

              await signupPatientPatientelo({
                doctor: user?._id,
                email:
                  lot1[testIndex - 1].ins.traitsIdentite.nomFamille
                    .split(" ")
                    .join("") +
                  lot1[testIndex - 1].ins.traitsIdentite.prenomActeNaissance
                    .split(" ")
                    .join("-") +
                  "@gmaaill.com",
                lastName: lot1[testIndex - 1].ins.traitsIdentite.nomFamille,
                firstName:
                  lot1[testIndex - 1].ins.traitsIdentite.prenomActeNaissance,
                birthDate: lot1[testIndex - 1].ins.traitsIdentite.dateNaissance,
                sexe:
                  lot1[testIndex - 1].ins.traitsIdentite.sexeAdministratif ===
                  "F"
                    ? ["woman"]
                    : ["man"],
                phone: Math.floor(100000000 + Math.random() * 900000000),
                adresse: "adresse",
                numSecSocial: "012345678912345",
                postalCode: "12345",
                country: "France",
                pregnant: false,
                INS: {
                  INSStaus: statusINS(
                    "idvalid",
                    !_.isEmpty(responseINS?.insActif)
                  ),
                  responseINS: responseINS,
                  INSValue: {
                    identifiantSysteme: INSType[
                      responseINS?.insActif?.identifiantSysteme
                    ] as any,
                    dateNaissance: moment(
                      lot1[testIndex - 1].ins.traitsIdentite.dateNaissance
                    ).format("DDMMYYYY"),
                    lieuNaissance:
                      lot1[testIndex - 1].ins.traitsIdentite.dateNaissance ||
                      null,
                    nomNaissance:
                      lot1[testIndex - 1].ins.traitsIdentite.nomFamille,
                    prenom: lot1[
                      testIndex - 1
                    ].ins.traitsIdentite.prenomActeNaissance
                      .split(" ")
                      .join(","),
                    sexe: lot1[testIndex - 1].ins.traitsIdentite
                      .sexeAdministratif,
                    prenoms:
                      lot1[
                        testIndex - 1
                      ].ins.traitsIdentite.prenomActeNaissance.split(" "),

                    context: {
                      structure: { identifiantFacturation: null },
                      auteur: {},
                    },

                    matriculeINS: responseINS?.insActif?.valeur,
                  },
                },
              });
            }
          }}
        >
          Ajout patient
        </Button>
      </div>

      {/*<div className="w-full">
        <h3>Vérification par Lot </h3>

         <div className="flexCenter flex-col">
              <div className="flex items-center gap-4 mt-4">
                <p>minimum</p>{" "}
                <input
                  type="number"
                  value={minValue}
                  onChange={(e) => setMinValue(+e.target.value)}
                  className="w-20"
                />
              </div>
              <div className="flex items-center gap-4 mt-4">
                <p>maximum</p>{" "}
                <input
                  type="number"
                  value={maxValue}
                  onChange={(e) => setMaxValue(+e.target.value)}
                  className="w-20"
                />
              </div>
            </div> 

        <div className="">
          <div className="flex justify-around">
            <Button
              type="button"
              height="40px"
              width="30%"
              className="my-5"
              onClick={async () => {
                await CPS.readerInfo();
                if (MLot1.length >= minValue && MLot1.length <= maxValue) {
                  const res1 = await INS.verificationLot({
                    context: {
                      structure: {
                        identifiantFacturation: "123456789",
                      },
                      auteur: {},
                    },
                    lotIdentifiants: MLot1.map((el) => ({ ...el.ins })),
                  });
                  setLotDetails(res1);
                  if (res1?.delaiEnSeconde) start(res1?.delaiEnSeconde * 1000);
                } else
                  makeToast(
                    "warning",
                    `Le nombre de vérification n'est pas entre l'intervale des valeurs min et max [${minValue},${maxValue}]`
                  );
              }}
            >
              Lot 1
            </Button>
            <Button
              type="button"
              height="40px"
              width="30%"
              className="my-5"
              onClick={async () => {
                await CPS.readerInfo();
                if (MLot2.length >= minValue && MLot2.length <= maxValue) {
                  const res2 = await INS.verificationLot({
                    context: {
                      structure: {
                        identifiantFacturation: "123456789",
                      },
                      auteur: {},
                    },
                    lotIdentifiants: MLot2.map((el) => ({ ...el.ins })),
                  });
                  setLotDetails(res2);
                  if (res2?.delaiEnSeconde) start(res2?.delaiEnSeconde * 1000);
                } else
                  makeToast(
                    "warning",
                    `Le nombre de vérification n'est pas entre l'intervale des valeurs min et max [${minValue},${maxValue}]`
                  );
              }}
            >
              Lot 2
            </Button>
            <Button
              type="button"
              height="40px"
              width="30%"
              className="my-5"
              onClick={async () => {
                await CPS.readerInfo();
                if (MLot3.length >= minValue && MLot3.length <= maxValue) {
                  const res3 = await INS.verificationLot({
                    context: {
                      structure: {
                        identifiantFacturation: "123456789",
                      },
                      auteur: {},
                    },
                    lotIdentifiants: MLot3.map((el) => ({ ...el.ins })),
                  });
                  setLotDetails(res3);
                  if (res3?.delaiEnSeconde) start(res3?.delaiEnSeconde * 1000);
                } else
                  makeToast(
                    "warning",
                    `Le nombre de vérification n'est pas entre l'intervale des valeurs min et max [${minValue},${maxValue}]`
                  );
              }}
            >
              Lot 3
            </Button>
          </div>
          <div className="w-full ">
            <h3>Information de lot </h3>
            <p>idLot: {lotDetails?.identifiantLot?.idLot} </p>
            <p>
              nbDocReq: {lotDetails?.identifiantLot?.nbDocReq}
              <span className="pl-10">
                {lotDetails?.identifiantLot?.nbDocReq >= minValue &&
                  lotDetails?.identifiantLot?.nbDocReq <= maxValue &&
                  `✔ Nombre de vérifications est entre [${minValue},${maxValue}]`}
              </span>
            </p>
            <p>
              horodatageLotReponse:{" "}
              {lotDetails?.identifiantLot?.horodatageLotReponse &&
                moment(lotDetails?.identifiantLot?.horodatageLotReponse).format(
                  "LLLL"
                )}{" "}
            </p>
            <p>delaiEnSeconde {lotDetails?.delaiEnSeconde}</p>
            <Button
              type="button"
              height="40px"
              width="100%"
              className="my-5"
              onClick={async () => {
                if (timeLeft <= 0) {
                  makeToast(
                    "warning",
                    "Le délai de traitement estimé est expiré"
                  );
                } else {
                  const resLot = await INS.verificationLotWithResult(
                    lotDetails?.identifiantLot?.idLot
                  );
                  makeToast(
                    "success",
                    "La vérification a été effectuée avec succès"
                  );
                  setLotResult(resLot);
                }
              }}
            >
              Résultat {timeLeft > 0 && timeLeft / 1000}
            </Button>
            {lotResult?.compteRendu?.valeur && (
              <div className="mb-5">
                <h3>Résultat</h3>
                {lotResult.resultats.map((el, i) => (
                  <p
                    className={`${
                      el?.compteRendu?.valeur == "00"
                        ? "text-green-500"
                        : "text-red-500"
                    }`}
                  >
                    {i} - {el?.compteRendu?.libelle}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>*/}

      <div className="w-full mb-2">
        <h3 className="">{t("AddPatient.Injection")}</h3>
      </div>
      <section className="container cursor-pointer">
        <div {...getRootProps({ className: "dropzone flexCenter" })}>
          <input {...getInputProps()} />
          <p>{t("Profile.uploadXML")}</p>
        </div>
        <aside className="thumbsContainer gap-4 mt-4 ml-4 mb-4">
          {!_.isEmpty(file) && <p>{file?.name}</p>}
        </aside>
      </section>

      <Button
        width="300px"
        height="40px"
        onClick={async () => {
          try {
            await INS.clearInjection();
            makeToast("success", t("AddPatient.successClear"));
          } catch (error) {
            console.log(
              "🚀 ~ file: Step2_Test.tsx:567 ~ onClick={ ~ error:",
              error
            );
            makeToast("error", t("DoctorMoveMeeting.SomethingWentWrong"));
          }
        }}
      >
        {t("AddPatient.clearInjection")}
      </Button>
      {/*
      <div className="w-full my-2">
        <h3> {t("AddPatient.Trait")}</h3>
      </div>
      <div>
        <Input
          id="firstNameINS"
          placeholder={t("AddPatient.firstNameS2")}
          type="text"
          value={values.firstNameINS}
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
          }}
          className="mb-2.5"
          iconColor="white"
          isIcon={true}
          icon="user"
          image="require"
          right={true}
          stylesDiv="relative"
          imageCLS="w-4 max-h-1"
          disabled={valueConfirmed}
        />
        <Input
          id="NameINS"
          placeholder={t("AddPatient.birthName")}
          type="text"
          value={values.NameINS}
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
          }}
          className="mb-2.5"
          iconColor="white"
          isIcon={true}
          icon="user"
          image="require"
          right={true}
          stylesDiv="relative"
          imageCLS="w-4 max-h-1"
          disabled={valueConfirmed}
        />
        <Select
          id="country"
          value={values.pays}
          divProps="w-full"
          className="mb-2.5 w-full rounded-lg pl-3"
          options={CountryWithPhoneSuffix.map((el) => ({
            value: el.name,
            label: el.name,
          }))}
          onChange={handleChange}
          onBlur={handleBlur}
          text={t("AddPatient.regions")}
          required
        />

        {values.country === "France" && (
          <>
            <Select
              text={t("AddPatient.regions")}
              className="mb-2.5 w-full rounded-lg pl-3"
              value={selectedRegionState ? selectedRegionState.value : ""}
              divProps="w-full"
              options={regionState}
              onChange={(e) => {
                setSelectedRegionState(
                  regionState.find((elm) => elm.value == e.target.value)
                );
              }}
            />
            {selectedRegionState?.value && (
              <Select
                text={t("AddPatient.departments")}
                className="mb-2.5 w-full rounded-lg pl-3"
                divProps="w-full"
                value={
                  selectedDepartementState ? selectedDepartementState.value : ""
                }
                options={departementState}
                onChange={(e) => {
                  setSelectedDepartementState(
                    departementState.find((elm) => elm.value == e.target.value)
                  );
                }}
              />
            )}
            {selectedDepartementState?.value && (
              <Select
                text={t("AddPatient.commune")}
                className="mb-2.5 w-full rounded-lg pl-3"
                divProps="w-full"
                value={selectedCommunesState ? selectedCommunesState.value : ""}
                options={communesState}
                onChange={(e) => {
                  setSelectedCommunesState(
                    communesState.find((elm) => elm.value == e.target.value)
                  );
                  setFieldValue(
                    "birthAddressINS",
                    communesState.find((elm) => elm.value == e.target.value)
                      ?.code
                  );
                }}
              />
            )}
          </>
        )}
        <Input
          id="birthAddressINS"
          placeholder={t("AddPatient.placeOfBirth")}
          type="text"
          value={values.birthAddressINS}
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
          }}
          className="mb-2.5"
          icon="home"
          iconColor="white"
          isIcon={true}
          disabled={valueConfirmed}
        />
        <div className="relative">
          <DatePicker
            id="birthDateINS"
            locale="fr"
            className="custom-Input mb-2.5 "
            selected={startDateINS}
            dateFormat="ddMMyyyy"
            onChange={(date) => {
              console.log(date);
              setStartDateINS(date);
            }}
            placeholderText={t("AddPatient.dateOfBirth")}
            autoComplete="off"
            showYearDropdown
            disabled={valueConfirmed}
          />
          <img
            crossOrigin="anonymous"
            referrerPolicy="origin"
            src={cake}
            alt="cake"
            className="absolute top-3 left-3"
            width="18"
          />
          <img
            crossOrigin="anonymous"
            referrerPolicy="origin"
            src={requireInput}
            alt="require"
            className="absolute right-3 top-3 w-1.5 h-1.5"
          />
        </div>
        <Select
          id="INSSexe"
          value={values.INSSexe}
          divProps="w-full"
          className="mb-2.5 w-full rounded-lg pl-3"
          options={dropdownSexeOptions}
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
          }}
          text={t("AddPatient.YourSex")}
          required
          disabled={valueConfirmed}
        />

        <div className="relative ">
          <Input
            id="matriculeINS"
            placeholder={t("AddPatient.INSNumber")}
            type="text"
            value={values.matriculeINS}
            onChange={handleChange}
            onBlur={(e) => {
              handleBlur(e);
            }}
            className="mb-5"
            image2="securite-sociale"
            right={true}
            stylesDiv="relative"
            imageCLS="w-4 max-h-1"
            disabled={valueConfirmed}
          />
          {!valueConfirmed && (
            <div className=" absolute top-2 right-2">
              <Tooltip
                tooltipText={t("AddPatient.VerificationINS")}
                position="top"
              >
                {loadingINS ? (
                  <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-purple-500" />
                ) : (
                  <img
                    crossOrigin="anonymous"
                    referrerPolicy="origin"
                    alt="search"
                    src={
                      require("../../../assets/icons/icon-search.svg").default
                    }
                    width="35"
                    className="cursor-pointer icon-blue"
                    onClick={() => handleVerifyINS(values, setFieldValue)}
                  />
                )}
              </Tooltip>
            </div>
          )}{" "}
          {valueConfirmed && (
            <Input
              id="identifiantSysteme"
              type="text"
              value={values.identifiantSysteme}
              onChange={handleChange}
              onBlur={(e) => {
                handleBlur(e);
              }}
              className="mb-5"
              right={true}
              stylesDiv="relative"
              disabled
            />
          )}
        </div>

        {/* {matriculeINS && matriculeINS.includes(values.matriculeINS) && (
          <span className="text-red-500">{t("AddPatient.INSExist")}</span>
        )} */}{/*
        <div className="w-full mb-2.5">
          <Input
            id="identity"
            name="identity"
            type="radio"
            onChange={handleChange}
            value="IDDoting"
            labelText={t("AddPatient.SI")}
            labelProps={{
              className: "font-semibold text-sm   pl-2",
            }}
          />
          <div className="mb-2" />
          <Input
            id="identity"
            name="identity"
            type="radio"
            onChange={handleChange}
            value="Idfictional"
            labelText={t("AddPatient.FI")}
            labelProps={{
              className: "font-semibold text-sm   pl-2",
            }}
          />
          <div className="mb-2" />

          <Input
            id="identity"
            name="identity"
            type="radio"
            onChange={handleChange}
            value="idvalid"
            labelText={t("AddPatient.VI")}
            labelProps={{
              className: "font-semibold text-sm   pl-2",
            }}
            defaultChecked
          />
          <div className="mb-2" />

          <Input
            id="identity"
            name="identity"
            type="radio"
            onChange={handleChange}
            value="idNonValid"
            labelText={t("AddPatient.INV")}
            labelProps={{
              className: "font-semibold text-sm   pl-2",
            }}
          />
          <div className="mb-4" />

          <Button
            status="primary"
            height="50px"
            className="mb-2.5 w-full"
            type="button"
            onClick={() => {
              if (
                !startDateINS ||
                !values.NameINS ||
                !values.firstNameINS ||
                !values.INSSexe
              ) {
                return makeToast("warning", t("AddPatient.FillAll"));
              } else if (!values.matriculeINS)
                return makeToast("warning", t("AddPatient.INSRequire"));
              else {
                !values.firstName &&
                  setFieldValue(
                    "firstName",
                    values.firstNameINS.trim().split(",").join(" ")
                  );
                !values.lastName && setFieldValue("lastName", values.NameINS);
                setFieldValue("country", "France");
                setFieldValue(
                  "sexe",
                  values.INSSexe === "M" ? ["man"] : ["woman"]
                );
                setStartDate(new Date(startDateINS));
                setSteps(3);
              }
            }}
            disabled={loadingBtn || loadingINS}
          >
            {t("AddPatient.next")}
          </Button>
        </div>
          </div>*/}
    </>
  );
};

export default Step2;
