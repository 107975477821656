import ICalendarEvent from "../../types/ICalendarEvent";
import Camera from "../../assets/images/camera.png";
import Handshake from "../../assets/images/poigne-de-main.png";
import Syringe from "../../assets/images/syringe.png";
import moment from "../../helper/moment";

type props = {
  timeText: string;
  event: {
    extendedProps: Partial<ICalendarEvent>;
    [property: string]: any;
  };
  [property: string]: any;
  comment?: string;
};

const CalendarEvent: React.FC<props> = ({ timeText, event, ...props }) => {
  const {
    patient,
    status,
    // comment,
    consultationPurpose,
    isTeleconsultation,
    isPresentiel,
    monthView,
    isActe,
    officeColorTag,
    handleEvent,
    startTimeSlot,
    // endTimeSlot,
    consultationStatus,
    AvailableSlotText,
  } = event.extendedProps;

  return (
    // <ToolTip tooltipText={comment || ""}>
    <div
      className={`fc-custom-event-content relative ${
        patient ? "" : "fc-custom-event-open"
      } ${
        status === "off"
          ? "bg-red-200"
          : moment(startTimeSlot).isBefore(moment()) &&
            consultationStatus !== "done"
          ? "bg-red-100"
          : "bg-white"
      }`}
      style={{ borderColor: officeColorTag }}
      onClick={() => handleEvent(timeText)}
    >
      <p className="fc-custom-event-time">{timeText}</p>
      {status !== "off" &&
        (patient ? (
          <>
            <p className="fc-custom-event-patient">
              <span className="fc-custom-event-patient-firstname">
                {patient.firstName || "John"}
              </span>
              <span className="fc-custom-event-patient-lastname">
                {patient.lastName || "Doe"}
              </span>
            </p>
            {!monthView && (
              <span className="fc-custom-event-purpose box">
                {consultationPurpose}
              </span>
            )}
          </>
        ) : (
          <p className="fc-custom-event-open-text">{AvailableSlotText}</p>
        ))}
      {isTeleconsultation && (
        <span className="absolute right-0 top-0.5 ">
          <img
            crossOrigin="anonymous"
            referrerPolicy="origin"
            src={Camera}
            alt="camera"
            width="25"
            style={{
              filter:
                "invert(32%) sepia(89%) saturate(2276%) hue-rotate(221deg) brightness(98%) contrast(80%)",
            }}
          />
        </span>
      )}
      {isPresentiel && (
        <span className="absolute -right-2 -top-2">
          <img
            crossOrigin="anonymous"
            referrerPolicy="origin"
            src={Handshake}
            alt="presentiel"
            width="40"
            style={{
              filter:
                "invert(32%) sepia(89%) saturate(2276%) hue-rotate(221deg) brightness(98%) contrast(97%)",
            }}
          />
        </span>
      )}
      {isActe && (
        <span className="absolute right-0 top-0">
          <img
            crossOrigin="anonymous"
            referrerPolicy="origin"
            src={Syringe}
            alt="act"
            width="20"
            style={{
              filter:
                "invert(32%) sepia(89%) saturate(2276%) hue-rotate(221deg) brightness(98%) contrast(97%)",
            }}
          />
        </span>
      )}
    </div>
  );
};

export default CalendarEvent;
