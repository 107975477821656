import React from "react";
import moment from "../../helper/moment";
import useScreenSize from "../../hooks/ScreenSize";

/**
 * Components
 */
import DoctorDetail from "../../components/DoctorDetails";
import ConfirmModal from "../../components/DoctorDetails/ConfirmMeet";
import TakeMeet from "../../components/DoctorDetails/TakeMeet";
import ConfirmTeleConsultation from "../../components/DoctorDetails/ConfirmTeleConsultation";
import Button from "../../components/ButtonUI";
import Modal from "../../components/ModalUI";
/**
 * Assets
 */
import { ReactComponent as IconMessage } from "../../assets/icons/icon-message.svg";
import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";
import { loadImage } from "../../api/documents";
import { handleSpeciality } from "../../helper/handleSpeciality";
import { useTranslation } from "react-i18next";

const colorAvatar = [
  "#ccd2ff",
  "#d5f0f7",
  "#ffecba",
  "#fff7ab",
  "#ffccd2",
  "#ffe2c8",
];

// interface IDoctorDetails {
//   _id: string;
//   sexe: String;
//   firstName: String;
//   lastName: String;
//   speciality: String;
//   business_photo?: string;
// }

interface Props {
  doctorDetails: any;
  indexCard: number;
  setopenModal: Function;
  maxRow: number;
}

const CardDoctor = (props: Props) => {
  const { i18n } = useTranslation();
  const { doctorDetails, indexCard, setopenModal } = props;
  const { windowDimenion } = useScreenSize();
  const [DoctorCardModal, setDoctorCardModal] = React.useState(false);
  const [takeMeetModal, setTakeMeetModal] = React.useState(false);
  const [openTimeSlots] = React.useState([]) as any;
  const [addedConsultation, setAddedConsultation] = React.useState();
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [confirmTeleconsultationModal, setConfirmTeleconsultationModal] =
    React.useState(false);
  const [meetData, setMeetData] = React.useState();

  const firstOpenTimeSlot = () => {
    for (const openTimeSlot of openTimeSlots)
      for (const slotOfTheDay of openTimeSlot.data)
        if (
          slotOfTheDay.eventStatus === "open" &&
          moment(slotOfTheDay.startTime).isAfter(moment())
        )
          return slotOfTheDay.startTime;
    return null;
  };
  return (
    <>
      <Modal
        showModal={DoctorCardModal}
        slidemodal
        closeModal={() => setDoctorCardModal(false)}
      >
        <DoctorDetail
          closeModal={() => setDoctorCardModal(false)}
          selectSpecialist={doctorDetails}
          openTakeMeetModal={() => {
            setDoctorCardModal(false);
            setTakeMeetModal(true);
          }}
          firstOpenMeet={firstOpenTimeSlot()}
        />
      </Modal>
      <Modal
        showModal={takeMeetModal}
        slidemodal
        closeModal={() => setTakeMeetModal(false)}
      >
        <TakeMeet
          // maxRow={maxRow}
          maxRow={windowDimenion.winWidth > 768 ? 7 : 3}
          closeModal={() => setTakeMeetModal(false)}
          picketDateReservation={null}
          selectSpecialist={doctorDetails}
          takeMeetModal={takeMeetModal}
          openTimeSlots={openTimeSlots}
          openConfirmMeetModal={(addedConsultation) => {
            setAddedConsultation(addedConsultation);
            setConfirmModal(true);
          }}
          openConfirmTeleconsultationModal={(takeMeetData) => {
            setMeetData(takeMeetData);
            setTakeMeetModal(false);
            setConfirmTeleconsultationModal(true);
          }}
        />
      </Modal>
      {/* Add modal Confirm take meeting */}
      <Modal
        showModal={confirmModal}
        closeModal={() => {
          setConfirmModal(false);
        }}
      >
        <ConfirmModal
          closeModal={() => {
            setConfirmModal(false);
          }}
          addedConsultation={addedConsultation}
        />
      </Modal>
      <Modal
        showModal={confirmTeleconsultationModal}
        slidemodal
        closeModal={() => {
          setConfirmTeleconsultationModal(false);
        }}
      >
        <ConfirmTeleConsultation
          closeModal={() => {
            setConfirmTeleconsultationModal(false);
          }}
          meetData={meetData}
          selectSpecialist={doctorDetails}
          openConfirmMeetModal={(addedConsultation) => {
            setAddedConsultation(addedConsultation);
            setConfirmModal(true);
          }}
        />
      </Modal>
      <div className="card-doctor p-3 space-x-3 max-w-max shadow">
        <div
          className="flex cursor-pointer"
          onClick={() => setTakeMeetModal(true)}
        >
          <div
            className="rounded-full w-16 h-16 flexCenter mx-5 my-auto"
            style={{
              backgroundColor: doctorDetails?.business_photo
                ? ""
                : colorAvatar[indexCard % colorAvatar.length],
            }}
          >
            {doctorDetails?.business_photo ? (
              <img
                crossOrigin="anonymous"
                referrerPolicy="origin"
                alt="profile"
                src={
                  doctorDetails?.business_photo.slice(0, 4) === "http"
                    ? doctorDetails?.business_photo
                    : loadImage(
                        doctorDetails?._id,
                        "profile",
                        "thumbnail",
                        doctorDetails?.business_photo
                      )
                }
                className="w-16 h-16 rounded-full shadow-md object-fill"
              />
            ) : doctorDetails?.sexe === "man" ? (
              <ManPatient />
            ) : (
              <WomanPatient />
            )}
          </div>
          <div className="details">
            <div className="flex-grow">
              <p className="card-title">
                {doctorDetails?.firstName} {doctorDetails?.lastName}
              </p>
              <p className="card-subtitle mb-2">
                {handleSpeciality(
                  doctorDetails?.speciality,
                  i18n.language.includes("en")
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="flex space-x-3">
          {/* <Button
            status="icon-secondary"
            onClick={() => setopenModal("shareFolder")}
          >
            <IconFolder />
          </Button> */}
          <Button status="icon-secondary" onClick={() => setopenModal("chat")}>
            <IconMessage />
          </Button>
          {/* <Button status="icon-secondary">
      <ThreeDots />
    </Button> */}
        </div>
      </div>
    </>
  );
};

export default CardDoctor;
