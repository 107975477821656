import React, { useState } from "react";
import TimePicker from "react-time-picker";
import { useTranslation } from "react-i18next";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";

import moment from "../../../helper/moment";
import { AddAFKOrHoliday } from "../../../api/agenda";

import Button from "../../ButtonUI";
import makeToast from "../../Snackbar";

import fr from "date-fns/locale/fr";
registerLocale("fr", fr);
setDefaultLocale("fr");

interface Props {
  setShowAgendaModal: Function;
}

const Conges: React.FC<Props> = ({ setShowAgendaModal }) => {
  const { t } = useTranslation() as any;

  const [holidayDateFrom, setHolidayDateFrom] = useState() as any;
  const [holidayTimeFrom, setHolidayTimeFrom] = useState("08:00") as any;
  const [holidayDateto, setHolidayDateTo] = useState() as any;
  const [holidayTimeto, setHolidayTimeTo] = useState("18:00") as any;
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);

  const _addIndisponibility = async (dateStart, dateEnd) => {
    try {
      setLoadingBtn(true);
      const result = AddAFKOrHoliday(dateStart, dateEnd);
      setLoadingBtn(false);

      return result;
    } catch (error) {
      setLoadingBtn(false);

      console.error(error);
    }
  };

  const handleAddIndisponibility = async () => {
    if (
      !holidayDateFrom ||
      !holidayTimeFrom ||
      !holidayDateto ||
      !holidayTimeto
    )
      return makeToast("warning", t("Agenda.FillLeaveDate"));

    const dateStart = new Date(
      moment(holidayDateFrom).year(),
      holidayDateFrom?.getMonth(),
      parseInt(moment(holidayDateFrom).format("DD")),
      holidayTimeFrom.slice(0, 2),
      holidayTimeFrom.slice(3)
    );
    const dateEnd = new Date(
      moment(holidayDateto).year(),
      holidayDateto?.getMonth(),
      parseInt(moment(holidayDateto).format("DD")),
      holidayTimeto.slice(0, 2),
      holidayTimeto.slice(3)
    );

    await _addIndisponibility(dateStart, dateEnd);
    setShowAgendaModal("");
  };
  return (
    <>
      <div className="flexColCenter">
        <p className="self-start mb-5">{t("Agenda.StartDateLeave")} </p>
        <div className="flex flex-col md:flex-row mb-5 md:mb-auto space-x-10">
          <DatePicker
            id="date"
            locale="fr"
            className="custom-Input mb-8 border boder-gray-300 px-3"
            selected={holidayDateFrom}
            dateFormat="dd/MM/yyyy"
            onChange={setHolidayDateFrom}
            placeholderText={t("Agenda.DateLeave")}
            autoComplete="off"
            showYearDropdown
          />
          <div className="flexCenter sub-values-act ">
            <TimePicker
              onChange={setHolidayTimeFrom}
              format="HH:mm"
              value={holidayTimeFrom}
              onClockClose={() => {
                //setSelectedInput("");
              }}
              onClockOpen={() => {}}
            />
          </div>
        </div>

        <p className="self-start mb-5">{t("Agenda.EndDateLeave")} </p>
        <div className="flex  flex-col md:flex-row  space-x-10">
          <DatePicker
            id="date"
            locale="fr"
            className="custom-Input mb-8 border boder-gray-300 px-3"
            selected={holidayDateto}
            dateFormat="dd/MM/yyyy"
            onChange={setHolidayDateTo}
            placeholderText={t("Agenda.EndLeave")}
            autoComplete="off"
            showYearDropdown
          />
          <div className="flexCenter sub-values-act ">
            <TimePicker
              onChange={setHolidayTimeTo}
              format="HH:mm"
              value={holidayTimeto}
              // onClockClose={() => {
              //   setSelectedInput("");
              // }}
              onClockOpen={() => {}}
            />
          </div>
        </div>

        <div className="flexCenter">
          <Button
            status="primary"
            type="submit"
            width="170px"
            height="50px"
            onClick={handleAddIndisponibility}
            disabled={loadingBtn}
          >
            <div className="relative">
              {loadingBtn && (
                <div className="absolute h-full w-full flexCenter">
                  <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-purple-500"></div>
                </div>
              )}
              {t("Agenda.Save")}
            </div>
          </Button>
        </div>
      </div>
    </>
  );
};

export default Conges;
