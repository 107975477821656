import React, { useEffect, useRef } from "react";
import { Layer } from "react-konva";
import BarCodeGenerator from "./BarCodeGenerator";
import ImageComponenet from "./ImageComponent";
import QrCodeGenerator from "./QrCodeGenetrator";
import RedactionComponent from "./RedactionComponent";
import TextAndInput from "./TextComponent";

/**
 * This is the core layer of React Konva where to found items have been added to your template
 * @param listData,
 * @param setListData,
 * @function setPosition,
 * @function setSelectedInputId,
 * @function setDidEdit,
 * @function selectedInputId,
 * @param isSelected,
 * @function setIselected,
 * @param setTextColor,
 * @param dataSelectedObj,
 * @function setDataSelectedObj,
 * @function setShowModalTextOption,
 * @param mainContainer,
 * @function setShowModalImageOption
 * @returns
 */
export const LayerComponent = ({
  listData,
  setListData,
  setPosition,
  setSelectedInputId,
  setDidEdit,
  selectedInputId,
  isSelected,
  setIselected,
  setTextColor,
  dataSelectedObj,
  setDataSelectedObj,
  setShowModalTextOption,
  setShowModalImageOption,
}) => {
  const mainContainer = useRef() as any;

  useEffect(() => {
    if (mainContainer.current) {
      mainContainer.current.canvas.context.canvas.width = 210 * 3;
      mainContainer.current.canvas.context.canvas.height = 297 * 3;
    }
  }, [mainContainer]);

  return (
    <Layer ref={mainContainer}>
      {listData.length > 0 &&
        listData.map((data, index) => {
          switch (data.type) {
            case "text":
              return (
                <TextAndInput
                  index={index}
                  data={data}
                  listData={listData}
                  setListData={setListData}
                  setPosition={setPosition}
                  setSelectedInputId={setSelectedInputId}
                  setDidEdit={setDidEdit}
                  selectedInputId={selectedInputId}
                  isSelected={isSelected}
                  setIselected={setIselected}
                  setTextColor={setTextColor}
                  dataSelectedObj={dataSelectedObj}
                  setDataSelectedObj={setDataSelectedObj}
                  setShowModalTextOption={setShowModalTextOption}
                />
              );
            case "image":
              return (
                <ImageComponenet
                  listData={listData}
                  setListData={setListData}
                  id={data.id}
                  data={data}
                  setSelectedInputId={setSelectedInputId}
                  selectedInputId={selectedInputId}
                  isSelected={isSelected}
                  setIselected={setIselected}
                  setDataSelectedObj={setDataSelectedObj}
                  setShowModalImageOption={setShowModalImageOption}
                />
              );
            case "QrCode":
              return (
                <QrCodeGenerator
                  url={data.b64}
                  id={data.id}
                  index={index}
                  data={data}
                  listData={listData}
                  setListData={setListData}
                  setPosition={setPosition}
                  setSelectedInputId={setSelectedInputId}
                  selectedInputId={selectedInputId}
                  isSelected={isSelected}
                  setIselected={setIselected}
                />
              );
            case "BarCode":
              return (
                <BarCodeGenerator
                  url={data.b64}
                  id={data.id}
                  index={index}
                  data={data}
                  listData={listData}
                  setListData={setListData}
                  setPosition={setPosition}
                  setSelectedInputId={setSelectedInputId}
                  selectedInputId={selectedInputId}
                  isSelected={isSelected}
                  setIselected={setIselected}
                />
              );
            case "endRedaction":
            case "redaction":
              return (
                <RedactionComponent
                  index={index}
                  data={data}
                  listData={listData}
                  setListData={setListData}
                  setSelectedInputId={setSelectedInputId}
                  selectedInputId={selectedInputId}
                  isSelected={isSelected}
                  setDataSelectedObj={setDataSelectedObj}
                  color={
                    data.type === "redaction" && data.spec === "add"
                      ? "green"
                      : "red"
                  }
                />
              );
            default:
              return (
                <TextAndInput
                  index={index}
                  data={data}
                  listData={listData}
                  setListData={setListData}
                  setPosition={setPosition}
                  setSelectedInputId={setSelectedInputId}
                  setDidEdit={setDidEdit}
                  selectedInputId={selectedInputId}
                  isSelected={isSelected}
                  setIselected={setIselected}
                  setTextColor={setTextColor}
                  dataSelectedObj={dataSelectedObj}
                  setDataSelectedObj={setDataSelectedObj}
                  setShowModalTextOption={setShowModalTextOption}
                />
              );
          }
        })}
    </Layer>
  );
};
