/* eslint-disable no-useless-computed-key */
export const statusLabels = {
  ["IdProvisional"]: "Identité provisoire",
  ["IdRecovered"]: "Identité récuperée",
  ["IdValidated"]: "Identité validée",
  ["IdQualified"]: "Identité qualifiée",
};

// urn:asip:ci-sis:idap:2011
// urn:ihe:lab:xd-lab:2008
export const handleFormat = (format, mimeType) => {
  switch (format) {
    case "urn:ihe:iti:xds-sd:text:2008":
      return "TEXT";
    case "urn:ihe:iti:xds-sd:pdf:2008":
      return "PDF";
    case "urn:ihe:iti-fr:xds-sd:rtf:2010":
      return "RTF";
    case "urn:ihe:iti-fr:xds-sd:jpeg:2010":
      return "IMAGE";
    case "urn:ihe:iti-fr:xds-sd:tiff:2010":
      return "TIFF";
    case "urn:asip:ci-sis:vac-note:2021":
    case "urn:asip:ci-sis:vac:2019":
      return "NOTE VACCINATION";

    default:
      return "AUTRE";
  }
};

// IF IdentifiantSysteme is for TEST mode changed for NORMAL mode
export const handleIdentifiantSysteme = (id) => {
  switch (id) {
    case "1.2.250.1.213.1.4.8":
      return "1.2.250.1.213.1.4.10";
    case "1.2.250.1.213.1.4.2":
      return "1.2.250.1.213.1.4.6";
    default:
      return id;
  }
};

export const dropdownQueryOptions = [
  {
    label: "Rechercher des documents",
    value: "FindDocuments",
  },
  {
    label: "Trouver des ensembles de soumission et obtenir du contenu",
    value: "FindSubmissionSetsAndGetContents",
  },
  {
    label: "Obtenir des documents",
    value: "GetDocuments",
  },
  {
    label: "Trouver les vaccinations historiques",
    value: "FindHistoriqueVaccinations",
  },
];

export const dropdownStatusOptions = [
  {
    label: "APPROUVÉ",
    value: "APPROVED",
  },
  {
    label: "SOUMIS",
    value: "SUBMITTED",
  },
  {
    label: "ARCHIVÉ",
    value: "ARCHIVED",
  },
  {
    label: "SUPPRIMÉ",
    value: "DELETED",
  },
];

export const dropdownExerciseFrame = [
  { label: "Ambulatoire", value: "Ambulatoire" },
  { label: "Dépistage", value: "Dépistage" },
  { label: "Etablissement de santé", value: "Etablissement de santé" },
  { label: "Hospitalisation å domicile", value: "Hospitalisation å domicile" },
  { label: "Maintien å domicile", value: "Maintien å domicile" },
  {
    label: "Organisme d'assurance maladie obligatoire",
    value: "Organisme d'assurance maladie obligatoire",
  },
  { label: "SAMIJ/SMIJR", value: "SAMIJ/SMIJR" },
  { label: "Soins palliatifs", value: "Soins palliatifs" },
  { label: "Soins å domicile", value: "Soins å domicile" },
];

export const dropdownClasseOptions = [
  {
    label: "Autres documents déposés par le patient",
    value: "OtherDocumentsSubmittedByThePatient",
    options: [
      {
        value: "DOCPAT09",
        identifiantNomenclature: "1.2.250.1.213.1.1.4.12",
        label: "Document administratif déposé par le patient",
        displayName: "Document administratif déposé par le patient",
        code: "DOCPAT09",
        codingScheme: "1.2.250.1.213.1.1.4.12",
      },
      {
        value: "EXPPAT_3",
        identifiantNomenclature: "1.2.250.1.213.1.1.4.12",
        label: "Directives anticipées",
        displayName: "Directives anticipées",
        code: "EXPPAT_3",
        codingScheme: "1.2.250.1.213.1.1.4.12",
      },
      {
        value: "EXPPAT_2",
        identifiantNomenclature: "1.2.250.1.213.1.1.4.12",
        label: "Autre document du patient",
        displayName: "Autre document du patient",
        code: "EXPPAT_2",
        codingScheme: "1.2.250.1.213.1.1.4.12",
      },
      {
        value: "EXPPAT_1",
        identifiantNomenclature: "1.2.250.1.213.1.1.4.12",
        label: "Volontés et droits du patient",
        displayName: "Volontés et droits du patient",
        code: "EXPPAT_1",
        codingScheme: "1.2.250.1.213.1.1.4.12",
      },
    ],
  },

  {
    label: "Certificat et déclaration",
    value: "CertificateAndDeclaration",
    options: [
      {
        value: "DOCPAT07",
        identifiantNomenclature: "1.2.250.1.213.1.1.4.12",
        label: "Certificat déposé par le patient",
        displayName: "Certificat déposé par le patient",
        code: "DOCPAT07",
        codingScheme: "1.2.250.1.213.1.1.4.12",
      },
      {
        value: "96173-0",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Attestation de dépistage",
        displayName: "Attestation de dépistage",
        code: "96173-0",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "87273-9",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Note de vaccination",
        displayName: "Note de vaccination",
        code: "87273-9",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "11369-6",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Historique de vaccinations",
        displayName: "Historique de vaccinations",
        code: "11369-6",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "96874-3",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "COVID-19 Attestation de vaccination",
        displayName: "COVID-19 Attestation de vaccination",
        code: "96874-3",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "CERT_DECL",
        identifiantNomenclature: "1.2.250.1.213.1.1.4.12",
        label: "Certificat, déclaration",
        displayName: "Certificat, déclaration",
        code: "CERT_DECL",
        codingScheme: "1.2.250.1.213.1.1.4.12",
      },
      {
        value: "89601-9",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Certificat médical",
        displayName: "Certificat médical",
        code: "89601-9",
        codingScheme: "2.16.840.1.113883.6.1",
      },
    ],
  },
  {
    label: "Compte rendu",
    value: "Report",
    options: [
      {
        value: "75492-9",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "CR d'acte diagnostique à visée préventive ou de dépistage",
        displayName:
          "CR d'acte diagnostique à visée préventive ou de dépistage",
        code: "75492-9",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "67851-6",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "CR d'admission",
        displayName: "CR d'admission",
        code: "67851-6",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "93024-8",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "CR de consultation pharmaceutique",
        displayName: "CR de consultation pharmaceutique",
        code: "93024-8",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "11490-0",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Lettre de liaison à la sortie d'un établissement de soins",
        displayName:
          "Lettre de liaison à la sortie d'un établissement de soins",
        code: "11490-0",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "34794-8",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "CR de réunion de concertation pluridisciplinaire",
        displayName: "CR de réunion de concertation pluridisciplinaire",
        code: "34794-8",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "75496-0",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Demande d'acte de télémédecine",
        displayName: "Demande d'acte de télémédecine",
        code: "75496-0",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "11526-1",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "CR d'anatomie et de cytologie pathologiques",
        displayName: "CR d'anatomie et de cytologie pathologiques",
        code: "11526-1",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "78601-2",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "CR d'entretien pharmaceutique",
        displayName: "CR d'entretien pharmaceutique",
        code: "78601-2",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "77436-4",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "CR d'anesthésie",
        displayName: "CR d'anesthésie",
        code: "77436-4",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "34749-2",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "CR de consultation pré-anesthésique",
        displayName: "CR de consultation pré-anesthésique",
        code: "34749-2",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "83981-1",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label:
          "Lettre de liaison à la sortie d'une structure sociale ou médico-sociale",
        displayName:
          "Lettre de liaison à la sortie d'une structure sociale ou médico-sociale",
        code: "83981-1",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "47420-5",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "CR de bilan fonctionnel (par auxiliaire médical)",
        displayName: "CR de bilan fonctionnel (par auxiliaire médical)",
        code: "47420-5",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "75482-0",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "CR d'acte thérapeutique à visée préventive",
        displayName: "CR d'acte thérapeutique à visée préventive",
        code: "75482-0",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "11506-3",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "CR ou fiche de suivi de soins par auxiliaire médical",
        displayName: "CR ou fiche de suivi de soins par auxiliaire médical",
        code: "11506-3",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "34874-8",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "CR opératoire",
        displayName: "CR opératoire",
        code: "34874-8",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "BIL_AUTO",
        identifiantNomenclature: "1.2.250.1.213.1.1.4.12",
        label: "CR de bilan d'évaluation de la perte d'autonomie",
        displayName: "CR de bilan d'évaluation de la perte d'autonomie",
        code: "BIL_AUTO",
        codingScheme: "1.2.250.1.213.1.1.4.12",
      },
    ],
  },
  {
    label: " Digital Signature",
    value: "DigitalSignature",
    options: [
      {
        value: "E1762",
        identifiantNomenclature: "ASTM",
        label: "Full Document",
        displayName: "Full Document",
        code: "E1762",
        codingScheme: "ASTM",
      },
    ],
  },
  {
    label: "Dispensation",
    value: "Dispensation",
    options: [
      {
        value: "60593-1",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Dispensation médicamenteuse",
        displayName: "Dispensation médicamenteuse",
        code: "60593-1",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "DISP_AUT",
        identifiantNomenclature: "1.2.250.1.213.1.1.4.12",
        label: "Dispensation (autre)",
        displayName: "Dispensation (autre)",
        code: "DISP_AUT",
        codingScheme: "1.2.250.1.213.1.1.4.12",
      },
    ],
  },
  {
    label: "Dispensation",
    value: "Dispensation",
    options: [
      {
        value: "60593-1",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Dispensation médicamenteuse",
        displayName: "Dispensation médicamenteuse",
        code: "60593-1",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "DISP_AUT",
        identifiantNomenclature: "1.2.250.1.213.1.1.4.12",
        label: "Dispensation (autre)",
        displayName: "Dispensation (autre)",
        code: "DISP_AUT",
        codingScheme: "1.2.250.1.213.1.1.4.12",
      },
    ],
  },
  {
    label: "Données de remboursement",
    value: "RefundData",
    options: [
      {
        value: "REMB",
        identifiantNomenclature: "1.2.250.1.213.1.1.4.12",
        label: "Données de remboursement",
        displayName: "Données de remboursement",
        code: "REMB",
        codingScheme: "1.2.250.1.213.1.1.4.12",
      },
    ],
  },

  {
    label: "Imagerie médicale",
    value: "MedicalImaging",
    options: [
      {
        value: "DOCPAT04",
        identifiantNomenclature: "1.2.250.1.213.1.1.4.12",
        label: "Imagerie déposée par le patient",
        displayName: "Imagerie déposée par le patient",
        code: "DOCPAT04",
        codingScheme: "1.2.250.1.213.1.1.4.12",
      },
      {
        value: "18748-4",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "CR d'imagerie médicale",
        displayName: "CR d'imagerie médicale",
        code: "18748-4",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "52040-3",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Document encapsulant une image d'illustration non DICOM",
        displayName: "Document encapsulant une image d'illustration non DICOM",
        code: "52040-3",
        codingScheme: "2.16.840.1.113883.6.1",
      },
    ],
  },

  {
    label: "Plan de soins, protocole de soins",
    value: "CarePlan",
    options: [
      {
        value: "80788-3",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Projet personnalisé d'accompagnement",
        displayName: "Projet personnalisé d'accompagnement",
        code: "80788-3",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "PROT_ALD",
        identifiantNomenclature: "1.2.250.1.213.1.1.4.12",
        label: "Protocole de soins ALD",
        displayName: "Protocole de soins ALD",
        code: "PROT_ALD",
        codingScheme: "1.2.250.1.213.1.1.4.12",
      },
      {
        value: "77604-7",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Planification thérapeutique",
        displayName: "Planification thérapeutique",
        code: "77604-7",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "18776-5",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Plan personnalisé de soins",
        displayName: "Plan personnalisé de soins",
        code: "18776-5",
        codingScheme: "2.16.840.1.113883.6.1",
      },
    ],
  },

  {
    label: "Prescription",
    value: "Prescription",
    options: [
      {
        value: "PRESC-ORTHOPHO",
        identifiantNomenclature: "1.2.250.1.213.1.1.4.12",
        label: "Prescription d'actes d'orthophonie",
        displayName: "Prescription d'actes d'orthophonie",
        code: "PRESC-ORTHOPHO",
        codingScheme: "1.2.250.1.213.1.1.4.12",
      },
      {
        value: "61357-0",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Intervention pharmaceutique",
        displayName: "Intervention pharmaceutique",
        code: "61357-0",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "PRESC-ORTHOPTIE",
        identifiantNomenclature: "1.2.250.1.213.1.1.4.12",
        label: "Prescription d'actes d'orthoptie",
        displayName: "Prescription d'actes d'orthoptie",
        code: "PRESC-ORTHOPTIE",
        codingScheme: "1.2.250.1.213.1.1.4.12",
      },
      {
        value: "57832-8",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Prescription de soins",
        displayName: "Prescription de soins",
        code: "57832-8",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "DOCPAT02",
        identifiantNomenclature: "1.2.250.1.213.1.1.4.12",
        label: "Traitement ou document de soins déposé par le patient",
        displayName: "Traitement ou document de soins déposé par le patient",
        code: "DOCPAT02",
        codingScheme: "1.2.250.1.213.1.1.4.12",
      },
      {
        value: "PRESC-PEDI",
        identifiantNomenclature: "1.2.250.1.213.1.1.4.12",
        label: "Prescription d'actes de pédicurie",
        displayName: "Prescription d'actes de pédicurie",
        code: "PRESC-PEDI",
        codingScheme: "1.2.250.1.213.1.1.4.12",
      },
      {
        value: "PRESC-KINE",
        identifiantNomenclature: "1.2.250.1.213.1.1.4.12",
        label: "Prescription d'actes de kinésithérapie",
        displayName: "Prescription d'actes de kinésithérapie",
        code: "PRESC-KINE",
        codingScheme: "1.2.250.1.213.1.1.4.12",
      },
      {
        value: "55115-0",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Demande d'actes d'imagerie",
        displayName: "Demande d'actes d'imagerie",
        code: "55115-0",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "PRESC-BIO",
        identifiantNomenclature: "1.2.250.1.213.1.1.4.12",
        label: "Prescription d'actes de biologie médicale",
        displayName: "Prescription d'actes de biologie médicale",
        code: "PRESC-BIO",
        codingScheme: "1.2.250.1.213.1.1.4.12",
      },
      {
        value: "75468-9",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Renouvellement ordonnance par pharmacien correspondant",
        displayName: "Renouvellement ordonnance par pharmacien correspondant",
        code: "75468-9",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "57833-6",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Prescription de produits de santé",
        displayName: "Prescription de produits de santé",
        code: "57833-6",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "57828-6",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Prescription (autre)",
        displayName: "Prescription (autre)",
        code: "57828-6",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "PRESC-INF",
        identifiantNomenclature: "1.2.250.1.213.1.1.4.12",
        label: "Prescription d'actes infirmiers",
        displayName: "Prescription d'actes infirmiers",
        code: "PRESC-INF",
        codingScheme: "1.2.250.1.213.1.1.4.12",
      },
    ],
  },
  {
    label: "Synthése",
    value: "Synthesis",
    options: [
      {
        value: "DOCPAT08",
        identifiantNomenclature: "1.2.250.1.213.1.1.4.12",
        label: "Profil médical Mon espace santé",
        displayName: "Profil médical Mon espace santé",
        code: "DOCPAT08",
        codingScheme: "1.2.250.1.213.1.1.4.12",
      },
      {
        value: "60591-5",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Synthèse du dossier médical",
        displayName: "Synthèse du dossier médical",
        code: "60591-5",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "83901-9",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Bilan psychologique",
        displayName: "Bilan psychologique",
        code: "83901-9",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "34133-9",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Synthèse d'épisode de soins",
        displayName: "Synthèse d'épisode de soins",
        code: "34133-9",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "DOCPAT01",
        identifiantNomenclature: "1.2.250.1.213.1.1.4.12",
        label: "Synthèse déposée par le patient",
        displayName: "Synthèse déposée par le patient",
        code: "DOCPAT01",
        codingScheme: "1.2.250.1.213.1.1.4.12",
      },
      {
        value: "SYNTH",
        identifiantNomenclature: "1.2.250.1.213.1.1.4.12",
        label: "Synthèse",
        displayName: "Synthèse",
        code: "SYNTH",
        codingScheme: "1.2.250.1.213.1.1.4.12",
      },
      {
        value: "88348-8",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label:
          "Lettre de liaison d'entrée en structure sociale ou médico-sociale",
        displayName:
          "Lettre de liaison d'entrée en structure sociale ou médico-sociale",
        code: "88348-8",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "68817-6",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Bilan de santé et de prévention de l'enfant",
        displayName: "Bilan de santé et de prévention de l'enfant",
        code: "68817-6",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "56445-0",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Bilan médicamenteux (Officine)",
        displayName: "Bilan médicamenteux (Officine)",
        code: "56445-0",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "68599-0",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Synthèse psychiatrique",
        displayName: "Synthèse psychiatrique",
        code: "68599-0",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "18761-7",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label:
          "Note de transfert (dont lettre de liaison à l'entrée en établissement de soins)",
        displayName:
          "Note de transfert (dont lettre de liaison à l'entrée en établissement de soins)",
        code: "18761-7",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "80820-4",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Formulaire de conciliation médicamenteuse (Hôpital)",
        displayName: "Formulaire de conciliation médicamenteuse (Hôpital)",
        code: "80820-4",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "34842-5",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Grille d'évaluation médico-social",
        displayName: "Grille d'évaluation médico-social",
        code: "34842-5",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "74207-2",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Dossier de liaison d'urgence",
        displayName: "Dossier de liaison d'urgence",
        code: "74207-2",
        codingScheme: "2.16.840.1.113883.6.1",
      },
      {
        value: "83836-7",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "Schéma dentaire",
        displayName: "Schéma dentaire",
        code: "83836-7",
        codingScheme: "2.16.840.1.113883.6.1",
      },
    ],
  },
  {
    label: "Traitement administré",
    value: "TreatmentAdministered",
    options: [
      {
        value: "80565-5",
        identifiantNomenclature: "2.16.840.1.113883.6.1",
        label: "CR d'administration de médicaments",
        displayName: "CR d'administration de médicaments",
        code: "80565-5",
        codingScheme: "2.16.840.1.113883.6.1",
      },
    ],
  },
];
