/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import React from "react";
import Button from "../../../components/ButtonUI";
import Icon from "../../../components/Icon";
import Select from "../../../components/Select";
import { userAtom } from "../../../state/auth";
import { updateDoctorData } from "../../../api/doctor";
import ListSpecilaities from "../../../config/constants/specialties.json";
import makeToast from "../../../components/Snackbar";
interface Props {
  closeModal: Function;
}

const ChangeSpeciality = (props: Props) => {
  const { t, i18n } = useTranslation() as any;
  const { closeModal } = props;
  const [user, setUser] = useAtom(userAtom) as any;
  const [speciality, setSpeciality] = React.useState() as any;
  const [loading, setLoading] = React.useState(false) as any;

  const handleChangeSpeciality = async () => {
    setLoading(true);
    const res = await updateDoctorData({ speciality });
    makeToast("success", t("Profile.SuccessUpdated"));
    setLoading(false);
    setUser({ ...user, ...res });
    closeModal();
  };

  React.useEffect(() => {
    if (user?.speciality) setSpeciality(user.speciality);
  }, []);

  return (
    <div className="min-w-max w-80 sm:w-96 pb-5">
      <div className="flex justify-end cursor-pointer pt-2 ">
        <Icon
          isIcon={true}
          name="close"
          onClick={() => closeModal()}
          className="bg-gray-200 bg-opacity-50 rounded-xl"
        />
      </div>
      <div className="text-center mt-3">
        <h3 className="mb-5">{t("Profile.updateSpeciality")}</h3>
        {/* <Input
          placeholder="Spécialité"
          type="text"
          // error={errors.firstName}
          value={speciality}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleChangeSpeciality();
            }
          }}
          onChange={(e) => setSpeciality(e.target.value)}
          onBlur={(e) => setSpeciality(e.target.value)}
          className="w-full mb-2.5 rounded-lg border-gray-200 outline-none"
          inlineLabel={false}
        /> */}
        <Select
          options={ListSpecilaities.map((el) =>
            i18n.language.includes("en") ? { ...el, label: el.valueEN } : el
          )}
          className="md:ml-4 md:h-14 px-3 rounded-xl mb-5 cursor-pointer w-11/12 "
          setText={false}
          value={speciality}
          onChange={(e) => setSpeciality(e.target.value)}
          onBlur={(e) => setSpeciality(e.target.value)}
        />
      </div>

      <div className="flexCenter space-x-5">
        <Button
          status="secondary"
          width="100px"
          height="35px"
          onClick={() => closeModal()}
        >
          {t("Profile.Cancel")}
        </Button>
        <Button
          width="100px"
          height="35px"
          onClick={handleChangeSpeciality}
          disabled={loading}
        >
          {t("Profile.Save")}
        </Button>
      </div>
    </div>
  );
};

export default ChangeSpeciality;
