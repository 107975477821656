import { useEffect, useState } from "react";
import _ from "lodash";
/**
 * Helper & API
 */
import { filterFirstBookedTimeSlot } from "../../helper/index";
import moment from "../../helper/moment";
import { getOpenTimeSlots } from "../../api/doctor";
import { loadImage } from "../../api/documents";
/**
 * Component
 */
import makeToast from "../../components/Snackbar";
import Icon from "../../components/Icon";
/**
 * Assets
 */
import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";
import { handleSpeciality } from "../../helper/handleSpeciality";
import { useTranslation } from "react-i18next";

interface Props {
  handleCardClick: Function;
  handlePickDate: Function;
  doctorDetails: any;
  maxRow: number;
}

const CardDoctorWithFilter = (props: Props) => {
  const { i18n } = useTranslation();
  const { handleCardClick, handlePickDate, doctorDetails, maxRow } = props;
  const [openTimeSlots, setOpenTimeSlots] = useState([]) as any;
  const [loadingTimeSlot, setloadingTimeSlot] = useState(false);

  // resize time Slots
  const [startX, setStartX] = useState(0);
  const [endX, setEndX] = useState(maxRow);
  const [endY, setEndY] = useState(false);

  const _getOpenTimeSlots = async () => {
    try {
      setloadingTimeSlot(true);
      const response = await getOpenTimeSlots(doctorDetails?.agendaId);

      if (response?.data) {
        const groups = response.data.reduce((groups, el) => {
          const date = moment(el.startTime).format("YYYY-MM-DD");
          if (!groups[date]) {
            groups[date] = [];
          }

          groups[date].push(el);
          return groups;
        }, {});

        // // Edit: to add it in the array format instead
        const groupArrays = Object.keys(groups).map((date) => ({
          date,
          data: groups[date],
        }));
        setOpenTimeSlots(
          groupArrays
            .filter(
              (el) =>
                !_.isEmpty(el.data) &&
                !el?.data?.every((el) => el.eventStatus === "booked") &&
                !el?.data?.every((el) => el.eventType === "off") &&
                // eslint-disable-next-line no-useless-concat
                moment(el.date + " " + "23:59").isAfter(moment())
            )
            .sort(
              (a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf()
            )
        );
      }
      setloadingTimeSlot(false);
    } catch (error) {
      console.error(error);
    }
  };

  const firstOpenTimeSlot = () => {
    for (const openTimeSlot of openTimeSlots)
      for (const slotOfTheDay of openTimeSlot.data)
        if (
          slotOfTheDay.eventStatus === "open" &&
          moment(slotOfTheDay.startTime).isAfter(moment())
        )
          return slotOfTheDay.startTime;
    return null;
  };

  useEffect(() => {
    _getOpenTimeSlots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-white mt-10 w-full rounded-2xl p-3">
      <div className="flex justify-between items-center">
        <div
          className="flex items-center space-x-3 cursor-pointer"
          onClick={() =>
            handleCardClick(
              doctorDetails,
              openTimeSlots,
              moment(firstOpenTimeSlot()).format("dddd DD MMMM - HH:mm")
            )
          }
        >
          {doctorDetails?.business_photo ? (
            <img
              crossOrigin="anonymous"
              referrerPolicy="origin"
              src={
                doctorDetails?.business_photo.slice(0, 4) === "http"
                  ? doctorDetails?.business_photo
                  : loadImage(
                      doctorDetails?._id,
                      "profile",
                      "thumbnail",
                      doctorDetails?.business_photo
                    )
              }
              className="w-20 h-20 rounded-2xl object-fill shadow-md"
              alt="profile"
            />
          ) : doctorDetails?.sexe === "woman" ? (
            <WomanPatient className="w-20 h-20" />
          ) : (
            <ManPatient className="w-20 h-20" />
          )}
          <div>
            <h3>{`${doctorDetails?.firstName} ${doctorDetails?.lastName}`}</h3>
            <p className="card-subtitle ">
              {handleSpeciality(
                doctorDetails?.speciality,
                i18n.language.includes("en")
              )}
            </p>
            <p className="card-subtitle ">{doctorDetails?.address}</p>
          </div>
        </div>
        {firstOpenTimeSlot() && (
          <div className="border border-blue-500 rounded-xl p-4 text-center">
            <p className="courant-text ">Prochain rendez-vous disponible :</p>
            <p className="courant-text font-bold">
              {moment(firstOpenTimeSlot()).format("dddd DD MMMM - HH:mm")}
            </p>
          </div>
        )}
      </div>
      <div className="my-3">
        <hr />
      </div>

      {loadingTimeSlot ? (
        <div className="h-36 flexCenter">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
        </div>
      ) : (
        <>
          {/* Book New Meet */}
          <div className="flex my-2">
            <Icon
              isIcon={true}
              name="direction-gauche"
              className="cursor-pointer"
              onClick={() => {
                if (startX > 0) {
                  setStartX(startX - 1);
                  setEndX(endX - 1);
                }
              }}
            />
            <div className="flex-1 flex justify-between">
              {openTimeSlots &&
                !_.isEmpty(openTimeSlots) &&
                openTimeSlots.slice(startX, endX).map((el) => (
                  <div className="w-20 flex flex-col items-center">
                    <span className="card-subtitle text-xs">
                      {moment(el.date).format("dddd")}
                    </span>
                    <span className="card-title">
                      {moment(el.date).format("D MMM")}
                    </span>
                    {filterFirstBookedTimeSlot(
                      el.data.sort(
                        (a, b) =>
                          new Date(a.startTime).valueOf() -
                          new Date(b.startTime).valueOf()
                      )
                    )
                      .filter(({ startTime }) =>
                        moment(startTime).isAfter(moment())
                      )
                      .slice(0, endY ? el.data.length - 1 : 3)
                      .map(({ startTime, eventStatus, eventType, id }) => (
                        <span
                          className={` my-2 relative cursor-pointer ${
                            eventStatus === "open"
                              ? "bg-blue-500 text-white rounded-lg p-2"
                              : "box"
                          }`}
                          onClick={() => {
                            eventStatus === "open"
                              ? handlePickDate(
                                  {
                                    date: el.date,
                                    time: startTime,
                                    type: eventType,
                                    timeslotId: id,
                                  },
                                  doctorDetails,
                                  eventType
                                )
                              : makeToast(
                                  "warning",
                                  "Ce créneau horaire n'est pas disponible"
                                );
                          }}
                        >
                          {moment(startTime).format("HH:mm")}
                          {eventType === "teleconsultation" && (
                            <span className="absolute -right-1 -top-1">
                              <Icon isIcon={true} name="camera" />
                            </span>
                          )}
                        </span>
                      ))}
                  </div>
                ))}
            </div>
            <Icon
              isIcon={true}
              name="direction droite"
              className="cursor-pointer"
              onClick={() => {
                if (endX < openTimeSlots.length) {
                  setStartX(startX + 1);
                  setEndX(endX + 1);
                }
              }}
            />
          </div>
          {!endY && (
            <div className="flex items-center">
              <span
                className="show-more-hour cursor-pointer"
                onClick={() => {
                  setEndY(true);
                }}
              >
                Afficher plus d’horaires
              </span>
              <hr className="flex-1 px-4" />
              <Icon
                isIcon={true}
                name="plus-horaire"
                className="cursor-pointer"
                onClick={() => {
                  setEndY(true);
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CardDoctorWithFilter;
