export const DashboardDoctor = {
  fr: {
    title: "Mon Tableau de bord",
    WeAreOn: "Nous sommes le ",
    Rest: "Réinitialiser",
    NewWidget: "+ Nouveau widget",
    ConsultationOf: "Consultation du",
    Report: "Compte rendu",
    Prescription: "Prescription",
    CardCPSTitle: "Ma carte CPS",
    Info: "Mes informations",
    nationalId: "Identifiant national",
    cardId: "Identifiant de la carte",
    Name: "Nom",
    FirstName: "Prénom",
    IssuedOn: "Émise le",
    Survey: "Enquêtes",
    NoSurveyFound: "Aucun questionnaire trouvé",
    lastUpdate: "Dernière modification apportée le",
    taskOfDay: "Taches du jour",
    Agenda: "Agenda",
    today: "Aujourd'hui",
    EmptyList: "La liste de rendez-vous est vide",
    EmptyTask: "Aucune tâche aujourd'hui",
    EmptyConsultation: "Aucune consultation aujourd'hui",
    ptientofDay: "Patients du jour",
    connected: "Vous êtes maintenant connecté",
    Start: "Débuter",
    Today: "Aujourd’hui",
    Reset: "Réinitialiser",
    pscAlreadyExist: "La carte professionnelle existe déjà.",
  },
  en: {
    pscAlreadyExist: "The professional carte is already existing.",
    Today: "Today",
    Start: "Start",
    title: "Dashboard",
    WeAreOn: "Today is the : ",
    Reset: "Reset",
    NewWidget: "+ New widget",
    ConsultationOf: "Consultation of",
    Report: "Report",
    Prescription: "Prescription",
    CardCPSTitle: "My CPS card",
    Info: "My information",
    nationalId: "National ID",
    cardId: "Card ID",
    Name: "Name",
    FirstName: "First name",
    IssuedOn: "Issued on",
    Survey: "Surveys",
    NoSurveyFound: "No survey found",
    lastUpdate: "Last modification made on",
    taskOfDay: "Tasks of the day",
    Agenda: "Agenda",
    today: "Today",
    EmptyList: "There is nothing on your agenda at the moment.",
    EmptyTask: "No tasks today",
    EmptyConsultation: "No consultations today",
    ptientofDay: "Patients of the day",
    connected: "You are now connected",
  },
};
