import moment from "../../../helper/moment";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { getOlDDoneConsultation } from "../../../api/patient";
import useScreenSize from "../../../hooks/ScreenSize";
import Button from "../../../components/ButtonUI";
import Icon from "../../../components/Icon";
import { handleSpeciality } from "../../../helper/handleSpeciality";

interface Props {
  setSelectedConsultationId: any;
  setOpenDocumentModal: any;
}

const HistoryTab = (props: Props) => {
  const { t, i18n } = useTranslation() as any;
  const { setSelectedConsultationId, setOpenDocumentModal } = props;
  const { windowDimenion } = useScreenSize();
  const [loading, setLoading] = useState(false);
  const [historyDone, setHistoryDone] = useState([] as any);

  useEffect(() => {
    async function getHistoryDone() {
      setLoading(true);
      const response = await getOlDDoneConsultation();

      const newArray = response.consultations.map((e) => {
        let { autres, biologiques, radiologiques } = e;
        let docsNumber = 0;
        if (!_.isEmpty(e.prescriptions)) docsNumber += e.prescriptions.length;
        if (e.rapport) docsNumber += 1;

        return {
          ...e,
          docs: [...autres, ...biologiques, ...radiologiques],
          docsNumber,
        };
      });
      setLoading(false);
      setHistoryDone(newArray);
    }
    getHistoryDone();
  }, []);
  return loading ? (
    <div className="flexCenter">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
    </div>
  ) : (
    <div className="shadow-xl p-2 history-scroll-overflow">
      {_.isEmpty(historyDone) ? (
        <div className="m-auto text-center h-96">
          <h3>{t("MyFolder.NoHistory")}</h3>
        </div>
      ) : (
        <>
          <h3 className="uppercase my-5">
            {t("MyFolder.Historyappointments")}
          </h3>

          {historyDone
            .sort(
              (a, b) =>
                +new Date(b.dateConsultation) - +new Date(a.dateConsultation)
            )
            .map((el) => (
              <div className="bg-white rounded-3xl px-2 py-5 my-3">
                <div className="flex items-center justify-between">
                  <h2 className="mb-2">{`${el?.doctorId.firstName} ${el?.doctorId.lastName}`}</h2>
                  <span>
                    {moment(el.dateConsultation).format("DD MMMM YYYY")}{" "}
                    <span className="mx-3">|</span>{" "}
                    {moment(el.dateConsultation).format("HH:mm")}
                  </span>
                </div>
                <span className="box">
                  {handleSpeciality(
                    el?.doctorId?.speciality,
                    i18n.language.includes("en")
                  )}
                </span>
                <div className="flex items-center justify-between">
                  <div className="flexCenter">
                    <Icon isIcon={true} name="Motif de la consultation" />
                    <span className="card-subtitle mr-2">
                      {" "}
                      {t("MyFolder.motif")}{" "}
                    </span>
                    <span>{el.motif}</span>
                  </div>
                  <Button
                    status="secondary"
                    width={windowDimenion.winWidth > 768 ? "166px" : "80px"}
                    height="50px"
                  >
                    <div
                      className="flexCenter"
                      onClick={() => {
                        setOpenDocumentModal(true);
                        setSelectedConsultationId(el._id);
                      }}
                    >
                      <Icon isIcon={true} name="documents" />
                      <span>
                        {el?.docsNumber}{" "}
                        <span className="hidden md:inline">
                          {" "}
                          {t("MyFolder.documents")}
                        </span>
                      </span>
                    </div>
                  </Button>
                </div>
              </div>
            ))}
        </>
      )}
    </div>
  );
};

export default HistoryTab;
