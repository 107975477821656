/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import { useState } from "react";
import { Formik } from "formik";
// import _ from "lodash";
import { Link, useLocation } from "react-router-dom";
import useLocation2 from "../../hooks/useLocation";
import Button from "../../components/ButtonUI";
import Input from "../../components/FloatInput";
import InputOld from "../../components/Input";
import SelectComp from "../../components/Select";
import ListSpecilaities from "../../config/constants/specialties.json";
import CountryWithPhoneSuffix from "../../config/constants/pays.json";
import { addDoctor } from "../../api/doctor";
import Icon from "../../components/Icon";
import steto from "../../assets/images/steto.png";
import pscImg from "../../assets/psc.png";
import history from "../../history";
import makeToast from "../../components/Snackbar";
import countriesTn from "../../config/constants/countriesTn.json";
import voir from "../../assets/images/voir.png";
import "./styles.scss";
import Tooltip from "../../components/toolTip";
// import fr from "react-phone-number-input/locale/fr.json";
import { useTranslation } from "react-i18next";

// const dropdownOptions = [{ value: "TN", label: "Tunisie" }];
const dropdownSexeOptions = [
  { value: "men", label: "Homme" },
  { value: "woman", label: "Femme" },
];

const Signup: React.FC = () => {
  const tenant = process.env.REACT_APP_KATOMI_TENANT;
  const location = useLocation();
  const { t, i18n } = useTranslation() as any;

  const {
    loading,
    regionState,
    selectedRegionState,
    setSelectedRegionState,
    departementState,
    selectedDepartementState,
    setSelectedDepartementState,
    communesState,
    selectedCommunesState,
    setSelectedCommunesState,
    villeTnState,
    setVilleTnState,
    delegationTn,
    delegationTnState,
    setDelegationTnState,
  } = useLocation2();

  const [passwordFormType1, setPasswordFormType1] = useState("password");
  const [passwordFormType2, setPasswordFormType2] = useState("password");
  const [, setSuggestion] = useState<boolean>(false);

  const responseType = "code";
  const scope = "openid%20scope_all";
  const clientId = "katomi-kore-bas";
  const redirect = "http://localhost:3000";
  const acr = "eidas1";

  const uri = `https://wallet.bas.psc.esante.gouv.fr/auth?response_type=${responseType}&scope=${scope}&client_id=${clientId}&redirect_uri=${redirect}&acr_values=${acr}`;

  return (
    <>
      <div className="doctor-signup flexColCenter ">
        <div className="auth-Doctor-Signup-Container flexColCenter">
          {/* <h1 className="h1 title flexRowCenter">{t("SignUp.register")}</h1> */}
          <div className="formLogin mb-10  relative">
            <div className="absolute inset-0 pointer-events-none" />
            {loading && (
              <div className="absolute inset-0 z-90 flexCenter bg-opacity-80 bg-blue-300 rounded-2xl">
                <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-purple-500" />
              </div>
            )}
            <div
              className="icon cursor-pointer"
              onClick={() => history.push("/")}
            >
              <Icon
                name="direction-gauche"
                width={50}
                height={50}
                isIcon={true}
              />
            </div>
            <div className="flexRowCenter img-top">
              <img
                crossOrigin="anonymous"
                referrerPolicy="origin"
                src={steto}
                width="46px"
                height="46px"
                alt="steto"
              />
            </div>
            <div className="title-container flexColCenter">
              <p> {t("SignUp.im")}</p>
              <span className="flexRowCenter text-black font-black text-center text-2xl">
                {t("SignUp.doctor")}
              </span>
            </div>
            <Formik
              initialValues={{
                fname: "",
                lname: "",
                email: "",
                password: "",
                confirmPassword: "",
                name: "Factory 619",
                industry_id: "1",
                address: "",
                postal_code: "",
                country: "",
                city: "",
                State: "Tunisia",
                lat: "36.80",
                lng: "10.31",
                user_photo: null,
                business_photo: "",
                //_method:"PUT",
                phone: "",
                position: "Doctor",
                //Fields not needed for liberrex
                speciality: "",
                numCom: "",
                adeli: "",
                constCGU: false,
                cguModalIsOpen: false,
                sexe: "men",
                phoneSuffix: "",
                addressDetails: "",
                parrain: "",
              }}
              onSubmit={async (values) => {
                await new Promise((resolve) => setTimeout(resolve, 500));

                const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                const regexPassword =
                  /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{10,}$/;
                if (!values.fname)
                  return makeToast("warning", t("SignUp.fillName"));
                if (!values.lname)
                  return makeToast("warning", t("SignUp.fillLastName"));
                if (!values.email)
                  return makeToast("warning", t("SignUp.fillEmail"));
                if (!values.password)
                  return makeToast("warning", t("SignUp.fillPSW"));
                if (!values.confirmPassword)
                  return makeToast("warning", t("SignUp.fillConfirmedPSW"));
                if (!values.numCom)
                  return makeToast("warning", t("SignUp.fillNmInscr"));
                if (!values.adeli)
                  return makeToast("warning", t("SignUp.fillNmInscr"));
                if (!values.address)
                  return makeToast("warning", t("SignUp.filladdress"));
                // if (!values.city)
                //   return makeToast("warning", t("SignUp.fillCity"));
                if (!values.postal_code)
                  return makeToast("warning", t("SignUp.fillPostalCode"));
                if (!values.phone)
                  return makeToast("warning", t("SignUp.fillPhone"));
                if (!values.country)
                  return makeToast("warning", t("SignUp.fillPays"));
                if (!values.constCGU) {
                  return makeToast("warning", t("SignUp.CGU"));
                }

                if (tenant === "katomi") {
                  if (values.numCom.length !== 11)
                    return makeToast(
                      "warning",
                      "Le N° RPPS est composé de 11 chiffres"
                    );
                  if (values.adeli.length !== 9)
                    return makeToast(
                      "warning",
                      "Le N° ADELI est composé de 9 caractères"
                    );
                }

                // CONTROLE ADDRESS DETAILS
                if (values.country === "France") {
                  if (!selectedRegionState)
                    return makeToast(
                      "warning",
                      "Veuillez sélectionner votre région"
                    );
                  if (!selectedDepartementState)
                    return makeToast(
                      "warning",
                      "Veuillez sélectionner votre departement"
                    );
                  if (!selectedCommunesState)
                    return makeToast(
                      "warning",
                      "Veuillez sélectionner votre commune"
                    );
                }

                if (values.country === "Tunisia") {
                  if (!villeTnState)
                    return makeToast(
                      "warning",
                      "Veuillez sélectionner votre gouvernorat"
                    );
                  if (!delegationTnState)
                    return makeToast(
                      "warning",
                      "Veuillez sélectionner votre délegation"
                    );
                }

                values.addressDetails = JSON.stringify({
                  country: values.country,
                  region: selectedRegionState?.value,
                  departement: selectedDepartementState?.value,
                  commune: selectedCommunesState?.value,
                  gouvernement: villeTnState,
                  delegation: delegationTnState,
                });

                if (!validEmailRegex.test(values.email))
                  return makeToast("warning", t("SignUp.fillValidEmail"));
                if (!regexPassword.test(values.password))
                  return makeToast("warning", t("SignUp.pswConstraint"));
                if (values.password !== values.confirmPassword)
                  return makeToast(
                    "wating",
                    t("SignUp.pswConfirmedConstraint")
                  );
                try {
                  values.phoneSuffix = CountryWithPhoneSuffix.find(
                    (el) => el.name === values.country
                  )?.dial_code as any;

                  if (location.search.slice(1))
                    values.parrain = location.search.slice(1);

                  const response = await addDoctor(values);

                  if (response) {
                    if (response.status === "existEmail")
                      return makeToast(
                        "warning",
                        t("SignUp.existdoctorwithemail")
                      );
                    if (response.status === "existPhone")
                      return makeToast(
                        "warning",

                        t("SignUp.existdoctorwithPhoneNumber")
                      );
                    makeToast("success", t("SignUp.SuccessMsg"));
                    history.push({
                      pathname: `/confirm-email/${values.email}`,
                      state: { password: values.password, role: "doctor" },
                    });
                  } else {
                    makeToast("warning", t("SignUp.existdoctorwithemail"));
                  }
                } catch (err: any) {
                  if (err?.response?.data?.message === "cardAlreadyExist")
                    makeToast("warning", t("DashboardDoctor.pscAlreadyExist"));
                  else makeToast("warning", t("MyDoctors.SomethingWentWrong"));
                }
              }}
              // validationSchema={validationSchema}
            >
              {(props) => {
                const {
                  values,
                  // errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props;

                return (
                  <>
                    <form
                      onSubmit={handleSubmit}
                      className="form-login max-w-max"
                    >
                      <div className="flex bloc justify-between flex-wrap ">
                        <Input
                          id="lname"
                          labelText={t("SignUp.lastName")}
                          placeholder={t("SignUp.lastName")}
                          type="text"
                          // error={errors.lname}
                          value={values.lname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // require
                          //className="ml-2.5"
                        />
                        <Input
                          id="fname"
                          labelText={t("SignUp.name")}
                          placeholder={t("SignUp.name")}
                          type="text"
                          // error={errors.fname}
                          value={values.fname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // require
                        />
                      </div>

                      <div className={"flex bloc flex-wrap justify-between"}>
                        <Input
                          id="email"
                          labelText={t("SignUp.email")}
                          placeholder={t("SignUp.email")}
                          type="text"
                          // error={errors.email}
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // require
                        />
                        <SelectComp
                          id="sexe"
                          // error={errors.sexe}
                          value={values.sexe}
                          options={dropdownSexeOptions}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          text={t("SignUp.sexe")}
                          className="ml-2.5"
                          // required
                        />
                      </div>

                      {tenant === "katomi" ? (
                        <div className={"flex bloc flex-wrap justify-between"}>
                          <div className="relative">
                            <Input
                              id="numCom"
                              labelText={t("SignUp.registerNumRPPS")}
                              placeholder={t("SignUp.registerNumRPPS")}
                              type="text"
                              // error={errors.numCom}
                              value={values.numCom}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              // require
                            />
                            <div className="absolute right-3 top-5">
                              <Tooltip
                                position="top"
                                tooltipText={t("SignUp.controleRPPS")}
                                clsText={{ width: "220px", bottom: "200%" }}
                              >
                                <span className=" text-white text-xs font-bold bg-blue-500 rounded-full  w-5 h-5 cursor-pointer flexCenter">
                                  i
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                          <div className="relative">
                            <Input
                              id="adeli"
                              labelText={t("SignUp.registerNumAdeli")}
                              placeholder={t("SignUp.registerNumAdeli")}
                              type="text"
                              // error={errors.adeli}
                              value={values.adeli}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              // require
                            />
                            <div className="absolute right-3 top-5">
                              <Tooltip
                                position="top"
                                tooltipText={t("SignUp.controleAdeli")}
                                clsText={{ width: "220px", bottom: "200%" }}
                              >
                                <span className=" text-white text-xs font-bold bg-blue-500 rounded-full  w-5 h-5 cursor-pointer flexCenter">
                                  i
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={"flex  w-full "}>
                          <Input
                            id="numCom"
                            labelText={t("SignUp.registerNumber")}
                            placeholder={t("SignUp.registerNumber")}
                            type="text"
                            stylesDiv="w-full"
                            style={{ width: "100%" }}
                            // error={errors.numCom}
                            value={values.numCom}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // require
                          />
                        </div>
                      )}

                      <div className="flex bloc justify-between flex-wrap">
                        <div className="relative">
                          <Input
                            id="password"
                            labelText={t("SignUp.psw")}
                            placeholder="********"
                            type={passwordFormType1}
                            // error={errors.password}
                            className="pr-10"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={(e) => {
                              handleBlur(e);
                              setSuggestion(false);
                            }}
                            onFocus={() => {
                              setSuggestion(true);
                            }}
                            // require
                          />
                          <div className="absolute right-10 top-5">
                            <Tooltip
                              position="top"
                              tooltipText={t("SignUp.pswConstraint")}
                              clsText={{ width: "220px", bottom: "200%" }}
                            >
                              <span className=" text-white text-xs font-bold bg-blue-500 rounded-full  w-5 h-5 cursor-pointer flexCenter">
                                i
                              </span>
                            </Tooltip>
                          </div>
                          <img
                            crossOrigin="anonymous"
                            referrerPolicy="origin"
                            src={voir}
                            alt="voir"
                            className="absolute right-3 top-6 w-4 h-3 cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              setPasswordFormType1(
                                passwordFormType1 === "password"
                                  ? "text"
                                  : "password"
                              );
                            }}
                          />
                        </div>
                        <div className="relative">
                          <Input
                            id="confirmPassword"
                            labelText={t("SignUp.confirmPSW")}
                            placeholder="********"
                            type={passwordFormType2}
                            className="pr-10"
                            // error={errors.password}
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // require
                          />
                          <img
                            crossOrigin="anonymous"
                            referrerPolicy="origin"
                            src={voir}
                            alt="voir"
                            className="absolute right-3 top-6 w-4 h-3 cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              setPasswordFormType2(
                                passwordFormType2 === "password"
                                  ? "text"
                                  : "password"
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex bloc justify-between flex-wrap">
                        <SelectComp
                          id="speciality"
                          // error={errors.speciality}
                          value={values.speciality}
                          options={ListSpecilaities.map((el) =>
                            i18n.language.includes("en")
                              ? { ...el, label: el.valueEN }
                              : el
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          text={t("SignUp.speciality")}
                          // required
                        />
                        <SelectComp
                          id="country"
                          // error={errors.country}
                          value={values.country}
                          options={CountryWithPhoneSuffix.sort(
                            (a: any, b: any) => {
                              return a.name.localeCompare(b.name);
                            }
                          ).map((el) => ({
                            value: el.name,
                            label: el.name,
                          }))}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          text={t("SignUp.country")}
                          className="ml-2.5"
                          // required
                        />
                      </div>

                      {values.country === "France" && (
                        <>
                          <div className="flex bloc justify-between hasInput flex-wrap">
                            <SelectComp
                              className="w-full px-3"
                              labelProps={{ className: "w-40" }}
                              value={
                                selectedRegionState
                                  ? selectedRegionState.value
                                  : ""
                              }
                              text="Saisissez votre region"
                              options={regionState}
                              onChange={(e) => {
                                setSelectedRegionState(
                                  regionState.find(
                                    (elm) => elm.value == e.target.value
                                  )
                                );
                              }}
                            />
                            {selectedRegionState?.value && (
                              <SelectComp
                                className="w-full px-3"
                                labelProps={{ className: "w-40" }}
                                value={
                                  selectedDepartementState
                                    ? selectedDepartementState.value
                                    : ""
                                }
                                options={departementState}
                                text="Saisissez votre departement"
                                onChange={(e) => {
                                  setSelectedDepartementState(
                                    departementState.find(
                                      (elm) => elm.value == e.target.value
                                    )
                                  );
                                }}
                              />
                            )}
                          </div>
                          <div className="flex bloc justify-between hasInput flex-wrap">
                            {selectedDepartementState?.value && (
                              <SelectComp
                                className="w-full px-3"
                                labelProps={{ className: "w-40" }}
                                value={
                                  selectedCommunesState
                                    ? selectedCommunesState.value
                                    : ""
                                }
                                text="Saisissez votre commune"
                                options={communesState}
                                onChange={(e) => {
                                  setSelectedCommunesState(
                                    communesState.find(
                                      (elm) => elm.value == e.target.value
                                    )
                                  );
                                }}
                              />
                            )}
                          </div>
                        </>
                      )}
                      {values.country === "Tunisia" && (
                        <div className="flex bloc justify-between hasInput flex-wrap">
                          <SelectComp
                            className="w-full px-3"
                            labelProps={{ className: "w-40" }}
                            value={villeTnState}
                            options={Object.keys(countriesTn).map((elm) => ({
                              label: elm,
                              value: elm,
                            }))}
                            text="Saisissez votre gouvernerat"
                            onChange={(e) => {
                              setVilleTnState(e.target.value);
                            }}
                          />

                          {villeTnState && (
                            <SelectComp
                              className="w-full px-3"
                              labelProps={{ className: "w-40" }}
                              value={delegationTnState}
                              options={delegationTn}
                              text="Saisissez votre délégation"
                              onChange={(e) => {
                                setDelegationTnState(e.target.value);
                              }}
                            />
                          )}
                        </div>
                      )}
                      <div className="flex w-full">
                        <Input
                          id="address"
                          labelText={t("SignUp.address")}
                          placeholder={t("SignUp.address")}
                          type="text"
                          // style={{ width: "100%" }}
                          stylesDiv="w-full"
                          // error={errors.address}
                          style={{ width: "100%" }}
                          value={values.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // require
                        />

                        {/* <Input
                          id="city"
                          labelText={t("SignUp.SelectCity")}
                          placeholder={t("SignUp.SelectCity")}
                          type="text"
                          // error={errors.city}
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        // require
                        /> */}
                      </div>

                      <div className="flex bloc justify-between flex-wrap">
                        <Input
                          id="postal_code"
                          labelText={t("SignUp.postalCode")}
                          placeholder={t("SignUp.postalCode")}
                          type="text"
                          // error={errors.postal_code}
                          value={values.postal_code}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // require
                        />
                        <Input
                          id="phone"
                          labelText={t("SignUp.numberPhone")}
                          placeholder={t("SignUp.numberPhone")}
                          type="text"
                          // error={errors.phone}
                          className=""
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // require
                        />
                        {/* <PhoneInput
                          id="phone"
                          labels={fr}
                          placeholder="Saisissez votre numéro de téléphone"
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry="FR"
                          value={values.phone}
                          onChange={(e) =>
                            setFieldValue("phone", e ? String(e) : "")
                          }
                          className="border border-gray-400 w-full ml-2.5 rounded-xl px-4 h-16"
                        /> */}
                      </div>

                      {/* <div className="">
                        <p className="card-subtitle">
                          {t("SignUp.acceptedForm")}
                        </p>
                        <p className="card-subtitle"> {t("SignUp.maxSize")}</p>
                      </div> */}

                      <div className="mt-8 flex items-center">
                        {/* <Link to="#"> */}
                        <InputOld
                          id="constCGU"
                          // labelText={t("SignUp.CGU")}
                          // placeholder="Enter your address"
                          type="checkbox"
                          // error={errors.constCGU}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="mr-2"
                        />
                        <Link to="#">
                          <p>{t("SignUp.CGU")}</p>
                        </Link>
                        {/* </Link> */}
                      </div>
                      <div className="flexRowCenter">
                        <Button
                          type="submit"
                          disabled={isSubmitting}
                          status="primary"
                          className="btn mb-6"
                          onClick={() => handleSubmit()}
                        >
                          {t("SignUp.register")}
                        </Button>
                      </div>
                      <div className="flexColCenter  mb-6">
                        <h3 className="text-lg">Ou créer un compte avec</h3>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={uri}
                          onClick={() => {
                            localStorage.setItem("typePscLogin", "register");
                          }}
                        >
                          <img
                            src={pscImg}
                            alt="psc"
                            width={200}
                            className="mt-3 cursor-pointer"
                          />
                        </a>
                      </div>
                    </form>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
