import { useState } from "react";
import MeetHistory from "./Modals/MeetHistoryModal";
import PreviewAgenda from "./PreviewAgenda";
import useScreenSize from "../../hooks/ScreenSize";
import Modal from "../../components/ModalUI";
import MydoumentSlideModal from "./Modals/MyDoumentSlideModal";
const MyConsultations = () => {
  const { windowDimenion } = useScreenSize();
  const [, setMeetHistoryModal] = useState(false);
  const [myDocumentSlideModal, setMyDocumentSlideModal] = useState(false);
  const [meetDetails, setMeetDetails] = useState();

  const toggleMyDocumentSlideModal = () => {
    setMyDocumentSlideModal(!myDocumentSlideModal);
    setMeetHistoryModal(false);
  };
  return (
    <div className="my-consultations">
      <Modal
        showModal={myDocumentSlideModal}
        slidemodal={windowDimenion.winWidth > 768 ? true : false}
        closeModal={toggleMyDocumentSlideModal}
      >
        <MydoumentSlideModal
          closeModal={toggleMyDocumentSlideModal}
          meetDetails={meetDetails}
        />
      </Modal>
      <h1 className="mb-5">Mes consultations</h1>
      <div className="flex justify-between">
        <div className="w-2/5  ">
          <h3 className="mb-5">Mes rendez-vous à venir</h3>
          <PreviewAgenda winWidth={1900} />
        </div>
        <div className="w-2/5 ">
          <h3>Mes rendez-vous passés</h3>
          <MeetHistory
            winWidth={windowDimenion.winWidth}
            toggleMyDocumentSlideModal={(meetDetails) => {
              toggleMyDocumentSlideModal();
              setMeetDetails(meetDetails);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default MyConsultations;
