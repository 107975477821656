import React from "react";
import moment from "../../../helper/moment";
import Icon from "../../../components/Icon";
import { useTranslation } from "react-i18next";

type Props = {
  onEditorStateChange: Function;
  closeModal: Function;
  note: string;
  autoSaveAt: number;
};

const NoteModal = (props: Props) => {
  const { t } = useTranslation() as any;
  const { onEditorStateChange, note, autoSaveAt } = props;
  return (
    <div className="h-full p-5">
      <div className="flex justify-end">
        <div className="close-modal cursor-pointer bg-gray-200 bg-opacity-50">
          <Icon isIcon={true} name="close" onClick={() => props.closeModal()} />
        </div>
      </div>
      <div className="p-5">
        <textarea
          placeholder={t("Teleconsultation.WriteNote")}
          className="h-96 w-full "
          value={note}
          onChange={(e) => {
            onEditorStateChange(e);
          }}
        />
        {autoSaveAt && (
          <p className="card-subtitle text-center">
            {t("Teleconsultation.AutoSaved")}  {moment(autoSaveAt).format("HH:mm")} ✓
          </p>
        )}
      </div>
    </div>
  );
};

export default NoteModal;
