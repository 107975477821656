import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useAtom } from "jotai";
import { Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { userAtom } from "./state/auth";
import "./helper/axios";
import { UserRole } from "./types/user";
import Navigator from "./components/Navigator";
import Spinner from "./components/Spinner";
import { verify } from "./api/auth";
import history from "./history";
import "./theme.scss";
import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-datepicker/dist/react-datepicker.css";

// import i18n (needs to be bundled ;))
import "./i18n";

const App: React.FC = () => {
  const [loading, setlLading] = useState(true);
  console.log(
    `%c[info] app running in %c${process.env.NODE_ENV} mode.`,
    null,
    "color: #3B82F6; font-weight: bold;"
  );

  const [user, setUser] = useAtom(userAtom);
  useEffect(() => {
    setlLading(true);
    verify()
      .then((res) => {
        setUser(res.user);
        setlLading(false);
      })
      .catch((_) => {
        setlLading(false);
      });
  }, [setUser]);

  useEffect(() => {
    const bookingInfo: any = localStorage.getItem("Booking");
    if (bookingInfo) {
      const bookingInfoParsed = JSON.parse(bookingInfo);

      if (bookingInfoParsed && bookingInfoParsed?.first) {
        history.push("/annuaire");
      }
    }
  }, [setUser, user]);

  return loading ? (
    <Spinner />
  ) : (
    <Router history={history}>
      <Navigator role={user?.role || UserRole.Guest} />
      {/* <I18nToggle /> */}
    </Router>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
