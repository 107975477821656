import axios from "axios";
import {
  ADD_DOCUMENTS_BY_PATIENT,
  ADD_PHOTO_DOCTOR,
  ADD_PHOTO_PATIENT,
  ADD_SIGNATURE_DOCTOR,
  DOCUMENTS_LOAD_ENDPOINT,
  FETCH_DOCUMENTS_BY_PATIENT_AND_DOCTOR,
  REMOVE_DOC,
  REMOVE_DOCTURE_SIGNATURE,
  REMOVE_DOCTURE_PROFILE_PICTURE,
  REMOVE_PATIENT_PROFILE_PICTURE,
  ADD_CARDPRO_DOCTOR,
  REMOVE_DOCTURE_CARDPRO,
  UPDATE_DOC,
} from "../config/api";

export const addDocsByPatient = async (values) => {
  try {
    const { files, patientId, type, doctorId } = values;
    let formData = new FormData();
    files.forEach((element) => {
      formData.append("docs[]", element);
    });
    formData.append("patientId", patientId);
    formData.append("doctorId", doctorId);
    formData.append("type", type);
    const res = await axios.post(ADD_DOCUMENTS_BY_PATIENT, formData, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const loadImage = (userId, category, resolution, fileName) =>
  `${DOCUMENTS_LOAD_ENDPOINT}/load/${userId}/${category}/${resolution}/${fileName}?${Date.now()}`;

export const loadBill = (userId, fileName) =>
  `${DOCUMENTS_LOAD_ENDPOINT}/loadbill/${userId}/${fileName}?${Date.now()}`;

export const FetchDocumentWithDoctorId = async (values) => {
  try {
    const res = await axios.post(
      FETCH_DOCUMENTS_BY_PATIENT_AND_DOCTOR,
      values,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addSigntureToDoctor = async (values) => {
  try {
    const { file, doctorId } = values;
    let formData = new FormData();
    formData.append("doc", file);
    formData.append("doctorId", doctorId);
    const res = await axios.post(ADD_SIGNATURE_DOCTOR, formData, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addProfilePhotoToDoctor = async (values) => {
  try {
    const { file, doctorId } = values;
    let formData = new FormData();
    formData.append("photo", file);
    formData.append("doctorId", doctorId);
    const res = await axios.post(ADD_PHOTO_DOCTOR, formData, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addProfilePhotoToPatient = async (values) => {
  try {
    const { file, patientId } = values;
    let formData = new FormData();
    formData.append("photo", file);
    formData.append("patientId", patientId);
    const res = await axios.post(ADD_PHOTO_PATIENT, formData, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// export const loadImageWithUserId = async (
//   userId,
//   role,
//   category,
//   resolution
// ) => {
//   let fileName;
//   if (role === "Doctor") {
//     const result = await axios.get()
//   } else {
// const result = await axios.get();
//   }
//   return `${DOCUMENTS_LOAD_ENDPOINT}/load/${userId}/${category}/${resolution}/${fileName}?${Date.now()}`;
// };

export const deleteDocumentOFPatientByDoctor = async (
  patientId,
  category,
  docId,
  filename
) => {
  try {
    const res = await axios.delete(
      `${REMOVE_DOC}/${patientId}/${category}/${docId}/${filename}`,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteSignature = async () => {
  try {
    const res = await axios.delete(REMOVE_DOCTURE_SIGNATURE);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteProfilePicture = async () => {
  try {
    const res = await axios.delete(REMOVE_DOCTURE_PROFILE_PICTURE);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const DeletePatientProfilePicture = async () => {
  try {
    const res = await axios.delete(REMOVE_PATIENT_PROFILE_PICTURE);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addcardProToDoctor = async (values) => {
  try {
    const { file, doctorId } = values;
    let formData = new FormData();
    formData.append("doc", file);
    formData.append("doctorId", doctorId);
    const res = await axios.post(ADD_CARDPRO_DOCTOR, formData, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteCard = async () => {
  try {
    const res = await axios.delete(REMOVE_DOCTURE_CARDPRO);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateDocument = async (payload) => {
  try {
    const res = await axios.post(UPDATE_DOC, payload, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
