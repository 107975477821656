/* eslint-disable react-hooks/exhaustive-deps */

import { useAtom } from "jotai";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoEllipsisVerticalSharp } from "react-icons/io5";
import { DMP } from "../../../api/devbox";
import { ReactComponent as IconSearch } from "../../../assets/icons/icon-search.svg";
import moment from "../../../helper/moment";
import useOnClickOutside from "../../../hooks/useOnclickOutside";
import { dmpUrgenceAtom } from "../../../state/dmp";
import { DMPAtom } from "../../../state/myPatient";
import Button from "../../ButtonUI";
import Input from "../../Input";
import Modal from "../../Modal";
import Pagination from "../../Pagination2";
import makeToast from "../../Snackbar";
import Tabs from "../../Tabs";
import EditVisibilityModal from "./Modal/EditVisibilityModal";
import FindOption from "./Modal/FindOption";
import { handleFormat } from "./helper";
import PatientDocumentModal from "./patientDocumentModal";
import useDMP from "./useDMP";
import Tooltip from "../../toolTip";
import EditStatus from "./Modal/EditStatus";

const Index = ({ setPatientData, patientData, user }) => {
  const { t } = useTranslation() as any;
  const ref = useRef() as any;

  const [content] = useAtom(DMPAtom) as any;
  const [dmpUrgence, setDmpUrgence] = useAtom(dmpUrgenceAtom) as any;
  const [tabIndex, setTabIndex] = useState(0);

  //Pagination Vaccination
  const [pageVaccination, setPageVaccination] = useState(1);
  const [totalPageVaccination, setTotalPageVaccination] = useState(1);
  const [, setTotalResultVaccination] = useState(0);
  const [limitVaccination] = useState(5);

  //Pagination Document
  const [pageDocument, setPageDocument] = useState(1);
  const [totalPageDocument, setTotalPageDocument] = useState(1);
  const [, setTotalResultDocument] = useState(0);
  const [limitDocument] = useState(4);

  const [dashBoardUrlModal, setDashBoardUrlModal] = useState(false);
  const [findOptionModal, setFindOptionModal] = useState(false);
  const [dashBoardUrl, setDashBoardUrl] = useState();

  const [patientDocModal, setPatientDocModal] = useState(false);
  const [loadingVaccination, setLoadingVaccination] = useState(false);

  const [showDropdown, setShowDropdown] = useState<string | null>(null);

  const [editVisibiltyModal, setEditVisibiltyModal] = useState(false);
  const [editStatusesModal, setEditStatusesModal] = useState(false);
  const [editStatusesloading, setEditStatusesloading] = useState(false);

  const [nomenclatures, setNomenclatures] = useState([]) as any;

  const [selectedDoc, setSelectedDoc] = useState(null) as any;

  useOnClickOutside(ref, () => setShowDropdown(null));

  const toggleDropdown = (i) => {
    setShowDropdown(loadingVaccination ? null : String(i));
  };

  const {
    loading,
    checkCPSCard,
    patientDMPData,
    checkPatientINSQualified,
    checkPatientDMPAndAuth,
    author,
    documents,
    vaccination,
    refreshDMP,
    setRefreshDMP,
    _showContent,
    _getPatientDMP,
  } = useDMP({ patientData });

  const handleDashboardURL = async () => {
    try {
      const request = {
        context: {
          author,
          modeAcces: {
            acces: "NORMAL",
          },
        },
        request: {
          matriculeINS: patientDMPData?.matriculeINS,
        },
      };
      const response = await DMP.td09TableauDeBordUrl(request);
      setDashBoardUrlModal(true);
      setDashBoardUrl(response?.url);
    } catch (error) {
      console.log(error);
    }
  };

  // const handleUpdate = async (doc) => {
  //   try {
  //     const payload = {
  //       context: {
  //         author,
  //         modeAcces: {
  //           acces: "NORMAL",
  //         },
  //       },
  //       request: {
  //         matriculeINS: patientDMPData?.matriculeINS,
  //         ins: patientDMPData?.ins,
  //         contentType: "04",
  //         documentUuid: doc?.entryUuid,
  //         newStatus: "DELETED",
  //         oldStatus: "APPROVED",
  //       },
  //     };
  //     const response = await DMP.td33updateStatus(payload);
  //     console.log(
  //       "🚀 ~ file: index.tsx:75 ~ handleUpdate ~ response:",
  //       response
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const AddNewVaccination = async () => {
    try {
      setLoadingVaccination(true);
      // const patient = {
      //   internalId: patientData?._id,
      //   prenom:
      //     patientData?.INS?.responseINS.traitsIdentite?.prenomActeNaissance,
      //   matriculeINS: patientDMPData?.matriculeINS,
      //   nomUsuel: patientData?.INS?.responseINS.traitsIdentite?.nomFamille,
      //   dateDeNaissance:
      //     patientData?.INS?.responseINS.traitsIdentite?.dateNaissance
      //       .split("-")
      //       .join(""),
      //   ordreDeNaissance: 0,
      //   sexe: patientData?.INS?.responseINS.traitsIdentite?.sexeAdministratif,
      // };
      const requestAddNewDMPFile = {
        context: {
          author,
          modeAcces: {
            acces: "NORMAL",
          },
        },
        request: {
          submission: {
            comments: "Commentaire Soumission du document",
            contentType: "04",
            documents: [
              {
                confidentialities: ["N"],
                format: "urn:asip:ci-sis:vac-note:2021",
                patient: {
                  dateDeNaissance: "19770714",
                  matriculeINS: {
                    valeur: "277076322082910",
                    identifiantSysteme: "1.2.250.1.213.1.4.10",
                  },
                  internalId: "d95e8ff5-7bdc-4290-9e67-06d3252e3bc8",
                  nomUsuel: "NESSI",
                  prenom: "RUTH ISABELLE",
                },
                practiceSetting: "AMBULATOIRE",
                creationDateTime: "2022-10-26T13:00:00.000Z",
                serviceStartDateTime: "2022-10-26T11:00:00.000Z",
                serviceStopDateTime: "2022-10-26T12:00:00.000Z",
                vaccination: {
                  code: {
                    code: "INITIMMUNIZ",
                    codingScheme: "2.16.840.1.113883.5.4",
                    displayName: "1ère série vaccinante",
                  },
                  status: "completed",
                  dateTimeAdministration: "2022-10-26T12:00:00+01:00",
                  doseAdministree: {
                    value: "1",
                  },
                  voieAdministration: {
                    code: "IM",
                    codingScheme: "2.16.840.1.113883.5.112",
                    displayName: "intra musculaire",
                  },
                  regionAdministration: {
                    code: "LD",
                    codingScheme: "2.16.840.1.113883.5.1052",
                    displayName: "Deltoïde gauche",
                  },
                  consommable: {
                    nom: "Pentavac vaccin tétanique, diphtérie ...",
                    code: {
                      code: "69811279",
                      codingScheme: "1.2.250.1.213.2.3.1",
                      displayName:
                        "PENTAVAC, poudre et suspension pour suspension injectable en seringue préremplie. Vaccin diphtérique, tétanique, coquelucheux (acellulaire, multicomposé), poliomyélitique (inactivé) et conjugué de l'haemophilus type b, adsorbé",
                    },
                    traductions: [
                      {
                        code: "J07CA06",
                        codingScheme: "2.16.840.1.113883.6.73",
                        displayName:
                          "Diphtérie - Haemophilus influenzae B - coqueluche - poliomyélite - tétanos",
                      },
                      {
                        code: "3687434",
                        codingScheme: "1.2.250.1.213.2.3.2",
                        displayName:
                          "1 flacon(s) en verre - 1 seringue(s) préremplie(s) en verre de 0,5 ml muni d'un tip-cap avec 2 aiguille(s) séparées",
                      },
                    ],
                    numeroLot: "4456672",
                  },
                  executant: {
                    personnelSante: {
                      internalId: "899700369665",
                      nom: "DOC0036966",
                      prenom: "KIT",
                      secteurActivite: "SA01",
                      specialite: "G15_10/SM26",
                      structureSante: {
                        idNational: "10B0176228",
                        nom: "HOPITAL GENERIQUE  FIN VARI",
                      },
                    },
                  },
                  auteur: {
                    personnelSante: {
                      internalId: "899700369665",
                      nom: "DOC0036966",
                      prenom: "KIT",
                      secteurActivite: "SA01",
                      specialite: "G15_10/SM26",
                      structureSante: {
                        idNational: "10B0176228",
                        nom: "HOPITAL GENERIQUE  FIN VARI",
                      },
                    },
                  },
                  rangVaccination: {
                    valeur: "2",
                  },
                  commentaire: "Commentaire de la vaccination",
                },
                versionNumber: 1,
                dmpcType: "87273-9",
              },
            ],
            patient: {
              dateDeNaissance: "19770714",
              matriculeINS: {
                valeur: "277076322082910",
                identifiantSysteme: "1.2.250.1.213.1.4.10",
              },
              internalId: "d95e8ff5-7bdc-4290-9e67-06d3252e3bc8",
              nomUsuel: "NESSI",
              prenom: "RUTH ISABELLE",
            },
          },
        },
      };
      const response = await DMP.td21SubmitDocuments(requestAddNewDMPFile);

      setRefreshDMP(!refreshDMP);
      setLoadingVaccination(false);
      makeToast(
        "success",
        `L'ajout de dossier patient a été effectué avec succès.`
      );
      return response;
    } catch (error) {
      makeToast("error", `L'ajout de dossier patient a echoué.`);
      setLoadingVaccination(false);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(vaccination)) {
      setTotalResultVaccination(vaccination.length);
      setTotalPageVaccination(Math.ceil(vaccination.length / limitVaccination));
    }
  }, [pageVaccination, vaccination]);

  useEffect(() => {
    if (!_.isEmpty(documents)) {
      setTotalResultDocument(documents.length);
      setTotalPageDocument(Math.ceil(documents.length / limitDocument));
    }
  }, [pageDocument, documents]);

  useEffect(() => {
    if (_.isEmpty(nomenclatures))
      DMP.nomenclatures("CONTENT_TYPE_CODE")
        .then((res) => setNomenclatures(res))
        .catch((error) => console.log(error));
  }, []);

  const decodeUTF8String = (encodedString) => {
    try {
      let percentEncodedString = encodeURIComponent(encodedString);
      let decodedString = unescape(percentEncodedString);
      return decodedString;
    } catch (e) {
      console.error("Error decoding string:", e);
      return null;
    }
  };

  return loading?.status || content?.loadingContent ? (
    <>
      <div className="flexCenter h-96 flex-col gap-2">
        <h3 className="text-sm font-medium">{loading.indice}</h3>
        <div className="relative w-full sm:w-1/2 bg-gray-200 rounded">
          <div
            style={{ width: loading?.status }}
            className="absolute top-0 h-4 rounded shim-blue"
          ></div>
        </div>
      </div>
    </>
  ) : checkPatientINSQualified ? (
    patientDocModal ? (
      <PatientDocumentModal
        setPatientData={setPatientData}
        setPatientDocModal={setPatientDocModal}
        patientData={patientData}
        author={author}
        patientDMPData={patientDMPData}
        setRefreshDMP={setRefreshDMP}
        refreshDMP={refreshDMP}
      />
    ) : !checkCPSCard ? (
      <div className="flexCenter h-96 text-center">
        <h3>{t("Segure.OpenDevBox")}</h3>
      </div>
    ) : !checkPatientDMPAndAuth ? (
      <div className="flexCenter h-96 text-center">
        <h3>DMP inexistant</h3>
      </div>
    ) : (
      <div>
        <Tabs
          menu={[{ name: "Documents" }, { name: "Vaccinations" }]}
          tabIndex={(i) => setTabIndex(i)}
          className="m-auto"
        />
        {tabIndex === 0 && (
          <>
            <Modal
              showModal={findOptionModal}
              closeModal={() => setFindOptionModal(false)}
            >
              <FindOption
                handleFilter={_getPatientDMP}
                closeModal={() => setFindOptionModal(false)}
                nomenclatures={nomenclatures}
              />
            </Modal>

            <Modal
              showModal={dashBoardUrlModal}
              closeModal={() => setDashBoardUrlModal((x) => !x)}
            >
              <h3>{dashBoardUrl ? dashBoardUrl : "Error"}</h3>
            </Modal>
            <Modal
              showModal={editStatusesModal}
              closeModal={() => setEditStatusesModal(false)}
            >
              <EditStatus
                setEditStatusesModal={setEditStatusesModal}
                editStatusesloading={editStatusesloading}
                setEditStatusesloading={setEditStatusesloading}
                author={author}
                patientDMPData={patientDMPData}
                selectedDoc={selectedDoc}
              />
            </Modal>
            <Modal
              showModal={editVisibiltyModal}
              closeModal={() => setEditVisibiltyModal((x) => !x)}
            >
              <EditVisibilityModal
                closeModal={() => setEditVisibiltyModal((x) => !x)}
                author={author}
                matriculeINS={patientDMPData?.matriculeINS}
                documentUuid={selectedDoc?.entryUuid}
                contentType="04"
              />
            </Modal>
            <div className="flex gap-3 justify-end mb-5 mt-4">
              <Button
                status="primary"
                width={"130px"}
                height={"35px"}
                onClick={() => setFindOptionModal(true)}
              >
                <div className="flexCenter gap-2">
                  <IconSearch
                    className="-mt-1 -ml-1 "
                    style={{
                      filter:
                        "invert(100%) sepia(0%) saturate(7456%) hue-rotate(51deg) brightness(113%) contrast(106%)",
                    }}
                  />{" "}
                  <span className="texte-white">Filtres</span>
                </div>
              </Button>
              <Button
                status="primary"
                className="btnAddtache"
                width={"200px"}
                height={"35px"}
                onClick={handleDashboardURL}
              >
                {t("Segure.dashboardURL")}
              </Button>

              <Button
                status="secondary"
                className="btnAddtache"
                width={"146px"}
                height={"35px"}
                onClick={() => {
                  // fetchPatientData();
                  setPatientDocModal(true);
                }}
              >
                {t("Segure.Add")}
              </Button>
            </div>
            <div className="">
              <h3>Autorisation</h3>
              <Input
                name="authDPS"
                type="checkbox"
                onChange={() => {}}
                value="concentement"
                labelText="Le patient (ou son représentant légal) vous autorise à accéder à son DMP"
                labelProps={{ className: "check" }}
                checked
                className="my-2 cursor-not-allowed"
              />
              <Input
                name="authDPS"
                type="checkbox"
                onChange={() => {}}
                value="treating"
                labelText="Le patient vous à désigné comme traitant DMP"
                labelProps={{ className: "check" }}
                checked
                className="my-2 cursor-not-allowed"
              />
              <Input
                name="dmpUrgence"
                type="checkbox"
                onChange={() => {
                  setDmpUrgence((x) => !x);
                }}
                labelText="j'accède en urgence au DMP (accès bris de glace)"
                labelProps={{ className: "check" }}
                checked={dmpUrgence}
                className="my-2"
              />
            </div>
            {!_.isEmpty(documents) ? (
              <div className="mt-5">
                <div className="my-1 cursor-pointer relative rounded-xl flex gap-5 flex-wrap ">
                  {documents
                    .slice(
                      (pageDocument - 1) * limitDocument,
                      (pageDocument - 1) * limitDocument + limitDocument
                    )
                    .sort(
                      (a, b) =>
                        +new Date(b?.creationDateTime) -
                        +new Date(a?.creationDateTime)
                    )
                    .map((doc, i) => {
                      return (
                        <div
                          className="shadow-xl rounded-xl px-5 pb-5 pt-10 w-64 bg-white relative"
                          onClick={(e) => {
                            if (
                              doc?.confidentialities?.includes(
                                "INVISIBLE_REPRESENTANTS_LEGAUX"
                              )
                            )
                              makeToast(
                                "info",
                                "Ce document est invisible au représentant légal"
                              );
                            _showContent(doc);
                          }}
                        >
                          <div className="flex justify-end absolute top-2 right-2">
                            <div className="relative">
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  toggleDropdown(i);
                                }}
                                className="bg-gray-200 text-gray-700 rounded-full p-2 hover:bg-gray-300 focus:outline-none focus:shadow-outline"
                              >
                                <IoEllipsisVerticalSharp />
                              </button>
                              {showDropdown === String(i) && (
                                <div
                                  className="absolute right-0 w-56 mt-2 origin-top-right rounded-md shadow-lg"
                                  ref={ref}
                                >
                                  <div className="rounded-md bg-white shadow-xs">
                                    <div
                                      className="py-1"
                                      role="menu"
                                      aria-orientation="vertical"
                                      aria-labelledby="options-menu"
                                    >
                                      {/* <span
                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                        role="menuitem"
                                      >
                                        Visualiser les entétes
                                      </span> */}
                                      <span
                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                        role="menuitem"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                          setSelectedDoc(doc);
                                          setEditStatusesModal(true);
                                        }}
                                      >
                                        Archiver
                                      </span>
                                      <span
                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                        role="menuitem"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                          setSelectedDoc(doc);
                                          setEditVisibiltyModal(true);
                                        }}
                                      >
                                        Modifier la visibilité
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="absolute top-2 left-2 rounded-tr-xl rounded-br-xl  px-4 py-1 shadow-xl bg-blue-500  uppercase text-white font-medium text-xs">
                            {handleFormat(doc?.format, doc?.mimeType)}
                          </div>
                          <p
                            className="card-subtitle"
                            style={{ color: "black", fontWeight: "bold" }}
                          >
                            {" "}
                            {doc?.title && decodeUTF8String(doc?.title)}
                          </p>

                          <span className="text-sm text-gray-500">
                            {moment(doc?.creationDateTime).format(
                              "DD/MM/YYYY à HH:MM"
                            )}
                          </span>
                          <p className="mt-3">
                            {doc?.executant?.prenom} {doc?.executant?.nom}{" "}
                            {doc?.executant?.rpps &&
                              `(${doc?.executant?.rpps})`}
                          </p>
                          <div className="flex items-center gap-3 mt-3">
                            {doc?.confidentialities?.includes(
                              "INVISIBLE_REPRESENTANTS_LEGAUX"
                            ) && (
                              <Tooltip
                                tooltipText="INVISIBLE_REPRESENTANTS_LEGAUX"
                                position="top"
                              >
                                <p className="p-2 rounded-full bg-blue-400 text-white font-semibold">
                                  IRL
                                </p>
                              </Tooltip>
                            )}
                            {doc?.confidentialities?.includes(
                              "INVISIBLE_PATIENT"
                            ) && (
                              <Tooltip
                                tooltipText="INVISIBLE_PATIENT"
                                position="top"
                              >
                                <div className="w-8 h-8 rounded-full bg-blue-600 text-white font-semibold flexCenter">
                                  IP
                                </div>
                              </Tooltip>
                            )}
                          </div>

                          {/* {!_.isEmpty(
                            doc?.auteurs?.find((el) =>
                              el?.internalId?.includes(author?.internalId)
                            )
                          ) && (
                            <div className="flex gap-1 items-center absolute top-2 right-2">
                              <span
                                className="text-blue-200  cursor-pointer "
                                style={{
                                  filter:
                                    "invert(20%) sepia(85%) saturate(6925%) hue-rotate(358deg) brightness(107%) contrast(116%)",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  handleUpdate(doc);
                                }}
                              >
                                🗑️
                              </span>
                              <span
                                className="text-blue-200  cursor-pointer "
                                // style={{
                                //   filter:
                                //     "invert(20%) sepia(85%) saturate(6925%) hue-rotate(358deg) brightness(107%) contrast(116%)",
                                // }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  // handleUpdate(doc);
                                }}
                              >
                                <img src={pen} alt="edit" width={15} />
                              </span>
                            </div>
                          )} */}
                        </div>
                      );
                    })}
                </div>
                <Pagination
                  page={pageDocument}
                  totalPage={totalPageDocument}
                  setPage={setPageDocument}
                  totalResult={documents.length}
                />
              </div>
            ) : (
              <div className="h-64 flexCenter items-center">
                <h3> {t("Segure.NoDoc")}</h3>
              </div>
            )}
          </>
        )}

        {tabIndex === 1 && (
          <>
            <div className="flex justify-end">
              <Button
                className=""
                status="secondary"
                width="175px"
                height="40px"
                onClick={AddNewVaccination}
              >
                Ajouter
              </Button>
            </div>
            {!_.isEmpty(vaccination) ? (
              <>
                <div className="mt-10">
                  <table className="items-center w-full bg-transparent border-collapse">
                    <thead>
                      <tr className=" w-full">
                        <th className="w-1/4 px-2 text-teal-500  border-b border-solid border-gray-200 py-3 text-sm whitespace-pre-line font-bold text-left">
                          Date de l'acte
                        </th>
                        <th className="w-5/12 px-2 text-teal-500  border-b border-solid border-gray-200 py-3 text-sm whitespace-pre-line font-bold text-left">
                          Nom du vaccin
                        </th>

                        <th className="w-1/3 px-2 text-teal-500  border-b border-solid border-gray-200 py-3 text-sm whitespace-pre-line font-bold text-left">
                          Numéro de lot
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {vaccination
                        .slice(
                          (pageVaccination - 1) * limitVaccination,
                          (pageVaccination - 1) * limitVaccination +
                            limitVaccination
                        )
                        .map((el) => (
                          <tr key={el.id} className="w-full">
                            <td className="w-1/4 border-b border-gray-200 align-middle font-light text-sm whitespace-pre-line px-2 py-4 text-left">
                              {moment(el.dateTimeAdministration).format(
                                "DD/MM/YYYY"
                              )}
                            </td>
                            <td className="w-5/12 max-w-xs border-b border-gray-200 align-middle font-light text-sm whitespace-pre-line px-2 py-4 text-left">
                              {el.consommable.nom}
                            </td>

                            <td className="w-1/3 border-b border-gray-200 align-middle font-light text-sm whitespace-pre-line px-2 py-4 text-left">
                              {el.consommable.numeroLot}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <Pagination
                    page={pageVaccination}
                    totalPage={totalPageVaccination}
                    setPage={setPageVaccination}
                  />
                </div>
              </>
            ) : (
              <div className="h-64 flexCenter items-center">
                <h3> {t("Segure.NoVacc")}</h3>
              </div>
            )}{" "}
          </>
        )}
      </div>
    )
  ) : (
    <div className="flexCenter h-96">
      <h3> {t("Segure.INSNotQualified")}</h3>
    </div>
  );
};

export default Index;
