import _ from "lodash";
import { useAtom } from "jotai";
import { Link } from "react-router-dom";
import Button from "../../components/ButtonUI";
import Icon from "../../components/Icon";
import { previousState } from "../../state/previousPageState";
import { useTranslation } from "react-i18next";
interface Props {
  closeModal: any;
  openPrescriptionModal: any;
  idConsultation: string;
  sendConsultationToPatient: any;
  consultationDetails?: any;
}

const EndReportModal = (props: Props) => {
  const {
    closeModal,
    idConsultation,
    openPrescriptionModal,
    sendConsultationToPatient,
    consultationDetails,
  } = props;
  const { t } = useTranslation() as any;

  const [previousStates] = useAtom(previousState) as any;

  return (
    <div className="modal-end-consultation flexCenter flex-col space-y-5">
      <h2> {t("Consultation.ReportFinished")}</h2>
      <div className="flex space-x-10">
        {(!consultationDetails?.prescriptions ||
          _.isEmpty(consultationDetails?.prescriptions)) && (
          <Button
            status="secondary"
            width="146.6px"
            height="50px"
            onClick={() => {
              closeModal();
              openPrescriptionModal();
            }}
          >
            {t("Consultation.addPrescription")}
          </Button>
        )}
        <Link
          to={`/consultation-report/${idConsultation}`}
          onClick={closeModal}
        >
          <Button
            status="secondary"
            width="167px"
            height="50px"
            onClick={sendConsultationToPatient}
          >
            <div className="flexCenter">
              <Icon isIcon={true} name="transmettre" />
              <span> {t("Consultation.Send")}</span>
            </div>
          </Button>
        </Link>
      </div>
      <Link
        to={previousStates?.filePatientTasks ? "/mes-patients" : "/"}
        onClick={closeModal}
      >
        <span className="menu"> {t("Consultation.PutInToDoList")}</span>
      </Link>
    </div>
  );
};

export default EndReportModal;
