import { useEffect, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import moment from "../../../helper/moment";
import { getOlDDoneConsultation } from "../../../api/patient";
import { loadImage } from "../../../api/documents";

import Button from "../../../components/ButtonUI";
import Icon from "../../../components/Icon";

import { ReactComponent as WomanPatient } from "../../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../../assets/illustrations/illustration-homme-patient.svg";
import { ReactComponent as IconFolder } from "../../../assets/icons/icon-piece-jointe-blanc.svg";
import { handleSpeciality } from "../../../helper/handleSpeciality";

const MeetHistoryModal = ({
  closeModal,
  toggleMyDocumentSlideModal,
  winWidth,
}) => {
  const { t, i18n } = useTranslation();
  const [consultationsDetails, setConsultationsDetails] = useState<any>([]);

  const _getOlDDoneConsultation = async () => {
    try {
      const response = await getOlDDoneConsultation();
      setConsultationsDetails(response.consultations);
    } catch (error) {}
  };

  useEffect(() => {
    _getOlDDoneConsultation();
  }, []);

  return (
    <>
      <div className="md:p-5 ">
        <div className="flex p-5 md:p-0">
          <h1 className="m-auto">{t("ProfilePatient.MyPastDoc")}</h1>
          <div className="float-right close-modal cursor-pointer">
            <Icon isIcon={true} name="close" onClick={closeModal} />
          </div>
        </div>

        {/* Card */}
        {_.isArray(consultationsDetails) && !_.isEmpty(consultationsDetails) ? (
          consultationsDetails.map((meetDetails) => (
            <div className="flex items-center justify-between md:space-x-10 space-x-5  p-3 my-5 md:m-5 rounded-xl">
              <div className="flex items-center md:space-x-7 space-x-2">
                <div className="rounded-lg md:h-28 md:w-28  h-20 w-20 md:block hidden">
                  {/* <img crossOrigin = "anonymous"  referrerPolicy="origin" src={WomanDoctor} alt="doctor avatar" /> */}

                  {meetDetails.doctorId?.business_photo ? (
                    <img
                      crossOrigin="anonymous"
                      referrerPolicy="origin"
                      src={
                        meetDetails.doctorId?.business_photo.slice(0, 4) ===
                        "http"
                          ? meetDetails.doctorId?.business_photo
                          : loadImage(
                              meetDetails.doctorId?._id,
                              "profile",
                              "thumbnail",
                              meetDetails.doctorId?.business_photo
                            )
                      }
                      className="w-full h-full object-fill rounded shadow-md"
                      alt="profile"
                    />
                  ) : meetDetails.doctorId?.sexe === "woman" ? (
                    <WomanPatient className="w-full h-full object-fill rounded shadow-md" />
                  ) : (
                    <ManPatient className="w-full h-full object-fill rounded shadow-md" />
                  )}
                </div>
                <div className="flex flex-col space-y-4">
                  <div className="flex flex-col">
                    <span className="card-title">
                      {" "}
                      {`${meetDetails.doctorId?.firstName} ${meetDetails.doctorId?.lastName}`}
                    </span>
                    <span className="card-subtitle">
                      {handleSpeciality(
                        meetDetails.doctorId?.speciality,
                        i18n.language.includes("en")
                      )}
                    </span>
                  </div>
                  <span className="box">{meetDetails.motif}</span>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex card-subtitle justify-between items-center">
                  <span>
                    {" "}
                    {moment(meetDetails.dateConsultation).format(
                      "DD MMMM YYYY"
                    )}
                  </span>
                  <h3>|</h3>
                  <span>
                    {" "}
                    {moment(meetDetails.dateConsultation).format("HH:mm")}
                  </span>
                </div>

                <Button
                  status="secondary"
                  width={winWidth > 768 ? "173.9px" : "120px"}
                  height="50px"
                  onClick={() => toggleMyDocumentSlideModal(meetDetails)}
                >
                  <span className="md:inline hidden">
                    {t("ProfilePatient.SeeDoc")}
                  </span>
                  <IconFolder className="md:hidden inline filter-blue" />
                </Button>
              </div>
            </div>
          ))
        ) : (
          <h3 className="text-center mt-20">
            {t("ProfilePatient.NoAppointmentsMade")}
          </h3>
        )}
      </div>
    </>
  );
};

export default MeetHistoryModal;
