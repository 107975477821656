/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import FormBuilder from "../../components/SurveyGenerator/FormBuilder";
import Spinner from "../../components/Spinner";
import makeToast from "../../components/Snackbar";
import { getSurveyById, updateForm } from "../../api/survey";
import frMsg from "../../config/constants/locales/fr-fr.json";
import enMsg from "../../config/constants/locales/fr-fr.json";

const theme = extendTheme({
  colors: {
    transparent: "transparent",
    black: "#000",
    white: "#fff",
    blue: "#4565f6",

    // ...
  },
});

function Dragable() {
  const params: any = useParams();
  const { surveryId } = params;
  const [lang] = useState("fr");
  const [intialForm, setIntialForm] = useState() as any;
  const [loading, setLoading] = useState(true);

  const getFormData = async () => {
    try {
      const updatedSurvery = await getSurveyById(surveryId);
      if (updatedSurvery) {
        if (updatedSurvery.survey) {
          setIntialForm(updatedSurvery.survey);
          setLoading(false);
        }
      } else setLoading(false);
    } catch (error) {
      console.error(error);
      Promise.reject(error);
    }
  };
  const updateFormData = async (data) => {
    try {
      const updatedSurvery = await updateForm(data, surveryId);
      if (updatedSurvery) {
        if (updatedSurvery.survey) {
          setIntialForm(updatedSurvery.survey);
        }
        makeToast("success", "Vos changements sont sauvegardés");
      }
    } catch (error) {
      console.error(error);

      Promise.reject(error);
    }
  };
  useEffect(() => {
    getFormData();
  }, []);
  return !intialForm && loading ? (
    <Spinner />
  ) : (
    <ChakraProvider theme={theme}>
      <div className="App">
        <IntlProvider
          locale={"fr"}
          defaultLocale="en"
          messages={lang === "fr" ? frMsg : enMsg}
        >
          <FormBuilder
            fields={intialForm ? intialForm.sections[0].task_data : []}
            formSetup={
              intialForm
                ? intialForm
                : {
                    sections: [
                      { task_data: [], stepNbr: 0, sectionName: "Page 1" },
                    ],
                    name: "",
                    description: "",
                  }
            }
            onSave={updateFormData}
            onUpdate={() => {}}
            simulation={false}
            fieldProps={[]}
            formProps={{
              labelAlign: "left",
              colon: true,
              requiredMark: true,
              labelCol: {
                span: 3,
              },
            }}
            showPreviewTab={true}
          />
        </IntlProvider>
      </div>
    </ChakraProvider>
  );
}
export default Dragable;
