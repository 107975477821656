export const ProfilePatient = {
  fr: {
    MyAccount: "Mon compte",
    MYINFORMATION: "MES INFORMATIONS",
    Edit: "Modifier",
    MYHEALTHDATA: "MES DONNÉES DE SANTÉ",
    size: "taille :",
    cm: "cm",
    Weight: "Poids :",
    kg: "kg",
    BMI: "IMC :",
    IM: "Je suis",
    Connection: "Connexion",
    LastUpdatedOn: "Dernière mis à jour le :",
    Email: "Email :",
    Password: "Mot de passe :",
    MyDates: "Mes rendez-vous",
    History: "Historique",
    updateEmail: "Modifier mon email",
    CurrentEmail: "Email actuel",
    NewEmail: "Nouvel email",
    Save: "Enregistrer",
    AllFieldsAreRequired: "Tous les champs sont obligatoires",
    SuccessUpdate: "La mise à jour a été effectuée avec succès",
    EditMyInformation: "Modifier mes informations",
    Identity: "Identité",
    InCaseOfEmergency: "En cas d’urgence",
    FirstName: "Prénom",
    LastName: "Nom de famille",
    SocialSecurityNumber: "Numéro de sécurité sociale",
    Male: "Masculin",
    Feminine: "Féminin",
    birthdate: "Date de naissance (JJ/MM/AAAA)",
    Nationality: "nationalité",
    PhoneNumber: "Numéro de téléphone",
    PhonePrefix: "préfixe de téléphone",
    Address: "Adresse",
    postalCode: "Code postal",
    trustedPerson:
      "Indiquez ici une personne de confiance à contacter en cas de besoin.",
    updatePassword: "Modifier mon mot de passe",
    currentpassword: "Votre mot de passe actuel",
    newpassword: "Votre nouveau mot de passe",

    minimumCaract: "10 caracteres minimum",
    maj: "1 majuscule",
    chiffre: "1 chiffre",
    specialCarc: "1 caractere special",

    MyPastDoc: "Mes rendez-vous passés",
    SeeDoc: "Voir les documents",
    NoAppointmentsMade: "Aucun rendez-vous passé",

    YourAppointment: "Votre rendez-vous",
    Motif: "Motif :",
    DateAndTimeOfYourppointment: "Date et heure de votre rendez-vous :",
    TheConsultationConductedNyTeleconsultation:
      "La consultation sera réalisée en téléconsultation",
    Move: "Déplacer",

    MustChoosenewDate:
      "Vous devez choisir votre nouvelle date de cconsultation",
    SuccessUpdate2:
      "La mise à jour de votre consultation a été effectuée avec succès",
    ErrorTrylater:
      "Quelque chose s'est mal passé. Veuillez réessayer plus tard",
    Confirm: "Valider",
    Delete: "Supprimer",
    Today: "Aujourd'hui",
    EmptyListMeeting: "La liste de rendez-vous est vide",
    updatedAt: "Mise à jour le",
    ConsultationOf: "Consultation du :",
    Prescription: "Prescription",
    Report: "Compte rendu",
    Images: "Images",
    Document: "Document",

    Underweight: "Insuffisance pondérale",
    Normal: "Normal",
    Preoccupation: "Préobésité",
    Obesity1: "Obésité, classe I",
    Obesity2: "Obésité, classe II",
    Obesity3: "Obésité, classe III",

    EditMyHealthData: "Modifier mes données de santé",
    Smoker: "Fumeur",
    NonSmoking: "Non fumeur",
  },
  en: {
    MyAccount: "My account",
    MYINFORMATION: "MY INFORMATION",
    Edit: "Edit",
    MYHEALTHDATA: "MY HEALTH DATA",
    size: "size :",
    cm: "cm",
    Weight: "Weight:",
    kg: "kg",
    BMI: "BMI:",
    Connection: "Connection",
    LastUpdatedOn: "Last updated on:",
    Email: "Email:",
    Password: "Password:",
    MyDates: "My dates",
    History: "History",

    updateEmail: "Edit my email",
    CurrentEmail: "Current email",
    NewEmail: "New email",
    Save: "Save",

    AllFieldsAreRequired: "All fields are required",
    SuccessUpdate: "The update was successfully completed",
    EditMyInformation: "Edit my information",
    Identity: "Identity",
    InCaseOfEmergency: "In case of emergency",
    FirstName: "First name",
    LastName: "Last name",
    SocialSecurityNumber: "Social Security Number",
    Male: "Male",
    Feminine: "Feminine",
    birthdate: "birthdate (DD/MM/YYYY)",
    Nationality: "Nationality",
    PhoneNumber: "Phone number",
    PhonePrefix: "Phone prefix",
    Address: "Address",
    postalCode: "Postal code",
    trustedPerson: "Indicate here a trusted person to contact in case of need.",
    updatePassword: "Update password",

    currentpassword: "Your current password",
    newpassword: "Your new password",

    minimumCaract: "10 characters minimum",
    maj: "1 upper case",
    chiffre: "1 digit",
    specialCarc: "1 special character",

    MyPastDoc: "My past appointments",
    SeeDoc: "See the documents",
    NoAppointmentsMade: "No appointments made",

    YourAppointment: "Your appointment",
    Motif: "Motif :",
    DateAndTimeOfYourppointment: "Date and time of your appointment:",
    TheConsultationConductedNyTeleconsultation:
      "The consultation will be conducted by teleconsultation",
    Move: "Move",

    MustChoosenewDate: "You must choose your new date of consultation",
    SuccessUpdate2: "Your consultation has been successfully updated",
    ErrorTrylater: "Something went wrong. Please try again later",
    Confirm: "Confirm",
    Delete: "Delete",
    Today: "Today",
    EmptyListMeeting: "Appointment list is empty",
    updatedAt: "Updated At",
    ConsultationOf: "Consultation of:",
    Prescription: "Prescription",
    Report: "Report",
    Images: "Images",
    Document: "Document",

    Underweight: "Underweight",
    Normal: "Normal",
    Preoccupation: "Preoccupation",
    Obesity1: "Obesity, Class I",
    Obesity2: "Obesity, Class II",
    Obesity3: "Obesity, Class III",
    IM: "Im",

    EditMyHealthData: "Edit my health data",
    Smoker: "Smoker",
    NonSmoking: "Non-smoking",
  },
};
