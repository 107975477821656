/* eslint-disable eqeqeq */
import { useTranslation } from "react-i18next";
import Modal from "../../ModalUI";
import Button from "../../ButtonUI";
import { ReactComponent as Close } from "../../../assets/icons/icon-close.svg";

export const ImageEditModal = ({
  showModalImageOption,
  setShowModalImageOption,
  dataSelectedObj,
  setListData,
  listData,
}) => {
  const { t } = useTranslation() as any;

  return (
    <>
      <Modal
        showModal={showModalImageOption}
        slidemodal
        closeModal={() => {
          setShowModalImageOption(false);
        }}
      >
        <div className="p-8">
          <h2 className="mb-4">{t("TemplatePDF.EditProperties")}</h2>
          <div className="flex justify-end absolute right-2 top-8">
            <Button
              status="icon-secondary"
              type="button"
              onClick={() => setShowModalImageOption(false)}
            >
              <Close />
            </Button>
          </div>
          {dataSelectedObj.type === "image" ? (
            <div className="flex items-center gap-4">
              <div>
                <h3 className="font-light text-black mb-2">
                  {t("TemplatePDF.EditImageOpacity")}{" "}
                </h3>
              </div>
              <div>
                <input
                  type="range"
                  id="opacity"
                  name="opacity"
                  min="0"
                  max="10"
                  value={`${
                    listData.find((x) => x.id == dataSelectedObj.id).opacity
                  }`}
                  onChange={(e) => {
                    const CopyOfData = listData;
                    let foundIndex = listData.findIndex(
                      (x) => x.id == dataSelectedObj.id
                    );
                    CopyOfData[foundIndex] = {
                      ...listData[foundIndex],
                      opacity: parseInt(e.target.value),
                    };
                    setListData([...CopyOfData]);
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </Modal>
    </>
  );
};
