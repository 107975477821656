import { ReactComponent as IconCloseModal } from "../../../assets/icons/icon-close.svg";
import { ReactComponent as WomanPatient } from "../../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../../assets/illustrations/illustration-homme-patient.svg";

import { loadImage } from "../../../api/documents";
import { handleSpeciality } from "../../../helper/handleSpeciality";
import { useTranslation } from "react-i18next";
interface Props {
  doctorDetails: any;
  closeModal: any;
}

const HeaderModal = (props: Props) => {
  const { i18n } = useTranslation();
  const { doctorDetails, closeModal } = props;
  return (
    <div className="flex items-center  header-container">
      <div className="flex justify-between w-full header-chat">
        <div className="flex items-center space-x-5">
          <div className="avatarMedecinChat">
            {doctorDetails?.business_photo ? (
              <img
                crossOrigin="anonymous"
                referrerPolicy="origin"
                src={
                  doctorDetails?.business_photo.slice(0, 4) === "http"
                    ? doctorDetails?.business_photo
                    : loadImage(
                        doctorDetails?._id,
                        "profile",
                        "thumbnail",
                        doctorDetails?.business_photo
                      )
                }
                className="w-16 h-16 object-fill rounded-full shadow-md"
                alt="profile"
              />
            ) : doctorDetails?.sexe === "man" ? (
              <ManPatient />
            ) : (
              <WomanPatient />
            )}
          </div>
          <div className="card-title">
            <span className="card-title">
              {doctorDetails?.firstName} {doctorDetails?.lastName}
            </span>
            <p className="card-subtitle ">
              {handleSpeciality(
                doctorDetails?.speciality,
                i18n.language.includes("en")
              )}
            </p>
          </div>
        </div>
        {/* <div className="folder-container">
          <Button status="primary">
            <IconFolderModal
              style={{
                filter:
                  "invert(97%) sepia(9%) saturate(414%) hue-rotate(66deg) brightness(118%) contrast(100%)",
              }}
            />
          </Button>
        </div> */}
      </div>
      <div className="flex space-x-10 cros cursor-pointer bg-opacity-40 bg-gray-200 rounded-xl">
        <IconCloseModal onClick={closeModal} />
      </div>
    </div>
  );
};

export default HeaderModal;
