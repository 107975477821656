import axios from "axios";
import makeToast from "../components/Snackbar";
// import { refreshToken } from "../api/auth";
// import moment from "moment";
// Add credentail

axios.defaults.withCredentials = true;
axios.defaults.headers.common["Accept-Language"] =
  localStorage.getItem("i18nextLng") || "fr";
// axios.defaults["Accept-Language"] = "fr";

// Refresh token before send request
// axios.interceptors.request.use(async (config) => {
// try {
//   if (
//     localStorage.getItem("rememberMe") &&
//     moment(localStorage.getItem("expireIn")).add(30, "m").isBefore(moment())
//   ) {
//     await refreshToken();
//     localStorage.setItem("expireIn", moment().format());
//   }
//   return config;
// } catch (error) {
//   return config;
// }
// });

//
// axios.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error?.response?.status === 401) {
//       setTimeout(() => {
//         makeToast("warning", "votre session a expiré");
//       }, 2000);
//       window.location.href = "/";
//       // localStorage.clear();
//     }
//     return error;
//   }
// );

// ** NOTE TO EXCLUDE ONE REQUEST FROM THIS INTERCEPTER ADD .create() in axios befor options exemple axios.create().post('/path')
