/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { useAtom } from "jotai";
import moment from "../../helper/moment";
import ImagesContainer from "./imagesContainer";
import Button from "../ButtonUI";
import Modal from "../ModalUI";
import NewPrescriptionModal from "../NewPrescriptionModal";
import { previousState } from "../../state/previousPageState";
import makeToast from "../Snackbar";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { LeftArrow, RightArrow } from "../Arrows";
import Icon from "../Icon";
import pen from "../../assets/images/pen.png";
import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";
import Scene from "./model3D/3DTake";
import { getDoctorsModels } from "../../api/okure";
import { useTranslation } from "react-i18next";

interface Props {
  helperCategorie: any;
  setAddCategorieDocMenu: any;
  addCategorieDocMenu: any;
  documentsList: any;
  patientData: any;
  setImageUrl: any;
  setModalImage: any;
  setDocumentsList: any;
  prescriptions: any;
  display: any;
  refreshPrescription: any;
}
type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;
const Documents = (props: Props) => {
  const { t } = useTranslation() as any;
  const [, setPreviousStates] = useAtom(previousState) as any;
  const [modalPrescription, setModalPrescription] = React.useState(false);
  const [, setLoading] = useState(true);
  const [takes, setTakes] = useState([]);
  const [modalModels, setModalModels] = React.useState(false);
  const [selectedModel, setSelectedModel] = React.useState();

  const history = useHistory();
  const {
    helperCategorie,
    setAddCategorieDocMenu,
    addCategorieDocMenu,
    documentsList,
    patientData,
    setImageUrl,
    setModalImage,
    setDocumentsList,
    prescriptions,
    display,
    refreshPrescription,
  } = props;

  function onWheel(
    apiObj: scrollVisibilityApiType,
    ev: React.WheelEvent
  ): void {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  }

  const CardRapport = ({ itemId, rdv }) =>
    rdv?.rapport?.textRapport ? (
      <div
        className="tache my-1 cursor-pointer mx-3 border"
        onClick={() => {
          //setModalDisplayRaport(true);
          //setSelectedCompteRendu(rdv);
          setPreviousStates({
            patientFile: {
              patientId: rdv?.patientId,
              openTab: 2,
            },
          });
          history.push(`/previewCompteRendu/${rdv?._id}`);
        }}
        key={rdv?._id}
      >
        <div className="flex justify-between items-center header">
          <div className="flex sub-header items-center gap-2">
            <img
              crossOrigin="anonymous"
              referrerPolicy="origin"
              src={pen}
              alt="pen"
              width="20px"
              height="20px"
            />
            <p>
              {" "}
              {t("MyPatient.ConsultationOf")}
              <br />
              <span className="text-primary-blue">
                {moment(rdv.dateConsultation).format("LL")}
              </span>
            </p>
          </div>
          <div>{/* <Icon name="troisPoints" isIcon={true} /> */}</div>
        </div>
        <p className="subTitle-doc">
          {t("MyPatient.UpdateAt")}{" "}
          {moment(rdv.updatedAt).format(t("Moment.Z"))}
        </p>
        <div className="tache-box max-w-max break-all">{rdv.motif}</div>
      </div>
    ) : (
      <></>
    );

  const CardPrescription = ({ itemId, consultation, index }) => {
    return (
      <div
        className="tache my-1 cursor-pointer mx-3 relative border"
        onClick={() => {
          //setModalDisplayPrescription(true);
          //setSelectedCompteRendu(consultation);
          setPreviousStates({
            patientFile: {
              patientId: consultation?.patient,
              openTab: 2,
            },
          });
          history.push(`/preview/${consultation._id}/${consultation.id}`);
        }}
      >
        <div className="flex justify-between items-center header">
          <div className="flex sub-header">
            <div className="flex items-center gap-2">
              <Icon name={"capsule"} isIcon={false} height={20} width={20} />
              <p>
                {t("MyPatient.ConsultationOf")} <br />
                <span className="text-primary-blue">
                  {moment(consultation.dateConsultation).format("LL")}
                </span>
              </p>
            </div>
          </div>
          <div>{/* <Icon name="troisPoints" isIcon={true} /> */}</div>
        </div>
        <p className="subTitle-doc">
          {t("MyPatient.UpdateAt")}{" "}
          {moment(consultation.updatedAt).format(t("Moment.Z"))}
        </p>
        <div className="tache-box max-w-max">{consultation.motif}</div>
        {prescriptions.filter(
          (cslt) => cslt?.prescriptions?.length !== 0 && cslt?.status === "done"
        ).length > 1 && (
          <span className="absolute right-2 bottom-2 text-xs text-primary-grey">
            {t("MyPatient.Prescription")} {index + 1}
          </span>
        )}
      </div>
    );
  };

  const Card3DModel = ({ take, itemId }) => {
    const statusToMessageMap = {
      uploading: "Enregistrement en cours...",
      in_progress: "Modélisation en cours...",
      failed: "Traitement échoué",
      ready: "Prêt",
    };

    return (
      <div
        className="tache my-1 cursor-pointer mx-3 relative border"
        onClick={() => {
          setModalModels(true);
          setSelectedModel(take);
        }}
      >
        <div className="flex justify-between items-center header">
          <div className="flex sub-header">
            <div className="flex items-center gap-2">
              <Icon name={"camera"} isIcon={true} height={20} width={20} />
              <p>
                Capturé le : <br />
                <span className="text-primary-blue">
                  {moment(take?.createdAt).format("LLL")}
                </span>
              </p>
            </div>
          </div>
          <div>{/* <Icon name="troisPoints" isIcon={true} /> */}</div>
        </div>
        <p className="subTitle-doc">
          <b>ID:</b> {take?.name}
        </p>
        <div
          className="tache-box max-w-max"
          style={{
            background:
              take?.status === "ready"
                ? "#bcffab"
                : take?.status === "failed"
                ? "#ffccd2"
                : "#ffe2c8",
          }}
        >
          {statusToMessageMap[take?.status] || "NA"}
          {take?.status === "ready"
            ? ` ${take?.framesProcessed}/${take?.frames}`
            : null}
        </div>
      </div>
    );
  };

  const handleAddNewPrescription = async () => {
    try {
      // Get last done Consultation
      setModalPrescription(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (patientData?._id) {
      getDoctorsModels({ patient: patientData._id /*status: "ready"*/ }).then(
        (response) => {
          setTakes(response?.data || []);
          setLoading(false);
        }
      );
    }
  }, [patientData]);

  return (
    <>
      <div className="sub-documents">
        <div className="flex justify-between title-doc">
          <div className="sub-title">
            <p> {t("MyPatient.Report")}</p>
          </div>
          {/* <Button
                        status="secondary"
                        className="btnAddtache"
                        width={"146px"}
                        height={"35px"}
                      >
                        + Ajouter
                      </Button> */}
        </div>
        <div className="taches flex mt-4">
          {!_.isEmpty(
            patientData?.consultations?.filter((csl) => csl.rapport)
          ) ? (
            <div className="w-full ">
              {/* <HorizontalScroll animValues={150}>
                  {cardRapport()}
                </HorizontalScroll> */}

              <ScrollMenu
                LeftArrow={LeftArrow}
                RightArrow={RightArrow}
                onWheel={onWheel}
              >
                {patientData?.consultations
                  ?.sort(
                    (a, b) =>
                      +new Date(b.dateConsultation) -
                      +new Date(a.dateConsultation)
                  )
                  .map((rdv) => (
                    <CardRapport
                      itemId={rdv?._id} // NOTE: itemId is required for track items
                      key={rdv?._id}
                      rdv={rdv}
                    />
                  ))}
              </ScrollMenu>
            </div>
          ) : (
            <div className="flexCenter w-full anEmptyBox">
              <p className="card-subtitle">{t("MyPatient.NoReport")}</p>
            </div>
          )}
        </div>
        <div className="flex justify-between title-doc">
          <div className="sub-title">
            <p>{t("MyPatient.Prescriptions")}</p>
          </div>
          {!_.isEmpty(
            prescriptions.filter(
              (el) => el?.prescriptions?.length !== 0 && el?.status === "done"
            )
          ) && (
            <Button
              status="secondary"
              className="btnAddtache"
              width={"146px"}
              height={"35px"}
              onClick={handleAddNewPrescription}
            >
              {t("MyPatient.add")}
            </Button>
          )}
        </div>
        <div className="taches mt-4">
          {!_.isEmpty(prescriptions) ? (
            <ScrollMenu
              LeftArrow={LeftArrow}
              RightArrow={RightArrow}
              onWheel={onWheel}
            >
              {prescriptions
                ?.sort(
                  (a, b) =>
                    +new Date(b.dateConsultation) -
                    +new Date(a.dateConsultation)
                )
                .filter(
                  (cslt) =>
                    cslt?.prescriptions?.length !== 0 && cslt?.status === "done"
                )
                .map((consultation, i) => (
                  <CardPrescription
                    itemId={consultation?._id} // NOTE: itemId is required for track items
                    key={consultation?._id}
                    consultation={consultation}
                    index={i}
                  />
                ))}
            </ScrollMenu>
          ) : (
            <div className="flexCenter w-full anEmptyBox">
              <p className="card-subtitle">{t("MyPatient.NoPrescription")}</p>
            </div>
          )}
        </div>
      </div>
      {!display && (
        <div className="sub-documents">
          <div className="flex justify-between title-doc">
            <div className="sub-title">
              <p>{t("MyPatient.Report")} </p>
            </div>
          </div>
          <div className="taches flex">
            {patientData.consultations &&
              patientData.consultations.map((rdv) => {
                return (
                  rdv?.rapport?.textRapport && (
                    <div className="tache my-1">
                      <div className="flex justify-between items-center header">
                        <div className="flex sub-header">
                          <p>
                            {t("MyPatient.ConsultationOf")}
                            <br />
                            <span className="text-primary-blue">
                              {moment(rdv.dateConsultation).format("LL")}
                            </span>
                          </p>
                        </div>
                        <div></div>
                      </div>
                      <p className="subTitle-doc">{rdv.subTitle}</p>
                      <div className="tache-box max-w-max">{rdv.motif}</div>
                    </div>
                  )
                );
              })}
          </div>
        </div>
      )}
      <div className="sub-documents">
        <div className="flex justify-between title-doc">
          <div className="sub-title">
            <p> {t("MyPatient.Model3D")}</p>
          </div>
        </div>
        <div className="taches flex mt-4">
          {!_.isEmpty(takes) ? (
            <ScrollMenu
              LeftArrow={LeftArrow}
              RightArrow={RightArrow}
              onWheel={onWheel}
            >
              {takes.map((take: any, i) => (
                <Card3DModel
                  itemId={take?._id || take?.name || i} // NOTE: itemId is required for track items
                  take={take}
                  key={take?._id || take?.name || i}
                />
              ))}
            </ScrollMenu>
          ) : (
            <div className="flexCenter w-full anEmptyBox">
              <p className="card-subtitle">Ce patient n'a aucun modèle 3D.</p>
            </div>
          )}
        </div>
      </div>
      <ImagesContainer
        helperCategorie={helperCategorie}
        setAddCategorieDocMenu={setAddCategorieDocMenu}
        addCategorieDocMenu={addCategorieDocMenu}
        documentsList={documentsList}
        patientData={patientData}
        setImageUrl={setImageUrl}
        setModalImage={setModalImage}
        setDocumentsList={setDocumentsList}
      />

      <Modal showModal={modalPrescription}>
        <NewPrescriptionModal
          closeModal={() => setModalPrescription(false)}
          myPatient={true}
          consultationDetails={
            _.isEmpty(
              patientData?.consultations?.filter((el) => el.status == "done")
            )
              ? {}
              : patientData.consultations
                  .filter((el) => el.status == "done")
                  .sort(
                    (a, b) =>
                      +new Date(b.dateConsultation) -
                      +new Date(a.dateConsultation)
                  )[0]
          }
          setIsSuccess={() => {
            refreshPrescription();
            makeToast("success", "Nouvelle prescription ajoutée avec succès");
          }}
        />
      </Modal>
      <Modal showModal={modalModels} width={"w-11/12"} disableScroll>
        <div className="m-5">
          <div className="absolute right-0 mr-8">
            <div>
              <Button
                status="icon-secondary"
                onClick={() => setModalModels(false)}
              >
                <Close />
              </Button>
            </div>
          </div>
          <Scene selectedModel={selectedModel} />
        </div>
      </Modal>
    </>
  );
};

export default Documents;
