/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
import _ from "lodash";

import Input from "../../../components/Input";
import Select from "../../../components/Select";
import Button from "../../../components/ButtonUI";
import CountryWithPhoneSuffix from "../../../config/constants/pays.json";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import Tooltip from "../../../components/toolTip";
import cake from "../../../assets/images/cake.png";
import { dropdownSexeOptions } from "../../../helper/insHelper";
import requireInput from "../../../assets/images/require.png";
import { getAllMAtriculeINS } from "../../../api/doctor";
import makeToast from "../../../components/Snackbar";
import fr from "date-fns/locale/fr";
import { useTranslation } from "react-i18next";

registerLocale("fr", fr);
setDefaultLocale("fr");
const Step2 = ({
  values,
  handleChange,
  handleBlur,
  valueConfirmed,
  selectedRegionState,
  setSelectedRegionState,
  regionState,
  selectedDepartementState,
  setSelectedDepartementState,
  departementState,
  selectedCommunesState,
  setSelectedCommunesState,
  setFieldValue,
  communesState,
  startDateINS,
  setStartDateINS,
  loadingINS,
  handleVerifyINS,
  handleVerifyINSWithMultipleName,
  setSteps,
  setStartDate,
  loadingBtn,
}) => {
  const { t } = useTranslation() as any;
  const [matriculeINS, setMatriculeINS] = useState([]) as any;

  const _getAllMAtriculeINS = async () => {
    try {
      const response = await getAllMAtriculeINS();
      if (!_.isEmpty(response)) setMatriculeINS(response);
    } catch (error) {}
  };

  useEffect(() => {
    _getAllMAtriculeINS();
  }, []);

  return (
    <>
      <>
        <Input
          id="firstNameINS"
          placeholder={t("AddPatient.firstNameS2")}
          type="text"
          value={values.firstNameINS}
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
          }}
          className="mb-2.5"
          iconColor="white"
          isIcon={true}
          icon="user"
          image="require"
          right={true}
          stylesDiv="relative"
          imageCLS="w-4 max-h-1"
          disabled={valueConfirmed}
        />
        <Input
          id="NameINS"
          placeholder={t("AddPatient.birthName")}
          type="text"
          value={values.NameINS}
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
          }}
          className="mb-2.5"
          iconColor="white"
          isIcon={true}
          icon="user"
          image="require"
          right={true}
          stylesDiv="relative"
          imageCLS="w-4 max-h-1"
          disabled={valueConfirmed}
        />
        <Select
          id="country"
          value={values.pays}
          divProps="w-full"
          className="mb-2.5 w-full rounded-lg pl-3"
          options={CountryWithPhoneSuffix.map((el) => ({
            value: el.name,
            label: el.name,
          }))}
          onChange={handleChange}
          onBlur={handleBlur}
          text={t("AddPatient.paysBirth")}
          required
        />
        {values.country === "France" && (
          <>
            <Select
              text={t("AddPatient.regionsBirth")}
              className="mb-2.5 w-full rounded-lg pl-3"
              value={selectedRegionState ? selectedRegionState.value : ""}
              divProps="w-full"
              options={regionState}
              onChange={(e) => {
                setSelectedRegionState(
                  regionState.find((elm) => elm.value == e.target.value)
                );
              }}
            />
            {selectedRegionState?.value && (
              <Select
                text={t("AddPatient.departmentsBirth")}
                className="mb-2.5 w-full rounded-lg pl-3"
                divProps="w-full"
                value={
                  selectedDepartementState ? selectedDepartementState.value : ""
                }
                options={departementState}
                onChange={(e) => {
                  setSelectedDepartementState(
                    departementState.find((elm) => elm.value == e.target.value)
                  );
                }}
              />
            )}
            {selectedDepartementState?.value && (
              <Select
                text={t("AddPatient.communeBirth")}
                className="mb-2.5 w-full rounded-lg pl-3"
                divProps="w-full"
                value={selectedCommunesState ? selectedCommunesState.value : ""}
                options={communesState}
                onChange={(e) => {
                  setSelectedCommunesState(
                    communesState.find((elm) => elm.value == e.target.value)
                  );
                  setFieldValue(
                    "birthAddressINS",
                    communesState.find((elm) => elm.value == e.target.value)
                      ?.code
                  );
                }}
              />
            )}
          </>
        )}
        <Input
          id="birthAddressINS"
          placeholder={t("AddPatient.placeOfBirth")}
          type="text"
          value={values.birthAddressINS}
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
          }}
          className="mb-2.5"
          icon="home"
          iconColor="white"
          isIcon={true}
          disabled={valueConfirmed}
        />
        <div className="relative">
          <DatePicker
            id="birthDateINS"
            locale="fr"
            className="custom-Input mb-2.5 "
            selected={startDateINS}
            dateFormat="ddMMyyyy"
            onChange={(date) => {
              setStartDateINS(date);
            }}
            placeholderText={t("AddPatient.dateOfBirth")}
            autoComplete="off"
            showYearDropdown
            disabled={valueConfirmed}
          />
          <img
            crossOrigin="anonymous"
            referrerPolicy="origin"
            src={cake}
            alt="cake"
            className="absolute top-3 left-3"
            width="18"
          />
          <img
            crossOrigin="anonymous"
            referrerPolicy="origin"
            src={requireInput}
            alt="require"
            className="absolute right-3 top-3 w-1.5 h-1.5"
          />
        </div>
        <Select
          id="INSSexe"
          value={values.INSSexe}
          divProps="w-full"
          className="mb-2.5 w-full rounded-lg pl-3"
          options={dropdownSexeOptions}
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
          }}
          text={t("AddPatient.YourSex")}
          required
          disabled={valueConfirmed}
        />

        <div className="relative ">
          <Input
            id="matriculeINS"
            placeholder={t("AddPatient.INSNumber")}
            type="text"
            value={values.matriculeINS}
            onChange={handleChange}
            onBlur={(e) => {
              handleBlur(e);
            }}
            className="mb-5"
            image2="securite-sociale"
            right={true}
            stylesDiv="relative"
            imageCLS="w-4 max-h-1"
            disabled={valueConfirmed}
          />
          {!valueConfirmed && (
            <div className=" absolute top-2 right-2">
              <Tooltip
                tooltipText={t("AddPatient.VerificationINS")}
                position="top"
              >
                {loadingINS ? (
                  <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-purple-500" />
                ) : (
                  <img
                    crossOrigin="anonymous"
                    referrerPolicy="origin"
                    alt="search"
                    src={
                      require("../../../assets/icons/icon-search.svg").default
                    }
                    width="35"
                    className="cursor-pointer icon-blue"
                    onClick={() => {
                      if (values.firstNameINS) {
                        if (values.firstNameINS.split(" ").length === 1)
                          handleVerifyINS(values, setFieldValue);
                        else
                          handleVerifyINSWithMultipleName(
                            values,
                            setFieldValue
                          );
                      }
                    }}
                  />
                )}
              </Tooltip>
            </div>
          )}{" "}
          {valueConfirmed && (
            <Input
              id="identifiantSysteme"
              type="text"
              value={values.identifiantSysteme}
              onChange={handleChange}
              onBlur={(e) => {
                handleBlur(e);
              }}
              className="mb-5"
              right={true}
              stylesDiv="relative"
              disabled
            />
          )}
        </div>

        {matriculeINS && matriculeINS.includes(values.matriculeINS) && (
          <span className="text-red-500">{t("AddPatient.INSExist")}</span>
        )}
        <div className="w-full mb-2.5">
          {/* **************************************************************** */}
          <Input
            id="identity"
            name="identity"
            type="radio"
            onChange={handleChange}
            value="IDDoting"
            labelText={t("AddPatient.SI")}
            labelProps={{
              className: "font-semibold text-sm   pl-2",
            }}
          />
          <div className="mb-2" />
          <Input
            id="identity"
            name="identity"
            type="radio"
            onChange={handleChange}
            value="Idfictional"
            labelText={t("AddPatient.FI")}
            labelProps={{
              className: "font-semibold text-sm   pl-2",
            }}
          />
          <div className="mb-2" />

          <Input
            id="identity"
            name="identity"
            type="radio"
            onChange={handleChange}
            value="idvalid"
            labelText={t("AddPatient.VI")}
            labelProps={{
              className: "font-semibold text-sm   pl-2",
            }}
            defaultChecked
          />
          <div className="mb-2" />

          <Input
            id="identity"
            name="identity"
            type="radio"
            onChange={handleChange}
            value="idNonValid"
            labelText={t("AddPatient.INV")}
            labelProps={{
              className: "font-semibold text-sm   pl-2",
            }}
          />
          <div className="mb-4" />

          {/* **************************************************************** */}

          <Button
            status="primary"
            height="50px"
            className="mb-2.5 w-full"
            type="button"
            onClick={() => {
              //verify required field
              // const regexNom = /^[A-Z-' ]+$/; // majuscule + ' + - + white space
              // const regexLieux = /^[0-9]+$/;
              if (
                !startDateINS ||
                !values.NameINS ||
                !values.firstNameINS ||
                !values.INSSexe
              ) {
                return makeToast("warning", t("AddPatient.FillAll"));
              } else if (!values.matriculeINS)
                return makeToast("warning", t("AddPatient.INSRequire"));
              // else if (!regexNom.test(values.NameINS)) {
              //   return makeToast("warning", t("AddPatient.PasswordContraint"));
              // }
              //  else if (
              //   values?.birthAddressINS &&
              //   !regexLieux.test(values?.birthAddressINS)
              // )
              //   return makeToast(
              //     "warning",
              //     t("AddPatient.BirthPlaceContraint")
              //   );
              else {
                !values.firstName &&
                  setFieldValue(
                    "firstName",
                    values.firstNameINS.trim().split(",").join(" ")
                  );
                !values.lastName && setFieldValue("lastName", values.NameINS);
                setFieldValue("country", "France");
                setFieldValue(
                  "sexe",
                  values.INSSexe === "M" ? ["man"] : ["woman"]
                );
                setStartDate(new Date(startDateINS));
                setSteps(3);
              }
              //auto complete field
            }}
            disabled={loadingBtn || loadingINS}
          >
            {t("AddPatient.next")}
          </Button>
        </div>
      </>
    </>
  );
};

export default Step2;
