export const Teleconsultation = {
  fr: {
    End: "Terminer",
    SeeFile: "Voir son dossier",
    AutoSaved: "Enregistré automatiquement à",
    alreadyEnd: "La consultation est deja terminée",
    LeaveConsultation: "Vous êtes sur le point de quitter la consultation",
    Confirm: "Confirmer",
    LeftConsultation: "Vous avez quitté la consultation",
    Prescription: "+ Prescription",
    Report: "+ Compte Rendu",
    PutToDo: "Mettre dans la liste des tâches",
    WriteNote: "Écrire une note...",
  },
  en: {
    PutToDo: "Put in to-do list",
    Prescription: "+ Prescription",
    Report: "+ Report",
    LeftConsultation: "You have left the consultation",
    LeaveConsultation: "You are about to leave the consultation",
    alreadyEnd: "The consultation is already over",
    End: "Finish",
    SeeFile: "See his file",
    AutoSaved: "Automatically saved at",
    Confirm: "Confirm",
    WriteNote: "Write a note...",
  },
};
