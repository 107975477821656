import draftToHtml from "draftjs-to-html";
import makeToast from "../../Snackbar";
import { createPDFToDoctor } from "../../../api/pdfTemalpte";
import { convertToRaw } from "draft-js";
import history from "../../../history";

export const AddTemplate = async (
  templateName,
  modalType,
  listData,
  user,
  editorState,
  t
) => {
  if (templateName === "" && modalType === "") {
    makeToast("warning", t("TemplatePDF.FillRequireInput"));
  } else {
    const formData = new FormData();
    formData.append("listData", JSON.stringify(listData));
    formData.append("doctorID", user?._id);
    formData.append(
      "text",
      editorState === undefined
        ? ""
        : draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    formData.append("templateName", templateName);
    formData.append("modalType", modalType);
    await createPDFToDoctor(formData);
    makeToast("success", t("TemplatePDF.SuccessSaveTemplate"));
    history.push("/pdf");
  }
};
