import React, { useState } from "react";

type Props = {
  tooltipText: string;
  children: any;
  position: string;
  clsText?: any;
};

function Tooltip(props: Props) {
  const { tooltipText, children, position, clsText } = props;
  const tipRef = React.createRef() as any;

  // const top, bottom, left, right;
  const handlePositionObject = () => {
    switch (position) {
      case "top":
        return {
          margin: "marginTop",
          positionInverse: "bottom",
          transform: "-45deg",
        };
      case "bottom":
        return {
          margin: "marginBottom",
          positionInverse: "top",
          transform: "135deg",
        };
      case "left":
        return {
          margin: "marginLeft",
          positionInverse: "right",
          transform: "225deg",
        };
      case "right":
        return {
          margin: "marginRight",
          positionInverse: "left",
          transform: "45deg",
        };
      default:
        return {
          margin: "marginRight",
          positionInverse: "left",
          transform: "45deg",
        };
    }
  };

  const [handlePosition] = useState(handlePositionObject()) as any;

  function handleMouseEnter() {
    tipRef.current.style[handlePosition.margin] = "30px";
    tipRef.current.style.display = "block";
  }
  function handleMouseLeave() {
    tipRef.current.style[handlePosition.margin] = "20px";
    tipRef.current.style.display = "none";
  }

  return (
    <div
      className="relative flex items-center "
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`absolute hidden whitespace-no-wrap bg-gradient-to-r from-black to-gray-700 text-white px-4 py-2 rounded items-center transition-all duration-150 `}
        style={{ [handlePosition.positionInverse]: "100%", ...clsText }}
        ref={tipRef}
      >
        <div
          className="bg-black h-3 w-3 absolute"
          style={{
            [handlePosition.positionInverse]: "-6px",
            transform: `rotate(${handlePosition.transform})`,
          }}
        />
        {tooltipText}
      </div>
      {children}
    </div>
  );
}
export default Tooltip;
