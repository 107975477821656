import { useEffect, useState } from "react";
import classNames from "classnames";
import moment from "../../../../helper/moment";
import Button from "../../../../components/ButtonUI";
import Icon from "../../../../components/Icon";
import { ITreatements } from "../../../../types/patient";
import AddObservation from "../../../../components/SharedPatientDetails/modal/addObservation";
import ShowObservations from "../../../MyPatietns/modal/showObservations";
import { useTranslation } from "react-i18next";

const Traitements = ({
  patientData,
  ShareTraitements,
  sharedTraitement,
  setSharedTraitement,
  disabled,
}) => {
  const { t } = useTranslation() as any;
  const [treatementEnCours, setTreatementEnCours] = useState<ITreatements[]>(
    []
  );
  const [treatementsAncien, setTreatementsAncien] = useState<ITreatements[]>(
    []
  );
  const [typeObservation, setTypeObservation] = useState({
    typeObservation: "",
    idObservation: "",
  });

  const [observationModal, setObservationModal] = useState(false);
  const [showObservationsModal, setShowObservationsModal] = useState(false);
  const [selectedObservationId, setSelectedObservationId] = useState("");
  const [typeOfAddObservation, setTypeOfAddObservation] = useState("");
  // const [sharedTraitement, setSharedTraitement] = useState([]) as any;

  const handleShareTraitement = () => {
    ShareTraitements([], [], sharedTraitement);
  };

  useEffect(() => {
    if (patientData?.treatements) {
      let tCours = [] as any;
      let tAncien = [] as any;
      patientData?.treatements.forEach((e) => {
        if (e.traitement === "Traitement en cours") {
          tCours.push(e);
        } else {
          tAncien.push(e);
        }
      });
      setTreatementEnCours(tCours);
      setTreatementsAncien(tAncien);
    }
  }, [patientData]);
  return (
    <div className="share-folder-traitement">
      <div className="flex justify-between ">
        <div className="mb-3">
          <h3>{t("Specialist.inProgress")}</h3>
        </div>
        {/* <div className="flex">
                      <Button
                        status="secondary"
                        className="btnAddtache"
                        width={"146px"}
                        height={"35px"}
                        onClick={() => {
                          setOpenModalTraitement(true);
                          setTreatementType && setTreatementType("En cours");
                        }}
                      >
                        + Ajouter
                      </Button>

                    </div> */}
      </div>
      <div className="flex flex-wrap gap-2 max-h-96 overflow-auto ">
        {treatementEnCours && treatementEnCours.length > 0 ? (
          treatementEnCours.map((traitement) => (
            <div
              className={classNames(
                "card-traitement relative",
                sharedTraitement?.length > 0 &&
                  sharedTraitement.includes(traitement._id) &&
                  "border-blue"
              )}
            >
              <div className="absolute top-2 right-2 ">
                <label className="container-checkbox ">
                  <input
                    type="checkbox"
                    checked={sharedTraitement.includes(traitement._id)}
                    onChange={() => {
                      sharedTraitement &&
                      sharedTraitement.length > 0 &&
                      sharedTraitement.includes(traitement._id)
                        ? setSharedTraitement(
                            sharedTraitement
                              .slice()
                              .filter((elm) => elm !== traitement._id)
                          )
                        : setSharedTraitement([
                            ...sharedTraitement,
                            traitement._id,
                          ]);
                    }}
                  />
                  <span
                    className="checkmark"
                    onClick={() => {
                      sharedTraitement &&
                      sharedTraitement.length > 0 &&
                      sharedTraitement.includes(traitement._id)
                        ? setSharedTraitement(
                            sharedTraitement
                              .slice()
                              .filter((elm) => elm !== traitement._id)
                          )
                        : setSharedTraitement([
                            ...sharedTraitement,
                            traitement._id,
                          ]);
                    }}
                  ></span>
                </label>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex card-title">
                  <p>{traitement.type}</p>
                </div>
                {/* <div>
                  <Icon name="troisPoints" isIcon={true} />
                </div> */}
              </div>
              <p className="card-subtitle">
                {t("Specialist.UpdateAt")}
                <strong>{` ${moment(traitement.updatedAt).format(
                  t("Moment.Z")
                )} `}</strong>
              </p>
              <div className="flex">
                {traitement["medicament"] &&
                  traitement["medicament"].map((medic) => {
                    return (
                      <div className="max-w-max flex">
                        <Icon
                          name="capsule"
                          isIcon={false}
                          width={14}
                          height={16}
                        />
                        <p>{medic}</p>
                      </div>
                    );
                  })}
              </div>
              <p className="addObservation menu flexCenter traitementbtn blue-text">
                {traitement.observationsDoctor.length === 0 ? (
                  <p
                    className="addObservation menu flexCenter cursor-pointer"
                    onClick={() => {
                      setSelectedObservationId(traitement._id);
                      setObservationModal(true);
                    }}
                  >
                    {" "}
                    {t("Specialist.AddObservations")}
                  </p>
                ) : (
                  <div className="flexCenter">
                    <div
                      className="menu addObservation cursor-pointer"
                      onClick={() => {
                        setTypeObservation({
                          typeObservation: "treatements",
                          idObservation: traitement._id,
                        });
                        setShowObservationsModal(true);
                      }}
                    >
                      {traitement.observationsDoctor.length}{" "}
                      {t("Specialist.observations")}
                    </div>
                    <div
                      className="menu ml-6 addObservation cursor-pointer"
                      onClick={() => {
                        setSelectedObservationId(traitement._id);
                        setObservationModal(true);
                      }}
                    >
                      {t("Specialist.Add")}
                    </div>
                  </div>
                )}
              </p>
            </div>
          ))
        ) : (
          <div className="flexCenter w-full anEmptyBox">
            <p className="card-subtitle">{t("Specialist.NotreatmentFound")}</p>
          </div>
        )}
      </div>
      <div className="flex justify-between">
        <div className="my-5">
          <h3> {t("Specialist.old")}</h3>
        </div>
        {/* <div className="flex">
          <Button
            status="secondary"
            style={{ transition: "all .15s ease" }}
            className="btnAddtache"
            width={"146px"}
            height={"35px"}
            onClick={() => {
              setOpenModalTraitement(true);
              setTreatementType && setTreatementType("Ancien");
            }}
          >
            + Ajouter
          </Button>
        </div> */}
      </div>
      <div className="taches flex flex-wrap gap-2 max-h-96 overflow-auto ">
        {treatementsAncien && treatementsAncien.length > 0 ? (
          treatementsAncien.map((traitement) => {
            return (
              <div
                className={classNames(
                  "card-traitement relative",
                  sharedTraitement?.length > 0 &&
                    sharedTraitement.includes(traitement._id) &&
                    "border-blue"
                )}
              >
                <div className="absolute top-2 right-2 ">
                  <label className="container-checkbox ">
                    <input
                      type="checkbox"
                      checked={sharedTraitement.includes(traitement._id)}
                      onChange={() => {
                        sharedTraitement?.length > 0 &&
                        sharedTraitement.includes(traitement._id)
                          ? setSharedTraitement(
                              sharedTraitement
                                .slice()
                                .filter((elm) => elm !== traitement._id)
                            )
                          : setSharedTraitement([
                              ...sharedTraitement,
                              traitement._id,
                            ]);
                      }}
                    />
                    <span
                      className="checkmark"
                      onClick={() => {
                        sharedTraitement &&
                        sharedTraitement.length > 0 &&
                        sharedTraitement.includes(traitement._id)
                          ? setSharedTraitement(
                              sharedTraitement
                                .slice()
                                .filter((elm) => elm !== traitement._id)
                            )
                          : setSharedTraitement([
                              ...sharedTraitement,
                              traitement._id,
                            ]);
                      }}
                    ></span>
                  </label>
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex card-title">
                    <p>{traitement.type}</p>
                  </div>
                  {/* <div>
                    <Icon name="troisPoints" isIcon={true} />
                  </div> */}
                </div>
                <p className="card-subtitle">
                  {t("Specialist.UpdateAt")}
                  <strong>{` ${moment(traitement.updatedAt).format(
                    t("Moment.Z")
                  )}`}</strong>
                </p>
                <div className="flex">
                  {traitement["medicament"] &&
                    traitement["medicament"].map((medic) => {
                      return (
                        <div className="max-w-max flex">
                          <Icon
                            name="capsule"
                            isIcon={false}
                            width={14}
                            height={16}
                          />
                          <p>{medic}</p>
                        </div>
                      );
                    })}
                </div>
                <p className="addObservation menu flexCenter traitementbtn blue-text">
                  {traitement.observationsDoctor.length &&
                  traitement.observationsDoctor.length === 0 ? (
                    <p
                      className="addObservation menu flexCenter"
                      onClick={() => {
                        setSelectedObservationId(traitement._id);
                        setObservationModal(true);
                      }}
                    >
                      {t("Specialist.AddObservations")}
                    </p>
                  ) : (
                    <div className="flexCenter">
                      <div
                        className="menu addObservation cursor-pointer"
                        onClick={() => {
                          setTypeObservation({
                            typeObservation: "treatements",
                            idObservation: traitement._id,
                          });
                          setShowObservationsModal(true);
                        }}
                      >
                        {traitement.observationsDoctor.length}{" "}
                        {t("Specialist.observations")}
                      </div>
                      <div
                        className="menu ml-6 addObservation cursor-pointer"
                        onClick={() => {
                          setSelectedObservationId(traitement._id);
                          setObservationModal(true);
                        }}
                      >
                        {t("Specialist.Add")}
                      </div>
                    </div>
                  )}
                </p>
              </div>
            );
          })
        ) : (
          <div className="flexCenter w-full anEmptyBox">
            <p className="card-subtitle">
              {t("Specialist.NoOldtreatmentFound")}
            </p>
          </div>
        )}
      </div>
      <Button
        className="py-3 my-5 w-full"
        onClick={handleShareTraitement}
        disabled={disabled}
      >
        {t("Specialist.Share")}
      </Button>
      {showObservationsModal && (
        <ShowObservations
          setOpenModal={setShowObservationsModal}
          selectedPatient={patientData._id}
          openModal={observationModal}
          typeObservationObject={typeObservation}
          patientId={patientData._id}
        />
      )}
      {observationModal && (
        <AddObservation
          setOpenModal={setObservationModal}
          selectedPatient={patientData._id}
          selectedObservationId={selectedObservationId}
          openModal={observationModal}
          typeOfAddObservation={typeOfAddObservation}
          setTreatementEnCours={setTreatementEnCours}
          setTreatementsAncien={setTreatementsAncien}
          treatementEnCours={treatementEnCours}
          treatementsAncien={treatementsAncien}
          setTypeOfAddObservation={setTypeOfAddObservation}
        />
      )}
    </div>
  );
};

export default Traitements;
