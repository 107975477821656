import { Redirect, RouteProps } from "react-router";

import Agenda from "../pages/Agenda";

import Consultation from "../pages/ConsultationDoctor";
import MotifConsultation from "../pages/MotifConsultation";
import EditMotifConsultation from "../pages/MotifConsultation/editMotif";
import Dashboard from "../pages/DashboardDoctor";
import Profile from "../pages/Profile";
import MyPatients from "../pages/MyPatietns";
import Specialist from "../pages/Specialist";
import Facture from "../pages/Facture";
import DoctorReport from "../pages/DoctorReport";
import MyTeleconsultations from "../pages/MyTeleconsultations";
import Teleconsultation from "../pages/Teleconsultation";
import Paymee from "../pages/Paymee";
import PreviewOrdonnnance from "../components/PreviewOrdonannce";
import PreviewRapport from "../components/PreviewRapport";
import Dragable from "../pages/DragableInterface";
import Extension from "../pages/Extension";
import Forms from "../pages/Forms/";
import SurveyStats from "../pages/SurveyStats";
import CustomPDF from "../pages/CustomPDF";
import roomTeleconsultation from "../pages/VideoRoom";
import AddCustomPdf from "../components/TemplatePDFCreator/customPDFIO";

const doctorRoutes: RouteProps[] = [
  {
    path: "/room-teleconsultation",
    component: roomTeleconsultation,
    exact: true,
  },
  {
    path: "/preview/:taskId/:prescriptionId",
    component: PreviewOrdonnnance,
    exact: true,
  },
  {
    path: "/previewCompteRendu/:consultationId",
    component: PreviewRapport,
    exact: true,
  },

  {
    path: "/mes-patients",
    component: MyPatients,
    exact: true,
  },
  {
    path: "/mes-teleconsultations",
    component: MyTeleconsultations,
    exact: true,
  },
  {
    path: "/specialist",
    component: Specialist,
    exact: true,
  },
  {
    path: "/consultation/:idConsultation",
    component: Consultation,
    exact: true,
  },
  {
    path: "/consultation-report/:idConsultation",
    component: DoctorReport,
    exact: true,
  },

  {
    path: "/paymee",
    component: Paymee,
    exact: true,
  },

  {
    path: "/agenda",
    component: Agenda,
    exact: true,
  },
  {
    path: "/patient/consultation/add/:id",
    component: Consultation,
    exact: true,
  },
  {
    path: "/motif",
    component: MotifConsultation,
    exact: true,
  },
  {
    path: "/motif/:id",
    component: EditMotifConsultation,
    exact: true,
  },

  {
    path: "/teleconsultation/:idConsultation",
    component: Teleconsultation,
    exact: true,
  },
  {
    path: "/profile",
    component: Profile,
    exact: true,
  },
  {
    path: "/facture",
    component: Facture,
    exact: true,
  },
  {
    path: "/creation/:surveryId",
    component: Dragable,
    exact: true,
  },
  {
    path: "/forms",
    component: Forms,
    exact: true,
  },
  {
    path: "/stats/:surveryId",
    component: SurveyStats,
    exact: true,
  },
  {
    path: "/extension",
    component: Extension,
    exact: true,
  },

  {
    path: "/pdf/createPdf",
    component: AddCustomPdf,
    exact: true,
  },
  {
    path: "/pdf/createPdf/:templateId",
    component: AddCustomPdf,
    exact: true,
  },
  {
    path: "/pdf",
    component: CustomPDF,
    exact: true,
  },
  {
    path: "/",
    component: Dashboard,
    exact: true,
  },
];

doctorRoutes.push({ render: () => <Redirect to="/" /> });

export default doctorRoutes;
