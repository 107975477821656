import { IPatient } from "./patient";

export enum UserRole {
  Doctor = "DOCTOR",
  Patient = "PATIENT",
  Guest = "GUEST",
}

export interface User {
  role: UserRole;
  accountVerified: boolean;
  address: string;
  consultations: [];
  country: string;
  createdAt: Date;
  email: string;
  firstName: string;
  imagesNumber: number;
  lastName: string;
  liberrexData: ILiberrexDoctor;
  liberrexId: number;
  numCom: string;
  phoneNumber: string;
  speciality: string;
  updatedAt: Date;
  token: string;
  _id: string;
  __v?: number;
  pricingNight?: number;
  pricingWeekEnd?: number;
  pricing?: number;
  studiesHistory: IStudies[];
  description: string;
  linkedin: string;
  facebook: string;
  instagram: string;
  website: string;
  expertises: IExpertises[];
  patients: [];
  patient?: IPatient | any;
  langues: string[];
  agendaId?: string;
  signaturePath?: string;
  workingLocation?: IWorkingLocation[];
  detailsAddress: string;
  doctors?: [];
  otherPrice?: [IOtherPrices];
  currency?: ICurrency;
  stripeAccount?: any;
  update?: boolean;
  addressDetails?: IAddressDetails;
  AgendaConfigPresentiel?: any;
  AgendaConfigTeleconsultation?: any;
  AgendaConfigActe?: any;
  timeZone?: string;
  extensions?: any;
  pricingTeleconsultation?: any;
  idFacturationCps?: string;
  psc?: any;
}

interface ICurrency {
  id: string;
  code: string;
}
interface IOtherPrices {
  tarifLabel: String;
  price: String;
}
export interface IWorkingLocation {
  _id: string;
  location: string;
  availibility: string;
}

export interface IExpertises {
  _id: string;
  expertise: string;
}

export interface IStudies {
  _id?: string;
  diplome: string;
  university: string;
  year: string;
}

export interface IAddressDetails {
  country?: string;
  region?: string;
  departement?: string;
  commune?: string;
  gouvernement?: string;
  delegation?: string;
}
interface ILiberrexDoctor {
  action: string;
  booking_days: IBookingDays[];
  business: object;
  business_id: number;
  created_at: Date;
  department: null;
  department_id: string;
  department_relation: [];
  description: null;
  email: string;
  fname: string;
  id: number;
  lname: string;
  organization_id: null;
  permissions: [];
  photo: string;
  position: string;
  prefix: null;
  role: string;
  roles: object[];
  social_auth_id: null;
  social_auth_provider: null;
  status: string;
  telephone: string;
  title: null;
  type: null;
  updated_at: Date;
  workingDays: object[];
  working_days: object[];
}

interface IBookingDays {
  active: number;
  business_id: number;
  created_at: Date;
  day: string;
  end_time: string;
  id: number;
  member_id: number;
  start_time: string;
  updated_at: Date;
}

export interface CurrentUser {
  liberrexData: any;
  patient: any;
  role: UserRole;
  token: string;
  [key: string]: string | any;
}
