/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useAtom } from "jotai";
import classNames from "classnames";
import _ from "lodash";
import moment from "../../helper/moment";
import DownloadFile from "../../assets/download-files.jpg";

import { userAtom } from "../../state/auth";
import { loadBill } from "../../api/documents";
import { getAllPalier } from "../../api/palier";
import Payment from "./Payment";
import Modal from "../../components/ModalUI";
import "./styles.scss";

const Facture = () => {
  const [paymentModal, setPaymentModal] = React.useState(false);
  const [selectedBill, setSelectedBill] = React.useState() as any;
  const [palier, setPalier] = React.useState([]) as any;
  const [user, setUser] = useAtom(userAtom) as any;
  const [bills, setBills] = React.useState([]) as any;
  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  const [, setTotalResult] = React.useState(0);
  const [limit] = React.useState(7);

  // const totalPayment = _.isEmpty(bills)
  //   ? 0
  //   : bills.reduce((a, b) => {
  //       return !b.billedAt && a + +b.price;
  //     }, 0);

  const _getAllPalier = async () => {
    try {
      const response = await getAllPalier();
      setPalier(response?.palier);
    } catch (error) {}
  };

  React.useEffect(() => {
    _getAllPalier();
  }, []);

  React.useEffect(() => {
    if (!_.isEmpty(user?.katomiTNBilled)) {
      setTotalResult(user.katomiTNBilled.length);
      setTotalPage(Math.ceil(user.katomiTNBilled.length / limit));
      setBills(
        user.katomiTNBilled
          .sort((a, b) => +new Date(b.billableAt) - +new Date(a.billableAt))
          .slice((page - 1) * limit, (page - 1) * limit + limit)
      );
    }
  }, [page, user]);

  return (
    <div className="facture bg-white mt-5 rounded-3xl">
      <div className="headerContainer flex items-center relative">
        <img
          crossOrigin="anonymous"
          referrerPolicy="origin"
          src={DownloadFile}
          alt="telechager"
          width={30}
          height={30}
          className="ml-5"
        />
        <p className="header">Factures</p>
      </div>
      {/*   <h1>Mes Factures</h1>
    <div className="flex justify-center items-center">
        <div className="bg-gray-200 flex flex-col justify-center items-center w-96 h-40 rounded-xl ">
          <h1>
            {totalPayment ? totalPayment : 0}
            <sup>DT</sup>
          </h1>
          <h3>Montant total à payer TTC</h3>
        </div>
      </div> */}

      <div className="mt-10">
        <table className="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                Réference
              </th>
              <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                Date
              </th>
              <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                Facture
              </th>
              <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                Palier
              </th>
              <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                Montant
              </th>
              <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                Statut
              </th>
            </tr>
          </thead>

          <tbody>
            {_.isEmpty(bills) ? (
              <></>
            ) : (
              bills
                .sort(
                  (a, b) => +new Date(b.billableAt) - +new Date(a.billableAt)
                )
                .map((el) => {
                  return (
                    <tr className="">
                      <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left  ">
                        {" "}
                        {el?.reference ? el?.reference : el?._id}
                      </td>
                      <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                        {" "}
                        {moment(el?.billableAt).format("DD/MM/YYYY")}
                      </td>
                      <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left cursor-pointer">
                        {" "}
                        <a
                          href={loadBill(user._id, el.bill)}
                          target="_blank"
                          rel="noreferrer"
                          download
                        >
                          <img
                            crossOrigin="anonymous"
                            referrerPolicy="origin"
                            src={DownloadFile}
                            alt="telechager"
                            width={30}
                            height={30}
                          />
                        </a>
                      </td>
                      <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                        {" "}
                        {palier.find((elm) => elm._id == el?.palier)?.name}
                      </td>
                      <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                        {el?.price} TND
                      </td>
                      <td
                        className={classNames(
                          "border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left",
                          !el?.billedAt
                            ? "cursor-pointer text-red-500"
                            : "text-green-500"
                        )}
                        onClick={() => {
                          if (!el?.billedAt) {
                            setPaymentModal(true);
                            setSelectedBill(el);
                          }
                        }}
                      >
                        {el?.billedAt ? "Payée" : "Payer"}
                      </td>
                    </tr>
                  );
                })
            )}
          </tbody>
        </table>
        <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
          <div className="flex-1 flex justify-between sm:hidden">
            <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
              {" "}
              Previous{" "}
            </span>
            <span className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
              {" "}
              Next{" "}
            </span>
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Page N°<span className="font-medium">{page}</span>/
                <span className="font-medium">{totalPage}</span>
              </p>
            </div>
            <div>
              <nav
                className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                aria-label="Pagination"
              >
                <span
                  className="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  onClick={() => setPage(page - 1)}
                >
                  <span className="sr-only">Previous</span>
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                {Array.from({ length: totalPage }, (v, i) => i).map((el, i) => (
                  <span
                    aria-current="page"
                    onClick={() => setPage(i + 1)}
                    className="cursor-pointer z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                  >
                    {i + 1}
                  </span>
                ))}

                <span
                  className="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  onClick={() => setPage(page + 1)}
                >
                  <span className="sr-only">Next</span>
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <Modal showModal={paymentModal} closeModal={() => setPaymentModal(false)}>
        <Payment
          closeModal={() => setPaymentModal(false)}
          selectedBill={selectedBill}
          user={user}
          setUser={setUser}
        />
      </Modal>
    </div>
  );
};

export default Facture;
