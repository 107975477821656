interface Props {
  classNames?: string;
  nhs?: boolean;
}
const index: React.FC<Props> = ({ classNames, nhs }) => {
  return (
    <div className={`${classNames} ${!nhs ? "h-screen" : ""} flexCenter `}>
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
    </div>
  );
};

export default index;
