import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, chakra } from "@chakra-ui/react";
import { AttachmentIcon } from "@chakra-ui/icons";
import { useField } from "../../../hooks/FormGeneratorHooks";
import { FormGroup } from "./FormGroup";
import { FieldInput } from "./FieldInput";

export const FieldUploadBase64 = (props) => {
  const { t } = useTranslation();

  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    setValue,
    value,
    otherProps,
  } = useField(props);
  const { required, name } = props;
  const { children, label, type, placeholder, helper, ...rest } = otherProps;
  const showError = !isValid && isSubmitted;
  const [showInput, setShowIn] = useState("");

  const formGroupProps = {
    errorMessage,
    helper,
    id,
    isRequired: !!required,
    label,
    showError,
    name,
    ...rest,
  };
  // function encodeImageFileAsURL(element) {
  //   var file = element.files[0];
  //   var reader = new FileReader();
  //   //let str: any;

  //   reader.onloadend = function () {
  //     setValue(reader.result);
  //   };
  //   reader.readAsDataURL(file);
  // }

  const handleChange = ({ target }) => {
    const file = target.files[0];
    //const base64 = encodeImageFileAsURL(target);
    setShowIn(file.name);

    if (!file) {
      setValue(null);
      return;
    }
  };

  return (
    <FormGroup {...formGroupProps}>
      <Box border={2}>
        <FieldInput
          value={value.includes("data:image/") ? "" : value}
          name={name}
          placeholder="www.example-Image.com"
        />
        <span className="card-subtitle p-4 m-4">{t("Survey.orUploadPic")}</span>
        <chakra.label
          position="relative"
          bg="gray.50"
          alignItems="center"
          px="4"
          py="2"
          borderRadius="md"
          border="1px solid"
          borderColor="gray.200"
          color="gray.600"
          transition="0.2s"
          _hover={{
            bg: "brand.50",
            borderColor: "brand.600",
          }}
          _focusWithin={{
            bg: "brand.50",
            borderColor: "brand.600",
          }}
        >
          <chakra.input
            opacity={0}
            position="absolute"
            accept="image/jpeg, image/png, image/gif"
            top={0}
            left={0}
            width={0}
            type="file"
            id={id}
            onChange={handleChange}
            disabled={props.readOnly ? props.readOnly : false}
          />
          <AttachmentIcon mr="0" /> {showInput || t("Survey.selectImage")}
        </chakra.label>
      </Box>
    </FormGroup>
  );
};
