import { useTranslation } from "react-i18next";
import Button from "../../../components/ButtonUI";
interface Props {
  closeModal: any;
  openPrescriptionModal: any;
  idConsultation: string;
  statusTask: any;
}

const EndConsultationModal = (props: Props) => {
  const { t } = useTranslation() as any;
  const { closeModal, idConsultation, openPrescriptionModal, statusTask } =
    props;
  return (
    <div className="modal-end-consultation flexCenter flex-col space-y-5">
      <h2> {t("Teleconsultation.LeftConsultation")}</h2>
      <div className="flex space-x-10">
        {!statusTask?.tasks?.find(
          (el) => el.type === "prescription" && el.status === "done"
        ) && (
          <Button
            status="secondary"
            width="146.6px"
            height="50px"
            onClick={() => {
              closeModal();
              openPrescriptionModal();
            }}
          >
            {t("Teleconsultation.Prescription")}
          </Button>
        )}

        <Button
          status="secondary"
          width="167px"
          height="50px"
          onClick={() => {
            window.location.href = `/consultation-report/${idConsultation}`;
            closeModal();
          }}
        >
          {t("Teleconsultation.Report")}
        </Button>
      </div>

      <span
        className="menu"
        onClick={() => {
          window.location.href = `/consultation-report/${idConsultation}`;
          closeModal();
        }}
      >
        {" "}
        {t("Teleconsultation.PutToDo")}
      </span>
    </div>
  );
};

export default EndConsultationModal;
