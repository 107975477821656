import React, { useState, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { Heading } from "@chakra-ui/react";
import { ArrowRightIcon } from "@chakra-ui/icons";
import Spinner from "../../components/Spinner";
import FormRenderer from "../../components/SurveyGenerator/FormRenderer";
import {
  MultiStepsLayout,
  PageHeader,
} from "../../components/SurveyGenerator/FormBuilder/Lyaout";
import StepperWrapper from "../../components/SurveyGenerator/FormBuilder/StepperWrapper";
import {
  Formiz,
  FormizStep,
} from "../../components/SurveyGenerator/FormBuilder/StepperWrapper/Formiz";
import { useForm } from "../../hooks/FormGeneratorHooks";
import { resolver } from "../../helper/FormGeneratorHelper/conditionalLogic";
import frMsg from "../../config/constants/locales/fr-fr.json";
import enMsg from "../../config/constants/locales/fr-fr.json";
type Props = any;
const DisplayForm: React.FC<Props> = ({
  onChange,
  onSubmit,
  formConfig,
  initialField,
  ...props
}) => {
  const [lang] = useState("fr");
  const form = useForm();
  const [, setFormData] = useState({
    sections: [{ task_data: [], stepNbr: 0, sectionName: "Page 1" }],
    name: "",
    description: "",
  }) as any;

  useEffect(() => {
    formConfig
      ? setFormData(formConfig)
      : setFormData({
          sections: [{ task_data: [], stepNbr: 0, sectionName: "Page 1" }],
          name: "",
          description: "",
        });
  }, [formConfig]);
  const handleSubmit = (values) => {
    onSubmit(values);
  };

  return !formConfig ? (
    <Spinner />
  ) : (
    <IntlProvider
      locale={"fr"}
      defaultLocale="en"
      messages={lang === "fr" ? frMsg : enMsg}
    >
      <Formiz connect={form} onValidSubmit={handleSubmit}>
        <MultiStepsLayout submitLabel="Terminer">
          <PageHeader>
            {formConfig.name ? formConfig.name : "#Titre de questionnaire"}
            <Heading fontSize="lg" mb={4} mt={4}>
              <ArrowRightIcon
                height={"12px"}
                width={"12px"}
                mr={2}
                mb={1}
                color={"#4565f6"}
              />
              {formConfig.description
                ? formConfig.description
                : "Description du questionnaire..."}
            </Heading>
          </PageHeader>
          <>
            {formConfig.type === "Simulation" ? (
              <div className="   p-4 w-full rounded-lg mb-4">
                <StepperWrapper title="Dots stepper" />

                {formConfig.sections[0].task_data.map((el, i) => {
                  return (
                    <FormizStep
                      label={el.label}
                      name={`${el.field_name}`}
                      isEnabled={
                        el.conditionLogic && el.conditionLogic.status
                          ? resolver(el.conditionLogic, form.values)
                          : true
                      }
                    >
                      <FormRenderer
                        key={i}
                        elements={[el]}
                        values={form.values}
                        form={form}
                      ></FormRenderer>
                    </FormizStep>
                  );
                })}
              </div>
            ) : (
              <div className="   p-4 w-full rounded-lg mb-4">
                <StepperWrapper title="Dots stepper" />

                {formConfig.sections.map((step, i) => {
                  return (
                    <FormizStep
                      label={step.sectionName}
                      name={`${step.sectionName + "-" + step.stepNbr}`}
                      isEnabled={
                        step.conditionLogic && step.conditionLogic.status
                          ? resolver(step.conditionLogic, form.values)
                          : true
                      }
                    >
                      <FormRenderer
                        key={i}
                        elements={step.task_data}
                        values={form.values}
                        form={form}
                      ></FormRenderer>
                    </FormizStep>
                  );
                })}
              </div>
            )}
          </>
        </MultiStepsLayout>
      </Formiz>
    </IntlProvider>
  );
};
export default DisplayForm;
