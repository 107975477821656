import React, { useEffect, useState } from "react";
import moment from "../../../helper/moment";
import _ from "lodash";
import { useAtom } from "jotai";
import { Formik } from "formik";
import TimePicker from "react-time-picker";
import DatePicker from "react-datepicker";
import Button from "../../ButtonUI";
import SelectComp from "../../Select";

import { userAtom } from "../../../state/auth";
import Input from "../../Input";
import makeToast from "../../Snackbar";
import { getAllMyPatientsByDoctorId2 } from "../../../api/doctor";
import { addActe } from "../../../api/consultation";
import { useTranslation } from "react-i18next";
// import fr from "date-fns/locale/fr";
// registerLocale("fr", fr);
// setDefaultLocale("fr");

interface IProps {
  setShowAgendaModal: any;
}

const Presentiel: React.FC<IProps> = ({ setShowAgendaModal }) => {
  const { t } = useTranslation() as any;

  const [actDate, setActDate] = useState() as any;
  const [user] = useAtom(userAtom) as any;
  const [myPatient, setMyPatient] = useState([]) as any;

  const [startTime, setStartTime] = useState("08:00") as any;
  const [endTime, setEndTime] = useState("18:00") as any;

  useEffect(() => {
    async function getpatientsMine() {
      const myPatients = await getAllMyPatientsByDoctorId2(user._id);
      if (myPatients) {
        setMyPatient(myPatients.myPatients.patients);
      }
    }
    getpatientsMine();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values) => {
    try {
      const {
        cabinetName,
        selectedPatient,
        motif,
        commentaire,
        startActeTime,
        endActeTime,
      } = values;

      if (!selectedPatient)
        return makeToast("warning", t("Agenda.ErrorActPatient"));

      if (!motif) return makeToast("warning", t("Agenda.ErrorActReason"));

      if (!actDate) return makeToast("warning", t("Agenda.ErrorActDate"));
      if (!startActeTime || !endActeTime)
        return makeToast("warning", t("Agenda.ErrorActDuration"));

      const dateStart = new Date(
        moment(actDate).year(),
        actDate?.getMonth(),
        parseInt(moment(actDate).format("DD")),
        startTime.slice(0, 2),
        startTime.slice(3)
      );

      const dateEnd = new Date(
        moment(actDate).year(),
        actDate?.getMonth(),
        parseInt(moment(actDate).format("DD")),
        endTime.slice(0, 2),
        endTime.slice(3)
      );

      const newConsultation = {
        patientId: selectedPatient,
        dateConsultation: actDate,
        cabinetName,
        timeStart: dateStart,
        timeEnd: dateEnd,
        commentaire,
        checkBeforAdd: true,
      };
      const response = await addActe(newConsultation);
      if (response) makeToast("success", t("Agenda.SuccessAddAct"));
      else makeToast("warning", t("Agenda.CheckConsultationDate"));
      setShowAgendaModal("");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Formik
      initialValues={{
        cabinetName: !_.isEmpty(user?.workingLocation)
          ? user?.workingLocation[0]?.name
          : "",
        selectedPatient: "",
        motif: "",
        //slotDateTime: "",
        //forcerRdv: true,
        startActeTime: "00:00",
        endActeTime: "00:00",
        commentaire: "",
      }}
      onSubmit={onSubmit}
      //validationSchema={validationSchema}
    >
      {(props) => {
        const {
          values,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <>
            <form onSubmit={handleSubmit} className="event-act flexColCenter">
              {!_.isEmpty(user?.workingLocation) &&
              user?.workingLocation.length === 1 ? (
                <Input
                  id="location"
                  type="text"
                  value={user?.workingLocation[0].name}
                  // onChange={handleChange}
                  onBlur={handleBlur}
                  className="custom-Input mb-8 pl-10"
                  inlineLabel={false}
                  onfocus={true}
                  image="location"
                  right={false}
                  disabled
                />
              ) : (
                <SelectComp
                  options={user?.workingLocation?.map((el) => ({
                    label: el.name,
                    value: el.name,
                  }))}
                  text={t("Agenda.Cabinet")}
                  setText={false}
                  name="cabinetName"
                  onChange={handleChange}
                  value={values.cabinetName}
                  // error={errors.cabinetName}
                  className="menuInput"
                  image="location"
                />
              )}
              <SelectComp
                options={
                  myPatient.length > 0
                    ? myPatient?.map((p: any) => {
                        return {
                          label: `${p.firstName} ${p.lastName}`,
                          value: `${p._id}`,
                        };
                      })
                    : []
                }
                text={t("Agenda.SelectPatient")}
                name="selectedPatient"
                onChange={handleChange}
                value={values.selectedPatient}
                error={errors.selectedPatient}
                className="menuInput"
                image="patients"
              />

              <Input
                id="motif"
                placeholder={t("Agenda.MotifAct")}
                type="text"
                error={errors.motif}
                value={values.motif}
                onChange={handleChange}
                onBlur={handleBlur}
                className="custom-Input mb-8"
                inlineLabel={false}
                onfocus={true}
                // image="menuInput"
                right={false}
              />
              <DatePicker
                id="acteDate"
                locale="fr"
                className="custom-Input mb-8 border border-gray-200 px-3 "
                selected={actDate}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => {
                  setActDate(date);
                }}
                placeholderText={t("Agenda.DateAct")}
                autoComplete="off"
                showYearDropdown
                yearDropdownItemNumber={80}
                scrollableYearDropdown
                // dropdownMode="select"
              />

              <div className="plageHoraire flexCenter gap-x-2">
                <p>{t("Agenda.ScheduleAct")} </p>
                <div className="flexCenter sub-values-act ">
                  <TimePicker
                    id="startActeTime"
                    onChange={(time) => setStartTime(time)}
                    format="HH:mm"
                    value={values.startActeTime}
                    onClockClose={() => {
                      //setSelectedInput("");
                    }}
                    onClockOpen={() => {}}
                    className="flexCenter cursor-pointer"
                    style={{ display: "flex", alignItems: "center" }}
                  />
                </div>
                <p>{t("Agenda.to")}</p>
                <div className="flexCenter sub-values-act">
                  <TimePicker
                    id="endActeTime"
                    onChange={(time) => setEndTime(time)}
                    format="HH:mm"
                    value={values.endActeTime}
                    className="cursor-pointer"
                    // onClockClose={() => {
                    //   setSelectedInput("");
                    // }}
                    onClockOpen={() => {}}
                  />
                </div>
              </div>
              <Input
                id="commentaire"
                placeholder={t("Agenda.AddComment")}
                type="text"
                error={errors.commentaire}
                value={values.commentaire}
                onChange={handleChange}
                onBlur={handleBlur}
                className="custom-Input mb-8"
                inlineLabel={false}
                onfocus={true}
                // image="joinFolder"
                // right={true}
              />
              <div className="flexRowCenter">
                <Button
                  type="submit"
                  disabled={isSubmitting || Object.keys(errors).length > 0}
                  status="primary"
                  className="btn"
                  width="170px"
                  height="50px"
                  onClick={() => handleSubmit()}
                >
                  {t("Agenda.Save")}
                </Button>
              </div>
            </form>
          </>
        );
      }}
    </Formik>
  );
};

export default Presentiel;
