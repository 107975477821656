import React, { useEffect, useState } from "react";
import { InputGroup, RadioGroup } from "@chakra-ui/react";
import { Radio, Stack } from "@chakra-ui/react";
import { useField } from "../../../../hooks/FormGeneratorHooks";
import { FormGroup } from "../FormGroup";

const FieldRadio: React.FunctionComponent<any> = (props) => {
  let { name, error, options, direction, inputName, required, ...rest } = props;
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    otherProps,
  } = useField(props);

  const [isTouched, setIsTouched] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted);
  const { children, label, type, placeholder, helper, ...resta } = otherProps;
  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const formGroupProps = {
    errorMessage,
    helper: isTouched ? helper : "",
    id,
    isRequired: required,
    label,
    showError,
    name,

    ...resta,
  };
  const handleChange = (value: string) => {
    if (options) {
      let completeValue = options.find((f: any) => f.value === value);
      setValue(completeValue.value);
    }
  };
  return (
    <FormGroup className="mt-3" {...formGroupProps}>
      <InputGroup>
        <RadioGroup
          {...rest}
          value={value && (value.value || value)}
          onChange={handleChange}
          className={isValid ? " px-2 py-1  " : "px-2 py-1 "}
        >
          <Stack direction={direction || "row"}>
            {options &&
              options.map((item: any, index: number) => (
                <React.Fragment key={`${index}`}>
                  <Radio
                    _checked={{ bg: "blue" }}
                    key={index}
                    value={item.value}
                    isDisabled={props.disabled ? true : false}
                  >
                    <p style={{ fontSize: "14px" }}>{item.label}</p>
                  </Radio>
                  <span style={{ marginRight: "2rem" }} />
                </React.Fragment>
              ))}
          </Stack>
        </RadioGroup>
      </InputGroup>

      {children}
    </FormGroup>
  );
};

export default FieldRadio;
