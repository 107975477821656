import { FieldArray, Formik } from "formik";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import Button from "../../../components/ButtonUI";
import Input from "../../../components/FloatInput";
import "./styles.scss";
// import cursusSchema from "./schemas/cursusSchema";
import { IStudies } from "../../../types/user";
import { addCursus } from "../../../api/doctor";
import { useAtom } from "jotai";
import { userAtom } from "../../../state/auth";
import makeToast from "../../../components/Snackbar";
import Icon from "../../../components/Icon";
import Spinner from "../../../components/Spinner";
import { BsTrash } from "react-icons/bs";

interface Props {
  openModal: boolean;
  setOpenModal: Function;
  doctorId: string;
}

const emptyCursus = { diplome: "", university: "", year: "" };

const AddCursus: React.FC<Props> = ({ openModal, setOpenModal, doctorId }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useAtom(userAtom);
  const [newUserCursus, setNewUserCursus] = useState([]);
  useEffect(() => {
    if (user) {
      const cursusDoctor = user?.studiesHistory;
      const newArray = cursusDoctor.map((cd) => {
        return {
          diplome: cd.diplome,
          university: cd.university,
          year: cd.year,
        };
      });
      setNewUserCursus(newArray as any);
    }
    setLoading(false);
  }, [user]);
  const _addCursus = async (values) => {
    try {
      for (let crs of values.cursus) {
        if (!crs.diplome || !crs.university || !crs.year)
          return makeToast("warning", t("Profile.fillAll"));
      }

      setOpenModal(!openModal);
      const newUser = (await addCursus({ ...values, doctorId })) as any;
      setUser({ ...user, ...newUser });
      if (newUser?._id) {
        makeToast("success", t("Profile.successAddCursus"));
      } else {
        makeToast("error", t("Profile.failAddCursus"));
      }
    } catch (err) {}
  };
  return loading ? (
    <Spinner />
  ) : (
    <div className="cursusAdd cursusWidth">
      <div className="flex justify-end cursor-pointer mt-5 mr-5 ">
        <div className="bg-gray-100  rounded-lg hover:bg-gray-200">
          <Icon
            isIcon={true}
            name="close"
            onClick={() => setOpenModal(!openModal)}
          />
        </div>
      </div>
      <Formik
        initialValues={{
          cursus: newUserCursus.length !== 0 ? newUserCursus : [emptyCursus],
        }}
        onSubmit={async (values) => {
          try {
            // for (let crs of values.cursus) {
            //   if (!crs.diplome || !crs.university || !crs.year)
            //     return makeToast(
            //       "warning",
            //       "Tous les champs sont obligatoires"
            //     );
            // }
            // setOpenModal(!openModal);
            // const newUser = (await addCursus({ ...values, doctorId })) as any;
            // setUser({ ...user, ...newUser });
            // if (newUser?._id) {
            //   makeToast("success", "Cursus universitaire ajouté avec succès.");
            // } else {
            //   makeToast("error", "Ajout cursus universitaire a échouhé.");
            // }
          } catch (err) {}
        }}
        // validationSchema={cursusSchema}
      >
        {(props) => {
          const {
            values,
            // errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            // setFieldValue,
          } = props;

          return (
            <form onSubmit={handleSubmit} className="pb-5 px-5">
              <h1 className="mb-10">{t("Profile.universityCurriculum")}</h1>

              <FieldArray name="cursus">
                {({ push, remove }) => (
                  <div className="w-full">
                    {values.cursus.map((diploma: IStudies, index) => (
                      <div className="flex form-cursus flex-col xl:flex-row mt-5 w-full  justify-around">
                        <Input
                          id={`cursus.${index}.diplome`}
                          placeholder={t("Profile.DegreeName")}
                          labelText={t("Profile.DegreeName")}
                          type="text"
                          // error={values.cursus[index].diplome}
                          value={values.cursus[index].diplome}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inlineLabel={false}
                          onfocus={true}
                        />

                        <Input
                          id={`cursus.${index}.university`}
                          placeholder={t("Profile.UniversityName")}
                          labelText={t("Profile.UniversityName")}
                          type="text"
                          // error={values.cursus[index].university}
                          value={values.cursus[index].university}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inlineLabel={false}
                          onfocus={true}
                        />
                        <Input
                          id={`cursus.${index}.year`}
                          placeholder={t("Profile.AcademicYear")}
                          labelText={t("Profile.AcademicYear")}
                          type="text"
                          // error={values.cursus[index].year}
                          value={values.cursus[index].year}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inlineLabel={false}
                          onfocus={true}
                        />
                        <div className="buttons flexCenter gap-2">
                          {values.cursus.length > 1 && (
                            <BsTrash
                              onClick={() => {
                                if (!isSubmitting) {
                                  remove(index);
                                }
                              }}
                              size={28}
                              className="cursor-pointer"
                            />
                          )}
                          <Button
                            disabled={isSubmitting}
                            onClick={() => push(emptyCursus)}
                            type="button"
                            width={"38px"}
                            height={"38px"}
                          >
                            +
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>

              <Button
                status="secondary"
                width="100px"
                height="50px"
                className="float-left my-4 btn-delete "
                onClick={() => setOpenModal(false)}
              >
                {t("Profile.Cancel")}
              </Button>
              <Button
                status="secondary"
                width="100px"
                height="50px"
                className="float-right my-4"
                onClick={() => _addCursus(values)}
              >
                {t("Profile.Save")}
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddCursus;
