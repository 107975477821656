export const Extension = {
  fr: {
    Extension: "Extensions",
    EmptyExtension: "Liste des extensions vide",
    Confirm: "Confirmer",
    ConfirmDisable:
      "La désactivation de ce module entraînera sa disparition de la liste des modules. Êtes-vous sûr de vouloir le désactiver ?",
    ConfirmEnable:
      "Pour activer ce module, des frais supplémentaires seront facturés. Souhaitez-vous l'activer ?",

    Show: "Montrer",
    to: "à",
    results: "des résultats.",
  },
  en: {
    Extension: "Extensions",
    EmptyExtension: "List of extensions empty",
    Confirm: "Confirm",
    ConfirmDisable:
      "Deactivating this module will cause it to disappear from the module list. Are you sure you want to deactivate it?",
    ConfirmEnable:
      "To activate this module, additional fees will be charged. Would you like to activate it?",
    Show: "Show",
    to: "to",
    results: "the results.",
  },
};
