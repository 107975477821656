import { useTranslation } from "react-i18next";
import moment from "../../helper/moment";
import { loadImage } from "../../api/documents";
import Button from "../ButtonUI";
import Icon from "../Icon";
import { ReactComponent as AgendaIcon } from "../../assets/icons/icon-agenda.svg";
import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";
import makeToast from "../Snackbar";
import { handleSpeciality } from "../../helper/handleSpeciality";

const PatientConfirmMeeting = ({ closeModal, addedConsultation }) => {
  const { t, i18n } = useTranslation() as any;
  return (
    <>
      <div className="p-5">
        <div className="flex mb-5">
          <h2 className="m-auto">{t("MyDoctors.YourMeeting")}</h2>
          <div className="float-right close-modal">
            <Icon isIcon={true} name="close" onClick={closeModal} />
          </div>
        </div>

        <div className="flex items-center space-x-4 bg-gray-100 p-4 mb-2">
          <div className="h-16 w-16 rounded-lg">
            {addedConsultation?.doctorId?.business_photo ? (
              <img
                crossOrigin="anonymous"
                referrerPolicy="origin"
                alt="profile"
                src={
                  addedConsultation?.doctorId?.business_photo.slice(0, 4) ===
                  "http"
                    ? addedConsultation?.doctorId?.business_photo
                    : loadImage(
                        addedConsultation?.doctorId?._id,
                        "profile",
                        "thumbnail",
                        addedConsultation?.doctorId?.business_photo
                      )
                }
                className="w-16 h-16 rounded-full shadow-md object-fill"
              />
            ) : addedConsultation?.doctorId?.sexe === "woman" ? (
              <WomanPatient className="w-16 h-16 bg-gray-300 " />
            ) : (
              <ManPatient className="w-16 h-16 bg-gray-300 " />
            )}
          </div>
          <div className="flex flex-col">
            <span className="card-title">{`${addedConsultation?.doctorId?.firstName} ${addedConsultation?.doctorId?.lastName}`}</span>
            <span className="card-subtitle">
              {handleSpeciality(
                addedConsultation?.doctorId?.speciality,
                i18n.language.includes("en")
              )}
            </span>
          </div>
        </div>
        <div className="flex items-center ">
          <Icon isIcon={true} name="Motif de la consultation" />
          <p>
            <span className="card-subtitle"> {t("MyDoctors.Motif")} </span>
            <span className="ml-2">{addedConsultation?.motif}</span>
          </p>
        </div>
        <div className="flex items-center flex-wrap">
          <AgendaIcon className="agenda-icon" />
          <span className="card-subtitle">
            {t("MyDoctors.DateAppointement")}
          </span>
          <span className="ml-2">
            {t("MyDoctors.The")}
            {"  "}
            {moment(addedConsultation?.dateConsultation).format(t("Moment.Z"))}
          </span>
        </div>
        {addedConsultation?.doctorId?.address && (
          <div className="flex items-center flex-wrap">
            <Icon isIcon={true} name="event-cabinet" />
            <span className="card-subtitle">
              {t("MyDoctors.AppointementPlace")}
            </span>
            <span className="max-w-xs ml-2">
              {addedConsultation?.doctorId?.address}
            </span>
          </div>
        )}

        <div className="flexCenter my-5 ">
          <Button
            status="primary"
            width="283px"
            height="50px"
            onClick={() => {
              makeToast("success", "Rendez-vous pris avec succès");
              closeModal();
            }}
          >
            {t("MyDoctors.Finish")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default PatientConfirmMeeting;
