/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import moment from "../../helper/moment";
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import _ from "lodash";
import Button from "../ButtonUI";
import Icon from "../Icon";
import pen from "../../assets/images/pen.png";
import { useHistory } from "react-router";
import Modal from "../ModalUI";
import PrescriptionModal from "../PrescriptionModal";
import { useAtom } from "jotai";
import calendrier from "../../assets/images/calendrier.png";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { LeftArrow, RightArrow } from "../Arrows";
import { previousState } from "../../state/previousPageState";
interface Props {
  patientData: any;
  prescriptions: any;
  setPresecriptionList: Function;
  openTab: any;
}
type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

const Taches: React.FC<Props> = ({
  patientData,
  prescriptions,
  setPresecriptionList,
  openTab,
}) => {
  const { t } = useTranslation() as any;
  const history = useHistory();
  const { tasks } = patientData;
  const [, setPreviousStates] = useAtom(previousState) as any;
  const [modalPrescription, setModalPrescription] = useState(false);
  const [selectedPresCriptionTasks, setSelectedPresCriptionTasks] =
    useState<any>();
  const [tasksList, setTasksList] = useState<any>(patientData.tasks);
  const [isSuccess, setIsSuccess] = useState(false);
  // const [upComingRDVList, setUpComingRDV] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      setIsSuccess(false);
      if (tasksList && selectedPresCriptionTasks) {
        const newTaskList = tasksList.filter(
          (task) => task?.consultation?._id !== selectedPresCriptionTasks?._id
        );
        setTasksList(newTaskList);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    tasks && setTasksList(tasks);
  }, [patientData]);

  // useEffect(() => {
  //   async function getUpcomingRdv() {
  //     const response = await getUpComingRdvPatientAndDoctor(
  //       patientData._id,
  //       user._id
  //     );
  //     setUpComingRDV(response.consultation);
  //   }
  //   getUpcomingRdv();
  // }, []);

  const TasksCard = ({ itemId, tache }) => (
    <div
      className="tache my-1 mr-5 border"
      key={tache._id}
      style={{
        background: "#F5F5F9",
      }}
    >
      <div className="flex justify-between items-center header">
        <div className="flex sub-header gap-2">
          {tache.type === "prescription" ? (
            <Icon
              name={"capsule"}
              isIcon={tache.isIcone}
              height={20}
              width={20}
            />
          ) : tache.type === "report" ? (
            <img
              crossOrigin="anonymous"
              referrerPolicy="origin"
              src={pen}
              alt="pen"
              width="20px"
              height="20px"
            />
          ) : (
            <img
              crossOrigin="anonymous"
              referrerPolicy="origin"
              src={calendrier}
              alt="rdv"
              width="20px"
              height="20px"
            />
          )}
          <p>
            {tache.type === "report"
              ? t("MyPatient.Report")
              : tache.type === "prescription"
              ? t("MyPatient.Prescription")
              : t("MyPatient.Report")}
          </p>
        </div>
        <div>{/* <Icon name="troisPoints" isIcon={true} /> */}</div>
      </div>
      <p className="subTitle">
        {t("MyPatient.UpdateAt")}{" "}
        {moment(tache.updatedAt).format(t("Moment.Z"))}
      </p>
      <div></div>
      <div className="tache-box max-w-max">{tache.motif}</div>
      <Button
        status="primary"
        className="btn mb-4"
        width={"201px"}
        height={"50px"}
        onClick={() => {
          if (tache.type === "report") {
            setPreviousStates({
              ...previousState,
              filePatientTasks: {
                patientId: patientData._id,
                openTab,
              },
            });
            history.push(`/consultation-report/${tache?.consultation?._id}`);
          } else if (tache.type === "prescription") {
            setModalPrescription(true);
            setSelectedPresCriptionTasks(tache?.consultation);
          } else {
            history.push({
              pathname: `/agenda`,
              state: {
                doctorId: tache.doctor,
                patientId: tache.patient,
                consultationId: tache._id,
              },
            });
          }
        }}
      >
        {tache.type === "report"
          ? t("MyPatient.Write")
          : tache.type === "prescription"
          ? t("MyPatient.Prescribe")
          : t("MyPatient.Consult")}
      </Button>
    </div>
  );

  return tasksList &&
    !_.isEmpty(tasksList.filter((e) => e.status !== "done")) ? (
    <>
      <div className="flex justify-between title-taches">
        <div className="sub-title">
          <p>
            {t("MyPatient.YourTasks")} (
            {tasksList && tasksList.filter((e) => e.status !== "done").length})
          </p>
        </div>
        {/* <Button
            status="secondary"
            className="btnAddtache"
            width={"146px"}
            height={"35px"}
          >
            + Ajouter
          </Button> */}
      </div>
      <div className={classNames("taches mb-2 w-full")}>
        <ScrollMenu
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          onWheel={onWheel}
        >
          {!_.isEmpty(tasksList) ? (
            [...tasksList]
              .sort(
                (a, b) =>
                  new Date(b.updatedAt).valueOf() -
                  new Date(a.updatedAt).valueOf()
              )
              .filter((e) => e.status !== "done")
              .map((tache) => <TasksCard tache={tache} itemId={tache?._id} />)
          ) : (
            //
            <div className="flexCenter w-full">
              <p className="card-subtitle">{t("MyPatient.NoTask")}</p>
            </div>
          )}
        </ScrollMenu>
      </div>
      {modalPrescription && (
        <Modal showModal={modalPrescription}>
          <PrescriptionModal
            consultationDetails={selectedPresCriptionTasks}
            closeModal={() => setModalPrescription(false)}
            setIsSuccess={setIsSuccess}
            prescriptions={prescriptions}
            setPresecriptionList={setPresecriptionList}
            myPatient={true}
          />
        </Modal>
      )}
    </>
  ) : (
    <></>
  );
};

export default Taches;
