/* eslint-disable no-useless-computed-key */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import _ from "lodash";
import { FieldArray, Formik } from "formik";
import { editConsultationById } from "../../api/consultation";
import { useHistory } from "react-router-dom";
import { getWaitingTasksOfPatient } from "../../api/task";
import { getAllPdfOfDoctors } from "../../api/pdfTemalpte";
import { getMyPatientWithId } from "../../api/doctor";
import { DMP } from "../../api/devbox/";
// import { updatePrescription } from "../../api/consultation";
// import { getTemplateById } from "../../api/pdfTemalpte";
// import { generatePDFBase64 } from "../TemplatePDFCreator/Helper/generatePDFbase64";
// import DEFAULT_TEMPALTE from "../PreviewRapport/defaultTemplate.json";
// import Checkbox from "../Checkbox";
// import { ContentState, EditorState } from "draft-js";
// import htmlToDraft from "html-to-draftjs";
import FloatInput from "../FloatInput";
import Icon from "../Icon";
import Button from "../ButtonUI";

import "./styles.scss";
// import PatientInfo from "../SharedPatientDetails";
import * as Yup from "yup";
import { useAtom } from "jotai";
import { userAtom } from "../../state/auth";
import makeToast from "../Snackbar";
import SelectComp from "../Select";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  closeModal?: any;
  consultationDetails?: any;
  setIsSuccess?: Function;
  prescriptions?: any;
  setPresecriptionList?: Function;
  myPatient?: Boolean;
  redirection?: boolean;
  setPatientInfoData?: any;
  setModal?: any;
};

const emptyPrescription: any = { medicament: "", dosage: "" };

const PrescriptionModal: React.FC<Props> = ({
  closeModal,
  consultationDetails,
  setIsSuccess,
  prescriptions,
  setPresecriptionList,
  myPatient,
  redirection,
  setPatientInfoData,
  setModal,
}) => {
  const { t } = useTranslation() as any;
  const history = useHistory();
  const [user] = useAtom(userAtom);

  // const [, setDMPLoading] = useState(false);
  const [patientData, setPatientData] = useState<any>();
  const [tempalteList, setTemplateList] = useState([]) as any;
  const [btnLoading, setbtnLoading] = useState(false);
  const [templatePdfId, setTemplatePdfId] = useState<string>();
  const [author] = useState() as any;
  const [checkCPSCard] = useState(false);
  // const [sendToDMP, setSendToDMP] = useState(false);
  const [qualified, setQualified] = useState(false);

  const [setPatientDMPData] = useState() as any;
  // const [defaultTemplate] = useState({ ...DEFAULT_TEMPALTE }) as any;

  const statusLabels = {
    ["IdProvisional"]: "Identité provisoire",
    ["IdRecovered"]: "Identité récuperée",
    ["IdValidated"]: "Identité validée",
    ["IdQualified"]: "Identité qualifiée",
  };

  // const GetAuthorData = async () => {
  //   try {
  //     setDMPLoading(true);
  //     const responseReaderInfo = await CPS.readerInfo(
  //       false,
  //       i18n.language.includes("en")
  //     );
  //     setcheckCPSCard(responseReaderInfo);
  //     const doctorData = await CPS.readCard();

  //     setAuthor({
  //       internalId: doctorData?.infoPs?.idNatPs,
  //       nom: doctorData?.namePs?.nomPatronymique,
  //       prenom: doctorData?.namePs?.prenomUsuel,
  //       secteurActivite:
  //         doctorData?.activitiesPs[`${doctorData?.selectedActivity}`]
  //           ?.structSecActRpps,
  //       structureSante: {
  //         idNational:
  //           doctorData?.activitiesPs[`${doctorData?.selectedActivity}`]
  //             ?.structIdNat,
  //         nom: doctorData?.activitiesPs[`${doctorData?.selectedActivity}`]
  //           ?.structRaisSoc,
  //       },
  //       specialite: "G15_10/SM26",
  //     });
  //     setDMPLoading(false);
  //   } catch (error) {
  //     setDMPLoading(false);
  //     console.log(error);
  //   }
  // };

  const fetchPatientData = async () => {
    const request = {
      context: {
        author,
        insNirAutorite: "TEST",
        modeAcces: {
          acces: "NORMAL",
        },
      },
      request: {
        ins:
          patientData?.INS?.responseINS?.insActif?.valeur +
          patientData?.INS?.responseINS?.insActif?.cle,
      },
    };

    const data = await DMP.td02Exist(request);

    setPatientDMPData(data);
  };

  // const AddNewDMPFile = async (content, format) => {
  //   try {
  //     if (
  //       !checkCPSCard ||
  //       _.isEmpty(author) ||
  //       _.isEmpty(patientDMPData?.patient)
  //     )
  //       return;
  //     setDMPLoading(true);
  //     const patient = {
  //       ins:
  //         patientData?.INS?.responseINS?.insActif?.valeur +
  //         patientData?.INS?.responseINS?.insActif?.cle,
  //       internalId: patientData?._id,
  //       prenom:
  //         patientData?.INS?.responseINS.traitsIdentite?.prenomActeNaissance,
  //       insAutorite: patientDMPData?.patient?.insAutorite,
  //       nomUsuel: patientData?.INS?.responseINS?.traitsIdentite?.nomFamille,
  //       dateDeNaissance:
  //         patientData?.INS?.responseINS?.traitsIdentite?.dateNaissance
  //           .split("-")
  //           .join(""),
  //       ordreDeNaissance: 0,
  //       sexe: patientData?.INS?.responseINS?.traitsIdentite?.sexeAdministratif,
  //     };
  //     const contentCDA = await DMP.dmp2cda(author, content, patient, format);
  //     const requestAddNewDMPFile = {
  //       context: {
  //         author,
  //         insNirAutorite: "TEST",
  //         modeAcces: {
  //           acces: "NORMAL",
  //         },
  //       },
  //       request: {
  //         submission: {
  //           title: "soumission",
  //           contentType: "04",
  //           patient,
  //           documents: [
  //             {
  //               auteurs: [author],
  //               dmpcType: "wrapped",
  //               classCode: "11",
  //               comments: "Document Wrapped soumis via katomi",
  //               confidentialities: ["N"],
  //               isCdaR2N3: true,
  //               format,
  //               content: contentCDA,
  //             },
  //           ],
  //         },
  //       },
  //     };
  //     const response = await DMP.td21SubmitDocuments(requestAddNewDMPFile);
  //     setDMPLoading(false);
  //     makeToast(
  //       "success",
  //       `L'ajout de dossier patient a été effectué avec succès.`
  //     );
  //     return response;
  //   } catch (error) {
  //     makeToast("error", `L'ajout de dossier patient a echoué.`);
  //     setDMPLoading(false);
  //   }
  // };

  useEffect(() => {
    async function fetchMyAPI() {
      let response = await getMyPatientWithId(
        consultationDetails?.patientId._id
      );

      let response2: any = await getWaitingTasksOfPatient(
        response.patient._id,
        user?._id
      );

      const { patient, tasks } = response2;
      setPatientData({ ...patient, tasks });
    }

    if (consultationDetails) fetchMyAPI();
  }, [consultationDetails, user?._id]);

  useEffect(() => {
    async function getTempalte() {
      const response = await getAllPdfOfDoctors(user?._id);
      setTemplateList(response.data);
    }
    if (user?.extensions?.find((el) => el.name === "Mes documents")?.active)
      getTempalte();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   GetAuthorData();
  // }, []);

  useEffect(() => {
    if (patientData && checkCPSCard && qualified) {
      fetchPatientData();
    }
  }, [patientData, qualified]);

  useEffect(() => {
    if (
      !_.isEmpty(patientData?.INS?.responseINS?.insActif) &&
      statusLabels[patientData?.INS?.INSStaus] === "Identité qualifiée"
    )
      setQualified(true);
  }, [patientData]);

  return (
    <>
      {/* {modal && patientData && (
        <PatientInfo
          setOpenModal={setModal}
          patientData={patientData}
          selectedPatient={patientData._id}
          setModal={setModal}
          setPatientData={setPatientData}
        />
      )} */}
      <div className="prescription">
        <div className="flex justify-between">
          <h2>{t("AddPrescription.Title")}</h2>
          <div className="flex space-x-3 f">
            {!myPatient && (
              <Icon
                isIcon={true}
                name="fiche-patient"
                onClick={() => {
                  setPatientInfoData({
                    patientData: patientData,
                    selectedPatient: patientData?._id,
                    setPatientData: () => {},
                  });
                  setModal(true);
                  closeModal();
                }}
                className="icon cursor-pointer h-12"
              />
            )}

            <Icon
              isIcon={true}
              name="close"
              className="icon cursor-pointer h-12"
              onClick={closeModal}
            ></Icon>
          </div>
        </div>

        {/* Template */}
        {user?.extensions?.find((el) => el.name === "Mes documents")
          ?.active && (
          <>
            <span className="subtitle block uppercase mt-5">
              {t("AddPrescription.Template")}
            </span>
            <SelectComp
              value={templatePdfId}
              options={
                !_.isEmpty(tempalteList)
                  ? tempalteList.map((el) => ({
                      label: el.templateName,
                      value: el._id,
                    }))
                  : [
                      {
                        label: t("AddPrescription.defaultTemplate"),
                        value: "template par defaut",
                      },
                    ]
              }
              className="w-full"
              onChange={(e) => setTemplatePdfId(e.target.value)}
              defaultValue={"x"}
              text={t("AddPrescription.defaultTemplate")}
            />
          </>
        )}

        {/* consultation */}
        <span className="subtitle block uppercase mt-5">
          {t("AddPrescription.consultation")}
        </span>
        <FloatInput
          type="text"
          labelText={t("AddPrescription.patientName")}
          placeholder={t("AddPrescription.patientName")}
          value={
            consultationDetails?.patientId?.firstName +
            " " +
            consultationDetails?.patientId?.lastName
          }
          className="w-full capitalize bg-white"
          disabled
        />

        <span className="block courant-text my-4">
          {t("AddPrescription.consultationDate")}
          <span className="date-time-box">
            {consultationDetails?.dateConsultation?.substring(0, 10)}
            {/* {moment(Date.parse(consultationDetails?.dateConsultation))
                    .calendar()} */}
          </span>
          {t("AddPrescription.at")}
          <span className="date-time-box">
            {/* {consultationDetails?.dateConsultation.substring(11, 16)} */}
            {consultationDetails?.dateConsultation?.slice(11, 16)}
          </span>
          {t("AddPrescription.hours")}
        </span>
        <span className="courant-text mb-4">
          {t("AddPrescription.consultationCarried")}
          {consultationDetails?.type === "teleconsultation"
            ? t("AddPrescription.teleconsultation")
            : t("AddPrescription.presentiel")}
          :
        </span>

        {/* Traitement */}
        <span className="subtitle block uppercase mt-7">
          {t("AddPrescription.Treatment")}
        </span>
        <Formik
          initialValues={{
            prescriptions: [emptyPrescription],
          }}
          onSubmit={(values) => {
            try {
              setbtnLoading(true);
              if (values.prescriptions.every((el) => !el.medicament)) {
                setbtnLoading(false);

                return makeToast("warning", t("AddPrescription.fillAll"));
              }
              new Promise(async (resolve, reject) => {
                const editConsultation = await editConsultationById(
                  consultationDetails._id,
                  { ...values, templatePdfId }
                );
                if (editConsultation) {
                  setIsSuccess && setIsSuccess(true);
                }
                if (prescriptions) {
                  const { taskPrescription } = editConsultation;
                  taskPrescription &&
                    setPresecriptionList &&
                    setPresecriptionList([...prescriptions, taskPrescription]);
                }
                resolve(editConsultation);
              })
                .then(async (editConsultation: any) => {
                  // if (patientData && checkCPSCard && sendToDMP) {
                  //   let selectedTemplate;
                  //   if (
                  //     editConsultation?.consultation?.prescription
                  //       ?.templatePdfId
                  //   ) {
                  //     const response = await getTemplateById(
                  //       editConsultation?.consultation?.prescription[0]
                  //         ?.templatePdfId
                  //     );
                  //     selectedTemplate = { ...response.data };
                  //   }
                  //   let collectedPrecriptionData = "";
                  //   let contentState = null;
                  //   editConsultation?.consultation?.prescriptions[0]?.prescriptions?.map(
                  //     // eslint-disable-next-line array-callback-return
                  //     (e) => {
                  //       collectedPrecriptionData += `${e.medicament} ${e.dosage} <br>`;
                  //     }
                  //   );
                  //   if (collectedPrecriptionData) {
                  //     const contentBlock = htmlToDraft(
                  //       collectedPrecriptionData
                  //     );
                  //     if (contentBlock) {
                  //       contentState = ContentState.createFromBlockArray(
                  //         contentBlock.contentBlocks
                  //       );
                  //     }
                  //   }
                  //   const pdfb64 = (await generatePDFBase64(
                  //     selectedTemplate?.listData || defaultTemplate?.listData,
                  //     EditorState.createWithContent(contentState),
                  //     editConsultation?.consultation,
                  //     true,
                  //     user
                  //   )) as any;
                  //   const responseDMpFile = await AddNewDMPFile(
                  //     pdfb64?.replace(/^data:application\/(pdf);base64,/, ""),
                  //     "urn:ihe:iti:xds-sd:pdf:2008"
                  //   );
                  //   await updatePrescription({
                  //     consultationId: editConsultation?.consultation?._id,
                  //     dmp: responseDMpFile,
                  //     prescriptionId:
                  //       editConsultation?.consultation?.prescriptions[0]?._id,
                  //   });
                  // }
                })
                .then(() => {
                  if (redirection) history.push("/");
                  setbtnLoading(false);
                  closeModal();
                })
                .catch((err) => {
                  setbtnLoading(false);
                  console.error(err);
                });
            } catch (err) {
              setbtnLoading(false);
              console.error(err);
            }
          }}
          validationSchema={Yup.object().shape({
            prescriptions: Yup.array().of(
              Yup.object().shape({
                medicament: Yup.string(),
                dosage: Yup.string(),
              })
            ),
          })}
        >
          {(props) => {
            const {
              values,
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              errors,
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              setFieldValue,
            } = props;

            return (
              <form onSubmit={handleSubmit}>
                <FieldArray name="prescriptions">
                  {({ push, remove }) =>
                    values.prescriptions.map((prescription: any, index) => (
                      <div key={index}>
                        <FloatInput
                          id={`prescriptions.${index}.medicament`}
                          name={`prescriptions.${index}.medicament`}
                          type="text"
                          labelText={t("AddPrescription.TreatmentName")}
                          placeholder={t("AddPrescription.TreatmentName")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.prescriptions[index].medicament}
                        />
                        <FloatInput
                          id={`prescriptions.${index}.dosage`}
                          name={`prescriptions.${index}.dosage`}
                          type="text"
                          labelText={t("AddPrescription.Dosage")}
                          placeholder={t("AddPrescription.Dosage")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.prescriptions[index].dosage}
                        />
                        <hr className="border-blue-500" />
                        {index === values.prescriptions.length - 1 && (
                          <button
                            type="button"
                            className="block w-full mt-4 border border-dashed border-gray-300 rounded-lg h-12 card-subtitle menu "
                            onClick={() => push({ medicament: "", dosage: "" })}
                          >
                            {t("AddPrescription.AddTreatment")}
                          </button>
                        )}
                      </div>
                    ))
                  }
                </FieldArray>
                {/* {qualified && checkCPSCard && (
                  <p
                    className="mt-4 flex items-center"
                    onClick={() => setSendToDMP((x) => !x)}
                  >
                    <Checkbox checked={sendToDMP} />
                    <span> {t("AddPrescription.ShareWithDMP")}</span>
                  </p>
                )} */}
                <Button
                  type="button"
                  width="122.7px"
                  height="50px"
                  className="block my-10 mx-auto"
                  onClick={() => handleSubmit()}
                  disabled={btnLoading}
                >
                  {t("AddPrescription.Confirm")}
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default PrescriptionModal;
