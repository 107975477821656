import React, { useEffect, useState } from "react";
import Button from "../../ButtonUI";
import "./styles.scss";
import { ReactComponent as Close } from "../../../assets/icons/icon-close.svg";
import { getPatientObservations } from "../../../api/patient";
import moment from "../../../helper/moment";
import { useTranslation } from "react-i18next";

interface Props {
  openModal: boolean;
  setOpenModal: Function;
  doctorId?: string;
  selectedPatient: string;
  typeObservationObject: IType;
  patientId: string;
}

interface IType {
  typeObservation: string;
  idObservation: string;
}
const ShowObservations: React.FC<Props> = ({
  openModal,
  setOpenModal,
  selectedPatient,
  typeObservationObject,
  patientId,
}) => {
  const [data, setData] = useState<any>([]);
  const { t } = useTranslation() as any;
  useEffect(() => {
    async function fetchMyAPI() {
      let response = await getPatientObservations({
        patientId,
        ...typeObservationObject,
      });
      if (typeObservationObject.typeObservation === "treatements") {
        setData(response.treatement.observationsDoctor);
      } else if (typeObservationObject.typeObservation === "antecedents") {
        setData(response.antecedentMedicaux.observationsDoctor);
      } else {
        setData(response.antecedentChirurgicaux.observationsDoctor);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute bg-white z-40 top-1/3   showObservationsModal p-4">
        <div className="relative ">
          <div className="absolute right-0 mt-2 mr-2">
            <Button status="icon-secondary" onClick={() => setOpenModal(false)}>
              <Close />
            </Button>
          </div>
        </div>
        <div className="flex flex-wrap items-baseline">
          <h1 className="mb-10"> {t("ModalFichePatient.Observations")}</h1>
        </div>
        <div>
          {data && data.length !== 0 ? (
            data.map((e): any => {
              return (
                <div className="mb-6">
                  <div>
                    <h3 className="text-black">{e?.message}</h3>
                  </div>
                  <div>
                    <p>
                      {t("ModalFichePatient.ObservationsDoctor")}{" "}
                      <strong>
                        {e.doctorId && e.doctorId.firstName}{" "}
                        {e.doctorId && e.doctorId.lastName}
                      </strong>{" "}
                      {t("ModalFichePatient.at")} {moment(e.date).format("lll")}
                    </p>
                  </div>
                </div>
              );
            })
          ) : (
            <p className="inset-0 flexCenter">
              {" "}
              {t("ModalFichePatient.NoObservationsExist")}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShowObservations;
