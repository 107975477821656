import { Stack, IconButton, Box, FormLabel } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import ButtonUI from "../../../ButtonUI";
import { FieldInput } from "../../Elements/FieldInput";
import { useRepeater } from "../../../../hooks/FormGeneratorHooks/useRepeater";

const DEFAULT_VALUES = {
  collection: [{ label: "label" }],
};

export const RepeaterForLignes = (props) => {
  const collection = useRepeater({
    name: "lignes",
    form: props.form,
    initialValues: props.value,
  });
  const { t } = useTranslation() as any;
  return (
    <Stack
      justifyContent={"flex-start "}
      spacing={2}
      align="start"
      className="border p-4 rounded-lg w-full mb-6 "
    >
      <FormLabel>
        {" "}
        <span style={{ fontSize: "16px" }} className="font-bold ">
          {t("Survey.Questions")}
        </span>
      </FormLabel>
      <Box>
        {collection.keys.map((key, index) => (
          <Stack
            key={key}
            direction="row"
            spacing="4"
            mb="6"
            data-test={`repeater-item[${index}]`}
          >
            <Box flex="1">
              <FieldInput
                value={props.value[index]?.label}
                name={`lignes[${index}].label`}
                defaultValue={DEFAULT_VALUES.collection[index]?.label}
                label={`${"Ligne " + (index + 1)}`}
                required={t("Survey.RequireField")}
                m="0"
              />
            </Box>

            <Box pt="1.75rem">
              <IconButton
                aria-label="Delete"
                icon={
                  <DeleteIcon
                    className="
                    w-10 h-10"
                  />
                }
                onClick={() => collection.remove(index)}
              />
            </Box>
          </Stack>
        ))}
      </Box>

      <Box>
        {collection.length <= 20 && (
          <ButtonUI
            status="secondary"
            className="p-2 text-xs w-48"
            type="button"
            onClick={() => collection.append()}
          >
            {" "}
            {t("Survey.AddQuestion")}{" "}
          </ButtonUI>
        )}
      </Box>
    </Stack>
  );
};
