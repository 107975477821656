/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import _ from "lodash";
/**
 * Helper & Api
 */
import moment from "../../../helper/moment";
import { loadImage } from "../../../api/documents";
// import { getUndoneTask } from "../../../api/task";
/**
 * Component
 */
import Tooltip from "../../../components/toolTip";
import Button from "../../../components/ButtonUI";
/**
 * Assets
 */
import { ReactComponent as IconMessage } from "../../../assets/icons/icon-message.svg";
import { ReactComponent as WomanPatient } from "../../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../../assets/illustrations/illustration-homme-patient.svg";

import "./styles.scss";
import { useTranslation } from "react-i18next";

const colorAvatar = [
  "#ccd2ff",
  "#d5f0f7",
  "#ffecba",
  "#fff7ab",
  "#ffccd2",
  "#ffe2c8",
];

interface IPatientDetails {
  firstName: string;
  lastName: string;
  _id: string;
  date: Date;
  type: string;
  gender: string;
  photo?: string;
  consultations?: [] | any;
  INS: any;
  sexe: string;
}

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  patientDetails: IPatientDetails;
  openModal: boolean;
  setOpenModal: Function;
  setSelectedPatient: Function;
  setChatModal: Function;
  selectedPatient: string;
  setColorIndex: Function;
  setChatWithId: Function;
  i: number;
  user: any;
};

const CardPatient: React.FC<Props> = ({
  patientDetails,
  openModal,
  setOpenModal,
  setSelectedPatient,
  selectedPatient,
  setChatModal,
  setColorIndex,
  setChatWithId,
  user,
  i,
}) => {
  const [sortedConsultation, setSortedConsultation] = useState<any>([]);
  const [UnDonetask] = useState([]) as any;
  const { t } = useTranslation() as any;
  useEffect(() => {
    const ordonned =
      patientDetails?.consultations.length !== 0
        ? patientDetails?.consultations.sort(
            (a: any, b: any) =>
              new Date(a.dateConsultation).valueOf() -
              new Date(b.dateConsultation).valueOf()
          )
        : [];
    setSortedConsultation(ordonned);
  }, []);

  // const _checkUndoneTask = async () => {
  //   try {
  //     const response = await getUndoneTask(patientDetails._id, user?._id);
  //     setUndoneTask(response?.tasks);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  //Check if the patient have Undone task for connected doctor
  useEffect(() => {
    // _checkUndoneTask();
  }, []);

  return (
    <div
      className={`relative cursor-pointer hover:shadow card-patient justify-between bg-white ${
        selectedPatient === patientDetails?._id && "selectedPatientDoctor"
      }`}
      style={{ zIndex: 0 }}
      onClick={(e) => {
        setSelectedPatient(patientDetails?._id);
        setOpenModal(!openModal);
        setColorIndex(i);
      }}
    >
      {!_.isEmpty(UnDonetask) && (
        <div className="absolute  right-2 top-2">
          {/* <Tooltip tooltipText="Des tâches en attente" position="top"> */}
          <div className="rounded-full bg-red-500 w-3 h-3"></div>
          {/* </Tooltip> */}
        </div>
      )}

      <div className="flex items-center patient-info">
        <div
          className="rounded-full h-12 w-12 flexCenter mx-5 my-auto relative"
          style={{
            backgroundColor: patientDetails?.photo
              ? ""
              : colorAvatar[i % colorAvatar.length],
          }}
        >
          {patientDetails?.photo ? (
            <img
              crossOrigin="anonymous"
              referrerPolicy="origin"
              src={loadImage(
                patientDetails?._id,
                "profile",
                "thumbnail",
                patientDetails?.photo
              )}
              alt="patient"
            />
          ) : patientDetails?.sexe === "woman" ? (
            <WomanPatient />
          ) : (
            <ManPatient />
          )}

          {process.env.REACT_APP_KATOMI_TENANT === "katomi" &&
            !patientDetails?.INS?.responseINS?.insActif?.valeur && (
              <div className="absolute  -right-2 -top-2">
                <Tooltip
                  tooltipText={t("MyPatient.UnverifiedINS")}
                  position="top"
                  clsText={{ width: "200px" }}
                >
                  ⚠️
                </Tooltip>
              </div>
            )}
        </div>

        <div className=" ">
          <div className="flex-grow  ">
            <p className="card-title capitalize">
              {patientDetails.firstName} {patientDetails.lastName}
            </p>

            {sortedConsultation.length !== 0 ? (
              <>
                <p className="card-subtitle">
                  {t("MyPatient.ConsultationOf")}{" "}
                  <span>
                    {moment(
                      sortedConsultation[sortedConsultation.length - 1]
                        ?.dateConsultation
                    ).format(t("Moment.Z"))}
                  </span>
                </p>
                <p className="box-motif ">
                  {sortedConsultation[sortedConsultation.length - 1].motif}
                </p>
              </>
            ) : (
              <p className="card-subtitle">{t("MyPatient.NoConsultation")}</p>
            )}
          </div>
        </div>
      </div>
      <div className="patient-icons">
        <Button
          status="icon-secondary"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setChatWithId(patientDetails?._id);
            setChatModal(true);
          }}
        >
          <IconMessage />
        </Button>
      </div>
    </div>
  );
};

export default CardPatient;
