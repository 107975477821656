import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Formik } from "formik";
import Button from "../../ButtonUI";
import SelectComp from "../../Select";
import DoctorTimeSlots from "../../DoctorTimeSlots";
import { useAtom } from "jotai";
import { userAtom } from "../../../state/auth";
import Input from "../../Input";
import makeToast from "../../Snackbar";
import moment from "../../../helper/moment";
import { getAllMyPatientsByDoctorId2 } from "../../../api/doctor";
import { addConsultationDoctorKatomi } from "../../../api/consultation";
import Icon from "../../Icon";
import { useTranslation } from "react-i18next";

interface Props {
  setShowAgendaModal: any;
  selectedTimeSlot?: any;
  resetTimeSlots?: any;
}

const Presentiel: React.FC<Props> = ({
  setShowAgendaModal,
  selectedTimeSlot,
  resetTimeSlots,
}) => {
  const { t } = useTranslation() as any;
  const [user] = useAtom(userAtom) as any;
  const [myPatient, setMyPatient] = useState([]) as any;
  const [pickedTimeSlot, setPickedTimeSlot] = useState({}) as any;

  const handlePickDate = (values) => {
    // makeToast(
    //   "info",
    //   `Le rendez-vous sera au date ${moment(values.date).format(
    //     "DD/MM/YYYY"
    //   )} à ${moment(values.time).format("HH:mm")}`
    // );
    setPickedTimeSlot(values);
  };
  useEffect(() => {
    async function getpatientsMine() {
      const myPatients = await getAllMyPatientsByDoctorId2(user._id);
      if (myPatients) {
        setMyPatient(myPatients.myPatients.patients);
      }
    }
    getpatientsMine();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values) => {
    try {
      const {
        cabinetName,
        selectedPatient,
        motif,
        // slotDateTime,
        // forcerRdv,
        commentaire,
      } = values;
      const { time, timeslotId } = pickedTimeSlot;
      if (!timeslotId)
        return makeToast("warning", t("Agenda.ErrorFillDateConsultation"));
      if (!selectedPatient)
        return makeToast("warning", t("Agenda.ErrorActPatient"));
      if (!motif) return makeToast("warning", t("Agenda.ErrorActReason"));
      if (!cabinetName)
        return makeToast("warning", t("Agenda.CabinetNameEmpty"));

      const newConsultation = {
        patientId: selectedPatient,
        doctorId: user._id,
        timeslotId,
        dateConsultation: time,
        motif,
        type: "presentiel",
        cabinetName:
          user?.workingLocation?.length === 1
            ? user?.workingLocation[0].name
            : cabinetName,
        cabinetColor:
          user?.workingLocation?.length === 1
            ? user?.workingLocation[0].color
            : user?.workingLocation?.find((el) => el.name === cabinetName)
                .color,
        commentaire,
      };

      await addConsultationDoctorKatomi(newConsultation);
      makeToast("success", t("Agenda.SuccessTakeMeet"));

      //Reset state and close modal
      setMyPatient([]);
      setPickedTimeSlot({});
      setShowAgendaModal("");
      resetTimeSlots();
    } catch (err) {
      //Reset state and close modal
      setMyPatient([]);
      setPickedTimeSlot({});
      setShowAgendaModal("");
      resetTimeSlots();
      console.error(err);
    }
  };

  useEffect(() => {
    if (selectedTimeSlot)
      setPickedTimeSlot({
        date: selectedTimeSlot?.item?.startTime,
        time: selectedTimeSlot?.item?.startTime,
        type: "presentiel",
        timeslotId: selectedTimeSlot?.item?.id,
      });
  }, [selectedTimeSlot]);

  return (
    <Formik
      initialValues={{
        cabinetName: !_.isEmpty(user?.workingLocation)
          ? user?.workingLocation[0]?.name
          : "",
        selectedPatient: "",
        motif: "",
        slotDateTime: "",
        forcerRdv: true,
        commentaire: "",
      }}
      onSubmit={onSubmit}
      //validationSchema={validationSchema}
    >
      {(props) => {
        const {
          values,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <>
            <form onSubmit={handleSubmit} className="flexColCenter ">
              {!_.isEmpty(user?.workingLocation) &&
              user?.workingLocation.length === 1 ? (
                <Input
                  id="location"
                  type="text"
                  value={user?.workingLocation[0].name}
                  // onChange={handleChange}
                  onBlur={handleBlur}
                  className=" mb-8 pl-10"
                  inlineLabel={false}
                  onfocus={true}
                  image="location"
                  right={false}
                  disabled
                />
              ) : (
                <SelectComp
                  options={user?.workingLocation?.map((el) => ({
                    label: el.name,
                    value: el.name,
                  }))}
                  text={t("Agenda.Cabinet")}
                  setText={false}
                  name="cabinetName"
                  onChange={handleChange}
                  value={values.cabinetName}
                  // error={errors.cabinetName}
                  className="menuInput"
                  image="location"
                />
              )}
              <SelectComp
                options={
                  myPatient.length > 0
                    ? myPatient?.map((p: any) => {
                        return {
                          label: `${p.firstName} ${p.lastName}`,
                          value: `${p._id}`,
                        };
                      })
                    : []
                }
                text={t("Agenda.SelectPatient")}
                name="selectedPatient"
                onChange={handleChange}
                value={values.selectedPatient}
                error={errors.selectedPatient}
                className="menuInput"
                image="patients"
              />
              <Input
                id="motif"
                placeholder={t("Agenda.MotifConsultation")}
                type="text"
                error={errors.motif}
                value={values.motif}
                onChange={handleChange}
                onBlur={handleBlur}
                className=" mb-8"
                inlineLabel={false}
                onfocus={true}
                // image="menuInput"
                right={false}
              />

              {selectedTimeSlot?.item?.id ? (
                <div className="my-5">
                  <p className="flex items-center">
                    {t("Agenda.meetingMedicalon")}
                    <span className="bg-gray-200 rounded-lg p-2 text-blue-500 font-bold text-xs ml-2">
                      {moment(selectedTimeSlot?.item?.startTime).format(
                        "DD/MM/yyyy"
                      )}
                    </span>
                    <span className="mx-2">{t("Agenda.to")} </span>
                    <span className="bg-gray-200 rounded-lg p-2 text-blue-500 font-bold text-xs">
                      {moment(selectedTimeSlot?.item?.startTime).format(
                        "HH:mm"
                      )}
                    </span>
                    <Icon isIcon={false} name="tick" className="ml-3" />
                  </p>
                </div>
              ) : (
                <DoctorTimeSlots
                  handlePickDate={handlePickDate}
                  doctorDetails={user}
                  maxCol={window?.innerWidth >= 576 ? 5 : 3}
                  cls="miniAgenda"
                  withResult
                />
              )}

              <Input
                id="commentaire"
                placeholder={t("Agenda.AddComment")}
                type="text"
                error={errors.commentaire}
                value={values.commentaire}
                onChange={handleChange}
                onBlur={handleBlur}
                className=" mb-8"
                inlineLabel={false}
                onfocus={true}
                // image="joinFolder"
                // right={true}
              />
              <div className="flexRowCenter">
                <Button
                  type="submit"
                  disabled={isSubmitting || Object.keys(errors).length > 0}
                  status="primary"
                  className="btn"
                  width="170px"
                  height="50px"
                  onClick={() => handleSubmit()}
                >
                  {t("Agenda.Save")}
                </Button>
              </div>
            </form>
          </>
        );
      }}
    </Formik>
  );
};

export default Presentiel;
