/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import { useTranslation } from "react-i18next";
import { ReactComponent as Plus } from "../../assets/illusts/Classic.svg";
import { ReactComponent as OneForm } from "../../assets/illusts/Simulation.svg";
import { useEffect, useState } from "react";
import Input from "../../components/FloatInput";
import Button from "../../components/ButtonUI";
import history from "../../history";
import { useAtom } from "jotai";
import { formAtom } from "../../state/formAtom";
import { createForm } from "../../api/survey";
import { userAtom } from "../../state/auth";
import { User } from "../../types/user";

type Props = any;
const CreationSteps: React.FC<Props> = ({ props }) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [type, setType] = useState("Classic");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [form, setForm] = useAtom(formAtom);
  const [user] = useAtom<User | null>(userAtom) as any;
  useEffect(() => {
    let obj = {
      name: name,
      description: desc,
      externelResources: {},
      sections: [{ task_data: [], stepNbr: 0, sectionName: "Page 1" }],
      type: type,
    };
    setForm(obj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, desc]);

  const [, setSelectedForm] = useState() as any;
  const createSurvey = async () => {
    try {
      const newSurvey = await createForm({
        ...form,
        doctorID: user?._id,
      });
      if (newSurvey) {
        setSelectedForm(newSurvey.newSurvey);
        history.push(`/creation/${newSurvey.newSurvey._id}`);
      }
    } catch (error) {
      console.error(error);
      Promise.reject(error);
    }
  };
  return (
    <>
      {type !== "" && step == 1 && (
        <>
          <h3 className="font-bold text-2xl text-black text-center my-10 ">
            {t("Survey.AddNameDescriptionToSurvey")}
          </h3>
          <div className="my-5 mx-4 items-center  ">
            <Input
              id="name"
              className="w-full"
              placeholder={t("Survey.SurveyName")}
              labelText={t("Survey.SurveyName")}
              type="text"
              // error={errors.email}
              // value={values.email}
              onBlur={(e) => setName(e.target.value)}
              onChange={(e) => setName(e.target.value)}
              inlineLabel={false}
              onfocus={true}
              style={{ maxHeight: "50px" }}
            />
          </div>
          <div className="mx-4 items-center  ">
            <Input
              id="desc"
              className="w-full"
              placeholder={t("Survey.SurveyDescription1")}
              labelText={t("Survey.SurveyDescription1")}
              type="text"
              style={{ maxHeight: "50px" }}
              onBlur={(e) => setDesc(e.target.value)}
              onChange={(e) => setDesc(e.target.value)}
              inlineLabel={false}
              onfocus={true}
            />
          </div>
          <Button
            disabled={name === ""}
            status="primary"
            className="px-6 py-2 mt-10"
            onClick={async () => {
              await createSurvey();
            }}
          >
            Créer
          </Button>
        </>
      )}

      {step == 0 && (
        <div className="p-4">
          {" "}
          <h3 className="font-bold text-2xl text-black my-2 ">
            {t("Survey.SelectSurveyForm")}
          </h3>
          <h3>{t("Survey.ChoiceSurveyForm")}</h3>{" "}
          <div className=" flex   justify-center  ">
            <div className="w-1/2  mx-6 py-6">
              <a
                className="c-card block bg-white shadow-md hover:shadow-2xl rounded-2xl overflow-hidden cursor-pointer"
                onClick={() => {
                  setType("Classic");
                  setStep(step + 1);
                }}
              >
                <div className="relative bg-blue-100    overflow-hidden flexCenter">
                  <Plus className="my-4 " />{" "}
                </div>
                <div className="px-4 py-2 min-h-full">
                  <h2 className="mt-2 mb-2  font-bold">
                    {t("Survey.classicSurvey")}
                  </h2>
                  <p className="text-md">{t("Survey.ShowSurvey")}</p>
                </div>
                {/* </Link> */}
              </a>
            </div>

            <div className="w-1/2 mx-6 py-6">
              <a
                onClick={() => {
                  setType("Simulation");
                  setStep(step + 1);
                }}
                className="c-card block bg-white shadow-md hover:shadow-2xl rounded-2xl overflow-hidden cursor-pointer"
              >
                <div className="relative  bg-blue-100  overflow-hidden flexCenter">
                  <OneForm className="my-4 " />{" "}
                </div>
                <div className="px-4 py-2 min-h-full">
                  <h2 className="mt-2 mb-2  font-bold">
                    {t("Survey.CardSurvey")}
                  </h2>
                  <p className="text-md">{t("Survey.oneSurveyPerPage")}</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreationSteps;
