/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React from "react";
import _ from "lodash";
import { useAtom } from "jotai";
import moment from "../../helper/moment";
import { useTranslation } from "react-i18next";
import {
  ChakraProvider,
  ModalBody,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  extendTheme,
} from "@chakra-ui/react";
import history from "../../history";
import ModalUI from "../../components/ModalUI";
import Input from "../../components/Input";
import classNames from "classnames";
import makeToast from "../../components/Snackbar";
import ButtonUI from "../../components/ButtonUI";
import FormViewer from "../../components/SurveyGenerator/FormViewer";
import DisplayForm from "../DragableInterface/DisplayForm";
import { getSurveyByPatientId, patientResponseSurvey } from "../../api/survey";
import { userAtom } from "../../state/auth";
import { ReactComponent as IconSearch } from "../../assets/icons/icon-search.svg";
import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";
import "./styles.scss";
import Button from "../../components/ButtonUI";

const theme = extendTheme({
  colors: {
    transparent: "transparent",
    black: "#000",
    white: "#fff",
    blue: "#4565f6",
  },
});

const Survey = () => {
  const { t } = useTranslation() as any;
  const [user] = useAtom(userAtom);
  const [, setResponseForm] = React.useState() as any;
  const [survey, setSurvey] = React.useState([]) as any;
  const [tempSurvey, setTempSurvey] = React.useState([]) as any;
  const [selectedPreview, setSelectedPreview] = React.useState() as any;
  const [, setWidthPage] = React.useState(document.body.clientWidth);
  const [showModalMobile, setShowModalMobile] = React.useState<
    boolean | Function
  >(false);
  const [, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  const [, setTotalResult] = React.useState(0);
  const [filterName, setFilterName] = React.useState("");
  const [limit] = React.useState(10);
  const fieldObjs = {};
  selectedPreview?.surveyId.sections.map((e) =>
    e.task_data.map((f) => {
      fieldObjs[f.field_name] = "";
    })
  );

  const _getAllSurveyByPatientId = async () => {
    try {
      setLoading(true);
      const response = await getSurveyByPatientId({ patientId: user?._id });
      setSurvey(response?.survey?.reverse());
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    try {
      await patientResponseSurvey(selectedPreview?._id, values);
      makeToast("success", t("Survey.SuccessSavesSrveyResponse"));
      _getAllSurveyByPatientId();
      setSelectedPreview();
    } catch (error) {}
  };

  React.useEffect(() => {
    _getAllSurveyByPatientId();
  }, []);

  React.useEffect(() => {
    if (
      !_.isEmpty(
        survey.filter((el) =>
          el.surveyId?.name?.toLowerCase()?.includes(filterName?.toLowerCase())
        )
      )
    ) {
      setTotalResult(
        survey.filter((el) =>
          el.surveyId?.name?.toLowerCase()?.includes(filterName?.toLowerCase())
        )?.length
      );
      setTotalPage(
        Math.ceil(
          survey.filter((el) =>
            el.surveyId?.name
              ?.toLowerCase()
              ?.includes(filterName?.toLowerCase())
          ).length / limit
        )
      );
      setTempSurvey(
        survey
          .filter((el) =>
            el.surveyId?.name
              ?.toLowerCase()
              ?.includes(filterName?.toLowerCase())
          )
          .slice((page - 1) * limit, (page - 1) * limit + limit)
      );
    }
  }, [page, survey, filterName]);

  React.useEffect(() => {
    setWidthPage(document.body.clientWidth);
    document.body.clientWidth < 900 && setShowModalMobile(true);
  }, [document.body.clientWidth]);

  return document.body.clientWidth < 900 ? (
    <ModalUI
      showModal={showModalMobile !== false}
      closeModal={() => setShowModalMobile(false)}
      width={"300px"}
    >
      <div className="m-4">
        <span>{t("Survey.NoAvailbleForMobile")}</span>
        <div className="w-full mt-8 flex justify-center items-center">
          <Button
            status="primary"
            width="50%"
            height="37px"
            className=""
            onClick={() => {
              history.push("/");
            }}
          >
            {t("Survey.Ok")}
          </Button>
        </div>
      </div>
    </ModalUI>
  ) : (
    <div className="patient-survey">
      <h1>{t("Survey.MyQuestionnaires")}</h1>
      <div className="flex justify-end">
        <div className="relative flex flex-wrap items-stretch md:mb-3 md:mt-6 mb-2 mt-3 ">
          <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 bg-transparent rounded text-base items-center justify-center w-8 pl-1 py-1">
            <IconSearch />
          </span>
          <Input
            type="text"
            placeholder={t("Survey.FindSurvey")}
            width="w-full"
            onChange={(e) => setFilterName(e.target.value)}
            className={classNames(
              "rounded-xl px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white text-sm outline-none focus:border-blue-500  border-blue-100 w-full pl-10"
            )}
          />
        </div>
      </div>
      <div className="flexCenter bg-white rounded-xl mt-7 pt-5">
        <table className="items-center w-11/12 bg-transparent border-collapse ">
          {!_.isEmpty(tempSurvey) ? (
            <>
              <thead>
                <tr>
                  <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left text-bold">
                    {t("Survey.SurveyName")}
                  </th>
                  <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left text-bold">
                    {t("Survey.ReceiptDate")}
                  </th>
                  <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left text-bold">
                    {t("Survey.RequestingDoctor")}
                  </th>
                  <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left text-bold">
                    {t("Survey.status")}
                  </th>
                </tr>
              </thead>

              <tbody>
                {tempSurvey.map((el) => (
                  <tr>
                    <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                      {el?.surveyId?.name}
                    </td>
                    <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                      {moment(el?.surveyId?.createdAt).format("LL")}
                    </td>
                    <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                      {el?.doctorId?.firstName + " " + el?.doctorId?.lastName}
                    </td>
                    <td
                      className={`border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left cursor-pointer`}
                      onClick={() => {
                        setSelectedPreview(el);
                      }}
                    >
                      {el.status === "waiting"
                        ? t("Survey.Pending")
                        : t("Survey.Answered")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </>
          ) : (
            <h3 className="text-center my-5">{t("Survey.EmptySurveyList")}</h3>
          )}
        </table>
      </div>
      {selectedPreview && selectedPreview.response ? (
        <ChakraProvider theme={theme}>
          <Modal
            size="l"
            isOpen={selectedPreview}
            onClose={() => setSelectedPreview()}
          >
            <ModalOverlay />
            <ModalContent className="mx-6 p-6">
              <ButtonUI
                status="icon-secondary"
                onClick={() => setSelectedPreview()}
                className="absolute right-1 top-0 "
                type="button"
              >
                <Close />
              </ButtonUI>
              <ModalBody>
                {selectedPreview?.surveyId?.sections.map((s) => (
                  <FormViewer
                    elements={s.task_data}
                    values={selectedPreview.response}
                  />
                ))}
              </ModalBody>
            </ModalContent>
          </Modal>
        </ChakraProvider>
      ) : (
        selectedPreview && (
          <ChakraProvider theme={theme}>
            <Modal
              onClose={() => setSelectedPreview()}
              size="full"
              isOpen={selectedPreview && !selectedPreview.response}
              scrollBehavior={"inside"}
            >
              <ModalOverlay />
              <ModalContent className="mx-6">
                <ButtonUI
                  status="icon-secondary"
                  onClick={() => setSelectedPreview()}
                  className="absolute right-1 top-0 "
                  type="button"
                >
                  <Close />
                </ButtonUI>
                <ModalBody>
                  <DisplayForm
                    onChange={setResponseForm}
                    onSubmit={handleSubmit}
                    formConfig={selectedPreview?.surveyId}
                    initialField={fieldObjs}
                  />
                </ModalBody>
                <ModalFooter></ModalFooter>
              </ModalContent>
            </Modal>

            {/* </Modal> */}
          </ChakraProvider>
        )
      )}
      <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 mt-5 rounded-xl">
        <div className="flex-1 flex justify-between sm:hidden">
          <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
            {" "}
            Previous{" "}
          </span>
          <span className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
            {" "}
            Next{" "}
          </span>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Page N°<span className="font-medium">{page}</span>/
              <span className="font-medium">{totalPage}</span>
            </p>
          </div>
          <div>
            <nav
              className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
              aria-label="Pagination"
            >
              <span
                className="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                onClick={() => setPage(page - 1)}
              >
                <span className="sr-only">Previous</span>
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
              {Array.from({ length: totalPage }, (v, i) => i).map((el, i) => (
                <span
                  aria-current="page"
                  onClick={() => setPage(i + 1)}
                  className="cursor-pointer z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                >
                  {i + 1}
                </span>
              ))}

              <span
                className="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                onClick={() => setPage(page + 1)}
              >
                <span className="sr-only">Next</span>
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Survey;
