import React, { useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import "./styles.scss";
// interface Imenu {
//   name: string;
//   link?: string;
// }

// type IProps = React.DetailedHTMLProps<
//   React.HTMLAttributes<HTMLDivElement>,
//   HTMLDivElement
// > & { menu?: Imenu[]; tabIndex?: any; className?: string };

const Tabs = ({ menu, className, tabIndex }) => {
  const [current, setCurrent] = useState(0);
  const handleCurrent = (i) => {
    setCurrent(i);
    tabIndex(i);
  };

  return (
    <div className={classNames("tabs max-w-max flexCenter p-1", className)}>
      {menu &&
        menu.map((el, i) => (
          <span
            onClick={() => {
              handleCurrent(i);
            }}
            className={`tab link cursor-pointer ${current === i && "current"}`}
          >
            {el.link ? <Link to={el.link}> {el.name} </Link> : el.name}
          </span>
        ))}
    </div>
  );
};

Tabs.defaultProps = {
  menu: [],
};

export default Tabs;
