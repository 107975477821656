import { useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
/**
 * Helper & API
 */
import moment from "../../helper/moment";
import { loadImage } from "../../api/documents";
/**
 * Components
 */
import Button from "../ButtonUI";
import Icon from "../Icon";
import Tabs from "../Tabs";
import Spinner from "../Spinner";
import makeToast from "../Snackbar";
/**
 * Assets
 */
import phone from "../../assets/images/phone.png";

import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";
import { handleSpeciality } from "../../helper/handleSpeciality";

const DoctorCard = ({
  closeModal,
  selectSpecialist,
  openTakeMeetModal,
  firstOpenMeet,
}) => {
  const [location, setLocation] = useState(
    selectSpecialist?.workingLocation[0]
  ) as any;
  const { t, i18n } = useTranslation() as any;
  return !selectSpecialist ? (
    <Spinner />
  ) : (
    <>
      <div className="md:p-5">
        <div className="flex justify-between">
          <div className="flex flex-1 items-center space-x-4  p-4 mb-2 rounded-2xl">
            <div className="h-16 w-16 bg-gray-100 ">
              {selectSpecialist?.business_photo ? (
                <img
                  crossOrigin="anonymous"
                  referrerPolicy="origin"
                  src={
                    selectSpecialist?.business_photo.slice(0, 4) === "http"
                      ? selectSpecialist?.business_photo
                      : loadImage(
                          selectSpecialist?._id,
                          "profile",
                          "thumbnail",
                          selectSpecialist?.business_photo
                        )
                  }
                  className="h-16 w-16 rounded-2xl object-fill shadow-md"
                  alt="profile"
                />
              ) : selectSpecialist?.sexe === "woman" ? (
                <WomanPatient className="h-16 w-16" />
              ) : (
                <ManPatient className="h-16 w-16" />
              )}
            </div>
            <div className="flex flex-col">
              <span className="card-title">{`${selectSpecialist?.firstName} ${selectSpecialist?.lastName}`}</span>
              <span className="card-subtitle">
                {handleSpeciality(
                  selectSpecialist?.speciality,
                  i18n.language.includes("en")
                )}
              </span>
            </div>
          </div>
          <Icon
            isIcon={true}
            name="close"
            onClick={closeModal}
            className="close-modal"
          />
        </div>

        {!_.isEmpty(selectSpecialist?.workingLocation) && (
          <div className="flexCenter">
            <Tabs
              menu={selectSpecialist?.workingLocation.map((el) => ({
                name: el.location,
              }))}
              tabIndex={(e) => {
                setLocation(selectSpecialist?.workingLocation[e]);
              }}
              className=""
            />
          </div>
        )}

        <div className="bg-gray-100 p-4 mt-5">
          <h3 className="uppercase my-3">
            {t("MyDoctors.LocationAndSchedules")}
          </h3>

          <div className="w-full flex ">
            <div className="w-1/2">
              <div className="flex">
                <Icon name="localisation" isIcon={true} />
                <div className="ml-2">
                  <p className="text-sm font-bold w-52">{location?.location}</p>
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <div className="flex">
                <img
                  crossOrigin="anonymous"
                  referrerPolicy="origin"
                  src={phone}
                  alt="info"
                  className="w-4 h-4"
                />

                <span className="text-sm ml-2">
                  {selectSpecialist?.phoneSuffix}{" "}
                  {location?.tel || selectSpecialist?.phoneNumber}
                </span>
              </div>
            </div>
          </div>

          <div className="w-full flex mt-5">
            <div className="w-1/2">
              <div className="flex-col">
                {!_.isEmpty(
                  selectSpecialist?.workingLocation?.find(
                    (el) => el.name === location?.name
                  )?.config
                ) &&
                  selectSpecialist?.workingLocation
                    ?.find((el) => el.name === location?.name)
                    ?.config[0].days.map((days: any, index) => {
                      return (
                        <div className="flex agenda">
                          <div className="font-bold capitalize mr-4">
                            {days}
                          </div>
                          <div className="mx-2">
                            {
                              selectSpecialist?.workingLocation?.find(
                                (el) => el.name === location?.name
                              )?.config[0].start
                            }
                          </div>
                          <div className="mx-2">
                            {
                              selectSpecialist?.workingLocation?.find(
                                (el) => el.name === location?.name
                              )?.config[0].end
                            }
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>

          <h3 className="mt-3">{t("MyDoctors.Expertises")}</h3>
          <div className="flex flex-wrap">
            {selectSpecialist?.expertises?.map((el) => (
              <div className="bg-gray-300 rounded-xl card-subtitle p-2 m-2 ">
                # {el.expertise}
              </div>
            ))}
          </div>

          <h3 className="my-3">{t("MyDoctors.RatesPayments")}</h3>
          <div className="flex flex-wrap">
            {[
              {
                service: "Consultation normal :",
                price: selectSpecialist?.pricing || "--",
                currency: selectSpecialist?.currency,
              },
              {
                service: "Consultation pendant la nuit :",
                price: selectSpecialist?.pricingNight || "--",
                currency: selectSpecialist?.currency,
              },
              {
                service: "Consultation pendant le weekend et jours fériés :",
                price: selectSpecialist?.pricingWeekEnd || "--",
                currency: selectSpecialist?.currency,
              },
              ...selectSpecialist?.otherPrice.map((el) => ({
                service: el.tarifLabel,
                price: el.price,
                currency: selectSpecialist?.currency,
              })),
            ].map((el) => (
              <p className="courant-text mx-5">
                {el.service}{" "}
                <span className="mx-3 text-primary-blue">{el.price}</span>{" "}
                {el.currency}{" "}
              </p>
            ))}
          </div>

          <div className="flex justify-around mt-5 flex-col md:flex-row items-center gap-4 md:gap-0">
            <div className="border-2 border-blue-500 flex-1 mx-5 flexCenter flex-col rounded-2xl p-2">
              <p>{t("MyDoctors.NextAvailable")}</p>
              <p>
                {firstOpenMeet === "Invalid date"
                  ? t("MyDoctors.NoDisponibility")
                  : moment(firstOpenMeet).format("LLL") === "Invalid date"
                  ? firstOpenMeet
                  : moment(firstOpenMeet).format("LLL")}
              </p>
            </div>

            <Button
              width="234px"
              height="54px"
              onClick={() => {
                firstOpenMeet === "Invalid date"
                  ? makeToast("warning", t("MyDoctors.NotFreeTS"))
                  : openTakeMeetModal();
              }}
            >
              {t("MyDoctors.TakeMeeting")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorCard;
