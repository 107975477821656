import axios from "axios";
import {
  DOCTOR_REGISTER_URL,
  DOCTOR_ADD_CURSUS,
  DOCTOR_GET_ALL_SPECIALIST,
  DOCTOR_ADD_MODIFY_TARIFS,
  DOCTOR_ADD_MODIFY_DESCRIPTION,
  DOCTOR_ADD_MODIFY_SITES,
  DOCTOR_ADD_EXPERTISE,
  DOCTOR_GET_MY_PATIENTS,
  DOCTOR_DELETE_EXPERTISE,
  DOCTOR_GET_MY_PATIENTS_FILTRED,
  DOCTOR_GET_PATIENT_WITH_ID,
  DOCTOR_GET_MY_PATIENTS2,
  DOCTOR_UPDATE_INFO,
  DOCTOR_ADD_MODIFY_LANGUE,
  DOCTOR_GET_OPEN_TIME_SLOTS,
  DOCTOR_GET_OPEN_TIME_SLOTS_WITH_CONSULTATION,
  DOCTOR_ADD_MODIFY_LOCATION,
  DOCTOR_SET_AGENDA_CONFIG,
  DOCTOR_GET_CONTRIES,
  DOCTOR_UPDATE_DATA,
  DOCTOR_Add_SPECIALIST,
  DOCTOR_ADD_EXIST_PATIENT,
  DOCTOR_GET_ALL_PATIENT_NAME,
  DOCTOR_SEEN_NOTIFICATION,
  DOCTOR_UPDATE_INS_DATA,
  GET_ALL_MATRICULE_INS,
  DOCTOR_UPDATE_DATA_WITH_PSC,
  DOCTOR_GET_USER_INFO_PSC,
} from "../config/api";
import IAgendaConfigItem from "../types/IAgendaConfigItem";

export const addDoctor = async (values) => {
  try {
    const { business_photo } = values;
    let formData = new FormData();
    delete values.business_photo;
    delete values.user_photo;
    delete values.industry_id;
    Object.entries(values).map(async ([k, v]) => {
      if (k !== "business_photo") {
        formData.append(k, `${v}`);
      }
    });
    formData.append("business_photo", business_photo);
    formData.append("user_photo", business_photo);
    formData.append(" _method", "post");
    formData.append("industry_id", "1");

    const res = await axios.post(DOCTOR_REGISTER_URL, formData, {
      withCredentials: true,
    });

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllDoctors = async (page, limit, filerObject?) => {
  try {
    let filter = "";
    for (const property in filerObject) {
      if (filerObject[property])
        filter += `&${property}=${filerObject[property]}`;
    }
    const res = await axios.get(
      `${DOCTOR_GET_ALL_SPECIALIST}?limit=${limit}&page=${page}${filter}`,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addCursus = async (values) => {
  try {
    const res = await axios.post(DOCTOR_ADD_CURSUS, values, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addOrModifyTarifs = async (values) => {
  try {
    const res = await axios.post(DOCTOR_ADD_MODIFY_TARIFS, values, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addOrModifyDescription = async (values) => {
  try {
    const res = await axios.post(DOCTOR_ADD_MODIFY_DESCRIPTION, values, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addOrModifySites = async (values) => {
  try {
    const res = await axios.post(DOCTOR_ADD_MODIFY_SITES, values, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addOrModifyExpertise = async (values) => {
  try {
    const res = await axios.post(DOCTOR_ADD_EXPERTISE, values, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllMyPatientsByDoctorId2 = async (id) => {
  try {
    const res = await axios.get(`${DOCTOR_GET_MY_PATIENTS2}/${id}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllMyPatientsByDoctorId = async (
  id,
  page,
  limit,
  filerObject?
) => {
  let filter = "";

  for (const property in filerObject) {
    if (filerObject[property])
      filter += `&${property}=${filerObject[property]}`;
  }

  try {
    const res = await axios.get(
      `${DOCTOR_GET_MY_PATIENTS}/${id}?page=${page}&limit=${limit}${filter}`,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    console.log("[errror]", error);
    return Promise.reject(error);
  }
};

export const getAllMyPatientFiltredsByDoctorId = async (body) => {
  try {
    const res = await axios.get(
      `${DOCTOR_GET_MY_PATIENTS_FILTRED}/${body.id}`,
      { params: body, withCredentials: true }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteExpertise = async (id, doctorId) => {
  try {
    const res = await axios.delete(
      `${DOCTOR_DELETE_EXPERTISE}/${id}/${doctorId}`,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getMyPatientWithId = async (id) => {
  try {
    const res = await axios.get(`${DOCTOR_GET_PATIENT_WITH_ID}/${id}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateDataInfoPatient = async (values) => {
  try {
    const res = await axios.post(DOCTOR_UPDATE_INFO, values, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updatePatientINSData = async (values) => {
  try {
    const res = await axios.post(DOCTOR_UPDATE_INS_DATA, values, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addOrModifyLangues = async (values) => {
  try {
    const res = await axios.post(DOCTOR_ADD_MODIFY_LANGUE, values, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getOpenTimeSlots = async (userId) => {
  try {
    const res = await axios.get(
      `${DOCTOR_GET_OPEN_TIME_SLOTS}?userId=${userId}`,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getOpenTimeSlotsWithConsultationData = async () => {
  try {
    const res = await axios.get(DOCTOR_GET_OPEN_TIME_SLOTS_WITH_CONSULTATION, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addLocations = async (values) => {
  try {
    const res = await axios.post(DOCTOR_ADD_MODIFY_LOCATION, values, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const setAgendaConfiguration = async (payload: {
  config: IAgendaConfigItem[];
  type: "teleconsultation" | "regular" | "act";
  timeSlotDuration: number;
  location?: string;
}) => {
  try {
    const res = await axios.post(DOCTOR_SET_AGENDA_CONFIG, payload, {
      withCredentials: true,
    });

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getContriesList = async () => {
  try {
    const res = await axios.get(DOCTOR_GET_CONTRIES, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateDoctorData = async (payload) => {
  try {
    const res = await axios.post(DOCTOR_UPDATE_DATA, payload, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateDoctorDataWithPRS = async (payload) => {
  try {
    const res = await axios.post(DOCTOR_UPDATE_DATA_WITH_PSC, payload, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const doctorAddSpecialist = async (payload) => {
  try {
    const res = await axios.post(DOCTOR_Add_SPECIALIST, payload, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addExistPatientToDoctorList = async (payload) => {
  try {
    const res = (await axios.post(
      `${DOCTOR_ADD_EXIST_PATIENT}/${payload}`,
      payload,
      {
        withCredentials: true,
      }
    )) as any;
    if (res?.response?.data?.message === "Max Patient Limit Reached")
      return res?.response?.data;
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllPatientName = async () => {
  try {
    const res = await axios.get(`${DOCTOR_GET_ALL_PATIENT_NAME}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const doctorSeenNotification = async (id) => {
  try {
    const res = await axios.post(
      `${DOCTOR_SEEN_NOTIFICATION}/${id}`,
      {},
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllMAtriculeINS = async () => {
  try {
    const res = await axios.get(GET_ALL_MATRICULE_INS, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getdoctorUserInfoPSC = async () => {
  try {
    const res = await axios.get(DOCTOR_GET_USER_INFO_PSC, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
