/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { useAtom } from "jotai";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { editPatient } from "../../../api/patient";
import { userAtom } from "../../../state/auth";
import phoneSuffix from "../../../config/constants/pays.json";
import countriesTn from "../../../config/constants/countriesTn.json";
import Icon from "../../../components/Icon";
import Input from "../../../components/FloatInput";
import Button from "../../../components/ButtonUI";
import Select from "../../../components/Select";
import Tabs from "../../../components/Tabs";
import makeToast from "../../../components/Snackbar";

import fr from "date-fns/locale/fr";
registerLocale("fr", fr);
setDefaultLocale("fr");

const EditMyDataModal = ({ closeModal }) => {
  const { t } = useTranslation() as any;
  const [user, setUser] = useAtom(userAtom) as any;
  const [startDate] = useState();
  const [tabsIndex, setTabIndex] = useState(0);

  const [, setRegionState] = useState([]) as any;
  const [selectedRegionState] = useState() as any;
  const [, setDepartementState] = useState([]) as any;
  const [selectedDepartementState] = useState() as any;
  const [, setCommunesState] = useState([]) as any;
  const [selectedCommunesState] = useState() as any;
  const [villeTnState] = useState("");
  const [, setDelegationTn] = useState([]) as any;
  const [delegationTnState] = useState("");

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      if (startDate) values.birthDate = startDate;
      values.id = user?._id;
      if (
        !values.firstName ||
        !values.lastName ||
        !values.sexe ||
        !values.birthDate ||
        !values.nationality ||
        !values.phone ||
        !values.address ||
        !values.postalCode ||
        !values.phoneSuffixe
      ) {
        return makeToast("warning", t("ProfilePatient.AllFieldsAreRequired"));
      }

      values.addressDetails = JSON.stringify({
        country: phoneSuffix.find((el) => el.dial_code === values.phoneSuffixe)
          ?.name,
        region: selectedRegionState?.value,
        departement: selectedDepartementState?.value,
        commune: selectedCommunesState?.value,
        gouvernement: villeTnState,
        delegation: delegationTnState,
      });
      const response = await editPatient(values);
      setSubmitting(false);
      setUser({ ...user, ...response });
      makeToast("success", t("ProfilePatient.SuccessUpdate"));
      closeModal();
    } catch (error) {
      console.error("[error] Signin - onSubmit :", error);
      setSubmitting(false);
    }
  };
  const onSubmitSoSContact = async (values) => {
    try {
      const data = {
        sosContact: JSON.stringify(values),
        id: user?._id,
      };
      const response = await editPatient(data);
      setUser({ ...user, ...response });
      makeToast("success", t("ProfilePatient.SuccessUpdate"));
      closeModal();
    } catch (error) {
      console.error("[error] Signin - onSubmit :", error);
    }
  };

  const _getRegion = async () => {
    try {
      fetch("https://geo.api.gouv.fr/regions")
        .then((response) => response.json())
        .then((data) =>
          setRegionState(
            data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
          )
        );
    } catch (error) {}
  };
  const _getDepartementState = async () => {
    try {
      fetch(
        `https://geo.api.gouv.fr/regions/${selectedRegionState?.code}/departements`
      )
        .then((response) => response.json())
        .then((data) =>
          setDepartementState(
            data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
          )
        );
    } catch (error) {}
  };

  const _getCommunesState = async () => {
    try {
      fetch(
        `https://geo.api.gouv.fr/departements/${selectedDepartementState?.code}/communes`
      )
        .then((response) => response.json())
        .then((data) =>
          setCommunesState(
            data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
          )
        );
    } catch (error) {}
  };

  // const _getDepartements = async () => {
  //   try {
  //     fetch("https://geo.api.gouv.fr/departements")
  //       .then((response) => response.json())
  //       .then((data) =>
  //         setDepartementState(
  //           data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
  //         )
  //       );
  //   } catch (error) {}
  // };

  // const _getCommunes = async () => {
  //   try {
  //     fetch("https://geo.api.gouv.fr/communes")
  //       .then((response) => response.json())
  //       .then((data) =>
  //         setCommunesState(
  //           data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
  //         )
  //       );
  //   } catch (error) {}
  // };

  // Init
  useEffect(() => {
    _getRegion();
    // if (user?.addressDetails?.departement) _getDepartements();
    // if (user?.addressDetails?.commune) _getCommunes();
  }, []);

  // Init
  useEffect(() => {
    if (selectedRegionState?.code) _getDepartementState();
  }, [selectedRegionState]);

  // Init
  useEffect(() => {
    if (selectedDepartementState?.code) _getCommunesState();
  }, [selectedDepartementState]);

  useEffect(() => {
    if (document.getElementById("business_photo")) {
      // const getInputFile    = document.getElementById("business_photo") as HTMLInputElement;
      // getInputFile.innerHTML = "Sélectionnez une image"
    }
  }, []);

  // Init
  useEffect(() => {
    if (villeTnState) {
      setDelegationTn(
        countriesTn[villeTnState].map((el) => ({
          ...el,
          value: el.delegation,
          label: el.delegation,
        }))
      );
    }
  }, [villeTnState]);

  return (
    <div>
      <div className="flex justify-end">
        <div className="close-modal cursor-pointer">
          <Icon isIcon={true} name="close" onClick={closeModal} />
        </div>
      </div>
      <div className="m-auto px-10">
        <h1 className="text-center mb-5">
          {t("ProfilePatient.EditMyInformation")}
        </h1>

        <Tabs
          className="m-auto"
          menu={[
            { name: t("ProfilePatient.Identity") },
            { name: t("ProfilePatient.InCaseOfEmergency") },
          ]}
          tabIndex={(i) => setTabIndex(i)}
        />
        {tabsIndex === 0 ? (
          <Formik
            initialValues={{
              firstName: user?.firstName || "",
              lastName: user?.lastName || "",
              numSecSocial: user?.numSecSocial || "",
              sexe: user?.sexe || "man",
              birthDate: user?.birthDate ? new Date(user.birthDate) : "",
              nationality: user?.nationality || "",
              phone: user?.phone || "",
              address: user?.address || "",
              postalCode: user?.postalCode,
              city: user?.city || "",
              phoneSuffixe: user?.phoneSuffixe || "",
              addressDetails: "",
            }}
            onSubmit={onSubmit}
            validate={(values) => {}}
            // validationSchema={validationSchema}
          >
            {(props) => {
              const {
                values,
                // errors,
                isSubmitting,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
              } = props;
              return (
                <form
                  onSubmit={handleSubmit}
                  className="form-update-data-patient mt-10"
                >
                  <Input
                    id="firstName"
                    placeholder={t("ProfilePatient.FirstName")}
                    labelText={t("ProfilePatient.FirstName")}
                    type="text"
                    // error={errors.firstName}
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full mb-2.5 rounded-lg border-gray-200 outline-none"
                    inlineLabel={false}
                    // onfocus={true}
                  />
                  <Input
                    id="lastName"
                    placeholder={t("ProfilePatient.LastName")}
                    labelText={t("ProfilePatient.LastName")}
                    type="text"
                    // error={errors.lastName}
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full mb-2.5 rounded-lg border-gray-200 outline-none"
                    inlineLabel={false}
                    // onfocus={true}
                  />
                  <Input
                    id="numSecSocial"
                    labelText={t("ProfilePatient.SocialSecurityNumber")}
                    type="text"
                    value={values.numSecSocial}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full mb-2.5 rounded-lg border-gray-200 outline-none"
                    inlineLabel={false}
                  />
                  <Select
                    id="sexe"
                    options={[
                      { label: t("ProfilePatient.Male"), value: "man" },
                      { label: t("ProfilePatient.Feminine"), value: "woman" },
                    ]}
                    placeholder="Sexe"
                    // error={errors.sexe}

                    value={values.sexe}
                    setText={false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full mb-2.5 rounded-lg border-gray-200 outline-none px-3"
                  />
                  {/* <Input
                    id="birthDate"
                    placeholder="Date de naissance"
                    labelText="Date de naissance"
                    type="date"
                    // error={errors.birthdate}
                    value={values.birthDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full mb-2.5 rounded-lg border-gray-200 outline-none"
                    inlineLabel={false}
                    // onfocus={true}
                  /> */}
                  <DatePicker
                    id="birthDate"
                    locale="fr"
                    className="custom-Input  mb-2.5 rounded-lg px-3 border border-gray-200 w-full h-14"
                    selected={values.birthDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(e) => setFieldValue("birthDate", e)}
                    placeholderText={t("ProfilePatient.birthdate")}
                    autoComplete="off"
                    showYearDropdown
                  />
                  <Input
                    id="nationality"
                    placeholder={t("ProfilePatient.Nationality")}
                    labelText={t("ProfilePatient.Nationality")}
                    type="text"
                    // error={errors.nationality}
                    value={values.nationality}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full mb-2.5 rounded-lg border-gray-200 outline-none"
                    inlineLabel={false}
                    // onfocus={true}
                  />
                  <Input
                    id="phone"
                    placeholder={t("ProfilePatient.Nationality")}
                    labelText={t("ProfilePatient.Nationality")}
                    type="text"
                    // error={errors.phone}
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full mb-2.5 rounded-lg border-gray-200 outline-none"
                    inlineLabel={false}
                    // onfocus={true}
                  />
                  <Select
                    id="phoneSuffixe"
                    options={phoneSuffix.map((el) => ({
                      label: el.name + " " + el.dial_code,
                      value: el.dial_code,
                    }))}
                    placeholder={t("ProfilePatient.PhonePrefix")}
                    // error={errors.sexe}

                    value={values.phoneSuffixe}
                    setText={false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full mb-2.5 rounded-lg border-gray-200 outline-none px-3"
                  />

                  {/* {values.phoneSuffixe === "+33" && (
                    <>
                      <Select
                        className="w-full mb-2.5 rounded-lg border-gray-200 outline-none px-3"
                        labelProps={{ className: "w-40" }}
                        value={
                          user?.addressDetails?.region
                            ? user?.addressDetails?.region
                            : selectedRegionState
                            ? selectedRegionState.value
                            : ""
                        }
                        options={regionState}
                        text="Saisissez votre region"
                        onChange={(e) => {
                          setSelectedRegionState(
                            regionState.find(
                              (elm) => elm.value == e.target.value
                            )
                          );
                        }}
                      />
                      {(user?.addressDetails?.departement ||
                        selectedRegionState?.value) && (
                        <Select
                          className="w-full mb-2.5 rounded-lg border-gray-200 outline-none px-3"
                          labelProps={{ className: "w-40" }}
                          text="Saisissez votre departement"
                          value={
                            user?.addressDetails?.departement
                              ? user?.addressDetails?.departement
                              : selectedDepartementState
                              ? selectedDepartementState.value
                              : ""
                          }
                          options={departementState}
                          onChange={(e) => {
                            setSelectedDepartementState(
                              departementState.find(
                                (elm) => elm.value == e.target.value
                              )
                            );
                          }}
                        />
                      )}

                      {(selectedDepartementState?.value ||
                        user?.addressDetails?.departement) && (
                        <Select
                          className="w-full mb-2.5 rounded-lg border-gray-200 outline-none px-3"
                          labelProps={{ className: "w-40" }}
                          value={
                            user?.addressDetails?.commune
                              ? user?.addressDetails?.commune
                              : selectedCommunesState
                              ? selectedCommunesState.value
                              : ""
                          }
                          text="Saisissez votre commune"
                          options={communesState}
                          onChange={(e) => {
                            setSelectedCommunesState(
                              communesState.find(
                                (elm) => elm.value == e.target.value
                              )
                            );
                          }}
                        />
                      )}
                    </>
                  )}
                  {values.phoneSuffixe === "+216" && (
                    <>
                      <Select
                        className="w-full mb-2.5 rounded-lg border-gray-200 outline-none px-3"
                        labelProps={{ className: "w-40" }}
                        value={villeTnState}
                        options={Object.keys(countriesTn).map((elm) => ({
                          label: elm,
                          value: elm,
                        }))}
                        text="Saisissez votre gouvernerat"
                        onChange={(e) => {
                          setVilleTnState(e.target.value);
                        }}
                      />

                      {villeTnState && (
                        <Select
                          className="w-full mb-2.5 rounded-lg border-gray-200 outline-none px-3"
                          labelProps={{ className: "w-40" }}
                          value={delegationTnState}
                          text="Saisissez votre délégation"
                          options={delegationTn}
                          onChange={(e) => {
                            setDelegationTnState(e.target.value);
                          }}
                        />
                      )}
                    </>
                  )} */}
                  <Input
                    id="address"
                    placeholder={t("ProfilePatient.Address")}
                    labelText={t("ProfilePatient.Address")}
                    type="text"
                    // error={errors.adress}
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full mb-2.5 rounded-lg border-gray-200 outline-none"
                    inlineLabel={false}
                    // onfocus={true}
                  />
                  <Input
                    id="postalCode"
                    placeholder={t("ProfilePatient.postalCode")}
                    labelText={t("ProfilePatient.postalCode")}
                    type="text"
                    // error={errors.postalCode}
                    value={values.postalCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full mb-2.5 rounded-lg border-gray-200 outline-none"
                    inlineLabel={false}
                    // onfocus={true}
                  />
                  <div className="flex space-x-3">
                    {/* <Input
                      id="city"
                      placeholder="Ville"
                      labelText="Ville"
                      type="text"
                      // error={errors.ville}
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="w-full mb-2.5 rounded-lg border-gray-200 outline-none"
                      inlineLabel={false}
                      // onfocus={true}
                    /> */}
                  </div>
                  <div className="flexCenter my-5">
                    <Button
                      width="170px"
                      height="50px"
                      type="submit"
                      disabled={isSubmitting}
                      onClick={() => handleSubmit()}
                    >
                      {" "}
                      {t("ProfilePatient.Save")}
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        ) : (
          <div className="mt-8">
            <p className="card-subtitle text-center w-64 m-auto">
              {t("ProfilePatient.trustedPerson")}
            </p>

            <Formik
              initialValues={{
                firstName: user?.sosContact?.firstName || "",
                lastName: user?.sosContact?.lastName || "",
                phone: user?.sosContact?.phone || "",
                phoneSuffix: user?.sosContact?.phoneSuffix || "",
              }}
              onSubmit={onSubmitSoSContact}
              // validationSchema={validationSchema}
            >
              {(props) => {
                const {
                  values,
                  // errors,
                  // isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props;

                return (
                  <>
                    <form
                      onSubmit={handleSubmit}
                      className="form-update-data-patient mt-10"
                    >
                      <Input
                        id="firstName"
                        placeholder={t("ProfilePatient.FirstName")}
                        labelText={t("ProfilePatient.FirstName")}
                        type="text"
                        // error={errors.firstName}
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="w-full mb-2.5 rounded-lg border-gray-200 outline-none"
                        inlineLabel={false}
                        // onfocus={true}
                      />
                      <Input
                        id="lastName"
                        placeholder={t("ProfilePatient.LastName")}
                        labelText={t("ProfilePatient.LastName")}
                        type="text"
                        // error={errors.lastName}
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="w-full mb-2.5 rounded-lg border-gray-200 outline-none"
                        inlineLabel={false}
                        // onfocus={true}
                      />
                      <Select
                        id="phoneSuffix"
                        options={phoneSuffix.map((el) => ({
                          label: el.name + " " + el.dial_code,
                          value: el.dial_code,
                        }))}
                        placeholder={t("ProfilePatient.PhonePrefix")}
                        // error={errors.sexe}

                        value={values.phoneSuffix}
                        setText={false}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="w-full mb-2.5 rounded-lg border-gray-200 outline-none px-3"
                      />
                      <Input
                        id="phone"
                        placeholder={t("ProfilePatient.PhoneNumber")}
                        labelText={t("ProfilePatient.PhoneNumber")}
                        type="text"
                        // error={errors.phone}
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="w-full mb-2.5 rounded-lg border-gray-200 outline-none"
                        inlineLabel={false}
                        // onfocus={true}
                      />

                      <div className="flexCenter my-5">
                        <Button
                          width="170px"
                          height="50px"
                          type="submit"
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          {t("ProfilePatient.Save")}
                        </Button>
                      </div>
                    </form>
                  </>
                );
              }}
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditMyDataModal;
