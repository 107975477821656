import { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { getClientSecret } from "../../api/patient";
import makeToast from "../Snackbar";
type Props = {
  user: any;
  handleNext: any;
  setbtnSubmitStatusLoading: any;
  btnSubmitStatusLoading: any;
};

const StripeForm = (props: Props) => {
  const {
    user,
    handleNext,
    // setbtnSubmitStatusLoading,
    btnSubmitStatusLoading,
  } = props;
  const { t } = useTranslation() as any;
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const stripeInputStyles = {
    base: {
      fontSize: "16px",
      color: "rgba(0, 0, 0, 0.65)",
      "::placeholder": {
        color: "#d9d9d9",
      },
    },
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const res = await getClientSecret();
      if (res.data) {
        if (!stripe || !elements) {
          return;
        }
        const card = elements.getElement(CardNumberElement) as any;

        const { error, setupIntent } = await stripe.confirmCardSetup(res.data, {
          payment_method: {
            card,
            billing_details: {
              email: user.email,
              name: user.firstName + user.lastName,
            },
          },
        });
        if (error) console.error(error);
        await handleNext(setupIntent);
      } else {
        makeToast("warning", t("MyDoctors.verifyInformation"));
      }
    } catch (err) {
      makeToast("warning", t("MyDoctors.SomethingWentWrong"));
    }
    setLoading(false);
  };
  return (
    <>
      <div className="payment-form flex my-3 justify-around ">
        <CardNumberElement
          className="border border-gray-200 rounded-xl w-60 px-5 py-2 "
          // placeholder="Numéro de la carte"
          options={{ style: stripeInputStyles }}
        />
        <CardExpiryElement
          className="border border-gray-200 rounded-xl w-40 px-5 py-2 "
          // placeholder="MM/YY"
          options={{ style: stripeInputStyles }}
        />

        <CardCvcElement
          className="border border-gray-200 rounded-xl w-24 px-5 py-2 "
          // placeholder="CVV"
          options={{ style: stripeInputStyles }}
        />
      </div>
      <div className="flexCenter ">
        <button
          className={classNames(
            "relative text-white w-52 h-12 rounded-2xl bg-blue-500"
            //   cardValue.num !== "" && cardValue.cvv !== "" && cardValue.date !== ""
            //     ? "bg-blue-500 "
            //     : "bg-gray-300 "
          )}
          onClick={handleSubmit}
          disabled={loading || btnSubmitStatusLoading}
        >
          {(loading || btnSubmitStatusLoading) && (
            <div className="absolute h-full w-full flexCenter">
              <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-purple-500"></div>
            </div>
          )}
          {t("ConfirmAppointment.Confirm appointment")}
        </button>
      </div>
    </>
  );
};

export default StripeForm;
