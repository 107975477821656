import React, { useEffect } from "react";
import { Line, Transformer } from "react-konva";

const RedactionComponent = ({
  index,
  data,
  listData,
  setListData,
  setSelectedInputId,
  selectedInputId,
  isSelected,
  setDataSelectedObj,
  color,
}) => {
  const shapeRef = React.useRef() as any;
  const trRef = React.useRef() as any;

  useEffect(() => {
    if (isSelected && data.id === selectedInputId) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, selectedInputId]);

  const onMoveText = (e) => {
    const node = shapeRef.current;
    const CopyOfData = listData;
    // eslint-disable-next-line eqeqeq
    let foundIndex = listData.findIndex((x) => x.id == data.id);
    CopyOfData[foundIndex] = {
      ...listData[foundIndex],
      y: node.getY(),
    };
    setListData([...CopyOfData]);
  };

  return (
    <>
      <Line
        dragBoundFunc={(pos) => {
          if (pos.y % 10 === 0) {
            return {
              x: 10,
              y: pos.y,
            };
          } else {
            let div = Math.round(pos.y / 10);
            return {
              x: 10,
              y: div * 10,
            };
          }
        }}
        x={10}
        y={data.y}
        draggable={true}
        onDragMove={(e) => onMoveText(e)}
        onClick={(e) => {
          //setIselected(true);
          setSelectedInputId(data.id);
          setDataSelectedObj(data);
        }}
        ref={shapeRef}
        stroke={color}
        width={200}
        tension={1}
        strokeWidth={5}
        points={[0, 0, 610, 0]}
      />
      {isSelected && selectedInputId === data.id && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

export default RedactionComponent;
