import Button from "../../../../components/ButtonUI";
import { useAtom } from "jotai";
import { colorAvatarAtom } from "../../../../state/constants";
import { ReactComponent as IconFolder } from "../../../../assets/icons/icon-piece-jointe-blanc.svg";
import { ReactComponent as IconMessage } from "../../../../assets/icons/icon-message.svg";
import { ReactComponent as IconCloseModal } from "../../../../assets/icons/icon-close.svg";

import { ReactComponent as WomanPatient } from "../../../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../../../assets/illustrations/illustration-homme-patient.svg";
import { loadImage } from "../../../../api/documents";
import { useTranslation } from "react-i18next";
import { handleSpeciality } from "../../../../helper/handleSpeciality";

interface Props {
  ToggleContent: any;
  closeModal: any;
  chatOrShareFolder: string;
  user: any;
  specialistDetails: any;
}

const HeaderModal = (props: Props) => {
  const { i18n } = useTranslation();
  const {
    ToggleContent,
    closeModal,
    chatOrShareFolder,
    // user,
    specialistDetails,
  } = props;
  const [colorAvatar] = useAtom<any>(colorAvatarAtom);

  return (
    <div className="flex justify-between p-1 ">
      <div className="flex flex-1 justify-between mr-5 bg-gray-100  rounded-2xl p-3">
        <div className="flex items-center space-x-5">
          {specialistDetails?.business_photo ? (
            <div className="avatarMedecinChat ">
              {specialistDetails?.business_photo ? (
                <img
                  crossOrigin="anonymous"
                  referrerPolicy="origin"
                  src={
                    specialistDetails?.business_photo.slice(0, 4) === "http"
                      ? specialistDetails?.business_photo
                      : loadImage(
                          specialistDetails?._id,
                          "profile",
                          "thumbnail",
                          specialistDetails?.business_photo
                        )
                  }
                  className="w-14 h-14 object-fill rounded-full shadow-md"
                  alt="profile"
                />
              ) : specialistDetails?.sexe === "man" ? (
                <ManPatient />
              ) : (
                <WomanPatient />
              )}
            </div>
          ) : (
            <div
              className="avatarMedecinChat"
              style={{ backgroundColor: colorAvatar }}
            >
              <ManPatient className="-mt-2" />
            </div>
          )}

          <div className="card-title">
            <span className="card-title capitalize">
              {specialistDetails.firstName} {specialistDetails.lastName}
            </span>
            <p className="card-subtitle ">
              {handleSpeciality(
                specialistDetails?.speciality,
                i18n.language.includes("en")
              )}
            </p>
          </div>
        </div>
        {chatOrShareFolder !== "chat" ? (
          <Button status="primary" className="p-2">
            <IconMessage
              style={{
                filter:
                  "invert(97%) sepia(9%) saturate(414%) hue-rotate(66deg) brightness(118%) contrast(100%)",
              }}
              onClick={ToggleContent}
            />
          </Button>
        ) : (
          <Button status="primary" className="p-2">
            <IconFolder
              style={{
                filter:
                  "invert(97%) sepia(9%) saturate(414%) hue-rotate(66deg) brightness(118%) contrast(100%)",
              }}
              onClick={ToggleContent}
            />
          </Button>
        )}
      </div>
      <div className="bg-gray-100 flex-none rounded-xl max-h-10 cursor-pointer">
        <IconCloseModal onClick={closeModal} />
      </div>
    </div>
  );
};

export default HeaderModal;
