import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import Button from "../../components/ButtonUI";

import pscImg from "../../assets/psc.png";
import { userAtom } from "../../state/auth";
import { useAtom } from "jotai";
import { User } from "../../types/user";
import AddPresentation from "./modal/AddPresentation";
import AddExpertise from "./modal/AddExpertise";
import Icon from "../../components/Icon";
import { deleteExpertise } from "../../api/doctor";
import makeToast from "../../components/Snackbar";
import Modal from "../../components/Modal";

const Description: React.FC = () => {
  const { t } = useTranslation() as any;
  const [openModal, setOpenModal] = useState(false);
  const [user, setUser] = useAtom(userAtom);
  const [openModalExpertise, setOpenModalExpertise] = useState(false);

  const deleteExpertiseFn = async (expertiseId, doctorId) => {
    const doctor = await deleteExpertise(expertiseId, doctorId);
    setUser({ ...user, expertises: doctor.doctor.expertises } as User);
    if (doctor.status === "Success") {
      makeToast("success", t("Profile.SuccessDeleteExpertise"));
    } else {
      makeToast("error", t("Profile.FailDeleteExpertise"));
    }
  };

  const responseType = "code";
  const scope = "openid%20scope_all";
  const clientId = "katomi-kore-bas";
  const redirect = "http://localhost:3000";
  const acr = "eidas1";

  const uri = `https://wallet.bas.psc.esante.gouv.fr/auth?response_type=${responseType}&scope=${scope}&client_id=${clientId}&redirect_uri=${redirect}&acr_values=${acr}`;

  //

  return (
    <div className="">
      <div className="psc">
        <div className="headerContainer flex items-center relative">
          <img src={pscImg} alt="psc" width={200} />
        </div>

        <div className="p-4 flexCenter h-full">
          {user?.psc?.id ? (
            <p> {t("Profile.connectedPsc")}</p>
          ) : (
            <a target="_blank" rel="noreferrer" href={uri}>
              <Button status="secondary" className="px-6 py-2">
                {t("Profile.connectPsc")}
              </Button>
            </a>
          )}
        </div>
      </div>
      <div className="PresentationBox pb-3 ">
        <div className="headerContainer flex items-center relative">
          <Icon isIcon={true} name="nurse" className="svgClass" />
          <p className="header">{t("Profile.Presentation")}</p>
          <p
            className="absolute right-0	modifier"
            onClick={() => setOpenModal(true)}
          >
            {t("Profile.Update")}
          </p>
        </div>
        <div className="textClassContainer ">
          <p className={`textClass ${user?.description ? "" : "flexCenter"}`}>
            {user?.description ? (
              user.description
            ) : (
              <span className="card-subtitle">
                {t("Profile.NoPresentation")}
              </span>
            )}
          </p>
        </div>
      </div>
      <div className="expertises">
        <div className="">
          <div className="headerContainer flex items-center">
            <Icon name="adn" isIcon={true} className="svgClass" />
            <p className="header"> {t("Profile.EXPERTISES")}</p>
          </div>
          <div className="flex items-center flex-wrap  gap-3 specailities pb-2">
            {user?.expertises &&
              user.expertises.map((expertise) => {
                return (
                  <div className="relative box-expertise flexColCenter px-2">
                    <p>
                      {expertise.expertise}{" "}
                      <span
                        className="ml-2 cursor-pointer hover:text-red-500"
                        onClick={() =>
                          deleteExpertiseFn(expertise?._id, user._id)
                        }
                      >
                        X
                      </span>
                    </p>
                  </div>
                );
              })}
            <Button
              status="secondary"
              width="103px"
              height="35px"
              onClick={() => setOpenModalExpertise(true)}
            >
              {t("Profile.Add")}
            </Button>
          </div>
        </div>
      </div>

      <Modal closeModal={() => setOpenModal(false)} showModal={openModal}>
        <div className="card-presentation">
          <AddPresentation openModal={openModal} setOpenModal={setOpenModal} />
        </div>
      </Modal>
      <Modal
        closeModal={() => setOpenModalExpertise(false)}
        showModal={openModalExpertise}
      >
        <div className="card-presentation">
          <AddExpertise
            openModal={openModalExpertise}
            setOpenModal={setOpenModalExpertise}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Description;
