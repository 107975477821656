import history from "../../history";
import smile from "../../assets/images/smileSick.png";
import tetoScope from "../../assets/images/tetoScope.png";
import { useTranslation } from "react-i18next";
import "./styles.scss";

// const lngs = {
//   en: { nativeName: "English" },
//   fr: { nativeName: "France" },
// };
const Signin: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="auth-check-Container min-h-screen w-full ">
      <div className="absolute inset-0 katomiWterMark" />
      <div className="min-h-screen w-full flexColCenter  ">
        <div className="flexCenter mb-5">
          <div
            className="role flexColCenter cursor-pointer"
            onClick={() =>
              history.push({
                pathname: "/patient/login",
                state: "PATIENT",
              })
            }
          >
            <div className="icon-container">
              <img
                crossOrigin="anonymous"
                referrerPolicy="origin"
                src={smile}
                alt="smileSck"
              />
            </div>
            <div className="title-container flexColCenter">
              <p>{t("AuthentificationCheck.im")}</p>
              <span className="flexRowCenter text-black font-black text-center	text-sm md:text-xl lg:text-xl">
                {t("AuthentificationCheck.patient")}
              </span>
            </div>
          </div>
          <div
            className="role flexColCenter cursor-pointer"
            onClick={() =>
              history.push({ pathname: "/login", state: "DOCTOR" })
            }
          >
            <div className="icon-container">
              <img
                crossOrigin="anonymous"
                referrerPolicy="origin"
                src={tetoScope}
                alt="smileSck"
                style={{ width: "42px", height: "42px" }}
              />
            </div>
            <div className="title-container flexColCenter">
              <p>{t("AuthentificationCheck.im")}</p>
              <span className="flexRowCenter text-black font-black text-center	sm:text-sm md:text-xl lg:text-xl">
                {t("AuthentificationCheck.doctor")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
