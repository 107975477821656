import { useState } from "react";
import Input from "../../components/Input";
import Button from "../../components/ButtonUI";

interface Props {
  defaultDays?;
}

const Index = (props: Props) => {
  const [days, setDays] = useState([]) as any;

  const handleChange = (e) => {
    if ([...days].find((el) => el === e.target.value))
      setDays([...days].filter((el) => el !== e.target.value));
    else setDays([...days, e.target.value]);
  };

  const handleSubmit = () => {
    //TODO update worker days
  };
  return (
    <div>
      {/* <Formik
        initialValues={{
          days: [],
        }}
        onSubmit={async (values) => {
          try {
          } catch (err) {

          }
        }}
      >
        {(props) => {
          const {
            values,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;

          return (
            <form
              onSubmit={handleSubmit}
              className="flex-auto py-7 px-8 min-w-full	"
            > */}
      <div className="flex flex-wrap items-baseline">
        <h1 className="mb-10">Ajouter vos jours de travail</h1>
      </div>
      <div className="ml-8 mb-8">
        <Input
          name="days"
          type="checkbox"
          onChange={handleChange}
          value="Lundi"
          labelText="Lundi"
          labelProps={{ className: "check  ml-2" }}
          checked={days.find((e) => e === "Lundi") ? true : false}
          className="my-2"
        />

        <Input
          name="days"
          type="checkbox"
          onChange={handleChange}
          value="Mardi"
          labelText="Mardi"
          labelProps={{ className: "check ml-2" }}
          checked={days.find((e) => e === "Mardi") ? true : false}
          className="my-2"
        />

        <Input
          name="days"
          type="checkbox"
          onChange={handleChange}
          value="Mercredi"
          labelText="Mercredi"
          labelProps={{ className: "check ml-2" }}
          checked={days.find((e) => e === "Mercredi") ? true : false}
          className="my-2"
        />

        <Input
          name="days"
          type="checkbox"
          onChange={handleChange}
          value="Jeudi"
          labelText="Jeudi"
          labelProps={{ className: "check ml-2" }}
          checked={days.find((e) => e === "Jeudi") ? true : false}
          className="my-2"
        />

        <Input
          name="days"
          type="checkbox"
          onChange={handleChange}
          value="Vendredi"
          labelText="Vendredi"
          labelProps={{ className: "check ml-2" }}
          checked={days.find((e) => e === "Vendredi") ? true : false}
          className="my-2"
        />

        <Input
          name="days"
          type="checkbox"
          onChange={handleChange}
          value="samedi"
          labelText="samedi"
          labelProps={{ className: "check ml-2" }}
          checked={days.find((e) => e === "samedi") ? true : false}
          className="my-2"
        />

        <Input
          name="days"
          type="checkbox"
          onChange={handleChange}
          value="Dimdanche"
          labelText="Dimdanche"
          labelProps={{ className: "check ml-2" }}
          checked={days.find((e) => e === "Dimdanche") ? true : false}
          className="my-2"
        />
      </div>

      <Button
        status="secondary"
        width="100px"
        height="50px"
        className="float-right mb-4"
        onClick={handleSubmit}
      >
        Valider
      </Button>
      {/* </form>
          );
        }}
      </Formik> */}
    </div>
  );
};

export default Index;
