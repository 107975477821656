import React from "react";
import Button from "../../../components/ButtonUI";

import Icon from "../../../components/Icon";
import moment from "../../../helper/moment";

interface Props {
  openModal: boolean;
  setOpenModal: Function;
}

const Transmettre: React.FC<Props> = ({ openModal, setOpenModal }) => {
  return (
    <div className="absolute flexCenter  z-40 transition ease-in duration-700 patient-Transmettre">
      <div className="p-5">
        <div className="flex">
          <h1 className="m-auto">Transmettre mon document</h1>
          <div className="float-right">
            <Icon
              isIcon={true}
              name="close"
              onClick={() => setOpenModal(false)}
            />
          </div>
        </div>
        {[1, 2, 3, 4, 5].map((el) => (
          <div className="flex items-center justify-between space-x-10 shadow-xs p-3 m-5">
            <div className="flex items-center space-x-7">
              <div className="rounded-lg bg-gray-300 h-28 w-28">
                {/* <img crossOrigin = "anonymous"  referrerPolicy="origin" src={WomanDoctor} alt="doctor avatar" /> */}
              </div>
              <div className="flex flex-col space-y-4">
                <div className="flex flex-col">
                  <span className="card-title">Benoit HERMAN</span>
                  <span className="card-subtitle">Chirurgien dentiste</span>
                </div>
                <span className="box">Consultation dentaire</span>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex card-subtitle justify-between items-center">
                <span> {moment().format("DD MMMM")}</span>
                <h3>|</h3>
                <span> {moment().format("HH:mm")}</span>
              </div>

              <Button
                status="secondary"
                width="173.9px"
                height="50px"
                //onClick={toggleMyDocumentSlideModal}
              >
                Voir les documents
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Transmettre;
