export const Chat = {
  fr: {
    IMAGES: "IMAGES",
    REPORT: "COMPTE RENDU",
    ReportAt: "Consultation du : ",
    UpdatedAt: "Mise à jour le",
    PRESCRIPTION: "PRESCRIPTION",
    MEDICALANTECEDENTS: "ANTÉCÉDENTS MÉDICAUX",
    Observations: "Observations",
    AddObservation: "+  Ajouter une observation",
    Add: "Ajouter",
    SURGICALANTECEDENTS: "ANTÉCÉDENTS CHIRURGICAUX",
    TREATMENTS: "TRAITEMENTS",
    at: "à",
    ANCIENTTREATMENTS: "Traitements anciens",
    SocialSecurityNumber: "Numéro de securité social :",
    Envoyer: "Envoyer",
    TypeMsg: "Tapez ici votre message...",
  },
  en: {
    IMAGES: "IMAGES",
    REPORT: "REPORT",
    ReportAt: "Report at : ",
    UpdatedAt: "Updated at",
    PRESCRIPTION: "PRESCRIPTION",
    MEDICALANTECEDENTS: "MEDICAL ANTECEDENTS",
    Observations: "Observations",
    AddObservation: "+  Add observation",
    Add: "Add",
    SURGICALANTECEDENTS: "SURGICAL ANTECEDENTS",
    TREATMENTS: "TREATMENTS",
    at: "at",
    ANCIENTTREATMENTS: "ANCIENT TREATMENTS",
    SocialSecurityNumber: "Social security number :",
    Envoyer: "Send",
    TypeMsg: "Type your message here...",
  },
};
