import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import Button from "../../../components/ButtonUI";
import { ReactComponent as Close } from "../../../assets/icons/icon-close.svg";
import { useDropzone } from "react-dropzone";
import { addDocsByPatient } from "../../../api/documents";
import { me } from "../../../api/patient";
import { userAtom } from "../../../state/auth";
import { useAtom } from "jotai";
import { User } from "../../../types/user";
import makeToast from "../../../components/Snackbar";

interface Props {
  openModal: boolean;
  setOpenModal: Function;
  type: string;
  patientIdByDoctor?: string;
  isDoctor?: boolean;
  setDocumentsList?: Function;
  documentsList?: [] | any;
}

const AddDocuments: React.FC<Props> = ({
  openModal,
  setOpenModal,
  type,
  patientIdByDoctor,
  isDoctor,
  setDocumentsList,
  documentsList,
}) => {
  const { t } = useTranslation() as any;
  const [files, setFiles] = useState<any>([]);
  const [user, setUser] = useAtom(userAtom);
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ) as any
      );
    },
  });
  // const renderImages = (list) => {
  //   return list.map((e) => {
  //     return (
  //       <div>
  //         <img crossOrigin = "anonymous"  referrerPolicy="origin"
  //           src={e.url}
  //           alt="..."
  //           className="shadow-lg  w-auto max-h-16		m-2 align-middle border-none"
  //         />
  //       </div>
  //     );
  //   });
  // };
  const thumbs = files.map((file) => (
    <div className="thumb" key={file?.name as any}>
      <div className="thumbInner">
        <img
          crossOrigin="anonymous"
          referrerPolicy="origin"
          src={file.preview}
          alt="img drop"
          className="img"
        />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const _getMe = async () => {
    try {
      const result = await me();
      return result;
    } catch (error) {}
  };

  const onSubmit = async () => {
    if (isDoctor !== undefined && isDoctor && user?.role !== "PATIENT") {
      const response = await addDocsByPatient({
        files,
        patientId: patientIdByDoctor,
        doctorId: user?.role === "DOCTOR" ? user?._id : null,
        type,
      });
      if (response && setDocumentsList) {
        setLoadingBtn(false);
        setDocumentsList([...documentsList, response.documents]);
        makeToast("success", t("MyFolder.SuccessAddPicture"));
      }
    } else {
      const response = await addDocsByPatient({
        files,
        patientId: user?._id && user?._id,
        type,
      });
      const { patient } = response;
      if (patient) {
        setLoadingBtn(false);
        makeToast("success", t("MyFolder.SuccessAddPicture"));
      }
      const me = await _getMe();

      setUser({ ...user, ...me } as User);
    }
    setOpenModal(!openModal);
  };

  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute bg-white z-40 top-1/3 flexCente addDocs p-2">
        <div className="flex justify-end">
          <Button status="icon-secondary" onClick={() => setOpenModal(false)}>
            <Close />
          </Button>
        </div>

        <h1>{t("MyFolder.AddDoc")}</h1>
        <section className="container">
          <div
            {...getRootProps({
              className: "dropzone flexCenter cursor-pointer",
            })}
          >
            <input {...getInputProps()} />
            <p>{t("MyFolder.DragAndDrop")}</p>
          </div>
          <aside className="thumbsContainer gap-4">{thumbs}</aside>
        </section>
        <div className="w-full mt-4">
          <Button
            status="primary"
            className="float-right flexCenter"
            width="200px"
            height="50px"
            type="submit"
            disabled={loadingBtn}
            onClick={() => {
              setLoadingBtn(true);
              onSubmit();
            }}
          >
            {loadingBtn ? (
              <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-purple-500"></div>
            ) : (
              t("MyFolder.Save")
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddDocuments;
