import axios from "axios";
import { GET_DOCTORS_3D_MODELS } from "../config/api";

export const getDoctorsModels = async (
  params:
    | {
        patient: string;
        status: "uploading" | "in_progress" | "ready" | "failed";
      }
    | {} = {}
) => {
  const { patient, status } = params as any;

  let url = GET_DOCTORS_3D_MODELS;

  const queryItems: string[] = [];

  if (patient) queryItems.push(`patient=${patient}`);
  if (status) queryItems.push(`status=${status}`);

  if (queryItems.length > 0) {
    url += "?";
    url += queryItems.join("&");
  }

  try {
    const res = await axios.get(url, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
