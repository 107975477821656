export const TemplatePDF = {
  fr: {
    NotAvailbleForMobile: "Ce module est indisponible sur la version mobile.",
    setName: "Attribuer un nom et une catégorie à votre template.",
    Ok: "Ok",
    Create: "Créer",
    fillData: "Vous devez remplir les champs avant l'enregistrement",
    fillTemplateName: "Entrer le nom du votre template",
    selectCategorie: "Sélectionner une catégorie",
    nameTemplate: "Nom du template",
    MyTemplates: "Mes templates",
    NewTemplate: "Nouveau template",
    NoTemplateFound: "Aucun template personnalisé trouvé.",
    Prescription: "Prescription",
    Report: "Compte Rendu",
    Other: "Autres",
    EditAndSave: "Modifier et enregistrer",
    Preview: "Prévisualiser",
    Save: "✓ Enregistrer",
    Writing: "Rédaction du compte rendu ou préscréption",
    EditProperties: "Éditer les propriétés",
    EditImageOpacity: "Changer l'opacité de l'image :",
    Size: "Taille",
    selectHeight: "Selectionnez la taille",
    Character: "Caractère ( bold , italique ...)",
    Color: "Couleur",
    SelectSize: "Sélectionner la taille",
    RepeatInAllText: "Répéter ce texte dans toutes les pages",
    MyInformation: "Mes informations",
    InformationPatient: "Informations Patient",
    FillRequireInput: "Vous devez remplir les champs avant l'enregistrement",
    SuccessSaveTemplate: "Votre nouvelle template à été bien enregistrer",
    misplacedQR: "L'emplacement du Code QR vas entrainer un rendu mal placé.",

    Toolbox: "Boîte à outils",
    LimitUpload: "Vous avez dépasser le nombre limite des photos.",
    LimitSize: "La taille de l'image ne doit pas dépasser 50 Kb.",


  },
  en: {
    NotAvailbleForMobile: "This module is not available on the mobile version.",
    setName: "Assign a name and a category to your template.",
    Ok: "Ok",
    Create: "Create",
    fillData: "You must fill in the fields before registration",
    fillTemplateName: "Enter the name of your template",
    selectCategorie: "Select a category",
    nameTemplate: "Template name",
    MyTemplates: "My templates",
    NewTemplate: "New template",
    NoTemplateFound: "No custom templates found.",
    Prescription: "Préscription",
    Report: "Report",
    Other: "Others",
    EditAndSave: "Edit and save",
    Preview: "Preview",
    Save: "✓ Save",
    Writing: "Writing new report or prescreption",
    EditProperties: "Edit properties",
    EditImageOpacity: "Change the opacity of the image :",
    Size: "Size",
    SelectSize: "Select size",
    selectHeight: "Select size",
    Character: "Character ( bold , italic ...)",
    Color: "Color",
    RepeatInAllText: "Repeat this text on all pages",
    MyInformation: "My information",
    InformationPatient: "Patient Information",

    FillRequireInput: "You must fill in the fields before saving",
    SuccessSaveTemplate: "Your new template has been successfully saved",
    misplacedQR: "The location of the QR Code will cause misplaced rendering.",

    Toolbox: "Toolbox",
    LimitUpload: "You have exceeded the photo limit.",
    LimitSize: "Image size should not exceed 50 Kb.",
  },
};
