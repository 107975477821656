import makeToast from "../../../components/Snackbar";
import _ from "lodash";

export default class CPSApi {
  endpoint: string = "";

  constructor(devboxApiEndpoint: string) {
    this.endpoint = devboxApiEndpoint;
  }

  async readCard() {
    try {
      const res = await fetch(`${this.endpoint}/cps/cardInfo`, {
        headers: {
          "Content-Type": "application/json",
          "insi.lpsInfo.numeroAutorisation": "KRK17IM22JW0",
          "insi.lpsInfo.nom": "KORE",
          "insi.lpsInfo.version": "1.4",
        },
      });

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      if (!data) throw new Error("expected json. got different content");

      if (!res.ok) {
        const error = (data && data.message) || res.status;
        throw new Error(error);
      }

      return data;
    } catch (error) {
      console.log("[debug read card cps]", error);
      return Promise.reject(error);
    }
  }

  async login(pin) {
    try {
      const res = await fetch(`${this.endpoint}/cps/login?pin=${pin}`, {
        headers: {
          "Content-Type": "application/json",
          "insi.lpsInfo.numeroAutorisation": "KRK17IM22JW0",
          "insi.lpsInfo.nom": "KORE",
          "insi.lpsInfo.version": "1.4",
        },
      });

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      if (!data) throw new Error("expected json. got different content");

      if (!res.ok) {
        const error = (data && data.message) || res.status;
        throw new Error(error);
      }

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async signPdf(payload) {
    try {
      const res = await fetch(
        `${this.endpoint}/cps/signPdf?location=bas&reason=signature`,
        {
          method: "POST",
          body: payload,
          headers: {
            "Content-Type": "application/json",
            "insi.lpsInfo.numeroAutorisation": "KRK17IM22JW0",
            "insi.lpsInfo.nom": "KORE",
            "insi.lpsInfo.version": "1.4",
          },
        }
      );

      const blob = await res.blob();

      return blob;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async readerInfo(showAlert?, localEn = false) {
    try {
      const res = await fetch(`${this.endpoint}/cps/readersInfo`, {
        headers: {
          "Content-Type": "application/json",
          "insi.lpsInfo.numeroAutorisation": "KRK17IM22JW0",
          "insi.lpsInfo.nom": "KORE",
          "insi.lpsInfo.version": "1.4",
        },
      });

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      if (!data) throw new Error("expected json. got different content");

      if (!res.ok) {
        const error = (data && data.message) || res.status;
        throw new Error(error);
      }
      if (_.isEmpty(data)) {
        !showAlert &&
          makeToast(
            "warning",
            localEn
              ? "Please make sure that DevBox is running on your machine."
              : "Veuillez faire en sorte que DevBox soit en cours d'exécution sur votre machine."
          );
        return false;
      } else if (!data[0].cardPresent) {
        !showAlert &&
          makeToast(
            "warning",
            localEn
              ? "Please insert your CPS card"
              : "Veuillez inserer votre carte CPS"
          );
        return false;
      }
      return true;
    } catch (error) {
      !showAlert &&
        makeToast(
          "warning",
          localEn
            ? "Please make sure that DevBox is running on your machine, and your CPS card is inserted. "
            : "Veuillez faire en sorte que DevBox soit en cours d'exécution sur votre machine, et votre carte CPS soit inserer."
        );
      return false;
    }
  }
}
