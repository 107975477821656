/* eslint-disable eqeqeq */
/* eslint-disable no-new-object */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import { useEffect, useState } from "react";
import { Formik } from "formik";
import Button from "../ButtonUI";
import Input from "../Input";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import Select from "../Select";
import moment from "../../helper/moment";
import _ from "lodash";
// import validationSchema from "./validationIns";
import { INS as apiINS } from "../../api/devbox";
import { updatePatientINSData } from "../../api/doctor";
import makeToast from "../Snackbar";
import { addNewLogs } from "../../api/logs";
import { userAtom } from "../../state/auth";
import { useAtom } from "jotai";
import { cpsDataAtom } from "../../state/cardCpsData";
import Tooltip from "../toolTip";
const INS = ({ patientData, setPatientData }) => {
  const { t, i18n } = useTranslation() as any;
  const [user] = useAtom(userAtom);
  const [cpsData] = useAtom(cpsDataAtom) as any;
  const [cpsCardInfo] = useAtom(cpsDataAtom);
  const [regionState, setRegionState] = useState([]) as any;
  const [selectedRegionState, setSelectedRegionState] = useState() as any;
  const [startDateINS, setStartDateINS] = useState() as any;
  const [departementState, setDepartementState] = useState([]) as any;
  const [selectedDepartementState, setSelectedDepartementState] =
    useState() as any;
  const [communesState, setCommunesState] = useState([]) as any;
  const [patientInfos, setPatientInfos] = useState(patientData) as any;
  const [loading, setloading] = useState(false);
  const statusINS = (identity, INSI) => {
    if (identity === "IDDoting" || identity === "Idfictional")
      return "IdProvisional";
    else if (identity === "idvalid" && !INSI) return "IdValidated";
    else if (identity === "idvalid" && INSI) return "IdQualified";
    else if (identity !== "idvalid" && INSI) return "IdRecovered";
    else if (identity !== "idvalid" && !INSI) return "IdProvisional";
    else return "IdProvisional";
  };

  const dropdownSexeOptions = [
    { value: "M", label: "M" },
    { value: "F", label: "F" },
  ];

  const statusLabels = {
    ["IdProvisional"]: "Identité provisoire",
    ["IdRecovered"]: "Identité récuperée",
    ["IdValidated"]: "Identité validée",
    ["IdQualified"]: "Identité qualifiée",
  };
  const statusLabelsEN = {
    ["IdProvisional"]: "Provisional identity",
    ["IdRecovered"]: "Identity recovered",
    ["IdValidated"]: "Validated identity",
    ["IdQualified"]: "Qualified identity",
  };
  const statusColors = {
    ["IdProvisional"]: "INS-provisoire",
    ["IdRecovered"]: "INS-recovered",
    ["IdValidated"]: "INS-validated",
    ["IdQualified"]: "INS-qualivied",
  };

  const INSType = {
    "1.2.250.1.213.1.4.2": "INS_C",
    "1.2.250.1.213.1.4.7": "INS_C_DEMO",
    "1.2.250.1.213.1.4.6": "INS_C_TEST",
    "1.2.250.1.213.1.4.9": "INS_NIA",
    "1.2.250.1.213.1.4.8": "INS_NIR",
    "1.2.250.1.213.1.4.11": "INS_NIR_DEMO",
    "1.2.250.1.213.1.4.10": "INS_NIR_TEST",
  };

  const _getRegion = async () => {
    try {
      fetch("https://geo.api.gouv.fr/regions")
        .then((response) => response.json())
        .then((data) =>
          setRegionState(
            data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
          )
        );
    } catch (error) {}
  };
  const _getDepartementState = async () => {
    try {
      fetch(
        `https://geo.api.gouv.fr/regions/${selectedRegionState?.code}/departements`
      )
        .then((response) => response.json())
        .then((data) =>
          setDepartementState(
            data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
          )
        );
    } catch (error) {}
  };
  const _getCommunesState = async () => {
    try {
      fetch(
        `https://geo.api.gouv.fr/departements/${selectedDepartementState?.code}/communes`
      )
        .then((response) => response.json())
        .then((data) =>
          setCommunesState(
            data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
          )
        );
    } catch (error) {}
  };

  // Init
  useEffect(() => {
    _getRegion();
  }, []);

  // Init
  useEffect(() => {
    if (selectedRegionState?.code) _getDepartementState();
  }, [selectedRegionState]);

  // Init
  useEffect(() => {
    if (selectedDepartementState?.code) _getCommunesState();
  }, [selectedDepartementState]);

  const defaultInsValue = {
    dateNaissance: new Date(),
    lieuNaissance: "",
    nomNaissance: "",
    prenom: "",
    sexe: "",
    prenoms: [],
    IDDoting: false,
    Idfictional: false,
    identity: "idvalid",
  };
  const verifyIns = async (payload) => {
    try {
      const res = (await apiINS.verification(payload)) as any;
      if (res && res?.compteRendu?.valeur === "00") {
        return true;
      } else return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  //  INS Statut (identité provisoire || identité récuperée || identité validée || identité qualifiée )
  // let { INS } = patientData;
  const prepareInsObject = (values, validINS, reponseINS = {} as any) => {
    const insObject = new Object();
    insObject["INSStaus"] = statusINS(values.identity, validINS);
    insObject["INSValue"] = {
      ...values,
      identifiantSysteme: INSType[reponseINS?.insActif?.identifiantSysteme],
    };
    if (validINS) {
      insObject["responseINS"] = { ...reponseINS };

      return insObject;
    } else return insObject;
  };

  // const getCpsData = async () => {
  //   if (!cpsData) {
  //     const cpsInfo = await CPS.readCard();
  //     setCpsData(cpsInfo);
  //   }
  // };
  // useEffect(() => {
  //   getCpsData();
  // }, []);
  useEffect(() => {
    if (
      // cpsData &&
      patientData?.INS?.responseINS &&
      (!patientData?.INS?.lastVerifiedDate ||
        moment(patientData?.INS?.lastVerifiedDate)
          ?.add("days", 7)
          ?.isBefore(moment())) &&
      INSType[patientData.INS?.responseINS?.insActif?.identifiantSysteme] ===
        "INS_NIA"
    ) {
      const verifInsObject = {
        ins: {
          traitsIdentite: patientData.INS?.responseINS?.traitsIdentite,
          matriculeINS: {
            valeur: patientData.INS?.responseINS?.insActif?.valeur,
            cle: patientData.INS?.responseINS?.insActif?.cle,
            identifiantSysteme:
              patientData.INS?.responseINS?.insActif?.identifiantSysteme,
          },
        },
        context: {
          structure: {
            identifiantFacturation: user?.idFacturationCps,
          },
          auteur: {},
        },
      };
      const verificationResult = verifyIns(verifInsObject);

      if (!verificationResult) {
        updatePatientINSData({
          patientId: patientData._id,
          INS: prepareInsObject(
            patientData.INS?.INSValue,
            false,
            patientData?.INS?.responseINS
          ),
        });
      }
    }
  }, [cpsData]);

  const prepareLogsObject = (oldPayload, payload, response) => {
    try {
      // eslint-disable-next-line no-new-object
      const logsObject = new Object();

      logsObject["date"] = new Date();
      logsObject["type"] =
        response && !(response == null) ? "READ_WITHOUT_CARD" : "FAIL";
      logsObject["doctorId"] = user?._id;
      logsObject["patientId"] = patientData._id;
      logsObject["infoCardUsed"] = cpsCardInfo;
      logsObject["oldBody"] = oldPayload;
      logsObject["body"] = payload;
      logsObject["resposnse"] = response;

      return logsObject;
    } catch (error) {
      console.error(error);
    }
  };

  const handleVerifyINS = async (values) => {
    const {
      matriculeINS,
      Idfictional,
      IDDoting,
      prenoms,
      sexe,
      prenom,
      nomNaissance,
      lieuNaissance,
      dateNaissance,
    } = values;

    const newDate = new Date(dateNaissance);

    const INSPaylaod = {
      dateNaissance: moment(newDate).format("DDMMYYYY"),
      lieuNaissance:
        lieuNaissance && lieuNaissance !== "" ? lieuNaissance : null,
      nomNaissance,
      prenom,
      sexe,
      prenoms,
      context: {
        structure: {
          identifiantFacturation: user?.idFacturationCps,
        },
        auteur: {},
      },
      matriculeINS,
      Idfictional,
      IDDoting,
    };

    try {
      if (!prenoms || !sexe || !prenom || !nomNaissance || !dateNaissance) {
        return makeToast("warning", t("AddPatient.AllRequiredFields"));
      }
      // let cpsInfo;
      // const readerInfo = await CPS.readerInfo();
      // if (!readerInfo) return;
      // cpsInfo = await CPS.readCard();
      const regexNom = /^[A-Z-' ]+$/; // majuscule + ' + - + white space
      const regexLieux = /^[0-9]+$/;
      if (!regexNom.test(nomNaissance)) {
        return makeToast("warning", t("Segure.NameRequire"));
      } else if (lieuNaissance && !regexLieux.test(lieuNaissance))
        return makeToast("warning", t("Segure.BirthplaceRequire"));
      setloading(true);
      const INSResponse = await apiINS.searchWithoutCard(INSPaylaod, true);

      if (INSResponse && INSResponse.errorMessage) {
        const newPatient = await updatePatientINSData({
          patientId: patientData._id,
          INS: prepareInsObject(values, false),
        });
        setPatientInfos({ ...patientData, INS: newPatient?.patient?.INS });
        makeToast("warning", t("Segure.NoIdentityFound"));

        //setPatientData({ ...patientData, INS: newPatient.INS });
        addNewLogs(
          prepareLogsObject(patientData?.INS?.INSValue, INSPaylaod, INSResponse)
        );
        setloading(false);
      } else if (INSResponse?.compteRendu?.valeur === "02") {
        setloading(false);

        return makeToast("warning", t("Segure.SeveralIdentitiesFound"));
      } else if (
        INSResponse &&
        INSResponse.traitsIdentite &&
        INSResponse.compteRendu &&
        INSResponse.compteRendu?.valeur === "00"
      ) {

        const newPatient = await updatePatientINSData({
          patientId: patientData._id,
          INS: prepareInsObject(values, true, INSResponse),
        });
        setPatientInfos({ ...patientData, INS: newPatient?.patient?.INS });
        setPatientData({
          ...patientData,
          INS: newPatient?.patient?.INS,
          lastName: newPatient?.patient?.lastName,
          firstName: newPatient?.patient?.firstName,
          birthDate: moment(newPatient?.patient?.birthDate).format("DDMMYYYY"),
          numSecSocial: newPatient?.patient?.numSecSocial,
        });
        makeToast("success", t("Segure.uniqueIdentityFound"));
        addNewLogs(
          prepareLogsObject(patientData?.INS?.INSValue, INSPaylaod, INSResponse)
        );
        setloading(false);
        //setPatientData({ ...patientData, INS: newPatient.INS });
      } else {
        const newPatient = await updatePatientINSData({
          patientId: patientData._id,
          INS: prepareInsObject(values, false),
        });
        setPatientInfos({ ...patientData, INS: newPatient?.patient?.INS });

        makeToast("warning", t("Segure.NoIdentityFound"));
        addNewLogs(
          prepareLogsObject(patientData?.INS?.INSValue, INSPaylaod, INSResponse)
        );
        setloading(false);
        //setPatientData({ ...patientData, INS: newPatient.INS });
      }
    } catch (error) {
      console.error("123...err :", error);
      setloading(false);
      makeToast("warning", t("Segure.Failed"));
      addNewLogs(
        prepareLogsObject(patientData?.INS?.INSValue, INSPaylaod, null)
      );
    }
  };

  return (
    <div>
      {!_.isEmpty(patientInfos?.INS?.responseINS?.insActif) &&
      statusLabels[patientInfos?.INS?.INSStaus] === "Identité qualifiée" ? (
        <div className="flex flex-col gap-4 mx-2 p-4 border rounded-lg ">
          <h2 className="card-subTiltle">{t("Segure.NHI_INS")}</h2>
          <div className="flex flex-row gap-x-2">
            <p className="text-sm font-bold w-1/2 "> {t("Segure.Status")}</p>
            <p
              className={`text-sm px-2  rounded-lg  text-center text-white ${
                statusColors[patientInfos?.INS?.INSStaus]
              }`}
            >
              {i18n.language.includes("en")
                ? statusLabelsEN[patientInfos?.INS?.INSStaus]
                : statusLabels[patientInfos?.INS?.INSStaus]}
            </p>
          </div>

          <div className="flex flex-row gap-x-6">
            <p className="text-sm font-bold w-1/2 ">{t("Segure.ID")}</p>
            <p className="text-sm w-1/2">
              {patientInfos?.INS?.responseINS.insActif?.valeur}

              {
                INSType[
                  patientInfos?.INS?.responseINS.insActif?.identifiantSysteme
                ]
              }
            </p>
          </div>

          <div className="flex flex-row gap-x-6">
            <p className="text-sm font-bold w-1/2 "> {t("Segure.birthName")}</p>
            <p className="text-sm w-1/2">
              {patientInfos?.INS?.responseINS.traitsIdentite?.nomFamille ||
                patientInfos?.INS?.responseINS.traitsIdentite
                  ?.prenomActeNaissance}
            </p>
          </div>

          <div className="flex flex-row gap-x-6 ">
            {" "}
            <p className="text-sm font-bold w-1/2"> {t("Segure.firstName")} </p>
            <p className="text-sm w-1/2">
              {
                patientInfos?.INS?.responseINS.traitsIdentite
                  ?.prenomActeNaissance
              }
            </p>
          </div>
          <div className="flex flex-row gap-x-6">
            {" "}
            <p className="text-sm font-bold w-1/2"> {t("Segure.Sexe")} </p>
            <p className="text-sm w-1/2">
              {patientInfos?.INS?.responseINS.traitsIdentite
                ?.sexeAdministratif === "F"
                ? "Femme"
                : "Homme"}
            </p>
          </div>
          <div className="flex flex-row gap-x-6">
            {" "}
            <p className="text-sm font-bold w-1/2">
              {" "}
              {t("Segure.dateOfBirthTitle")}
            </p>
            <p className="text-sm w-1/2">
              {moment(
                patientInfos?.INS?.responseINS.traitsIdentite?.dateNaissance
              ).format("DD/MM/YYYY")}
            </p>
          </div>
          <div className="flex flex-row gap-x-6">
            {" "}
            <p className="text-sm font-bold w-1/2">
              {" "}
              {t("Segure.placeOfBirthTitle")}{" "}
            </p>
            <p className="text-sm w-1/2">
              {patientInfos?.INS?.responseINS.traitsIdentite?.lieuNaissance}
            </p>
          </div>
        </div>
      ) : (
        <Formik
          initialValues={
            patientInfos?.INS?.INSValue
              ? {
                  ...patientInfos?.INS?.INSValue,
                  lieuNaissance: "",
                  identity: "idvalid",
                }
              : defaultInsValue
          }
          onSubmit={(values) => {
            handleVerifyINS(values);
          }}
          // validationSchema={validationSchema}
        >
          {(props) => {
            const {
              values,
              errors,
              // isSubmitting,
              isValid,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = props;

            return (
              <form onSubmit={handleSubmit} className="w-full ">
                {process.env.REACT_APP_KATOMI_TENANT === "katomi" && (
                  <div className="mx-4 px-4 rounded-lg border py-4 my-4  relative  ">
                    <h2 className="card-subTiltle mb-2.5">
                      {t("Segure.InformationsINS")}
                    </h2>
                    <div className="flex flex-row gap-x-2"></div>
                    <div className="flex mt-2 mb-4">
                      {" "}
                      <p className="text-sm font-bold w-1/2 ">
                        {t("Segure.Status")}
                      </p>
                      <p
                        className={`text-sm px-2  rounded-lg  text-center text-white ${
                          statusColors[patientInfos?.INS?.INSStaus]
                            ? statusColors[patientInfos?.INS?.INSStaus]
                            : "INS-provisoire px-2 text-md "
                        }`}
                      >
                        {statusLabels[patientInfos?.INS?.INSStaus]
                          ? statusLabelsEN[patientInfos?.INS?.INSStaus]
                          : statusLabelsEN["IdProvisional"]}
                      </p>
                    </div>

                    <Input
                      id="nomNaissance"
                      labelText={t("Segure.birthName")}
                      labelProps={{ className: "font-bold text-sm " }}
                      placeholder={t("Segure.birthName")}
                      type="text"
                      value={values.nomNaissance ? values.nomNaissance : ""}
                      onChange={handleChange}
                      required={true}
                      // onBlur={(e) => {
                      //   handleBlur(e);
                      //   handleVerifyINS(values);
                      // }}
                      className="w-96 mb-2.5 rounded-lg border"
                      iconColor="white"
                      //isIcon={true}
                      // icon="user"
                    />
                    {errors["nomNaissance"] ? errors["nomNaissance"] : ""}
                    <Input
                      id="prenom"
                      placeholder={t("Segure.firstName")}
                      labelText={t("Segure.firstName")}
                      labelProps={{ className: "font-bold text-sm " }}
                      type="text"
                      value={values.prenom ? values.prenom : ""}
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                        setFieldValue("prenoms", values.prenom.split(" "));
                        // handleVerifyINS(values);
                      }}
                      required={true}
                      className="w-96 mb-2.5 rounded-lg border"
                      iconColor="white"
                      //isIcon={true}
                      // icon="user"
                    />
                    <Input
                      id="prenoms"
                      placeholder={t("Segure.firstNameS2")}
                      type="text"
                      value={
                        values.prenom ? values.prenom.split(" ").join(",") : ""
                      }
                      required={true}
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                        // handleVerifyINS(values);
                      }}
                      disabled
                      className="w-96 mb-2.5 rounded-lg border"
                      iconColor="white"
                      //isIcon={true}
                      // icon="user"
                    />
                    <>
                      <Select
                        text={t("Segure.regions")}
                        className="mb-2.5 w-96 rounded-lg pl-3"
                        // labelProps={{ className: "w-40" }}
                        value={
                          selectedRegionState ? selectedRegionState.value : ""
                        }
                        labelText={t("Segure.regions")}
                        labelProps={{ className: "font-bold text-sm -mb-3" }}
                        divProps="w-full"
                        options={regionState}
                        onChange={(e) => {
                          setSelectedRegionState(
                            regionState.find(
                              (elm) => elm.value == e.target.value
                            )
                          );
                        }}
                      />
                      {selectedRegionState?.value && (
                        <Select
                          text={t("Segure.Delegation")}
                          className="mb-2.5 w-96 rounded-lg pl-3"
                          labelText={t("Segure.Delegation")}
                          labelProps={{ className: "font-bold text-sm -mb-3" }}
                          divProps="w-full"
                          value={
                            selectedDepartementState
                              ? selectedDepartementState.value
                              : ""
                          }
                          options={departementState}
                          onChange={(e) => {
                            setSelectedDepartementState(
                              departementState.find(
                                (elm) => elm.value == e.target.value
                              )
                            );
                          }}
                        />
                      )}
                      {selectedDepartementState?.value && (
                        <Select
                          id="lieuNaissance"
                          placeholder={t("Segure.placeOfBirth")}
                          labelText={t("Segure.placeOfBirthTitle")}
                          labelProps={{ className: "font-bold text-sm -mb-3 " }}
                          options={communesState.map((c) => {
                            return {
                              label: c.code + " - " + c.label,
                              value: c.code,
                            };
                          })}
                          value={values.lieuNaissance}
                          onChange={handleChange}
                          onBlur={(e) => {
                            handleBlur(e);
                            // handleVerifyINS(values);
                          }}
                          className="w-96 mb-2.5 rounded-lg border"
                          // icon="home"
                          iconColor="white"
                          // isIcon={true}
                        />
                      )}
                    </>
                    <label className="font-bold text-sm ml-2">
                      {t("Segure.dateOfBirthTitle")}{" "}
                      <span className="text-red-500 ml-1">*</span>
                    </label>
                    <DatePicker
                      id="dateNaissance"
                      locale="fr"
                      required={true}
                      className="w-96 mb-2 rounded-lg border px-2 py-3"
                      selected={
                        values.dateNaissance
                          ? typeof values.dateNaissance === "string" &&
                            values.dateNaissance.length == 8
                            ? new Date(
                                +values.dateNaissance?.slice(4, 8),
                                +values.dateNaissance?.slice(2, 4) - 1,
                                +values.dateNaissance?.slice(0, 2)
                              )
                            : new Date(values.dateNaissance)
                          : startDateINS
                      }
                      dateFormat="ddMMyyyy"
                      onChange={(date) => {
                        setStartDateINS(date);
                        setFieldValue("dateNaissance", date);
                        // handleVerifyINS(values);
                      }}
                      placeholderText={t("Segure.dateOfBirth")}
                      autoComplete="off"
                      showYearDropdown
                    />
                    {/* <img crossOrigin = "anonymous"  referrerPolicy="origin"
                            src={cake}
                            alt="cake"
                            className="absolute top-3 left-3"
                            width="18"
                          /> */}
                    <label className="font-bold text-sm ml-2">
                      {t("Segure.Sexe")}{" "}
                      <span className="text-red-500 ml-1">*</span>
                    </label>
                    <Select
                      id="sexe"
                      value={
                        values.INSSexe
                          ? values.sexe
                          : values?.sexe?.includes("M")
                          ? "M"
                          : values?.sexe?.includes("F")
                          ? "F"
                          : ""
                      }
                      // labelText="Sexe"
                      labelProps={{ className: "font-bold text-sm -mb-3" }}
                      divProps="w-full"
                      className="w-96 mb-6 rounded-lg border p-2 pl-3"
                      options={dropdownSexeOptions}
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                        // handleVerifyINS(values);
                      }}
                      text={t("Segure.YourSex")}
                    />
                    <div className="relative w-96">
                      <Input
                        id="matriculeINS"
                        placeholder={t("Segure.INSNumber")}
                        labelText={t("Segure.MatriculeINS")}
                        labelProps={{ className: "font-bold text-sm " }}
                        type="text"
                        value={values.matriculeINS ? values.matriculeINS : ""}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          // handleVerifyINS(values);
                        }}
                        // required={true}
                        className="w-96 mb-2.5 rounded-lg border"
                        iconColor="white"
                        //isIcon={true}
                        // icon="user"
                      />{" "}
                      <div className=" absolute top-6 right-2">
                        <Tooltip
                          tooltipText={t("Segure.VerificationINS")}
                          position="top"
                        >
                          {loading ? (
                            <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-purple-500" />
                          ) : (
                            <img
                              crossOrigin="anonymous"
                              referrerPolicy="origin"
                              alt="search"
                              src={
                                require("../../assets/icons/icon-search.svg")
                                  .default
                              }
                              width="35"
                              className="cursor-pointer icon-blue"
                              onClick={() => handleSubmit(values)}
                            />
                          )}
                        </Tooltip>{" "}
                      </div>
                    </div>

                    <Input
                      id="identity"
                      name="identity"
                      type="radio"
                      onChange={handleChange}
                      value="IDDoting"
                      labelText={t("Segure.SI")}
                      labelProps={{
                        className: "font-semibold text-sm   pl-2",
                      }}
                    />
                    <div className="mb-2" />
                    <Input
                      id="identity"
                      name="identity"
                      type="radio"
                      onChange={handleChange}
                      value="Idfictional"
                      labelText={t("Segure.FI")}
                      labelProps={{
                        className: "font-semibold text-sm   pl-2",
                      }}
                    />
                    <div className="mb-2" />

                    <Input
                      id="identity"
                      name="identity"
                      type="radio"
                      onChange={handleChange}
                      value="idvalid"
                      labelText={t("Segure.VI")}
                      labelProps={{
                        className: "font-semibold text-sm   pl-2",
                      }}
                      defaultChecked
                    />
                    <div className="mb-2" />

                    <Input
                      id="identity"
                      name="identity"
                      type="radio"
                      onChange={handleChange}
                      value="idNonValid"
                      labelText={t("Segure.INV")}
                      labelProps={{
                        className: "font-semibold text-sm   pl-2",
                      }}
                    />
                    <div className="mb-4" />

                    {/* **************************************************************** */}

                    <div className="mb-12">
                      <Button
                        status="primary"
                        className="btn m-2.5 p-2 absolute right-0 bottom-0 "
                        disabled={isValid || loading ? false : true}
                      >
                        <div className="relative">
                          {false && (
                            <div className="absolute h-full w-full flexCenter ">
                              <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-purple-500"></div>
                            </div>
                          )}
                          {t("Segure.Save")}
                        </div>
                      </Button>
                    </div>
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default INS;
