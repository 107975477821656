/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import _ from "lodash";
//** Helper & API */
import widgetList from "../../components/Widget/widgetList.json";
import { userAtom } from "../../state/auth";
import { widgetAtom } from "../../state/widget";
import moment from "../../helper/moment";
import { useHistory, useLocation } from "react-router-dom";

import { updateDoctorData } from "../../api/doctor";
//** Component */
import DnDGridLayout from "../../components/Widget/DragAndDropWidget";
import Button from "../../components/ButtonUI";
import Widget from "../../components/Widget/WidgetDropList";
import Icon from "../../components/Icon";
/** Assets */
import "./styles.scss";
import { pscAtom } from "../../state/psc";
import { SavePscId } from "../../api/auth";
import makeToast from "../../components/Snackbar";

const DashboardDoctor: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation() as any;
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useAtom(userAtom) as any;
  const [psc, setPsc] = useAtom(pscAtom) as any;
  console.log("🚀 ~ file: index.tsx:25 ~ psc:", psc);
  const [dateNow, setDateNow] = useState(moment());
  const [resetWidget, setResetWidget] = useState(true);
  const [newWidget, setnewWidget] = useState(false);
  const [editWidget, setEditWidget] = useAtom(widgetAtom) as any;

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const code = query.get("code");
  const state = query.get("state");
  const sessionState = query.get("session_state");

  const defaultConfig = async () => {
    try {
      const updatedUser = await updateDoctorData({
        dashboardConfig: widgetList,
      });
      setUser({ ...user, ...updatedUser });
    } catch (error) {}
  };

  useEffect(() => {
    if (_.isEmpty(user.dashboardConfig)) defaultConfig();
  }, []);

  // auto reset widget position if doctor havent any config or all widget bugged and have width 2
  useEffect(() => {
    if (
      resetWidget &&
      (_.isEmpty(user.dashboardConfig) ||
        user.dashboardConfig
          .filter((el) => el.active)
          // eslint-disable-next-line eqeqeq
          .every((el) => el.width == 2))
    ) {
      defaultConfig();
      setResetWidget(false);
    }
  }, [user]);

  //refresh Date Time in Dashboard
  useEffect(() => {
    const interval = setInterval(() => {
      setDateNow(moment());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (code) {
      if (!user?.psc?.id)
        SavePscId(code)
          .then((res) => {
            console.log("res?.user?.psc ==>", res?.user?.psc);
            if (res?.user) setUser(res?.user);
            history.push("/profile");
          })
          .catch((error) => {
            console.log(error?.response?.data);
            if (error?.response?.data?.message === "cardAlreadyExist")
              makeToast("warning", t("DashboardDoctor.pscAlreadyExist"));
            else makeToast("warning", t("MyDoctors.SomethingWentWrong"));
          });
      setPsc({ code, state, sessionState });
    }
  }, [code]);

  return (
    <div className="dashboard-doctor w-full">
      {/* Header */}
      {/* <div className="border border-red-500 w-full">
        <Formik
          initialValues={{ location: "", raison: "", file: "" }}
          onSubmit={async (values) => {
            let formData = new FormData();
            formData.append("multipart", values.file);
            const blob = await CPS.signPdf(formData);

            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `signature.pdf`);
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link?.parentNode?.removeChild(link);
          }}
          // validationSchema={validationSchema}
        >
          {(props) => {
            const {
              values,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = props;

            return (
              <form onSubmit={handleSubmit} className="signature-pdf">
                <Input
                  id="location"
                  labelText="location"
                  placeholder="location"
                  type="text"
                  value={values.location}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Input
                  id="raison"
                  labelText="raison"
                  placeholder="raison"
                  type="text"
                  value={values.raison}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Input
                  id="file"
                  type="file"
                  onChange={(e) => {
                    if (e.currentTarget.files && e.currentTarget.files[0]) {
                      const file =
                        e.currentTarget.files && e.currentTarget.files[0];
                      setFieldValue("file", file);
                    } else {
                    }
                  }}
                  onBlur={handleBlur}
                  className=""
                />
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  status="primary"
                  className="btn mb-6"
                  width="150px"
                  height="35px"
                  onClick={() => handleSubmit()}
                >
                  Signer
                </Button>
              </form>
            );
          }}
        </Formik>
      </div> */}
      <header className="flex justify-between  pl-4 lg:pl-0 md:items-center w-full flex-col lg:flex-row">
        <div className="mb-4 lg:mb-auto">
          <h1>{t("DashboardDoctor.title")}</h1>{" "}
          <h3 className="card-subtitle">
            {t("DashboardDoctor.WeAreOn")} {dateNow.format(t("Moment.Z"))}
          </h3>
        </div>
        <div className="flex max-w-max items-center gap-2 flex-wrap">
          <div className="relative space-x-3 flex ">
            {editWidget && (
              <Button
                status="secondary"
                onClick={defaultConfig}
                width="130px"
                height="50px"
              >
                {t("DashboardDoctor.Reset")}
              </Button>
            )}
            <Button
              status="secondary"
              onClick={() => setnewWidget(!newWidget)}
              width="170px"
              height="50px"
            >
              {t("DashboardDoctor.NewWidget")}
            </Button>
            <Widget
              className={newWidget ? "" : "hidden"}
              closeWidegt={() => {}}
            />
          </div>

          <Button
            status="icon-primary"
            onClick={() => setEditWidget(!editWidget)}
          >
            {editWidget ? (
              <Icon name={"check-white"} isIcon={true} />
            ) : (
              <Icon name={"tableau-blanc"} isIcon={true} />
            )}
          </Button>
        </div>
      </header>
      <main>
        <DnDGridLayout />
      </main>
    </div>
  );
};

export default DashboardDoctor;
