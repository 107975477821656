import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import Icon from "../../components/Icon";
import { User } from "../../types/user";
import AddCursus from "./modal/AddCursus";
import Modal from "../../components/ModalUI";

const Cursus: React.FC<User | any> = ({ userProps }) => {
  const { t } = useTranslation() as any;
  const [openModal, setOpenModal] = useState(false);
  return (
    <div className="cursus w-full ">
      <div className="headerContainer flex items-center relative">
        <Icon
          isIcon={true}
          name="hat"
          transform="scale(1.1)"
          className="ml-5"
        />
        <p className="header"> {t("Profile.CURSUS")}</p>
        <p
          className="absolute right-0	modifier"
          onClick={() => setOpenModal(true)}
        >
          {t("Profile.Update")}
        </p>
      </div>
      <div className="cursusContainer">
        {!_.isEmpty(userProps.studiesHistory) ? (
          userProps.studiesHistory.map((d, index) => {
            return (
              <div className="flex">
                <div className="images">
                  <Icon isIcon={true} name="hat" />

                  {index !== userProps?.studiesHistory?.length - 1 ? (
                    <Icon
                      name="monCompte-cursus"
                      width={0}
                      height={33}
                      isIcon={true}
                    />
                  ) : (
                    <Icon
                      name="monCompte-cursus"
                      width={0}
                      height={0}
                      isIcon={true}
                    />
                  )}
                </div>
                <div className="details">
                  <div className="flex items-center space-x-2">
                    <p className="typeDiplome">{d.diplome}</p>
                    <span className="w-1 h-1 bg-black rounded"></span>
                    <p className="anneeDiplome">{d.year}</p>
                  </div>
                  <p className="university">{d.university}</p>
                </div>
              </div>
            );
          })
        ) : (
          <p className="card-subtitle flexCenter mt-20">
            {" "}
            {t("Profile.NoCurriculum")}
          </p>
        )}
      </div>

      <Modal
        closeModal={() => setOpenModal(false)}
        showModal={openModal}
        width="w-full"
      >
        <AddCursus
          openModal={openModal}
          setOpenModal={setOpenModal}
          doctorId={userProps._id}
        />
      </Modal>
    </div>
  );
};

export default Cursus;
