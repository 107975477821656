import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import classNames from "classnames";

import "./styles.scss";

type BtnStatutes =
  | "primary"
  | "success"
  | "info"
  | "warning"
  | "danger"
  | "control"
  | "basic"
  | "none";

type Props = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  status?: BtnStatutes;
  size?: string;
  width?: string;
};

const Button: React.FC<Props> = ({
  width,
  size,
  status,
  className,
  children,
  ...props
}) => {
  let btnStatusStr = "bg-primary text-white";
  const statusToColorMap: { [key in BtnStatutes]?: string } = {
    primary: "primary",
    success: "green-500",
    info: "blue-500",
    warning: "yellow-500",
    danger: "red-500",
    control: "black",
    none: "white",
  };

  switch (status) {
    case "basic":
      btnStatusStr = "border border-gray-200";
      break;
    default:
      btnStatusStr = `bg-${statusToColorMap[status!]} text-white`;
      break;
  }

  return (
    <button
      className={classNames(
        "flex items-center justify-center hover:bg-white border hover:text-primary-dark",
        className,
        btnStatusStr,
        size ? size : "h-12",
        width ? width : "w-1/2 "
      )}
      {...props}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  status: "primary",
};

export default Button;
