import React from "react";
import { Formik } from "formik";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { addOrModifyExpertise } from "../../../api/doctor";
import presentationSchema from "./schemas/presentationSchema";
import { User } from "../../../types/user";
import { userAtom } from "../../../state/auth";
import Button from "../../../components/ButtonUI";
import Input from "../../../components/Input";
import makeToast from "../../../components/Snackbar";
import { ReactComponent as Close } from "../../../assets/icons/icon-close.svg";
import "./styles.scss";

interface Props {
  openModal: boolean;
  setOpenModal: Function;
  doctorId?: string;
}

export const AddExpertise: React.FC<Props> = ({ openModal, setOpenModal }) => {
  const { t } = useTranslation() as any;
  const [user, setUser] = useAtom(userAtom);
  // const addExpertise = async (values) => {
  //   try {
  //     if (!values.expertise)
  //       return makeToast("warning", t("Profile.fillExpertise"));
  //     const newUser = await addOrModifyExpertise({ ...values, user });
  //     const { expertises } = newUser;
  //     setUser({
  //       ...user,
  //       expertises,
  //     } as User);
  //     if (newUser._id) {
  //       setOpenModal(!openModal);
  //       makeToast("success", t("Profile.successAddExpertise"));
  //     } else {
  //       makeToast("error", t("Profile.successAddExpertise"));
  //     }
  //   } catch (err) {}
  // };
  return (
    <div className="relative">
      <div className="absolute right-0 mt-2 mr-2">
        <Button status="icon-secondary" onClick={() => setOpenModal(false)}>
          <Close />
        </Button>
      </div>

      <Formik
        initialValues={{
          expertise: "",
        }}
        onSubmit={async (values) => {
          try {
            if (!values.expertise)
              return makeToast("warning", t("Profile.fillExpertise"));
            const newUser = await addOrModifyExpertise({ ...values, user });
            const { expertises } = newUser;
            setUser({
              ...user,
              expertises,
            } as User);
            if (newUser._id) {
              setOpenModal(!openModal);
              makeToast("success", t("Profile.successAddExpertise"));
            } else {
              makeToast("error", t("Profile.successAddExpertise"));
            }
          } catch (err) {}
        }}
        validationSchema={presentationSchema}
      >
        {(props) => {
          const {
            values,
            // errors,
            // isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;

          return (
            <form
              onSubmit={handleSubmit}
              className="flex-auto py-7 px-8 min-w-full	"
            >
              <div className="flex flex-wrap items-baseline">
                <h1 className="mb-10">{t("Profile.AddExpertise")}</h1>
              </div>
              <div className="">
                <Input
                  id="expertise"
                  placeholder={t("Profile.WriteURExpertise")}
                  //error={values.pricing}
                  value={values.expertise}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inlineLabel={false}
                  onfocus={true}
                  className="w-full mb-8"
                />
              </div>
              <Button
                status="secondary"
                width="100px"
                height="50px"
                className="float-left mb-4 btn-delete"
                onClick={() => setOpenModal(false)}
              >
                {t("Profile.Cancel")}
              </Button>
              <Button
                status="secondary"
                width="100px"
                height="50px"
                className="float-right mb-4"
                // onClick={() => addExpertise(values)}
              >
                {t("Profile.Save")}
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddExpertise;
