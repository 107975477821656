import { useTranslation } from "react-i18next";
import Button from "../../ButtonUI";
import { ReactComponent as Close } from "../../../assets/icons/icon-close.svg";

export interface IProps {
  openModal: boolean;
  setOpenModal: Function;
  selectedCompteRendu: any;
}

const DisplayPrescription: React.FC<IProps> = ({
  openModal,
  setOpenModal,
  selectedCompteRendu,
}) => {
  const { t } = useTranslation() as any;
  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute bg-white z-40 top-1/3   addTraitementModal p-4">
        <div className="relative ">
          <div className="absolute right-0 mt-2 mr-2">
            <Button status="icon-secondary" onClick={() => setOpenModal(false)}>
              <Close />
            </Button>
          </div>
        </div>
        <div>
          <div className="flex flex-wrap items-baseline">
            <h1 className="mb-10">
              {" "}
              {t("ModalFichePatient.ListPrescription")}
            </h1>
          </div>
        </div>
        <div className="pr-10 pl-10">
          {selectedCompteRendu?.consultation?.prescriptions &&
          selectedCompteRendu?.consultation?.prescriptions.length > 0 ? (
            <div className="flex flex-wrap mb-8">
              {selectedCompteRendu?.consultation?.prescriptions.map((e) => {
                return (
                  <div className="flex w-full justify-between">
                    <p>
                      {" "}
                      {t("ModalFichePatient.Medication")} {e.medicament}
                    </p>
                    <p>
                      {" "}
                      {t("ModalFichePatient.Dosage")} {e.dosage}
                    </p>
                  </div>
                );
              })}
            </div>
          ) : (
            t("ModalFichePatient.EmptyReport")
          )}
        </div>
      </div>
    </div>
  );
};

export default DisplayPrescription;
