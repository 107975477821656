import { SimpleGrid } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FormGroup } from "./FormGroup";
import { useField } from "../../../hooks/FormGeneratorHooks";
import { FieldInput } from "./FieldInput";
import { FieldSelect } from "./FieldSelect";
import countrysData from "../../../config/constants/locales/WorldNameLists.json";

export const FullAdress = (props) => {
  const { t } = useTranslation();
  const { errorMessage, id, otherProps } = useField(props);

  const {
    required,
    name,
    pays,
    ville,
    zipCode,
    adresse,
    departement,
    rules,
    readOnly,
  } = props;
  const { children, label, options, helper, validMessage, ...rest } =
    otherProps;

  const showError = required;

  const formGroupProps = {
    errorMessage,
    helper,
    id,
    isRequired: false,
    label,
    showError,
    name,

    ...rest,
  };

  return (
    <FormGroup {...formGroupProps}>
      <SimpleGrid
        columns={{ base: 2 }}
        borderColor={"gray"}
        rounded={"lg"}
        borderWidth={1}
        p="4"
        spacing="4"
        mb="4"
      >
        {adresse && (
          <>
            <FieldInput
              disabled={readOnly ? true : false}
              name={`${name}.adresse`}
              label={t("Survey.PostalAddress")}
              placeholder={t("Survey.Rue")}
              required={
                rules && rules[0] && rules[0].required
                  ? t("Survey.RequireField")
                  : false
              }
            />{" "}
          </>
        )}
        {ville && (
          <>
            <FieldInput
              disabled={readOnly ? true : false}
              name={`${name}.ville`}
              label={t("Survey.City")}
              placeholder={t("Survey.City")}
              required={
                rules && rules[0] && rules[0].required
                  ? t("Survey.RequireField")
                  : false
              }
            />
          </>
        )}
        {zipCode && (
          <>
            <FieldInput
              disabled={readOnly ? true : false}
              type={"number"}
              name={`${name}.zipCode`}
              label={t("Survey.PostalCode")}
              placeholder={t("Survey.PostalCode")}
              required={
                rules && rules[0] && rules[0].required
                  ? t("Survey.RequireField")
                  : false
              }
            />
          </>
        )}
        {pays && (
          <>
            <FieldSelect
              disabled={readOnly ? true : false}
              name={`${name}.pays`}
              options={countrysData}
              label={t("Survey.Country")}
              placeholder={t("Survey.selectContry")}
              required={
                rules && rules[0] && rules[0].required
                  ? t("Survey.RequireField")
                  : false
              }
            />
          </>
        )}
        {departement && (
          <>
            <FieldInput
              disabled={readOnly ? true : false}
              name={`${name}.departement `}
              label={t("Survey.Department")}
              placeholder={t("Survey.Department")}
              required={
                rules && rules[0] && rules[0].required
                  ? t("Survey.RequireField")
                  : false
              }
            />{" "}
          </>
        )}
      </SimpleGrid>

      {children}
    </FormGroup>
  );
};
