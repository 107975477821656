export const Survey = {
  fr: {
    Previous: "Précédent",
    Next: "Suivant",
    PageNum: "Page N°",
    NoAvailbleForMobile: "Ce module est indisponible sur la version mobile.",
    MyQuestionnaires: "Mes Questionnaires",
    NewSurvey: "Nouveau questionnaire",
    ResultsFound: "résultats trouvés",
    NoResultFound: "aucun résultat trouvé",
    NoSurveyFound: "Aucun questionnaire trouvé",
    Ok: " Ok",
    Leave: "Quitter",
    surveySpace: "Espace d'édition des enquêtes",
    StartFromZero: "Commencer à partir de zéro",
    AllYouNeed: "Une template vierge est tout ce dont vous avez besoin .",
    StartFromTemplate: "Commencez à partir d'un modèle",
    preRecordedForms: "Choisissez parmi vos formulaires préenregistrés .",
    Active: "Active",
    LastUpdateOn: "Dernière modification apportée le",
    Assign: "Adresser",
    SelectAll: "Tout séléctionner",
    SendSurvey: "Adresser ce questionnaire au(x) patient(s)",
    NA: "N/A",
    PostalAddress: "Adresse postale :",
    PostalCode: "Code Postal :",
    City: "Ville : ",
    Country: "Pays :",
    Department: "Département :",
    Rue: "Rue",
    Name: "Nom Prénom :",
    firstName: "Prénom",
    lastName: "Nom",
    Email: "Email :",
    NumTel: "N° Tel :",
    NumPhone: "N° téléphone :",
    NumSocial: "N° Sociale :",
    NumSecSocial: "N° de sécurité sociale",
    NonValidMail: "Email non Valide",
    selectContry: "Sélectionnez un pays",

    FieldIdenticalImages: "Champ d'images identiques",
    select2identicalimg: "Sélectionnez deux images identiques",
    the2identicalimg: "Parfait, les deux images sont identiques !",
    mustSelect2img: "Vous devez sélectionner 2 images",
    mustSelectSecondimg: "Vous devez sélectionner une deuxième image",
    imgAreNotIdentical: "Les images ne sont pas identiques",
    InvalidNumber: "Nombre invalide",

    orUploadPic: "ou téléchargez une image :",
    selectImage: "Selection image",
    loadImage: "Charger une image",
    RequireField: "Ce champ est requis",
    FieldInvalid: "Champ invalide",
    TextZone: "Contenu du texte",

    pageSettings: "Paramètres de la page",
    Remove: "Suprimer",
    AddSection: "Ajouter une nouvelle section",
    ConfirmDeletePage: "Voulez-vous supprimer la page ?",
    Edit: "Éditer",
    Cancel: "Annuler",
    Confirm: "Confirmer",
    Save: "Enregistrer",
    Upload: "Télécharger",
    Preview: "Prévisualiser",

    Note: "Note",
    Date: "Date",
    Signature: "Signature",
    Page: "Page",
    ResetForm: "Réinitialiser le formulaire",
    EditProperties: "Éditer les propriétés",
    ConditionalLogic: "Logique conditionnelle",
    Deactivate: "Désactiver",
    Activate: "Activer",
    Action: "Action",
    Value: "Valeur",
    AddCondition: "Ajouter une Condition",
    notYet: "Pas encore ⌛⌛....",
    PersonalInformation: "Renseignements personnels",
    Questions: "Questions",
    AddQuestion: "Ajouter un question",
    AddChoice: "Ajouter un choix",
    Choice: "Choix",
    ErrorMsg: "Message d'erreur",
    FieldParameter: "Paramètre du champ",
    AdvancedSettings: "Paramètres avancés",
    SurveyDescription: "Description du questionnaire...",
    SurveySummary: "Synthèse du questionnaire",

    AddOtherValidationParameters: "Ajouter autres paramètres de validations",
    selectSetting: "Veuillez sélectionner un paramètre",

    SearchedWord: "Mot recherché",
    NumberOfCharacters: "Nombre de caractères",
    MinimumNumberOfCharacters: "Nombre de caractères minimum",
    MaximumNumberOfCharacters: "Nombre de caractères maximum",
    Minimum: "Minimum",
    Maximum: "Maximum",
    Toolbox: "Boîte à outils",

    PredefinedFields: "Champs prédéfinis",
    InputFields: "Champs de saisie",
    InformationalFields: "Champs informationnels",
    NumberField:
      "Création d’un champ nombre. Seules des valeurs numériques peuvent être saisies.",
    DropDownList:
      "Création d’une liste déroulante comprenant de multiples options de réponse. L’utilisateur ne peut sélectionner qu’une seule option de réponse.",
    Checkbox:
      "Création d’une liste de cases à cocher. De multiples options de réponse peuvent être créées. L’utilisateur peut sélectionner plusieurs options de réponse (à la différence d’un bouton radio)",
    RadioButton:
      "Création d’une liste de boutons radios. De multiples options de réponse peuvent être créées mais l’utilisateur ne peut en sélectionner qu’une",
    questionsGroup:
      "Création d’un groupe des questions en fonction des choix sous forme d’une liste de boutons radios . De multiples options de réponse peuvent être créées mais l’utilisateur ne peut en sélectionner qu’une",
    CreatingATextField: "Creating a text field.",
    CreationOfScaleNumeric:
      "Création d’une échelle numérique. Les bornes de l’échelle peuvent être fixées par les valeurs minimale et maximale. L’intervalle entre deux graduations est personnalisable",

    CreateATextBox: "Création d’une zone de texte.",

    ZoneText: "Zone de texte",
    TextField: "Champ de texte",

    NumericField: "Champ numérique",
    DropdownList: "Liste déroulante",
    MultipleChoice: "Choix multiple",
    SimpleChoice: "Choix simple",
    Gate: "Grille",
    Photo: "Photo",
    CreatingDateField: "Création d’un champ date",
    CursorField: "Champ curseur",
    Identify: "Identité",

    FullAddress: "Adresse complète",
    AddNameDescriptionToSurvey:
      "Attribuez un nom et une description à votre questionnaire",
    SurveyName: "Nom du questionnaire",
    SurveyDescription1: "Description du questionnaire",
    SelectSurveyForm: "Sélectionnez la mise en page de votre questionnaire",
    ChoiceSurveyForm: "Choisissez une mise en page selon vos besoins",
    classicSurvey: "Questionnaire classique",
    ShowSurvey: "Afficher toutes les questions sur une ou plusieurs pages .",
    CardSurvey: "Questionnaire à cartes",
    oneSurveyPerPage: "Afficher une seule question par page .",

    SuccessSavesSrveyResponse: "Vos reponses sont enregistrés avec succès",
    FindSurvey: "Rechercher un questionnaire",
    ReceiptDate: "Date de réception",
    RequestingDoctor: "Médecin demandeur",
    status: "status",
    EmptySurveyList: "Liste des questionnaires est vide.",

    Pending: "En attente",
    Answered: "Répondu",
    FindPatient: "Rechercher un patient",
    successSendSurvey: "Le questionnaire a bien été envoyé.",
    successAffectedSurvey: "Le questionnaire a réussi à être attribué.",
  },

  en: {
    InvalidNumber: "Invalid Number",
    successAffectedSurvey: "The questionnaire was successfully assigned.",
    successSendSurvey: "The survey has been sent.",
    NoAvailbleForMobile: "This module is not available on the mobile version.",
    Previous: "Previous",
    Next: "Next",
    PageNum: "Page N°",
    MyQuestionnaires: "My Surveys",
    NewSurvey: "New Survey",
    ResultsFound: "results found",
    NoResultFound: "No result found",
    NoSurveyFound: "Aucun survey trouvé",
    Ok: " Ok",
    Leave: "leave",
    surveySpace: "Survey publishing space",
    StartFromZero: "Start from scratch",
    AllYouNeed: "A blank template is all you need.",
    StartFromTemplate: "Start from a template",
    preRecordedForms: "Choose from your pre-recorded forms.",
    Active: "Active",
    LastUpdateOn: "Last update made on",
    Assign: "Assign",
    SelectAll: "Select all",
    SendSurvey: "Send this survey to the patient(s)",
    NA: "N/A",
    PostalAddress: "Postal Address:",
    PostalCode: "Postal code:",
    City: "City: ",
    Country: "Country:",
    Department: "Department:",
    Rue: "Rue",
    Name: "FirstNme LastName :",
    firstName: "FirstName",
    lastName: "LasttName",
    Email: "Email :",
    NumTel: "N° Phone :",
    NumPhone: "N° Phone :",
    NumSocial: "N° Social :",
    NumSecSocial: "N° Social Security",
    selectContry: "select a country",

    NonValidMail: "Invalid email",
    FieldIdenticalImages: "Field of identical images",
    select2identicalimg: "Select two identical images",
    the2identicalimg: "Perfect, the two images are identical!",
    mustSelect2img: "You must select 2 images",
    mustSelectSecondimg: "You must select a second image",
    imgAreNotIdentical: "The images are not identical",
    orUploadPic: "or upload an image: :",
    selectImage: "Selection image",
    loadImage: "load an image",
    RequireField: "This field is required",
    FieldInvalid: "Invalid field",
    TextZone: "text content",
    pageSettings: "Page settings",
    Remove: "Remove",
    AddSection: "Add a new section",
    ConfirmDeletePage: "Do you want to delete the page?",
    Cancel: "Cancel",
    Confirm: "Confirm",
    Save: "Save",

    Upload: "Upload",
    Page: "Page",
    Date: "Date",
    Signature: "Signature",
    Note: "Note",
    ResetForm: "Reset form",
    EditProperties: "Edit properties",
    Edit: "Edit",
    Preview: "Preview",
    ConditionalLogic: "Conditional logic",
    Deactivate: "Deactivate",
    Activate: "Activate",
    Action: "Action",
    AddCondition: "Add condition",
    Value: "Value",
    notYet: "Not yet ⌛⌛....",
    PersonalInformation: "Personal Information",
    Questions: "Questions",
    AddChoice: "Add Choice",
    AddQuestion: "Add Question",
    Choice: "Choice",
    ErrorMsg: "Error message",
    FieldParameter: "Field parameter",
    AdvancedSettings: "Advanced settings",
    AddOtherValidationParameters: "Add other validation parameters",
    selectSetting: "Please select a setting",
    SurveyDescription: "Survey Description...",
    SearchedWord: "Searched word",
    NumberOfCharacters: "Number of characters",
    MinimumNumberOfCharacters: "Minimum number of characters",
    MaximumNumberOfCharacters: "Maximum number of characters",
    Minimum: "Minimum",
    Maximum: "Maximum",
    SurveySummary: "Survey summary",
    Toolbox: "Toolbox",
    PredefinedFields: "Predefined fields",
    InputFields: "Input fields",
    InformationalFields: "Informational fields",
    NumberField:
      "Creating a number field. Only numeric values ​​can be entered.",

    DropDownList:
      "Creating a drop-down list with multiple answer options. User can only select one answer option.",
    Checkbox:
      "Creating a list of checkboxes. Multiple response options can be created. User can select multiple response options (unlike a radio button)",
    RadioButton:
      "Creating a list of radio buttons. Multiple response options can be created but the user can only select one",
    questionsGroup:
      "Creation of a group of questions according to the choices in the form of a list of radio buttons. Multiple response options can be created but the user can only select one",
    CreationOfScaleNumeric:
      "Creation of a numerical scale. The limits of the scale can be fixed by the minimum and maximum values. The interval between two graduations is customizable",

    CreateATextBox: "Create a text box.",
    CreatingATextField: "Création d’un champ texte.",
    TextField: "Text field",
    NumericField: "Numeric field",
    ZoneText: "Text zone",
    DropdownList: "Dropdown list",
    MultipleChoice: "Multiple choice",
    SimpleChoice: "Simple choice",
    Gate: "Gate",
    Photo: "Photo",
    CreatingDateField: "Creating a date field",

    CursorField: " Cursor field",
    Identify: "Identify",
    FullAddress: "Full address",

    AddNameDescriptionToSurvey:
      "Give your questionnaire a name and description",
    SurveyName: "Survey name",
    SurveyDescription1: "Survey description",

    SelectSurveyForm: "Select your questionnaire layout",
    ChoiceSurveyForm: "Choose a layout according to your needs",
    classicSurvey: "Standard questionnaire",
    ShowSurvey: "Display all questions on one or more pages.",
    CardSurvey: "Card questionnaire",
    oneSurveyPerPage: "Display only one question per page.",

    SuccessSavesSrveyResponse: "Your answers are saved successfully",
    FindSurvey: "Find a survey",
    ReceiptDate: "Date of receipt",
    RequestingDoctor: " Requesting doctor",
    status: "status",
    EmptySurveyList: "List of surveys is empty.",
    Pending: "Pending",
    Answered: "Answered",
    FindPatient: "Find a patient",
  },
};
