import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import Button from "../../ButtonUI";
import TextArea from "../../TextArea";
// import FloatInput from "../../FloatInput";
import "./styles.scss";
// import SchemaAddTraitement from "./schemaAddraitement";
import { addObservationToTreatements } from "../../../api/treatements";
import { userAtom } from "../../../state/auth";
// import { User } from "../../../types/user";
import { ReactComponent as Close } from "../../../assets/icons/icon-close.svg";
import { useAtom } from "jotai";
import { addObservationToAntecedents } from "../../../api/antecedents";
import {
  IAntecedents,
  IAntecedentsChirugical,
  ITreatements,
} from "../../../types/patient";
// import Treatements from "../../../pages/Patient/Treatemets";
import makeToast from "../../Snackbar";

interface Props {
  openModal: boolean;
  setOpenModal: Function;
  doctorId?: string;
  selectedPatient: string;
  selectedObservationId: string;
  typeOfAddObservation?: string;
  setTreatementEnCours?: Function;
  setTreatementsAncien?: Function;
  treatementEnCours?: ITreatements[];
  treatementsAncien?: ITreatements[];
  setAntecedentsList?: Function | any;
  setAntecedentsChrirugicalList?: Function | any;
  antecedentsList?: IAntecedents[];
  antecedentsChrirugicalList?: IAntecedentsChirugical[];
  setTypeOfAddObservation: Function;
}

const AddObservation: React.FC<Props> = ({
  setTypeOfAddObservation,
  openModal,
  setOpenModal,
  selectedPatient,
  selectedObservationId,
  typeOfAddObservation,
  setTreatementEnCours,
  setTreatementsAncien,
  treatementEnCours,
  treatementsAncien,
  antecedentsList,
  setAntecedentsList,
  antecedentsChrirugicalList,
  setAntecedentsChrirugicalList,
}) => {
  const [user] = useAtom(userAtom);
  const { t } = useTranslation() as any;
  const makeToastFN = (res) => {
    res?.message === "Success"
      ? makeToast("success", t("ModalFichePatient.SuccessAddSurvey"))
      : makeToast("error", t("ModalFichePatient.FailAddObservation"));
  };
  useEffect(() => {
    return () => {
      setTypeOfAddObservation("");
    };
  });

  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute bg-white z-40 top-1/3   addTraitementModal">
        <div className="relative ">
          <div className="absolute right-0 mt-2 mr-2">
            <Button status="icon-secondary" onClick={() => setOpenModal(false)}>
              <Close />
            </Button>
          </div>
        </div>
        <Formik
          initialValues={{
            observation: "",
          }}
          onSubmit={async (values) => {
            try {
              setOpenModal(!openModal);
              let res = {} as any;
              if (
                typeOfAddObservation === "antecedents" ||
                typeOfAddObservation === "antecedentsChirugical"
              ) {
                res = await addObservationToAntecedents({
                  ...values,
                  typeOfAddObservation,
                  doctorId: user && user._id,
                  patientId: selectedPatient,
                  observationId: selectedObservationId,
                });
                makeToastFN(res);
                setOpenModal(false);
                const { antecedentMedicaux } = res;

                if (
                  (res?.antecedentMedicaux?.nature === "Antécédent Médical" ||
                    res?.antecedentMedicaux?.nature === "Allergie") &&
                  antecedentsList
                ) {
                  const newArray = antecedentsList.filter(
                    (e) => e._id !== antecedentMedicaux?._id
                  );

                  makeToastFN(res);

                  setAntecedentsList([...newArray, antecedentMedicaux]);
                } else {
                  const { antecedentChirurgicaux } = res as any;
                  const newArray: IAntecedentsChirugical | any =
                    antecedentsChrirugicalList &&
                    antecedentsChrirugicalList.filter(
                      (e) => e?._id !== antecedentChirurgicaux?._id
                    );
                  makeToastFN(res);
                  setAntecedentsChrirugicalList([
                    ...newArray,
                    antecedentChirurgicaux,
                  ]);
                }
              } else {
                res = await addObservationToTreatements({
                  ...values,
                  doctorId: user && user._id,
                  patientId: selectedPatient,
                  observationId: selectedObservationId,
                });
                makeToastFN(res);
                setOpenModal(false);
                const { treatement } = res as any;
                if (
                  treatement.traitement === "Traitement en cours" &&
                  treatementEnCours
                ) {
                  const newArray = treatementEnCours.filter(
                    (e) => e._id !== treatement?._id
                  );
                  makeToastFN(res);
                  setTreatementEnCours &&
                    setTreatementEnCours([...newArray, treatement]);
                } else {
                  const newArray: ITreatements[] | any =
                    treatementsAncien &&
                    treatementsAncien.filter((e) => e._id !== treatement?._id);
                  setTreatementsAncien &&
                    setTreatementsAncien([...newArray, treatement]);
                  makeToastFN(res);
                }
              }

              // setUser({
              //   ...user,
              //   patient: {
              //     ...patient,
              //     treatements: !_.isEmpty(user?.patient?.treatements)
              //       ? [...user?.patient.treatements, treatement]
              //       : treatement,
              //   },
              // } as User);
            } catch (err) {
              makeToast("error", t("ModalFichePatient.FailAddObservation"));
            }
          }}
          //validationSchema={SchemaAddTraitement}
        >
          {(props) => {
            const {
              values,
              // errors,
              // isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;

            return (
              <form
                onSubmit={handleSubmit}
                className="flex-auto py-7 px-8 min-w-full	"
              >
                <div className="flex flex-wrap items-baseline">
                  <h1 className="mb-10">
                    {t("ModalFichePatient.AddObservation")}
                  </h1>
                </div>
                <div className="">
                  <TextArea
                    id="observation"
                    placeholder={t("ModalFichePatient.TypeObservation")}
                    //labelText="Type"
                    //error={values.observation}
                    value={values.observation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inlineLabel={false}
                    onfocus={true}
                  />
                </div>
                <Button
                  status="secondary"
                  width="100px"
                  height="50px"
                  className="float-right mb-4"
                  onClick={() => handleSubmit()}
                >
                  {t("ModalFichePatient.Confirm")}
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddObservation;
