import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "../../../helper/moment";
import Button from "../../../components/ButtonUI";
import { ReactComponent as Capsule } from "../../../assets/emoji/emoji-capsule.svg";
import "./styles.scss";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & { task: any; reFetchTask: Function; openPrescriptionModal: any };

const Index: React.FC<Props> = ({ task, openPrescriptionModal }) => {
  const { t } = useTranslation() as any;
  const history = useHistory();
  const handleAction = () =>
    task.type === "report"
      ? history.push(`/consultation-report/${task.consultation._id}`)
      : openPrescriptionModal(task);

  return (
    <>
      <div className="tache-card-doctor box-border flex shadow-md bg-white rounded-lg  my-6 mr-6 py-5">
        <div className="rounded-full h-14 w-14 flexCenter bg-gray-200 mx-5 my-auto">
          {task.type === "report" ? (
            <img
              crossOrigin="anonymous"
              referrerPolicy="origin"
              src={require("../../../assets/images/pen.png").default}
              alt=""
              width="24"
            />
          ) : (
            <Capsule />
          )}
        </div>
        <div className="flex-grow ">
          <p className="card-title">{`${task.patient?.firstName} ${task.patient?.lastName}`}</p>
          <p className="card-subtitle mb-2">
            {t("DashboardDoctor.ConsultationOf")}{" "}
            {moment(task.dateConsultation).format(t("Moment.Z"))}
          </p>

          <span className="box">{task.motif}</span>
        </div>
        <div className="flex items-center space-x-3 mr-3">
          <Button
            status="secondary"
            onClick={handleAction}
            width="145px"
            height="60px"
          >
            {task.type === "report"
              ? t("DashboardDoctor.Report")
              : t("DashboardDoctor.Prescription")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default Index;
