/* eslint-disable array-callback-return */
import { useAtom } from "jotai";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import moment from "../../../helper/moment";
import { useEffect, useState } from "react";
import Button from "../../../components/ButtonUI";
import Icon from "../../../components/Icon";
import { userAtom } from "../../../state/auth";
// import { cardMedical } from "../cardMedicalData.json";
import AddDocuments from "../modal/AddDocuments";
import { loadImage } from "../../../api/documents";
import history from "../../../history";
import pen from "../../../assets/images/pen.png";

interface Props {}

// const groups = cardMedical.reduce((groups, el) => {
//   if (!groups[el.date]) {
//     groups[el.date] = [];
//   }
//   groups[el.date].push(el);
//   return groups;
// }, {});

// Edit: to add it in the array format instead
// const groupArrays = Object.keys(groups).map((date) => {
//   return {
//     date,
//     data: groups[date],
//   };
// });

const parseImage = (filename, userId, type) => ({
  original: loadImage(userId, type, "thumbnail", filename),
});

const DocumentsTab = (props: Props) => {
  const { t } = useTranslation() as any;
  const [modalAddDocs, setModalAddDocs] = useState(false);
  const [addCategorieDocMenu, setAddCategorieDocMenu] = useState(false);
  const [selectedCategorie, setSelectedCategorie] = useState("");
  const [filtredConsultationDocuments, setFiltredConsultationDocuments] =
    useState<any>([]);

  const [user] = useAtom(userAtom) as any;
  const helperCategorie = (type) => {
    setAddCategorieDocMenu(false);
    setModalAddDocs(true);
    setSelectedCategorie(type);
  };
  const handleDocTypeName = (type) => {
    switch (type) {
      case "Compte rendu":
        return t("MyFolder.Report");
      case "Préscriptions":
        return t("MyFolder.Prescription");
      default:
        return type;
    }
  };
  useEffect(() => {
    const tempConsultation = [] as any;
    if (user)
      user?.consultations.map((e) => {
        // if (e.prescriptions?.length > 0 && e.rapport?.length > 0) {
        //   tempConsultation.push({
        //     ...e,
        //     date: e.dateConsultation.slice(0, 10),
        //     type: "Résultat d’examen",
        //   });
        // } else {
        if (e.prescriptions && e.prescriptions.length > 0) {
          e.prescriptions.map((pr) => {
            tempConsultation.push({
              ...e,
              pr,
              date: e.dateConsultation.slice(0, 10),
              type: "Préscriptions",
            });
          });
        }
        if (e.secondPrescriptions && e.secondPrescriptions.length > 0) {
          tempConsultation.push({
            ...e,
            date: e.dateConsultation.slice(0, 10),
            type: "Préscriptions",
            secondPrescriptions: true,
          });
        }
        if (e.rapport) {
          tempConsultation.push({
            ...e,
            date: e.dateConsultation.slice(0, 10),
            type: "Compte rendu",
          });
        }
        // }
      });

    const newConsultations = user
      ? [
          ...tempConsultation,
          ...user?.documents.map((e) => {
            return {
              ...e,
              date: e.date && e.date.slice(0, 10),
            };
          }),
        ]
      : [];

    const groups = newConsultations.reduce((groups, el) => {
      if (!groups[el.date]) {
        groups[el.date] = [];
      }
      groups[el.date].push(el);
      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        data: groups[date],
      };
    });

    setFiltredConsultationDocuments(groupArrays);
  }, [user]);

  return (
    <div>
      {/* Filter */}
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-5">
          {/* {filterList.map((el) => (
            <span className="box flex space-x-3">
              <span>{el}</span>
              <span onClick={() => {}}>x</span>
            </span>
          ))} */}
        </div>
        <div className="flex items-center space-x-3">
          <div className="relative">
            <Button
              status="secondary"
              width={"200px"}
              height={"40px"}
              className="min-h-full mr-5"
              onClick={() => setAddCategorieDocMenu(!addCategorieDocMenu)}
            >
              {t("MyFolder.AddDocument")}
            </Button>
            <div
              className={`shadow-lg py-3 z-10 absolute top-10 left-4 bg-white min-w-max rounded-2xl ${
                addCategorieDocMenu ? "block" : "hidden"
              }`}
            >
              <ul className="p-2">
                <li
                  className="cursor-pointer"
                  onClick={() => helperCategorie("Biologique")}
                >
                  {t("MyFolder.Biology")}
                </li>
                <li
                  className="mt-4 mb-4 cursor-pointer"
                  onClick={() => helperCategorie("Radiologique")}
                >
                  {t("MyFolder.Radiology")}
                </li>
                <li
                  className="cursor-pointer"
                  onClick={() => helperCategorie("Autres")}
                >
                  {t("MyFolder.Other")}
                </li>
              </ul>
            </div>
          </div>
          {/* <Icon isIcon={true} name="monTableau-select" />
          <span className="card-subtitle">|</span>
          <Icon isIcon={true} name="monTableau-select" /> */}
        </div>
      </div>
      {/* Map Card By Date */}
      {_.isEmpty(filtredConsultationDocuments) ? (
        <div className="m-auto text-center">
          <h3> {t("MyFolder.NoDocument")}</h3>
        </div>
      ) : (
        filtredConsultationDocuments
          .sort(
            (a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf()
          )
          .map((el) => {
            return (
              <div className="">
                <h3 className="my-7 capitalize">
                  {moment(el.date).format("dddd") +
                    " " +
                    moment(el.date).format("LL")}
                </h3>
                <div className="flex gap-4 flex-wrap justify-center lg:justify-start">
                  {el.data.map((el) => (
                    <div
                      className={`sm:w-64 w-full bg-white flex flex-col justify-between pt-5 pb-2 pr-3 pl-3 rounded-2xl ${
                        (el.type === "Préscriptions" ||
                          el.type === "Compte rendu") &&
                        "cursor-pointer"
                      }`}
                      onClick={() => {
                        if (el.type === "Préscriptions") {
                          history.push(`/preview/${el._id}/${el.pr._id}`);
                        }
                        if (el.type === "Compte rendu") {
                          history.push(`/previewCompteRendu/${el._id}`);
                        }
                      }}
                    >
                      <span className="text-xs font-bold mb-5">
                        {el.type === "Radiologique" ? (
                          t("MyFolder.Radiology")
                        ) : el.type === "Biologique" ? (
                          t("MyFolder.Biology")
                        ) : el.type === "Préscriptions" ? (
                          <span className="flex gap-2 items-center">
                            {" "}
                            <Icon
                              name={"capsule"}
                              isIcon={false}
                              height={20}
                              width={20}
                            />
                            <span> {handleDocTypeName(el.type)}</span>
                          </span>
                        ) : el.type === "Compte rendu" ? (
                          <span className="flex gap-2 items-center">
                            {" "}
                            <img
                              crossOrigin="anonymous"
                              referrerPolicy="origin"
                              src={pen}
                              alt="pen"
                              width="20px"
                              height="20px"
                            />
                            <span> {handleDocTypeName(el.type)}</span>
                          </span>
                        ) : (
                          handleDocTypeName(el.type)
                        )}
                      </span>
                      {el.type === "Radiologique" ||
                      el.type === "Biologique" ||
                      el.type === "Autres" ? (
                        <div className="image-consultation overflow-hidden">
                          <img
                            crossOrigin="anonymous"
                            referrerPolicy="origin"
                            src={
                              parseImage(el.filePath, el.patientId, el.type)
                                .original as any
                            }
                            alt="medical-data"
                            className="object-cover rounded-lg"
                            style={{ height: "92px", width: "243.8px" }}
                          />
                        </div>
                      ) : (
                        <span className="card-title leading-none">
                          {t("MyFolder.ReportAt")} <br />
                          <span className="font-normal">
                            {moment(el.date).format("L")}
                          </span>
                        </span>
                      )}
                      <span className="mt-4 card-subtitle leading-none">
                        {t("MyFolder.UpdatedAt")}{" "}
                        <span className="font-bold">
                          {moment(el.updatedAt).format("LLLL")}
                        </span>{" "}
                        {t("MyFolder.by")} {el.doctorId && t("MyFolder.le")}{" "}
                        {!el.doctorId ? (
                          t("MyFolder.myself")
                        ) : (
                          <span className="font-bold">
                            {t("MyFolder.Dr")}
                            {el.doctorId && el.doctorId.firstName}{" "}
                            {el.doctorId && el.doctorId.lastName}
                          </span>
                        )}
                      </span>
                      {el.motif && <span className="box mt-2">{el.motif}</span>}
                    </div>
                  ))}
                </div>
              </div>
            );
          })
      )}
      {modalAddDocs && (
        <AddDocuments
          openModal={modalAddDocs}
          setOpenModal={setModalAddDocs}
          type={selectedCategorie}
        />
      )}
    </div>
  );
};

export default DocumentsTab;
