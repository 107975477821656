/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDrop } from "react-dnd";

import { useAtom } from "jotai";
import SortableElement from "../ToolBar/SortableElement";
import PropertyEditor from "../PropertyEditor";
import ItemTypes from "../ToolBar/ItemTypes";
import { Formiz } from "../StepperWrapper/Formiz";
import { elementAtom } from "../../../../state/FormGeneratorState/elmentAtom";
import { elementAtomData } from "../../../../state/FormGeneratorState/elmentAtomdata";

export const DropZone = ({ elements, onUpdate, formProps, ...props }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [currentElement, setCurrentElement]: any = useState(null);
  const [, setAtomElment] = useAtom(elementAtom);
  const [, setAtomElmentData] = useAtom(elementAtomData);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [{ canDrop, isOver, draggedItem }, drop] = useDrop(() => ({
    accept: ItemTypes.CARD,
    drop: (item, monitor) => item,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      draggedItem: monitor.getDropResult(),
    }),
  }));
  const editHandler = (element, index) => {
    setCurrentElement(element);
    setCurrentIndex(index);
    setAtomElment(element.id);
    setAtomElmentData(element);
    setVisible(true);
  };

  useEffect(() => {
    const fc = props.formConfig.sections[props.stepNbr].task_data;

    if (draggedItem && elements && onUpdate)
      if (draggedItem.id) {
        onUpdate([...fc, draggedItem]);
        editHandler(draggedItem, elements.length);
        setCurrentElement(draggedItem);
        setAtomElment(draggedItem.id);
        setAtomElmentData(draggedItem);
      }
  }, [isOver]);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      let elems = [...elements];
      [elems[dragIndex], elems[hoverIndex]] = [
        elems[hoverIndex],
        elems[dragIndex],
      ];
      onUpdate(elems);
    },
    [elements, onUpdate]
  );

  const deleteHandler = (element, index) => {
    onUpdate?.(elements.filter((element, i) => index !== i));
  };

  const submitHandler = (values) => {
    const element = { ...currentElement, ...values };

    onUpdate?.(elements.map((e, i) => (currentIndex !== i ? e : element)));
  };

  const isActive = canDrop && isOver;
  let backgroundColor = "#fff";
  if (isActive) {
    backgroundColor = "#fafafa";
  } else if (canDrop) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    backgroundColor = "#fafafa";
  }

  return (
    <div
      ref={drop}
      className=" w-full  rounded-lg py-2 px-2 h-screen border-t border-b border-r  overflow-y-auto   mb-6"
    >
      <Formiz>
        {elements.map((element, index) => {
          return (
            <SortableElement
              key={element.id}
              index={index}
              element={element}
              onMove={moveCard}
              onEdit={editHandler}
              onDelete={deleteHandler}
              readOnly={true}
            />
          );
        })}
        {elements.length === 0 && (
          <div
            className="border-dashed border-2  p-8 rounded-lg "
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "16px",
              borderColor: "#4565f6",
              backgroundColor: "#f5f5f9",
            }}
          >
            {t("SurveyIntl.dndMsg")}
          </div>
        )}
      </Formiz>
      <PropertyEditor
        show={visible}
        element={currentElement}
        onClose={(e) => setVisible(false)}
        onSubmit={submitHandler}
        elements={elements}
      ></PropertyEditor>
    </div>
  );
};
