import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/ButtonUI";
import "./styles.scss";
import { addOrModifyLangues } from "../../../api/doctor";
import { Formik } from "formik";
import { User } from "../../../types/user";
import { userAtom } from "../../../state/auth";
import { useAtom } from "jotai";
import Input from "../../../components/Input";
import makeToast from "../../../components/Snackbar";
import Icon from "../../../components/Icon";

interface Props {
  openModal: boolean;
  setOpenModal: Function;
  doctorId?: string;
  languesDoctor?: string[];
}

export const AddLangue: React.FC<Props> = ({
  openModal,
  setOpenModal,
  languesDoctor,
}) => {
  const { t } = useTranslation() as any;
  const [user, setUser] = useAtom(userAtom);
  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute bg-white z-40 top-1/3 flexCente cursusAdd w-11/12 md:w-1/2">
        <div className="flex justify-end cursor-pointer pt-5 pr-5">
          <Icon
            isIcon={true}
            name="close"
            onClick={() => setOpenModal(!openModal)}
            className="bg-gray-100 rounded-xl cursor-pointer hover:bg-gray-200"
          />
        </div>
        <Formik
          initialValues={{
            langues: user?.langues || [],
          }}
          onSubmit={async (values) => {
            try {
              setOpenModal(!openModal);
              const newUser = await addOrModifyLangues({ ...values, user });
              const { updatedDoctor } = newUser;
              setUser({
                ...user,
                langues: updatedDoctor.langues,
              } as User);
              if (updatedDoctor._id) {
                makeToast("success", t("Profile.SuccessAddLanguage"));
              } else {
                makeToast("error", t("Profile.FailedAddLanguage"));
              }
            } catch (err) {}
          }}
        >
          {(props) => {
            const {
              values,
              // errors,
              // isSubmitting,
              handleChange,
              // handleBlur,
              handleSubmit,
            } = props;

            return (
              <form
                onSubmit={handleSubmit}
                className="flex-auto pb-7 px-8 min-w-full	"
              >
                <div className="flex flex-wrap items-baseline">
                  <h1 className="mb-10"> {t("Profile.AddLanguage")}</h1>
                </div>
                <div className="ml-8 mb-8">
                  <Input
                    name="langues"
                    type="checkbox"
                    onChange={handleChange}
                    value="Français"
                    labelText="Français"
                    labelProps={{ className: "check" }}
                    checked={
                      values.langues.find((e) => e === "Français")
                        ? true
                        : false
                    }
                    className="my-2"
                  />
                  <Input
                    name="langues"
                    type="checkbox"
                    onChange={handleChange}
                    value="Anglais"
                    labelText="Anglais"
                    labelProps={{ className: "check" }}
                    checked={
                      values.langues.find((e) => e === "Anglais") ? true : false
                    }
                    className="my-2"
                  />
                  <Input
                    name="langues"
                    type="checkbox"
                    onChange={handleChange}
                    value="Allemand"
                    labelText="Allemand"
                    labelProps={{ className: "check" }}
                    checked={
                      values.langues.find((e) => e === "Allemand")
                        ? true
                        : false
                    }
                    className="my-2"
                  />
                  <Input
                    name="langues"
                    type="checkbox"
                    onChange={handleChange}
                    value="Espagnol"
                    labelText="Espagnol"
                    labelProps={{ className: "check" }}
                    checked={
                      values.langues.find((e) => e === "Espagnol")
                        ? true
                        : false
                    }
                    className="my-2"
                  />
                  <Input
                    name="langues"
                    type="checkbox"
                    onChange={handleChange}
                    value="Arabe"
                    labelText="Arabe"
                    labelProps={{ className: "check" }}
                    checked={
                      values.langues.find((e) => e === "Arabe") ? true : false
                    }
                    className="my-2"
                  />

                  <Input
                    name="langues"
                    type="checkbox"
                    onChange={handleChange}
                    value="Russe"
                    labelText="Russe"
                    labelProps={{ className: "check" }}
                    checked={
                      values.langues.find((e) => e === "Russe") ? true : false
                    }
                    className="my-2"
                  />
                </div>
                <Button
                  status="secondary"
                  width="100px"
                  height="50px"
                  className="float-left mb-4 btn-delete"
                  onClick={() => setOpenModal(false)}
                >
                  {t("Profile.Cancel")}
                </Button>
                <Button
                  status="secondary"
                  width="100px"
                  height="50px"
                  className="float-right mb-4"
                >
                  {t("Profile.Save")}
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddLangue;
