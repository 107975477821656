/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useAtom } from "jotai";
//**Helper & API */
import { tabs } from "./fakeData";
import { imcStatus } from "../../helper/imc";
import { ITreatements } from "../../types/patient";
import { userAtom } from "../../state/auth";
import { User } from "../../types/user";
import { updateDataInfoPatient } from "../../api/doctor";
import { getWaitingTasksOfPatient } from "../../api/task";
import { endTreatements } from "../../api/treatements";
import { previousState } from "../../state/previousPageState";
//**Components */
import AddDocuments from "../../pages/MyFolderPatient/modal/AddDocuments";
import AddObservation from "./modal/addObservation";
import ShowObservations from "./modal/showObservations";
import DisplayRaport from "./modal/displayRaport";
import DisplayPrescription from "./modal/displayPrescriptions";
import Statistic from "./chart";
import Taches from "./Taches";
import Historiques from "./Historiques";
import ChatDoctorPatient from "../SharedChat";
import Modal from "../ModalUI";
import ImageModal from "../ImageModal";
import Spinner from "../Spinner";
import makeToast from "../Snackbar";
import AddTraitement from "./modal/addTraitement";
import AddAntecedentsMedicaux from "./modal/AddAntecedentsMedicaux";
import AddAntecedentChirurgicaux from "./modal/AddAntecedentsChirurgicaux";
import Constantes from "./constantes";
import Header from "./Header";
import Documents from "./documents";
import Antecedents from "./antecedents";
import Traitements from "./traitements";
import Survey from "./Survey";
import INS from "./INS";
//**Assets */
import "./styles.scss";
import Tooltip from "../toolTip";
import DMP from "./DMP";
import { useTranslation } from "react-i18next";

interface IProps {
  setOpenModal: Function;
  patientData: any;
  consultaionPage?: boolean;
  colorIndex?: number;
  selectedPatient: string;
  setModal: Function;
  setPatientData: {} | any;
  setPatientAdded?: Function;
}

const PatientInfo: React.FC<IProps> = ({
  patientData,
  setOpenModal,
  consultaionPage,
  colorIndex,
  selectedPatient,
  setModal,
  setPatientData,
  setPatientAdded,
}) => {
  const { t } = useTranslation() as any;
  const [refreshPrescription, setRefreshPrescription] = useState(false);
  const [documentsList, setDocumentsList] = useState([]);
  const [addAntecendentModal, setAddAntecendentModal] = useState(false);
  const [addAntecendentChrirgicauxModal, setAddAntecendentChrirgicauxModal] =
    useState(false);
  const [antecedentsList, setAntecedentsList] = useState([]) as any;
  const [antecedentsChrirugicalList, setAntecedentsChrirugicalList] = useState(
    []
  ) as any;

  const [SwitchToChatModal, setSwitchToChatModal] = useState(false);
  const [openTab, setOpenTab] = useState(1);
  const [display, setDisplay] = useState(true);
  const [, setCalculIMC] = useState(0);
  const [user] = useAtom<User | any>(userAtom);
  const [treatementEnCours, setTreatementEnCours] = useState<ITreatements[]>(
    []
  );
  const [treatementsAncien, setTreatementsAncien] = useState<ITreatements[]>(
    []
  );
  const [treatementType, setTreatementType] = useState("");
  const [observationModal, setObservationModal] = useState(false);
  const [showObservationsModal, setShowObservationsModal] = useState(false);
  const [typeObservation, setTypeObservation] = useState({
    typeObservation: "",
    idObservation: "",
  });
  const [modalTraitement, setModalTraitement] = useState(false);
  const [selectedObservationId, setSelectedObservationId] = useState("");
  const [typeOfAddObservation, setTypeOfAddObservation] = useState("");

  const [modalAddDocs, setModalAddDocs] = useState(false);
  const [modalDisplayRaport, setModalDisplayRaport] = useState(false);
  const [modalDisplayPrescription, setModalDisplayPrescription] =
    useState(false);
  const [selectedCompteRendu] = useState({});
  const [addCategorieDocMenu, setAddCategorieDocMenu] = useState(false);
  const [selectedCategorie, setSelectedCategorie] = useState("");
  const helperCategorie = (type) => {
    setAddCategorieDocMenu(false);
    setModalAddDocs(true);
    setSelectedCategorie(type);
  };
  const [selectedInput, setSelectedInput] = useState("");
  const [saturation, setSaturation] = useState({
    value: 0,
    updatedAt: "",
  }) as any;

  const [valueSelectedInput, setValueSelectedInput] = useState<any>();
  const [pouls, setPouls] = useState<any>({
    value: patientData?.pouls?.value ? patientData?.pouls?.value : 0,
    temp: patientData?.pouls?.value ? patientData?.pouls?.value : 0,
    perMinute: patientData?.pouls?.perMinute
      ? patientData?.pouls?.perMinute
      : 0,
  });
  const [Temperaturepouls, setTemperature] = useState<any>({
    degree: patientData?.Temperature?.degree
      ? patientData.Temperature.degree
      : 0,
    time: patientData?.Temperature?.time
      ? patientData.Temperature.time
      : "00:00",
  });
  const [size, setSize] = useState<any>(0);
  const [weight, setWeight] = useState<any>(
    patientData.weight ? patientData.weight : 0
  );
  const [, setConsultation] = useState(false);
  const [prescriptions, setPresecriptionList] = useState<any>([]);
  const [imageUrl, setImageUrl] = useState({
    url: "",
    type: "",
  });
  const [modalImage, setModalImage] = useState(false);
  const [previousStates, setPreviousStates] = useAtom(previousState) as any;

  const endTreatment = async (treatment) => {
    try {
      const res = await endTreatements(treatment._id);
      if (res?.message === "Success") {
        setTreatementEnCours(
          treatementEnCours.filter((el) => el._id !== treatment._id)
        );
        setTreatementsAncien([
          ...treatementsAncien,
          { ...treatment, traitement: "Ancien Traitement" },
        ]);
      }
    } catch (error) {}
  };

  const updateField = async () => {
    if (
      selectedInput === "poulsValue" ||
      selectedInput === "poulsValueRa" ||
      selectedInput === "perMinute"
    ) {
      const response = await updateDataInfoPatient({
        pouls,
        patientId: patientData._id,
      });
      if (response.status === "Success")
        makeToast("success", t("MyPatient.SuccessUpdate"));
      else if (response.status === "failure") {
        makeToast("error", t("MyPatient.PatientUpdateFailed"));
      }
    } else if (
      selectedInput === "Temperaturepouls" ||
      selectedInput === "TemperatureTime"
    ) {
      const response = await updateDataInfoPatient({
        Temperaturepouls: {
          ...Temperaturepouls,
          time: Temperaturepouls.time,
        },
        patientId: patientData._id,
      });
      if (response.status === "Success")
        makeToast("success", t("MyPatient.SuccessUpdate"));
      else if (response.status === "failure") {
        makeToast("error", t("MyPatient.PatientUpdateFailed"));
      }
    } else if (selectedInput === "size") {
      const response = await updateDataInfoPatient({
        size: size === "" ? 0 : size,
        patientId: patientData._id,
      });
      if (response.status === "Success") {
        makeToast("success", t("MyPatient.SuccessUpdate"));
      } else if (response.status === "failure") {
        makeToast("error", t("MyPatient.PatientUpdateFailed"));
      }
    } else if (selectedInput === "Saturation") {
      const response = await updateDataInfoPatient({
        saturation,
        patientId: patientData._id,
      });
      if (response.status === "Success") {
        makeToast("success", t("MyPatient.SuccessUpdate"));
      } else if (response.status === "failure") {
        makeToast("error", t("MyPatient.PatientUpdateFailed"));
      }
    } else {
      const response = await updateDataInfoPatient({
        weight: weight === "" ? 0 : weight,
        patientId: patientData._id,
      });
      if (response.status === "Success")
        makeToast("success", t("MyPatient.SuccessUpdate"));
      else if (response.status === "failure") {
        makeToast("error", t("MyPatient.PatientUpdateFailed"));
      }
    }
    setSelectedInput("");
    setValueSelectedInput(null);
  };

  /** Constantes vitales & Données générales  */
  useEffect(() => {
    if (patientData?.saturation?.value)
      setSaturation({
        value: patientData?.saturation?.value
          ? patientData?.saturation?.value
          : 0,
        updatedAt: patientData?.saturation?.updatedAt
          ? patientData?.saturation?.updatedAt
          : "",
      });
  }, [patientData]);

  useEffect(() => {
    if (patientData.size === "0") {
      setCalculIMC(0);
    } else if (
      patientData.size &&
      patientData.weight &&
      patientData.size !== 0
    ) {
      const dividedSize = patientData.size / 100;
      const imc = patientData.weight / (dividedSize * dividedSize);
      setCalculIMC(imc);
    }
  }, []);

  useEffect(() => {
    if (size === "0") {
      setCalculIMC(0);
    } else if ((size || weight) && size !== 0) {
      const dividedSize = size / 100;
      const imc = weight / (dividedSize * dividedSize);
      setCalculIMC(imc);
    }
  }, [size, weight]);

  useEffect(() => {
    setSize(patientData.size ? patientData.size : 0);
    setWeight(patientData.weight ? patientData.weight : 0);
    setPouls({
      value: patientData?.pouls?.value ? patientData?.pouls?.value : 0,
      temp: patientData?.pouls?.temp ? patientData?.pouls?.temp : 0,
      perMinute: patientData?.pouls?.perMinute
        ? patientData?.pouls?.perMinute
        : 0,
    });
    setTemperature({
      degree: patientData?.Temperature?.degree
        ? patientData.Temperature.degree
        : 0,
      time: patientData?.Temperature?.time
        ? patientData.Temperature.time
        : "00:00",
    });
    return () => {
      setSize(0);
      setWeight(0);
    };
  }, [patientData]);

  useEffect(() => {
    if (consultaionPage) setConsultation(consultaionPage ? true : false);
  }, [consultaionPage]);

  /** End  Constantes vitales & Données générales */

  /** Prescription */
  useEffect(() => {
    if (patientData.tasks) {
      const newArrayPrescription = [] as any;
      patientData.tasks.map((task) => {
        if (
          task?.consultation?.prescriptions &&
          task?.consultation?.prescriptions.length !== 0 &&
          task?.status === "done" &&
          task?.type === "prescription"
        ) {
          task?.consultation?.prescriptions?.map((prescription) => {
            newArrayPrescription.push({ ...task, id: prescription._id });
          });
        }
      });

      setPresecriptionList(newArrayPrescription);
    }
  }, [patientData, refreshPrescription]);

  /** Treatements */
  useEffect(() => {
    if (patientData?.treatements) {
      let tCours = [] as any;
      let tAncien = [] as any;
      patientData?.treatements.forEach((e) => {
        if (e.traitement === "Traitement en cours") {
          tCours.push(e);
        } else {
          tAncien.push(e);
        }
      });
      setTreatementEnCours(tCours);
      setTreatementsAncien(tAncien);
    }
  }, [patientData, user]);

  /** Tasks */
  useEffect(() => {
    async function fetchMyAPI() {
      let response: any = await getWaitingTasksOfPatient(
        selectedPatient,
        user?._id
      );
      const { patient, tasks } = response;
      if (response.status !== "Success") {
        makeToast("error", t("MyPatient.FailLoad"));
      }
      setPatientData({ ...patient, tasks });
      setAntecedentsList(patient.antecedents);
      setAntecedentsChrirugicalList(patient.antecedentsChirugical);
      setDocumentsList(patient.documents);
    }
    if (selectedPatient) {
      fetchMyAPI();
      setModal(true);
      setPatientAdded && setPatientAdded(false);
    }
  }, [selectedPatient, openTab, refreshPrescription]);

  useEffect(() => {
    if (previousStates?.patientFile?.openTab) {
      setOpenTab(previousStates?.patientFile?.openTab);
      setPreviousStates({
        patientFile: null,
      });
    }
    if (previousStates?.filePatientTasks?.openTab) {
      setOpenTab(previousStates?.filePatientTasks?.openTab);
      setPreviousStates({
        filePatientTasks: null,
      });
    }
  }, [previousStates]);

  return SwitchToChatModal ? (
    <ChatDoctorPatient
      showModal={SwitchToChatModal}
      chatWithId={patientData?._id}
      closeChatModal={() => setSwitchToChatModal(false)}
    />
  ) : (
    <div
      className={classNames(
        !consultaionPage &&
          "fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter "
      )}
    >
      {patientData._id ? (
        <div className="absolute  inset-y-0 right-0 z-40 transition ease-in duration-700 patient-modal-info ">
          <div
            className={`sub-container ${
              display ? "overflow-hidden" : "max-h-content"
            }`}
          >
            <Header
              colorIndex={colorIndex}
              patientData={patientData}
              consultaionPage={consultaionPage}
              setOpenModal={setOpenModal}
              setSwitchToChatModal={setSwitchToChatModal}
              setOpenTab={setOpenTab}
            />

            <div className={`mt-5 patient-tabs ${!display ? "stretch" : null}`}>
              <div className="tabs flex items-center justify-between">
                {tabs
                  // .filter((el) => tenant)
                  .map((tab) => {
                    return (
                      <div
                        className={
                          openTab === tab.id
                            ? "tab selected flexCenter"
                            : "tab flexCenter cursor-pointer"
                        }
                        onClick={() => {
                          setOpenTab(tab.id);
                          if (tab.id === 3 || tab.id === 4 || tab.id === 7) {
                            setDisplay(false);
                          } else if (
                            tab.id === 1 ||
                            tab.id === 2 ||
                            tab.id === 6
                          ) {
                            setDisplay(true);
                          }
                        }}
                      >
                        {tab.name === "Historique" ? (
                          <Tooltip
                            tooltipText={t("MyPatient.History")}
                            position="top"
                          >
                            <img
                              crossOrigin="anonymous"
                              referrerPolicy="origin"
                              src={
                                require("../../assets/fichePatientTabs/clock.png")
                                  .default
                              }
                              alt="Historique"
                              width={25}
                              height={25}
                              style={{ maxWidth: "inherit" }}
                            />
                          </Tooltip>
                        ) : // tab.name === "INS" ? (
                        //   <Tooltip tooltipText="INS" position="top">
                        //     <div className="relative">
                        //       <img crossOrigin = "anonymous"  referrerPolicy="origin"
                        //         src={
                        //           require("../../assets/fichePatientTabs/ins.svg")
                        //             .default
                        //         }
                        //         alt="INS"
                        //         width={25}
                        //         height={25}
                        //         style={{ maxWidth: "inherit" }}
                        //       />
                        //       {!patientData?.INS?.responseINS?.insActif
                        //         .valeur && (
                        //         <div className="absolute  -right-3 -top-3">
                        //           <Tooltip
                        //             tooltipText="INS n'est pas verifiée"
                        //             position="top"
                        //           >
                        //             ⚠️
                        //           </Tooltip>
                        //         </div>
                        //       )}
                        //     </div>
                        //   </Tooltip>
                        // ) :
                        tab.name === "Traitements" ? (
                          <Tooltip
                            tooltipText={t("MyPatient.Treatments")}
                            position="top"
                          >
                            <img
                              crossOrigin="anonymous"
                              referrerPolicy="origin"
                              src={
                                require("../../assets/fichePatientTabs/syringe.png")
                                  .default
                              }
                              alt="syringe"
                              width={25}
                              height={25}
                              style={{ maxWidth: "inherit" }}
                            />
                          </Tooltip>
                        ) : tab.name === "Antécédents" ? (
                          <Tooltip
                            tooltipText={t("MyPatient.MedicalHistory")}
                            position="top"
                          >
                            <img
                              crossOrigin="anonymous"
                              referrerPolicy="origin"
                              src={
                                require("../../assets/fichePatientTabs/agreement.png")
                                  .default
                              }
                              alt="Antécédents"
                              width={25}
                              height={25}
                              style={{ maxWidth: "inherit" }}
                            />
                          </Tooltip>
                        ) : tab.name === "Documents" ? (
                          <Tooltip
                            tooltipText={t("MyPatient.Documents")}
                            position="top"
                          >
                            <img
                              crossOrigin="anonymous"
                              referrerPolicy="origin"
                              src={
                                require("../../assets/fichePatientTabs/document.png")
                                  .default
                              }
                              alt="Documents"
                              width={25}
                              height={25}
                              style={{ maxWidth: "inherit" }}
                            />
                          </Tooltip>
                        ) : tab.name === "Constantes" ? (
                          <Tooltip
                            tooltipText={t("MyPatient.Constants")}
                            position="top"
                          >
                            <img
                              crossOrigin="anonymous"
                              referrerPolicy="origin"
                              src={
                                require("../../assets/fichePatientTabs/data.png")
                                  .default
                              }
                              alt="Constantes"
                              width={25}
                              height={25}
                              style={{ maxWidth: "inherit" }}
                            />
                          </Tooltip>
                        ) : tab.name === "Static" ? (
                          <Tooltip
                            tooltipText={t("MyPatient.Static")}
                            position="top"
                          >
                            <img
                              crossOrigin="anonymous"
                              referrerPolicy="origin"
                              src={
                                require("../../assets/images/chart.png").default
                              }
                              alt="Static"
                              width={25}
                              height={25}
                              style={{ maxWidth: "inherit" }}
                            />
                          </Tooltip>
                        ) : tab.name === "Survey" ? (
                          <Tooltip
                            tooltipText={t("MyPatient.Surveys")}
                            position="left"
                          >
                            <img
                              crossOrigin="anonymous"
                              referrerPolicy="origin"
                              src={require("../../assets/survey.png").default}
                              alt="Survey"
                              width={30}
                              height={25}
                              style={{ maxWidth: "inherit" }}
                            />
                          </Tooltip>
                        ) : (
                          tab.name
                        )}
                      </div>
                    );
                  })}
              </div>
              <div></div>
            </div>
            {display && patientData && openTab == 1 && (
              <Taches
                patientData={patientData}
                prescriptions={prescriptions}
                setPresecriptionList={setPresecriptionList}
                openTab={openTab}
              />
            )}
            <div
              className={`${
                display && openTab === 1 ? "" : "relative"
              } tabs-content`}
              style={{ bottom: "20px" }}
            >
              <div className="tabs-container">
                <div className="tab-content tab-space tabs-liste">
                  <div
                    className={classNames(
                      "item",
                      openTab === 1 ? "block" : "hidden"
                    )}
                    id=""
                  >
                    <Constantes
                      patientData={patientData}
                      setSelectedInput={setSelectedInput}
                      selectedInput={selectedInput}
                      setSize={setSize}
                      updateField={updateField}
                      valueSelectedInput={valueSelectedInput}
                      size={size}
                      setWeight={setWeight}
                      weight={weight}
                      imcStatus={imcStatus}
                      setPouls={setPouls}
                      pouls={pouls}
                      setSaturation={setSaturation}
                      saturation={saturation}
                      setTemperature={setTemperature}
                      Temperaturepouls={Temperaturepouls}
                    />
                  </div>

                  {openTab === 6 && <Statistic patientData={patientData} />}
                  {openTab === 7 && <Survey patientData={patientData} />}
                  {openTab === 8 && (
                    <INS
                      setPatientData={setPatientData}
                      patientData={patientData}
                    />
                  )}
                  {openTab === 9 && (
                    <DMP
                      setPatientData={setPatientData}
                      patientData={patientData}
                      user={user}
                    />
                  )}

                  <div
                    className={classNames(
                      "item",
                      openTab === 2 ? "block" : "hidden"
                    )}
                    id=""
                  >
                    <Documents
                      helperCategorie={helperCategorie}
                      setAddCategorieDocMenu={setAddCategorieDocMenu}
                      addCategorieDocMenu={addCategorieDocMenu}
                      documentsList={documentsList}
                      patientData={patientData}
                      setImageUrl={setImageUrl}
                      setModalImage={setModalImage}
                      setDocumentsList={setDocumentsList}
                      prescriptions={prescriptions}
                      display={display}
                      refreshPrescription={() =>
                        setRefreshPrescription(!refreshPrescription)
                      }
                    />
                  </div>
                  <div className={openTab === 3 ? "block" : "hidden"} id="">
                    <Antecedents
                      setAddAntecendentModal={setAddAntecendentModal}
                      display={display}
                      setDisplay={setDisplay}
                      antecedentsList={antecedentsList}
                      setTypeObservation={setTypeObservation}
                      setShowObservationsModal={setShowObservationsModal}
                      setTypeOfAddObservation={setTypeOfAddObservation}
                      setSelectedObservationId={setSelectedObservationId}
                      setObservationModal={setObservationModal}
                      setAddAntecendentChrirgicauxModal={
                        setAddAntecendentChrirgicauxModal
                      }
                      antecedentsChrirugicalList={antecedentsChrirugicalList}
                    />
                  </div>
                  <div className={openTab === 4 ? "block" : "hidden"} id="">
                    <Traitements
                      display={display}
                      setModalTraitement={setModalTraitement}
                      setTreatementType={setTreatementType}
                      setDisplay={setDisplay}
                      treatementEnCours={treatementEnCours}
                      endTreatment={endTreatment}
                      setSelectedObservationId={setSelectedObservationId}
                      setObservationModal={setObservationModal}
                      setTypeObservation={setTypeObservation}
                      setShowObservationsModal={setShowObservationsModal}
                      treatementsAncien={treatementsAncien}
                    />
                  </div>
                  <div className={openTab === 5 ? "block" : "hidden"} id="">
                    {patientData ? (
                      <Historiques
                        patientData={patientData}
                        refreshPrescription={refreshPrescription}
                      />
                    ) : (
                      <div className="flexCenter w-full anEmptyBox">
                        <p className="card-subtitle">
                          {t("MyPatient.noHistory")}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="absolute  inset-y-0 right-0 z-40 transition ease-in duration-700 patient-modal-info">
          <Spinner />
        </div>
      )}
      {observationModal && (
        <AddObservation
          setOpenModal={setObservationModal}
          selectedPatient={patientData._id}
          selectedObservationId={selectedObservationId}
          openModal={observationModal}
          typeOfAddObservation={typeOfAddObservation}
          setTreatementEnCours={setTreatementEnCours}
          setTreatementsAncien={setTreatementsAncien}
          treatementEnCours={treatementEnCours}
          treatementsAncien={treatementsAncien}
          setAntecedentsList={setAntecedentsList}
          setAntecedentsChrirugicalList={setAntecedentsChrirugicalList}
          antecedentsList={antecedentsList}
          antecedentsChrirugicalList={antecedentsChrirugicalList}
          setTypeOfAddObservation={setTypeOfAddObservation}
        />
      )}
      {showObservationsModal && (
        <ShowObservations
          setOpenModal={setShowObservationsModal}
          selectedPatient={patientData._id}
          openModal={observationModal}
          typeObservationObject={typeObservation}
          patientId={patientData._id}
        />
      )}
      {modalAddDocs && (
        <AddDocuments
          openModal={modalAddDocs}
          setOpenModal={setModalAddDocs}
          type={selectedCategorie}
          patientIdByDoctor={patientData._id}
          isDoctor={true}
          setDocumentsList={setDocumentsList}
          documentsList={documentsList}
        />
      )}

      {modalDisplayRaport && (
        <DisplayRaport
          openModal={modalDisplayRaport}
          setOpenModal={setModalDisplayRaport}
          selectedCompteRendu={selectedCompteRendu}
        />
      )}
      {modalDisplayPrescription && (
        <DisplayPrescription
          openModal={modalDisplayPrescription}
          setOpenModal={setModalDisplayPrescription}
          selectedCompteRendu={selectedCompteRendu}
        />
      )}
      {modalTraitement && (
        <AddTraitement
          openModal={modalTraitement}
          setOpenModal={setModalTraitement}
          selectedPatient={patientData?._id as string}
          treatementType={treatementType}
          setTreatementEnCours={setTreatementEnCours}
          setTreatementsAncien={setTreatementsAncien}
          treatementEnCours={treatementEnCours}
          treatementsAncien={treatementsAncien}
        />
      )}
      {addAntecendentModal && (
        <AddAntecedentsMedicaux
          openModal={addAntecendentModal}
          setOpenModal={setAddAntecendentModal}
          selectedPatient={selectedPatient}
          setAntecedentsList={setAntecedentsList}
          antecedentsList={antecedentsList}
        />
      )}
      {addAntecendentChrirgicauxModal && (
        <AddAntecedentChirurgicaux
          openModal={addAntecendentChrirgicauxModal}
          setOpenModal={setAddAntecendentChrirgicauxModal}
          selectedPatient={selectedPatient}
          antecedentsChrirugicalList={antecedentsChrirugicalList}
          setAntecedentsChrirugicalList={setAntecedentsChrirugicalList}
        />
      )}
      {/* {modalImage && (
        <ImageModal
          setModalImage={setModalImage}
          patientId={patientData._id}
          imageData={imageUrl}
        />
      )} */}
      <Modal showModal={modalImage} closeModal={() => setModalImage(false)}>
        <ImageModal
          closeModal={() => setModalImage(false)}
          patientId={patientData._id}
          imageData={imageUrl}
        />
      </Modal>
    </div>
  );
};

export default PatientInfo;
