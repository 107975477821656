/* eslint-disable eqeqeq */
import classNames from "classnames";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
  getSurveyByPatientId,
  getAllSurveyDoctor,
  doctorAssignSurvey,
} from "../../api/survey";
import Button from "../ButtonUI";
import Modal from "../ModalUI";
import SelectComp from "../Select";
import Spinner from "../Spinner";
import makeToast from "../Snackbar";
// import { FormViewer } from "../FormBuilderCore";
import { ReactComponent as IconSearch } from "../../assets/icons/icon-search.svg";
import moment from "../../helper/moment";
import Input from "../Input";
import FormViewer from "../SurveyGenerator/FormViewer";

const Survey = ({ patientData }) => {
  const { t } = useTranslation() as any;
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState() as any;
  const [previewSurvey, setPreviewSurvey] = useState() as any;
  const [filterName, setFilterName] = useState("");
  const [patientSurveyList, setPatientSurveyList] = useState([]) as any;
  const [DoctorSurveyList, setDoctorSurveyList] = useState([]) as any;

  const assignSurveyPatient = async () => {
    try {
      setLoading(true);
      await doctorAssignSurvey({
        patientId: patientData._id,
        surveyId: selectedSurvey,
      });
      makeToast("success", t("MyPatient.successAssigned"));
      _getSurveyByPatientId();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const _getSurveyByPatientId = async () => {
    try {
      setLoading(true);
      const response = await getSurveyByPatientId({
        patientId: patientData?._id,
      });
      setPatientSurveyList(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  //get all doctor Survey
  const _getAllDoctorSurvey = async () => {
    try {
      setLoading(true);
      const response = await getAllSurveyDoctor();
      setDoctorSurveyList(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // Get all survey for this patient
  useEffect(() => {
    _getSurveyByPatientId();
    _getAllDoctorSurvey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <Spinner nhs />
  ) : (
    <div>
      <div className="flex justify-between items-center">
        <div className="relative flex flex-wrap items-stretch ">
          <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 bg-transparent rounded text-base items-center justify-center w-8 pl-1 py-1">
            <IconSearch />
          </span>
          <Input
            type="text"
            placeholder={t("MyPatient.SurveyForSurvey")}
            width="w-full"
            onChange={(e) => setFilterName(e.target.value)}
            className={classNames(
              "rounded-xl px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white text-sm outline-none focus:border-blue-500  border-blue-100 w-full pl-10"
            )}
          />
        </div>
        <Button
          status="secondary"
          width="150px"
          height="35px"
          onClick={() => setShowAddModal(true)}
        >
          {t("MyPatient.add")}
        </Button>
      </div>
      <div className="mt-10 pb-10">
        {!_.isEmpty(patientSurveyList?.survey) ? (
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  {t("MyPatient.Name")}
                </th>
                <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  {t("MyPatient.Date")}
                </th>
                <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  {t("MyPatient.status")}
                </th>
              </tr>
            </thead>
            <tbody>
              {patientSurveyList?.survey
                ?.sort(
                  (a, b) => +new Date(b?.createdAt) - +new Date(a?.createdAt)
                )
                .filter((el) =>
                  el.surveyId?.name
                    ?.toLowerCase()
                    .includes(filterName?.toLowerCase())
                )
                .map((el) => (
                  <tr>
                    <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                      {el.surveyId?.name}
                    </td>
                    <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                      {moment(el.surveyId?.createdAt).format("L")}
                    </th>
                    <td
                      className={classNames(
                        "border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left",
                        el.status == "done"
                          ? "cursor-pointer text-green-500"
                          : "text-red-500"
                      )}
                      onClick={() => {
                        if (el.status == "done") {
                          setShowPreviewModal(true);
                          setPreviewSurvey(el);
                        }
                      }}
                    >
                      {el.status == "waiting"
                        ? t("MyPatient.pending")
                        : t("MyPatient.preview")}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <div className="flexCenter w-full anEmptyBox">
            <p className="card-subtitle">{t("MyPatient.NoSurvey")}</p>
          </div>
        )}
      </div>
      <Modal closeModal={() => setShowAddModal(false)} showModal={showAddModal}>
        <div className="h-52 w-96 px-10 pt-5 ">
          <h3 className="text-center">{t("MyPatient.selectSurvey")} </h3>
          <SelectComp
            id="country"
            // error={errors.country}
            value={selectedSurvey}
            options={
              !_.isEmpty(DoctorSurveyList?.survey)
                ? DoctorSurveyList?.survey.map((el) => ({
                    value: el._id,
                    label: el.name,
                  }))
                : []
            }
            onChange={(e) => setSelectedSurvey(e.target.value)}
            text={t("MyPatient.EnterSurvey")}
            className="w-full mt-4"
            // required
          />
          <div className="flexCenter w-full mt-5">
            <Button
              width="150px"
              height="35px"
              className=""
              onClick={() => {
                assignSurveyPatient();
                setShowAddModal(false);
              }}
            >
              {t("MyPatient.Confirm")}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        closeModal={() => setShowPreviewModal(false)}
        showModal={showPreviewModal}
        className="p-10 m-16 "
      >
        {previewSurvey?.surveyId?.sections.map((s) => (
          <FormViewer elements={s.task_data} values={previewSurvey?.response} />
        ))}
      </Modal>
    </div>
  );
};

export default Survey;
