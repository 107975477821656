import { useEffect, useState } from "react";
import { Textarea } from "@chakra-ui/react";
import { FormGroup } from "./FormGroup";
import { useField } from "../../../hooks/FormGeneratorHooks";

export const FieldTextarea = (props) => {
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    otherProps,
  } = useField(props);
  const { required, name } = props;
  const { children, label, placeholder, helper, ...rest } = otherProps;
  const [isTouched, setIsTouched] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const formGroupProps = {
    errorMessage,
    helper,
    id,
    isRequired: !!required,
    label,
    showError,
    name,
    ...rest,
  };

  return (
    <FormGroup {...formGroupProps}>
      <Textarea
        borderWidth={props.readOnly ? 2 : 1}
        id={id}
        value={value || ""}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => setIsTouched(true)}
        aria-invalid={showError}
        aria-describedby={!isValid ? `${id}-error` : undefined}
        placeholder={placeholder}
        disabled={props.readOnly ? props.readOnly : false}
        fontSize="14px"
        _invalid={{ borderColor: "Red" }}
        className="border border-gray-200 focus:border-blue-500 focus:shadow-sm w-full "
      />
      {children}
    </FormGroup>
  );
};
