import { useAtom } from "jotai";
import moment from "../../helper/moment";
import { useEffect, useState } from "react";
import { getAllPdfOfDoctorsPagination } from "../../api/pdfTemalpte";
import history from "../../history";
import { userAtom } from "../../state/auth";
import Button from "../ButtonUI";
import { IDocTemplate } from "./interfaces/interface";
import Spinner from "../../components/Spinner";
import PaginationComponenet from "../Pagination";
import SelectComp from "../Select";
import FloatInput from "../FloatInput";
import Modal from "../../components/ModalUI";
import makeToast from "../Snackbar";
import { useTranslation } from "react-i18next";

const TemplatePDFCreator = () => {
  const { t } = useTranslation() as any;
  const [data, setData] = useState([]);
  const [user] = useAtom(userAtom) as any;
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [limit] = useState(8);
  const [page, setPage] = useState(1);
  const [totalResult, setTotalResult] = useState(0);
  const [showModal, setShowModal] = useState<boolean | Function>(false);
  const [templateName, setTemplateName] = useState<string>("");
  const [modalType, setModalType] = useState<string>("");
  const [, setWidthPage] = useState(document.body.clientWidth);
  const [showModalMobile, setShowModalMobile] = useState<boolean | Function>(
    false
  );

  const ModalsType = [
    { label: t("TemplatePDF.Prescription"), value: "Préscription" },
    { label: t("TemplatePDF.Report"), value: "Compte Rendu" },
    { label: t("TemplatePDF.Other"), value: "Autres" },
  ];
  useEffect(() => {
    async function getTemplateDoctor() {
      setLoading(true);
      const response = await getAllPdfOfDoctorsPagination(
        page,
        limit,
        user?._id
      );
      setTotalResult(response?.data?.totalDocs);
      setTotalPage(response?.data?.totalPages);
      setPage(response?.data?.page);
      setData(response?.data?.docs);
      setLoading(false);
    }
    getTemplateDoctor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    return () => {
      setTemplateName("");
      setModalType("");
    };
  }, []);

  useEffect(() => {
    setWidthPage(document.body.clientWidth);
    document.body.clientWidth < 900 && setShowModalMobile(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.body.clientWidth]);

  return loading ? (
    <Spinner />
  ) : document.body.clientWidth < 900 ? (
    <>
      <Modal
        showModal={showModalMobile !== false}
        closeModal={() => setShowModalMobile(false)}
        width={"300px"}
      >
        <div className="m-4">
          <span>{t("TemplatePDF.NotAvailbleForMobile")}</span>
          <div className="w-full mt-8 flex justify-center items-center">
            <Button
              status="primary"
              width="50%"
              height="37px"
              className=""
              onClick={() => {
                history.push("/");
              }}
            >
              {t("TemplatePDF.Ok")}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  ) : (
    <>
      <Modal
        showModal={showModal !== false}
        closeModal={() => setShowModal(false)}
        // width={"630px"}
      >
        <div className="modalName">
          <h3 className="font-bold text-2xl text-black text-center mb-10 ">
            {t("TemplatePDF.setName")}
          </h3>
          <FloatInput
            id="template"
            placeholder={t("TemplatePDF.fillTemplateName")}
            labelText={t("TemplatePDF.nameTemplate")}
            type="text"
            //error={values.pricingWeekEnd}
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            inlineLabel={false}
            onfocus={true}
          />
          <SelectComp
            className="w-full px-3"
            labelProps={{ className: "w-40" }}
            value={modalType}
            text={t("TemplatePDF.selectCategorie")}
            options={ModalsType}
            onChange={(e) => {
              setModalType(e.target.value);
            }}
          />
          <Button
            status="primary"
            width="100%"
            height="47px"
            className="my-5"
            disabled={!templateName || !modalType}
            onClick={() => {
              if (templateName === "" && modalType === "") {
                makeToast("warning", t("TemplatePDF.fillData"));
              } else {
                setShowModal(false);
                history.push({
                  pathname: `/pdf/createPdf`,
                  state: {
                    templateName,
                    modalType,
                  },
                });
              }
            }}
          >
            {t("TemplatePDF.Create")}
          </Button>
        </div>
      </Modal>
      <header className="mb-10">
        <h1 className="pageTitle mb-5">{t("TemplatePDF.MyTemplates")}</h1>
        <Button
          status="secondary"
          width="222px"
          height="40px"
          onClick={() => {
            setShowModal(true);
          }}
          className="btnAddFiltre ml-3"
        >
          <span className="mr-2">🧾</span>
          {t("TemplatePDF.NewTemplate")}
        </Button>
      </header>

      <div className="flex flex-wrap w-full gap-10">
        {data.length !== 0 ? (
          data.map((e: IDocTemplate) => {
            return (
              <div
                className={`relative  hover:shadow card-patient justify-between bg-white`}
                style={{ zIndex: 0 }}
              >
                <div className="flex patient-info">
                  <div className="rounded-full h-12 w-12 flexCenter mx-5 my-auto">
                    <img
                      src="https://fr.geneawiki.com/images/f/fd/Icone-telecharger-pdf.png"
                      width={50}
                      alt="template"
                    />
                  </div>
                  <div className="details">
                    <div className="flex-grow ">
                      <p className="card-title capitalize">{e.templateName}</p>
                      <p className="card-subtitle">
                        Crée le{" "}
                        <span>{moment(e.createdAt).format(t("Moment.Z"))}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="rounded-full h-14 w-14 flexCenter bg-gray-200 mx-5 my-auto cursor-pointer"
                  onClick={() =>
                    history.push({
                      pathname: `/pdf/createPdf/${e._id}`,
                      state: {
                        templateName: e.templateName,
                        modalType: e.type,
                        selectedTemplate: e,
                      },
                    })
                  }
                >
                  <img
                    crossOrigin="anonymous"
                    referrerPolicy="origin"
                    src={require("../../assets/images/pen.png").default}
                    alt=""
                    width="24"
                  />
                </div>
              </div>
            );
          })
        ) : (
          <div className="w-full flexCenter mt-24">
            <h3> {t("TemplatePDF.NoTemplateFound")}</h3>
          </div>
        )}
      </div>
      {totalResult > 10 && (
        <PaginationComponenet
          page={page}
          setPage={(i) => setPage(i)}
          totalPage={totalPage}
          totalDocs={totalResult}
        />
      )}
    </>
  );
};

export default TemplatePDFCreator;
