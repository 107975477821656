import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  LabelHTMLAttributes,
} from "react";
import classNames from "classnames";
import requireInput from "../../assets/images/require.png";
import "./styles.scss";

type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  error?: string;
  labelText?: any;
  inlineLabel?: boolean;
  onfocus?: boolean;
  labelProps?: DetailedHTMLProps<
    LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >;
  stylesDiv?: string;
  icon?: string;
  iconColor?: string;
  image?: string;
  require?: boolean;
  withoutMB?: boolean;
  placeholder?: any;
};

const Input: React.FC<Props> = ({
  error,
  labelText,
  labelProps,
  inlineLabel,
  stylesDiv,
  iconColor,
  icon,
  image,
  withoutMB,
  require,
  placeholder,
  ...props
}) => {
  const { className: labelClassName, ...restOfLabelProps } = labelProps!;
  const { className: inputClassName, ...inputProps } = props;
  return (
    <div
      className={`${stylesDiv} floating-input relative ${
        withoutMB ? "" : "mb-3"
      }`}
    >
      <input
        placeholder={placeholder}
        className={classNames(
          "border border-gray-200 focus:outline-none rounded-md focus:border-blue-500 focus:shadow-sm w-full p-3 h-14 ",
          inputClassName,
          error ? "text-red-500 border-red-300" : null
        )}
        {...inputProps}
      />
      {labelText ? (
        <label
          htmlFor={inputProps.id}
          className={classNames(
            "card-subtitle absolute top-0 left-0 px-3 py-5 h-full pointer-events-none transform origin-left transition-all duration-100 ease-in-out ",
            labelClassName,
            error ? "text-red-500" : null
          )}
          {...restOfLabelProps}
        >
          {labelText}
        </label>
      ) : null}
      {require ? (
        <div className={`absolute right-2 top-3 ${props.className}`}>
          <img
            crossOrigin="anonymous"
            referrerPolicy="origin"
            src={requireInput}
            alt="require"
            className="w-2 h-2"
          />
        </div>
      ) : null}
    </div>
  );
};
Input.defaultProps = {
  labelProps: {},
};

export default Input;
