import Button from "../../../ButtonUI";
import { DMP } from "../../../../api/devbox";
import makeToast from "../../../Snackbar";
import { dropdownStatusOptions } from "../helper";

type Props = {
  editStatusesloading;
  setEditStatusesModal;
  setEditStatusesloading;
  author;
  patientDMPData;
  selectedDoc;
};

const EditStatus = (props: Props) => {
  const {
    selectedDoc,
    patientDMPData,
    author,
    editStatusesloading,
    setEditStatusesloading,
    setEditStatusesModal,
  } = props;
  //  Radio box with enum [ APPROVED, DEPRECATED, SUBMITTED, ARCHIVED, DELETED ]
  const handleEditStatuses = async () => {
    try {
      setEditStatusesloading(true);
      await DMP.td33updateStatus({
        context: {
          author,
          modeAcces: {
            acces: "NORMAL",
          },
        },
        request: {
          matriculeINS: patientDMPData?.matriculeINS,
          contentType: "04",
          documentUuid: selectedDoc?.entryUuid,
          newStatus: "ARCHIVED",
          oldStatus: selectedDoc?.status,
        },
      });
      makeToast("success", "opération effectuée avec succès");
      setEditStatusesloading(false);
      setEditStatusesModal(false);
    } catch (error) {
      setEditStatusesloading(false);
      makeToast(
        "error",
        "Quelque chose s'est mal passé. Veuillez réessayer plus tard"
      );

      console.log(error);
    }
  };

  return (
    <div className="py-5 px-10">
      <h3 className="text-center mb-5">Modifier statut de document</h3>
      {dropdownStatusOptions.map((el, i) => (
        <div className="flex items-center mb-4" key={i}>
          <input
            id="default-radio-1"
            type="radio"
            value=""
            name="default-radio"
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            htmlFor="default-radio-1"
            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            {el.label}
          </label>
        </div>
      ))}

      <div className="flexCenter gap-5 mt-3">
        <Button
          status="secondary"
          width="175px"
          height="35px"
          onClick={() => setEditStatusesModal(false)}
        >
          Annuler
        </Button>
        <Button width="175px" height="35px" onClick={handleEditStatuses}>
          <div className="relative">
            {editStatusesloading && (
              <div className="absolute h-full w-full flexCenter ">
                <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-purple-500"></div>
              </div>
            )}
            Confirmer
          </div>
        </Button>
      </div>
    </div>
  );
};

export default EditStatus;
