import { useTranslation } from "react-i18next";
import Button from "../../../../components/ButtonUI";
type Props = {
  confirmSHareFolder: Function;
  closeModal: Function;
};

const Concentement = (props: Props) => {
  const { confirmSHareFolder, closeModal } = props;
  const { t } = useTranslation() as any;
  return (
    <div className="p-3 ">
      <h3>{t("ModalFichePatient.Concentment")}</h3>
      <div className="flexCenter gap-2 mt-4">
        <Button
          onClick={() => closeModal()}
          status="secondary"
          width="120px"
          height="35px"
        >
          {t("ModalFichePatient.Cancel")}
        </Button>
        <Button
          onClick={() => confirmSHareFolder()}
          width="120px"
          height="35px"
        >
          {" "}
          {t("ModalFichePatient.Confirm")}
        </Button>
      </div>
    </div>
  );
};

export default Concentement;
