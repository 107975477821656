import React from "react";
import { initiatePayment } from "../../api/paymee";

const Paymee = () => {
  const _initiatePayment = async () => {
    try {
      initiatePayment({
        vendor: "520",
        amount: "120",
        note: "subscription month",
      });
    } catch (error) {

    }
  };
  React.useEffect(() => {
    _initiatePayment();
  }, []);
  return <div></div>;
};

export default Paymee;
