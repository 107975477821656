/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import {
  InputGroup,
  FormLabel,
  VStack,
  Grid,
  GridItem,
  Icon,
  Tooltip,
  Modal,
  ModalBody,
  ModalFooter,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  HStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import ButtonUI from "../../../ButtonUI";
import renderValueInput from "./ValidationIputsRender";
import { FieldInput } from "../../Elements/FieldInput";
import { SwitchInput } from "../../Elements/FieldSwitcher";
import { FormGroup } from "../../Elements/FormGroup";
import { elementAtomData } from "../../../../state/FormGeneratorState/elmentAtomdata";
import { useField } from "../../../../hooks/FormGeneratorHooks";
import {
  formatingRules,
  rules,
} from "../../../../helper/FormGeneratorHelper/Utils";
import { ReactComponent as Close } from "../../../..//assets/icons/icon-close.svg";
interface IProps {
  rule: string;
  parms: {
    valuePat?: string | null;
    maxLength?: number | null;
    minLength?: number | null;
    sup?: number | null;
    inf?: number | null;
    islength?: number | null;
  };
}
const Rules = (props) => {
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    otherProps,
  } = useField(props);
  const { t } = useTranslation() as any;

  const { required, name } = props;
  const { children, label, type, placeholder, helper, ...rest } = otherProps;
  const [newRule, setNewRule] = useState<IProps>({
    rule: "",
    parms: {
      valuePat: "",
      maxLength: null,
      minLength: null,
      sup: null,
      inf: null,
      islength: null,
    },
  });
  const [atomElment] = useAtom(elementAtomData);
  const [rulesModal, setRulesModal] = useState(false);
  const allowedInputs = [
    "TextArea",
    "TimePicker",
    "DatePicker",
    "RadioButtons",
    "Checkboxes",
    "Dropdown",
    "NumberInput",
    "TextInput",
  ];
  const [isTouched, setIsTouched] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const formGroupProps = {
    errorMessage,
    helper,
    id,
    isRequired: !!required,
    label,
    showError,
    name,
    ...rest,
  };

  const addNewRule = () => {
    let current = value;
    current.push(formatingRules(newRule));
    setValue(current);
    setNewRule({
      rule: "",
      parms: {
        valuePat: "",
        maxLength: null,
        minLength: null,
        sup: null,
        inf: null,
        islength: null,
      },
    });
  };
  const rulesNames = (item: any) => {
    let entries = Object.entries(item);
    return entries ? entries : [[], []];
  };

  const newRulesView = (item, i) => {
    const keys = rulesNames(item);
    return (
      allowedInputs.includes(atomElment.type) &&
      keys.length > 1 && (
        <Grid
          templateColumns={i == 0 ? "repeat(2, 1fr)" : ""}
          alignItems={"center"}
          className="mb-6"
          gap={0}
        >
          <>
            <HStack gap={0} className="relative">
              <GridItem className="px-2 py-3 " w="72" h="18">
                <span className="mx-0 p-2 text-sm text-black ">
                  {t(`SurveyIntl[${keys[1][0]}]`)}
                </span>
              </GridItem>
              <GridItem
                className="px-2 py-3 border  border-gray-300 rounded-lg "
                w="72"
                h="18"
              >
                <span className=" text-xs text-black p-2  ">{keys[0][1]} </span>
              </GridItem>
              <span className="p-2 -mr-4 absolute  -top-3 right-4">
                <DeleteIcon
                  onClick={() => {
                    let valueCopy = value;
                    valueCopy.splice(i, 1);
                    setValue(valueCopy);
                  }}
                ></DeleteIcon>
              </span>
              <span className=" text-xs text-gray-400 p-2 -mr-4 absolute  -top-2 right-32">
                {t("Survey.ErrorMsg")}
              </span>
            </HStack>
          </>
        </Grid>
      )
    );
  };
  return (
    <>
      <FormGroup {...formGroupProps}>
        <InputGroup name="rules">
          <VStack
            spacing={2}
            align="start"
            className="border p-4 rounded-lg w-full "
          >
            <Grid
              width={"100%"}
              templateColumns="repeat(2, 1fr)"
              alignItems={"baseline"}
              gap={0}
            >
              <GridItem w="170px" h="10">
                {" "}
                <span style={{ fontSize: "16px" }} className="font-bold ">
                  {t("Survey.FieldParameter")}
                </span>
              </GridItem>
            </Grid>

            <Grid
              templateColumns="repeat(2, 1fr)"
              alignItems={"center"}
              className="mb-12"
              gap={0}
            >
              {" "}
              <span className="mx-0 p-2 text-sm ">
                {" "}
                {t("Survey.RequireField")}
              </span>
              <Grid templateColumns="repeat(2, 1fr)" w="100%" h="10">
                <Grid
                  templateColumns="repeat(4, 1fr)"
                  w="100%"
                  h="10"
                  className="px-2 py-3  card-subtitle "
                >
                  <GridItem>
                    <span className="p-2  card-subtitle">
                      {" "}
                      {t("Survey.Deactivate")}
                    </span>
                  </GridItem>
                  <GridItem>
                    {" "}
                    <SwitchInput
                      value={props.value[0].required}
                      name="rules[0].required"
                    />
                  </GridItem>
                  <GridItem>
                    <span className="p-2 card-subtitle">
                      {" "}
                      {t("Survey.Activate")}
                    </span>
                  </GridItem>
                </Grid>
              </Grid>
              {props.form.values.rules && props.form.values.rules[0].required && (
                <Grid templateColumns="repeat(1, 1fr)" width={"100%"} gap={0}>
                  <GridItem
                    className="px-2 py-3  card-subtitle "
                    w="100%"
                    h="10"
                  >
                    <span className=" text-xs"> {t("Survey.ErrorMsg")}</span>
                    <FieldInput
                      placeholder={t("Survey.ErrorMsg")}
                      name="rules[0].message"
                      value={props.value[0].message}
                    />
                  </GridItem>
                </Grid>
              )}
            </Grid>
            {allowedInputs.includes(atomElment.type) && (
              <Grid
                width={"60%"}
                templateColumns="repeat(2, 1fr)"
                alignItems={"baseline"}
                gap={0}
              >
                <GridItem w="170px" h="10">
                  {" "}
                  <span style={{ fontSize: "16px" }} className="font-bold ">
                    {t("Survey.AdvancedSettings")}
                  </span>
                </GridItem>
                <GridItem w="100%" h="10" className="flex  card-subtitle  ">
                  {" "}
                  <Tooltip
                    label={t("Survey.AddOtherValidationParameters")}
                    aria-label="A tooltip"
                  >
                    <div
                      onClick={() => setRulesModal(true)}
                      style={{ backgroundColor: "transparent" }}
                      className="mx-1 cursor-pointer border border-blue-400 rounded-lg   flexCenter mt-1 bg-white p-1  w-6 h-6"
                    >
                      <Icon as={AddIcon} w={3} h={3} color="#4565f6" />
                    </div>
                  </Tooltip>
                </GridItem>
              </Grid>
            )}

            {value.map((item, i) => {
              return i > 0 && newRulesView(item, i);
            })}
          </VStack>
        </InputGroup>
      </FormGroup>

      <Modal isOpen={rulesModal} onClose={() => setRulesModal(false)}>
        <ModalOverlay />
        <ModalContent bg={"transparent"}>
          <div className="rounded-2xl bg-white">
            <ModalHeader> {t("Survey.FieldParameter")} </ModalHeader>
            <ButtonUI
              status="icon-secondary"
              onClick={() => setRulesModal(false)}
              className="absolute right-1 top-0"
            >
              <Close />
            </ButtonUI>
            <ModalBody p={6}>
              <FormLabel className="card-subtitle"> </FormLabel>
              <Select
                placeholder={t("Survey.selectSetting")}
                fontSize={"14px"}
                name="rule"
                options={rules(atomElment.type)}
                onChange={(e) =>
                  setNewRule({ ...newRule, rule: e.target.value })
                }
              >
                {(rules(atomElment.type) || []).map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label || item.value}
                  </option>
                ))}
              </Select>
              <div className="my-2 ">
                {" "}
                {renderValueInput(newRule, setNewRule, newRule, t)}
              </div>
            </ModalBody>

            <ModalFooter>
              <ButtonUI
                status="secondary"
                className="mr-3 px-4 py-2 w-auto float-left  btn-delete"
                onClick={() => setRulesModal(false)}
              >
                {t("Survey.Cancel")}
              </ButtonUI>
              <ButtonUI
                status="secondary"
                className="mr-3 px-4 py-2 w-auto float-right  "
                disabled={
                  !newRule ||
                  newRule.rule === "" ||
                  (newRule.rule === "isPattern" &&
                    newRule.parms.valuePat === "") ||
                  (newRule.rule === "isLength" &&
                    newRule.parms.islength == null) ||
                  (newRule.rule === "isMaxLength" &&
                    newRule.parms.maxLength == null) ||
                  (newRule.rule === "isMinLength" &&
                    newRule.parms.minLength == null) ||
                  (newRule.rule === "isMaxNumber" &&
                    newRule.parms.sup == null) ||
                  (newRule.rule === "isMinNumber" && newRule.parms.inf == null)
                }
                onClick={() => {
                  addNewRule();
                  setRulesModal(false);
                }}
              >
                {t("Survey.Save")}
              </ButtonUI>
            </ModalFooter>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Rules;
