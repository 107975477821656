import _ from "lodash";
import { useTranslation } from "react-i18next";
import Button from "../ButtonUI";
import Icon from "../Icon";

interface Props {
  setAddAntecendentModal: any;
  display: any;
  setDisplay: any;
  antecedentsList: any;
  setTypeObservation: any;
  setShowObservationsModal: any;
  setTypeOfAddObservation: any;
  setSelectedObservationId: any;
  setObservationModal: any;
  setAddAntecendentChrirgicauxModal: any;
  antecedentsChrirugicalList: any;
}

const Antecedents = (props: Props) => {
  const {
    setAddAntecendentModal,
    display,
    // setDisplay,
    antecedentsList,
    setTypeObservation,
    setShowObservationsModal,
    setTypeOfAddObservation,
    setSelectedObservationId,
    setObservationModal,
    setAddAntecendentChrirgicauxModal,
    antecedentsChrirugicalList,
  } = props;
  const { t } = useTranslation();
  const handleLocalNature = (nature) => {
    switch (nature) {
      case "Antécédent Médical":
        return t("ModalFichePatient.MedicalHistory");

      case "Antécédent chirurgical":
        return t("ModalFichePatient.SurgicalHistory");

      default:
        return t("ModalFichePatient.MedicalHistory");
    }
  };

  const handleLocalType = (nature) => {
    switch (nature) {
      case "Diabéte":
        return t("ModalFichePatient.Diabetes");

      case "Maladie Cardiovasculaire":
        return t("ModalFichePatient.CardiovascularDisease");

      case "Maladie Autoimmune":
        return t("ModalFichePatient.AutoimmuneDisease");
      case "Herpes ou Bouton de fièvre":
        return t("ModalFichePatient.HerpesOrFeverBlister");
      case "Autres":
        return t("ModalFichePatient.Other");
    }
  };
  return (
    <>
      <div
        className={`flex justify-between items-center  ${
          display ? "title-taches" : "title-taches-inside-block"
        }`}
      >
        <div className="sub-title">
          <p> {t("MyPatient.MedicalHistoryOrAllergies")}</p>
        </div>
        <div className="flex">
          <Button
            status="secondary"
            className="btnAddtache"
            width={"146px"}
            height={"35px"}
            onClick={() => setAddAntecendentModal(true)}
          >
            {t("MyPatient.add")}
          </Button>
        </div>
      </div>
      <div className="taches flex flex-wrap">
        {antecedentsList && antecedentsList.length > 0 ? (
          antecedentsList
            .sort((a, b) => +new Date(b.updatedAt) - +new Date(a.updatedAt))
            .map((med) => {
              return (
                <div className="tache my-1">
                  <div className="flex justify-between items-center header">
                    <div
                      className="flex sub-header"
                      style={{ minHeight: "63px" }}
                    >
                      <p>
                        {handleLocalNature(med.nature)}
                        <br /> <span>{handleLocalType(med.type)}</span>
                      </p>
                    </div>
                  </div>
                  <p className="subTitle">{med.subTitle}</p>
                  <div className="flex flex-wrap tacheboxContainer">
                    {med["traitements"] &&
                      med["traitements"].map((medic) => {
                        return (
                          <div className="tache-box max-w-max flex">
                            <Icon
                              name="capsule"
                              isIcon={false}
                              width={14}
                              height={16}
                            />
                            <p>{medic.traitement}</p>
                          </div>
                        );
                      })}
                  </div>
                  {med.to ? (
                    <p className="addObservation menu flexCenter obsContainer">
                      {t("MyPatient.AddObservation")}
                    </p>
                  ) : (
                    <div className="flexCenter obsContainer">
                      <div
                        className="menu addObservation cursor-pointer"
                        onClick={() => {
                          setTypeObservation({
                            typeObservation: "antecedents",
                            idObservation: med._id,
                          });
                          setShowObservationsModal(true);
                        }}
                      >
                        {med.observationsDoctor.length}{" "}
                        {t("MyPatient.Observations")}
                      </div>
                      <div
                        className="menu ml-6 addObservation cursor-pointer"
                        onClick={() => {
                          setSelectedObservationId(med._id);
                          setObservationModal(true);
                          setTypeOfAddObservation("antecedents");
                        }}
                      >
                        {t("MyPatient.add2")}
                      </div>
                    </div>
                  )}
                </div>
              );
            })
        ) : (
          <div className="flexCenter w-full anEmptyBox">
            <p className="card-subtitle">{t("MyPatient.NoMedicalHistory")}</p>
          </div>
        )}
      </div>
      <div
        className={`flex justify-between items-center  ${
          display ? "title-taches" : "title-taches-inside-block"
        }`}
      >
        <div className="sub-title">
          <p>{t("MyPatient.SurgicalHistory")}</p>
        </div>
        <div className="flex">
          <Button
            status="secondary"
            className="btnAddtache"
            width={"146px"}
            height={"35px"}
            onClick={() =>
              setAddAntecendentChrirgicauxModal &&
              setAddAntecendentChrirgicauxModal(true)
            }
          >
            {t("MyPatient.add")}
          </Button>
        </div>
      </div>
      <div className="taches flex">
        {antecedentsChrirugicalList && antecedentsChrirugicalList.length > 0 ? (
          antecedentsChrirugicalList
            .filter((el) => !_.isEmpty(el))
            .sort((a, b) => +new Date(b.updatedAt) - +new Date(a.updatedAt))
            .map((med) => {
              return (
                <div className="tache my-1">
                  <div className="flex justify-between items-center header">
                    <div className="flex sub-header">
                      <p>
                        {handleLocalNature(med.nature)}
                        <br /> <span>{handleLocalType(med.type)}</span>
                      </p>
                    </div>
                  </div>
                  <p className="subTitle">{med.subTitle}</p>
                  <div className="flex tacheboxContainer">
                    {med["traitements"].map((medic) => {
                      return (
                        <div className="tache-box max-w-max flex">
                          <Icon
                            name="capsule"
                            isIcon={false}
                            width={14}
                            height={16}
                          />
                          <p>{medic.traitement}</p>
                        </div>
                      );
                    })}
                  </div>
                  {med.to ? (
                    <p className="addObservation menu flexCenter">
                      {"+  Ajouter une observation"}
                    </p>
                  ) : (
                    <div className="flexCenter obsContainer">
                      <div
                        className="menu addObservation cursor-pointer"
                        onClick={() => {
                          setTypeObservation({
                            typeObservation: "antecedentChirurgicaux",
                            idObservation: med._id,
                          });
                          setShowObservationsModal(true);
                        }}
                      >
                        {med.observationsDoctor.length}{" "}
                        {t("MyPatient.Observations")}
                      </div>
                      <div
                        className="menu ml-6 addObservation cursor-pointer"
                        onClick={() => {
                          setSelectedObservationId(med._id);
                          setObservationModal(true);
                          setTypeOfAddObservation("antecedentsChirugical");
                        }}
                      >
                        {t("MyPatient.add2")}
                      </div>
                    </div>
                  )}
                </div>
              );
            })
        ) : (
          <div className="flexCenter w-full anEmptyBox">
            <p className="card-subtitle">{t("MyPatient.NoSurgicalHistory")}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default Antecedents;
