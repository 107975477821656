import { Switch, Route } from "react-router-dom";
import LayoutDoctor from "../../Layouts/LayoutDoctor";
import LayoutPatient from "../../Layouts/LayoutPatient";
import { UserRole } from "../../types/user";
import routes from "../../routes";

type Props = {
  role: UserRole;
};

const Navigator: React.FC<Props> = ({ role }) => {
  const Routes = () => (
    <Switch>
      {routes(role).map((routeProps, index) => (
        <Route key={index} {...routeProps} />
      ))}
    </Switch>
  );

  switch (role) {
    case UserRole.Guest:
      return Routes();
    case UserRole.Doctor:
      return <LayoutDoctor>{Routes()}</LayoutDoctor>;
    case UserRole.Patient:
      return <LayoutPatient>{Routes()}</LayoutPatient>;
    default:
      return Routes();
  }
};

export default Navigator;
