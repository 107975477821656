import { useTranslation } from "react-i18next";
import TimePicker from "react-time-picker";
import moment from "../../helper/moment";

interface Props {
  patientData: any;
  setSelectedInput: any;
  selectedInput: any;
  setSize: any;
  updateField: any;
  valueSelectedInput: any;
  size: any;
  setWeight: any;
  weight: any;
  imcStatus: any;
  setPouls: any;
  pouls: any;
  setSaturation: any;
  saturation: any;
  setTemperature: any;
  Temperaturepouls: any;
}

const Constantes = (props: Props) => {
  const {
    patientData,
    setSelectedInput,
    selectedInput,
    setSize,
    updateField,
    valueSelectedInput,
    size,
    setWeight,
    weight,
    imcStatus,
    setPouls,
    pouls,
    setSaturation,
    saturation,
    setTemperature,
    Temperaturepouls,
  } = props;
  const { t } = useTranslation() as any;
  return (
    <>
      <div className="sub-item">
        <div className="title-container">
          <p className="title">{t("MyPatient.GeneralData")}</p>
          <span className="card-subtitle">
            {t("MyPatient.lastUpdate")}
            {moment(patientData.updatedAt).format("L")}
          </span>
        </div>
        <div className="flex flex-wrap values-patient">
          <div
            className="flexCenter sub-values"
            onClick={() => setSelectedInput("size")}
          >
            <p className="name">{t("MyPatient.size")}</p>
            {selectedInput === "size" ? (
              <input
                //id="size"
                name="size"
                type="text"
                className="w-0.5 value"
                onChange={(e) => setSize(e?.target?.value)}
                onKeyPress={(e) => {
                  if (e.charCode === 13 || e.code === "Enter") {
                    updateField();
                  }
                }}
                value={valueSelectedInput}
                onBlur={() => {
                  updateField();
                  setSelectedInput("");
                }}
                autoFocus
              />
            ) : (
              <p className="value flexCenter cursor-pointer">{size}</p>
            )}
            <p>cm</p>
          </div>
          <div
            className="flexCenter sub-values"
            onClick={() => setSelectedInput("weight")}
          >
            <p className="name"> {t("MyPatient.weight")}</p>
            {selectedInput === "weight" ? (
              <input
                //id="size"
                name="weight"
                type="text"
                className="w-0.5 value"
                onChange={(e) => setWeight(e?.target?.value)}
                onKeyPress={(e) => {
                  if (e.charCode === 13 || e.code === "Enter") {
                    updateField();
                  }
                }}
                value={valueSelectedInput}
                onBlur={() => {
                  updateField();
                  setSelectedInput("");
                }}
                autoFocus
              />
            ) : (
              <p className="value flexCenter cursor-pointer">
                {weight !== 0
                  ? weight
                  : patientData.weight
                  ? patientData.weight
                  : 0}
              </p>
            )}
            <p>Kg</p>
          </div>

          <div className="flexCenter  mt-2">
            <p className="pr-4">{t("MyPatient.IMC")} </p>
            <div className=" p-2 bg-gray-200 rounded-2xl">
              <p className="text-gray-500">{imcStatus(weight, size, t)}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="sub-item">
        <div className="title-container">
          <p className="title">{t("MyPatient.ConstantesVitales")} </p>
          <span className="card-subtitle">
            {patientData?.pouls?.updatedAt &&
              `${t("MyPatient.lastUpdate")} ${moment(
                patientData?.pouls?.updatedAt
              ).format("L")}`}
          </span>
        </div>
        <div className="flexCol values-patient min-w-full ">
          <div
            className="flex items-center sub-values  max-w-max "
            onClick={(e) => setSelectedInput("poulsValue")}
          >
            <p className="name mr-16">{t("MyPatient.FC")}</p>
            {selectedInput === "poulsValue" ? (
              <input
                name="value"
                type="text"
                className="w-0.5 value"
                onChange={(e) =>
                  setPouls({
                    ...pouls,
                    value: e?.target?.value,
                  })
                }
                onKeyPress={(e) => {
                  if (e.charCode === 13 || e.code === "Enter") {
                    updateField();
                  }
                }}
                value={valueSelectedInput}
                onBlur={() => {
                  updateField();
                  setSelectedInput("");
                }}
                autoFocus
              />
            ) : (
              <p className="value flexCenter cursor-pointer">
                {pouls.value !== 0
                  ? pouls.value
                  : patientData?.pouls?.value
                  ? patientData?.pouls?.value
                  : 0}
              </p>
            )}
            <p>{"/BPM"}</p>
          </div>
          <div className="flex flex-wrap">
            <div
              className="flex items-center sub-values"
              onClick={(e) => setSelectedInput("poulsValueRa")}
            >
              <p className="name w-max mr-8">{t("MyPatient.TA")} </p>
              <p className="ml-10">{t("MyPatient.Systole")}</p>
              {selectedInput === "poulsValueRa" ? (
                <>
                  <input
                    name="value"
                    type="text"
                    className="w-0.5 value "
                    onChange={(e) =>
                      setPouls({
                        ...pouls,
                        temp: e?.target?.value,
                      })
                    }
                    onKeyPress={(e) => {
                      if (e.charCode === 13 || e.code === "Enter") {
                        updateField();
                      }
                    }}
                    value={valueSelectedInput}
                    onBlur={() => {
                      updateField();
                      setSelectedInput("");
                    }}
                    autoFocus
                  />
                </>
              ) : (
                <p className="value flexCenter cursor-pointer">
                  {pouls?.temp !== 0
                    ? pouls.temp
                    : patientData?.pouls?.temp
                    ? patientData.pouls.temp
                    : 0}
                </p>
              )}
              <p>{"/mmHg"}</p>
            </div>
            <div
              className="flex items-center sub-values "
              onClick={(e) => setSelectedInput("perMinute")}
            >
              <p className="ml-auto md:ml-10">{t("MyPatient.Diastole")} </p>
              {selectedInput === "perMinute" ? (
                <input
                  name="value"
                  type="text"
                  className="w-0.5 value"
                  onChange={(e) =>
                    setPouls({
                      ...pouls,
                      perMinute: e?.target?.value,
                    })
                  }
                  onKeyPress={(e) => {
                    if (e.charCode === 13 || e.code === "Enter") {
                      updateField();
                    }
                  }}
                  value={valueSelectedInput}
                  onBlur={() => {
                    updateField();
                    setSelectedInput("");
                  }}
                  autoFocus
                />
              ) : (
                <p className="value flexCenter cursor-pointer">
                  {pouls?.perMinute !== 0
                    ? pouls?.perMinute
                    : patientData?.pouls?.perMinute}
                </p>
              )}
              <p>{"/mmHg"}</p>
            </div>
          </div>
        </div>

        <div className="flex values-patient">
          <div
            className="flexCenter sub-values"
            onClick={(e) => {
              setSelectedInput("Saturation");
            }}
          >
            <p className="name mr-5">{t("MyPatient.Saturation")} </p>O
            <sub>2</sub>
            {selectedInput === "Saturation" ? (
              <input
                name="saturation"
                type="text"
                className="w-0.5 value "
                onChange={(e) =>
                  setSaturation({
                    updatedAt: moment(),
                    value: e?.target?.value,
                  })
                }
                onKeyPress={(e) => {
                  if (e.charCode === 13 || e.code === "Enter") {
                    updateField();
                  }
                }}
                value={valueSelectedInput}
                onBlur={() => {
                  updateField();
                  setSelectedInput("");
                }}
                autoFocus
              />
            ) : (
              <p className="value flexCenter cursor-pointer ml-3">
                {saturation?.value ? saturation?.value : 0}
              </p>
            )}
            <p>%</p>
          </div>
        </div>
      </div>

      <div className="sub-item pb-2">
        <div className="title-container">
          <p className="title">{t("MyPatient.Temperature")}</p>
          <span className="card-subtitle">
            {patientData?.Temperature?.updatedAt &&
              `  ${t("MyPatient.lastUpdate")} ${moment(
                patientData.Temperature.updatedAt
              ).format("L")}`}
          </span>
        </div>
        <div className="flex values-patient">
          <div
            className="flexCenter sub-values"
            onClick={(e) => {
              setSelectedInput("Temperaturepouls");
            }}
          >
            <p className="name">{t("MyPatient.TC")}</p>
            {selectedInput === "Temperaturepouls" ? (
              <input
                name="value"
                type="text"
                className="w-0.5 value"
                onChange={(e) =>
                  setTemperature({
                    ...Temperaturepouls,
                    degree: e?.target?.value,
                  })
                }
                onKeyPress={(e) => {
                  if (e.charCode === 13 || e.code === "Enter") {
                    updateField();
                  }
                }}
                value={valueSelectedInput}
                onBlur={() => {
                  updateField();
                  setSelectedInput("");
                }}
                autoFocus
              />
            ) : (
              <p className="value flexCenter cursor-pointer">
                {Temperaturepouls?.degree !== 0
                  ? Temperaturepouls?.degree
                  : patientData?.Temperature?.degree
                  ? patientData?.Temperature?.degree
                  : 0}
              </p>
            )}
            <p>°C</p>
          </div>
          <div
            className="flexCenter sub-values"
            onClick={(e) => {
              setSelectedInput("TemperatureTime");
            }}
          >
            <p className="name min-w-max">{t("MyPatient.TakenAt")}</p>
            {selectedInput === "TemperatureTime" ? (
              <TimePicker
                onChange={(e) => {
                  if (e !== null)
                    setTemperature({
                      ...Temperaturepouls,
                      time: e,
                    });
                }}
                format="HH:mm"
                value={Temperaturepouls.time}
                onClockClose={() => {
                  updateField();
                  setSelectedInput("");
                }}
                onClockOpen={() => {}}
              />
            ) : (
              <p className="value flexCenter cursor-pointer">
                {Temperaturepouls.time !== 0
                  ? Temperaturepouls.time
                  : patientData?.Temperature?.time
                  ? patientData.Temperature.time
                  : 0}
              </p>
            )}
            <p>{t("MyPatient.hours")}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Constantes;
