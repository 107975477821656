/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { Flex, Stack, Heading } from "@chakra-ui/react";
import {
  EditIcon,
  ViewIcon,
  ArrowRightIcon,
  CheckIcon,
} from "@chakra-ui/icons";
//Components
import ButtonUI from "../../ButtonUI";
import ModalUI from "../../ModalUI";
import FormRenderer from "../FormRenderer";
import FormViewer from "../FormViewer";
import Toolbar from "./ToolBar";
import StepperWrapper from "./StepperWrapper";
import { MultiStepsLayout, PageHeader } from "./Lyaout";
import { ControlledTabs } from "./DropZone";
import { Formiz, FormizStep } from "./StepperWrapper/Formiz";
//Hooks & Helpers
import { useForm } from "../../../hooks/FormGeneratorHooks";
import { resolver } from "../../../helper/FormGeneratorHelper/conditionalLogic";
import { loadLocaleData } from "../../../helper/FormGeneratorHelper/Utils";
//Atoms
import { stepAtom } from "../../../state/currentStep";
import { formAtom } from "../../../state/formAtom";
//Assets
import { ReactComponent as Close } from "../../../assets/icons/icon-close.svg";
const FormBuilder = ({
  fields,
  onUpdate,
  fieldProps,
  onSave,
  formProps,
  showPreviewTab,
  formSetup,
  simulation,
}) => {
  const { t } = useTranslation() as any;
  const [showPreview, setShowPreview] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedSec, setSelectedSec] = useState() as any;
  const [popUp, setPopUp] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [formConfig, setFormConfig] = useAtom(formAtom);
  const [, setStAtom] = useAtom(stepAtom);
  const form = useForm();
  const [preview, setPreview] = useState(false);
  const messages = loadLocaleData("fr");

  useEffect(() => {
    setFormConfig(formSetup);
  }, [formSetup]);
  useEffect(() => {}, [form.values]);

  const updateFormElement = (elem: any) => {
    let newSection = formConfig.sections;
    newSection[currentIndex].task_data = [...elem];
    setFormConfig({ ...formConfig, sections: newSection });
    onUpdate?.({ ...formConfig, sections: newSection });
  };

  const getAllFields = (sectionOrdre) => {
    let allFields = [] as any[];
    formConfig.sections.map((section) => {
      if (section.stepNbr < sectionOrdre)
        section.task_data.map((f) => {
          allFields.push(f);
        });
    });
    return allFields;
  };
  const addStep = () => {
    let formConfigx = {
      task_data: [],
      stepNbr: formConfig.sections.length,
      sectionName:
        "Page " +
        (formConfig.sections.length ? formConfig.sections.length + 1 : "0"),
    };
    let steps = formConfig.sections ? formConfig.sections : [];
    let data = {
      name: formConfig.name || "",
      description: formConfig.description || "",
      sections: [...steps, formConfigx],
    };
    setFormConfig(data);
    onUpdate?.(data);
  };
  const editStepConfig = (newConfig, index) => {
    let copy = formConfig;
    copy.sections[index].sectionName = newConfig.name;
    if (
      (newConfig.conditionLogic && newConfig.conditionLogic.status == false) ||
      (newConfig.conditionLogic.conditions &&
        newConfig.conditionLogic.conditions.length == 0) ||
      !newConfig.conditionLogic.conditions
    ) {
      copy.sections[index].conditionLogic = {
        status: false,
        action: "S",
        operator: "all",
        conditions: [],
      };
    } else {
      copy.sections[index].conditionLogic = newConfig.conditionLogic;
    }

    setFormConfig(copy);
    onUpdate?.(copy);
  };

  const removeStep = () => {
    const index = formConfig.sections.indexOf(selectedSec);
    if (index == 0 && formConfig.sections.length == 1) {
    } else {
      let newarray = formConfig.sections.filter(function (element) {
        return element != selectedSec;
      });
      newarray.forEach((element, k) => {
        element.stepNbr = k;
      });
      setCurrentIndex(0);
      setFormConfig({ ...formConfig, sections: newarray });
      onUpdate?.({ ...formConfig, sections: newarray });
    }
  };
  const handleSubmit = (values) => {
    setPreview(true);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className="layout-doctor"
        style={{
          height: "100%",
          display: "flex",
          alignItems: "stretch",
          borderRadius: "10px",
        }}
      >
        <Flex
          justifyContent={"flex-start"}
          px="6"
          pt="8"
          className="absolute z-40 -top-4 right-0"
        >
          <Stack direction="row" align="center" mb="1">
            <ButtonUI
              width="130px"
              height="47px"
              status="primary"
              className="p-2  "
              onClick={() => onSave?.(formConfig)}
            >
              <CheckIcon color={"gray-50"} mx={1} size="14px" opacity={"0.8"} />
              {t("Survey.Save")}
            </ButtonUI>
            <ButtonUI
              status={"secondary"}
              type="button"
              height="47px"
              width="130px"
              className="p-2 mx-1 "
              onClick={() => setShowPreview((prev) => !prev)}
            >
              {showPreview ? (
                <EditIcon size="14px" opacity={"0.8"} />
              ) : (
                <ViewIcon size="14px" opacity={"0.8"} />
              )}{" "}
              {showPreview ? t("Survey.Edit") : t("Survey.Preview")}
            </ButtonUI>
          </Stack>
        </Flex>

        {!showPreview ? (
          <>
            <div
              className="  bg-white flex shadow-md  py-4 rounded-lg px-4"
              style={{ width: "100%" }}
            >
              <div className="w-1/4 bg-white py-4 px-4 rounded-lg mt-12 ">
                <Toolbar
                  messages={messages}
                  fieldProps={fieldProps}
                  fields={undefined}
                />
              </div>
              <div className=" w-3/4 ">
                <ControlledTabs
                  currentIndex={currentIndex}
                  setStAtom={setStAtom}
                  setCurrentIndex={setCurrentIndex}
                  formConfig={formConfig}
                  setPopUp={setPopUp}
                  setConfirmModal={setConfirmModal}
                  setSelectedSec={setSelectedSec}
                  addStep={addStep}
                  formProps={formProps}
                  updateFormElement={updateFormElement}
                  removeStep={removeStep}
                  confirmModal={confirmModal}
                  editStepConfig={editStepConfig}
                  getAllFields={getAllFields}
                  popUp={popUp}
                />
              </div>
            </div>
          </>
        ) : (
          <Formiz connect={form} onValidSubmit={handleSubmit}>
            <MultiStepsLayout submitLabel="Terminer">
              <PageHeader>
                {formConfig.name ? formConfig.name : "#Titre de questionnaire"}
                <Heading fontSize="lg" mb={4} mt={4}>
                  <ArrowRightIcon
                    height={"12px"}
                    width={"12px"}
                    mr={2}
                    mb={1}
                    color={"#4565f6"}
                  />
                  {formConfig.description
                    ? formConfig.description
                    : t("Survey.SurveyDescription")}
                </Heading>
              </PageHeader>
              <div className="border  shadow-lg  p-4 w-full rounded-lg mb-4">
                <StepperWrapper title="Dots stepper"></StepperWrapper>

                {formConfig.type === "Simulation" ? (
                  <>
                    {formConfig.sections[0].task_data.map((el, i) => {
                      return (
                        resolver(el.conditionLogic, form.values) && (
                          <FormizStep
                            label={el.label}
                            name={`${el.field_name}`}
                          >
                            <FormRenderer
                              key={i}
                              elements={[el]}
                              values={form.values}
                              form={form}
                            ></FormRenderer>
                          </FormizStep>
                        )
                      );
                    })}
                  </>
                ) : (
                  formConfig.sections.map((step, i) => {
                    return (
                      <FormizStep
                        label={step.sectionName}
                        name={`${step.sectionName + "-" + step.stepNbr}`}
                        isEnabled={
                          step.conditionLogic && step.conditionLogic.status
                            ? resolver(step.conditionLogic, form.values)
                            : true
                        }
                      >
                        <FormRenderer
                          key={i}
                          elements={step.task_data}
                          values={form.values}
                          form={form}
                        ></FormRenderer>
                      </FormizStep>
                    );
                  })
                )}
              </div>
            </MultiStepsLayout>
            <ModalUI
              className="mb-8 w-64"
              showModal={preview}
              closeModal={() => setPreview(false)}
            >
              <div
                className="rounded-2xl bg-white border  p-12 mt-44   "
                style={{ background: "#f5f5f9" }}
              >
                <ButtonUI
                  status="icon-secondary"
                  onClick={() => setPreview(false)}
                  className="absolute right-1 top-0 "
                  type="button"
                >
                  <Close />
                </ButtonUI>
                <h1 className="flexCenter text-3xl font-bold  rounded-t-lg p-4 ">
                  {t("Survey.SurveySummary")}
                </h1>

                {formConfig?.sections.map((s) => (
                  <FormViewer elements={s.task_data} values={form.values} />
                ))}
              </div>
            </ModalUI>
          </Formiz>
        )}
      </div>
    </DndProvider>
  );
};

export default FormBuilder;
