/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Formik } from "formik";
import { useAtom } from "jotai";
import { updateDoctorData } from "../../../api/doctor";
import { userAtom } from "../../../state/auth";
import countriesTn from "../../../config/constants/countriesTn.json";
import Input from "../../../components/FloatInput";
import Button from "../../../components/ButtonUI";
import Select from "../../../components/Select";
import Icon from "../../../components/Icon";
import makeToast from "../../../components/Snackbar";
import ListSpecilaities from "../../../config/constants/specialties.json";
interface Props {
  closeModal: Function;
}

const EditData = (props: Props) => {
  const { t, i18n } = useTranslation() as any;
  const [user, setUser] = useAtom(userAtom) as any;
  const [paysState, setPaysState] = useState(
    user.addressDetails.country || "France"
  );
  const [, setRegionState] = useState([]) as any;
  const [selectedRegionState] = useState() as any;
  const [, setDepartementState] = useState([]) as any;
  const [selectedDepartementState] = useState() as any;
  const [, setCommunesState] = useState([]) as any;
  const [selectedCommunesState] = useState() as any;
  const [speciality, setSpeciality] = useState() as any;

  const [villeTnState] = useState("");
  const [, setDelegationTn] = useState([]) as any;
  const [delegationTnState] = useState("");

  const _getRegion = async () => {
    try {
      fetch("https://geo.api.gouv.fr/regions")
        .then((response) => response.json())
        .then((data) =>
          setRegionState(
            data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
          )
        );
    } catch (error) {}
  };
  const _getDepartementState = async () => {
    try {
      fetch(
        `https://geo.api.gouv.fr/regions/${selectedRegionState?.code}/departements`
      )
        .then((response) => response.json())
        .then((data) =>
          setDepartementState(
            data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
          )
        );
    } catch (error) {}
  };
  const _getCommunesState = async () => {
    try {
      fetch(
        `https://geo.api.gouv.fr/departements/${selectedDepartementState?.code}/communes`
      )
        .then((response) => response.json())
        .then((data) =>
          setCommunesState(
            data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
          )
        );
    } catch (error) {}
  };

  // Init
  useEffect(() => {
    _getRegion();
  }, []);

  // Init
  useEffect(() => {
    if (selectedRegionState?.code) _getDepartementState();
  }, [selectedRegionState]);

  // Init
  useEffect(() => {
    if (selectedDepartementState?.code) _getCommunesState();
  }, [selectedDepartementState]);

  // Init
  useEffect(() => {
    if (villeTnState) {
      setDelegationTn(
        countriesTn[villeTnState].map((el) => ({
          ...el,
          value: el.delegation,
          label: el.delegation,
        }))
      );
    }
  }, [villeTnState]);

  useEffect(() => {
    if (user?.speciality) setSpeciality(user.speciality);
  }, []);
  const onSubmit = async (values) => {
    try {
      values.addressDetails = {
        country: paysState,
        region: selectedRegionState?.value,
        departement: selectedDepartementState?.value,
        commune: selectedCommunesState?.value,
        gouvernement: villeTnState,
        delegation: delegationTnState,
      };
      values.speciality = speciality;
      const response = await updateDoctorData(values);
      if (response) {
        makeToast("success", t("Profile.SuccessChange"));
        setUser({ ...user, ...response });
        props.closeModal();
      }
    } catch (error) {
      console.error("[error] Edit name & lastName - onSubmit :", error);
    }
  };
  return (
    <div className="py-3 px-5">
      <div className="flex justify-end">
        <div className="close-modal cursor-pointer">
          <Icon isIcon={true} name="close" onClick={() => props.closeModal()} />
        </div>
      </div>
      <Formik
        initialValues={{
          firstName: user?.firstName || "",
          lastName: user?.lastName || "",
          idFacturationCps: user?.idFacturationCps || "",
        }}
        onSubmit={onSubmit}
        validate={(values) => {}}
        // validationSchema={validationSchema}
      >
        {(props) => {
          const {
            values,
            // errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <form
              onSubmit={handleSubmit}
              className="form-update-data-patient mt-10"
            >
              <Input
                id="firstName"
                placeholder={t("Profile.name")}
                labelText={t("Profile.name")}
                type="text"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-full mb-2.5 rounded-lg border-gray-200 outline-none"
                inlineLabel={false}
                // onfocus={true}
              />
              <Input
                id="lastName"
                placeholder={t("Profile.lastName")}
                labelText={t("Profile.lastName")}
                type="text"
                // error={errors.lastName}
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-full mb-2.5 rounded-lg border-gray-200 outline-none"
                inlineLabel={false}
                // onfocus={true}
              />
              <Input
                id="idFacturationCps"
                placeholder={t("Profile.idFacturationCps")}
                labelText={t("Profile.idFacturationCps")}
                type="text"
                // error={errors.idFacturationCps}
                value={values.idFacturationCps}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-full mb-2.5 rounded-lg border-gray-200 outline-none"
                inlineLabel={false}
                // onfocus={true}
              />
              <div className="">
                <Select
                  // labelText="Pays"
                  text={t("Profile.Country")}
                  className="w-full px-3 mb-5 "
                  labelProps={{ className: "w-40" }}
                  value={paysState}
                  options={[
                    { label: t("ModalFichePatient.Tunisia"), value: "Tunisie" },
                    { label: t("ModalFichePatient.France"), value: "France" },
                  ]}
                  onChange={(e) => {
                    setPaysState(e.target.value);
                  }}
                />
              </div>
              <div className="">
                <Select
                  options={ListSpecilaities.map((el) =>
                    i18n.language.includes("en")
                      ? { ...el, label: el.valueEN }
                      : el
                  )}
                  className="w-full px-3"
                  setText={false}
                  value={speciality}
                  onChange={(e) => setSpeciality(e.target.value)}
                  onBlur={(e) => setSpeciality(e.target.value)}
                />
              </div>
              {/* <div>
                {paysState === "France" && (
                  <>
                    <Select
                      labelText="Régions"
                      className="w-full px-3"
                      labelProps={{ className: "w-40" }}
                      value={
                        selectedRegionState ? selectedRegionState.value : ""
                      }
                      options={regionState}
                      onChange={(e) => {
                        setSelectedRegionState(
                          regionState.find((elm) => elm.value == e.target.value)
                        );
                      }}
                    />
                    {selectedRegionState?.value && (
                      <Select
                        labelText="Départements"
                        className="w-full px-3"
                        labelProps={{ className: "w-40" }}
                        value={
                          selectedDepartementState
                            ? selectedDepartementState.value
                            : ""
                        }
                        options={departementState}
                        onChange={(e) => {
                          setSelectedDepartementState(
                            departementState.find(
                              (elm) => elm.value == e.target.value
                            )
                          );
                        }}
                      />
                    )}
                    {selectedDepartementState?.value && (
                      <Select
                        labelText="Commune"
                        className="w-full px-3"
                        labelProps={{ className: "w-40" }}
                        value={
                          selectedCommunesState
                            ? selectedCommunesState.value
                            : ""
                        }
                        options={communesState}
                        onChange={(e) => {
                          setSelectedCommunesState(
                            communesState.find(
                              (elm) => elm.value == e.target.value
                            )
                          );
                        }}
                      />
                    )}
                  </>
                )}
                {paysState === "Tunisie" && (
                  <>
                    <Select
                      labelText="Gouvernorat"
                      className="w-full px-3"
                      labelProps={{ className: "w-40" }}
                      value={villeTnState}
                      options={Object.keys(countriesTn).map((elm) => ({
                        label: elm,
                        value: elm,
                      }))}
                      onChange={(e) => {
                        setVilleTnState(e.target.value);
                      }}
                    />

                    {villeTnState && (
                      <Select
                        labelText="Délégation"
                        className="w-full px-3"
                        labelProps={{ className: "w-40" }}
                        value={delegationTnState}
                        options={delegationTn}
                        onChange={(e) => {
                          setDelegationTnState(e.target.value);
                        }}
                      />
                    )}
                  </>
                )}
              </div> */}
              <div className="flexCenter my-5">
                <Button
                  width="170px"
                  height="50px"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={() => handleSubmit()}
                >
                  {t("Profile.Save")}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditData;
