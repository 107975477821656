import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { loginPsc, registerPSC } from "../../api/auth";
import makeToast from "../../components/Snackbar";
import Spinner from "../../components/Spinner";
import { userAtom } from "../../state/auth";

type Props = {};

const PscRedirect = (props: Props) => {
  const { t } = useTranslation() as any;
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  // const [psc, setPsc] = useAtom(pscAtom);
  const [, setUser] = useAtom(userAtom);
  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const code = query.get("code");
  // const state = query.get("state");
  // const sessionState = query.get("session_state");

  useEffect(() => {
    if (code && localStorage.getItem("typePscLogin") === "login") {
      loginPsc(code)
        .then((response) => {
          if (response.status === "success") {
            makeToast("success", t("SignIn.SuccessConnectedMsg"));
          }
          setUser(response.user);
          setLoading(false);
        })
        .catch((error) => {
          makeToast("error", t("SignIn.ErrorMsgIncorrectCredential"));
          console.error("[error] Signin - onSubmit :", error);
          setLoading(false);
        });
    } else if (code && localStorage.getItem("typePscLogin") === "register") {
      registerPSC(code)
        .then((response) => {
          if (response.status === "success") {
            makeToast("success", t("SignIn.SuccessConnectedMsg"));
            console.log("response.user ==>", response.user);
            setUser(response.user);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message === "cardAlreadyExist")
            makeToast("warning", t("DashboardDoctor.pscAlreadyExist"));
          else makeToast("warning", t("MyDoctors.SomethingWentWrong"));
          setLoading(false);
        });
    } else history.push("/check/user");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, history]);

  return loading ? (
    <Spinner />
  ) : (
    <Redirect
      to={{
        pathname: "/",
      }}
    />
  );
};

export default PscRedirect;
