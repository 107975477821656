import Button from "../../../components/ButtonUI";
import { addExistPatientToDoctorList } from "../../../api/doctor";
import makeToast from "../../../components/Snackbar";
import { useTranslation } from "react-i18next";

type Props = {
  closeModal: Function;
  idPatient: string;
  setOpenModalInfoPatient: Function;
  setOpenModal: Function;
  setPatientAdded: Function;
};

const AddExistPatient = (props: Props) => {
  const {
    closeModal,
    idPatient,
    setOpenModalInfoPatient,
    setOpenModal,
    setPatientAdded,
  } = props;
  const { t } = useTranslation();
  const addExistPatient = async () => {
    const response = await addExistPatientToDoctorList(idPatient);
    if (response?.message === "Max Patient Limit Reached") {
      makeToast(
        "warning",
        "Vous avez atteint la limite des patients de votre palier"
      );

      closeModal();
      setOpenModal(false);
      return;
    }
    closeModal();
    setOpenModal(false);
    setOpenModalInfoPatient(true);
    setPatientAdded(true);
    try {
    } catch (error) {}
  };
  return (
    <div className="p-5 text-center">
      <h3>{t("AddPatient.addExistPatient")}</h3>
      <div className="flexCenter">
        <Button
          width="130px"
          height="35px"
          status="secondary"
          className="mt-4 mr-2"
          onClick={() => closeModal()}
        >
          Annuler
        </Button>
        <Button
          width="130px"
          height="35px"
          className="mt-4 ml-2"
          onClick={addExistPatient}
        >
          Confirmer
        </Button>
      </div>
    </div>
  );
};

export default AddExistPatient;
