/* eslint-disable react-hooks/exhaustive-deps */
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import moment from "../../helper/moment";
import Icon from "../../components/Icon";
import CardDetails from "./PatientDetails";
import Tabs from "../../components/Tabs";
import Modal from "../../components/ModalUI";
import DocumentsTab from "./Tabs/DocumentsTab";
import AntecedentTab from "./Tabs/AntecedentTab";
import HistoryTab from "./Tabs/HistoryTab";
import { userAtom } from "../../state/auth";
import { previousState } from "../../state/previousPageState";
import { me } from "../../api/patient";
import "./styles.scss";
import FilterModalAntecedent from "./modal/ModalFilterAntecedent";
import ConsultationDocument from "./modal/ConsultationDocment";
import { getFilteredAntecedent } from "../../api/antecedents";
import { imcStatus } from "../../helper/imc";
interface Props {}

const MyFolderPatient = (props: Props) => {
  const { t } = useTranslation() as any;
  const [previousStates, setPreviousStates] = useAtom(previousState) as any;
  const [selectedConsultationId, setSelectedConsultationId] = useState();
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [filterModal, setFilterModal] = useState(false);
  const [filerObject, setFilterObject] = useState({
    doctor: "",
    antecedent: "",
  });
  const [search, setSearch] = useState(false);

  const tabsNames = [
    {
      name: t("MyFolder.Documents"),
    },
    {
      name: t("MyFolder.Antecedents"),
    },
    // {
    //   name:  t("MyFolder.Treatments"),
    // },
    {
      name: t("MyFolder.Historical"),
    },
  ];
  const handlerTab = () => {
    switch (tabIndex) {
      case 0:
        return <DocumentsTab />;
      case 1:
        return (
          <AntecedentTab
            setSearch={setSearch}
            setOpenModal={setFilterModal}
            setFilterObject={setFilterObject}
            filerObject={filerObject}
          />
        );
      case 2:
        // return <TreatmentsTab />;
        return (
          <HistoryTab
            setSelectedConsultationId={setSelectedConsultationId}
            setOpenDocumentModal={setOpenDocumentModal}
          />
        );

      case 3:
        return (
          <HistoryTab
            setSelectedConsultationId={setSelectedConsultationId}
            setOpenDocumentModal={setOpenDocumentModal}
          />
        );
    }
  };
  const [user, setUser] = useAtom(userAtom) as any;
  useEffect(() => {
    async function getdataPatient() {
      const patientData = await me();
      if (patientData) setUser({ ...user, ...patientData });
    }
    getdataPatient();
  }, []);

  useEffect(() => {
    async function getFiltredDataAsync() {
      await getFilteredAntecedent(filerObject);
    }
    if (search) {
      getFiltredDataAsync();
      setSearch(false);
    }
  }, [search]);

  useEffect(() => {
    if (previousStates?.FolderHistory) {
      setTabIndex(previousStates?.FolderHistory?.openTab);
      setSelectedConsultationId(
        previousStates?.FolderHistory?.selectedConsultation
      );
      setOpenDocumentModal(true);
      setPreviousStates({
        FolderHistory: null,
      });
    }
  }, [previousStates]);

  return (
    <>
      <Modal
        showModal={openDocumentModal}
        slidemodal
        closeModal={() => setOpenDocumentModal(false)}
      >
        <ConsultationDocument
          consultationID={selectedConsultationId}
          closeModal={() => setOpenDocumentModal(false)}
        />
      </Modal>
      <div className="my-folder-patient">
        <div className="flex justify-between">
          {/* Header */}
          <div>
            <h1>{t("MyFolder.MyFolder")}</h1>
            {/* <span className="card-subtitle">
              Ces informations constituent votre dossier médical partagé avec
              vos médecins.
            </span> */}
          </div>
          {/* <Button width="257px" height="50px" status="secondary">
            <span className="flex items-center ">
              <Icon name="export" isIcon={true} />
              Transmettre mon dossier
            </span>
          </Button> */}
        </div>

        {/* Card Details */}
        <div className="bg-white rounded-lg py-5 mt-5 w-full">
          <div className="flex justify-end">
            {/* <span className="link">Modifier</span> */}
          </div>
          <div className="flex items-ceter lg:space-x-3 p-5 flex-col lg:flex-row ">
            <div className="flex-1 w-full patient-profile-responsive">
              <CardDetails
                user={{
                  gender: user && user?.sexe,
                  age: new Date().getFullYear() - moment(user.birthDate).year(),
                  numberPhone: `${
                    user?.phoneSuffixe ? user?.phoneSuffixe : ""
                  } ${user?.phone}`,
                  adress: user.address,
                  mail: user.email,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  photo: user.photo || "",
                  id: user._id,
                  birthDate: user.birthDate,
                  handicap: user.handicap,
                }}
              />
            </div>
            <div className="flex-1 w-full flex flex-col justify-center patient-profile-responsive mt-10 lg:mt-0">
              <div className="leading-none">
                <h2>{t("MyFolder.GeneralData")}</h2>
                <span className="card-subtitle">
                  {t("MyFolder.LastUpdatedOn")}{" "}
                  {moment(user?.updateAt).format("DD/MM/YYYY")}
                </span>
              </div>
              <div>
                <div className="flex lg:items-center lg:space-x-7 flex-col lg:flex-row">
                  <span>
                    {t("MyFolder.size")}
                    <span className="text-xs mx-2 font-bold">{user?.size}</span>
                    {t("MyFolder.cm")}
                  </span>
                  <span>
                    {t("MyFolder.Weight")}
                    <span className="text-xs mx-2 font-bold">
                      {user?.weight}
                    </span>
                    {t("MyFolder.kg")}
                  </span>
                  <span>
                    {t("MyFolder.BMI")}{" "}
                    <span className="text-xs font-bold">
                      {(user?.weight / Math.pow(user?.size / 100, 2)).toFixed(
                        2
                      )}
                    </span>
                  </span>
                  <span className="bg-gray-50 rounded-xl pr-3 flexCenter ">
                    <Icon isIcon={true} name="imc" />
                    <span>{imcStatus(user?.weight, user?.size, t)}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Tabs */}
        <div className="flexCenter my-10">
          <Tabs menu={tabsNames} tabIndex={setTabIndex} className="" />
        </div>

        {/** Card Medical by Date */}
        {handlerTab()}
      </div>
      {filterModal && (
        <FilterModalAntecedent
          setOpenModal={setFilterModal}
          filerObject={filerObject}
          setFilterObject={setFilterObject}
          setSearch={setSearch}
        />
      )}
    </>
  );
};

export default MyFolderPatient;
