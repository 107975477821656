/* eslint-disable array-callback-return */
import axios from "axios";
import {
  ADD_MEDICAL_ANTECDENTS_TO_PATIENT_BY_DOCTOR,
  ADD_CHIRURGICAUX_ANTECDENTS_TO_PATIENT_BY_DOCTOR,
  ADD_OBSERVATION_ANTECDENTS_TO_PATIENT_BY_DOCTOR,
  GET_FILTRED_ANTECEDENT_BY_PATIENT,
} from "../config/api";

export const addAntecedentMedicalToPatientByDoctor = async ({ ...values }) => {
  try {
    const res = await axios.post(
      ADD_MEDICAL_ANTECDENTS_TO_PATIENT_BY_DOCTOR,
      values,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addAntecedentChrirgicauxToPatientByDoctor = async ({
  ...values
}) => {
  try {
    const res = await axios.post(
      ADD_CHIRURGICAUX_ANTECDENTS_TO_PATIENT_BY_DOCTOR,
      values,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addObservationToAntecedents = async ({ ...values }) => {
  try {
    const res = await axios.post(
      ADD_OBSERVATION_ANTECDENTS_TO_PATIENT_BY_DOCTOR,
      values,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFilteredAntecedent = async (filter) => {
  try {
    let filterQuery = "";

    Object.entries(filter).map(([key, value]: any, index) => {
      filterQuery += `${index > 0 ? "&" : ""}${key}=${encodeURIComponent(
        value.toString()
      )}`;
    });
    const res = await axios.get(
      `${GET_FILTRED_ANTECEDENT_BY_PATIENT}?${filterQuery}`,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
