import axios from "axios";

import {
  PATIENT_VERIFY_SMS,
  PATIENT_LOGIN,
  PATIENT_SIGNUP,
  DOCTOR_ADD_PATIENT_URL,
  DOCTOR_GET_ALL_PATIENTS_URL,
  DOCTOR_GET_PATIENT_BY_ID_URL,
  DOCTOR_EDIT_PATIENT_BY_ID_URL,
  PATIENT_SIGNUP_PATIENTELO,
  PATIENT_GET_MY_DOCTORS,
  DOCTOR_GET_PATIENT_BY_ID_OBSERVATIONS_URL,
  PATIENT_GET_FILTRED_DOCTORS,
  PATIENT_GET_DOCTOR_SPECIALITY_LIST,
  PATIENT_GET_UPCOMING_CONSULTATION,
  PATIENT_OLD_DONE_CONSULTATION,
  PATIENT_GET_TREATING_DOCTOR,
  PATIENT_GET_ALL_DOCUMENT,
  PATIENT_BOOK_MEETING,
  PATIENT_UPDATE_PROFILE,
  PATIENT_ME,
  PATIENT_SEARCH,
  PATIENT_SEARCH_OTHER_DOCTORS,
  PATIENT_SET_STRIPE_CUSTOMER,
  PATIENT_SETUP_INTENT,
} from "../config/api";

export const addPatient = async (
  values,
  Antecdents,
  Bio,
  Treatment,
  AntecdentsChirurgie,
  RadiologiqueExam,
  Autres
) => {
  try {
    let formData = new FormData();
    Object.entries(values).map(async ([k, v]) => {
      formData.append(k, `${v}`);
    });
    await Bio.forEach((b) => {
      if (b.bio && typeof b.bio == "object") {
        formData.append("Bio[]", b.bio);
      }
    });
    await RadiologiqueExam.forEach((b) => {
      if (b.radio && typeof b.radio == "object") {
        formData.append("Radiologique[]", b.radio);
      }
    });
    await Autres.forEach((b) => {
      if (b.autre && typeof b.autre == "object") {
        formData.append("Autres[]", b.autre);
      }
    });
    Treatment.forEach((item) => {
      if (typeof item._id === "number") {
        delete item._id;
        formData.append(`Treatments[]`, JSON.stringify(item));
      }
    });
    Antecdents.forEach((item) => {
      if (typeof item._id === "number") {
        delete item._id;
        formData.append(`Antecdents[]`, JSON.stringify(item));
      }
    });
    AntecdentsChirurgie.forEach((item) => {
      if (typeof item._id === "number") {
        delete item._id;
        formData.append(`AntecdentsChirurgie[]`, JSON.stringify(item));
      }
    });
    const res = await axios.post(DOCTOR_ADD_PATIENT_URL, formData, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const signupPatient = async (values) => {
  try {
    const { patient_photo } = values;
    let formData = new FormData();
    Object.entries(values).map(async ([k, v]) => {
      if (k !== "patient_photo") {
        formData.append(k, `${v}`);
      }
    });
    formData.append("patient_photo", patient_photo);
    const res = await axios.post(PATIENT_SIGNUP, formData, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const verifyLoginSMSPatient = async (values) => {
  try {
    const res = await axios.post(PATIENT_VERIFY_SMS, values, {
      withCredentials: true,
    });

    localStorage.setItem("token", res.data.user.token);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export async function login(credentials: {
  phone?: string;
  phone_prefix?: string;
  email?: string;
  password?: string;
  newPassword?: string;
  rememberMe?: boolean;
}) {
  try {
    const res = await axios.post(PATIENT_LOGIN, credentials, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export const getAllPatients = async (page, limit) => {
  try {
    const res = await axios.get(
      `${DOCTOR_GET_ALL_PATIENTS_URL}?limit=${limit}&page=${page}`,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPatientById = async (id) => {
  try {
    const res = await axios.get(`${DOCTOR_GET_PATIENT_BY_ID_URL}${id}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const editPatient = async (
  values?: object,
  Antecdents?: any,
  Bio?: any,
  Treatment?: any,
  AntecdentsChirurgie?: any,
  RadiologiqueExam?: any,
  Autres?: any
) => {
  try {
    let formData = new FormData();
    if (values)
      Object.entries(values).map(async ([k, v]) => {
        formData.append(k, `${v}`);
      });
    if (Bio)
      await Bio.forEach((b) => {
        if (b.bio && typeof b.bio == "object") {
          formData.append("Bio[]", b.bio);
        }
      });
    if (RadiologiqueExam)
      await RadiologiqueExam.forEach((b) => {
        if (b.radio && typeof b.radio == "object") {
          formData.append("Radiologique[]", b.radio);
        }
      });
    if (Autres)
      await Autres.forEach((b) => {
        if (b.autre && typeof b.autre == "object") {
          formData.append("Autres[]", b.autre);
        }
      });
    if (Treatment)
      Treatment.forEach((item) => {
        if (typeof item._id === "number") {
          delete item._id;
          formData.append(`Treatments[]`, JSON.stringify(item));
        }
      });
    if (Antecdents)
      Antecdents.forEach((item) => {
        if (typeof item._id === "number") {
          delete item._id;
          formData.append(`Antecdents[]`, JSON.stringify(item));
        }
      });
    if (AntecdentsChirurgie)
      AntecdentsChirurgie.forEach((item) => {
        if (typeof item._id === "number") {
          delete item._id;
          formData.append(`AntecdentsChirurgie[]`, JSON.stringify(item));
        }
      });
    const res = await axios.post(DOCTOR_EDIT_PATIENT_BY_ID_URL, formData, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const signupPatientPatientelo = async (values) => {
  try {
    const res = (await axios.post(PATIENT_SIGNUP_PATIENTELO, values, {
      withCredentials: true,
    })) as any;
    if (res?.response?.data?.message === "Max Patient Limit Reached")
      return res?.response?.data;
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPatientObservations = async (values) => {
  try {
    const res = await axios.post(
      `${DOCTOR_GET_PATIENT_BY_ID_OBSERVATIONS_URL}`,
      values,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getMyDoctors = async (page, limit, filter) => {
  try {
    let filterQuery = "";

    for (let element of filter)
      filterQuery += `&${element.type}=${element.value}`;

    // for (let propName in element) {
    //   if (filter[propName] !== null && filter[propName] !== undefined) {
    //     filterQuery += `&${propName}=${filter[propName]}`;
    //   }
    // }

    const res = await axios.get(
      `${PATIENT_GET_MY_DOCTORS}?limit=${limit}&page=${page}${filterQuery}`,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSpecialistFiltred = async (page, limit, filter) => {
  try {
    let filterQuery = "";

    for (var propName in filter) {
      if (filter[propName] !== null && filter[propName] !== undefined) {
        filterQuery += `&${propName}=${filter[propName]}`;
      }
    }
    // const filter = `&patientId=${patientId}&selectedDoctor${selectedDoctor}$selectedVille${selectedVille}`;

    const res = await axios.get(
      `${PATIENT_GET_FILTRED_DOCTORS}?limit=${limit}&page=${page}${filterQuery}`,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSpecialityList = async () => {
  try {
    const res = await axios.get(`${PATIENT_GET_DOCTOR_SPECIALITY_LIST}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUpcomingConsultation = async () => {
  try {
    const res = await axios.get(`${PATIENT_GET_UPCOMING_CONSULTATION}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getOlDDoneConsultation = async () => {
  try {
    const res = await axios.get(`${PATIENT_OLD_DONE_CONSULTATION}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getMyTreatingDoctor = async () => {
  try {
    const res = await axios.get(`${PATIENT_GET_TREATING_DOCTOR}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const patientGetAllDocument = async () => {
  try {
    const res = await axios.get(`${PATIENT_GET_ALL_DOCUMENT}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const patientBookMeeting = async (body) => {
  try {
    const res = (await axios.post(`${PATIENT_BOOK_MEETING}`, body, {
      withCredentials: true,
    })) as any;
    if (res?.response?.data?.message === "Max Patient Limit Reached")
      return res?.response?.data;
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const me = async () => {
  try {
    const res = await axios.get(`${PATIENT_ME}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updatePatient = async (body) => {
  try {
    const res = await axios.post(`${PATIENT_UPDATE_PROFILE}`, body, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const serachDoctorByPatient = async (patientId, search) => {
  try {
    const res = await axios.get(`${PATIENT_SEARCH}/${patientId}/${search}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getOtherDoctors = async (patientId) => {
  try {
    const res = await axios.get(
      `${PATIENT_SEARCH_OTHER_DOCTORS}/${patientId}`,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const setStripeCustomer = async () => {
  try {
    const res = await axios.get(`${PATIENT_SET_STRIPE_CUSTOMER}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClientSecret = async () => {
  try {
    const res = await axios.get(`${PATIENT_SETUP_INTENT}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
