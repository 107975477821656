import React from "react";
import { useAtom } from "jotai";
import { getUserById } from "../../api/auth";
import { userAtom } from "../../state/auth";
import { getShareFolderById } from "../../api/sharedFolder";
import Chat from "./Chat";
import Header from "./Header";
import Modal from "../ModalUI";
import Spinner from "../Spinner";
import SharedFolderFromChat from "./PreviewSharedFolder";
import "./style.scss";

interface Props {
  chatWithId: string | undefined;
  closeChatModal: Function;
  showModal: boolean;
  headerType?: string;
  headerShareFolder?: boolean;
  handleShareFolder?: Function;
  handlePreviewSharedFolder?: Function;
}

const Index: React.FC<Props> = ({
  chatWithId,
  closeChatModal,
  showModal,
  // headerType,
  // headerShareFolder,
  // handleShareFolder,
}) => {
  const [user] = useAtom(userAtom);
  const [loading, setLoading] = React.useState() as any;
  const [sharedFolderDetails, setSharedFolderDetails] = React.useState();
  const [modalChat, setModalChat] = React.useState(true) as any;
  const [chatWithData, setChatWithData] = React.useState() as any;

  const _getuserById = async () => {
    try {
      setLoading(true);
      const response = await getUserById(chatWithId);
      setChatWithData(response?.user);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handlePreviewSharedConsultation = () => {
    closeChatModal();
    setModalChat(true);
  };

  const handlePreviewSharedFolder = async (values) => {
    try {
      setModalChat(false);
      const ResponseSharedFolderDetails = await getShareFolderById(
        values.sharedFolder
      );
      setSharedFolderDetails(ResponseSharedFolderDetails.data);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (chatWithId) _getuserById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatWithId]);

  return (
    <Modal
      showModal={showModal}
      slidemodal={true}
      closeModal={() => {
        closeChatModal();
        setModalChat(true);
      }}
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Header
            chatWithData={chatWithData}
            closeChatModal={() => {
              closeChatModal();
              setModalChat(true);
            }}
          />
          {modalChat ? (
            <Chat
              chatWithData={chatWithData}
              handlePreviewSharedFolder={handlePreviewSharedFolder}
              handlePreviewSharedConsultation={handlePreviewSharedConsultation}
            />
          ) : (
            <SharedFolderFromChat
              sharedFolderDetails={sharedFolderDetails}
              user={user}
            />
          )}
        </>
      )}
    </Modal>
  );
};

export default Index;
