import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import classNames from "classnames";

import "./styles.scss";

type BtnStatutes = "primary" | "secondary" | "icon-primary" | "icon-secondary";

type Props = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  status?: BtnStatutes;
  size?: string;
  width?: string;
  height?: string;
};

const Button: React.FC<Props> = ({
  width,
  height,
  size,
  status,
  className,
  children,
  ...props
}) => {
  let btnStatusStr;
  switch (status) {
    case "primary":
      btnStatusStr = "btn-primary";
      break;
    case "secondary":
      btnStatusStr = "btn-secondary";
      break;
    case "icon-primary":
      btnStatusStr = "icon-primary";
      break;
    case "icon-secondary":
      btnStatusStr = "icon-secondary";
      break;
    default:
      btnStatusStr = "primary";
      break;
  }

  return (
    <button
      className={classNames(
        btnStatusStr,
        size && size,
        "flex-none ",
        className
        // width ? "" : "px-4",
        // height ? "" : "py-1"
      )}
      style={{ width, height }}
      {...props}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  status: "primary",
};

export default Button;
