import React, { useEffect, useState } from "react";
import moment from "../../helper/moment";
import _ from "lodash";

import "./styles.scss";
import { useTranslation } from "react-i18next";
// import { handleSpeciality } from "../../helper/handleSpeciality";

const PreviewAgenda = ({ meetDeatils }) => {
  const { t } = useTranslation() as any;
  const [listBooking, setListBooking] = useState<any>();
  const today = moment().endOf("day");

  useEffect(() => {
    const groups = meetDeatils.reduce((groups, el) => {
      if (!groups[el.date]) {
        groups[el.date] = [];
      }
      groups[el.date].push(el);
      return groups;
    }, {});

    // // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((date) => ({
      date,
      data: groups[date],
    }));
    setListBooking(groupArrays);
  }, [meetDeatils]);

  return _.isArray(meetDeatils) && !_.isEmpty(meetDeatils) ? (
    <div className="bg-white w-full p-3 rounded-2xl mt-4 border max-h-screen overflow-y-scroll h-full">
      {_.isArray(listBooking) &&
        !_.isEmpty(listBooking) &&
        listBooking.map(({ date, data }) => (
          <div>
            <div className="flex justify-between">
              <h2>
                {moment(date) < today
                  ? t("DashboardDoctor.Today")
                  : moment(date).format("DD MMM YYYY")}
              </h2>
              {/* <Icon isIcon={true} name="voirPlus" /> */}
            </div>
            {data.map((el) => (
              <div className="flex my-3">
                <span className="courant-text mr-5">
                  {moment(el.datetime).format("HH:mm")}
                </span>
                <div>
                  <span className="courant-text font-bold">
                    {el.patientName}
                  </span>
                  {el.speciality && (
                    <div className="box max-w-max">
                      {el.speciality}
                      {/* {handleSpeciality(
                        el.speciality,
                        i18n.language.includes("en")
                      )} */}
                    </div>
                  )}
                </div>
              </div>
            ))}
            <hr className="my-5" />
          </div>
        ))}
    </div>
  ) : (
    <div className="bg-white w-full flexCenter my-5 rounded-lg shadow-md py-5">
      <span className="card-subtitle ">{t("DashboardDoctor.EmptyList")} </span>
    </div>
  );
};

PreviewAgenda.defaultProps = {};

export default PreviewAgenda;
