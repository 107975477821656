/* eslint-disable eqeqeq */
import axios from "axios";
import makeToast from "../components/Snackbar";

import {
  DOCTOR_LOGIN_URL,
  DOCTOR_LOGOUT_URL,
  USER_VERIFY_URL,
  DOCTOR_SAVE_PSC_ID,
  PATIENT_LOGOUT_URL,
  USER_UPDATE_PASSWORD,
  GET_USER_BY_ID,
  REFRESH_TOKEN,
  CONFIRM_EMAIL,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  RESEND_CONFIRMATION_CODE,
  DOCTOR_REGISTER_WITH_PSC,
  DOCTOR_GET_USER_INFO_PSC,
} from "../config/api";

export async function login(credentials: {
  email: string;
  password: string;
  rememberMe?: boolean;
}) {
  try {
    const res = (await axios.post(DOCTOR_LOGIN_URL, credentials, {
      withCredentials: true,
    })) as any;
    // if (res?.response?.data?.message == "Incorrect username or password.") {
    //   return "Inconfirmed";
    // }

    if (res?.data?.message == "user is blocked") {
      makeToast(
        "warning",
        "Ce compte est bloqué veuillez contacter notre support"
      );
    }
    if (res?.data?.user?.token) {
      localStorage.setItem("token", res.data.user.token);
    }

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function resendVerificationCode(payload) {
  try {
    const res = await axios.post(RESEND_CONFIRMATION_CODE, payload);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function verify() {
  try {
    const res = await axios.create().get(USER_VERIFY_URL, {
      withCredentials: true,
      headers: {
        "Accept-Language": localStorage.getItem("i18nextLng") || "fr",
      },
    });

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function logout() {
  try {
    const res = await axios.post(
      DOCTOR_LOGOUT_URL,
      {},
      {
        withCredentials: true,
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function logoutPatient() {
  try {
    const res = await axios.post(
      PATIENT_LOGOUT_URL,
      {},
      {
        withCredentials: true,
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updatePassword(payload) {
  try {
    const res = await axios.post(
      USER_UPDATE_PASSWORD,
      {
        password: payload.password,
        newpassword: payload.confirmpassword,
      },
      {
        withCredentials: true,
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getUserById(userId) {
  try {
    const res = await axios.get(`${GET_USER_BY_ID}/${userId}`, {
      withCredentials: true,
    });

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function refreshToken() {
  try {
    const res = await axios.create().get(`${REFRESH_TOKEN}`, {
      withCredentials: true,
    });

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function confirmEmail(payload) {
  try {
    const res = await axios.post(`${CONFIRM_EMAIL}`, payload, {
      withCredentials: true,
    });

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export const forgetPassword = async (values) => {
  try {
    const res = await axios.post(FORGET_PASSWORD, values, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const resetPassword = async (values) => {
  try {
    const res = await axios.post(RESET_PASSWORD, values, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export async function loginPsc(code) {
  try {
    const res = (await axios.post(
      DOCTOR_LOGIN_URL,
      { code },
      {
        withCredentials: true,
        headers: {
          "auth-provider": "psc",
        },
      }
    )) as any;

    if (res?.data?.message == "user is blocked") {
      makeToast(
        "warning",
        "Ce compte est bloqué veuillez contacter notre support"
      );
    }
    if (res?.data?.user?.token) {
      localStorage.setItem("token", res.data.user.token);
    }

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function SavePscId(code) {
  try {
    const res = await axios.post(
      DOCTOR_SAVE_PSC_ID,
      { code },
      {
        withCredentials: true,
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function registerPSC(code) {
  try {
    const res = await axios.post(
      DOCTOR_REGISTER_WITH_PSC,
      { code },
      {
        withCredentials: true,
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getUserInfoPSC() {
  try {
    const res = await axios.get(DOCTOR_GET_USER_INFO_PSC, {
      withCredentials: true,
    });

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
