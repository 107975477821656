/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import countriesTn from "../config/constants/countriesTn.json";

const useLocation = () => {
  const [loading, setLoading] = React.useState(false);
  //FRANCE
  const [regionState, setRegionState] = React.useState([]) as any;
  const [selectedRegionState, setSelectedRegionState] = React.useState() as any;
  const [departementState, setDepartementState] = React.useState([]) as any;
  const [selectedDepartementState, setSelectedDepartementState] =
    React.useState() as any;
  const [communesState, setCommunesState] = React.useState([]) as any;
  const [selectedCommunesState, setSelectedCommunesState] =
    React.useState() as any;
  //TUNIS
  const [villeTnState, setVilleTnState] = React.useState("");
  const [delegationTn, setDelegationTn] = React.useState([]) as any;
  const [delegationTnState, setDelegationTnState] = React.useState("");

  const _getRegion = async () => {
    try {
      fetch("https://geo.api.gouv.fr/regions")
        .then((response) => response.json())
        .then((data) =>
          setRegionState(
            data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
          )
        );
    } catch (error) {}
  };

  const _getDepartementState = async () => {
    try {
      fetch(
        `https://geo.api.gouv.fr/regions/${selectedRegionState?.code}/departements`
      )
        .then((response) => response.json())
        .then((data) =>
          setDepartementState(
            data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
          )
        );
    } catch (error) {}
  };

  const _getCommunesState = async () => {
    try {
      fetch(
        `https://geo.api.gouv.fr/departements/${selectedDepartementState?.code}/communes`
      )
        .then((response) => response.json())
        .then((data) =>
          setCommunesState(
            data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
          )
        );
    } catch (error) {}
  };

  // Init
  React.useEffect(() => {
    _getRegion();
  }, []);

  // Init
  React.useEffect(() => {
    if (selectedRegionState?.code) _getDepartementState();
  }, [selectedRegionState]);

  // Init
  React.useEffect(() => {
    if (selectedDepartementState?.code) _getCommunesState();
  }, [selectedDepartementState]);

  // Init
  React.useEffect(() => {
    if (villeTnState) {
      setDelegationTn(
        countriesTn[villeTnState].map((el) => ({
          ...el,
          value: el.delegation,
          label: el.delegation,
        }))
      );
    }
  }, [villeTnState]);

  return {
    loading,
    setLoading,
    regionState,
    setRegionState,
    selectedRegionState,
    setSelectedRegionState,
    departementState,
    setDepartementState,
    selectedDepartementState,
    setSelectedDepartementState,
    communesState,
    setCommunesState,
    selectedCommunesState,
    setSelectedCommunesState,
    villeTnState,
    setVilleTnState,
    delegationTn,
    setDelegationTn,
    delegationTnState,
    setDelegationTnState,
  };
};

export default useLocation;
