import PriorityQueue from "./PriorityQueue";

interface IGraph {
  nodes: any;
  adjacencyList: any;
  adjacents: any;
  initialized: any;
  distance: any;
}

export default class Graph implements IGraph {
  nodes: any;
  adjacencyList: any;
  adjacents: any;
  initialized: any;
  distance: any;

  constructor() {
    this.nodes = new Set();
    this.adjacencyList = new Map();
    this.adjacents = new Map();
    this.initialized = false;
    this.distance = undefined;
  }

  addNode(node) {
    if (!this.nodes.has(node)) {
      this.nodes.add(node);
      this.adjacencyList.set(node, []);
      this.adjacents.set(node, new Set());
    }
  }

  addEdge(node1, node2) {
    if (
      !(
        this.adjacents.get(node1).has(node2) &&
        this.adjacents.get(node2).has(node1)
      )
    ) {
      let weight = node1.distanceTo(node2);

      this.adjacencyList.get(node1).push({ node: node2, weight: weight });
      this.adjacencyList.get(node2).push({ node: node1, weight: weight });
      this.adjacents.get(node1).add(node2);
      this.adjacents.get(node2).add(node1);
    }
  }

  findPathDijkstra(startNode, endNode) {
    let distances = new Map();
    let backtrace = new Map();
    let pq = new PriorityQueue();

    this.nodes.forEach((node) => {
      if (node !== startNode) {
        distances.set(node, Infinity);
      }
    });

    distances.set(startNode, 0);
    pq.enqueue([startNode, 0]);

    while (!pq.isEmpty()) {
      let shortestStep = pq.dequeue();
      let currentNode = shortestStep[0];

      this.adjacencyList.get(currentNode).forEach((neighbor) => {
        let distance = distances.get(currentNode) + neighbor.weight;

        if (distance < distances.get(neighbor.node)) {
          distances.set(neighbor.node, distance);
          backtrace.set(neighbor.node, currentNode);
          pq.enqueue([neighbor.node, distance]);
        }
      });
    }
    let path = [endNode];
    let lastStep = endNode;

    while (lastStep !== startNode && backtrace.size > 0) {
      path.unshift(backtrace.get(lastStep));
      lastStep = backtrace.get(lastStep);
    }
    this.distance = distances.get(endNode);

    return path;
  }

  getDistancePath() {
    return this.distance === undefined ? 0 : this.distance;
  }

  //obsolete pour l'instant
  getGraphFromBox(pathPoints) {
    let v: any = [];
    let len = Math.floor(pathPoints.length / 3.0);
    for (let i = 0; i < len; i++) {
      v[0] = pathPoints[3 * i];
      v[1] = pathPoints[3 * i + 1];
      v[2] = pathPoints[3 * i + 2];
      for (let j = 0; j < 3; j++) {
        this.addNode(v[j]);
      }
      for (let k = 0; k < 3; k++) {
        this.addEdge(v[0], v[(k + 1) % 3]);
      }
    }
  }

  getGraphFromMesh(mesh, points) {
    let v: any = [];
    let indices = mesh.geometry.index.array;
    let len = Math.floor(indices.length / 3.0);

    for (let i = 0; i < len; i++) {
      v[0] = points[indices[3 * i]];
      v[1] = points[indices[3 * i + 1]];
      v[2] = points[indices[3 * i + 2]];
      for (let j = 0; j < 3; j++) {
        this.addNode(v[j]);
      }
      for (let k = 0; k < 3; k++) {
        this.addEdge(v[k], v[(k + 1) % 3]);
      }
    }
    this.initialized = true;
  }
}
