import axios from "axios";

import {
  DOCTOR_ADD_CONSULTATION_KATOMI,
  DOCTOR_ADD_CONSULTATION_URL,
  DOCTOR_GET_CONSULTATIONS_OF_THE_DAY,
  DOCTOR_GET_CONSULTATION_OF_PATIENT_BYID_URL,
  DOCTOR_UPDATE_CONSULTATION_BY_ID,
  GET_CONSULTATION_BY_DOCTOR_AND_PATIENT_ID,
  GET_CONSULTATION_BY_ID,
  PATIENT_UPDATE_CONSULTATION,
  DOCTOR_UPDATE_CONSULTATION,
  ADD_ACTE,
  UPCOMING_RDV_DOCTOR_PATIENT,
  CHECK_ROOM_VALIDITY,
  ADD_NEW_PRESCRIPTION_OR_REPORT,
  UPDATE_PRESCRIPTION,
  UPDATE_CONSULTATION_DMP,
} from "../config/api";

export const addConsultation = async (values) => {
  try {
    const {
      autre,
      bio,
      radio,
      prescription,
      motif,
      rapport,
      dateConsultation,
      patientId,
    } = values;
    let formData = new FormData();
    formData.append("motif", motif);
    formData.append("rapport", rapport);
    formData.append("dateConsultation", dateConsultation);
    formData.append("patientId", patientId);

    await bio.forEach((b) => {
      if (b.bioDoc && typeof b.bioDoc == "object") {
        formData.append("Bio[]", b.bioDoc);
      }
    });
    await radio.forEach((b) => {
      if (b.radioDoc && typeof b.radioDoc == "object") {
        formData.append("Radiologique[]", b.radioDoc);
      }
    });
    await autre.forEach((b) => {
      if (b.autreDoc && typeof b.autreDoc == "object") {
        formData.append("Autres[]", b.autreDoc);
      }
    });
    await prescription.forEach((item) => {
      formData.append(`prescriptions[]`, JSON.stringify(item));
    });
    const res = await axios.post(DOCTOR_ADD_CONSULTATION_URL, formData, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getListConsultationByIdPatient = async (id) => {
  try {
    const res = await axios.get(
      `${DOCTOR_GET_CONSULTATION_OF_PATIENT_BYID_URL}${id}`,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getListConsultationForDoctor = async () => {
  try {
    const res = await axios.get(`${DOCTOR_GET_CONSULTATIONS_OF_THE_DAY}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const editConsultationById = async (id, body) => {
  try {
    const res = await axios.put(
      `${DOCTOR_UPDATE_CONSULTATION_BY_ID(id)}`,
      body,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getConsultationById = async (id) => {
  try {
    const res = await axios.get(`${GET_CONSULTATION_BY_ID(id)}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getConsultationByDoctorIdAndPatientId = async (
  doctorId,
  patientId
) => {
  try {
    const res = await axios.get(
      `${GET_CONSULTATION_BY_DOCTOR_AND_PATIENT_ID}/${doctorId}/${patientId}`,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addConsultationDoctorKatomi = async (data) => {
  try {
    const res = await axios.post(`${DOCTOR_ADD_CONSULTATION_KATOMI}`, data, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const patientUpdateConsultationDate = async (values) => {
  try {
    const res = await axios.post(`${PATIENT_UPDATE_CONSULTATION}`, values, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addActe = async (values) => {
  try {
    const res = await axios.post(`${ADD_ACTE}`, values, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const doctorUpdateConsultationDate = async (values) => {
  try {
    const res = await axios.post(`${DOCTOR_UPDATE_CONSULTATION}`, values, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUpComingRdvPatientAndDoctor = async (patientId, doctorId) => {
  try {
    const res = await axios.get(
      `${UPCOMING_RDV_DOCTOR_PATIENT}/${patientId}/${doctorId}`,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const checkRoomValidity = async (consultationId) => {
  try {
    const res = await axios.get(`${CHECK_ROOM_VALIDITY}/${consultationId}/`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addNewPrescriptionOrReport = async (payload, consultationId) => {
  try {
    const res = await axios.post(
      `${ADD_NEW_PRESCRIPTION_OR_REPORT}/${consultationId}/`,
      payload,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updatePrescription = async (payload) => {
  try {
    const res = await axios.post(`${UPDATE_PRESCRIPTION}`, payload, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateConsultationDMP = async (payload) => {
  try {
    const res = await axios.post(`${UPDATE_CONSULTATION_DMP}`, payload, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
