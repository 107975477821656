import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  LabelHTMLAttributes,
} from "react";
import classNames from "classnames";

import "./styles.scss";

type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  error?: string;
  labelText?: string;
  inlineLabel?: boolean;
  onfocus?: boolean;
  labelProps?: DetailedHTMLProps<
    LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >;
  stylesDiv?: string;
};

const Checkbox: React.FC<Props> = ({
  error,
  labelText,
  labelProps,
  inlineLabel,
  stylesDiv,
  ...props
}) => {
  const { className: labelClassName, ...restOfLabelProps } = labelProps!;
  const { className: inputClassName, ...inputProps } = props;

  return (
    // <div className="container-checkbox ">
    <label
      className={classNames("container-checkbox ", labelClassName)}
      {...restOfLabelProps}
    >
      {labelText && labelText}
      <input
        type="checkbox"
        className={classNames(inputClassName)}
        {...inputProps}
      />
      <span className="checkmark"></span>
    </label>
    // </div>
  );
};

Checkbox.defaultProps = {
  labelProps: {},
};

export default Checkbox;
