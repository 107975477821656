import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../../components/ButtonUI";
interface Props {
  closeModal: any;
  openPrescriptionModal: any;
  idConsultation: string;
  statusTask: any;
}

const EndConsultationModal = (props: Props) => {
  const { t } = useTranslation() as any;
  const { closeModal, idConsultation, openPrescriptionModal, statusTask } =
    props;
  return (
    <div className=" flexCenter flex-col space-y-5 p-2 md:p-10">
      <h2 className="text-center">{t("Consultation.LeftConsultation")}</h2>
      <div className="flex space-x-2 md:space-x-10">
        {!statusTask?.tasks?.find(
          (el) => el.type === "prescription" && el.status === "done"
        ) && (
          <Button
            status="secondary"
            width="146.6px"
            height="50px"
            onClick={() => {
              closeModal();
              openPrescriptionModal();
            }}
          >
            {t("Consultation.addPrescription")}
          </Button>
        )}

        <Link
          to={`/consultation-report/${idConsultation}`}
          onClick={closeModal}
        >
          <Button status="secondary" width="167px" height="50px">
            {t("Consultation.AddReport")}
          </Button>
        </Link>
      </div>
      <Link to="/" onClick={closeModal}>
        <span className="menu"> {t("Consultation.PutInToDoList")}</span>
      </Link>
    </div>
  );
};

export default EndConsultationModal;
