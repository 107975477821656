export const MyDoctors = {
  fr: {
    title: "Mes médecins",
    dispDoc: "spécialistes disponibles.",
    NewDoc: "+ Nouveau spécialiste",
    otherFilter: "+ Autres filtres",
    resultsFound: "résultats trouvés",
    selectVille: "sélectionner ville",
    selectSpecialty: "sélectionner spécialité",
    Find: "Chercher",
    ErrorMaxReached: "Ce médecin a atteint la limite des patients",

    MakingAppointments: "Prise de rendez-vous",
    Face2Face: "Présentiel",
    Teleconsultation: "Téléconsultation",
    MotifMeeting: "Motif de rendez-vous",
    ReasonForConsultation: "Motif de consultation",
    MedicalAppointmentFor: "Vous avez pris un rendez-vous médical pour le :",
    at: "à :",
    info1: "Vous êtes sur le point de prendre un rendez-vous médical.",
    info2: "En confirmant ce rendez-vous, vous vous engagez à y participer.",
    info3:
      "En cas de contretemps, merci d’annuler ou de modifier votre rendez-vous au plus tot.",

    EnterMotif: "Veuillez choisir un motif de consultation",
    EnterDate: "Veuillez choisir une date de consultation",

    TakeMeeting: "Prendre rendez-vous",
    Cancel: "Annuler",
    Finish: "Terminer",
    Date: "Date",

    SelectMotifToShowAvailibility:
      "Sélectionnez un motif pour voir les disponibilités",
    ConfirmAppointment: "Confirmer le rendez-vous",
    verifyInformation: "Veuillez vérifier vos informations",
    SomethingWentWrong: "Quelque chose s'est mal passé",

    LocationAndSchedules: "Localisation et Horaires",
    Expertises: "Expertises",
    RatesPayments: "Tarifs & Paiements",
    NextAvailable: "Prochain rendez-vous disponible :",
    NotFreeTS: "Ce médecin n'a pas de créneau libre pour le moment",

    NoDisponibility: "Pas de disponibilité",

    AppointementPlace: "Lieu de votre rendez-vous :",
    DateAppointement: "Date et heure de votre rendez-vous :",
    The: "le",
    Motif: "Motif :",
    YourMeeting: "Votre rendez-vous",

    AvailableSoon: "cette fonctionalité sera disponible prochainement",
    FinalizAppointment: "Finaliser la prise de rendez-vous",

    NoAmountWillDebited:
      "Aucune somme ne sera débitée avant la fin de la consultation.",

    maximumAmount:
      "Le montant maximal qui peut vous etre demandé par le professionnel de santé est de 25€.",

    exactAmount: "Le montant exact sera validé en fin de consultation",

    consultationDoesNotTakePlace:
      "Si la consultation n'as pas lieu, vous n'aurez rien à payer.",

    DecideNotCharge:
      "Le praticien peut décider de ne pas vous faire payer la consultation, par example si vous avez l'habtitude de ne pas avancer les frais",

    TOS: "J'accepte les Conditions d'utilisation de Katomi et je consens à réaliser une consultation vidéo.",

    ProtectionPolicy: "J'accepte la Politique de protection des données",

    AcceptGeneralConditions:
      "Vous devez accepter les conditions générales d'utilisation et politique de protection des données",

    Confirm: "Valider",

    bankingInformation: "Entrer vos informations bancaires",

    EnterYourBankDetails:
      "Afin de réserver votre rendez-vous en téléconsultation, vous devez entrer vos informations bancaires.",

    madeAfter: "Le débit n’est effectué qu’apres la consultation.",

    PaiementSecurity: "Paiement sécurisé :",
    Dr: "Dr",
    FindOutMore: "En savoir plus",
  },
  en: {
    title: "My doctors",
    dispDoc: "specialists available.",
    NewDoc: "+ New specialist",
    otherFilter: "+ Other filters",
    resultsFound: "results found",
    selectVille: "Select city",
    selectSpecialty: "Select specialty",
    Find: "Search",
    ErrorMaxReached: "This doctor has reached the patient limit",
    MakingAppointments: "Making appointments",
    Face2Face: "Face-to-face",
    Teleconsultation: "Teleconsultation",
    MotifMeeting: "Reason for meeting",
    ReasonForConsultation: "Reason for consultation",
    MedicalAppointmentFor: "You have made a medical appointment for:",
    at: "at :",
    info1: "You are about to make a medical appointment.",
    info2: "By confirming this appointment, you agree to participate.",
    info3:
      "In the event of a setback, please cancel or modify your appointment as soon as possible.",

    EnterMotif: "Please choose a reason for consultation",
    EnterDate: "Please choose a consultation date",
    TakeMeeting: "Make an appointment",
    Cancel: "Cancel",
    Finish: "Finish",
    Date: "Date",
    SelectMotifToShowAvailibility: "Select a pattern to see availability",
    ConfirmAppointment: "Confirm appointment",

    verifyInformation: "Please verify your information",
    SomethingWentWrong: "Something went wrong",

    LocationAndSchedules: "Location and Schedules",
    Expertises: "Expertise",
    RatesPayments: "Rates & Payments",
    NextAvailable: "Next available appointment:",
    NotFreeTS: "This doctor does not have a free slot at the moment",

    NoDisponibility: "No availability",

    AppointementPlace: "Place of your appointment:",
    DateAppointement: "Date and time of your appointment:",
    The: "",
    Motif: "Motif :",
    YourMeeting: "Your meeting",
    AvailableSoon: "this feature will be available soon",

    FinalizAppointment: "Finalize the appointment",

    NoAmountWillDebited:
      "No amount will be debited before the end of the consultation.",

    maximumAmount:
      "The maximum amount that can be asked of you by the healthcare professional is €25.",

    exactAmount:
      "The exact amount will be validated at the end of the consultation",

    consultationDoesNotTakePlace:
      "If the consultation does not take place, you will have nothing to pay.",

    DecideNotCharge:
      "The practitioner can decide not to charge you for the consultation, for example if you are used to not advancing the costs",

    TOS: "I agree to Katomi's Terms of Service and consent to conduct a video consultation.",

    ProtectionPolicy: "I accept the Data Protection Policy",

    AcceptGeneralConditions:
      "You must accept the general conditions of use and data protection policy",

    Confirm: "Confirm",

    bankingInformation: "Enter your banking information",

    EnterYourBankDetails:
      "In order to book your teleconsultation appointment, you must enter your bank details.",

    madeAfter: "The debit is only made after the consultation.",

    PaiementSecurity: "Secure payment :",
    Dr: "Dr",
    FindOutMore: "Find out more",
  },
};
