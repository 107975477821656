/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
/**
 * Helper & API
 */
import {
  getMyDoctors,
  getSpecialistFiltred,
  getSpecialityList,
} from "../../api/patient";
import useScreenSize from "../../hooks/ScreenSize";
/**
 * Components
 */
import CardDoctor from "./CardDoctor";
import moment from "../../helper/moment";
import Modal from "../../components/ModalUI";
import Spinner from "../../components/Spinner";
import Pagination from "../../components/Pagination";
import ConfirmModal from "../../components/DoctorDetails/ConfirmMeet";
import TakeMeet from "../../components/DoctorDetails/TakeMeet";
import ConfirmTeleConsultation from "../../components/DoctorDetails/ConfirmTeleConsultation";
import DoctorCard from "../../components/DoctorDetails";
import CardDoctorWithFilter from "./CardDoctorWithFilter";
import SearchBar from "../../components/AutoComplete";
// import ChatModal from "./ModalsMyDoctor/ChatModal";
// import HeaderModal from "./ModalsMyDoctor/HeaderModal";
import ChatModal from "../../components/SharedChat";
import HeaderModal from "../MyDoctors/modal/HeaderModal";
import CompteRendu from "../MyDoctors/modal/CompteRendu";
/**
 * Assets
 */
import "./style.scss";

interface Props {}

const AnnuaireDoctor = (props: Props) => {
  const { t } = useTranslation();
  const { windowDimenion } = useScreenSize();
  const [loading, setLoading] = useState(false);
  const [updateComponent, setUpdateComponent] = useState(false);
  const [specialist, setSpecialist] = useState<any>([]);
  const [mydoctors, setMyDoctors] = useState<any>([]);
  const [spcialityList, setSpecialityList] = useState<any>([]);
  const [, setEventPicked] = useState("");
  const [openTimeSlots, setOpenTimeSlots] = useState([]) as any;
  const [firstOpenMeet, setFirstOpenMeet] = useState(moment());
  const [meetData, setMeetData] = useState();
  const [addedConsultation, setAddedConsultation] = useState();
  const [, setDocInfos] = useState();
  // filter State
  const [selectedDoctor, setSelectedDoctor] = useState("") as any;
  const [selectedVille] = useState("");

  // Modal State
  const [DoctorCardModal, setDoctorCardModal] = useState(false);
  const [takeMeetModal, setTakeMeetModal] = useState(false);
  const [confirmTeleconsultationModal, setConfirmTeleconsultationModal] =
    useState(false);
  const [consfirmModal, setConfirmModal] = useState(false);
  const [chatModalState, setChatModalState] = useState("");
  const [chatWith, setChatWith] = useState() as any;
  // Pagintaion State
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit] = useState(10);
  const [totalResult, setTotalResult] = useState(0);

  // Specialist Modal
  const [selectSpecialist, setSelectSpecialist] = useState();
  const [picketDateReservation, setPicketDateReservation] = useState({});

  const _getSpecialist = async () => {
    try {
      setLoading(true);

      const filter = {
        filterby: selectedDoctor,
        // selectedVille,
      };
      for (var propName in filter) {
        if (filter[propName] === null || filter[propName] === undefined) {
          delete filter[propName];
        }
      }

      const response = await getSpecialistFiltred(page, limit, filter);
      setSpecialist(response?.myDoctors?.docs);
      setTotalResult(response?.myDoctors?.totalDocs);
      setTotalPage(response?.myDoctors?.totalPages);
      setPage(response?.myDoctors?.page);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error("Error Fetch specialist :", error);
    }
  };

  const _getMyDoctors = async () => {
    try {
      setLoading(true);
      const response = await getMyDoctors(page, limit, []);
      setMyDoctors(response.myDoctors.docs);
      setTotalResult(response?.myDoctors?.totalDocs);
      setTotalPage(response?.myDoctors?.totalPages);
      setPage(response?.myDoctors?.page);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error("Error Fetch specialist :", error);
    }
  };

  const _getSpecialityList = async () => {
    try {
      setLoading(true);
      const response = await getSpecialityList();
      setSpecialityList(response.speciality);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error Fetch Speciality Lists :", error);
    }
  };

  useEffect(() => {
    _getSpecialityList();
    _getMyDoctors();
  }, []);

  useEffect(() => {
    if (selectedDoctor || selectedVille) _getSpecialist();
    else _getMyDoctors();
    return () => setUpdateComponent(false);
  }, [page, selectedDoctor, selectedVille, updateComponent]);
  useEffect(() => {
    const bookingInfo: any = localStorage.getItem("Booking");
    if (bookingInfo) {
      const bookingInfoParsed = JSON.parse(bookingInfo);

      if (bookingInfoParsed && bookingInfoParsed?.first) {
        localStorage.setItem(
          "Booking",
          JSON.stringify({ ...bookingInfoParsed, first: false })
        );
        setSelectedDoctor(bookingInfoParsed?.nom);
        setPicketDateReservation({
          date: moment(bookingInfoParsed?.date, "X").format("DD-MM-YYYY"),
          time: moment(bookingInfoParsed?.date, "X").format(),
          type: bookingInfoParsed?.eventType,
          timeslotId: bookingInfoParsed?.timeSlotId,
        });
        setEventPicked(bookingInfoParsed?.eventType);
        setTakeMeetModal(true);
        // localStorage.removeItem("Booking")
      }
    }
  }, []);
  useEffect(() => {
    const bookingInfo: any = localStorage.getItem("Booking");
    if (bookingInfo) {
      const bookingInfoParsed = JSON.parse(bookingInfo);

      if (bookingInfoParsed) {
        const found = specialist.find(
          (element) => element._id === bookingInfoParsed.docId
        );

        if (found) {
          setDocInfos(found);
          setSelectSpecialist(found);
        }
      }
    }
  }, [setSelectedDoctor, setSpecialist, specialist]);

  return (
    <>
      <Modal showModal={consfirmModal}>
        <ConfirmModal
          closeModal={() => {
            setConfirmModal(false);
          }}
          addedConsultation={addedConsultation}
        />
      </Modal>
      <Modal
        showModal={confirmTeleconsultationModal}
        slidemodal={windowDimenion.winWidth > 768}
        closeModal={() => {
          setConfirmTeleconsultationModal(false);
        }}
      >
        <ConfirmTeleConsultation
          closeModal={() => {
            setConfirmTeleconsultationModal(false);
          }}
          meetData={meetData}
          selectSpecialist={selectSpecialist}
          openConfirmMeetModal={(addedConsultation) => {
            setAddedConsultation(addedConsultation);
            setConfirmModal(true);
          }}
        />
      </Modal>
      <Modal
        showModal={takeMeetModal}
        slidemodal={windowDimenion.winWidth > 768}
        closeModal={() => setTakeMeetModal(false)}
      >
        <TakeMeet
          maxRow={windowDimenion.winWidth > 768 ? 7 : 3}
          closeModal={() => setTakeMeetModal(false)}
          picketDateReservation={picketDateReservation}
          selectSpecialist={selectSpecialist}
          takeMeetModal={takeMeetModal}
          openTimeSlots={openTimeSlots}
          openConfirmMeetModal={(addedConsultation) => {
            setAddedConsultation(addedConsultation);
            setConfirmModal(true);
            setUpdateComponent(true);
            //TODO reset component
          }}
          openConfirmTeleconsultationModal={(takeMeetData) => {
            setMeetData(takeMeetData);
            setTakeMeetModal(false);
            setConfirmTeleconsultationModal(true);
          }}
        />
      </Modal>
      <Modal
        showModal={DoctorCardModal}
        slidemodal={windowDimenion.winWidth > 768}
        closeModal={() => setDoctorCardModal(false)}
      >
        <DoctorCard
          closeModal={() => setDoctorCardModal(false)}
          selectSpecialist={selectSpecialist}
          openTakeMeetModal={() => {
            setDoctorCardModal(false);
            setTakeMeetModal(true);
            setUpdateComponent(true);

            //TODO reset component
          }}
          firstOpenMeet={firstOpenMeet}
        />
      </Modal>
      {/* ChatModal && CompteRendu Modal */}
      {/* <Modal
        showModal={!!chatModalState}
        slidemodal
        closeModal={() => setChatModalState("")}
      >
        {chatModalState === "chat" ? (
          <>
            <HeaderModal
              doctorDetails={chatWith}
              closeModal={() => setChatModalState("")}
            />
            <ChatModal chatWithId={chatWith?._id} />
          </>
        ) : (
          <>
            <HeaderModal
              doctorDetails={chatWith}
              closeModal={() => setChatModalState("")}
            />
            <CompteRendu
              closeModal={() => setChatModalState("")}
              selectedDoctor={chatWith}
            />
          </>
        )}
      </Modal> */}

      <ChatModal
        showModal={chatModalState === "chat"}
        closeChatModal={() => setChatModalState("")}
        chatWithId={chatWith?._id}
      />
      <Modal
        showModal={chatModalState === "shareFolder"}
        slidemodal
        closeModal={() => setChatModalState("")}
      >
        <HeaderModal
          doctorDetails={chatWith}
          closeModal={() => setChatModalState("")}
        />
        <CompteRendu
          closeModal={() => setChatModalState("")}
          selectedDoctor={chatWith}
        />
      </Modal>
      <div className="annuaire-patient">
        <header>
          <h1> {t("SideBarMenu.Annuaire")}</h1>
          <h3 className="card-subtitle">
            {t("MyPatient.Caregivers")} ({totalResult})
          </h3>
        </header>
        <div className="flex w-full mt-4 space-x-3">
          <SearchBar
            options={spcialityList}
            value={selectedDoctor}
            onChange={setSelectedDoctor}
            className="flex-1"
            placeholder={t("MyPatient.placeholderFindDoctor")}
            prefix="impression"
          />
          {/* <SearchBar
            options={["Tunis", "Ariana", "Ben Arous"]}
            value={selectedVille}
            onChange={setSelectedVille}
            className=""
            placeholder="Ville"
            prefix="localisation"
            prefixBordless
            suffix="geolocalisation"
          />
          <Button width="140px" height="45px">
            Rechercher
          </Button> */}
        </div>

        {/* <div className="flex items-center justify-between w-full mt-4 ">
          <Button
            status="secondary"
            height="35px"
            width="150px"
            className="bg-transparent"
          >
            + Autres filtres
          </Button>
          <p>{totalResult} résultats trouvés</p>
        </div> */}

        {/* <CardDoctorWithFilter /> */}
        {loading ? (
          <Spinner />
        ) : selectedDoctor || selectedVille ? (
          <div className=" mt-10 gap-y-3">
            {specialist && !_.isEmpty(specialist) ? (
              specialist.map((el) => (
                <CardDoctorWithFilter
                  maxRow={windowDimenion.winWidth > 768 ? 7 : 3}
                  handleCardClick={(
                    doctorDetails,
                    openTimeSlots,
                    firstOpenMeet
                  ) => {
                    setEventPicked("");
                    setPicketDateReservation({});
                    setDoctorCardModal(true);
                    setSelectSpecialist(doctorDetails);
                    setOpenTimeSlots(openTimeSlots);
                    setFirstOpenMeet(firstOpenMeet);
                  }}
                  handlePickDate={(dateTime, doctorDetails, eventType) => {
                    setPicketDateReservation(dateTime);
                    setSelectSpecialist(doctorDetails);
                    setTakeMeetModal(true);
                    setEventPicked(eventType);
                  }}
                  doctorDetails={el}
                />
              ))
            ) : (
              <h3>{t("MyPatient.NoDocFound")}</h3>
            )}
          </div>
        ) : (
          <div className="flex flex-wrap mt-10 justify-between">
            {mydoctors &&
              !_.isEmpty(mydoctors) &&
              mydoctors.map((el, i) => (
                <CardDoctor
                  maxRow={windowDimenion.winWidth > 768 ? 7 : 3}
                  doctorDetails={el}
                  indexCard={i}
                  setopenModal={(modalType) => {
                    setChatModalState(modalType);
                    setChatWith(el);
                  }}
                />
              ))}
          </div>
        )}
        {totalResult > limit && (
          <div className="mt-5">
            <Pagination
              page={page}
              setPage={(i) => setPage(i)}
              totalPage={totalPage}
              totalDocs={totalResult}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default AnnuaireDoctor;
