import axios from "axios";

import { UPDATE_EXTENSIONS } from "../config/api";

export async function updateExtensions(payload) {
  try {
    const res = await axios.post(UPDATE_EXTENSIONS, payload, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
