import React from "react";
import { useTranslation } from "react-i18next";
import Icon from "../Icon";
// import { HiChevronLeft, HiChevronRight } from 'react-icons/hi'
import Button from "../Button";

interface IProps {
  page: number;
  setPage: Function;
  totalPage: number;
  totalDocs: number;
  dontShowText?: boolean;
}

export const PaginationComponenet: React.FC<IProps> = (props: IProps) => {
  const { page, setPage, totalPage, totalDocs, dontShowText } = props;
  const { t } = useTranslation() as any;
  const getNumberCels = (total) => {
    let arrayOfJsx: JSX.Element[] = [];
    for (let i = 1; i <= total; i++) {
      arrayOfJsx.push(
        <Button
          status="none"
          aria-current="page"
          onClick={() => setPage(i)}
          className={`z-10 border-indigo-200 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium hover:bg-gray-200 ${
            i === page && "bg-blue-200"
          }`}
        >
          {i}
        </Button>
      );
    }
    return arrayOfJsx.map((e) => e);
  };
  return (
    <div className="bg-white w-full px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 rounded-2xl">
      <div className="flex-1 flex justify-between sm:hidden">
        <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
          Previous
        </span>
        <span className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
          Next
        </span>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        {dontShowText ? null : (
          <div>
            <p className="text-sm text-gray-700">
              {t("Extension.Show")}
              <span className="font-medium"> {page} </span> {t("Extension.to")}
              <span className="font-medium"> {totalDocs}</span>{" "}
              {t("Extension.results")}
            </p>
          </div>
        )}
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <Button
              onClick={() => setPage(page - 1)}
              status="none"
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              disabled={page > 1 ? false : true}
            >
              <span className="sr-only">Previous</span>
              <Icon isIcon={true} name="direction-gauche" />
            </Button>
            {totalPage && getNumberCels(totalPage)}

            <Button
              onClick={() => setPage(page + 1)}
              status="none"
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              disabled={totalPage >= page + 1 ? false : true}
            >
              <span className="sr-only">Next</span>

              <Icon isIcon={true} name="direction droite" />
            </Button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default PaginationComponenet;
