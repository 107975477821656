import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Icon from "../../components/Icon";

interface Props {
  options?: any;
  value?: any;
  onChange?: any;
  className?: any;
  placeholder?: any;
  prefix?: any;
  suffix?: any;
  prefixBordless?: boolean;
  suffixBordless?: boolean;
}
const Autocomplete: React.FC<Props> = ({
  options,
  value,
  onChange,
  className,
  placeholder,
  prefix,
  suffix,
  prefixBordless,
  suffixBordless,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [cursor, setCursor] = useState(-1);
  const ref = useRef<any>();

  const select = (option) => {
    onChange(option);
    setShowOptions(false);
  };

  const handleChange = (text) => {
    onChange(text);
    setCursor(-1);
    if (!showOptions) {
      setShowOptions(true);
    }
  };

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(value.toLowerCase())
  );

  const moveCursorDown = () => {
    if (cursor < filteredOptions.length - 1) {
      setCursor((c) => c + 1);
    }
  };

  const moveCursorUp = () => {
    if (cursor > 0) {
      setCursor((c) => c - 1);
    }
  };

  const handleNav = (e) => {
    switch (e.key) {
      case "ArrowUp":
        moveCursorUp();
        break;
      case "ArrowDown":
        moveCursorDown();
        break;
      case "Enter":
        if (cursor >= 0 && cursor < filteredOptions.length) {
          select(filteredOptions[cursor]);
        }
        break;
    }
  };

  useEffect(() => {
    const listener = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShowOptions(false);
        setCursor(-1);
      }
    };

    document.addEventListener("click", listener);
    document.addEventListener("focusin", listener);
    return () => {
      document.removeEventListener("click", listener);
      document.removeEventListener("focusin", listener);
    };
  }, []);

  return (
    <div className={classNames("relative w-64", className)} ref={ref}>
      <input
        type="text"
        className={classNames(
          "w-full border-2 py-2 outline-none rounded-lg",
          prefix ? "px-10" : " px-4 "
        )}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        onFocus={() => setShowOptions(true)}
        onKeyDown={handleNav}
        placeholder={placeholder}
      />
      {prefix && (
        <Icon
          isIcon={true}
          name={prefix}
          className={classNames(
            "absolute",
            prefixBordless ? "top-3 left-4" : "top-0.5 left-0.5"
          )}
          style={{
            width: "18px",
            height: "18px",
            filter:
              " invert(74%) sepia(19%) saturate(324%) hue-rotate(197deg) brightness(88%) contrast(93%)",
          }}
        />
      )}
      {suffix && (
        <Icon
          isIcon={true}
          name={suffix}
          className={classNames(
            "absolute",
            suffixBordless ? "top-3 -right-4" : "top-0.5 right-6"
          )}
          style={{
            width: "18px",
            height: "18px",
            filter:
              " invert(41%) sepia(67%) saturate(4988%) hue-rotate(221deg) brightness(98%) contrast(96%)",
          }}
        />
      )}
      <ul
        className={`absolute w-full rounded-lg shadow-lg bg-white  ${
          !showOptions && "hidden"
        } select-none border border-blue-300`}
      >
        {filteredOptions.length > 0 ? (
          filteredOptions.map((option, i, arr) => {
            let className = "px-4 hover:bg-gray-100 ";

            if (i === 0) className += "pt-2 pb-1 rounded-t-lg";
            else if (i === arr.length) className += "pt-1 pb-2 rounded-b-lg";
            else if (i === 0 && arr.length === 1)
              className += "py-2 rounded-lg";
            else className += "py-1";

            if (cursor === i) {
              className += " bg-gray-100";
            }

            return (
              <li
                className={className}
                key={option}
                onClick={() => select(option)}
              >
                {option}
              </li>
            );
          })
        ) : (
          <li className="px-4 py-2 text-gray-500">Pas de resultat</li>
        )}
      </ul>
    </div>
  );
};

export default Autocomplete;
