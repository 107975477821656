import { useState, useEffect } from "react";
import { FieldArray, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { editConsultationById } from "../../api/consultation";
import { getWaitingTasksOfPatient } from "../../api/task";
import Spinner from "../Spinner";
import { getMyPatientWithId } from "../../api/doctor";

import FloatInput from "../FloatInput";
import Icon from "../Icon";
import Button from "../ButtonUI";

import "./styles.scss";
import PatientInfo from "../SharedPatientDetails";
import * as Yup from "yup";
import { useAtom } from "jotai";
import { userAtom } from "../../state/auth";
import makeToast from "../Snackbar";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  closeModal?: any;
  consultationDetails?: any;
  setIsSuccess?: Function;
  prescriptions?: any;
  setPresecriptionList?: Function;
  myPatient?: Boolean;
};

const emptyPrescription: any = { medicament: "", dosage: "" };

const PrescriptionModal: React.FC<Props> = ({
  closeModal,
  consultationDetails,
  setIsSuccess,
  prescriptions,
  setPresecriptionList,
  myPatient,
}) => {
  const { t } = useTranslation() as any;
  const [modal, setModal] = useState(false);
  const [patientData, setPatientData] = useState<any>();
  const [btnLoading, setbtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user] = useAtom(userAtom);

  useEffect(() => {
    async function fetchMyAPI() {
      setLoading(true);
      let response = await getMyPatientWithId(consultationDetails?.patientId);

      let response2: any = await getWaitingTasksOfPatient(
        response?.patient?._id,
        user?._id
      );

      const { patient } = response2;
      setPatientData({ ...patient });
      setLoading(false);
    }

    if (consultationDetails) {
      fetchMyAPI();
    } else {
      makeToast("warning", t("AddPrescription.NoConsultationsFound"));
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultationDetails, user?._id]);

  return loading ? (
    <div className="prescription">
      <Spinner />
    </div>
  ) : (
    <>
      {modal && patientData && (
        <PatientInfo
          setOpenModal={setModal}
          patientData={patientData}
          selectedPatient={patientData._id}
          setModal={setModal}
          setPatientData={setPatientData}
        />
      )}
      <div className="prescription">
        <div className="flex justify-between">
          <h2>{t("AddPrescription.Title")}</h2>
          <div className="flex space-x-3 f">
            {!myPatient && (
              <Icon
                isIcon={true}
                name="fiche-patient"
                onClick={() => setModal(true)}
                className="icon cursor-pointer h-12"
              />
            )}

            <Icon
              isIcon={true}
              name="close"
              className="icon cursor-pointer h-12"
              onClick={closeModal}
            ></Icon>
          </div>
        </div>

        {/* consultation */}
        <span className="subtitle block uppercase mt-5">
          {t("AddPrescription.consultation")}
        </span>
        <FloatInput
          type="text"
          labelText={t("AddPrescription.patientName")}
          placeholder={t("AddPrescription.patientName")}
          value={patientData?.firstName + " " + patientData?.lastName}
          className="w-full capitalize bg-white"
          disabled
        />
        <span className="block courant-text my-4">
          {t("AddPrescription.consultationDate")}
          <span className="date-time-box">
            {consultationDetails?.dateConsultation?.substring(0, 10)}
            {/* {moment(Date.parse(consultationDetails?.dateConsultation))
                    .calendar()} */}
          </span>
          {t("AddPrescription.at")}
          <span className="date-time-box">
            {/* {consultationDetails?.dateConsultation.substring(11, 16)} */}
            {consultationDetails?.dateConsultation?.slice(11, 16)}
          </span>
          {t("AddPrescription.hours")}
        </span>
        <span className="courant-text mb-4">
          {t("AddPrescription.consultationCarried")}
          {consultationDetails?.type === "teleconsultation"
            ? " téléconsultation "
            : " présentiel "}
          :
        </span>

        {/* Traitement */}
        <span className="subtitle block uppercase mt-7">
          {" "}
          {t("AddPrescription.Treatment")}
        </span>
        <Formik
          initialValues={{
            prescriptions: [emptyPrescription],
          }}
          onSubmit={(values) => {
            try {
              setbtnLoading(true);
              new Promise(async (resolve, reject) => {
                // const editConsultation = await addNewPrescriptionOrReport(
                //   values,
                //   consultationDetails._id
                // );
                const editConsultation = await editConsultationById(
                  consultationDetails._id,
                  values
                );
                if (editConsultation) {
                  setIsSuccess && setIsSuccess(true);
                }

                resolve("done");
              })
                .then(() => {
                  setbtnLoading(false);
                  closeModal();
                })
                .catch((err) => {
                  setbtnLoading(false);
                  console.error(err);
                });
            } catch (err) {
              setbtnLoading(false);
              console.error(err);
            }
          }}
          validationSchema={Yup.object().shape({
            prescriptions: Yup.array().of(
              Yup.object().shape({
                medicament: Yup.string(),
                dosage: Yup.string(),
              })
            ),
          })}
        >
          {(props) => {
            const {
              values,
              // errors,
              // isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              // setFieldValue,
            } = props;

            return (
              <form onSubmit={handleSubmit}>
                <FieldArray name="prescriptions">
                  {({ push, remove }) =>
                    values.prescriptions.map((prescription: any, index) => (
                      <div key={index}>
                        <FloatInput
                          id={`prescriptions.${index}.medicament`}
                          name={`prescriptions.${index}.medicament`}
                          type="text"
                          labelText={t("AddPrescription.TreatmentName")}
                          placeholder={t("AddPrescription.TreatmentName")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.prescriptions[index].medicament}
                        />
                        <FloatInput
                          id={`prescriptions.${index}.dosage`}
                          name={`prescriptions.${index}.dosage`}
                          type="text"
                          labelText={t("AddPrescription.Dosage")}
                          placeholder={t("AddPrescription.Dosage")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.prescriptions[index].dosage}
                        />
                        <hr className="border-blue-500" />
                        {index === values.prescriptions.length - 1 && (
                          <button
                            type="button"
                            className="block w-full mt-4 border border-dashed border-gray-300 rounded-lg h-12 card-subtitle menu "
                            onClick={() => push({ medicament: "", dosage: "" })}
                          >
                            {t("AddPrescription.AddTreatment")}
                          </button>
                        )}
                      </div>
                    ))
                  }
                </FieldArray>
                <Button
                  type="button"
                  width="122.7px"
                  height="50px"
                  className="block my-10 mx-auto"
                  onClick={() => handleSubmit()}
                  disabled={btnLoading}
                >
                  {t("AddPrescription.Confirm")}
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default PrescriptionModal;
