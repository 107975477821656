import { useState } from "react";
/**
 * Helpers & API
 */
import moment from "../../helper/moment";
import { loadImage } from "../../api/documents";
import useScreenSize from "../../hooks/ScreenSize";
/**
 * Components
 */
import Modal from "../../components/ModalUI";
import DoctorDetail from "../../components/DoctorDetails";
import ConfirmModal from "../../components/DoctorDetails/ConfirmMeet";
import TakeMeet from "../../components/DoctorDetails/TakeMeet";
import ConfirmTeleConsultation from "../../components/DoctorDetails/ConfirmTeleConsultation";
import Button from "../../components/ButtonUI";
import SharedChat from "../../components/SharedChat";
/**
 * Assets
 */

import { ReactComponent as IconMessage } from "../../assets/icons/icon-message.svg";
import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";
import { useTranslation } from "react-i18next";
import { handleSpeciality } from "../../helper/handleSpeciality";

const CardDoctor = ({ doctorDetails, cardIndex, selectedDoctor }) => {
  const { windowDimenion } = useScreenSize();
  const { i18n } = useTranslation();
  const [openChatModal, setopenChatModal] = useState(false);
  const [DoctorCardModal, setDoctorCardModal] = useState(false);
  const [takeMeetModal, setTakeMeetModal] = useState(false);
  const [openTimeSlots] = useState([]) as any;
  const [addedConsultation, setAddedConsultation] = useState();
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmTeleconsultationModal, setConfirmTeleconsultationModal] =
    useState(false);
  const [meetData, setMeetData] = useState();

  const colorAvatar = [
    "#ccd2ff",
    "#d5f0f7",
    "#ffecba",
    "#fff7ab",
    "#ffccd2",
    "#ffe2c8",
  ];

  const firstOpenTimeSlot = () => {
    for (const openTimeSlot of openTimeSlots)
      for (const slotOfTheDay of openTimeSlot.data)
        if (
          slotOfTheDay.eventStatus === "open" &&
          moment(slotOfTheDay.startTime).isAfter(moment())
        )
          return slotOfTheDay.startTime;
    return null;
  };

  // useEffect(() => {
  //   if (doctorDetails) _getOpenTimeSlots();
  // }, [doctorDetails]);

  return (
    <>
      <Modal
        showModal={DoctorCardModal}
        slidemodal
        closeModal={() => setDoctorCardModal(false)}
      >
        <DoctorDetail
          closeModal={() => setDoctorCardModal(false)}
          selectSpecialist={doctorDetails}
          openTakeMeetModal={() => {
            setDoctorCardModal(false);
            setTakeMeetModal(true);
          }}
          firstOpenMeet={firstOpenTimeSlot()}
        />
      </Modal>
      <Modal
        showModal={takeMeetModal}
        slidemodal
        closeModal={() => setTakeMeetModal(false)}
      >
        <TakeMeet
          maxRow={windowDimenion.winWidth > 768 ? 7 : 3}
          closeModal={() => setTakeMeetModal(false)}
          picketDateReservation={null}
          selectSpecialist={doctorDetails}
          takeMeetModal={takeMeetModal}
          openTimeSlots={openTimeSlots}
          openConfirmMeetModal={(addedConsultation) => {
            setAddedConsultation(addedConsultation);
            setConfirmModal(true);
          }}
          openConfirmTeleconsultationModal={(takeMeetData) => {
            setMeetData(takeMeetData);
            setTakeMeetModal(false);
            setConfirmTeleconsultationModal(true);
          }}
        />
      </Modal>
      {/* Add modal Confirm take meeting */}
      <Modal
        showModal={confirmModal}
        closeModal={() => {
          setConfirmModal(false);
        }}
      >
        <ConfirmModal
          closeModal={() => {
            setConfirmModal(false);
          }}
          addedConsultation={addedConsultation}
        />
      </Modal>
      <Modal
        showModal={confirmTeleconsultationModal}
        slidemodal
        closeModal={() => {
          setConfirmTeleconsultationModal(false);
        }}
      >
        <ConfirmTeleConsultation
          closeModal={() => {
            setConfirmTeleconsultationModal(false);
          }}
          meetData={meetData}
          selectSpecialist={doctorDetails}
          openConfirmMeetModal={(addedConsultation) => {
            setAddedConsultation(addedConsultation);
            setConfirmModal(true);
          }}
        />
      </Modal>
      <SharedChat
        chatWithId={doctorDetails._id}
        showModal={openChatModal}
        closeChatModal={() => setopenChatModal(false)}
      />
      <div
        className="card-specialist md:p-3 space-x-3 shadow cursor-pointer"
        onClick={() => setTakeMeetModal(true)}
      >
        <div className="flex">
          <div
            className="rounded-full flexCenter my-auto"
            style={{
              backgroundColor:
                doctorDetails?.business_photo &&
                doctorDetails?.business_photo?.slice(0, 4) !== "http"
                  ? ""
                  : colorAvatar[cardIndex % colorAvatar.length],
            }}
          >
            {doctorDetails?.business_photo ? (
              <img
                crossOrigin="anonymous"
                referrerPolicy="origin"
                src={
                  doctorDetails?.business_photo.slice(0, 4) === "http"
                    ? doctorDetails?.business_photo
                    : loadImage(
                        doctorDetails?._id,
                        "profile",
                        "thumbnail",
                        doctorDetails?.business_photo
                      )
                }
                className="w-14 h-14 object-fill rounded-full shadow-md"
                alt="profile"
              />
            ) : doctorDetails?.sexe === "woman" ? (
              <WomanPatient />
            ) : (
              <ManPatient />
            )}
          </div>
          <div className="details flexCenter ml-4">
            <div className="flex-grow ">
              <p className="card-title">
                {" "}
                {doctorDetails?.firstName} {doctorDetails?.lastName}
              </p>
              <p className="maj">
                {handleSpeciality(
                  doctorDetails?.speciality,
                  i18n.language.includes("en")
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="buttons hidden md:block">
          {/* <Button
            status="icon-secondary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              selectedDoctor(doctorDetails);
            }}
          >
            <IconFolder />
          </Button> */}
          <Button
            status="icon-secondary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setopenChatModal(true);
            }}
          >
            <IconMessage />
          </Button>
        </div>
        <div className="flex gap-2 md:hidden ">
          {/* <div
            className="p-1 px-2 bg-gray-200 bg-opacity-50 rounded-xl"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              selectedDoctor(doctorDetails);
            }}
          >
            <IconFolder className="w-8" />
          </div> */}
          <div
            className="p-1 bg-gray-200 bg-opacity-50 rounded-xl"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setopenChatModal(true);
            }}
          >
            <IconMessage />
          </div>
        </div>
      </div>
    </>
  );
};

export default CardDoctor;
