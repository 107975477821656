import React, { useState } from "react";
import { useAtom } from "jotai";
import _ from "lodash";
import Button from "../../../components/ButtonUI";
import Modal from "../../../components/ModalUI";

import HeaderModal from "./modal/HeaderModal";
import ChatModal from "../../../components/SharedChat";
import ShareFolderModal from "./modal/ShareFolderModal";
import SharedFolderFromChat from "./modal/PreviewSharedFolder";

import { ReactComponent as IconMessage } from "../../../assets/icons/icon-message.svg";
import { ReactComponent as IconFolder } from "../../../assets/icons/icon-piece-jointe-blanc.svg";
import { ReactComponent as WomanPatient } from "../../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../../assets/illustrations/illustration-homme-patient.svg";

import { userAtom } from "../../../state/auth";
import { socketAtom } from "../../../state/socket";
import { getAllMyPatientsByDoctorId2 } from "../../../api/doctor";
import {
  generateNewShareFolder,
  getShareFolderById,
} from "../../../api/sharedFolder";
import "./styles.scss";
import { loadImage } from "../../../api/documents";
import { handleSpeciality } from "../../../helper/handleSpeciality";
import { useTranslation } from "react-i18next";

const colorAvatar = [
  "#ccd2ff",
  "#d5f0f7",
  "#ffecba",
  "#fff7ab",
  "#ffccd2",
  "#ffe2c8",
];

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & { specialistDetails: any; i: number };

const CardSpecialist: React.FC<Props> = ({ specialistDetails, i }) => {
  const { i18n } = useTranslation();
  const [socket] = useAtom<any>(socketAtom);
  const [user] = useAtom(userAtom);
  const [openModal, setopenModal] = useState(false);
  // const [socket, setSocket] = useState<any>();
  const [chatOrShareFolder, setChatOrShareFolder] = useState("chat");
  const [sharedFolderDetails, setSharedFolderDetails] = useState();
  const [, setPreviewReportModal] = useState({
    status: false,
    rapport: "",
  });
  const [, setOpenPreviewPrescription] = useState<boolean>(false);
  const [, setPreviewPrescription] = useState() as any;
  const handleGenerateNewShareFolder = async (values) => {
    try {
      const response = await generateNewShareFolder(values);
      setChatOrShareFolder("chat");
      if (socket) {
        socket.emit("joinRoom", {
          chatroomId: [specialistDetails._id, user?._id].sort().join(""),
        });

        socket.emit("chatroomMessage", {
          message: "sharedFolder",
          sentFrom: user?._id,
          sentTo: specialistDetails._id,
          type: "sharedFolder",
          sharedFolder: response?.data?._id,
          chatroomId: [specialistDetails._id, user?._id].sort().join(""),
          onModelFrom: "Doctor",
          onModelTo: "Doctor",
        });

        socket.emit("leaveRoom", {
          chatroomId: [specialistDetails._id, user?._id].sort().join(""),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePreviewSharedFolder = async (values) => {
    try {
      setChatOrShareFolder("preview");
      const ResponseSharedFolderDetails = await getShareFolderById(
        values.sharedFolder
      );
      setSharedFolderDetails(ResponseSharedFolderDetails.data);
    } catch (error) {
      console.error(error);
    }
  };

  const ToggleContent = () => {
    chatOrShareFolder === "chat"
      ? setChatOrShareFolder("shareFolder")
      : setChatOrShareFolder("chat");
  };

  return (
    <>
      {/* Show modal */}

      <ChatModal
        chatWithId={specialistDetails?._id}
        showModal={chatOrShareFolder === "chat" && openModal}
        closeChatModal={() => setopenModal(false)}
        headerShareFolder
        handleShareFolder={ToggleContent}
        handlePreviewSharedFolder={handlePreviewSharedFolder}
      />

      {chatOrShareFolder !== "chat" && (
        <Modal
          showModal={openModal}
          slidemodal={true}
          closeModal={() => setopenModal(false)}
        >
          <>
            <HeaderModal
              specialistDetails={specialistDetails}
              chatOrShareFolder={chatOrShareFolder}
              ToggleContent={ToggleContent}
              closeModal={() => setopenModal(false)}
              user={user}
            />
            {chatOrShareFolder === "shareFolder" ? (
              <ShareFolderModal
                generateNewShareFolder={handleGenerateNewShareFolder}
                setPreviewReportModal={setPreviewReportModal}
                getPatientById={getAllMyPatientsByDoctorId2}
                setOpenPreviewPrescription={(el) => {
                  setOpenPreviewPrescription(true);
                  setPreviewPrescription(el);
                }}
                specialistDetails={specialistDetails}
                socket={socket}
                user={user}
              />
            ) : (
              <SharedFolderFromChat
                sharedFolderDetails={sharedFolderDetails}
                user={user}
              />
            )}
          </>
        </Modal>
      )}
      {/* <Modal
        showModal={previewReportModal?.status}
        closeModal={() =>
          setPreviewReportModal({
            status: false,
            rapport: "",
          })
        }
      >
        <div className="p-5">
          <p>{ReactHtmlParser(ReactHtmlParser(previewReportModal?.rapport))}</p>
        </div>
      </Modal> */}
      <div className="card-specialist p-3 space-x-3 max-w-max hover:shadow">
        <div className="flex">
          <div
            className="rounded-full h-12 w-12 flexCenter mx-5 my-auto"
            style={{
              backgroundColor: specialistDetails?.business_photo
                ? ""
                : colorAvatar[i % colorAvatar.length],
            }}
          >
            {specialistDetails?.business_photo &&
            specialistDetails?.business_photo.slice(0, 4) === "http" ? (
              <div className="avatar-doctor ">
                <img
                  crossOrigin="anonymous"
                  referrerPolicy="origin"
                  src={specialistDetails?.business_photo}
                  alt="doctor avatar"
                  className="object-cover rounded-full w-full h-full"
                />
              </div>
            ) : specialistDetails?.business_photo ? (
              <img
                crossOrigin="anonymous"
                referrerPolicy="origin"
                src={loadImage(
                  specialistDetails?._id,
                  "profile",
                  "thumbnail",
                  specialistDetails?.business_photo
                )}
                alt="patient"
                className="object-cover rounded-full w-full h-full"
              />
            ) : specialistDetails.sexe === "woman" ? (
              <WomanPatient />
            ) : (
              <ManPatient />
            )}
          </div>
          <div className="details">
            <div className="flex-grow">
              <p className="card-title">
                {specialistDetails?.firstName} {specialistDetails?.lastName}
              </p>
              <p className="card-subtitle mb-2">
                {handleSpeciality(
                  specialistDetails?.speciality,
                  i18n.language.includes("en")
                )}
              </p>
              {!_.isEmpty(specialistDetails?.expertises) && (
                <p className="card-subtitle mb-2 flex flex-col">
                  {specialistDetails?.expertises.map((el) => (
                    <span className="mx-2">#{el.expertise}</span>
                  ))}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="flex space-x-3">
          <Button
            status="icon-secondary"
            onClick={() => {
              setopenModal(true);
              setChatOrShareFolder("shareFolder");
            }}
          >
            <IconFolder />
          </Button>
          <Button
            status="icon-secondary"
            onClick={() => {
              setopenModal(true);
              setChatOrShareFolder("chat");
            }}
          >
            <IconMessage />
          </Button>
        </div>
      </div>
    </>
  );
};

export default CardSpecialist;
