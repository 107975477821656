/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Button from "../../components/ButtonUI";
import io from "socket.io-client";

import Modal from "../../components/ModalUI";
import Icon from "../../components/Icon";
import { useAtom } from "jotai";
import { userAtom } from "../../state/auth";
import classnames from "classnames";
import ChatTeleConsultationModal from "./Modals/ChatModal";
import ChatTeleConsultation from "./ChatTeleConsultation";
import { getConsultationById } from "../../api/consultation";
import { endTeleconsultation } from "../../api/teleconsultation";
import makeToast from "../../components/Snackbar";
import VideoCallContainer from "../Teleconsultation/VideoCallContainer";

import { ReactComponent as IconMessage } from "../../assets/icons/icon-message.svg";
import { handleSpeciality } from "../../helper/handleSpeciality";
import { socketAtom } from "../../state/socket";

interface Props {}

const KatomiEndPoint =
  process.env.REACT_APP_KATOMI_API_URL || "http://localhost:8080";

const Teleconsultation = (props: Props) => {
  const { t, i18n } = useTranslation();
  const localStreamRef = useRef() as any;
  const remoteStreamRef = useRef() as any;
  const [chatModal, setChatModal] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [consultationDetails, setconsultationDetails] = useState<any>();
  const [endConsultationModal, setEndConsultationModal] = useState(false);
  const [, setLoading] = useState(false);
  const params: any = useParams();
  // const history: any = useHistory();
  const { idConsultation } = params;
  const [user] = useAtom(userAtom) as any;
  const [socket, setSocket] = useAtom(socketAtom);

  const toggleFullScreen = () => setFullScreen(fullScreen ? false : true);

  const _getConsultationById = async () => {
    setLoading(true);
    try {
      const response = await getConsultationById(idConsultation);
      setLoading(false);
      setconsultationDetails(response?.consultation);
    } catch (error) {
      setLoading(false);
    }
  };

  const _endTeleconsultation = async () => {
    try {
      await endTeleconsultation(idConsultation);
      window.location.href = "/";
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    _getConsultationById();
  }, []);

  useEffect(() => {
    if (consultationDetails && consultationDetails.status === "done") {
      makeToast("warning", t("Teleconsultation.alreadyEnd"));
    }
  }, [consultationDetails]);

  const setupSocket = () => {
    const newSocket = io(KatomiEndPoint, {
      withCredentials: true,
    });
    newSocket.on("disconnect", () => {
      setSocket(null);
    });
    newSocket.on("connection", () => {});
    newSocket.on("me", (id) => {
      localStorage.setItem("socketId", id);
    });
    setSocket(newSocket);
  };

  useEffect(() => {
    if (!socket) {
      setupSocket();
    } //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="block lg:hidden">
        <Modal
          showModal={chatModal}
          closeModal={() => {
            setChatModal(false);
          }}
        >
          <ChatTeleConsultationModal
            chatroomId={consultationDetails?._id}
            chatWithId={consultationDetails?.patientId?._id}
          />
        </Modal>
      </div>
      <Modal
        showModal={endConsultationModal}
        closeModal={() => setEndConsultationModal(true)}
      >
        <div className="flexCenter flex-col p-10 space-y-5">
          <h3>{t("Teleconsultation.LeaveConsultation")}</h3>
          <Button
            width="170px"
            height="50px"
            onClick={() => {
              _endTeleconsultation();
            }}
          >
            {t("Teleconsultation.Confirm")}
          </Button>
        </div>
      </Modal>
      <div className="teleconsultation flex -mt-10 ">
        <div className={fullScreen ? "w-full" : "w-full lg:w-2/3 xxl:w-3/4"}>
          <header className="w-full flex gap-4 lg:items-center lg:justify-between flex-col lg:flex-row">
            <div>
              <h2>{`${consultationDetails?.doctorId?.firstName} ${consultationDetails?.doctorId?.lastName}`}</h2>
              <p className="px-3 py-2 max-w-max motif-border card-subtitle">
                {handleSpeciality(
                  consultationDetails?.doctorId?.speciality,
                  i18n.language.includes("en")
                )}
              </p>
            </div>
            <div className="flex space-x-3">
              <Button
                status="primary"
                width="158px"
                height="50px"
                onClick={() => {
                  setEndConsultationModal(true);
                }}
              >
                {t("Teleconsultation.End")}
              </Button>

              <Button
                status="primary"
                width="50px"
                height="50px"
                className="hidden lg:flex justify-center items-center"
                onClick={toggleFullScreen}
              >
                {fullScreen ? (
                  <Icon isIcon={true} name="fleches-vs" />
                ) : (
                  <Icon
                    isIcon={true}
                    name="fleche-blanc"
                    style={{
                      filter:
                        "invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%)",
                    }}
                  />
                )}
              </Button>
              <IconMessage
                className="cursor-pointer block lg:hidden"
                onClick={() => {
                  setChatModal(true);
                }}
              />
            </div>
          </header>
          <div className="m-1 lg:m-5  flex-col">
            <VideoCallContainer
              idConsultation={idConsultation}
              endCall={() => {
                window.location.href = "/";
              }}
            />
          </div>
          {/* <div className="m-1 lg:m-5  flex-col ">
            <VideoCallContainer
              localStreamRef={localStreamRef}
              remoteStreamRef={remoteStreamRef}
              updateMic={updateMic}
              updateVideo={updateVideo}
              localStream={localStream}
              endCall={() => {
                window.location.href = "/";
                updateMic();
                updateVideo();
              }}
            />
          </div> */}
        </div>
        <div
          className={classnames(
            fullScreen ? "hidden" : "hidden lg:block lg:w-1/3 xxl:w-1/4"
          )}
        >
          <div
            className={classnames(
              "tele-right-slide relative shadow",
              "bg-white rounded-2xl ml-4 pt-5 "
            )}
          >
            <ChatTeleConsultation
              chatWithId={consultationDetails?.doctorId?._id}
              consultationDetails={consultationDetails}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Teleconsultation;
