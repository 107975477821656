import _ from "lodash";
const filterFirstBookedTimeSlot = (arr) => {
  let test = false;
  let rslt = [] as any;

  if (!arr || _.isEmpty(arr)) return rslt;
  for (const slot of arr) {
    if (test) rslt.push(slot);
    else if (slot.eventStatus === "open") {
      test = true;
      rslt.push(slot);
    }
  }
  return rslt;
};

export { filterFirstBookedTimeSlot };
