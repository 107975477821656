import { useState } from "react";
import { useAtom } from "jotai";
import moment from "../../helper/moment";
import { useTranslation } from "react-i18next";
/**
 * Helper & API
 */
import { userAtom } from "../../state/auth";
import { colorAvatarAtom } from "../../state/constants";
import { imcStatus } from "../../helper/imc";
import useScreenSize from "../../hooks/ScreenSize";
/**
 * Component
 */
import CardDetails from "./PatientDetails";
import PreviewAgenda from "./PreviewAgenda";
import EditMyDataModal from "./Modals/EditMyDataModal";
import MyHealthDataModal from "./Modals/MyHealthDataModal";
import EditEmailModal from "./Modals/EditEmailModal";
import MeetHistoryModal from "./Modals/MeetHistoryModal";
import EditPasswordModal from "./Modals/EditPasswordModal";
import MyDoumentSlideModal from "./Modals/MyDoumentSlideModal";
import Modal from "../../components/ModalUI/index";
import Icon from "../../components/Icon";
/**
 * Assets
 */
import { ReactComponent as EmojiAgenda } from "../../assets/emoji/emoji-agenda.svg";
import IconADN from "../../assets/images/adn.png";
import IconPadLock from "../../assets/images/pad-lock.png";

import "./styles.scss";

const MyAccountPatient = () => {
  const { t, i18n } = useTranslation() as any;
  const { windowDimenion } = useScreenSize();
  const [user] = useAtom<any>(userAtom) as any;
  const [colorAvatar] = useAtom<any>(colorAvatarAtom);

  const [editMyDataModal, setEditMyDataModal] = useState(false);
  const [myHealthModal, setMyHealthModal] = useState(false);
  const [editEmailModal, setEditEmailModal] = useState(false);
  const [editPasswordModal, setEditPasswordModal] = useState(false);
  const [meetHistoryModal, setMeetHistoryModal] = useState(false);
  const [myDocumentSlideModal, setMyDocumentSlideModal] = useState(false);

  const [meetDetails, setMeetDetails] = useState();

  const toggleMyDocumentSlideModal = () => {
    setMyDocumentSlideModal(!myDocumentSlideModal);
    setMeetHistoryModal(false);
  };

  const toggleEditMyDataModal = () => setEditMyDataModal(!editMyDataModal);
  const toggleMeetHistoryModal = () => setMeetHistoryModal(!meetHistoryModal);
  const toggleMyHealthModal = () => setMyHealthModal(!myHealthModal);
  const toggleEditEmailModal = () => setEditEmailModal(!editEmailModal);
  const toggleEditPasswordModal = () =>
    setEditPasswordModal(!editPasswordModal);

  return (
    <>
      <Modal
        showModal={myDocumentSlideModal}
        slidemodal={windowDimenion.winWidth > 768 ? true : false}
        closeModal={toggleMyDocumentSlideModal}
      >
        <MyDoumentSlideModal
          closeModal={toggleMyDocumentSlideModal}
          meetDetails={meetDetails}
        />
      </Modal>

      <Modal showModal={editMyDataModal} closeModal={toggleEditMyDataModal}>
        <EditMyDataModal closeModal={toggleEditMyDataModal} />
      </Modal>

      <Modal showModal={myHealthModal} closeModal={toggleMyHealthModal}>
        <MyHealthDataModal closeModal={toggleMyHealthModal} />
      </Modal>

      <Modal showModal={editEmailModal} closeModal={toggleEditEmailModal}>
        <EditEmailModal closeModal={toggleEditEmailModal} />
      </Modal>

      <Modal showModal={editPasswordModal} closeModal={toggleEditPasswordModal}>
        <EditPasswordModal closeModal={toggleEditPasswordModal} />
      </Modal>

      <Modal
        showModal={meetHistoryModal}
        slidemodal={windowDimenion.winWidth > 768 ? true : false}
        closeModal={toggleMeetHistoryModal}
      >
        <MeetHistoryModal
          closeModal={toggleMeetHistoryModal}
          winWidth={windowDimenion.winWidth}
          toggleMyDocumentSlideModal={(meetDetails) => {
            toggleMyDocumentSlideModal();
            setMeetDetails(meetDetails);
          }}
        />
      </Modal>

      <div className="my-account-patient">
        <div className="flex items-center justify-between">
          <h1 className="mb-5">{t("ProfilePatient.MyAccount")}</h1>
          <div className="switch-local">
            <input
              id="language-toggle"
              className="check-toggle check-toggle-round-flat"
              type="checkbox"
              checked={!i18n.language.includes("en")}
              onChange={() =>
                i18n.language.includes("en")
                  ? i18n.changeLanguage("fr")
                  : i18n.changeLanguage("en")
              }
            />
            <label htmlFor="language-toggle"></label>
            <span className="on">En</span>
            <span className="off">Fr</span>
          </div>
        </div>
        <div className="flex w-full lg:space-x-5 lg:flex-row flex-col">
          <div className="lg:w-2/3">
            {/* Card Details */}
            <div className="rounded-xl flex flex-col space-y-7 bg-white lg:p-5 py-5 px-2 mb-10">
              <div className="flex justify-between items-center mb-4">
                <h3 className="flex items-center space-x-3">
                  {/* <img crossOrigin = "anonymous"  referrerPolicy="origin" src={IconProfile} alt="profle" width="14" height="14" /> */}

                  <span>{t("ProfilePatient.MYINFORMATION")}</span>
                </h3>
                <span
                  className="link cursor-pointer"
                  onClick={toggleEditMyDataModal}
                >
                  {t("ProfilePatient.Edit")}
                </span>
              </div>
              <hr className="hr-m-0" />
              <CardDetails colorAvatar={colorAvatar} />
            </div>

            {/* My health data */}

            <div className="rounded-xl flex flex-col space-y-7 bg-white lg:p-5 py-5 px-2 my-10">
              <div className="flex justify-between content-center mb-4">
                <h3 className="flexCenter space-x-3">
                  <img
                    crossOrigin="anonymous"
                    referrerPolicy="origin"
                    src={IconADN}
                    alt="ADN"
                    width="17"
                    height="17"
                  />

                  <span> {t("ProfilePatient.MYHEALTHDATA")}</span>
                </h3>
                <span
                  className="link cursor-pointer"
                  onClick={toggleMyHealthModal}
                >
                  {t("ProfilePatient.Edit")}
                </span>
              </div>
              <hr className="hr-m-0" />
              <div className="flex w-full justify-between items-center flex-col md:flex-row gap-3">
                <div className="flex items-center justify-between lg:w-3/5 gap-5">
                  <span>
                    {t("ProfilePatient.size")}
                    <span className="text-xs text-blue-500 mx-2 font-bold">
                      {user?.size}
                    </span>
                    {t("ProfilePatient.cm")}
                  </span>
                  <span>
                    {t("ProfilePatient.Weight")}
                    <span className="text-xs text-blue-500 mx-2 font-bold">
                      {user?.weight}
                    </span>
                    {t("ProfilePatient.kg")}
                  </span>
                  <span>
                    {t("ProfilePatient.BMI")}{" "}
                    <span className="text-xs font-bold">
                      {(user?.weight / Math.pow(user?.size / 100, 2)).toFixed(
                        2
                      )}
                    </span>
                  </span>
                </div>

                <span className="box flex space-x-2 items-center py0">
                  <Icon isIcon={true} name="imc" />
                  {imcStatus(user?.weight, user?.size, t)}
                </span>
              </div>
            </div>

            {/* Connexion */}

            <div className="rounded-xl flex flex-col space-y-7 bg-white md:p-5 py-5 px-2 my-10">
              <div className="flex space-x-3 mb-4">
                <img
                  crossOrigin="anonymous"
                  referrerPolicy="origin"
                  src={IconPadLock}
                  alt="padlock"
                  width="20"
                  height="20"
                  className="flex-none mt-2 max-h-5"
                />
                <div className="flex flex-col">
                  <h3 className="uppercase">
                    {" "}
                    {t("ProfilePatient.Connection")}
                  </h3>
                  <span className="card-subtitle text-xs">
                    {t("ProfilePatient.LastUpdatedOn")} {moment().calendar()}
                  </span>
                </div>
              </div>
              <hr className="hr-m-0" />
              <div className="">
                <div className="flex justify-between px-2 mb-3">
                  <div className="flexCenter space-x-1">
                    <span className="courant-text flex items-center">
                      <Icon isIcon={true} name="mail" />{" "}
                      {t("ProfilePatient.Email")}
                    </span>
                    <span className="card-subtitle text-xs">{user?.email}</span>
                  </div>
                  {/* We Cant update Email for now */}
                  {/* <span
                    className="link cursor-pointer"
                    onClick={toggleEditEmailModal}
                  >
                    Modifier
                  </span> */}
                </div>
                <div className="flex justify-between items-center px-2 my-3">
                  <div className="flexCenter">
                    <span className="courant-text flex items-center">
                      <Icon isIcon={true} name="lockCB" />
                      {t("ProfilePatient.Password")}{" "}
                    </span>
                    <span className="card-subtitle text-xs">
                      {" ••••••••••••••••••"}
                    </span>
                  </div>

                  <span
                    className="link cursor-pointer"
                    onClick={toggleEditPasswordModal}
                  >
                    {t("ProfilePatient.Edit")}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-1/3">
            <div className="flex justify-between items-center h-16 bg-white rounded-t-2xl">
              <h3 className="flex items-center space-x-2 px-3">
                <EmojiAgenda />
                <span className="uppercase">
                  {" "}
                  {t("ProfilePatient.MyDates")}
                </span>
              </h3>
              <span
                className="link cursor-pointer mr-3 md:mr-5"
                onClick={toggleMeetHistoryModal}
              >
                {t("ProfilePatient.History")}
              </span>
            </div>
            <hr />
            <div className="max-h-screen overflow-y-scroll">
              <PreviewAgenda winWidth={windowDimenion.winWidth} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAccountPatient;
