/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import _ from "lodash";
import { addlogsDMP } from "../../../api/logs";
import { DMPAtom } from "../../../state/myPatient";
import { handleIdentifiantSysteme, statusLabels } from "./helper";
import { CPS, DMP } from "../../../api/devbox";
import { cpsDataAtom } from "../../../state/cardCpsData";
import * as tiff from "tiff";

interface Props {
  patientData: any;
}

// ***CHECKLIST***
// 1-MakeSure that devbox Opened  ✔️
// 2-SET AUTHOR ✔️ checkCPSCard
// 3-MakeSure That Patient have INS qualified  ✔️   !_.isEmpty(patientData?.INS?.responseINS?.insActif) && statusLabels[patientData?.INS?.INSStaus] === "Identité qualifiée" &&
// 4-MakeSure that this patient have DMP && CPS have Auth ✔️
// 5-SET PATIENT_DMP ✔️
// 6-GET DMP DOCS ✔️
// 7-GET VACCINATION ✔️
// 8-SHOW CONTENT ✔️
// 9-SHOW VACCINATION ✔️
// 8-UPLOAD REPORTS & PRESCRIPTIONS & PICTURES ✔️
const useDMP = (props: Props) => {
  const { patientData } = props;
  const { i18n } = useTranslation();
  const [, setcpsCardInfo] = useAtom(cpsDataAtom);
  const [loading, setLoading] = useState({ status: null }) as any;
  // const [loadingIndice, setLoadingIndice] = useState("") as any;
  const [checkCPSCard, setcheckCPSCard] = useState(false);
  const [checkPatientINSQualified, setCheckPatientINSQualified] =
    useState(false);
  const [checkPatientDMPAndAuth, setCheckPatientDMPAndAuth] = useState(false);
  const [refreshDMP, setRefreshDMP] = useState(true);
  const [patientDMPData, setPatientDMPData] = useState() as any;
  const [author, setAuthor] = useState() as any;
  const [documents, setDocuments] = useState([]) as any;
  const [vaccination, setVaccination] = useState([]) as any;
  const [container, setContainer] = useState(null) as any;
  const newWindow = useRef(null) as any;
  const [, setContent] = useAtom(DMPAtom) as any;

  const tiff2Png = (base64TiffImg) => {
    try {
      const results: String[] = [];
      const tiffData = atob(base64TiffImg);
      const tiffArray = new Uint8Array(tiffData.length);

      for (let i = 0; i < tiffData.length; i++) {
        tiffArray[i] = tiffData.charCodeAt(i);
      }

      const tiffImgs = tiff.decode(tiffArray) as any;

      for (const tiffImg of tiffImgs) {
        const canvas = document.createElement("canvas");
        canvas.width = tiffImg.width;
        canvas.height = tiffImg.height;

        const context = canvas.getContext("2d") as any;
        const imageData = context.createImageData(
          tiffImg.width,
          tiffImg.height
        );
        imageData.data.set(tiffImg.data);
        context.putImageData(imageData, 0, 0);
        results.push(canvas.toDataURL("image/png"));
      }
      return results;
    } catch (error) {
      console.log("🚀 ~ file: useDMP.tsx:59 ~ tiff2Png ~ error:", error);
      return [];
    }
  };

  const _handleContent = (base64Type, base64Content) => {
    const div = document.createElement("div");
    if (base64Type === "text/plain") {
      div.innerHTML = `<p>${atob(base64Content)}</p>`;
    } else if (base64Type === "text/rtf") {
      const b64ToDraft = atob(base64Content);
      div.innerHTML = ReactHtmlParser(b64ToDraft);
    } else if (base64Type?.includes("image")) {
      if (base64Type === "image/tiff") {
        const pagesImages = tiff2Png(base64Content) as any;
        let innerHtml = "";
        for (const img of pagesImages) {
          innerHtml += `<img crossOrigin = "anonymous"  referrerPolicy="origin" src="${img}"/>`;
        }
        div.innerHTML = innerHtml;
      } else
        div.innerHTML = `<img crossOrigin = "anonymous"  referrerPolicy="origin" src="data:${base64Type};base64,${base64Content}" />`;
    } else if (base64Type === "application/pdf") {
      div.innerHTML = ` <embed
          src="data:application/pdf;base64,${base64Content}"
          className="w-full"
          width="100%"
          height="100%"
        />`;
    } else if (!base64Type) return <h3> Ce document est de type inconnue </h3>;
    else {
      // await addlogsDMP({ unhandledType: base64Type });
      return <h3> Type de document n'est pas pris en charge </h3>;
    }

    setContainer(div);
  };

  const _showContent = async (doc) => {
    try {
      setContent({ openModal: true, loadingContent: true });
      const response = await DMP.td32RetrieveContent({
        context: {
          author,
          modeAcces: {
            acces: "NORMAL",
          },
        },
        request: {
          matriculeINS: patientDMPData?.matriculeINS,
          document: {
            repositoryUniqueId: doc?.repositoryUniqueId,
            uniqueId: doc?.uniqueId,
          },
        },
      });

      const parser = new DOMParser();
      const dcmnt = parser.parseFromString(
        atob(response?.cdaContent),
        "application/xml"
      );

      const errorNode = dcmnt.querySelector("parsererror");
      if (errorNode) {
        console.log("error while parsing");
      } else {
        console.log(
          "🚀🚀🚀 dcmnt",
          dcmnt,
          "base64 content: ",
          dcmnt?.documentElement?.querySelector("nonXMLBody > text")?.innerHTML,
          "mediaType :",
          dcmnt?.documentElement
            ?.querySelector("nonXMLBody > text")
            ?.getAttribute("mediaType")
        );
      }
      //type: "text/plain"
      const base64Type = dcmnt?.documentElement
        ?.querySelector("nonXMLBody > text")
        ?.getAttribute("mediaType");

      _handleContent(
        base64Type,
        dcmnt?.documentElement?.querySelector("nonXMLBody > text")?.innerHTML
      );
      setContent((x) => ({ ...x, loadingContent: false }));

      if (
        base64Type !== "text/plain" &&
        base64Type !== "text/rtf" &&
        base64Type !== "text/rtf" &&
        !base64Type?.includes("image")
      ) {
        await addlogsDMP({
          unhandledType: base64Type,
        });
      }
    } catch (error) {
      console.log([error]);
    }
  };

  useEffect(() => {
    // When container is ready
    if (container) {
      // Create window
      newWindow.current = window.open(
        "",
        "",
        ""
        // "width='100%',height='100%',left=200,top=200"
      );
      // Append container
      newWindow?.current?.document?.body.appendChild(container);

      // Save reference to window for cleanup
      const curWindow = newWindow.current;

      // Return cleanup function
      return () => {
        curWindow?.close();
      };
    }
  }, [container]);

  const _getPatientVaccination = async () => {
    try {
      setLoading({ status: "80%", indice: "Récupération des vaccinations" });
      const response = await DMP.td31_32FindAndRetrieveContent({
        context: {
          author,
          modeAcces: {
            acces: "NORMAL",
          },
        },
        request: {
          query: "TD31Request$FindHistoriqueVaccinations",
          matriculeINS: patientDMPData?.matriculeINS,
        },
      });
      setVaccination(response?.document?.vaccinations);
      setLoading({ status: false, indice: "" });
    } catch (error) {
      console.log([error]);
      setLoading({ status: false, indice: "" });
    }
  };

  const _getPatientDMP = async (
    filter?,
    auteur?,
    classCode?,
    confidentialityCode?,
    creationTimeFrom?,
    creationTimeTo?,
    submissionTimeFrom?,
    submissionTimeTo?,
    query?,
    serviceStartTimeFrom?,
    serviceStartTimeTo?,
    serviceStopTimeFrom?,
    serviceStopTimeTo?,
    statuses?,
    typeCode?
  ) => {
    try {
      setLoading({ status: "60%", indice: "Récupération des documents" });

      const response = await DMP.td31Find({
        context: {
          author,
          modeAcces: {
            acces: "NORMAL",
          },
        },
        request: {
          matriculeINS: patientDMPData?.matriculeINS,
          query: query ? query : "FindDocuments",
          statuses: statuses ? statuses : ["APPROVED"],

          auteur: auteur ? author : null,
          classCode: classCode ? classCode : null,
          confidentialityCode: !_.isEmpty(confidentialityCode)
            ? confidentialityCode
            : null,
          creationTimeFrom: creationTimeFrom ? creationTimeFrom : null,
          creationTimeTo: creationTimeTo ? creationTimeTo : null,
          submissionTimeFrom: submissionTimeFrom ? submissionTimeFrom : null,
          submissionTimeTo: submissionTimeTo ? submissionTimeTo : null,
          serviceStartTimeFrom: serviceStartTimeFrom
            ? serviceStartTimeFrom
            : null,
          serviceStartTimeTo: serviceStartTimeTo ? serviceStartTimeTo : null,
          serviceStopTimeFrom: serviceStopTimeFrom ? serviceStopTimeFrom : null,
          serviceStopTimeTo: serviceStopTimeTo ? serviceStopTimeTo : null,
          typeCode: typeCode ? typeCode : null,
        },
      });

      if (!_.isEmpty(response?.documents)) {
        setDocuments(response?.documents);
      } else setDocuments([]);
      setLoading({ status: false, indice: "" });

      if (!filter) await _getPatientVaccination();
    } catch (error) {
      setLoading({ status: false, indice: "" });
      console.log([error]);
    }
  };

  const _checkPatientDMPAndAuth = async () => {
    try {
      setLoading({
        status: "30%",
        indice: "Vérification de l'existence du dmp",
      });
      const response = await DMP.td02Exist({
        context: {
          author,
          modeAcces: {
            acces: "NORMAL",
          },
        },
        request: {
          matriculeINS: patientData?.matriculeINS
            ? patientData?.matriculeINS
            : {
                valeur:
                  patientData?.INS?.responseINS.insActif?.valeur +
                  patientData?.INS?.responseINS.insActif?.cle,
                identifiantSysteme: handleIdentifiantSysteme(
                  patientData?.INS?.responseINS.insActif?.identifiantSysteme
                ),
              },
        },
      });

      //CHECK IF DMP ACTIF

      if (!_.isEmpty(response?.patient) && response?.status === "ACTIF") {
        setPatientDMPData(response?.patient);
        if (response?.autorisation === "VALIDE")
          setCheckPatientDMPAndAuth(true);
      }

      //ADD AUTORISATION IF NOT EXIST
      if (response?.status === "ACTIF" && response?.autorisation !== "VALIDE") {
        setLoading({ status: "30%", indice: "Ajout de l'autorisation" });

        await DMP.td03AddAuthorization({
          context: {
            author,
            modeAcces: {
              acces: "NORMAL",
            },
          },
          request: {
            matriculeINS: response?.patient?.matriculeINS,
            role: "STANDARD",
          },
        });
        setCheckPatientDMPAndAuth(true);
      }

      setLoading({ status: false, indice: "" });
    } catch (error) {
      setLoading({ status: false, indice: "" });

      console.log("Error", Error);
    }
  };

  useEffect(() => {
    console.log(
      patientData?.matriculeINS
        ? true
        : !_.isEmpty(patientData?.INS?.responseINS?.insActif) &&
            statusLabels[patientData?.INS?.INSStaus] === "Identité qualifiée"
    );
    setCheckPatientINSQualified(
      patientData?.matriculeINS
        ? true
        : !_.isEmpty(patientData?.INS?.responseINS?.insActif) &&
            statusLabels[patientData?.INS?.INSStaus] === "Identité qualifiée"
    );
  }, [patientData]);

  // Set Author
  useEffect(() => {
    const myAPI = async () => {
      try {
        setLoading({ status: "10%", indice: "Vérification du carte CPS" });

        const responseReaderInfo = await CPS.readerInfo(
          false,
          i18n.language.includes("en")
        );
        setcheckCPSCard(responseReaderInfo);
        const doctorData = await CPS.readCard();
        setcpsCardInfo(doctorData);
        setAuthor({
          internalId: doctorData?.infoPs?.idNatPs,
          nom: doctorData?.namePs?.nomPatronymique,
          prenom: doctorData?.namePs?.prenomUsuel,
          secteurActivite:
            doctorData?.activitiesPs[`${doctorData?.selectedActivity}`]
              ?.structSecActRpps,
          structureSante: {
            idNational:
              doctorData?.activitiesPs[`${doctorData?.selectedActivity}`]
                ?.structIdNat,
            nom: doctorData?.activitiesPs[`${doctorData?.selectedActivity}`]
              ?.structRaisSoc,
          },
          specialite: "G15_10/SM26",
        });
        setLoading({ status: false, indice: "" });
      } catch (error) {
        setLoading({ status: false, indice: "" });

        console.log(error);
      }
    };

    myAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set Patient DMP DATA
  useEffect(() => {
    if (checkPatientINSQualified && checkCPSCard && author) {
      _checkPatientDMPAndAuth();
    }
  }, [checkPatientINSQualified, checkCPSCard, author]);

  // GET Patient DMP
  useEffect(() => {
    if (
      checkPatientINSQualified &&
      checkCPSCard &&
      author &&
      checkPatientDMPAndAuth
    ) {
      _getPatientDMP();
    }
  }, [
    checkPatientINSQualified,
    checkCPSCard,
    checkPatientDMPAndAuth,
    refreshDMP,
  ]);

  return {
    loading,
    checkCPSCard,
    patientDMPData,
    checkPatientINSQualified,
    checkPatientDMPAndAuth,
    author,
    documents,
    vaccination,
    refreshDMP,
    setRefreshDMP,
    _showContent,
    _getPatientDMP,
  };
};

export default useDMP;
