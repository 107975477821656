import React, { useState } from "react";
import moment from "../../helper/moment";
import _ from "lodash";
/**
 * Helper & API
 */
import { patientUpdateConsultationDate } from "../../api/consultation";
import useScreenSize from "../../hooks/ScreenSize";
/**
 * Component
 */
import Button from "../ButtonUI";
import Icon from "../Icon";
import DoctorTimeSlot from "../DoctorTimeSlots";
/**
 * Assets
 */
import "./styles.scss";
import makeToast from "../Snackbar";
import { useTranslation } from "react-i18next";

interface IProps {
  consultationDetails: any;
  closeModal: Function;
}

const Index: React.FC<IProps> = ({ consultationDetails, closeModal }) => {
  const { t } = useTranslation() as any;
  const { windowDimenion } = useScreenSize();
  const [pickedDate, setPickedDate] = useState() as any;
  const [loadingBtn, setLoadingBtn] = useState(false) as any;
  const handlePickDate = (pickedDate) => {
    setPickedDate(pickedDate);
  };
  const handleChangeDateMeeting = async () => {
    try {
      if (!pickedDate)
        return makeToast(
          "warning",
          t("DashboardPatient.SelectConsultationDate")
        );
      setLoadingBtn(true);
      const result = await patientUpdateConsultationDate({
        timeSlotId: pickedDate.timeslotId,
        consultationId: consultationDetails._id,
        dateConsultation: pickedDate.time,
        oldTimeSlotId: consultationDetails.timeSlotId,
      });
      if (result)
        makeToast("success", t("DashboardPatient.SuccessUpdateConsultation"));
      closeModal();
      setLoadingBtn(false);
    } catch (error) {
      setLoadingBtn(false);

      makeToast("error", t("DashboardPatient.ErrorTryLater"));
    }
  };

  return (
    <div className="change-consultation-date flexCenter flex-col ">
      <h3>{t("DashboardPatient.ChooseNewConsultation")}</h3>
      {pickedDate && !_.isEmpty(pickedDate) ? (
        <>
          <div className="my-5 ">
            <p className="md:flex items-center">
              {t("DashboardPatient.ChangedConsultationTo")}
              <span className="bg-gray-200 rounded-lg p-2 text-blue-500 font-bold text-xs ml-2">
                {moment(pickedDate.time).format("DD/MM/yyyy")}
              </span>
              <span className="mx-2"> {t("DashboardPatient.At")} </span>
              <span className="bg-gray-200 rounded-lg p-2 text-blue-500 font-bold text-xs">
                {moment(pickedDate.time).format("HH:mm")}
              </span>
              <Icon
                isIcon={false}
                name="tick"
                className="ml-3 hidden md:flex"
              />
            </p>
          </div>
        </>
      ) : (
        <DoctorTimeSlot
          handlePickDate={handlePickDate}
          doctorDetails={consultationDetails.doctorId}
          maxCol={windowDimenion.winWidth > 768 ? 5 : 3}
          cls="p-5"
        />
      )}
      <Button
        onClick={handleChangeDateMeeting}
        height="50px"
        width="170px"
        disabled={loadingBtn}
      >
        <div className="relative">
          {loadingBtn && (
            <div className="absolute h-full w-full flexCenter">
              <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-purple-500"></div>
            </div>
          )}
          {t("DashboardPatient.Confirm2")}
        </div>
      </Button>
    </div>
  );
};

export default Index;
