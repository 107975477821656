export const AddPrescription = {
  fr: {
    Title: "Ajouter une prescription",
    Template: "Template",
    defaultTemplate: "Template par defaut",
    consultation: "consultation",
    patientName: "Nom du patient",
    consultationDate: "Date de la consultation :",
    at: "à :",
    hours: "heures",
    consultationCarried: "La consultation a été réalisée en",
    teleconsultation: " téléconsultation ",
    presentiel: " présentiel ",
    Treatment: "Traitement",
    fillAll: "Veuillez remplir tous les champs requis",
    TreatmentName: "Nom du traitement",
    Dosage: "Posologie",
    AddTreatment: "Ajouter un traitement",
    ShareWithDMP: "Partager ce document au dmp",
    Confirm: "Valider",
    NoConsultationsFound: "Aucune consultation trouvée.",
  },
  en: {
    Title: "Add prescription",
    Template: "Template",
    defaultTemplate: "Default template",
    consultation: "consultation",
    patientName: "Patient name",
    consultationDate: "Date of consultation :",
    at: "at :",
    hours: "hours",
    consultationCarried: "The consultation was carried out in",
    teleconsultation: " teleconsultation ",
    presentiel: " face-to-face ",
    Treatment: "Treatment",
    fillAll: "Please complete all required fields",
    TreatmentName: "Treatment name",
    Dosage: "Dosage",
    AddTreatment: "Add treatment",
    ShareWithDMP: "Share this document to the dmp",
    Confirm: "Confirm",

    NoConsultationsFound: "No consultations found.",
  },
};
