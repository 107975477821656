import React from "react";
import classNames from "classnames";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  children: any;
  showModal: boolean;
  slidemodal?: boolean;
  closeModal?: any;
  className?: string;
  width?: string;
  disableScroll?: boolean;
};

const Modal: React.FC<Props> = ({
  children,
  showModal,
  slidemodal,
  closeModal,
  className,
  width,
  disableScroll,
}) => {
  return (
    <>
      {showModal ? (
        <>
          <div
            className={classNames(
              "fixed inset-0 z-50 outline-none focus:outline-none  bg-opacity-modal",
              !slidemodal && "flex justify-center items-center"
            )}
            onClick={closeModal}
          >
            <div
              className={classNames(
                "relative my-6 md:ml-auto ",
                slidemodal ? "slide-left h-screen px-2 pb-10" : "md:m-auto",
                width ? width : "md:w-auto md:max-w-2xl",
                "sm:w-4/5"
              )}
            >
              {/*content*/}
              <div
                className={classNames(
                  className,
                  ` border-0 mt-3 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-2 overflow-y-${
                    disableScroll ? "hidden" : "auto"
                  } overflow-x-hidden`,
                  slidemodal ? "h-full" : "max-h-screen-100"
                )}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {children}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Modal;
