/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import makeToast from "../../Snackbar";
import { AddItemToCanvas } from "../Helper/functions";
import ItemsArray from "../StaticData/ItemArray.json";
import { v4 as uuidv4 } from "uuid";

/**
 * List of
 * @param listData,
 * @function setListData,
 * @returns
 */
export const OutilsItems = ({ setListData, listData }) => {
  // function blobToBase64(blob) {
  //   return new Promise((resolve, _) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => resolve(reader.result);
  //     reader.readAsDataURL(blob);
  //   });
  // }
  const [numberImage, setNumberImage] = useState<number>(0);
  const [, setUrlCodeQR] = useState(false);
  const { t } = useTranslation() as any;
  return (
    <div className="MenuContainer w-1/4 bg-white py-4 px-4 rounded-lg">
      <div className="box-tools shadow-xl ">
        <h2> {t("TemplatePDF.Toolbox")} </h2>
        <ul className="ListItem ">
          {Object.entries(ItemsArray).map(([k, v]: any) => {
            if (k !== "redaction" && k !== "endRedaction") {
              return k === "image" ? (
                <li className="cursor-pointer">
                  <input
                    className="absolute opacity-0 fileInput"
                    type="file"
                    onChange={(e) => {
                      const file: any = e.target.files;
                      const img = file[0];
                      if (!img) return;
                      if (numberImage >= 2) {
                        return makeToast(
                          "warning",
                          t("TemplatePDF.LimitUpload")
                        );
                      }
                      if (img?.size > 50 * 1024) {
                        return makeToast("warning", t("TemplatePDF.LimitSize"));
                      } else {
                        // Make new FileReader
                        let reader = new FileReader();
                        // Convert the file to base64 text
                        reader.readAsDataURL(img);
                        // on reader load somthing...
                        let fileInfo: any = {};
                        reader.onload = () => {
                          // Make a fileInfo Object
                          fileInfo = {
                            name: img.name,
                            type: img.type,
                            size: Math.round(img.size / 1000) + " kB",
                            base64: reader.result,
                            file: img,
                          };
                          setListData([
                            ...listData,
                            {
                              ...ItemsArray["image"],
                              b64: fileInfo.base64,
                              infos: fileInfo,
                              id: uuidv4(),
                            },
                          ]);
                          setNumberImage(numberImage + 1);
                        };
                      }
                    }}
                  />
                  <p className=" font-normal cursor-pointer">
                    <i className={`mr-4 fas ${v.iconName}`} />
                    {v.name}
                  </p>
                </li>
              ) : (
                <li
                  onClick={() => {
                    switch (k) {
                      case "text":
                        AddItemToCanvas(v, listData, setListData, setUrlCodeQR);
                        break;
                      case "QrCode":
                        AddItemToCanvas(v, listData, setListData, setUrlCodeQR);
                        break;
                      case "BarCode":
                        AddItemToCanvas(v, listData, setListData, setUrlCodeQR);
                        break;
                      default:
                        AddItemToCanvas(v, listData, setListData, setUrlCodeQR);
                        break;
                    }
                  }}
                >
                  <p className=" font-normal">
                    <i className={`mr-4 fas ${v.iconName}`} />
                    {v.name}
                  </p>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
};
