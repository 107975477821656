/* eslint-disable eqeqeq */
import React from "react";
import { useAtom } from "jotai";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "../../../helper/moment";
import { userAtom } from "../../../state/auth";
import { previousState } from "../../../state/previousPageState";
import Icon from "../../../components/Icon";
import pen from "../../../assets/images/pen.png";

const ConsultationDocment = ({ consultationID, closeModal }) => {
  const { t } = useTranslation() as any;
  const [user] = useAtom(userAtom) as any;
  const [, setPreviousStates] = useAtom(previousState) as any;
  const history = useHistory();

  const consultation = user.consultations.find(
    (el) => el._id == consultationID
  );

  const CardPrescription = ({ prescriptions, index }) => {
    return (
      <div
        className="my-1 cursor-pointer mx-3 relative px-5 py-3 shadow-lg rounded-xl max-w-max"
        onClick={() => {
          setPreviousStates({
            FolderHistory: {
              selectedConsultation: consultation?._id,
              openTab: 2,
            },
          });
          history.push(`/preview/${consultation?._id}/${prescriptions?._id}`);
        }}
      >
        <div className="flex justify-between items-center header">
          <div className="flex sub-header">
            <div className="flex items-center gap-2">
              <Icon name={"capsule"} isIcon={false} height={20} width={20} />
              <p>
                {" "}
                {t("MyFolder.ReportAt")}
                <br />
                <span className="text-primary-blue">
                  {moment(consultation?.dateConsultation).format("LL")}
                </span>
              </p>
            </div>
          </div>
          <div>{/* <Icon name="troisPoints" isIcon={true} /> */}</div>
        </div>
        <p className="card-subtitle">
          {" "}
          {t("MyFolder.UpdatedAt")}{" "}
          {moment(prescriptions?.updatedAt).format(t("Moment.Z"))}
        </p>
        <div className="tache-box max-w-max">{consultation?.motif}</div>
        {consultation?.prescriptions?.length > 1 && (
          <span className="absolute right-2 bottom-2 text-xs text-primary-grey">
            {t("MyFolder.Prescription")} {index + 1}
          </span>
        )}
      </div>
    );
  };

  return (
    <div className="p-5 w-full h-full">
      <div className="flex justify-end">
        <Icon
          isIcon={true}
          name="close"
          onClick={closeModal}
          className="cursor-pointer p-1 rounded-xl bg-gray-100 bg-opacity-50"
        />
      </div>
      {consultation?.rapport && (
        <>
          <h3> {t("MyFolder.Report")}</h3>
          <div
            className="my-1 cursor-pointer mx-3  px-5 py-3 shadow-lg rounded-xl max-w-max"
            onClick={() => {
              setPreviousStates({
                FolderHistory: {
                  selectedConsultation: consultation?._id,
                  openTab: 2,
                },
              });
              history.push(`/previewCompteRendu/${consultation?._id}`);
            }}
          >
            <div className="flex justify-between items-center header">
              <div className="flex sub-header items-center gap-2">
                <img
                  crossOrigin="anonymous"
                  referrerPolicy="origin"
                  src={pen}
                  alt="pen"
                  width="20px"
                  height="20px"
                />

                <p>
                  {" "}
                  {t("MyFolder.ReportAt")}
                  <br />
                  <span className="text-primary-blue">
                    {moment(consultation?.dateConsultation).format("LL")}
                  </span>
                </p>
              </div>
              <div>{/* <Icon name="troisPoints" isIcon={true} /> */}</div>
            </div>
            <p className="card-subtitle">
              {t("MyFolder.UpdatedAt")}{" "}
              {moment(consultation?.updatedAt).format(t("Moment.Z"))}
            </p>
            <div className="bg-gray px-5 rounded-xl py-1 -100 max-w-max">
              {consultation?.motif}
            </div>
          </div>
        </>
      )}

      {consultation?.prescriptions?.length > 0 && (
        <h3> {t("MyFolder.Prescription")}</h3>
      )}
      <div className="flex flex-wrap gap-3">
        {consultation?.prescriptions?.map((el, i) => (
          <CardPrescription prescriptions={el} index={i} />
        ))}
      </div>

      {!consultation?.rapport && _.isEmpty(consultation?.prescriptions) && (
        <div className="flexCenter h-full">
          {" "}
          <h3> {t("MyFolder.NoDoc")}</h3>
        </div>
      )}
    </div>
  );
};

export default ConsultationDocment;
