/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import moment from "../../helper/moment";
import { useAtom } from "jotai";
import _ from "lodash";
/** API & HELPER */
import { serachDoctorByPatient } from "../../api/patient";
import { userAtom } from "../../state/auth";
/** COMPONENETS */
import Icon from "../../components/Icon";
import Button from "../../components/ButtonUI";
import Spinner from "../../components/Spinner";
/** ASSETS */
import doctorMotif from "../../assets/images/doctor-motif.png";
import documents from "../../assets/images/documents.png";
import doctorMotifGrey from "../../assets/images/doctor-motif-grey.png";

import "./styles.scss";
import { useTranslation } from "react-i18next";
import { handleSpeciality } from "../../helper/handleSpeciality";

export const SearchPatient: React.FC = () => {
  const { t, i18n } = useTranslation() as any;
  const location = useLocation();
  const history = useHistory();
  const [openTab, setOpenTab] = useState(1);
  const [user] = useAtom(userAtom);
  const [consultationsDone, setConsultationsDone] = useState([]);
  const [consultations, setConsultations] = useState([]);
  const [loading, setLoading] = useState(true);

  const tabs = [
    {
      id: 1,
      name: t("SideBarMenu.Documents"),
    },
    {
      id: 2,
      name: t("SideBarMenu.Report"),
    },
    {
      id: 3,
      name: t("SideBarMenu.Doctor"),
    },
  ];

  useEffect(() => {
    async function search() {
      const response = await serachDoctorByPatient(user?._id, location.state);
      let newConsultationDone = [] as any;
      let newConsultation = [] as any;
      if (!_.isEmpty(response?.consultations)) {
        response.consultations.map((e) => {
          if (
            moment(e.dateConsultation).isAfter(moment()) &&
            e.status !== "done"
          ) {
            newConsultation.push(e);
          } else {
            newConsultationDone.push(e);
          }
        });
      }
      setConsultationsDone(newConsultationDone);
      setConsultations(newConsultation);
      setLoading(false);
    }
    search();
  }, [location.state]);

  return loading ? (
    <Spinner />
  ) : (
    <div className="search-container">
      <div className="header flex">
        <div className="flexCenter">
          <Icon name="direction-gauche" isIcon={true} />
        </div>
        <div className="flex header-msg">
          <h1>
            {t("SideBarMenu.ResultFor")} <span>"{location.state}"</span>
          </h1>
        </div>
      </div>
      <div className="flexCenter tabs-Container">
        <div className="patient-tabs ">
          <div className="tabs flex items-center justify-between">
            {tabs.map((tab) => {
              return (
                <div
                  className={
                    openTab === tab.id
                      ? "atab cursor-pointer selected flexCenter"
                      : "atab cursor-pointer flexCenter"
                  }
                  onClick={() => setOpenTab(tab.id)}
                >
                  {tab.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="doc">
        <div className="tab-content tab-space">
          <div className={openTab === 1 ? "block" : "hidden"}>
            <p className="title">
              {consultationsDone.length} {t("SideBarMenu.Documents")}{" "}
            </p>
            <div className="flex list flex-wrap gap-4">
              {consultationsDone.length > 0 ? (
                consultationsDone.map((doc: any) => {
                  return (
                    <div className="docContainer">
                      <div className="titledoc max-w-max">
                        <p>{"Résultat d’examen"}</p>
                      </div>
                      <div className="resultat">
                        <p> {t("SideBarMenu.ResultsReportOf")} </p>
                        <span>{moment(doc.dateConsultation).format("ll")}</span>
                      </div>
                      <p className="maj">
                        {t("SideBarMenu.UpdatedOn")}{" "}
                        {moment(doc?.updatedAt).format(t("Moment.Z"))}{" "}
                        <strong>
                          {doc?.doctorId?.firstName} {doc?.doctorId?.lastName}
                        </strong>
                      </p>
                      <div className="specialite max-w-max">
                        <p>
                          {handleSpeciality(
                            doc.doctorId.speciality,
                            i18n.language.includes("en")
                          )}
                        </p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="medecin flexCenter flex-col w-full">
                  <p className="menu">{t("SideBarMenu.NoResults")}</p>
                </div>
              )}
            </div>
          </div>
          <div className={openTab === 2 ? "block" : "hidden"}>
            <div className="nextRdvList">
              <p className="title">
                {consultations.length} {t("SideBarMenu.upcomingAppointment")}
              </p>
              <div className="flex flex-col ">
                {consultations.length ? (
                  consultations.map((e: any) => {
                    return (
                      <div className="rdv">
                        <div className="header-rdv flex justify-between w-full ">
                          <div className="flexCenter">
                            <div>
                              <p className="font-bold	">
                                {e.doctorId.firstName} {e.doctorId.lastName}
                              </p>
                            </div>
                            <div className="connected rounded-full ml-6"></div>
                          </div>
                          <div className="courant-text text-white">
                            {moment(e.dateConsultation).format("LL")}
                          </div>
                        </div>
                        <div className="flexCenter boxSpecialite">
                          <p>
                            {handleSpeciality(
                              e.doctorId.speciality,
                              i18n.language.includes("en")
                            )}
                          </p>
                        </div>
                        <div className="flex motif">
                          <img
                            crossOrigin="anonymous"
                            referrerPolicy="origin"
                            src={doctorMotif}
                            alt="doctor katomi"
                          />
                          <p className="card-subtitle flexCenter">
                            {" "}
                            {t("SideBarMenu.Pattern")}
                          </p>
                          <p className="text-white">&nbsp;&nbsp;{e.motif}</p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="medecin flexCenter flex-col w-full">
                    <p className="menu">
                      {" "}
                      {t("SideBarMenu.NoUpcomingAppointments")}
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="nextRdvList">
              <p className="title">
                {consultationsDone.length} {t("SideBarMenu.pastAppointments")}
              </p>
              <div className="flex flex-col">
                {consultationsDone.length > 0 ? (
                  consultationsDone.map((e: any) => {
                    return (
                      <div className="rdvDone rounded shadow bg-white bg-opacity-40">
                        <div className="header-rdv flex justify-between w-full">
                          <div className="flexCenter">
                            <div>
                              <p className="font-bold	">
                                {e.doctorId.firstName} {e.doctorId.lastName}
                              </p>
                            </div>
                            {/* <div className="connected rounded-full ml-6"></div> */}
                          </div>
                          <div className="courant-text">
                            {moment(e.dateConsultation).format("LL")}
                          </div>
                        </div>
                        <div className="flexCenter boxSpecialite ">
                          <p>
                            {handleSpeciality(
                              e.doctorId.speciality,
                              i18n.language.includes("en")
                            )}
                          </p>
                        </div>
                        <div className="flex motif justify-between">
                          <div className="flexCenter motif">
                            <img
                              crossOrigin="anonymous"
                              referrerPolicy="origin"
                              src={doctorMotifGrey}
                              alt="doctor katomi"
                            />
                            <p className="card-subtitle flexCenter">
                              {t("SideBarMenu.Pattern")}
                            </p>
                            <p className="text-black">&nbsp;&nbsp;{e.motif}</p>
                          </div>
                          <div>
                            <div
                              className="flexCenter nbDoc cursor-pointer"
                              onClick={() =>
                                history.push("/mon-dossier-patient")
                              }
                            >
                              <img
                                crossOrigin="anonymous"
                                referrerPolicy="origin"
                                src={documents}
                                alt="documents katomi"
                              />
                              <p className="menu">{e.nbDocs}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="medecin flexCenter flex-col w-full">
                    <p className="menu">
                      {" "}
                      {t("SideBarMenu.NopastAppointments")}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={openTab === 3 ? "block" : "hidden"}>
            <div className="medecin flexCenter flex-col">
              <p className="menu">
                {t("SideBarMenu.NoDoctorMatches")} “
                {location.state && location.state}”
              </p>
              <Button
                status="primary"
                onClick={() => {
                  history.push("/annuaire");
                }}
              >
                {t("SideBarMenu.FindADoctor")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchPatient;
