/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import moment from "../../helper/moment";
import { useParams } from "react-router-dom";
/*import {
  BlobProvider,
  pdf,
  PDFDownloadLink,
  PDFViewer,
} from "@react-pdf/renderer";*/
import { useAtom } from "jotai";
/** API & HELPER */
import { loadImage } from "../../api/documents";
import { User } from "../../types/user";
import { userAtom } from "../../state/auth";
import { getConsultationById } from "../../api/consultation";
import { useTranslation } from "react-i18next";
/** COMPONENTS */
import Tooltip from "../toolTip";
import history from "../../history";
import Button from "../ButtonUI";
import Icon from "../Icon";
import SharedChat from "../../components/SharedChat";
import TransmettreDocumentPDF from "./TransmettreDocument";
/** ASSETS */
import "./styles.scss";
import { printCharacters } from "../../components/TemplatePDFCreator/Helper/functions";
import htmlToDraft from "html-to-draftjs";
import { EditorState, ContentState } from "draft-js";
import ReactHtmlParser from "react-html-parser";
import DataToJSPDFComponent from "../TemplatePDFCreator/pdfToJsPdfButtons";
import DEFAULT_TEMPALTE from "./defaultTemplate.json";

const Index: React.FC<any> = (): any => {
  const { t } = useTranslation();
  const [openChatModal, setOpenCHatModal] = useState<boolean>(false);
  const [chatWithId, setChatWithId] = useState<string>("");

  const [, setModalInfo] = useState(true);
  const [task, setTask] = useState(null) as any;
  const { consultationId } = useParams() as any;
  const [user] = useAtom<User | null>(userAtom);
  const [, setDoctorSignature] = useState("");
  const [modalTransmettreDoc, setModalTransmettreDoc] = useState(false);
  const [sharedY] = useState();
  const [linkPdf, setLinkPdf] = useState();
  const [defaultTemplate] = useState({ ...DEFAULT_TEMPALTE }) as any;

  const [editorState, setEditorState] = useState<any>();

  const parseImage = (url) => ({
    original: loadImage(
      user?.role === "DOCTOR" ? user?._id : task?.doctorId?._id,
      "signature",
      "thumbnail",
      url
    ),
  });

  useEffect(() => {
    async function getTaskById() {
      const response = await getConsultationById(consultationId);
      if (response.consultation) {
        setTask(response.consultation);
      }
    }
    getTaskById();
  }, []);

  useEffect(() => {
    if (task) {
      if (user?.role === "DOCTOR") {
        setDoctorSignature(parseImage(user?.signaturePath).original);
      } else {
        setDoctorSignature(parseImage(task?.doctorId?.signaturePath).original);
      }
    }
  }, [task]);

  useEffect(() => {
    if (task?.rapport?.textRapport) {
      const contentBlock = htmlToDraft(
        ReactHtmlParser(String(task.rapport.textRapport))[0]
      );

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        setEditorState(EditorState.createWithContent(contentState));
      }
    }
  }, [task]);

  useEffect(() => {
    if (linkPdf) {
      let imageOfPdf = new Image();
      imageOfPdf.src = linkPdf;
    }
  }, [linkPdf]);

  return (
    task && (
      <>
        <div className="preview-Page">
          <div className="flex justify-between mb-10">
            <div className="header flex">
              <div className="flexCenter">
                <Icon
                  name="direction-gauche"
                  isIcon={true}
                  transform="scale(0.6)"
                  color="#A6A9C8"
                  className="cursor-pointer"
                  onClick={history.goBack}
                />
              </div>
              <div className="flexCenter header-msg">
                <h3>
                  {t("DashboardDoctor.Report")}-{" "}
                  {task ? moment(task.dateConsultation).format("LL") : ""} -
                  {task?.doctorId &&
                    ` Dr ${task?.doctorId?.firstName} ${task?.doctorId?.lastName}`}{" "}
                  -
                  {task?.patientId &&
                    ` Patient  ${task?.patientId?.firstName} ${task?.patientId?.lastName}`}
                </h3>
              </div>
            </div>
            <div className="flex gap-2">
              {/*(task !== null && task?.rapport?.templatePdfId ) ? "": (
              <Tooltip tooltipText="Imprimer" position="left">
                <div
                          className="btn-secondary flexCenter"
                          style={{ width: "41px", height: "43px" }}
                        >
                          <Icon isIcon={true} name="imprimante" />
                        </div>
              </Tooltip>
            )*/}
              {task && editorState && (
                <>
                  <DataToJSPDFComponent
                    listData={
                      task?.rapport?.templatePdfId?.listData ||
                      defaultTemplate?.listData
                    }
                    editorState={editorState}
                    printCharacters={printCharacters}
                    sharedY={sharedY}
                    setShowModal={() => {}}
                    templateName={
                      task?.rapport?.templatePdfId?.templateName ||
                      defaultTemplate.templateName
                    }
                    modalType={
                      task?.rapport?.templatePdfId?.modalType ||
                      defaultTemplate.modalType
                    }
                    isPatient={user?.role !== "DOCTOR"}
                    setLinkPdf={setLinkPdf}
                    linkPdf={linkPdf}
                    isPrescreption={false}
                    task={task}
                  />
                </>
              )}
              {user?.role === "PATIENT" && (
                <Tooltip tooltipText="Partager" position="left">
                  <Button
                    status="secondary"
                    onClick={() => {
                      setModalTransmettreDoc(true);
                      setModalInfo(true);
                    }}
                  >
                    <Icon isIcon={true} name="export" />
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
          <div className="w-full flexCenter">
            {linkPdf && (
              <embed
                type="application/pdf"
                style={{
                  minHeight: 1200,
                  minWidth: "100%",
                }}
                src={`${linkPdf}#toolbar=0`}
              />
            )}
          </div>
        </div>
        {/* {modalInfo && task && (
          <InfoModal
            setModal={setModalInfo}
            openModal={modalInfo}
            data={task}
            openModalTransmettre={modalTransmettreDoc}
            setModalTransmettre={setModalTransmettreDoc}
          />
        )} */}
        {modalTransmettreDoc && (
          <TransmettreDocumentPDF
            openModal={modalTransmettreDoc}
            setModal={setModalTransmettreDoc}
            taskId={task?._id}
            doctorIdOfConsultation={task.doctorId._id}
            setChatWithId={(e) => {
              setModalTransmettreDoc(false);
              setChatWithId(e);
              setOpenCHatModal(true);
            }}
          />
        )}
        <SharedChat
          closeChatModal={setOpenCHatModal}
          showModal={openChatModal}
          chatWithId={chatWithId}
        />
      </>
    )
  );
};

export default Index;
