import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
/** API & HELPER */
import { userAtom } from "../../state/auth";
import { User } from "../../types/user";
import * as stripeConfig from "../../config/stripe";
import {
  addProfilePhotoToDoctor,
  loadImage,
  deleteProfilePicture,
} from "../../api/documents";
import { HiOutlineClipboard, HiOutlineClipboardCheck } from "react-icons/hi";
import { handleSpeciality } from "../../helper/handleSpeciality";
/** COMPONENTS */
import Cursus from "./Cursus";
import Tarifs from "./Tarifs";
import Location from "./Location";
import Description from "./Description";
import Sites from "./Sites";
import Modal from "../../components/ModalUI";
import ModalStepper from "../../components/Modal";
import Icon from "../../components/Icon";
import Button from "../../components/ButtonUI";
import makeToast from "../../components/Snackbar";
import AddLangue from "./modal/AddLangue";
import EditData from "./modal/EditData";
import Signature from "./signature";
//import ProCard from "./ProCard";
import ChangeSpeciality from "./modal/ModifySpeciality";
/** ASSETS */
import Pen from "../../assets/images/pen.png";
import langue from "../../assets/images/langue.png";
import UploadImg from "../../assets/images/upload.png";
import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";
import "./styles.scss";
import Tooltip from "../../components/toolTip";
import Stepper from "react-stepper-horizontal";
import AddressDoctorProfileStepper from "./modal/AddressStepper";
import ConfirmData from "./modal/ConfirmData";

const Profile: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [user, setUser] = useAtom<User | null>(userAtom) as any;
  const [openModalAddLangue, setOpenModalAddLangue] = useState(false);
  const [modalConfirmDeletePicture, setModalConfirmDeletePicture] =
    useState(false);
  const [, setModalStepper] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [openModalEditSpeciality, setOpenModalEditSpeciality] = useState(false);
  const [editData, setEditData] = useState(false);
  const [pictureURI, setPictureURI] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [steps, setSteps] = useState([
    { title: t("Profile.MyConsultations") },
    { title: t("Profile.StripeConfig") },
  ]);
  // const [showModal, setShowModal] = useState(false);

  const parseImage = (url, doctorId, type) => ({
    original: loadImage(doctorId, type, "lossless", url),
  });

  const _uploadPicture = async (fileProfile) => {
    try {
      const response = await addProfilePhotoToDoctor({
        doctorId: user?._id,
        file: fileProfile,
      });
      makeToast("success", t("Profile.successUpdatePicture"));
      setUser({
        ...user,
        business_photo: response.doctor.business_photo,
      });
      setPictureURI(
        parseImage(user.business_photo, user._id, "profile").original
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangePicture = (e) => {
    _uploadPicture(e?.target?.files[0]);
    setTimeout(() => {}, 2000);
    setRefresh(!refresh);
  };

  const handleDeleteProfilePicture = async () => {
    try {
      await deleteProfilePicture();
      setUser({ ...user, business_photo: null });
    } catch (error) {
      console.error(error);
    }
  };

  const urlToCopy = `${process.env.REACT_APP_DOC_PAGE_URLFR}DoctorInfo/${user?._id}`;
  const copyToClipboard = () => {
    navigator.clipboard.writeText(urlToCopy);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1500);
  };

  const STRIPE_CLIENT_ID = stripeConfig.STRIPE_CLIENT_ID;

  const prepaeStripeExpressURL = (user) => {
    const scope = "read_write";
    const responseType = "code";
    
    return (
      "https://connect.stripe.com/express/oauth/authorize?" +
      `&redirect_uri=${stripeConfig.STRIPE_REDIRECT_URI}` +
      `&client_id=${STRIPE_CLIENT_ID}` +
      `&state=${user._id}` +
      `&scope=${scope}` +
      `&response_type=${responseType}` +
      `&stripe_user[email]=${user.email}` +
      `&stripe_user[first_name]=${user.firstName}` +
      `&stripe_user[last_name]=${user.lastName}` +
      `&stripe_user[phone_number]=${user.phoneNumber}` +
      `&stripe_user[url]=${urlToCopy}` +
      `&suggested_capabilities[]=transfers`
    );
  };

  useEffect(() => {
    if (user.business_photo)
      setPictureURI(
        parseImage(user.business_photo, user._id, "profile").original
      );
  }, [user, refresh]);

  const [activeStep, setActiveStep] = useState(0);

  const handleSave = () => {
    setActiveStep(2);
  };

  useEffect(() => {
    if (activeStep === 0) {
      if (user?.psc?.id) {
        setActiveStep(0);
        setSteps([
          { title: "Mes données" },
          { title: t("Profile.MyConsultations") },
          { title: t("Profile.StripeConfig") },
        ]);
      } else if (_.isEmpty(user.workingLocation)) {
        setActiveStep(1);
      } else {
        setActiveStep(2);
      }
    }
  }, [user]);

  return (
    <>
      <Modal
        showModal={modalConfirmDeletePicture}
        closeModal={() => setModalConfirmDeletePicture(false)}
      >
        <>
          <div className="flex justify-end">
            <div className="bg-gray-200 rounded-xl bg-opacity-70 cursor-pointer">
              <Icon
                isIcon={true}
                name="close"
                onClick={() => setModalConfirmDeletePicture(false)}
              />
            </div>
          </div>
          <div className="p-5">
            <h3 className="mb-4">{t("Profile.ConfirmDeletePicture")}</h3>
            <div className="flexCenter space-x-3">
              <Button
                status="secondary"
                height="40px"
                width="120px"
                onClick={() => setModalConfirmDeletePicture(false)}
              >
                {t("Profile.Cancel")}
              </Button>
              <Button
                height="40px"
                width="120px"
                onClick={() => {
                  handleDeleteProfilePicture();
                  setModalConfirmDeletePicture(false);
                }}
              >
                {t("Profile.confirm")}
              </Button>
            </div>
          </div>
        </>
      </Modal>

      <Modal
        showModal={openModalEditSpeciality}
        closeModal={() => setOpenModalEditSpeciality(false)}
      >
        <ChangeSpeciality
          closeModal={() => setOpenModalEditSpeciality(false)}
        />
      </Modal>

      <Modal showModal={editData} closeModal={() => setEditData(false)}>
        <EditData closeModal={() => setEditData(false)} />
      </Modal>

      <div className="profile-doctor">
        {(_.isEmpty(user.stripeAccount) || _.isEmpty(user.workingLocation)) && (
          <ModalStepper
            showModal={true}
            closeModal={() => setModalStepper(false)}
          >
            <div className="widthFirstStepper">
              <Stepper steps={steps} activeStep={activeStep} />
              {activeStep === 0 && (
                /*user?.psc?.id &&*/ <ConfirmData
                  setActiveStep={setActiveStep}
                />
              )}
              {activeStep === 1 && _.isEmpty(user.workingLocation) && (
                <AddressDoctorProfileStepper
                  doctorId={user?._id}
                  onSave={handleSave}
                />
              )}
              {activeStep === 2 && _.isEmpty(user.stripeAccount) && (
                <div className="w-full text-gray-500 my-14 p-3">
                  <p className="text-center w-full text-base">
                    {t("Profile.StripeConfigMessage")}
                  </p>
                  <div className="flexCenter">
                    <a
                      href={prepaeStripeExpressURL(user)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p className="px-10 py-2 mt-4 rounded-lg text-white bg-primary-blue">
                        {t("Profile.Configure")}
                      </p>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </ModalStepper>
        )}

        {/* {_.isEmpty(user.stripeAccount) && (
          <div className="w-full  flex items-center alert-strip text-sm text-gray-500 my-2">
            <a
              href={prepaeStripeExpressURL(user)}
              target="_blank"
              rel="noreferrer"
            >
              <span className="warn warning"></span>
              {t("Profile.setPaymentAccount")}
            </a>
          </div>
        )}
        {_.isEmpty(user.workingLocation) && (
          <div className="w-full  flex items-center alert-strip text-sm text-gray-500 my-2">
            <span className="warn warning"></span>
            {t("Profile.ConfigParameter")}
          </div>
        )} */}

        <div className="flex justify-between info ">
          <div className="flex col-span-5">
            <div className="doctorContainer profile-picture mr-3 md:mr-6">
              <div className="photo-profile relative">
                {user?.business_photo ? (
                  <img
                    crossOrigin="anonymous"
                    referrerPolicy="origin"
                    src={pictureURI}
                    alt="doctor avatar"
                    className="imageDoctor rounded-lg w-full h-full object-cover"
                  />
                ) : user?.sexe === "woman" ? (
                  <WomanPatient className="imageDoctor rounded-lg w-full h-full" />
                ) : (
                  <ManPatient className="imageDoctor rounded-lg w-full h-full" />
                )}
                <div className="hide text-center hidden absolute inset-0 bg-opacity-40 bg-blue-500 rounded-lg">
                  <div className="flexCenter h-full">
                    <img
                      crossOrigin="anonymous"
                      referrerPolicy="origin"
                      src={UploadImg}
                      alt="upload"
                      width="30"
                    />
                    <input
                      type="file"
                      name="profile"
                      className="absolute inset-0 opacity-0"
                      onChange={handleChangePicture}
                    />
                  </div>
                </div>
              </div>
              <div
                className="flexCenter mt-2"
                onClick={() => {
                  setModalConfirmDeletePicture(true);
                }}
              >
                {user.business_photo && (
                  <p className="cursor-pointer card-subtitle hover:text-red-500 z-10">
                    {t("Profile.Delete")}
                  </p>
                )}
              </div>
            </div>
            <div className=" flex flex-col w-full doctorNames justify-around">
              <p className="doctorTitle flex items-center mb-2">
                <span className="block mr-5">
                  {" "}
                  {t("Profile.Dr")} {user?.firstName}{" "}
                  {user?.lastName && user?.lastName.toUpperCase()}
                </span>

                <span
                  // onClick={() => {
                  //   setOpenModalEditSpeciality(true);
                  // }}
                  onClick={() => setEditData(true)}
                  className="cursor-pointer block"
                >
                  <img
                    crossOrigin="anonymous"
                    referrerPolicy="origin"
                    src={Pen}
                    alt="pen"
                    width="25"
                  />
                </span>
              </p>

              {/* <div className="flex items-center justify-around bg-gray-200 bg-opacity-50 rounded-xl p-2"> */}
              <div className="boxSpecialiteProfile flex items-center justify-around p-2 gap-2 ">
                <p>
                  {handleSpeciality(
                    user?.speciality,
                    i18n.language.includes("en")
                  ) || "NA"}
                </p>
                {/* <span
                  onClick={() => {
                    setOpenModalEditSpeciality(true);
                  }}
                  className="cursor-pointer"
                >
                  <img
                    crossOrigin="anonymous"
                    referrerPolicy="origin"
                    src={Pen}
                    alt="pen"
                    width="25"
                  /> 
                </span>*/}
              </div>
              <div className="boxSpecialiteProfile mt-2 mb-2 flex items-center justify-around p-2 gap-2 ">
                <p>ID Facturation : {user?.idFacturationCps || "NA"}</p>
              </div>
              <div className="flex felx-row items-center gap-x-2 ">
                <Button
                  width="250px"
                  height="50px"
                  status="secondary"
                  onClick={() => window.open(urlToCopy, "_blank")}
                >
                  {t("Profile.landingPage")}
                </Button>
                {copySuccess ? (
                  <Tooltip
                    clsText={{ minWidth: "120px", textAlign: "center" }}
                    tooltipText={t("Profile.copied")}
                    position={"top"}
                  >
                    <HiOutlineClipboardCheck
                      className="text-green-500"
                      size={26}
                      onClick={copyToClipboard}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    tooltipText={t("Profile.copyLink")}
                    clsText={{ minWidth: "120px", textAlign: "center" }}
                    position={"top"}
                  >
                    <HiOutlineClipboard size={26} onClick={copyToClipboard} />
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center gap-5">
            {/* <Icon
              name="reglage-haut_droite"
              isIcon={true}
              className="float-right cursor-pointer"
              onClick={() => setEditData(true)}
            /> */}
            <div className="switch-local">
              <input
                id="language-toggle"
                className="check-toggle check-toggle-round-flat"
                type="checkbox"
                checked={!i18n.language.includes("en")}
                onChange={() =>
                  i18n.language.includes("en")
                    ? i18n.changeLanguage("fr")
                    : i18n.changeLanguage("en")
                }
              />
              <label htmlFor="language-toggle"></label>
              <span className="on">En</span>
              <span className="off">Fr</span>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 mt-8  p-2">
          <Description />
          <Location userProps={user} />
        </div>

        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 p-2 blocCursusTarif ">
          <div>
            <Cursus userProps={user} />
            <div className="langues mt-5">
              <div className="headerContainer flex items-center relative">
                <img
                  crossOrigin="anonymous"
                  referrerPolicy="origin"
                  src={langue}
                  className="svgClass"
                  alt="chat"
                />
                <p className="header"> {t("Profile.SpokenLanguages")}</p>
                <p
                  className="absolute right-0	modifier"
                  onClick={() => setOpenModalAddLangue(true)}
                >
                  {t("Profile.Update")}
                </p>
              </div>
              <div className="paiements flex">
                {user?.langues &&
                  user?.langues.map((langue) => (
                    <div className="boxMethod flexRowCenter">
                      <p>{langue}</p>
                    </div>
                  ))}
              </div>
            </div>

            <Sites />
          </div>
          <div>
            <Tarifs />
            <Signature />
            {/* <ProCard /> */}
          </div>
        </div>

        {openModalAddLangue && (
          <AddLangue
            setOpenModal={setOpenModalAddLangue}
            openModal={openModalAddLangue}
            doctorId={user?._id}
            languesDoctor={user?.langues}
          />
        )}
      </div>
    </>
  );
};

export default Profile;
