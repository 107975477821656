import { useEffect, useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import makeToast from "../../../components/Snackbar";
import Input from "../../../components/FloatInput";
import SelectComp from "../../../components/Select";
import Tooltip from "../../../components/toolTip";
import Spinner from "../../../components/Spinner";
import ListSpecilaities from "../../../config/constants/specialties.json";
import Button from "../../../components/ButtonUI";
import {
  updateDoctorDataWithPRS,
  getdoctorUserInfoPSC,
} from "../../../api/doctor";
import { useAtom } from "jotai";
import { userAtom } from "../../../state/auth";

type Props = {
  setActiveStep: any;
};

const dropdownSexeOptions = [
  { value: "men", label: "Homme" },
  { value: "woman", label: "Femme" },
];

const ConfirmData = (props: Props) => {
  const { setActiveStep } = props;
  const [user, setUser] = useAtom(userAtom) as any;
  const [userInfoPsc, setUserInfoPsc] = useState() as any;
  const [loading, setLoading] = useState(true) as any;

  useEffect(() => {
    getdoctorUserInfoPSC()
      .then((response) => {
        console.log("get doctor info psc response : ", response);
        if (response?.userInfo) setUserInfoPsc(response?.userInfo);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const { t, i18n } = useTranslation() as any;

  return loading ? (
    <Spinner />
  ) : (
    <Formik
      initialValues={{
        fname: user.firstName || userInfoPsc?.given_name || "",
        lname: user.lastName || userInfoPsc?.family_name || "",
        email: user.email || "",
        address: user.address || "",
        postal_code: "",
        country: "France",
        city: "",
        State: "",
        phone: user.phoneNumber || "",
        speciality: user.speciality || "",
        numCom: user.numCom || "",
        adeli: user.adeli || "",
        sexe:
          user.sexe || userInfoPsc?.family_name
            ? userInfoPsc?.family_name === "M"
              ? "men"
              : "women"
            : "men",
      }}
      onSubmit={async (values) => {
        await new Promise((resolve) => setTimeout(resolve, 500));

        const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!values.fname) return makeToast("warning", t("SignUp.fillName"));
        if (!values.lname)
          return makeToast("warning", t("SignUp.fillLastName"));
        if (!values.email) return makeToast("warning", t("SignUp.fillEmail"));

        if (!values.numCom)
          return makeToast("warning", t("SignUp.fillNmInscr"));
        if (!values.adeli) return makeToast("warning", t("SignUp.fillNmInscr"));
        if (!values.address)
          return makeToast("warning", t("SignUp.filladdress"));
        // if (!values.city)
        //   return makeToast("warning", t("SignUp.fillCity"));
        if (!values.postal_code)
          return makeToast("warning", t("SignUp.fillPostalCode"));
        if (!values.phone) return makeToast("warning", t("SignUp.fillPhone"));
        if (!values.country) return makeToast("warning", t("SignUp.fillPays"));

        if (values.numCom.length !== 11)
          return makeToast("warning", "Le N° RPPS est composé de 11 chiffres");
        if (values.adeli.length !== 9)
          return makeToast(
            "warning",
            "Le N° ADELI est composé de 9 caractères"
          );
        if (!validEmailRegex.test(values.email))
          return makeToast("warning", t("SignUp.fillValidEmail"));

        try {
          const response = await updateDoctorDataWithPRS(values);

          if (response) {
            if (response.status === "existEmail")
              return makeToast("warning", t("SignUp.existdoctorwithemail"));
            if (response.status === "existPhone")
              return makeToast(
                "warning",

                t("SignUp.existdoctorwithPhoneNumber")
              );
            if (response.status === "success") {
              makeToast("success", t("SignUp.successUpdate"));
              setActiveStep(1);
            }
          } else {
            makeToast("warning", t("SignUp.existdoctorwithemail"));
            setUser(response.user);
          }
        } catch (err) {
          makeToast(
            "error",
            "Quelque chose s'est mal passé. Veuillez réessayer plus tard"
          );
          console.log(err);
        }
      }}
    >
      {(props) => {
        const {
          values,
          // errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <>
            <form onSubmit={handleSubmit} className="">
              <div className="flexCenter gap-10 flex-wrap mt-10 ">
                <Input
                  id="lname"
                  labelText={t("SignUp.lastName")}
                  placeholder={t("SignUp.lastName")}
                  type="text"
                  // error={errors.lname}
                  value={values.lname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // require
                  stylesDiv="w-5/12"
                  withoutMB
                />
                <Input
                  id="fname"
                  labelText={t("SignUp.name")}
                  placeholder={t("SignUp.name")}
                  type="text"
                  // error={errors.fname}
                  value={values.fname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // require
                  stylesDiv="w-5/12"
                  withoutMB
                />
              </div>

              <div className="flexCenter gap-10 flex-wrap mt-5 ">
                <Input
                  id="email"
                  labelText={t("SignUp.email")}
                  placeholder={t("SignUp.email")}
                  type="text"
                  // error={errors.email}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  stylesDiv="w-5/12"
                  withoutMB

                  // require
                />

                <SelectComp
                  id="sexe"
                  // error={errors.sexe}
                  value={values.sexe}
                  options={dropdownSexeOptions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  text={t("SignUp.sexe")}
                  setText={false}
                  className=" w-full"
                  divProps="w-5/12"
                  // required
                />
              </div>

              <div className="flexCenter gap-10 flex-wrap mt-5 ">
                <div className="relative w-5/12">
                  <Input
                    id="numCom"
                    labelText={t("SignUp.registerNumRPPS")}
                    placeholder={t("SignUp.registerNumRPPS")}
                    type="text"
                    // error={errors.numCom}
                    value={values.numCom}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    stylesDiv="w-full"
                    withoutMB

                    // require
                  />
                  <div className="absolute right-3 top-5">
                    <Tooltip
                      position="top"
                      tooltipText={t("SignUp.controleRPPS")}
                      clsText={{ width: "220px", bottom: "200%" }}
                    >
                      <span className=" text-white text-xs font-bold bg-blue-500 rounded-full  w-5 h-5 cursor-pointer flexCenter">
                        i
                      </span>
                    </Tooltip>
                  </div>
                </div>
                <div className="relative  w-5/12">
                  <Input
                    id="adeli"
                    labelText={t("SignUp.registerNumAdeli")}
                    placeholder={t("SignUp.registerNumAdeli")}
                    type="text"
                    // error={errors.adeli}
                    value={values.adeli}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    stylesDiv="w-full"
                    withoutMB

                    // require
                  />
                  <div className="absolute right-3 top-5">
                    <Tooltip
                      position="top"
                      tooltipText={t("SignUp.controleAdeli")}
                      clsText={{ width: "220px", bottom: "200%" }}
                    >
                      <span className=" text-white text-xs font-bold bg-blue-500 rounded-full  w-5 h-5 cursor-pointer flexCenter">
                        i
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>

              <div className="flexCenter gap-10 flex-wrap mt-5">
                <SelectComp
                  id="speciality"
                  // error={errors.speciality}
                  value={values.speciality}
                  options={ListSpecilaities.map((el) =>
                    i18n.language.includes("en")
                      ? { ...el, label: el.valueEN }
                      : el
                  )}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  text={t("SignUp.speciality")}
                  className=" w-full"
                  divProps="w-5/12"
                  // required
                />
                <SelectComp
                  id="country"
                  // error={errors.country}
                  value={values.country}
                  options={[
                    {
                      value: "France",
                      label: "France",
                    },
                  ]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  text={t("SignUp.country")}
                  setText={false}
                  className=" w-full"
                  divProps="w-5/12"
                  // required
                />
              </div>

              <div className="flexCenter gap-10 flex-wrap mt-5">
                <Input
                  id="address"
                  labelText={t("SignUp.address")}
                  placeholder={t("SignUp.address")}
                  type="text"
                  // style={{ width: "100%" }}

                  // error={errors.address}
                  style={{ width: "100%" }}
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // require
                  stylesDiv="w-10/12"
                  withoutMB
                />
              </div>

              <div className="flexCenter gap-10 flex-wrap mt-5">
                <Input
                  id="postal_code"
                  labelText={t("SignUp.postalCode")}
                  placeholder={t("SignUp.postalCode")}
                  type="text"
                  // error={errors.postal_code}
                  value={values.postal_code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  stylesDiv="w-5/12"
                  withoutMB
                  // require
                />
                <Input
                  id="phone"
                  labelText={t("SignUp.numberPhone")}
                  placeholder={t("SignUp.numberPhone")}
                  type="text"
                  // error={errors.phone}
                  className=""
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  stylesDiv="w-5/12"
                  withoutMB
                  // require
                />
              </div>

              <div className="flexRowCenter mt-5">
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  status="primary"
                  className="btn mb-6"
                  onClick={() => handleSubmit()}
                  width="150px"
                  height="40px"
                >
                  {t("SignUp.confirm")}
                </Button>
              </div>
            </form>
          </>
        );
      }}
    </Formik>
  );
};

export default ConfirmData;
