import React from "react";
import Button from "../ButtonUI";
import moment from "../../helper/moment";

import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";
import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as IconCloseModal } from "../../assets/icons/icon-close.svg";

import birthday from "../../assets/images/birthday.png";
import phone from "../../assets/images/phone.png";
import mail from "../../assets/images/mail.png";
import home from "../../assets/images/home.png";
import { loadImage } from "../../api/documents";
import { useTranslation } from "react-i18next";
import { handleSpeciality } from "../../helper/handleSpeciality";

const HeaderChat = ({ chatWithData, closeChatModal }) => {
  const { t, i18n } = useTranslation() as any;
  const [userData, setUserData] = React.useState() as any;
  React.useEffect(() => {
    if (chatWithData)
      chatWithData.role === "DOCTOR"
        ? setUserData({
            fullName: `${chatWithData?.firstName} ${chatWithData?.lastName}`,
            birthdate: "",
            phone: chatWithData?.phoneNumber,
            email: chatWithData?.email,
            address: chatWithData?.address,
            role: "DOCTOR",
            speciality: chatWithData?.speciality,
            imageSrc: chatWithData?.business_photo
              ? chatWithData?.business_photo.slice(0, 4) === "http"
                ? chatWithData?.business_photo
                : loadImage(
                    chatWithData?._id,
                    "profile",
                    "thumbnail",
                    chatWithData?.business_photo
                  )
              : "",
            sexe: chatWithData?.sexe,
            phoneSuffixe: chatWithData?.phoneSuffix,
          })
        : setUserData({
            fullName: `${chatWithData?.firstName} ${chatWithData?.lastName}`,
            birthDate: chatWithData?.birthDate,
            phone: chatWithData?.phone,
            email: chatWithData?.email,
            address: chatWithData?.address,
            role: "PATIENT",
            speciality: "",
            imageSrc: chatWithData?.photo
              ? chatWithData.photo.slice(0, 4) === "http"
                ? chatWithData.photo
                : loadImage(
                    chatWithData?._id,
                    "profile",
                    "thumbnail",
                    chatWithData?.photo
                  )
              : "",
            sexe: chatWithData?.sexe,
            numSecSocial: chatWithData?.numSecSocial,
            phoneSuffixe: chatWithData?.phoneSuffixe,
          });
  }, [chatWithData]);

  return !chatWithData ? (
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
  ) : userData?.role === "DOCTOR" ? (
    <>
      <div className="flex  header-container">
        <div className="flex justify-between w-full header-chat">
          <div className="flex items-center space-x-5">
            <div className="avatarMedecinChat">
              {userData?.imageSrc ? (
                <img
                  crossOrigin="anonymous"
                  referrerPolicy="origin"
                  src={userData?.imageSrc}
                  className="w-16 h-16 object-fill rounded-full shadow-md"
                  alt="profile"
                />
              ) : userData?.sexe === "woman" ? (
                <WomanPatient />
              ) : (
                <ManPatient />
              )}
            </div>
            <div className="card-title">
              <span className="card-title">{userData?.fullName}</span>
              <p className="card-subtitle ">
                {handleSpeciality(
                  userData?.speciality,
                  i18n.language.includes("en")
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="flex space-x-10 cros cursor-pointer ">
          <IconCloseModal
            onClick={(e) => {
              e.preventDefault();
              closeChatModal();
            }}
            className="bg-gray-200 opacity-60 rounded-xl"
          />
        </div>
      </div>
    </>
  ) : (
    // if chatWith is patient
    <div className="flex header-chat-patient justify-between">
      <div className="infos w-full mr-1">
        {/* <div className="flex justify-end md:hidden">
        <Button
          status="icon-secondary"
          onClick={closeChatModal}
          className="cursor-pointer "
        >
          <Close />
        </Button>
      </div> */}
        <div className="flex sub-infos ">
          <div
            className="photo hidden md:block"
            style={{
              backgroundColor: userData?.imageSrc ? "" : "lightcyan",
            }}
          >
            {/* <ManPatient /> */}
            {userData?.imageSrc ? (
              <img
                crossOrigin="anonymous"
                referrerPolicy="origin"
                src={userData?.imageSrc}
                className="w-full h-full object-fill rounded-lg shadow-md"
                alt="profile"
              />
            ) : userData?.sexe === "woman" ? (
              <WomanPatient />
            ) : (
              <ManPatient />
            )}
          </div>
          <div className="details w-full">
            <div className="flex justify-between">
              <p className="name capitalize">{userData?.fullName} </p>
              <div className="block md:hidden">
                <Button
                  status="icon-secondary"
                  onClick={closeChatModal}
                  className="cursor-pointer "
                >
                  <Close />
                </Button>
              </div>
            </div>
            {userData?.birthDate !== "Invalid Date" && (
              <div className="flex birth-chat-header items-center">
                <div className="sub-birth flexCenter img">
                  <img
                    crossOrigin="anonymous"
                    referrerPolicy="origin"
                    src={birthday}
                    alt="birthday"
                  />
                </div>

                <div className="dateBirth">
                  <p className="ml-1" style={{ fontSize: "12px" }}>
                    {moment(userData?.birthDate).format("L")} (
                    {moment(Date.now()).year() -
                      moment(userData?.birthDate).year()}{" "}
                    {t("MyPatient.year")})
                  </p>
                </div>
              </div>
            )}
            <div className="flex  flex-wrap phonemail gap-x-5">
              {userData?.phone && (
                <div className="flex">
                  <div className="flexCenter img">
                    <img
                      crossOrigin="anonymous"
                      referrerPolicy="origin"
                      src={phone}
                      alt="phone"
                      width="15.5px"
                      height="15.5px"
                    />
                  </div>
                  <div className="ml-1">
                    {userData?.phoneSuffixe} {userData?.phone}
                  </div>
                </div>
              )}
              {userData?.email && (
                <div className="flex">
                  <div className="flexCenter img">
                    <img
                      crossOrigin="anonymous"
                      referrerPolicy="origin"
                      src={mail}
                      alt="mail"
                      width="15.5px"
                      height="15.5px"
                    />
                  </div>
                  <div className="ml-1">{userData?.email}</div>
                </div>
              )}
            </div>
            {userData?.address && (
              <div className="flex">
                <div className="flex">
                  <div className="flexCenter img">
                    <img
                      crossOrigin="anonymous"
                      referrerPolicy="origin"
                      src={home}
                      alt="home"
                      width="14.64px"
                      height="14.64px"
                    />
                  </div>
                  <div className="ml-1">{userData?.address}</div>
                </div>
              </div>
            )}
            <div className="flex mb-2">
              <div className="flexCenter mt-1">
                {userData?.numSecSocial && (
                  <div className="flexCenter img">
                    {t("Chat.SocialSecurityNumber")}
                  </div>
                )}
                <div className="ml-2">{userData?.numSecSocial}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:block">
        <Button
          status="icon-secondary"
          onClick={closeChatModal}
          className="cursor-pointer"
        >
          <Close />
        </Button>
      </div>
    </div>
  );
};

export default HeaderChat;
