/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionItem,
  Box,
  AccordionIcon,
  AccordionPanel,
  AccordionButton,
} from "@chakra-ui/react";
import { CalendarIcon, InfoOutlineIcon, StarIcon } from "@chakra-ui/icons";

import _ from "./ToolbarDraggableItem";
import { FormItems, CustomItems } from "./formItems";

function buildItems(items: any, fieldProps: any[] = [], defaultItems: any[]) {
  let resultItems: any[] = [];
  if (!items) resultItems = defaultItems;

  resultItems = resultItems.map((field: any) => {
    let found = fieldProps.find(
      (overrideField) => field.key === overrideField.key
    );
    if (found) field.fieldProps = { ...field.fieldProps, ...found.fieldProps };
    return field;
  });

  return resultItems;
}

//TODO: Fields still need to implement

const Toolbar = ({ fields, fieldProps, ...props }) => {
  const { t } = useTranslation() as any;
  const [items, setItems]: any = useState([]);
  const [customItems, setCustomItems]: any = useState([]);

  useEffect(() => {
    setItems(buildItems(fields, fieldProps, FormItems(t)));
    setCustomItems(CustomItems(t));
  }, [fields, fieldProps]);

  const ToolbarItem: any = _;
  return (
    <div className=" rounded-lg ">
      <Accordion
        defaultIndex={0}
        className=" rounded-lg shadow-md border  "
        style={{
          height: "80vh",
          backgroundColor: "#f5f5f999",
          overflowY: "scroll",
        }}
        allowToggle
      >
        <h1
          className="p-4  rounded-lg"
          style={{
            fontWeight: "bold",
            fontSize: "28px",
            textAlign: "center",
            marginBottom: "0px",
            backgroundColor: "#f5f5f999",
          }}
        >
          {t("Survey.Toolbox")}
        </h1>
        <AccordionItem
          border={"none"}
          backgroundColor={"#f5f5f999"}
          className="  border-gray-300"
        >
          {({ isExpanded }) => (
            <>
              <AccordionButton rounded="lg" _hover={{}} _focus={{}}>
                <Box
                  className={
                    "text-md p-2 " +
                    `${
                      isExpanded
                        ? "font-bold rounded-2xl  text-blue-600"
                        : " font-bold"
                    }`
                  }
                  flex="1"
                  textAlign="left"
                  color={isExpanded ? "blue" : ""}
                >
                  <StarIcon mr={2} mb={1} />
                  {t("Survey.PredefinedFields")}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                {customItems.map((item) => (
                  <ToolbarItem data={item} key={item.key} />
                ))}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        <AccordionItem
          backgroundColor={"#f5f5f999"}
          className=" border-gray-300  "
        >
          {({ isExpanded }) => (
            <>
              <AccordionButton _hover={{}} rounded="lg" _focus={{}}>
                <Box
                  className={
                    "text-md p-2 " +
                    `${
                      isExpanded
                        ? "font-bold rounded-2xl  text-blue-600"
                        : " font-bold"
                    }`
                  }
                  flex="1"
                  textAlign="left"
                >
                  {" "}
                  <CalendarIcon mr={2} mb={1} />
                  {t("Survey.InputFields")}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                {items.map(
                  (item, index) =>
                    index >= 4 && <ToolbarItem data={item} key={item.key} />
                )}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
        <AccordionItem
          backgroundColor={"#f5f5f999"}
          className=" border-gray-300 rounded-b-lg  "
        >
          {({ isExpanded }) => (
            <>
              <AccordionButton _hover={{}} rounded="lg" _focus={{}}>
                <Box
                  className={
                    "text-md p-2 " +
                    `${
                      isExpanded
                        ? "font-bold rounded-2xl  text-blue-600"
                        : " font-bold"
                    }`
                  }
                  flex="1"
                  textAlign="left"
                >
                  <InfoOutlineIcon mr={2} mb={1} />{" "}
                  {t("Survey.InformationalFields")}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                {items.map(
                  (item, index) =>
                    index < 4 && <ToolbarItem data={item} key={item.key} />
                )}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default Toolbar;
