import { useState } from "react";
import { useTranslation } from "react-i18next";
import { loadImage } from "../../../api/documents";
/**
 * Component
 */
import DoctorTimeSlot from "../../../components/DoctorTimeSlots";
import Button from "../../../components/ButtonUI";
import Icon from "../../../components/Icon";
import makeToast from "../../../components/Snackbar";
/**
 * helper & API
 */
import { patientUpdateConsultationDate } from "../../../api/consultation";
/**
 * Assets
 */
import { ReactComponent as AgendaIcon } from "../../../assets/icons/icon-agenda.svg";
import { ReactComponent as WomanPatient } from "../../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../../assets/illustrations/illustration-homme-patient.svg";
import { handleSpeciality } from "../../../helper/handleSpeciality";

const MoveMeetModal = ({ closeModal, meetDetails, winWidth, refreshData }) => {
  const { t, i18n } = useTranslation() as any;
  const [changeMeetDetail, setChangeMettDetail] = useState() as any;
  const [loadingBtn, setLoadingBtn] = useState(false);
  const handleChangeMeet = async () => {
    try {
      if (!changeMeetDetail)
        return makeToast("warning", t("DoctorMoveMeeting.selectDate"));
      setLoadingBtn(true);
      const result = await patientUpdateConsultationDate({
        timeSlotId: changeMeetDetail?.timeslotId,
        consultationId: meetDetails._id,
        dateConsultation: changeMeetDetail.time,
        oldTimeSlotId: meetDetails.timeSlotId,
      });
      if (result) makeToast("success", t("DoctorMoveMeeting.SuccessUpdate"));
      refreshData();
      closeModal();
      setLoadingBtn(false);
    } catch (error) {
      setLoadingBtn(false);

      makeToast("error", t("DoctorMoveMeeting.SomethingWentWrong"));
    }
  };
  return (
    <>
      <div className="p-2 md:p-5">
        <div className="flex">
          <h2 className="m-auto">{t("DoctorMoveMeeting.YourMeeting")}</h2>
          <div className="float-right close-modal cursor-pointer">
            <Icon isIcon={true} name="close" onClick={closeModal} />
          </div>
        </div>
        <h3>{t("DoctorMoveMeeting.YourMeeting")} :</h3>
        <div className="flex items-center space-x-4 bg-gray-100 p-4 mb-2">
          <div className="h-16 w-16 rounded-lg">
            {meetDetails.doctorId?.business_photo ? (
              <img
                crossOrigin="anonymous"
                referrerPolicy="origin"
                alt="profile"
                src={
                  meetDetails.doctorId?.business_photo.slice(0, 4) === "http"
                    ? meetDetails.doctorId?.business_photo
                    : loadImage(
                        meetDetails.doctorId?._id,
                        "profile",
                        "thumbnail",
                        meetDetails.doctorId?.business_photo
                      )
                }
                className="w-16 h-16 rounded-lg shadow-md object-fill"
              />
            ) : meetDetails.doctorId?.sexe === "woman" ? (
              <WomanPatient className="h-16 w-16 bg-gray-300" />
            ) : (
              <ManPatient className="h-16 w-16 bg-gray-300" />
            )}
          </div>
          <div className="flex flex-col">
            <span className="card-title">
              {`${meetDetails.doctorId?.firstName} ${meetDetails.doctorId?.lastName}`}
            </span>
            <span className="card-subtitle">
              {handleSpeciality(
                meetDetails.doctorId?.speciality,
                i18n.language.includes("en")
              )}
            </span>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <Icon isIcon={true} name="Motif de la consultation" />
          <span className="card-subtitle">{t("DoctorMoveMeeting.Motif")} </span>
          <span>{meetDetails.motif}</span>
        </div>
        <div className="flex items-center ">
          <AgendaIcon className="agenda-icon" />
          <span className="card-subtitle">
            {t("DoctorMoveMeeting.DateAppointment")}{" "}
          </span>
        </div>
        {/* Book New Meet */}
        <DoctorTimeSlot
          withResult
          maxCol={winWidth > 768 ? 5 : 3}
          handlePickDate={(pickedDate, doctorDetails, eventType) => {
            setChangeMettDetail(pickedDate);
          }}
          doctorDetails={meetDetails.doctorId}
        />

        {/* End Book New Meet */}

        {
          meetDetails.type === "teleconsultation" && (
            <span className="card-subtitle">
              {t("DoctorMoveMeeting.ConsultationTeleconsultation")}
            </span>
          )
          //  : (
          //   <div className="flex items-center ">
          //     <Icon isIcon={true} name="event-cabinet" />
          //     <span className="card-subtitle">Lieu de votre rendez-vous :</span>
          //     <span className="max-w-xs ml-2">
          //       {meetDetails.doctorId?.address}
          //     </span>
          //   </div>
          // )
        }

        {/* 
        <div className="flex items-center">
          <Icon isIcon={true} name="event-cabinet" />
          <span className="card-subtitle">Lieu de votre rendez-vous : </span>          
          <span className="max-w-xs"> {meetDetails.doctorId?.address}</span>
        </div> */}

        <div className="flexCenter my-5">
          <Button
            width="170px"
            height="50px"
            onClick={handleChangeMeet}
            disabled={loadingBtn}
          >
            {t("DoctorMoveMeeting.Confirm")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default MoveMeetModal;
