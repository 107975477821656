/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import {
  isEmail,
  isInRangeNumber,
  isLength,
  isMaxLength,
  isMinLength,
  isNotEmptyString,
  isNumber,
  isPattern,
  isPercentage,
  isRequired,
} from "../../hooks/FormGeneratorHooks/validitions";

const isMobile = (breakPoints) => {
  return breakPoints.xs || (breakPoints.sm && !breakPoints.md);
};
const checkIfMultiple = (currentCondition: Partial<any>): boolean => {
  const multipleInput: string[] = [
    "Checkboxes",
    "RadioButtons",
    "Tags",
    "Dropdown",
  ];

  const type =
    currentCondition?.target?.type !== undefined
      ? currentCondition?.target?.type
      : "text_area";
  if (multipleInput.includes(type)) {
    return true;
  } else return false;
};
const validitionRules = (rulesTabs) => {
  try {
    let result: any[] = [];
    if (rulesTabs) {
      rulesTabs.map((rule: any) => {
        switch (Object.entries(rule)[1][0]) {
          case "required":
            if (rule.required == true)
              result.push({
                rule: isRequired(),
                message: Object.entries(rule)[0][1],
              });
            break;
          case "isPattern":
            result.push({
              rule: isPattern(rule.valuePat),
              message: Object.entries(rule)[0][1],
            });
            break;
          case "isLength":
            result.push({
              rule: isLength(rule.Strlength),
              message: Object.entries(rule)[0][1],
            });
            break;

          case "isMinLength":
            result.push({
              rule: isMinLength(rule.minLength),
              message: Object.entries(rule)[0][1],
            });
            break;

          case "isMaxLength":
            result.push({
              rule: isMaxLength(rule.maxLength),
              message: Object.entries(rule)[0][1],
            });
            break;

          case "isMinNumber":
            result.push({
              rule: isInRangeNumber(rule.inf, 999999999999999999999999),
              message: Object.entries(rule)[0][1],
            });
            break;

          case "isMaxNumber":
            result.push({
              rule: isInRangeNumber(0, rule.sup),
              message: Object.entries(rule)[0][1],
            });

            break;

          case "isNotEmptyString":
            result.push({
              rule: isNotEmptyString(),
              message: Object.entries(rule)[0][1],
            });
            break;

          case "isNumber":
            result.push({
              rule: isNumber(),
              message: Object.entries(rule)[0][1],
            });
            break;

          case "isEmail":
            result.push({
              rule: isEmail(),
              message: Object.entries(rule)[0][1],
            });
            break;

          case "isPercentage":
            result.push({
              rule: isPercentage(),
              message: Object.entries(rule)[0][1],
            });
            break;

          default:
            break;
        }
      });
    }
    return result;
  } catch (error) {
    console.error(error);
  }
};
function loadLocaleData(locale: string) {
  switch (locale) {
    case "fr":
      return import("../../config/constants/locales/fr-fr.json");
    default:
      return import("../../config/constants/locales/en-us.json");
  }
}

const formatingRules = (rule: any) => {
  switch (rule.rule) {
    case "isPattern":
      return {
        message: `Ce champ doit contenir le mot ${rule.parms.valuePat}`,
        isPattern: true,
        valuePat: rule.parms.valuePat,
      };
    case "isLength":
      return {
        message: `Ce champ doit contenir ${rule.parms.islength} caractéres`,
        isLength: true,
        Strlength: rule.parms.islength,
      };
    case "isMinLength":
      return {
        message: `Ce champ doit contenir au moins ${rule.parms.minLength} caractéres`,
        isMinLength: true,
        minLength: rule.parms.minLength,
      };
    case "isMaxLength":
      return {
        message: `Ce champ doit contenir au plus ${rule.parms.maxLength} caractéres`,
        isMaxLength: true,
        maxLength: rule.parms.maxLength,
      };
    case "isMinNumber":
      return {
        message: `Ce champ doit être supérieur à ${rule.parms.inf} `,
        isMinNumber: true,
        inf: rule.parms.inf,
      };
    case "isMaxNumber":
      return {
        message: `Ce champ doit être inférieur à ${rule.parms.sup} `,
        isMaxNumber: true,
        sup: rule.parms.sup,
      };
    case "isNotEmptyString":
      return { message: `Chaine vide ! `, isNotEmptyString: true };
    case "isNumber":
      return {
        message: `Ce champ doit contenir uniquement des chiffres `,
        isNumber: true,
      };
    case "isEmail":
      return {
        message: `Email invalide ! `,
        isEmail: true,
      };
    case "isPercentage":
      return {
        message: `Ce champ doit contenir que des pourcentages`,
        isPercentage: true,
      };
    default:
      break;
  }
};
const rules = (type) => {
  let textRules = [
    { value: "isPattern", label: "Doit contenir un mot précis" },
    { value: "isNumber", label: "Doit contenir que de chiffres" },
    {
      value: "isLength",
      label: "Doit contenir un nombre de caractères définis",
    },
    { value: "isMinLength", label: "Doit contenir un minimum de caractères" },
    { value: "isMaxLength", label: "Doit contenir un maximum de caractères" },
    { value: "isEmail", label: "Doit être sous la forme d'un email" },
  ];
  let numberRules = [
    { value: "isMinNumber", label: "Doit être supérieur à" },
    { value: "isMaxNumber", label: "Doit être inférieur à" },
    { value: "isPercentage", label: "Pourcentage" },
  ];

  switch (type) {
    case "TextInput":
    case "TextArea":
      return textRules;

    case "NumberInput":
      return numberRules;

    default:
      return textRules;
  }
};

export {
  isMobile,
  checkIfMultiple,
  validitionRules,
  loadLocaleData,
  formatingRules,
  rules,
};
