/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useAtom } from "jotai";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
/**
 * Helpers & API
 */
import { ActiveRouteAtom } from "../../state/route";
import { getMyDoctors, getSpecialityList } from "../../api/patient";
import { getContriesList } from "../../api/doctor";

/**
 * Helpers & API
 */
import Button from "../../components/ButtonUI";
import Select from "../../components/Select";
import Modal from "../../components/ModalUI";
import Pagination from "../../components/Pagination";
import CardDoctor from "./CardDoctor";

import CompteRendu from "./modal/CompteRendu";
import Transmettre from "./modal/Transmettre";
import HeaderModal from "./modal/HeaderModal";
/**
 * Assets
 */
import "./styles.scss";
import { handleSpeciality } from "../../helper/handleSpeciality";

const MyDoctors: React.FC = () => {
  const { t, i18n } = useTranslation() as any;
  const [selectedDoctor, setSelectedDoctor] = useState() as any;
  const [compteRenduModal, setCompteRenduModal] = useState(false);
  const [transmettre, setTransmettre] = useState(false);
  const [, setLoading] = useState(false);
  const [mydoctors, setMyDoctors] = useState<any>([]);
  const [, setPath] = useAtom(ActiveRouteAtom);

  // Pagintaion State
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit] = useState(10);
  const [totalResult, setTotalResult] = useState(0);
  const history = useHistory();

  //temp filter
  const [contriesList, setContriesList] = useState() as any;
  const [specialityList, setSpecialityList] = useState() as any;
  const [filterModal, setFilterModal] = useState(false);
  const [filterDoctor, setFilterDoctor] = useState<any>([]);

  const [tempFilterVille, setTempFilterVille] = useState<any>();
  const [tempFilterSexe, setTempFilterSexe] = useState<any>();
  const [tempFilterSpeciality, setTempFilterSpeciality] = useState<any>();

  const addNewFilter = () => {
    setTempFilterVille(null);
    setTempFilterSexe(null);
    setTempFilterSpeciality(null);
    setFilterModal(true);
  };

  const _getMyDoctors = async () => {
    try {
      setLoading(true);
      const response = await getMyDoctors(page, limit, filterDoctor);
      setMyDoctors(response.myDoctors.docs);
      setTotalResult(response?.myDoctors?.totalDocs);
      setTotalPage(response?.myDoctors?.totalPages);
      setPage(response?.myDoctors?.page);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error("Error Fetch specialist :", error);
    }
  };

  const _getContriesList = async () => {
    try {
      const response = await getContriesList();
      setContriesList(response);
    } catch (error) {
      console.error("Error Fetch specialist :", error);
    }
  };

  const _getSpecialityList = async () => {
    try {
      const response = await getSpecialityList();
      setSpecialityList(response);
    } catch (error) {
      console.error("Error Fetch specialist :", error);
    }
  };

  useEffect(() => {
    _getSpecialityList();
  }, []);

  useEffect(() => {
    _getContriesList();
  }, []);

  useEffect(() => {
    _getMyDoctors();
  }, [page, filterDoctor]);

  return (
    <>
      {/* Add modal Confirm take meeting */}
      {/* <Modal
        showModal={confirmModal}
        closeModal={() => {
          setConfirmModal(false);
        }}
      >
        <ConfirmModal
          closeModal={() => {
            setConfirmModal(false);
            setUpdateComponent(true);
          }}
          addedConsultation={addedConsultation}
        />
      </Modal> */}
      {/* Filter Modal */}
      <Modal showModal={filterModal} closeModal={() => setFilterModal(false)}>
        <div className="flex flex-col item-center space-y-3  p-10">
          <div className="flexCenter space-x-5">
            <Select
              // labelText="Select ville"
              className="w-60 px-3 cursor-pointer"
              labelProps={{ className: "w-40" }}
              text={t("MyDoctors.selectVille")}
              options={contriesList?.countries?.map((el) => ({
                label: el,
                value: el,
              }))}
              onChange={(e) => {
                if (e.target.value)
                  setTempFilterSexe({ type: "ville", value: e.target.value });
                else setTempFilterSexe(null);
              }}
            />
          </div>
          <div className="flexCenter space-x-5">
            <Select
              // labelText="Select specialité"
              className="w-60 px-3 cursor-pointer"
              labelProps={{ className: "w-40" }}
              text={t("MyDoctors.selectSpecialty")}
              options={specialityList?.speciality?.map((el) => ({
                label: handleSpeciality(el, i18n.language.includes("en")),
                value: el,
              }))}
              onChange={(e) => {
                if (e.target.value)
                  setTempFilterSpeciality({
                    type: "speciality",
                    value: e.target.value,
                  });
                else setTempFilterSpeciality(null);
              }}
            />
          </div>
          <Button
            status="secondary"
            height="50px"
            onClick={() => {
              setFilterModal(false);
              let arr: any = [];
              if (tempFilterVille) arr = arr.concat(tempFilterVille);
              if (tempFilterSexe) arr = arr.concat(tempFilterSexe);
              if (tempFilterSpeciality) arr = arr.concat(tempFilterSpeciality);
              setFilterDoctor(arr);
            }}
          >
            {t("MyDoctors.Find")}
          </Button>
        </div>
      </Modal>

      {/* Show modal */}
      <Modal
        showModal={compteRenduModal}
        slidemodal
        closeModal={() => {
          setCompteRenduModal(false);
        }}
      >
        <HeaderModal
          closeModal={() => setCompteRenduModal(false)}
          doctorDetails={selectedDoctor}
        />
        <CompteRendu
          closeModal={() => setCompteRenduModal(false)}
          selectedDoctor={selectedDoctor}
        />
      </Modal>

      {transmettre && (
        <Transmettre openModal={transmettre} setOpenModal={setTransmettre} />
      )}

      <div className="my-doctors">
        <header className="flex justify-between w-full flex-col md:flex-row gap-2">
          <div>
            <h1>{t("MyDoctors.title")}</h1>
            <h3 className="card-subtitle">
              {totalResult} {t("MyDoctors.dispDoc")}
            </h3>
          </div>
          <div className="flex max-w-max items-center space-x-2 ">
            <div className="relative">
              <Button
                width="200px"
                height="50px"
                status="secondary"
                onClick={() => {
                  setPath("/annuaire");
                  history.push("/annuaire");
                }}
              >
                {t("MyDoctors.NewDoc")}
              </Button>
            </div>
          </div>
        </header>
        <div className="flex justify-between items-center w-full flex-wrap gap-3">
          <div className="flex flex-wrap gap-3">
            {filterDoctor && !_.isEmpty(filterDoctor)
              ? filterDoctor.map((el) => (
                  <span className="box mx-2 h-9 flexCenter">
                    {el.value === "man"
                      ? "Homme"
                      : el.value === "woman"
                      ? "Femme"
                      : el.value}
                    <button
                      className="pointer ml-3 hover:text-red-500"
                      onClick={() =>
                        setFilterDoctor(
                          filterDoctor
                            .slice()
                            .filter((elm) => elm.type !== el.type)
                        )
                      }
                    >
                      X
                    </button>
                  </span>
                ))
              : null}

            <Button
              status="secondary"
              width="146px"
              height="35px"
              onClick={addNewFilter}
            >
              {t("MyDoctors.otherFilter")}
            </Button>
          </div>
          <div>
            {totalResult} {t("MyDoctors.resultsFound")}
          </div>
        </div>
        <div className="card-container mt-10 flex flex-wrap gap-10 ">
          {mydoctors &&
            !_.isEmpty(mydoctors) &&
            mydoctors.map((doctorDetails, cardIndex) => {
              return (
                <CardDoctor
                  doctorDetails={doctorDetails}
                  cardIndex={cardIndex}
                  selectedDoctor={(doctor) => {
                    setSelectedDoctor(doctor);
                    setCompteRenduModal(true);
                  }}
                />
              );
            })}
        </div>
      </div>
      {totalResult > limit && (
        <div className="mt-5">
          <Pagination
            page={page}
            setPage={(i) => setPage(i)}
            totalPage={totalPage}
            totalDocs={totalResult}
          />
        </div>
      )}
    </>
  );
};

export default MyDoctors;
