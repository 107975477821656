import axios from "axios";
import { GET_OLD_MESSAGES } from "../config/api";

export const getOldMessages = async (chatroomId) => {
  try {
    const response = await axios.get(GET_OLD_MESSAGES(chatroomId), {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
