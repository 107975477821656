import { Formik, FormikHelpers } from "formik";
import React,{useEffect,useState} from "react";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { userAtom } from '../../state/auth'
import { useAtom } from 'jotai';
import { addMotif,getAllByDoctor,deleteMotifDoctor } from "../../api/motif";
import Table from "../../components/Table";
import { IValues, IMotif} from './interfaces'


const MotifConsultation: React.FC = () => {
  const [user]=useAtom(userAtom);
  const [motifList, setMotifList] = useState<IMotif[] | []>([]);
    
  useEffect(() => {
    async function fetchpatients() {
        let response = await getAllByDoctor(user?._id);
        setMotifList(response.motifs)
      }
      fetchpatients();
  },[user])

  const deleteMotif= async (id)=>{
    await deleteMotifDoctor(id)
    setMotifList(motifList?.filter(e=>e._id !== id))
  }

  
  return (
    <div className="m-4">
      <Formik
        initialValues={{
          name: "",
          doctorName: user?.firstName+" "+user?.lastName,
        }}
        onSubmit={async(
          values: IValues,
          { setSubmitting }: FormikHelpers<IValues>
        ) => {
          const newMotif = await addMotif({motif:values.name,doctorId: user?._id});
          setMotifList([...motifList, newMotif.data])
        }}
      >
        {(props) => {
          const {
            values,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <form onSubmit={handleSubmit} className="flex-auto py-7 px-8">
              <div className="space-x-2 flex flex-wrap text-sm font-light text-black">
                <Input
                  id="doctorName"
                  labelText="Nom du docteur"
                  placeholder=""
                  type="text"
                  error={errors.doctorName}
                  value={values.doctorName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelProps={{ className: "w-full" }}
                  disabled
                />
              </div>
              <div className="space-x-2 flex flex-wrap text-sm font-light text-black">
                <Input
                  id="name"
                  labelText="Motif du consultation"
                  placeholder="Entrez votre motif"
                  type="text"
                  error={errors.name}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelProps={{ className: "w-full" }}
                />
              </div>
              <div className="flex space-x-3 mt-3 text-sm font-semibold uppercase">
                <div className="flex-auto flex space-x-3 flex-row-reverse justify-center">
                  <Button
                    type="submit"
                    disabled={isSubmitting || Object.keys(errors).length > 0}
                  >
                    Ajouter motif
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
      {
          motifList&&(
              <Table         
              headersTable={[
                "NOM DU MOTIF",
                "ACTIONS"
              ]}
              contentTable={motifList}
              deleteFromArray={deleteMotif}
              edit={true}
              editName={"Modifier"}
              supprimer={true}
              linkEdit={"/motif"}
              consultationHistorique={false}
            />
          )
      }
    </div>
  );
};

export default MotifConsultation;
