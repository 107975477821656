import { useAtom } from "jotai";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { addSigntureToDoctor } from "../../../api/documents";
import Button from "../../../components/ButtonUI";
import Icon from "../../../components/Icon";
import makeToast from "../../../components/Snackbar";
import { userAtom } from "../../../state/auth";
import { User } from "../../../types/user";

interface IProps {
  setModalModifySignature: Function;
}

const ModifySignature: React.FC<IProps> = ({ setModalModifySignature }) => {
  const { t } = useTranslation() as any;
  const [file, setFile] = useState<any>({});
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const [user, setUser] = useAtom(userAtom);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];

      setFile(
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }) as any
      );
    },
  });
  const onSubmit = async () => {
    const response = await addSigntureToDoctor({
      file,
      doctorId: user?.role === "DOCTOR" ? user?._id : null,
    });
    if (response.doctor) {
      makeToast("success", t("Profile.SuccessAddSignature"));
      setUser({
        ...user,
        signaturePath: response.doctor.signaturePath,
      } as User);
      setLoadingBtn(false);
      setModalModifySignature(false);
    }
  };
  const isButtonDisabled = !file.preview;

  return (
    <>
      <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter modalSites">
        <div className="absolute bg-white z-40 top-1/3 flexCente cursusAdd">
          <div className="flex justify-end cursor-pointer pt-5 pr-5">
            <Icon
              isIcon={true}
              name="close"
              onClick={() => setModalModifySignature(false)}
              className="bg-gray-100 rounded-xl cursor-pointer hover:bg-gray-200"
            />
          </div>
          <section className="container cursor-pointer">
            <div {...getRootProps({ className: "dropzone flexCenter" })}>
              <input {...getInputProps()} />
              <p>{t("Profile.uploadSignature")}</p>
            </div>
            <aside className="thumbsContainer gap-4 mt-4 ml-4 mb-4">
              {file.preview && (
                <img
                  crossOrigin="anonymous"
                  referrerPolicy="origin"
                  src={file.preview}
                  alt="signature"
                />
              )}
            </aside>
          </section>
          <div className="w-full mt-4">
            <Button
              status="secondary"
              width="100px"
              height="50px"
              className="float-left mb-4 ml-4 btn-delete"
              onClick={() => setModalModifySignature(false)}
            >
              {t("Profile.Cancel")}
            </Button>
            <Button
              status="primary"
              className="float-right flexCenter mb-4 mr-4"
              width="100px"
              height="50px"
              type="submit"
              disabled={loadingBtn || isButtonDisabled}
              onClick={() => {
                setLoadingBtn(true);
                onSubmit();
              }}
            >
              {loadingBtn && !file.path ? (
                <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-purple-500"></div>
              ) : (
                t("Profile.Save")
              )}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModifySignature;
