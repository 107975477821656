import { useEffect, useState } from "react";
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Stack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  SliderMark,
} from "@chakra-ui/react";
import { FormGroup } from "./FormGroup";
import { useField } from "../../../hooks/FormGeneratorHooks";

export const FieldSlider = (props) => {
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    otherProps,
  } = useField(props);
  const { required, name } = props;
  const {
    children,
    label,
    type,
    helper,
    min = 0,
    max = 100,
    step = 1,
    ...rest
  } = otherProps;
  const [isTouched, setIsTouched] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted);
  const labelStyles = {
    mt: "6",
    ml: "-5",
    fontSize: "sm",
  };
  const labelStyles2 = {
    mt: "6",
    ml: "-12",
    fontSize: "sm",
  };
  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const formGroupProps = {
    errorMessage,
    helper,
    id,
    isRequired: !!required,
    label,
    showError,
    name,
    ...rest,
  };

  const changeValue = (val) => {
    const newVal = parseInt(val, 10);
    setValue(newVal);
  };

  return (
    <FormGroup {...formGroupProps}>
      <Stack direction="row" spacing="8" mt="1">
        <NumberInput
          size="sm"
          maxW="6rem"
          id={id}
          value={value || 0}
          onChange={changeValue}
          onBlur={() => setIsTouched(true)}
          aria-invalid={showError}
          aria-describedby={!isValid ? `${id}-error` : undefined}
          min={min}
          max={max}
          step={step}
          disabled={props.readOnly ? props.readOnly : false}
        >
          <NumberInputField rounded={"lg"} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Slider
          id={`${id}-slider`}
          value={value || 0}
          onChange={changeValue}
          onBlur={() => setIsTouched(true)}
          aria-invalid={showError}
          aria-describedby={!isValid ? `${id}-error` : undefined}
          min={min}
          max={max}
          step={step}
        >
          <SliderMark value={min} {...labelStyles}>
            {props.minLabel}
          </SliderMark>
          <SliderMark value={max} {...labelStyles2}>
            {props.maxLabel}
          </SliderMark>
          <SliderTrack>
            <SliderFilledTrack bg="blue" />
          </SliderTrack>
          <SliderThumb backgroundColor="white" />
        </Slider>
      </Stack>
      {children}
    </FormGroup>
  );
};
