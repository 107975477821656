import React from "react";
import _ from "lodash";
import { useAtom } from "jotai";
import classNames from "classnames";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import fcLocaleFR from "@fullcalendar/core/locales/fr";
import moment from "../../helper/moment";
import { useTranslation } from "react-i18next";
/** API & HELPER */
import { getOpenTimeSlotsWithConsultationData } from "../../api/doctor";
import { deletetimeSlotWithId } from "../../api/agenda";
import ICalendarEvent from "../../types/ICalendarEvent";
import { userAtom } from "../../state/auth";
import getCalendarOptions from "./calendarOptions";
import CalendarEvent from "./CalendarEvent";
/** COMPONENTS */
import NewAgendaEventForm from "../../components/NewAgendaEventForm";
import AgendaConfigForm from "../../components/AgendaConfigForm";
import WorkingdaysModal from "../../components/WorkingdaysModal";
import DoctorMoveMeeting from "../../components/DoctorMoveMeeting";
import Modal from "../../components/ModalUI";
import Spinner from "../../components/Spinner";
import Icon from "../../components/Icon";
import Tooltip from "../../components/toolTip";
import Reset from "../../assets/reset.svg";
import Button from "../../components/ButtonUI";
import makeToast from "../../components/Snackbar";
import "./styles.scss";

const AgendaKatomi: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [tabIndex, setTabIndex] = React.useState<number | null | undefined>();
  const [refreshTimeSlots, setRefreshTimeSlots] =
    React.useState<boolean>(false);
  const [selectedTimeSlot, setSlectedTimeSlot] = React.useState() as any;
  const [showAgendaModal, setShowAgendaModal] = React.useState("") as any;
  const [showDaysModal, setShowDaysModal] = React.useState<boolean>(false);
  const [deletebtnLoading, setDeletebtnLoading] =
    React.useState<boolean>(false);
  const [openModalMeetDetails, setOpenModalMeetDetails] =
    React.useState<boolean>(false);
  const [loadingAgendaData, setLoadingAgendaData] =
    React.useState<boolean>(false);
  const [modalReset, setModalReset] = React.useState<boolean>(false);
  const [deleteIndisponibility, setDeleteIndisponibility] =
    React.useState<boolean>(false);
  const [eventsList, setEventsList] = React.useState<ICalendarEvent[]>([]);
  const [monthView, setViewMonth] = React.useState<boolean>(false);
  const [user] = useAtom(userAtom);
  const fcRef = React.useRef<any>(null);

  const now = moment();
  const calendarScrollToTime =
    now.hours() > 2
      ? now.subtract(90, "minutes").format("HH:mm:ss")
      : now.format("HH:mm:ss");

  const calendarOptions = getCalendarOptions(
    // localStorage.getItem("i18nextLng") === "fr" ? fcLocaleFR : null,
    i18n.language.includes("en") ? null : fcLocaleFR,
    calendarScrollToTime,
    () => {
      if (
        _.isEmpty(
          eventsList.filter((el) => moment(el.startTimeSlot).isAfter(moment()))
        )
      )
        makeToast(
          "warning",
          "Configurez votre agenda afin de pouvoir ajouter un rendez-vous"
        );
      setShowAgendaModal("newEvent");
    },
    () => setShowAgendaModal("agendaSetup"),
    window?.innerWidth,
    (view) => {
      setViewMonth(view === "month");
    },
    t("Agenda.btnAddEventTitle")
  );

  const _DeleteIndisponibiliy = async () => {
    try {
      setDeletebtnLoading(true);
      await deletetimeSlotWithId(selectedTimeSlot?.item?.id);
      setDeleteIndisponibility(false);
      setRefreshTimeSlots(!refreshTimeSlots);
      makeToast("success", t("Agenda.SuccessOperation"));
      setDeletebtnLoading(false);
    } catch (error) {
      setDeletebtnLoading(false);
    }
  };

  React.useEffect(() => {
    (async () => {
      try {
        setLoadingAgendaData(true);
        const response = await getOpenTimeSlotsWithConsultationData();
        setLoadingAgendaData(false);

        if (response?.data) {
          const remoteEventsList: ICalendarEvent[] = response.data
            .filter((el) => {
              if (moment(el.endTime).isBefore(moment()) || monthView)
                return el.eventStatus !== "open";
              else return el;
            })
            .map((item) => {
              return {
                id: item.id,
                patient:
                  item.eventStatus !== "open"
                    ? {
                        firstName: item?.firstName,
                        lastName: item?.lastName,
                      }
                    : null,
                consultationPurpose: item.motif,
                comment: item.comment,
                AvailableSlotText: t("Agenda.AvailableSlot"),
                monthView: monthView,
                status:
                  item.eventType === "act"
                    ? "act"
                    : item.eventStatus === "off"
                    ? "off"
                    : item.eventStatus,
                // isTeleconsultation: item.eventType === "teleconsultation",
                isTeleconsultation: item.isTeleconsultation,
                isPresentiel: item.isPresentiel,
                isActe: item.isActe,
                officeColorTag:
                  item.eventStatus === "off" && item.eventType !== "act"
                    ? "#FF0000"
                    : item.eventStatus !== "open"
                    ? item?.color
                      ? item?.color
                      : "#ffdf8e"
                    : "#a6a9c8",
                start: item.startTime,
                end: item.endTime,
                startTimeSlot: item.startTime,
                endTimeSlot: item.endTime,
                consultationStatus: item.status,

                handleEvent: (timeText) => {
                  if (item.eventStatus === "open") {
                    if (item.eventType === "regular") {
                      setTabIndex(1);
                      setShowAgendaModal("newEvent");
                      setSlectedTimeSlot({ item, timeText });
                    } else if (item.eventType === "teleconsultation") {
                      setTabIndex(2);
                      setShowAgendaModal("newEvent");
                      setSlectedTimeSlot({ item, timeText });
                    } else if (item.eventType === "act") {
                      setTabIndex(3);
                      setShowAgendaModal("newEvent");
                      setSlectedTimeSlot({ item, timeText });
                    }
                  } else if (item.eventStatus === "booked") {
                    setOpenModalMeetDetails(true);
                    setSlectedTimeSlot({ item, timeText });
                  } else if (
                    item.eventStatus === "off" &&
                    item.eventType !== "act"
                  ) {
                    setDeleteIndisponibility(true);
                    setSlectedTimeSlot({ item, timeText });
                  }
                },
              };
            });

          setEventsList(remoteEventsList);
        }
      } catch (error) {
        setLoadingAgendaData(false);
        console.error(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, showAgendaModal, refreshTimeSlots, monthView]);

  return (
    <section id="agenda" className="flex">
      <div className="w-full relative">
        <FullCalendar
          ref={fcRef}
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
          themeSystem="bootstrap"
          eventContent={CalendarEvent}
          events={eventsList}
          {...calendarOptions}
        />
        {loadingAgendaData && (
          <div className="absolute inset-0 bg-opacity-agenda">
            <Spinner />
          </div>
        )}
      </div>
      <Modal
        showModal={showAgendaModal.length > 0}
        slidemodal={true}
        className=""
        closeModal={() => {
          // When close modal reset component
          setShowAgendaModal("");
          setSlectedTimeSlot();
        }}
      >
        <div className="h-full">
          <div
            className={classNames(
              "flex w-full",
              showAgendaModal === "agendaSetup"
                ? "justify-between"
                : "justify-end"
            )}
          >
            {showAgendaModal === "agendaSetup" && (
              <div
                className="ml-3 mt-3 cursor-pointer"
                onClick={() => setModalReset(true)}
              >
                <Tooltip tooltipText={t("Agenda.Reset")} position="right">
                  <img
                    crossOrigin="anonymous"
                    referrerPolicy="origin"
                    src={Reset}
                    width="20"
                    alt="reset"
                  />
                </Tooltip>
              </div>
            )}
            <div className="flexCenter bg-gray-200 opacity-50 rounded-xl cursor-pointer mr-2 justify-self-end">
              <Icon
                isIcon={true}
                name="close"
                onClick={() => {
                  setShowAgendaModal("");
                  setSlectedTimeSlot();
                }}
              />
            </div>
          </div>
          {showAgendaModal === "newEvent" ? (
            <NewAgendaEventForm
              setShowAgendaModal={setShowAgendaModal}
              selectedTimeSlot={selectedTimeSlot}
              resetTimeSlots={setSlectedTimeSlot}
              tabIndex={tabIndex}
            />
          ) : showAgendaModal === "agendaSetup" ? (
            <AgendaConfigForm
              modalReset={modalReset}
              setModalReset={setModalReset}
              closeModal={() => {
                setShowAgendaModal("");
                setSlectedTimeSlot();
              }}
            />
          ) : null}
        </div>
      </Modal>
      <Modal
        showModal={showDaysModal}
        closeModal={() => setShowDaysModal(false)}
      >
        <WorkingdaysModal />
      </Modal>
      <Modal
        showModal={openModalMeetDetails}
        closeModal={() => {
          setOpenModalMeetDetails(false);
          setSlectedTimeSlot();
        }}
      >
        <DoctorMoveMeeting
          meetDetails={selectedTimeSlot}
          closeModal={() => {
            setOpenModalMeetDetails(false);
            setSlectedTimeSlot();
            setRefreshTimeSlots(!refreshTimeSlots);
          }}
        />
      </Modal>
      <Modal
        showModal={deleteIndisponibility}
        closeModal={() => setDeleteIndisponibility(false)}
      >
        <div className="p-3 ">
          <p> {t("Agenda.confirmDelete")}</p>
          <div className="flexCenter my-3">
            <Button
              width="150px"
              height="40px"
              onClick={_DeleteIndisponibiliy}
              disabled={deletebtnLoading}
            >
              {t("Agenda.delete")}
            </Button>
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default AgendaKatomi;
