import { useState } from "react";
import { Formik } from "formik";
import { useAtom } from "jotai";
import moment from "../../../helper/moment";
import { useHistory } from "react-router-dom";
import Button from "../../../components/ButtonUI";
import Input from "../../../components/FloatInput";
import { login } from "../../../api/patient";
import { resendVerificationCode } from "../../../api/auth";
import { userAtom } from "../../../state/auth";
import "./stylesSignin.scss";
import Icon from "../../../components/Icon";
import smileSick from "../../../assets/images/smileSick.png";
import makeToast from "../../../components/Snackbar";
import voir from "../../../assets/images/voir.png";
import { useTranslation } from "react-i18next";

const SigninPatientKatomi: React.FC = () => {
  const { t } = useTranslation() as any;
  const history = useHistory();
  const [passwordFormType, setPasswordFormType] = useState("password");
  const [, setUser] = useAtom(userAtom);

  const handleLoginRequiredAction = (action, lastLoginParams) => {
    switch (action) {
      case "NEW_PASSWORD_REQUIRED":
        makeToast("warning", t("SignIn.PasswordChangeRequired"));
        return history.push(`/patient/password-change`, { lastLoginParams });
      default:
        break;
    }
  };

  const onSubmit = async (values) => {
    const { email, password, rememberMe } = values;
    try {
      const response = await login({ email, password, rememberMe });

      if (response.message === "User is not confirmed.") {
        makeToast("warning", t("SignIn.ErrorMsgConfirmedEmail"));
        await resendVerificationCode({ email });
        history.push(`/confirm-email/${email}`);
        return;
      }

      if (response.status === "success") {
        makeToast("success", t("SignIn.SuccessConnectedMsg"));
      } else if (
        response.status === "actions_required" &&
        Array.isArray(response.actions)
      ) {
        console.log(
          "[DEBUG] further required actions needed : ",
          response.actions
        );
        // TODO handle multiple required actions
        return handleLoginRequiredAction(response.actions[0], {
          email,
          password,
          rememberMe,
        });
      }

      values.rememberMe && localStorage.setItem("rememberMe", "true");
      values.rememberMe && localStorage.setItem("expireIn", moment().format());
      setUser(response.user);
    } catch (error: any) {
      makeToast("error", t("SignIn.ErrorMsgIncorrectCredential"));
    }
  };

  return (
    <div className="login-patient min-h-screen flex justify-center">
      <div className="absolute inset-0 katomiWterMark" />
      <div className="auth-login-Container flexColCenter ">
        {/* <div className="logo-bloc flexRowCenter">
          <img crossOrigin = "anonymous"  referrerPolicy="origin" src={logo} alt="logo" />
          <p className="logo_text">Katomi</p>
        </div> */}
        {/* <h1 className="h1 title flexRowCenter">{t("SignIn.login")}</h1> */}
        <div className="formLogin">
          {/* start */}
          <div
            className="icon cursor-pointer"
            onClick={() => history.push("/check/user")}
          >
            <Icon
              name="direction-gauche"
              width={50}
              height={50}
              isIcon={true}
            />
          </div>
          <div className="flexRowCenter img-top">
            <img
              crossOrigin="anonymous"
              referrerPolicy="origin"
              src={smileSick}
              width="46px"
              height="46px"
              alt="smileSick"
            />
          </div>
          <div className="title-container flexColCenter">
            <p>{t("SignIn.im")}</p>
            <h1 className="h1 flexRowCenter">{t("SignIn.patient")}</h1>
          </div>
          <div className="">
            <Formik
              initialValues={{ email: "", password: "", rememberMe: false }}
              onSubmit={onSubmit}
              // validationSchema={validationSchema}
            >
              {(props) => {
                const {
                  values,
                  // errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props;

                return (
                  <>
                    <form
                      onSubmit={handleSubmit}
                      className="form-login bg-opacity-50"
                    >
                      <Input
                        id="email"
                        placeholder={t("SignIn.email")}
                        labelText={t("SignIn.email")}
                        type="text"
                        // error={errors.email}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inlineLabel={false}
                        onfocus={true}
                      />
                      <div className="relative">
                        <Input
                          id="password"
                          placeholder={t("SignIn.psw")}
                          labelText={t("SignIn.psw")}
                          type={passwordFormType}
                          className="pr-10"
                          // error={errors.password}
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <img
                          crossOrigin="anonymous"
                          referrerPolicy="origin"
                          src={voir}
                          alt={t("SignIn.voir")}
                          className="absolute right-3 top-6 w-4 h-3 cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault();
                            setPasswordFormType(
                              passwordFormType === "password"
                                ? "text"
                                : "password"
                            );
                          }}
                        />
                      </div>
                      <p
                        className="mdp cursor-pointer"
                        onClick={() => history.push("/forgetPassword")}
                      >
                        {t("SignIn.forgetPassword")}
                      </p>
                      {/* <div className="flex items-center space-x-2">
                        <Input
                          id="rememberMe"
                          placeholder={t("SignIn.rememberMe")}
                          // labelText={t("SignIn.rememberMe")}
                          type="checkbox"
                          // error={errors.password}
                          value={values.rememberMe as any}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="mr-2"
                          inlineLabel={false}
                          onfocus={true}
                          // labelProps={{
                          //   className: "-mt-1 ml-5 text-9xl ",
                          // }}
                        />
                        <span className="-mt-4">{t("SignIn.rememberMe")}</span>
                      </div> */}
                      <div className="flexRowCenter">
                        <Button
                          type="submit"
                          disabled={isSubmitting}
                          status="primary"
                          className="btn"
                        >
                          {t("SignIn.login")}
                        </Button>
                      </div>
                    </form>
                  </>
                );
              }}
            </Formik>
          </div>
          <div className="create-Account flexColCenter">
            <p className="check-account">{t("SignIn.haventCompte")}</p>
            <p
              className="create cursor-pointer"
              onClick={() => history.push("/patient/inscription/routage")}
            >
              {t("SignIn.createCompte")}
            </p>
          </div>
          {/* bloc  */}
        </div>
      </div>
      {/* {process.env.REACT_APP_KATOMI_ENV === "development" && (
        <div
          className="absolute bottom-5 right-3 rounded-full cursor-pointer "
          onClick={() =>
            onSubmit({
              email: process.env.REACT_APP_KATOMI_PATIENT_EMAIL,
              password: process.env.REACT_APP_KATOMI_PATIENT_PSW,
            })
          }
        >
          <img crossOrigin = "anonymous"  referrerPolicy="origin"
            src={require("../../../assets/password.png").default}
            alt="admin-login"
            width={64}
          />
        </div>
      )} */}
    </div>
  );
};

export default SigninPatientKatomi;
