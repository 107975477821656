/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import Spinner from "../../components/Spinner";
import { userAtom } from "../../state/auth";
import history from "../../history";
type Props = any;
const ExternalBokking: React.FC<Props> = ({ props }) => {
  const [user] = useAtom(userAtom);
  const [loader] = useState(true);
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  useEffect(() => {
    const localStorgeObject = {
      first: true,
      docId: query.get("doc"),
      date: query.get("date"),
      timeSlotId: query.get("tid"),
      eventType: query.get("eventType"),
      nom: query.get("name"),
    } as any;
    localStorage.setItem("Booking", JSON.stringify(localStorgeObject));

    if (user) {
      history.push("/annuaire");
    } else {
      history.push("/patient/login");
    }
  }, []);

  return loader ? <Spinner /> : <Spinner />;
};

export default ExternalBokking;
