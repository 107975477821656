import React, { useEffect, useState } from "react";
import _ from "lodash";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  deleteDocumentOFPatientByDoctor,
  loadImage,
} from "../../api/documents";
import { IPatient } from "../../types/patient";
import Button from "../ButtonUI";
import Spinner from "../Spinner";
import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";

interface IProps {
  helperCategorie: Function;
  setAddCategorieDocMenu: Function;
  addCategorieDocMenu: boolean;
  documentsList: [] | any;
  patientData: IPatient | any;
  setImageUrl: Function;
  setModalImage: Function;
  setDocumentsList: Function;
}

const parseImage = (filename, userId, type) => ({
  original: loadImage(userId, type, "thumbnail", filename),
});

const ImagesContainer: React.FC<IProps> = ({
  helperCategorie,
  setAddCategorieDocMenu,
  addCategorieDocMenu,
  documentsList,
  patientData,
  setImageUrl,
  setModalImage,
  setDocumentsList,
}) => {
  const { t } = useTranslation() as any;
  const [parsedImages, setParsedImages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (patientData) {
      const imgs = [] as any;
      // eslint-disable-next-line array-callback-return
      documentsList.map((img) => {
        imgs.push(parseImage(img.filePath, patientData?._id, img.type));
      });
      setParsedImages(imgs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsList]);

  const hanldeDelete = async (imageUrl) => {
    const array = imageUrl.split("/");
    const newDocs = await deleteDocumentOFPatientByDoctor(
      array[6],
      array[7],
      "null",
      array[9].split("?")[0]
    );
    setDocumentsList([...newDocs.DeletedDocsPatient.documents]);
    setLoading(false);
  };

  const handleDocument = () =>
    parsedImages.map((i: any) => {
      return (
        i && (
          <div
            className="relative imagesContainer mr-5 mb-3 "
            style={{ minHeight: "150px" }}
          >
            <div className="absolute right-0 mt-2 deleteImg">
              <Button
                status="icon-secondary"
                onClick={() => {
                  setLoading(true);
                  hanldeDelete(i.original);
                }}
                width={"37px"}
                height={"37px"}
              >
                <Close />
              </Button>
            </div>
            <img
              crossOrigin="anonymous"
              referrerPolicy="origin"
              src={i?.original}
              className="object-cover "
              alt="document katomi"
              onClick={() => {
                setImageUrl({
                  url: i?.original.split("/")[9].split("?")[0],
                  type: i?.original.split("/")[7],
                });
                setModalImage(true);
              }}
            />
          </div>
        )
      );
    });

  return loading ? (
    <Spinner />
  ) : (
    <>
      <div className="flex justify-between items-center">
        <h3>{t("MyPatient.Images")}</h3>
        <div className="relative ml-12">
          <Button
            status="secondary"
            width={"100px"}
            height={"40px"}
            className="min-h-full"
            onClick={() => setAddCategorieDocMenu(!addCategorieDocMenu)}
          >
            {t("MyPatient.add")}
          </Button>
          <div
            className={`shadow-lg py-3 z-10 absolute top-10 -left-6 bg-white min-w-max rounded-2xl ${
              addCategorieDocMenu ? "block" : "hidden"
            }`}
          >
            <ul className="p-2">
              <li
                className="cursor-pointer"
                onClick={() => helperCategorie("Biologique")}
              >
                {t("MyPatient.Organic")}
              </li>
              <li
                className="mt-4 mb-4 cursor-pointer"
                onClick={() => helperCategorie("Radiologique")}
              >
                {t("MyPatient.Radiological")}
              </li>
              <li
                className="cursor-pointer"
                onClick={() => helperCategorie("Autres")}
              >
                {t("MyPatient.Others")}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          "images mt-2  ",
          // eslint-disable-next-line eqeqeq
          parsedImages.length == 0 ? "h-40 w-full" : "flex flex-wrap min-w-full"
        )}
      >
        {!_.isEmpty(parsedImages) ? (
          // parsedImages.length > 4 ? (
          //   <HorizontalScroll>{handleDocument()}</HorizontalScroll>
          // ) : (
          handleDocument()
        ) : (
          <div
            className="flexCenter w-full anEmptyBox"
            style={{ minHeight: "150px" }}
          >
            <p className="card-subtitle"> {t("MyPatient.NoDoC")}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default ImagesContainer;
