import { useState } from "react";
import Input from "../../../Input";
import Button from "../../../ButtonUI";
import FloatInput from "../../../FloatInput";
import SelectComp from "../../../Select";
import { dropdownClasseOptions, dropdownExerciseFrame } from "../helper";
import { DMP } from "../../../../api/devbox";
import makeToast from "../../../Snackbar";

type Props = {
  selectedFile;
  closeModal;
  setRefreshPatient;
  setRefreshDMP;
};

const OpenModalOptionSend = (props: Props) => {
  const { content, format, patientData, patientDMPData, author } =
    props.selectedFile;
  const [title, setTitle] = useState("");
  const [classCode, setClassCode] = useState("");
  const [typeCode, setTypeCode] = useState("");
  const [exerciseFrame, setExerciseFrame] = useState("");
  const [confidentialityCode, setConfidentialityCode] = useState<string[]>([
    "N",
  ]);
  // useState loading
  const [loading, setLoading] = useState(false);

  const AddNewDMPFile = async () => {
    try {
      setLoading(true);
      const patient = {
        internalId: patientData?._id,
        prenom:
          patientData?.INS?.responseINS.traitsIdentite?.prenomActeNaissance,
        matriculeINS: patientDMPData?.matriculeINS,
        nomUsuel: patientData?.INS?.responseINS.traitsIdentite?.nomFamille,
        dateDeNaissance:
          patientData?.INS?.responseINS.traitsIdentite?.dateNaissance
            .split("-")
            .join(""),
        ordreDeNaissance: 0,
        sexe: patientData?.INS?.responseINS.traitsIdentite?.sexeAdministratif,
      };
      const contentCDA = await DMP.dmp2cda(
        author,
        content,
        patient,
        format,
        title
      );
      const requestAddNewDMPFile = {
        context: {
          author,
          modeAcces: {
            acces: "NORMAL",
          },
          confidentialityCode: confidentialityCode?.includes(
            "INVISIBLE_REPRESENTANTS_LEGAUX"
          )
            ? {
                valeur: "INVISIBLE_REPRESENTANTS_LEGAUX",
                identifiantNomenclature: "1.2.250.1.213.1.1.4.13",
                libelle: "Non visible par les représentants légaux du patient",
              }
            : null,
        },
        request: {
          submission: {
            title,
            contentType: "04",
            patient,
            documents: [
              {
                auteurs: [author],
                dmpcType: "wrapped",
                classCode: "11",
                comments: "Document Wrapped soumis via katomi",
                confidentialities: confidentialityCode,
                isCdaR2N3: true,
                format,
                content: contentCDA,
              },
            ],
          },
        },
      };
      const response = await DMP.td21SubmitDocuments(requestAddNewDMPFile);
      props.setRefreshPatient((x) => !x);
      props.setRefreshDMP((x) => !x);
      setLoading(false);
      makeToast(
        "success",
        `L'ajout de dossier patient a été effectué avec succès.`
      );
      props.closeModal();
      return response;
    } catch (error) {
      makeToast("error", `L'ajout de dossier patient a echoué.`);
      setLoading(false);
    }
  };

  return (
    <div className="p-5">
      <h3 className="font-bold mb-4">Paramètres d'alimentation </h3>
      <hr className="-mt-2 mb-5" />
      <div className="flex gap-10" style={{ width: "80vw" }}>
        <div className="w-2/3">
          <FloatInput
            className="w-full border-blue-500"
            placeholder="Titre du document"
            labelText="Titre du document"
            type="text"
            style={{ maxHeight: "50px" }}
            onBlur={(e) => setTitle(e.target.value)}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            inlineLabel={false}
            onfocus={true}
          />
          <FloatInput
            className="w-full border-blue-500"
            placeholder="Format"
            labelText="Format"
            type="text"
            style={{ maxHeight: "50px" }}
            // onBlur={(e) => setFormat(e.target.value)}
            // onChange={(e) => setFormat(e.target.value)}
            value={format}
            inlineLabel={false}
            onfocus={true}
            disabled
          />
          <p className="font-bold">Classe : {classCode}</p>
          <SelectComp
            value={classCode}
            options={dropdownClasseOptions}
            onChange={(e) => setClassCode(e.target.value)}
            onBlur={(e) => setClassCode(e.target.value)}
            className="w-full border-blue-500"
            setText={false}
          />

          <p className="font-bold">Type : {typeCode}</p>
          <SelectComp
            value={typeCode}
            options={
              classCode
                ? dropdownClasseOptions?.find((el) => el.value === classCode)
                    ?.options || []
                : []
            }
            onChange={(e) => setTypeCode(e.target.value)}
            onBlur={(e) => setTypeCode(e.target.value)}
            className="w-full border-blue-500"
            setText={false}
          />

          <p className="font-bold">Cadre d'exercice : {typeCode}</p>
          <SelectComp
            value={exerciseFrame}
            options={dropdownExerciseFrame}
            onChange={(e) => setExerciseFrame(e.target.value)}
            onBlur={(e) => setExerciseFrame(e.target.value)}
            className="w-full border-blue-500"
            setText={false}
          />
        </div>
        <div className="w-1/3 flex flex-col justify-between  h-full">
          <div className="">
            <p className="font-bold">Visibililé :</p>
            <Input
              name="visibility"
              type="checkbox"
              onChange={() => {
                confidentialityCode.includes("INVISIBLE_PATIENT")
                  ? setConfidentialityCode((x) =>
                      x.filter((el) => el !== "INVISIBLE_PATIENT")
                    )
                  : setConfidentialityCode([
                      ...confidentialityCode,
                      "INVISIBLE_PATIENT",
                    ]);
              }}
              checked={confidentialityCode.includes("INVISIBLE_PATIENT")}
              value="treating"
              labelText="Non visible par le patient"
              labelProps={{ className: "check" }}
              className="my-2"
            />
            <Input
              name="visibility"
              type="checkbox"
              value="treating"
              onChange={() => {
                confidentialityCode.includes("MASQUE_PS")
                  ? setConfidentialityCode((x) =>
                      x.filter((el) => el !== "MASQUE_PS")
                    )
                  : setConfidentialityCode([
                      ...confidentialityCode,
                      "MASQUE_PS",
                    ]);
              }}
              checked={confidentialityCode.includes("MASQUE_PS")}
              labelText="Masqué aux professionnels de santé"
              labelProps={{ className: "check" }}
              className="my-2"
            />
            <Input
              name="visibility"
              type="checkbox"
              value="treating"
              labelText="Non visible pour les représentants légaux"
              onChange={() => {
                confidentialityCode.includes("INVISIBLE_REPRESENTANTS_LEGAUX")
                  ? setConfidentialityCode((x) =>
                      x.filter((el) => el !== "INVISIBLE_REPRESENTANTS_LEGAUX")
                    )
                  : setConfidentialityCode([
                      ...confidentialityCode,
                      "INVISIBLE_REPRESENTANTS_LEGAUX",
                    ]);
              }}
              checked={confidentialityCode.includes(
                "INVISIBLE_REPRESENTANTS_LEGAUX"
              )}
              labelProps={{ className: "check" }}
              className="my-2"
            />
          </div>
          <Button
            status="primary"
            height="32px"
            disabled={loading}
            onClick={AddNewDMPFile}
          >
            <div className="relative">
              {loading && (
                <div className="absolute h-full w-full flexCenter ">
                  <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-purple-500"></div>
                </div>
              )}
              ✔ Valider
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OpenModalOptionSend;
