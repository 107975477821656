export const Consultation = {
  fr: {
    consultationOver: "la consultation est deja terminée",
    autoSaveAt: " Enregistré automatiquement à",
    End: "Terminer",
    Confirm: "Confimer",
    Cancel: "Annuler",
    Save: "Enregistrer",
    Send: "Envoyer",
    ShareToPatient: "Transmettre au patient",
    NoteFromConsultation: "Mes notes de la consultation du",
    LeftConsultation: "Vous avez quitté la consultation",
    addPrescription: "+ Prescription",
    AddReport: "+ Compte Rendu",
    PutInToDoList: "Mettre dans la liste des tâches",
    EmptyReport: "Le compte rendu est vide",
    SuccessSentReport:
      "Le compte rendu rendu a été envoyé à votre patient avec succès",
    SuccessAddDMP: `L'ajout de dossier patient a été effectué avec succès.`,
    FailAddDMP: `L'ajout de dossier patient a echoué.`,
    SuccessSendReportToPatient:
      "Le compte rendu rendu a été envoyé à votre patient avec succès",
    ShareToDMP: "Voulez-vous partager ce document au dmp?",
    ModelCenter: "Centre de modèles",
    YourNotes: "Vos notes",
    PatientFoldersAndImages: "Dossiers et images patient",
    NoDocumentFound: "Aucun dossier trouvé pour ce patient",
    WriteYourReport: "Rédigez votre compte rendu",
    ReportFinished: "Votre compte rendu est terminé ?",
    ConsultationOf: "Consultation de",
  },
  en: {
    consultationOver: "the consultation is already over",
    autoSaveAt: "Automatically saved at",
    End: "Save",
    Cancel: "Cancel",
    Save: "Save",
    Send: "Send",

    ShareToPatient: "Share to the patient",
    NoteFromConsultation: "My notes from the consultation",
    LeftConsultation: "You left the consultation",
    addPrescription: "+ Prescription",
    AddReport: "+ Report",
    PutInToDoList: "Put in to-do list",
    EmptyReport: "The report is empty",
    SuccessSentReport: "The report has been successfully sent to your patient",
    SuccessAddDMP: `Patient document has been successfully added to DMP.`,
    FailAddDMP: `Add document patient to DMP failed. `,
    Confirm: "Confirm",
    SuccessSendReportToPatient:
      "The report has been successfully sent to your patient",
    ShareToDMP: "Do you want to share this document to the dmp?",
    ModelCenter: "Model center",
    YourNotes: "Your notes",
    PatientFoldersAndImages: "Patient Folders and Images",
    NoDocumentFound: "No document found for this patient",
    WriteYourReport: "Write your report",
    ReportFinished: "Is your report finished?",
    ConsultationOf: "Consultation of",
  },
};
