import React from "react";
import Button from "../ButtonUI";
import { resetAgenda } from "../../api/agenda";
import { useTranslation } from "react-i18next";

type Props = {
  closeModal: Function;
};

const ModalReset = (props: Props) => {
  const { t } = useTranslation();
  const { closeModal } = props;
  const [loading, setLoading] = React.useState(false);

  const handleReset = async () => {
    try {
      setLoading(true);
      await resetAgenda();
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="p-3 text-center">
      <h2>{t("Agenda.ConfirmText1")}</h2>
      <h3>{t("Agenda.ConfirmText2")}</h3>
      <div className="flexCenter gap-2 mt-5">
        <Button
          status="secondary"
          width="130px"
          height="40px"
          onClick={() => closeModal()}
        >
          {t("Agenda.Cancel")}
        </Button>
        <Button
          width="130px"
          height="40px"
          onClick={handleReset}
          disabled={loading}
        >
          <div className="relative">
            <span>{t("Agenda.Confirm")}</span>
            {loading && (
              <div className="absolute inset-0 flexCenter">
                <div className=" animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-purple-500" />
              </div>
            )}
          </div>
        </Button>
      </div>
    </div>
  );
};

export default ModalReset;
