import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import Button from "../../../components/ButtonUI";
import Select from "../../../components/Select";
import { userAtom } from "../../../state/auth";
import "./styles.scss";
import moment from "../../../helper/moment";

export interface IProps {
  setOpenModal: Function;
  filerObject: object;
  setFilterObject: Function;
  setSearch;
}

const FilterModalAntecedent: React.FC<IProps> = ({
  setOpenModal,
  filerObject,
  setFilterObject,
  setSearch,
}) => {
  const { t } = useTranslation() as any;
  const [user] = useAtom(userAtom) as any;
  let doctorOption = user?.doctors?.map((e: any) => {
    return {
      label: e?.firstName + " " + e?.lastName,
      value: e._id,
    };
  });
  doctorOption = [
    ...doctorOption,
    { label: t("MyFolder.AllMyDoctors"), value: "Tous mes medecins" },
  ];

  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute bg-white z-40 top-1/3 flexCenter modalFilter">
        {/* <div className="flex justify-end p-5">
          <div className="absolute right-0 mt-2 mr-2">
            <Button status="icon-secondary" onClick={() => setOpenModal(false)}>
              <Close />
            </Button>
          </div>
        </div> */}
        <div className="flex flex-col item-center space-y-3  px-10 pb-5 mt-12  w-full">
          <div className="flex space-x-5 w-full justify-between">
            <Select
              labelText={t("MyFolder.slectDoctor")}
              text={t("MyFolder.selectChoice")}
              className="px-3"
              labelProps={{ className: "w-40" }}
              options={doctorOption}
              onChange={(e) => {
                setFilterObject({ ...filerObject, doctor: e.target.value });
              }}
            />
          </div>
          {/* <div className="flexCenter space-x-5 ">
            <Select
              labelText="Ville"
              className="w-60 px-3"
              labelProps={{ className: "w-40" }}
              text="Sélectionner une ville"
              options={[
                { label: "Tunis", value: "tunis" },
                { label: "Ariana", value: "ariana" },
                { label: "Ben Arous", value: "ben Arous" },
              ]}
              onChange={(e) => {
                setFilterObject({
                  ...filerObject,
                  patientCity: e.target.value,
                });
              }}
            />
          </div> */}
          <div className="flex space-x-5 w-full justify-between">
            <Select
              labelText="Sélectionnez un antécédent"
              className="px-3"
              labelProps={{ className: "w-40" }}
              text={"Sélectionnez un antécédent"}
              options={[
                {
                  label: t("MyFolder.AllAntecedent"),
                  value: "Tous les antécédents médicaux et chirurgicaux",
                },
                {
                  label: t("MyFolder.MedicalBackground"),
                  value: "Antécédents médicaux",
                },
                {
                  label: t("MyFolder.SurgicalHistory"),
                  value: "Antécédents chirurgicaux",
                },
              ]}
              onChange={(e) => {
                setFilterObject({
                  ...filerObject,
                  antecedent: e.target.value,
                });
              }}
            />
          </div>
          <div className="flex space-x-5 w-full justify-between">
            <Select
              labelText={t("MyFolder.AntecedentDate")}
              className="px-3"
              labelProps={{ className: "w-40" }}
              text={t("MyFolder.SelectDate")}
              options={[
                {
                  label: t("MyFolder.Today"),
                  value: `${moment(Date.now()).format("L")}`,
                },
                {
                  label: t("MyFolder.last7Day"),
                  value: `${moment(Date.now())
                    .subtract(7, "days")
                    .format("L")}`,
                },
                {
                  label: t("MyFolder.last30Day"),
                  value: `${moment(Date.now())
                    .subtract(30, "days")
                    .format("L")}`,
                },
              ]}
              onChange={(e) => {
                setFilterObject({
                  ...filerObject,
                  date: e.target.value,
                });
              }}
            />
          </div>
          <Button
            status="secondary"
            height="50px"
            onClick={() => {
              setSearch(true);
              setOpenModal(false);
            }}
          >
            {t("MyFolder.Filter")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterModalAntecedent;
