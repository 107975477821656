import axios from "axios";
import {
  GENERATE_NEW_SHARE_FOLDER,
  GET_SHARE_FOLDER_BY_ID,
  GENERATE_NEW_TASK_FOLDER,
  GET_TASK_FOLDER_BYID,
} from "../config/api";

export const generateNewShareFolder = async ({ ...values }) => {
  try {
    const res = await axios.post(GENERATE_NEW_SHARE_FOLDER, values, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getShareFolderById = async (id) => {
  try {
    const res = await axios.get(GET_SHARE_FOLDER_BY_ID(id), {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const generateNewtaskFolder = async (payload) => {
  try {
    const res = await axios.post(GENERATE_NEW_TASK_FOLDER, payload, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getTaskFolderById = async (id) => {
  try {
    const res = await axios.get(`${GET_TASK_FOLDER_BYID}/id`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
