/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";

import { useAtom } from "jotai";
//** Helper & API */
import { CPS } from "../../api/devbox";
import { getListConsultationForDoctor } from "../../api/consultation";
import { getAllBooking } from "../../api/agenda";
import { loadImage } from "../../api/documents";
import { messagesPatientLists, messagesDoctorLists } from "../../api/messages";
import { getWaitingTaskDoctorOfDay } from "../../api/task";
import { getAllDoctorSurvey } from "../../api/survey";
import moment from "../../helper/moment";
import { userAtom } from "../../state/auth";
import { cpsDataAtom } from "../../state/cardCpsData";
//** Component */
import Tabs from "../Tabs";
import ChatModal from "../SharedChat";
import CardPatientsOfTheDay from "../../pages/DashboardDoctor/CardPatientsOfTheDay";
import TacheCardDoctor from "../../pages/DashboardDoctor/TacheCardDoctor";
import PreviewAgenda from "../PreviewAgenda";
import CardSurvey from "./SurveyCard";
import makeToast from "../Snackbar";
/** Assets */
import Reset from "../../assets/reset.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";
import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import "../../pages/Forms/styles.scss";
import { useTranslation } from "react-i18next";

function PatientOfTheDayWidget() {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [listConsultationOfDoctor, setListConsultationOfDoctor] = useState(
    []
  ) as any;

  const _getListConsultationOfDoctor = async () => {
    setLoading(true);
    try {
      const response = await getListConsultationForDoctor();
      setLoading(false);
      if (
        response &&
        response.consultations &&
        response.consultations.length > 0
      )
        setListConsultationOfDoctor(response.consultations);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    _getListConsultationOfDoctor();
  }, []);
  return loading ? (
    <div className="flexCenter h-full">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
    </div>
  ) : (
    <div>
      <h3 className="uppercase">{t("DashboardDoctor.ptientofDay")} </h3>
      {!_.isEmpty(listConsultationOfDoctor) ? (
        <div className="flex">
          {listConsultationOfDoctor
            .sort(
              (a, b) =>
                +new Date(a.dateConsultation) - +new Date(b.dateConsultation)
            )
            .slice(0, 2)
            .map((consultation: any, i) => (
              <CardPatientsOfTheDay
                key={consultation?._id}
                status={i === 0 ? "current" : "default"}
                meetDeatils={consultation}
              />
            ))}
        </div>
      ) : (
        <div className="mw-700 bg-white w-full flexCenter my-5 rounded-lg shadow-md py-5">
          <span className="card-subtitle">
            {t("DashboardDoctor.EmptyConsultation")}{" "}
          </span>
        </div>
      )}
    </div>
  );
}

function TaskOfTheDayWidget({ openPrescriptionModal, reFetchTask }) {
  const { t } = useTranslation() as any;
  const [loading, setLoading] = useState(false);
  const [listTaskOfDoctor, setlistTaskOfDoctor] = useState([]);

  const _getWaitingTaskDoctorOfDay = async () => {
    try {
      setLoading(true);
      const response = await getWaitingTaskDoctorOfDay();
      setlistTaskOfDoctor(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    _getWaitingTaskDoctorOfDay();
  }, [reFetchTask]);
  return loading ? (
    <div className="flexCenter h-full">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
    </div>
  ) : (
    <div className="h-full overflow-y-auto">
      <div className="mw-700 flex justify-between items-center">
        <h3 className="uppercase"> {t("DashboardDoctor.taskOfDay")}</h3>
        {/* <Button width="128px" height="50px">
     + Ajouter
   </Button> */}
      </div>
      {listTaskOfDoctor && !_.isEmpty(listTaskOfDoctor) ? (
        listTaskOfDoctor.map((task: any, i) => (
          <TacheCardDoctor
            task={task}
            key={task._id}
            reFetchTask={_getWaitingTaskDoctorOfDay}
            openPrescriptionModal={(x) => openPrescriptionModal(x)}
          />
        ))
      ) : (
        <div className="mw-700 bg-white w-full flexCenter my-5 rounded-lg shadow-md py-5">
          <span className="card-subtitle">
            {t("DashboardDoctor.EmptyTask")}
          </span>
        </div>
      )}
    </div>
  );
}

function AgendaWidget() {
  const { t } = useTranslation() as any;
  const [loading, setLoading] = useState(false);
  const [listBookingDoctor, setListBookingDoctor] = useState([]);
  const _getAllBookingDoctor = async () => {
    setLoading(true);
    try {
      const response = await getAllBooking();
      setLoading(false);
      if (response)
        setListBookingDoctor(
          response.consultations
            .filter(
              (el) =>
                moment(el.dateConsultation).isAfter(
                  moment().subtract(1, "days").endOf("day")
                ) && el.status !== "done"
            )
            .sort((a, b) =>
              moment(moment(a.dateConsultation)).diff(
                moment(b.dateConsultation)
              )
            )
            .map((el) => ({
              patientName:
                el.patientId.firstName.charAt(0).toUpperCase() +
                el.patientId.firstName.toLowerCase().slice(1) +
                " " +
                el.patientId.lastName.charAt(0).toUpperCase() +
                el.patientId.lastName.toLowerCase().slice(1),
              speciality: el.motif,
              date: el.dateConsultation.slice(0, 10),
              datetime: el.dateConsultation,
            }))
        );
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    _getAllBookingDoctor();
  }, []);
  return loading ? (
    <div className="flexCenter h-full">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
    </div>
  ) : (
    <div className="overflow-auto lg:ml-5 h-full">
      <h3 className="uppercase"> {t("DashboardDoctor.Agenda")}</h3>
      <PreviewAgenda meetDeatils={listBookingDoctor} />
    </div>
  );
}

function MessagerieWidget() {
  const [chatWithId, setChatWithId] = useState("");
  const [modalCHat, setModalChat] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState(0);

  //** PATIENT STATE */
  const [patients, setPatients] = useState([]) as any;
  const [loadingFetchPatient, setLoadingFetchPatient] = useState(false);

  /** DOCTOR STATE */
  const [doctors, setDoctors] = useState([]) as any;
  const [, setLoadingFetchDoctors] = useState(false);

  const _fetchPatients = async () => {
    setLoadingFetchPatient(true);
    let response = await messagesPatientLists();
    setPatients(
      _.isEmpty(response?.patients?.patients) ? [] : response.patients?.patients
    );
    setLoadingFetchPatient(false);
  };

  const _fetchDoctors = async () => {
    setLoadingFetchDoctors(true);
    let response = await messagesDoctorLists();
    setDoctors(_.isEmpty(response?.doctors) ? [] : response.doctors);
    setLoadingFetchDoctors(false);
  };

  useEffect(() => {
    _fetchPatients();
    _fetchDoctors();
  }, []);

  return (
    <>
      <ChatModal
        chatWithId={chatWithId}
        showModal={modalCHat}
        closeChatModal={() => {
          setModalChat(false);
          setChatWithId("");
        }}
      />
      <h3 className="uppercase mb-3">Messagerie</h3>
      <div className="shadow w-96 flexCol p-5 bg-white rounded-2xl">
        <Tabs
          menu={[{ name: "Patients" }, { name: "Doctors" }]}
          tabIndex={(i) => setTabIndex(i)}
          className="m-auto"
        />
        <div className="">
          {tabIndex === 0 ? (
            loadingFetchPatient ? (
              <div className="flexCenter">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
              </div>
            ) : (
              patients.map((el) => (
                <div
                  className="flex space-x-5 items-center my-2 shadow rounded-2xl cursor-pointer"
                  onClick={() => {
                    setChatWithId(el._id);
                    setModalChat(true);
                  }}
                >
                  {el?.photo ? (
                    <img
                      crossOrigin="anonymous"
                      referrerPolicy="origin"
                      src={loadImage(
                        el?._id,
                        "profile",
                        "thumbnail",
                        el?.photo
                      )}
                      className="w-16 h-16 object-fill rounded-full shadow-md"
                      alt="profile"
                    />
                  ) : el?.sexe === "woman" ? (
                    <div className="w-16 h-16">
                      <WomanPatient />
                    </div>
                  ) : (
                    <div className="w-16 h-16">
                      <ManPatient />
                    </div>
                  )}
                  <h3>{el.lastName + " " + el.firstName}</h3>
                </div>
              ))
            )
          ) : (
            doctors.map((el) => (
              <div
                className="flex space-x-5 items-center my-2 shadow rounded-2xl p-1 cursor-pointer"
                onClick={() => {
                  setChatWithId(el._id);
                  setModalChat(true);
                }}
              >
                {el?.business_photo ? (
                  <img
                    crossOrigin="anonymous"
                    referrerPolicy="origin"
                    src={loadImage(
                      el?._id,
                      "profile",
                      "thumbnail",
                      el?.business_photo
                    )}
                    className="w-16 h-16 object-fill rounded-full shadow-md"
                    alt="profile"
                  />
                ) : el?.sexe === "woman" ? (
                  <div className="w-16 h-16">
                    <WomanPatient />
                  </div>
                ) : (
                  <div className="w-16 h-16">
                    <ManPatient />
                  </div>
                )}
                <h3>{el.lastName + " " + el.firstName}</h3>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
}

function SurveyWidget() {
  const { t } = useTranslation() as any;
  const [user] = useAtom(userAtom);
  const [surveyList, setSurveyList] = useState([] as any[]);
  const [, setLoader] = useState(true);
  const getAllSurveys = async () => {
    try {
      const res = await getAllDoctorSurvey(user?._id);
      if (res) {
        setSurveyList(res);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };
  useEffect(() => {
    getAllSurveys();
  }, []);

  return (
    <div className="overflow-auto">
      <h3 className="uppercase">{t("DashboardDoctor.Survey")}</h3>
      <div className="bg-white rounded-2xl mt-5">
        {!_.isEmpty(surveyList) ? (
          <div className={`patient-list flex flex-wrap gap-3 `}>
            {surveyList
              .sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt))
              .slice(0, 3)
              .map((el, i) => (
                <CardSurvey
                  patientDetails={el}
                  openModal={false}
                  surveyDetails={el}
                  user={user}
                  i={i}
                />
              ))}
          </div>
        ) : (
          <div className="w-full flexCenter mt-5 py-5">
            <span className="card-subtitle">
              {t("DashboardDoctor.NoSurveyFound")}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

function CardReaderWidget() {
  const { t } = useTranslation() as any;
  const [cpsData, setCpsData] = useAtom(cpsDataAtom) as any;
  const [user] = useAtom(userAtom) as any;
  const [loading, setLoading] = useState(false) as any;
  const [code, setCode] = useState() as any;
  const [sessionState, setSessionState] = useState() as any;

  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  useEffect(() => {
    if (query.get("session_state")) {
      setSessionState(query.get("session_state"));
    }
    if (query.get("code")) {
      setCode(query.get("code"));
    }
  }, []);

  const loadCardInfo = async () => {
    try {
      setLoading(true);
      const data = await CPS.readCard();
      setCpsData(data);
      setLoading(false);
    } catch (error: any) {
      console.error("[error] could not load CPS card information!\n", error);
      setLoading(false);
      if (error.message.toLowerCase().startsWith("failed to fetch")) {
        makeToast(
          "warning",
          "Veuillez faire en sorte que DevBox soit en cours d'exécution sur votre machine."
        );
      }
    }
  };

  return (
    <>
      <div className="lg:ml-5 h-full ">
        <h3 className="uppercase">{t("DashboardDoctor.CardCPSTitle")} </h3>
        <div className="shadow w-full md:w-96 flexCol p-5 bg-white rounded-2xl mt-5">
          <div className="rounded-xl  modalCardCPS">
            <div className="flex justify-between items-center">
              <h3 className="card-title ">{t("DashboardDoctor.Info")} </h3>

              {loading ? (
                <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-purple-500" />
              ) : (
                <div className="flexCenter gap-5">
                  <img
                    crossOrigin="anonymous"
                    referrerPolicy="origin"
                    alt="reset"
                    src={Reset}
                    width="25"
                    className="cursor-pointer icon-blue"
                    onClick={loadCardInfo}
                  />
                </div>
              )}
            </div>

            <div className="info-card mt-3">
              <p>
                <span className="font-bold">
                  {t("DashboardDoctor.nationalId")} :{" "}
                </span>{" "}
                {cpsData?.infoPs?.idNatPs ? (
                  cpsData?.infoPs?.idNatPs
                ) : (
                  <span>N/A</span>
                )}
              </p>
              <p>
                <span className="font-bold">
                  {t("DashboardDoctor.cardId")} :{" "}
                </span>{" "}
                {cpsData?.idCard?.idEmet ? (
                  cpsData?.idCard?.idEmet
                ) : (
                  <span>N/A</span>
                )}
              </p>

              <p>
                <span className="font-bold">
                  {t("DashboardDoctor.Name")} :{" "}
                </span>{" "}
                {cpsData?.namePs?.nomPatronymique ? (
                  cpsData?.namePs?.nomPatronymique
                ) : (
                  <span>N/A</span>
                )}
              </p>

              <p>
                <span className="font-bold">
                  {t("DashboardDoctor.FirstName")} :{" "}
                </span>{" "}
                {cpsData?.namePs?.prenomUsuel ? (
                  cpsData?.namePs?.prenomUsuel
                ) : (
                  <span>N/A</span>
                )}
              </p>

              <p>
                <span className="font-bold">
                  {t("DashboardDoctor.IssuedOn")} :{" "}
                </span>{" "}
                {cpsData?.idCard?.dateDebutVal ? (
                  <span>{`${cpsData?.idCard?.dateDebutVal?.slice(
                    6,
                    8
                  )}/${cpsData?.idCard?.dateDebutVal?.slice(
                    4,
                    6
                  )}/${cpsData?.idCard?.dateDebutVal?.slice(0, 4)}`}</span>
                ) : (
                  <span>N/A</span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export {
  PatientOfTheDayWidget,
  TaskOfTheDayWidget,
  AgendaWidget,
  MessagerieWidget,
  SurveyWidget,
  CardReaderWidget,
};
