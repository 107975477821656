import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { getDoneTeleconsultationlist } from "../../api/teleconsultation";
import _ from "lodash";
import CardTeleconsultation from "./CardTeleconsultation";
import Spinner from "../../components/Spinner";
import Pagination from "../../components/Pagination";
import { userAtom } from "../../state/auth";
import "./styles.scss";
type Props = {};

const Teleconsultation = (props: Props) => {
  const { t } = useTranslation();
  const [user] = useAtom(userAtom);
  const [teleconsultations, setTeleconsultations] = useState([]) as any;
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1) as any;
  const [totalPage, setTotalPage] = useState(1);
  const [limit] = useState(10);
  const [totalResult, setTotalResult] = useState(0);
  const [updateConsultationStatus, setUpdateConsultationStatus] =
    useState(false);

  const toggleUpdateConsultationStatus = () =>
    setUpdateConsultationStatus(!updateConsultationStatus);

  const _getDoneTeleconsultationlist = async (page) => {
    try {
      if (user) {
        setLoading(true);
        const response = await getDoneTeleconsultationlist(page, limit);
        setTeleconsultations(response?.teleconsultations?.docs);
        setTotalResult(response?.teleconsultations?.totalDocs);
        setTotalPage(response?.teleconsultations?.totalPages);
        setPage(response?.teleconsultations?.page);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      console.error("Error Fetch specialist :", error);
    }
  };

  useEffect(() => {
    _getDoneTeleconsultationlist(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, updateConsultationStatus]);

  return loading ? (
    <Spinner />
  ) : (
    <div>
      <h1>{t("MyTeleconsultation.MyTeleconsultations")}</h1>
      {teleconsultations && !_.isEmpty(teleconsultations) ? (
        <>
          <div className="card-container mt-10">
            {teleconsultations.map((el, i) => (
              <CardTeleconsultation
                updateConsultationStatus={toggleUpdateConsultationStatus}
                teleconsultationDetails={el}
                key={el._id}
                i={i}
              />
            ))}
          </div>
          <Pagination
            page={page}
            setPage={(i) => setPage(i)}
            totalPage={totalPage}
            totalDocs={totalResult}
          />
        </>
      ) : (
        <div className="flexCenter mt-10">
          <h3>{t("MyTeleconsultation.NoTeleconsultationFound")}</h3>
        </div>
      )}
    </div>
  );
};

export default Teleconsultation;
