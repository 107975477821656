import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAtom } from "jotai";
import classNames from "classnames";
import { useSwipeable } from "react-swipeable";
import _ from "lodash";

import { loadImage } from "../../api/documents";
import { logoutPatient } from "../../api/auth";
import { getUnreadMsg } from "../../api/messages";
import { ActiveRouteAtom } from "../../state/route";
import { userAtom } from "../../state/auth";
import ChatModal from "../SharedChat";

import Icon from "../Icon";
import Button from "../ButtonUI";
import Input from "../../components/Input";
import history from "../../history";
import { screenBreakpoints } from "../../config/theme";

import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";
import { ReactComponent as IconSearch } from "../../assets/icons/icon-search.svg";
import "./styles.scss";

const SideBarDoctor: React.FC = () => {
  const { t } = useTranslation() as any;
  const [user] = useAtom(userAtom) as any;
  const [path, setPath] = useAtom(ActiveRouteAtom);
  const [search, setSearch] = useState(window.location.pathname);
  const [tryToLogout, setTryToLogout] = useState(false);
  const [notification, setNotifications] = useState([]) as any;
  const [chatModal, setChatModal] = useState();
  const [, setScreenWidth] = useState(window.innerWidth);
  const [menuToggleIsActive, setMenuToggleIsActive] = useState(
    window.innerWidth <= screenBreakpoints.md
  );
  const [forceMenuOpen, setForceMenuOpen] = useState(false);

  const swipeEventsHandlers = useSwipeable({
    onSwipedLeft: () => {
      setForceMenuOpen(false);
    },
    onSwipedRight: () => {
      setForceMenuOpen(true);
    },
  });

  useEffect(() => {
    setPath(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const [, setSize] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => setSize(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const handleSearch = () => {
    history.push({
      pathname: "/search",
      state: search,
    });
  };

  const menus = [
    {
      icon: "union",
      text: t("SideBarMenu.DashboardPatient"),
      url: "/",
    },
    {
      icon: "lesSoignants",
      text: t("SideBarMenu.MyDoctors"),
      url: "/myDoctors",
    },
    {
      icon: "monCompte",
      text: t("SideBarMenu.Profile"),
      url: "/mon-compte-patient",
    },
    {
      icon: "fiche-patient",
      text: t("SideBarMenu.MyFolder"),
      url: "/mon-dossier-patient",
      transform: "scale(0.6)",
    },
    {
      icon: "list",
      text: t("SideBarMenu.Annuaire"),
      url: "/annuaire",
    },
    {
      icon: "aide",
      text: t("SideBarMenu.Mysurveys"),
      url: "/survey-patient",
    },
  ];

  const handleLogout = async () => {
    try {
      await logoutPatient();
      setPath("/");
      window.location.href = "/";
      // localStorage.clear();
    } catch (error) {
      window.location.href = "/";
      // localStorage.clear();
      console.error(error);
    }
  };

  const _getUnreadMsg = async () => {
    try {
      const response = await getUnreadMsg();
      setNotifications(_.uniqBy(response, "sentFrom._id"));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setPath(window.location.pathname);

    const handleWindowResize = () => {
      setScreenWidth(window.innerWidth);
      setMenuToggleIsActive(window.innerWidth <= screenBreakpoints.md);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    _getUnreadMsg();
  }, [chatModal]);

  return (
    <>
      <ChatModal
        chatWithId={chatModal}
        showModal={!!chatModal}
        closeChatModal={() => setChatModal(undefined)}
      />
      <div className="sidebar-doctor sidebar">
        <div
          className={`swipe-events-container ${
            !menuToggleIsActive || !forceMenuOpen ? "" : "swipeable-fullscreen"
          }`}
          {...swipeEventsHandlers}
        />

        <div
          className={`sidebar-container absolute transform md:relative md:translate-x-0 transition duration-200 ease-in-out ${
            menuToggleIsActive ? "mobile-sidebar" : "bg-transparent"
          } ${menuToggleIsActive && !forceMenuOpen ? "-translate-x-full" : ""}`}
        >
          <div className="relative">
            {/* <!-- SIDEBAR --> */}
            {/* mobile menu */}
            <div className="flex justify-between md:hidden absolute mobile-menu-burger">
              <button
                className="p-4 focus:outline-none"
                onClick={() => {
                  setForceMenuOpen(!forceMenuOpen);
                }}
              >
                <Icon isIcon={true} name={forceMenuOpen ? "close" : "menu"} />
              </button>
            </div>
            {/* mobile menu END */}

            <div className="flex items-center space-x-3 relative notification">
              <div
                className={classNames(
                  "rounded-xl relative  ",
                  user?.photo ? "" : "bg-blue-200"
                )}
              >
                {user?.photo ? (
                  <img
                    crossOrigin="anonymous"
                    referrerPolicy="origin"
                    alt="profile"
                    src={loadImage(
                      user?._id,
                      "profile",
                      "thumbnail",
                      user?.photo
                    )}
                    className="w-16 h-16 rounded-full shadow-md  object-cover"
                  />
                ) : user?.sexe === "woman" ? (
                  <WomanPatient />
                ) : (
                  <ManPatient />
                )}

                {!_.isEmpty(notification) && (
                  <Icon
                    isIcon={true}
                    className="absolute -right-3 -top-3"
                    name="notif-deconnexion"
                  />
                )}
              </div>
              <div>
                <span className="menu">{t("SideBarMenu.Bonjour")}</span>
                <p className="docter-center capitalize">
                  {user?.firstName} {user?.lastName}
                </p>
              </div>
              {!_.isEmpty(notification) && (
                <div className="w-64 max-h-96 bg-white absolute z-40  top-10 overflow-auto rounded-2xl  notification-list">
                  {notification.map((el) => (
                    <div
                      className=" flex space-x-3 items-center p-2 hover:bg-blue-100 cursor-pointer"
                      onClick={() => setChatModal(el.sentFrom._id)}
                    >
                      <p className="w-14 h-10 rounded-full bg-blue-500 flexCenter text-white text-bold">
                        {el.sentFrom.lastName[0].toUpperCase() +
                          el.sentFrom.firstName[0].toUpperCase()}
                      </p>
                      <p>
                        {el.sentFrom.lastName + " " + el.sentFrom.firstName}{" "}
                        {t("SideBarMenu.sentMessage")}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="relative flex w-full flex-wrap items-stretch mb-3 mt-6 ">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 bg-transparent rounded text-base items-center justify-center w-8 pl-1 py-1">
                <IconSearch />
              </span>
              <Input
                type="text"
                placeholder={t("SideBarMenu.Find")}
                width="w-full"
                onBlur={handleSearch}
                onKeyPress={(e) => {
                  if (e.charCode === 13 || e.code === "Enter") {
                    handleSearch();
                  }
                }}
                onChange={(e) => setSearch(e.target.value)}
                className="rounded-xl px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white text-sm outline-none focus:border-blue-500  border-blue-100 w-full pl-10"
              />
            </div>

            <nav className="mt-3">
              {menus.map((menu) => {
                return (
                  <div className={path === menu.url ? "selected-menu" : ""}>
                    <Link
                      to={menu.url}
                      className="flex items-center space-x-3 md:py-3 -py-1"
                      onClick={() => setPath(menu.url)}
                    >
                      <div className="w-12 h-12 flexCenter">
                        <Icon
                          isIcon={true}
                          name={menu.icon}
                          transform={
                            menu.transform ? menu.transform : "scale(1)"
                          }
                        />
                      </div>
                      <span className="my-auto menu">{menu.text}</span>
                    </Link>
                  </div>
                );
              })}
              <div className={path === "/logout" ? "selected-menu" : ""}>
                <span
                  className="flex items-center space-x-3 py-1 cursor-pointer"
                  onClick={() => setTryToLogout(true)}
                >
                  <div className="w-12 h-12 flexCenter">
                    <img
                      crossOrigin="anonymous"
                      referrerPolicy="origin"
                      src={require("../../assets/images/logout.png").default}
                      width="22"
                      alt="logout"
                    />
                  </div>
                  <span className="my-auto menu">
                    {" "}
                    {t("SideBarMenu.disconnect")}
                  </span>
                </span>
              </div>
              {tryToLogout && (
                <div className="w-full  bg-white mt-1 md:mt-3 shadow p-2 rounded-lg slide-left">
                  <p className="card-subtitle text-center mb-3">
                    {t("SideBarMenu.ConfirmLogout")}
                  </p>
                  <div className="flexCenter space-x-3">
                    <Button
                      status="secondary"
                      width="100px"
                      height="35px"
                      onClick={() => setTryToLogout(false)}
                    >
                      {t("SideBarMenu.Cancel")}
                    </Button>
                    <Button width="100px" height="35px" onClick={handleLogout}>
                      {t("SideBarMenu.Confirm")}
                    </Button>
                  </div>
                </div>
              )}
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBarDoctor;
