import React from "react";
import { useTranslation } from "react-i18next";
import { doctorAddSpecialist } from "../../../../api/doctor";
import Button from "../../../../components/ButtonUI";
import Input from "../../../../components/FloatInput";
import Icon from "../../../../components/Icon";
import makeToast from "../../../../components/Snackbar";

interface Props {
  closeModal: any;
}

const AddDoctor = (props: Props) => {
  const { t } = useTranslation();

  const { closeModal } = props;
  const [invateDoctor, setInvatedDoctor] = React.useState({
    doctorEmail: "",
    name: "",
    lastName: "",
    phone: "",
  });
  const handleChange = (e) => {
    setInvatedDoctor({
      ...invateDoctor,
      [e.target.name]: e.target.value,
    });
  };
  const handleAddNewDoctor = async () => {
    try {
      if (!invateDoctor.doctorEmail)
        return makeToast("warning", t("Specialist.FillEmail"));
      if (!invateDoctor.name)
        return makeToast("warning", t("Specialist.FillFirstName"));
      if (!invateDoctor.lastName)
        return makeToast("warning", t("Specialist.FillLastName"));

      const response = await doctorAddSpecialist(invateDoctor);

      if (response?.status === 200)
        makeToast("success", t("Specialist.SuccessSend"));
      if (response?.status === 400)
        makeToast("error", t("Specialist.ExistDoctor"));
      closeModal();
    } catch (error) {
      return makeToast("Warning", t("Specialist.Error"));
    }
  };
  return (
    <div className="w-80">
      <div className="flex mb-3 justify-end w-full  cursor-pointer">
        <Icon isIcon={true} name="close" onClick={closeModal} />
      </div>

      <h2 className="m-auto text-center">{t("Specialist.newSpecialist")}</h2>
      <div className="p-10">
        <Input
          id="doctorEmail"
          placeholder={t("Specialist.email")}
          labelText={t("Specialist.email")}
          type="text"
          name="doctorEmail"
          value={invateDoctor.doctorEmail}
          onChange={handleChange}
          className="w-full mb-2.5 rounded-lg border-gray-200 outline-none"
          inlineLabel={false}
          // onfocus={true}
        />

        <Input
          id="doctorName"
          placeholder={t("Specialist.FirstName")}
          labelText={t("Specialist.FirstName")}
          type="text"
          name="name"
          value={invateDoctor.name}
          onChange={handleChange}
          className="w-full mb-2.5 rounded-lg border-gray-200 outline-none"
          inlineLabel={false}
          // onfocus={true}
        />

        <Input
          id="doctorLastName"
          placeholder={t("Specialist.LastName")}
          labelText={t("Specialist.LastName")}
          type="text"
          name="lastName"
          value={invateDoctor.lastName}
          onChange={handleChange}
          className="w-full mb-2.5 rounded-lg border-gray-200 outline-none"
          inlineLabel={false}
          // onfocus={true}
        />
        <Input
          id="DoctorPhone"
          placeholder={t("Specialist.PhoneNumber")}
          labelText={t("Specialist.PhoneNumber")}
          type="text"
          name="phone"
          value={invateDoctor.phone}
          onChange={handleChange}
          className="w-full mb-2.5 rounded-lg border-gray-200 outline-none"
          inlineLabel={false}
          // onfocus={true}
        />
        <div className="flexCenter">
          <Button width="150px" height="40px" onClick={handleAddNewDoctor}>
            {t("Specialist.Add")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddDoctor;
