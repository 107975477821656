export const AmericanExpress = ({ style }) => (
  <img
    crossOrigin="anonymous"
    referrerPolicy="origin"
    style={style}
    src={require("../../assets/images/cards/AmericanExpress.svg").default}
    alt="AmericanExpress-logo"
  />
);
export const ApplePay = ({ style }) => (
  <img
    crossOrigin="anonymous"
    referrerPolicy="origin"
    style={style}
    src={require("../../assets/images/cards/ApplePay.svg").default}
    alt="ApplePay-logo"
  />
);
export const DinnersClub = ({ style }) => (
  <img
    crossOrigin="anonymous"
    referrerPolicy="origin"
    style={style}
    src={require("../../assets/images/cards/DinnersClub.svg").default}
    alt="DinnersClub-logo"
  />
);
export const Discover = ({ style }) => (
  <img
    crossOrigin="anonymous"
    referrerPolicy="origin"
    style={style}
    src={require("../../assets/images/cards/Discover.svg").default}
    alt="Discover-logo"
  />
);
export const Gpay = ({ style }) => (
  <img
    crossOrigin="anonymous"
    referrerPolicy="origin"
    style={style}
    src={require("../../assets/images/cards/Gpay.svg").default}
    alt="Gpay-logo"
  />
);
export const JCB = ({ style }) => (
  <img
    crossOrigin="anonymous"
    referrerPolicy="origin"
    style={style}
    src={require("../../assets/images/cards/JCB.png").default}
    alt="JCB-logo"
  />
);
export const Maestro = ({ style }) => (
  <img
    crossOrigin="anonymous"
    referrerPolicy="origin"
    style={style}
    src={require("../../assets/images/cards/Maestro.svg").default}
    alt="Maestro-logo"
  />
);
export const MasterCard = ({ style }) => (
  <img
    crossOrigin="anonymous"
    referrerPolicy="origin"
    style={style}
    src={require("../../assets/images/cards/MasterCard.svg").default}
    alt="MasterCard-logo"
  />
);
export const Visa = ({ style }) => (
  <img
    crossOrigin="anonymous"
    referrerPolicy="origin"
    style={style}
    src={require("../../assets/images/cards/Visa.svg").default}
    alt="Visa-logo"
  />
);

export const PlayStore = ({ style }) => (
  <img
    crossOrigin="anonymous"
    referrerPolicy="origin"
    style={style}
    src={require("../../assets/images/cards/google-play-store.svg").default}
    alt="Playstore-logo"
  />
);

export const AllCards = [
  Visa,
  MasterCard,
  AmericanExpress,
  Maestro,
  Gpay,
  ApplePay,
  DinnersClub,
  Discover,
  JCB,
];
