import { useState, useEffect } from "react";
import _ from "lodash";
import AutoComplete from "../../components/AutoComplete";
import Button from "../../components/ButtonUI";
import { me, updatePatient } from "../../api/patient";
import makeToast from "../../components/Snackbar";
import { useTranslation } from "react-i18next";
interface Props {
  closeModal: Function;
}

const ChnageDoctorModal = (props: Props) => {
  const { t } = useTranslation() as any;
  const { closeModal } = props;
  const [user, setUser] = useState({}) as any;
  const [loading, setLoading] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const filtredDoctors: any = _.uniqBy(user?.doctors, "_id");
  async function _getMe() {
    try {
      setLoading(true);
      const response = await me();
      setUser(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  async function handleClick() {
    try {
      if (selectedDoctor) {
        setLoading(true);
        const response = await updatePatient({
          doctorId: user?.doctors?.find(
            (doctor) =>
              `${doctor.firstName} ${doctor.lastName}` === selectedDoctor
          )._id,
        });
        makeToast("success", t("DashboardPatient.SuccessChangeDoctor"));
        closeModal();
        setUser(response);
        setLoading(false);
      } else {
        makeToast("warning", t("DashboardPatient.SelectNewDoctor"));
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  useEffect(() => {
    _getMe();
  }, []);
  return loading ? (
    <div className="flexCenter modal-change-doctors ">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
    </div>
  ) : (
    <div className="p-10 modal-change-doctors">
      <h3> {t("DashboardPatient.ConfirmChangeAttendingDoctor")}</h3>
      <AutoComplete
        options={filtredDoctors.map(
          (doctor) => `${doctor.firstName} ${doctor.lastName}` || []
        )}
        value={selectedDoctor}
        onChange={setSelectedDoctor}
        className="my-10 mx-auto"
        placeholder={t("DashboardPatient.Doctor")}
        prefix="impression"
      />
      <div className="flexCenter">
        <Button onClick={handleClick} width="200px" height="50px">
          {t("DashboardPatient.Confirm")}
        </Button>
      </div>
    </div>
  );
};

export default ChnageDoctorModal;
