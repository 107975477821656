import axios from "axios";
import {
  GET_SURVEY_BY_PATIENTID,
  GET_ALL_SURVEY_DOCTOR,
  DOCTOR_ASSIGN_SURVEY,
  PATIENT_RESPONSE_SURVEY,
  DOWNLOAD_SURVEY_RESULT,
  DOCTOR_GET_ALL_SURVEYID,
  DOCTOR_CREATE_SURVEY,
  DOCTOR_UPDATE_SURVEY,
  DOCTOR_GET_SURVEY,
  GET_ALL_DOCTOR_SURVEY,
  SURVEY_GET_STATS,
} from "../config/api";

// export const getSurveyByPatientId = async (payload) => {
//   try {
//     const res = await axios.post(GET_SURVEY_BY_PATIENTID, payload);
//     return res.data;
//   } catch (error) {
//     return Promise.reject(error);
//   }
// };

export const getAllDoctorSurvey = async (docId) => {
  try {
    const res = await axios.get(`${GET_ALL_DOCTOR_SURVEY}/${docId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getSurveysStats = async (surveyId) => {
  try {
    const res = await axios.get(`${SURVEY_GET_STATS}/${surveyId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// export const doctorAssignSurvey = async (payload) => {
//   try {
//     const res = await axios.post(`${DOCTOR_ASSIGN_SURVEY}`, payload, {
//       withCredentials: true,
//     });
//     return res.data;
//   } catch (error) {
//     return Promise.reject(error);
//   }
// };
export const createForm = async (values) => {
  try {
    const res = await axios.post(DOCTOR_CREATE_SURVEY, values, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateForm = async (values, formId) => {
  try {
    const res = await axios.post(`${DOCTOR_UPDATE_SURVEY}/${formId}`, values, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getSurveyById = async (formId) => {
  try {
    const res = await axios.get(`${DOCTOR_GET_SURVEY}/${formId}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSurveyByPatientId = async (payload) => {
  try {
    const res = await axios.post(GET_SURVEY_BY_PATIENTID, payload);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllSurveyDoctor = async () => {
  try {
    const res = await axios.get(GET_ALL_SURVEY_DOCTOR);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const doctorAssignSurvey = async (payload) => {
  try {
    const res = await axios.post(`${DOCTOR_ASSIGN_SURVEY}`, payload, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const patientResponseSurvey = async (surveyId, payload) => {
  try {
    const res = await axios.post(
      `${PATIENT_RESPONSE_SURVEY}/${surveyId}`,
      payload,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const downloadExcel = async (surveyId) => {
  try {
    const res = await axios.get(`${DOWNLOAD_SURVEY_RESULT}/${surveyId}`, {
      withCredentials: true,
      responseType: "blob",
    });
    if (res.data.status === "NO_RESPONSE") return "NO_RESPONSE";
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllPatientBySurveyId = async (surveyId) => {
  try {
    const res = await axios.get(`${DOCTOR_GET_ALL_SURVEYID}/${surveyId}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
