import axios from "axios";
import {
  GET_CONFIG_TELECONSULTATION,
  SAVE_CALLER_SOCKET_ID,
  GET_TELECONSULTATION_HISTORY,
  TELECONSULTATION_CANCEL_BILLING,
  TELECONSULTATION_REFUND_BILLING,
  TELECONSULTATION_INIT_BILLING,
  END_TELECONSULTATION,
} from "../config/api";

export const getConfigTeleconsultation = async () => {
  try {
    const res = await axios.get(GET_CONFIG_TELECONSULTATION, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const saveCallerSocketId = async (payload) => {
  try {
    const res = await axios.post(SAVE_CALLER_SOCKET_ID, payload, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getDoneTeleconsultationlist = async (page, limit) => {
  try {
    const res = await axios.get(
      `${GET_TELECONSULTATION_HISTORY}?page=${page}&limit=${limit}`,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const cancelBillingTeleconsultationAsync = async (
  id,
  thirdPartyPayer
) => {
  try {
    const res = await axios.post(
      `${TELECONSULTATION_CANCEL_BILLING}/${id}`,
      { thirdPartyPayer },
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const refundTeleconsultationAsync = async (id) => {
  try {
    const res = await axios.post(
      `${TELECONSULTATION_REFUND_BILLING}/${id}`,
      {},
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const billTeleconsultationAsync = async (id) => {
  try {
    const res = await axios.post(
      `${TELECONSULTATION_INIT_BILLING}/${id}`,
      {},
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const endTeleconsultation = async (id) => {
  try {
    const res = await axios.post(
      `${END_TELECONSULTATION}/${id}`,
      {},
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
