import React, { useState, useEffect } from "react";
import _ from "lodash";
import { ReactComponent as IconThreeDot } from "../../assets/icons/icon-troisPoints.svg";
import MeetingDetailModal from "./Modals/MeetingDetailModal";
import Modal from "../../components/ModalUI/index";
import MoveMeetmodal from "./Modals/MoveMeetModal";
import { getUpcomingConsultation } from "../../api/patient";
import moment from "../../helper/moment";

const PreviewAgenda = ({ winWidth }) => {
  const [listBooking, setListBooking] = useState<any>();

  const [meetingDetailsModa, setMeetingDetailsModa] = useState(false);
  const [MoveMeetModal, setMoveMeetModal] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [selectedMeet, setSelectMeet] = useState();

  const today = moment().endOf("day");

  const toggleMeetingDetailsModa = (data) => {
    setMeetingDetailsModa(!meetingDetailsModa);
    setSelectMeet(data);
  };

  const toggleMoveMeetModal = () => {
    setMeetingDetailsModa(false);
    setMoveMeetModal(!MoveMeetModal);
  };

  const _getUpcomingConsultation = async () => {
    try {
      const result = await getUpcomingConsultation();

      if (result.consultations) {
        const groups = result.consultations.reduce((groups, el) => {
          const date = moment(el.dateConsultation).format("DD/MM/YYYY");
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(el);
          return groups;
        }, {});

        // Edit: to add it in the array format instead
        const groupArrays = Object.keys(groups).map((date) => {
          return {
            date,
            data: groups[date],
          };
        });
        setListBooking(groupArrays);
      }
    } catch (error) {}
  };

  useEffect(() => {
    _getUpcomingConsultation();
  }, [refreshData]);

  return (
    <>
      <Modal
        showModal={meetingDetailsModa}
        closeModal={toggleMeetingDetailsModa}
      >
        <MeetingDetailModal
          closeModal={toggleMeetingDetailsModa}
          toggleMoveMeetModal={toggleMoveMeetModal}
          meetDetails={selectedMeet}
        />
      </Modal>

      <Modal showModal={MoveMeetModal} closeModal={toggleMoveMeetModal}>
        <MoveMeetmodal
          closeModal={toggleMoveMeetModal}
          meetDetails={selectedMeet}
          winWidth={winWidth}
          refreshData={() => setRefreshData(!refreshData)}
        />
      </Modal>
      {_.isArray(listBooking) && !_.isEmpty(listBooking) ? (
        <div className="bg-white w-full p-3">
          {listBooking
            .sort(
              (a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf()
            )
            .map(({ date, data }) => (
              <div>
                <h2>
                  {moment(date) < today
                    ? "Aujourd'hui"
                    : moment(date, "DD/MM/YYYY").format("DD MMM.")}
                </h2>
                {data
                  .sort(
                    (a, b) =>
                      new Date(a.dateConsultation).valueOf() -
                      new Date(b.dateConsultation).valueOf()
                  )
                  .map((el) => (
                    <div className="flex my-3">
                      <span className="courant-text mr-5">
                        {moment(el.dateConsultation).format("HH:mm")}
                      </span>
                      <div className="w-full">
                        <div className="flex justify-between w-full">
                          <span className="courant-text font-bold">
                            {el.doctorId?.firstName} {el.doctorId?.lastName}
                          </span>
                          <IconThreeDot
                            className="cursor-pointer hover:bg-gray-200 hover:"
                            onClick={() => toggleMeetingDetailsModa(el)}
                          />
                        </div>
                        <div className="box max-w-max">{el.motif}</div>
                      </div>
                    </div>
                  ))}
                <hr className="my-5" />
              </div>
            ))}
        </div>
      ) : (
        <div className="bg-white w-full p-3 rounded-2xl mt-4">
          <span className="card-subtitle">
            La liste de rendez-vous est vide
          </span>
        </div>
      )}
    </>
  );
};

PreviewAgenda.defaultProps = {};

export default PreviewAgenda;
