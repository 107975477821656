import { useState } from "react";
import Input from "../../../Input";
import Button from "../../../ButtonUI";
import { DMP } from "../../../../api/devbox";
import makeToast from "../../../Snackbar";
type Props = {
  closeModal;
  author;
  matriculeINS;
  documentUuid;
  contentType;
};

const EditVisibilityModal = (props: Props) => {
  const { closeModal, author, matriculeINS, documentUuid, contentType } = props;
  const [confidentialityCode, setConfidentialityCode] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleUpdateVisibility = async () => {
    try {
      setLoading(true);
      await DMP.td33updateConfidentiality({
        context: {
          author: author,
          confidentialityCode: null,
          modeAcces: {
            acces: "NORMAL",
            raison: null,
          },
        },
        request: {
          matriculeINS,
          documentUuid,
          confidentialities: ["N", ...confidentialityCode],
          contentType,
        },
      });
      makeToast("success", "opération effectuée avec succès");
      setLoading(false);

      closeModal();
    } catch (error) {
      setLoading(false);
      makeToast(
        "error",
        "Quelque chose s'est mal passé. Veuillez réessayer plus tard"
      );
    }
  };

  return (
    <div className="px-7">
      <h3 className="text-center mb-7">Modifier la visibilité du document</h3>
      <p className="font-bold mb-3">Visibililé :</p>
      <Input
        name="visibility"
        type="checkbox"
        onChange={() => {
          confidentialityCode.includes("INVISIBLE_PATIENT")
            ? setConfidentialityCode((x) =>
                x.filter((el) => el !== "INVISIBLE_PATIENT")
              )
            : setConfidentialityCode([
                ...confidentialityCode,
                "INVISIBLE_PATIENT",
              ]);
        }}
        checked={confidentialityCode.includes("INVISIBLE_PATIENT")}
        value="treating"
        labelText="Non visible par le patient"
        labelProps={{ className: "check" }}
        className="my-2"
      />
      <Input
        name="visibility"
        type="checkbox"
        value="treating"
        onChange={() => {
          confidentialityCode.includes("MASQUE_PS")
            ? setConfidentialityCode((x) =>
                x.filter((el) => el !== "MASQUE_PS")
              )
            : setConfidentialityCode([...confidentialityCode, "MASQUE_PS"]);
        }}
        checked={confidentialityCode.includes("MASQUE_PS")}
        labelText="Masqué aux professionnels de santé"
        labelProps={{ className: "check" }}
        className="my-2"
      />
      <Input
        name="visibility"
        type="checkbox"
        value="treating"
        labelText="Non visible pour les représentants légaux"
        onChange={() => {
          confidentialityCode.includes("INVISIBLE_REPRESENTANTS_LEGAUX")
            ? setConfidentialityCode((x) =>
                x.filter((el) => el !== "INVISIBLE_REPRESENTANTS_LEGAUX")
              )
            : setConfidentialityCode([
                ...confidentialityCode,
                "INVISIBLE_REPRESENTANTS_LEGAUX",
              ]);
        }}
        checked={confidentialityCode.includes("INVISIBLE_REPRESENTANTS_LEGAUX")}
        labelProps={{ className: "check" }}
        className="my-2"
      />
      <div className="flexCenter gap-10 my-4">
        <Button
          status="secondary"
          width="175px"
          height="35px"
          onClick={closeModal}
        >
          Anuuler
        </Button>
        <Button
          status="primary"
          width="175px"
          height="35px"
          onClick={handleUpdateVisibility}
          disabled={loading}
        >
          <div className="relative">
            {loading && (
              <div className="absolute h-full w-full flexCenter ">
                <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-purple-500"></div>
              </div>
            )}
            Confirmer
          </div>
        </Button>
      </div>
    </div>
  );
};

export default EditVisibilityModal;
