import React, { useEffect, useState } from "react";
import { loadImage } from "../../api/documents";
import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";
import Button from "../ButtonUI";

interface IProps {
  imageData: object | any;
  closeModal: Function;
  patientId: string;
}

const ImageModal: React.FC<IProps> = ({ imageData, closeModal, patientId }) => {
  const [parsedImage, setParsedImage] = useState<any>({});
  const [, setLoading] = useState(true);

  const parseImage = (url, patientId, type) => ({
    original: loadImage(patientId, type, "lossless", url),
  });

  useEffect(() => {
    if (patientId) {
      setParsedImage(parseImage(imageData.url, patientId, imageData.type));
      if (parsedImage) {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageData]);

  return (
    <div className="relative">
      {parsedImage.original ? (
        <>
          <div className="absolute right-0 mt-4 mr-4">
            <Button status="icon-secondary" onClick={() => closeModal()}>
              <Close />
            </Button>
          </div>
          <img
            crossOrigin="anonymous"
            referrerPolicy="origin"
            src={parsedImage.original}
            alt="katomi"
            loading="lazy"
            className="rounded-2xl"
          />
        </>
      ) : (
        <>
          <div className="w-96 py-10">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
          </div>
        </>
      )}
    </div>
  );
};

export default ImageModal;
