export const Agenda = {
  fr: {
    AvailableSlot: "Créneau disponible",
    btnAddEventTitle: "+ Nouvel événement",
    confirmDelete: "Voulez-vous supprimer cette indisponibilité ?",
    delete: "Supprimer",
    timeSlot: "Plages horaires",
    Presential: "Présentiel",
    Teleconsultation: "Téléconsultation",
    Acts: "Actes",
    soon: "Cette fonctionnalité sera prochainement disponible",
    SuccessUpdate: "La configuration de votre agenda a été bien mise à jour",
    ErrorTryLater:
      "Quelque chose s'est mal passé. Veuillez réessayer plus tard",
    ResetWeek: "Réinitialiser la semaine",
    ActSlots: "Créneaux pour mes actes :",
    Reset: "Réinitialiser",
    Save: "Enregistrer",
    Confirm: "Confirmer",
    Cancel: "Annuler",
    ActDuration: "Durée d'actes :",
    ConsultationDuration: "Durée des rendez-vous :",
    ConfirmText1: "Vous êtes sur le point de réinitialiser votre agenda",
    ConfirmText2: "Vos créneaux réservés ne seront pas supprimés",
    OpeningOffice: "Ouverture du cabinet",
    to: "à :",
    from: "de",
    RangeTeleconsultation: "Plage de téléconsultation :",
    NewTeleconsultationSlot: "Nouveau créneau de téléconsultation",
    NewPresentielSlot: "Nouveau créneau de consultation",
    DurationOfTeleconsultations: "Durée des téléconsultations :",

    NewEvent: "Nouvel événement",
    meeting: "Rendez-vous",
    unavailability: "Indisponibilité",
    leave: "Congés",
    ErrorConfigAgenda: "Merci d'avoir configurer votre agenda",
    availableSoon: "Cette fonctionnalité sera prochainement disponible",

    Cabinet: "Cabinet",
    ErrorActPatient: "Vous devez choisir un patient",
    ErrorActReason: "Motif d'acte est vide",
    ErrorActDate: "Vous devez choisir la date de l'acte",
    ErrorActDuration: "Vous devez choisir la durée de l'acte",
    SuccessAddAct: "Nouvel acte a été ajouté avec succès",
    CheckConsultationDate: "Veuillez vérifier la date de votre consultation",
    SelectPatient: "Séléctionner un patient",
    MotifAct: "Motif de l'acte",
    MotifConsultation: "Motif de la consultation",

    DateAct: "Date de l'acte (JJ/MM/AAAA)",
    ScheduleAct: "Horaire de l'acte :",
    AddComment: "Ajouter un commentaire",

    FillLeaveDate: "Veuillez remplir votre date congé",
    StartDateLeave: "Date et Heure de début de congé",
    EndDateLeave: "Date et Heure de fin de congé ",
    DateLeave: "Date début de congé (JJ/MM/AAAA)",
    EndLeave: "Date fin de congé (JJ/MM/AAAA)",

    fillDateIndis: "veuillez remplir votre date d'indisponibilité",
    ConfirmCancelMeeting:
      "Vous avez des rendez vous durant cette plage horaire. Voulez-vous les Annuler ?",

    DateAndStartUnavailability: "Date et Heure de début de l'indisponibilité ",
    StartDatUnavailability: "Date début de l'indisponibilité (JJ/MM/AAAA)",
    DateAndEndUnavailability: " Date et Heure de fin de l'indisponibilité",
    EndDatUnavailability: "Date fin de l'indisponibilité (JJ/MM/AAAA)",

    meetingMedicalon: "Vous avez pris un rendez-vous médical pour le :",
    ErrorFillDateConsultation: "Vous devez choisir la date de consultation",
    SuccessTakeMeet: "Rendez-vous pris avec succès.",
    CabinetNameEmpty: "Nom du cabinet est vide",
    emptyMotif: "Motif de consultation est vide",
    SuccessAddTeleconsultation:
      "L'ajout de la téléconsultation a été effectué avec succès",

    updateSlot: "modifier le créneau",
    ConfigAgenda:
      "Configurez votre agenda afin de pouvoir ajouter un rendez-vous",
    NoTS: "Pas de créneau disponible",
    SlotNotAvailble: "Ce créneau horaire n'est pas disponible",

    teleconsultationSlotRequire:
      "Vous devez sélectionner un créneau de téléconsultation",
    consultationSlotRequire:
      "vous devez sélectionner un créneau de consultation",
    MoreTS: "Afficher plus d’horaires",
    SuccessOperation: "opération effectuée avec succès",
  },
  en: {
    SuccessOperation: "Operation completed successfully",
    AvailableSlot: "Slot available",
    btnAddEventTitle: "+ New Event",
    confirmDelete: "Do you want to delete this unavailability",
    delete: "Delete",
    timeSlot: "Time slots",
    Presential: "Face-to-face",
    Teleconsultation: "Teleconsultation",
    Acts: "Acts",
    soon: "This feature will be available soon",
    SuccessUpdate: "The configuration of your agenda has been properly updated",
    ErrorTryLater: "Something went wrong. Please try again later",
    ResetWeek: "Reset the week",
    ActSlots: "Slots for my acts :",
    Reset: "Reset",
    Save: "Save",
    Confirm: "Confirm",
    Cancel: "Cancel",
    ActDuration: "Duration of acts :",
    ConsultationDuration: "Consultation duration :",
    ConfirmText1: "You are about to reset your calendar",
    ConfirmText2: "All your reservations will not be deleted",
    OpeningOffice: "Opening of the office",
    to: "to",
    from: "from",
    RangeTeleconsultation: "Teleconsultation range :",
    NewTeleconsultationSlot: "New teleconsultation slot",
    NewPresentielSlot: "New consultation slot",
    DurationOfTeleconsultations: "Duration of teleconsultations :",

    NewEvent: "New event",
    meeting: "Meeting",
    unavailability: "Unavailability",
    leave: "Leaves",
    ErrorConfigAgenda: "Thank you for setting up your calendar",
    availableSoon: "This feature will be available soon",

    Cabinet: "Cabinet",
    ErrorActPatient: "You must choose a patient",
    ErrorActReason: "Reason for act is empty",
    ErrorActDate: "You must choose the date of the act",
    ErrorActDuration: "You must choose the duration of the act",
    SuccessAddAct: "New act successfully added",
    CheckConsultationDate: "Please check the date of your consultation",
    SelectPatient: "Select a patient",
    MotifAct: "Reason for the act",
    DateAct: "Date of the act (DD/MM/YYYY)",
    ScheduleAct: "Schedule of the act :",
    AddComment: "Add a comment",

    FillLeaveDate: "Please fill in your leave date",
    StartDateLeave: "Start date and time of leave",
    EndDateLeave: "Date and time of end of leave",
    DateLeave: "Date of the leave (DD/MM/YYYY)",
    EndLeave: "End date of leave(DD/MM/YYYY)",

    fillDateIndis: "Please fill in your date of unavailability",

    ConfirmCancelMeeting:
      "You have a consultation during this time slot. Do you want to Cancel them?",

    DateAndStartUnavailability: "Date and start time of the unavailability",
    StartDatUnavailability: "Start date of the unavailability (DD/MM/YYYY)",
    DateAndEndUnavailability: "Date and time of end of unavailability",
    EndDatUnavailability: "End date of unavailability (DD/MM/YYYY)",

    MotifConsultation: "Reason for the consultation",
    meetingMedicalon: "You have booked a medical consultation for :",

    ErrorFillDateConsultation: "You must choose the date of consultation",
    SuccessTakeMeet: "Consultation made with success.",
    CabinetNameEmpty: "Nom du cabinet est vide",

    emptyMotif: "Consultation reason is empty",

    SuccessAddTeleconsultation: "Teleconsultation was successfully added",
    updateSlot: "update time slot",
    ConfigAgenda: "Set your calendar so you can add meeting",
    NoTS: "No slot available",

    teleconsultationSlotRequire: "You must select a teleconsultation slot",
    consultationSlotRequire: "you must select a consultation slot",

    SlotNotAvailble: "This time slot is not available",
    MoreTS: "Show more schedules",
  },
};
