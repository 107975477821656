import { useState } from "react";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { updateExtensions } from "../../api/extensions";
import { userAtom } from "../../state/auth";
import Modal from "../../components/ModalUI";
import Button from "../../components/ButtonUI";
import Spinner from "../../components/Spinner";
import extensionsList from "../../config/constants/extensionsList.json";
import "./styles.scss";

type Props = {};

const Extension = (props: Props) => {
  const { t, i18n } = useTranslation();
  const [user, setUser] = useAtom(userAtom) as any;
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedExtension, setSelectedExtension] = useState() as any;

  const handleExtension = async () => {
    try {
      setLoading(true);
      const extensions = await updateExtensions({
        ...selectedExtension,
        active: !user?.extensions?.find(
          (ext) => ext.id === selectedExtension.id
        )?.active,
      });
      setUser({ ...user, extensions });
      setShowConfirmModal(false);
      setLoading(false);
    } catch (error) {}
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="h-full w-full">
      <h1>{t("Extension.Extension")}</h1>
      {_.isEmpty(extensionsList) ? (
        <h3 className="text-center ">{t("Extension.EmptyExtension")}</h3>
      ) : (
        <div className="mt-10 flex flex-wrap gap-5">
          {extensionsList.map((el) => (
            <div className="extension-card flex items-center rounded-xl bg-white p-2">
              <div className="w-2/12 ">
                {el.iconName && (
                  <img
                    crossOrigin="anonymous"
                    referrerPolicy="origin"
                    src={
                      require(`../../assets/extensions/${el.iconName}`).default
                    }
                    width={60}
                    alt={el.name}
                  />
                )}
              </div>
              <div className="w-8/12">
                <h3 className="">
                  {i18n.language.includes("en") ? el.nameEn : el.name}
                </h3>
                <h3 className="text-xs ">
                  {i18n.language.includes("en") ? el.desEn : el.des}
                </h3>
              </div>
              <div className="w-2/12 flexCenter">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={
                      user?.extensions?.find((ext) => ext.id === el.id)?.active
                    }
                    onChange={(e) => {
                      setSelectedExtension(el);
                      setShowConfirmModal(true);
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          ))}
        </div>
      )}
      <Modal
        showModal={showConfirmModal}
        closeModal={() => setShowConfirmModal(false)}
      >
        <div className="w-96 p-5">
          <p>
            {user?.extensions?.find((ext) => ext.id === selectedExtension?.id)
              ?.active
              ? t("Extension.ConfirmDisable")
              : t("Extension.ConfirmEnable")}
          </p>
          <Button
            className="block m-auto mt-5"
            width="150px"
            height="40px"
            onClick={handleExtension}
            disabled={loading}
          >
            {t("Extension.Confirm")}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Extension;
