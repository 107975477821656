/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
/* eslint-disable array-callback-return */
import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import HorizontalScroll from "react-scroll-horizontal";
import { Editor } from "react-draft-wysiwyg";
import SelectComp from "../../components/Select";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import ReactHtmlParser from "react-html-parser";
import { useAtom } from "jotai";
import _ from "lodash";
import {
  editConsultationById,
  getConsultationById,
  updateConsultationDMP,
} from "../../api/consultation";
import { CPS, DMP } from "../../api/devbox/";
import DEFAULT_TEMPALTE from "../../components/PreviewRapport/defaultTemplate.json";
import { generatePDFBase64 } from "../../components/TemplatePDFCreator/Helper/generatePDFbase64";
import { loadImage } from "../../api/documents";
import moment from "../../helper/moment";
import EndReportModal from "./EndReportModal";
import Modal from "../../components/ModalUI";
import Button from "../../components/ButtonUI";
import Spinner from "../../components/Spinner";
import makeToast from "../../components/Snackbar";
import Icon from "../../components/Icon";
import PrescritionModal from "../../components/PrescriptionModal";
import { previousState } from "../../state/previousPageState";
import { ReactComponent as IconFolder } from "../../assets/icons/icon-piece-jointe-blanc.svg";
import "./styles.scss";
import { getAllPdfOfDoctors } from "../../api/pdfTemalpte";
import { userAtom } from "../../state/auth";
import { useTranslation } from "react-i18next";

interface Props {}

const Report = (props: Props) => {
  const { t, i18n } = useTranslation() as any;
  const [loading, setLoading] = useState(false);
  const [modalNote, setModalNote] = useState(false);
  const [qualified, setQualified] = useState(false);
  const [modalDocument, setModalDocument] = useState(false);
  const [templateOption, setTemplateOption] = useState([]) as any;
  const [consultationDetails, setconsultationDetails] = useState<any>();
  const [autoSaveAt, setAutoSaveAt] = useState(Date.now());
  const [endReportModal, setEndReportModal] = useState(false);
  const [prescritionModal, setPrescritionModal] = useState(false);
  const [contentState] = useState("");
  const params: any = useParams();
  const { idConsultation } = params;
  const history = useHistory();
  const [editorState, setEditorState] = useState<any>("");
  const [previousStates] = useAtom(previousState) as any;
  const [data, setData] = useState([]);
  const [user] = useAtom(userAtom);
  const [selectedTemplate, setSelectedTemplate] = useState<any>();
  const [, setDMPLoading] = useState(false);
  const [patientData] = useState<any>();

  const [author, setAuthor] = useState() as any;
  const [checkCPSCard, setcheckCPSCard] = useState(false);
  const [patientDMPData, setPatientDMPData] = useState() as any;
  const [defaultTemplate] = useState({ ...DEFAULT_TEMPALTE }) as any;
  const [modalSaveToDMP, setModalSaveToDMP] = useState(false);

  const statusLabels = {
    ["IdProvisional"]: "Identité provisoire",
    ["IdRecovered"]: "Identité récuperée",
    ["IdValidated"]: "Identité validée",
    ["IdQualified"]: "Identité qualifiée",
  };

  const GetAuthorData = async () => {
    try {
      setDMPLoading(true);
      const responseReaderInfo = await CPS.readerInfo(
        false,
        i18n.language.includes("en")
      );
      setcheckCPSCard(responseReaderInfo);
      const doctorData = await CPS.readCard();

      setAuthor({
        internalId: doctorData?.infoPs?.idNatPs,
        nom: doctorData?.namePs?.nomPatronymique,
        prenom: doctorData?.namePs?.prenomUsuel,
        secteurActivite:
          doctorData?.activitiesPs[`${doctorData?.selectedActivity}`]
            ?.structSecActRpps,
        structureSante: {
          idNational:
            doctorData?.activitiesPs[`${doctorData?.selectedActivity}`]
              ?.structIdNat,
          nom: doctorData?.activitiesPs[`${doctorData?.selectedActivity}`]
            ?.structRaisSoc,
        },
        specialite: "G15_10/SM26",
      });
      setDMPLoading(false);
    } catch (error) {
      setDMPLoading(false);
      console.log(error);
    }
  };

  const fetchPatientData = async () => {
    const request = {
      context: {
        author,
        insNirAutorite: "TEST",
        modeAcces: {
          acces: "NORMAL",
        },
      },
      request: {
        ins:
          patientData?.INS?.responseINS?.insActif?.valeur +
          patientData?.INS?.responseINS?.insActif?.cle,
      },
    };

    const data = await DMP.td02Exist(request);

    setPatientDMPData(data);
  };
  const _shareFolderWithDMP = async () => {
    if (!editorState)
      return makeToast("warning", t("Consultation.EmptyReport"));
    setLoading(true);
    try {
      const rdv = await editConsultationById(idConsultation, {
        rapportDone: true,
        rapport: {
          textRapport: draftToHtml(
            convertToRaw(editorState.getCurrentContent())
          ),
          templatePdfId: selectedTemplate,
        },
      });

      const contentBlock = htmlToDraft(
        ReactHtmlParser(String(rdv?.consultation?.rapport?.textRapport))[0]
      );

      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );

      const pdfb64 = (await generatePDFBase64(
        selectedTemplate?.listData || defaultTemplate?.listData,
        EditorState.createWithContent(contentState),
        rdv?.consultation,
        false,
        user
      )) as any;

      const responseDMpFile = await AddNewDMPFile(
        pdfb64?.replace(/^data:application\/(pdf);base64,/, ""),
        "urn:ihe:iti:xds-sd:pdf:2008"
      );

      await updateConsultationDMP({
        consultationId: rdv?._id,
        dmp: responseDMpFile,
      });

      history.push(previousStates?.filePatientTasks ? "/mes-patients" : "/");
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const AddNewDMPFile = async (content, format) => {
    try {
      if (
        !checkCPSCard ||
        _.isEmpty(author) ||
        _.isEmpty(patientDMPData?.patient)
      )
        return;
      setDMPLoading(true);
      const patient = {
        ins:
          patientData?.INS?.responseINS?.insActif?.valeur +
          patientData?.INS?.responseINS?.insActif?.cle,
        internalId: patientData?._id,
        prenom:
          patientData?.INS?.responseINS.traitsIdentite?.prenomActeNaissance,
        insAutorite: patientDMPData?.patient?.insAutorite,
        nomUsuel: patientData?.INS?.responseINS?.traitsIdentite?.nomFamille,
        dateDeNaissance:
          patientData?.INS?.responseINS?.traitsIdentite?.dateNaissance
            .split("-")
            .join(""),
        ordreDeNaissance: 0,
        sexe: patientData?.INS?.responseINS?.traitsIdentite?.sexeAdministratif,
      };
      const contentCDA = await DMP.dmp2cda(author, content, patient, format);
      const requestAddNewDMPFile = {
        context: {
          author,
          insNirAutorite: "TEST",
          modeAcces: {
            acces: "NORMAL",
          },
        },
        request: {
          submission: {
            title: "soumission",
            contentType: "04",
            patient,
            documents: [
              {
                auteurs: [author],
                dmpcType: "wrapped",
                classCode: "11",
                comments: "Document Wrapped soumis via katomi",
                confidentialities: ["N"],
                isCdaR2N3: true,
                format,
                content: contentCDA,
              },
            ],
          },
        },
      };
      const response = await DMP.td21SubmitDocuments(requestAddNewDMPFile);
      setDMPLoading(false);
      makeToast("success", t("Consultation.SuccessAddDMP"));
      return response;
    } catch (error) {
      makeToast("error", t("Consultation.FailAddDMP"));
      setDMPLoading(false);
      console.log("[DEBUG AddNewDMPFile] ", error);
    }
  };

  const parseImage = (filename, userId, type) => ({
    original: loadImage(userId, type, "thumbnail", filename),
  });

  const _getConsultationById = async () => {
    setLoading(true);
    try {
      const response = await getConsultationById(idConsultation);
      setconsultationDetails(response.consultation);
      setSelectedTemplate(response.consultation.rapport.templatePdfId._id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const _sendConsultationToPatient = async () => {
    if (!editorState)
      return makeToast("warning", t("Consultation.EmptyReport"));
    setLoading(true);
    try {
      await editConsultationById(idConsultation, {
        rapportDone: true,
        rapport: {
          textRapport: draftToHtml(
            convertToRaw(editorState.getCurrentContent())
          ),
          templatePdfId: selectedTemplate,
        },
      });
      makeToast("success", t("Consultation.SuccessSentReport"));

      history.push(previousStates?.filePatientTasks ? "/mes-patients" : "/");
    } catch (error) {
      setLoading(false);
    }
  };

  const onEditorStateChange = async (newState) => {
    setEditorState(newState);
    setAutoSaveAt(Date.now());
    try {
      await editConsultationById(idConsultation, {
        rapport: {
          textRapport: draftToHtml(
            convertToRaw(editorState.getCurrentContent())
          ),
          templatePdfId: selectedTemplate,
        },
      });
    } catch (error) {}
  };

  useEffect(() => {
    _getConsultationById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (consultationDetails?.rapport?.textRapport) {
      const contentBlock = htmlToDraft(
        ReactHtmlParser(String(consultationDetails?.rapport?.textRapport))[0]
      );

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        setEditorState(EditorState.createWithContent(contentState));
      }
    }
  }, [consultationDetails?.rapport]);

  useEffect(() => {
    async function getData() {
      const response = await getAllPdfOfDoctors(user?._id);
      setTemplateOption(
        response?.data?.map((el) => ({ label: el.templateName, value: el._id }))
      );
      const arrangedByCategory = response.data.reduce(function (r, a) {
        r[a.modalType] = r[a.modalType] || [];
        r[a.modalType].push(a);
        return r;
      }, Object.create(null));
      setData(arrangedByCategory);
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GetAuthorData();
  }, []);
  useEffect(() => {
    if (patientData && checkCPSCard && qualified) {
      fetchPatientData();
    }
  }, [patientData, qualified]);
  useEffect(() => {
    if (
      !_.isEmpty(patientData?.INS?.responseINS?.insActif) &&
      statusLabels[patientData?.INS?.INSStaus] === "Identité qualifiée"
    )
      setQualified(true);
  }, [patientData]);

  return loading ? (
    <Spinner />
  ) : (
    <div
      className={
        user?.extensions?.find((el) => el.name === "Mes templates")?.active
          ? "block md:flex md:gap-10"
          : ""
      }
    >
      {user?.extensions?.find((el) => el.name === "Mes templates")?.active && (
        <div
          style={{
            minWidth: "250px",
            marginTop: "55px",
            maxHeight: "85vh",
            overflowY: "scroll",
          }}
          className="hidden md:block"
        >
          <div className="mb-8">
            <span className="minTitle">{t("Consultation.ModelCenter")}</span>
          </div>
          <div>
            {Object.entries(data).map(([value, key]: any) => {
              return (
                <div className="mt-8">
                  <span className="recommand mb-2">{value}</span>
                  <div className="pointed"></div>

                  {key.map((e) => {
                    return (
                      <div
                        className="mt-6 ml-4 textPointed"
                        onClick={() => setSelectedTemplate(e?._id)}
                      >
                        <p
                          className={`${
                            e._id === selectedTemplate
                              ? "templateNameSelected"
                              : "templateName"
                          }`}
                        >
                          {e?.templateName}
                        </p>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="relative px-3 xl:px-0">
        <Modal
          showModal={modalSaveToDMP}
          closeModal={() => setModalSaveToDMP(false)}
        >
          <div className="px-10 py-5">
            <p>{t("Consultation.ShareToDMP")}</p>
            <div className="flexCenter gap-3 mt-10">
              <Button width="150px" height="35px" onClick={_shareFolderWithDMP}>
                {t("Consultation.Confirm")}
              </Button>
              <Button
                width="150px"
                height="35px"
                onClick={_sendConsultationToPatient}
                status="secondary"
              >
                {" "}
                {t("Consultation.Cancel")}
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          showModal={prescritionModal}
          closeModal={() => setPrescritionModal(false)}
        >
          <PrescritionModal
            consultationDetails={consultationDetails}
            closeModal={() => setPrescritionModal(false)}
            redirection
          />
        </Modal>
        <Modal
          showModal={endReportModal}
          closeModal={() => {
            setEndReportModal(false);
          }}
        >
          <EndReportModal
            closeModal={() => {
              setEndReportModal(false);
            }}
            consultationDetails={consultationDetails}
            openPrescriptionModal={() => setPrescritionModal(true)}
            idConsultation={idConsultation}
            sendConsultationToPatient={_sendConsultationToPatient}
          />
        </Modal>
        <Modal
          showModal={modalNote}
          closeModal={() => {
            setModalNote(false);
          }}
        >
          <div className="min-w-3/4">
            <div className="flex mb-1 justify-end w-full  cursor-pointer ">
              <Icon
                isIcon={true}
                name="close"
                className="bg-gray-200 bg-opacity-50 rounded-xl"
                onClick={() => setModalNote(false)}
              />
            </div>
            <span className="menu block text-center my-5">
              {" "}
              {t("Consultation.YourNotes")}
            </span>
            <p className="m-3">
              {consultationDetails &&
                consultationDetails.doctorNote &&
                ReactHtmlParser(
                  ReactHtmlParser(consultationDetails.doctorNote)
                )}
            </p>
          </div>
        </Modal>
        <Modal
          showModal={modalDocument}
          closeModal={() => {
            setModalDocument(false);
          }}
        >
          <div className="bg-white w-full my-10" style={{ minHeight: "288px" }}>
            <div className="flex mb-1 justify-end w-full  cursor-pointer ">
              <Icon
                isIcon={true}
                name="close"
                className="bg-gray-200 bg-opacity-50 rounded-xl"
                onClick={() => setModalDocument(false)}
              />
            </div>
            <span className="menu block text-center mb-5">
              {t("Consultation.PatientFoldersAndImages")}
            </span>
            {consultationDetails?.patientId?.documents &&
            !_.isEmpty(consultationDetails.patientId.documents) ? (
              consultationDetails.patientId.documents.length > 6 ? (
                <div className="w-full h-40 mt-5">
                  <HorizontalScroll>
                    {consultationDetails?.patientId?.documents?.map((el) => {
                      if (el.metadata && el.metadata.slice(0, 5) === "image") {
                        return (
                          <img
                            crossOrigin="anonymous"
                            referrerPolicy="origin"
                            src={
                              parseImage(el.filePath, el.patientId, el.type)
                                .original
                            }
                            alt="patient-folder"
                            className="rounded-lg mx-3"
                            style={{
                              width: "120px",
                              height: "120px",
                            }}
                          />
                        );
                      }
                    })}
                  </HorizontalScroll>
                </div>
              ) : (
                <div
                  className="flexCenter gap-5 mx-5 flex-wrap"
                  style={{ margin: "inherit" }}
                >
                  {consultationDetails?.patientId?.documents?.map((el) => {
                    if (el.metadata && el.metadata.slice(0, 5) === "image") {
                      return (
                        <img
                          crossOrigin="anonymous"
                          referrerPolicy="origin"
                          src={
                            parseImage(el.filePath, el.patientId, el.type)
                              .original
                          }
                          alt="patient-folder"
                          className="rounded-lg"
                          style={{
                            width: "120px",
                            height: "120px",
                          }}
                        />
                      );
                    }
                  })}
                </div>
              )
            ) : (
              <div className="flexCenter" style={{ minHeight: "220px" }}>
                <span className="card-subtitle">
                  {t("Consultation.NoDocumentFound")}
                </span>
              </div>
            )}
          </div>
        </Modal>

        <div className="flex justify-between flex-col xl:flex-row xl:gap-0 gap-3">
          <div>
            <h1> {t("Consultation.WriteYourReport")}</h1>
            <span className="card-subtitle">
              {t("Consultation.ConsultationOf")}{" "}
              <span className="capitalize">
                {consultationDetails?.patientId?.firstName}
                {consultationDetails?.patientId?.lastName}
              </span>{" "}
              {moment(consultationDetails?.dateConsultation).format(
                t("Moment.Z")
              )}
            </span>
          </div>
          <div className="flex space-x-3 ">
            <Button
              width="119px"
              height="50px"
              onClick={() => {
                if (!editorState)
                  return makeToast("warning", t("Consultation.EmptyReport"));
                setEndReportModal(true);
              }}
            >
              {t("Consultation.Save")}
            </Button>
            <Button
              width="216px"
              height="50px"
              status="secondary"
              onClick={() => {
                if (editorState) setEndReportModal(true);
              }}
            >
              <div
                className="flexCenter space-x-2"
                onClick={() => {
                  if (checkCPSCard && qualified) setModalSaveToDMP(true);
                  else _sendConsultationToPatient();
                }}
              >
                <Icon isIcon={true} name="transmettre" />
                {t("Consultation.ShareToPatient")}
              </div>
            </Button>
          </div>
          <div className="flex xl:hidden justify-around mt-3  w-full">
            <Button
              width="50px"
              height="50px"
              status="secondary"
              onClick={() => {
                if (editorState) setEndReportModal(true);
              }}
            >
              <div
                className="flexCenter space-x-2"
                onClick={() => {
                  setModalNote(true);
                }}
              >
                <img
                  crossOrigin="anonymous"
                  referrerPolicy="origin"
                  src={require("../../assets/images/pen.png").default}
                  alt=""
                  width="24"
                />
              </div>
            </Button>
            <Button
              width="50px"
              height="50px"
              status="secondary"
              onClick={() => {
                setModalDocument(true);
              }}
            >
              <div className="flexCenter space-x-2">
                <IconFolder />
              </div>
            </Button>
            {!_.isEmpty(templateOption) && (
              <SelectComp
                options={templateOption}
                setText={false}
                className="w-full  ml-4 cursor-pointer"
                style={{ minWidth: "150px" }}
                name="morning"
                value={selectedTemplate}
                onChange={(e) => {
                  setSelectedTemplate(e.target.value);
                }}
              />
            )}
          </div>
        </div>
        <div className="flex justify-between">
          <div className=" w-full xl:w-1/2 h-screen">
            <div className="mt-5 mb-3 bg-white rounded-lg h-3/4">
              <Editor
                initialContentState={contentState}
                editorState={editorState}
                wrapperClassName="consultation-editor"
                editorClassName="consultation-editor"
                onEditorStateChange={onEditorStateChange}
              />
            </div>
            <div className="text-center">
              <span className="card-subtitle">
                {t("Consultation.autoSaveAt")}{" "}
                {moment(autoSaveAt).format("hh:mm")}✓
              </span>
            </div>
          </div>
          <div className="w-1/2 hidden xl:block h-screen mx-5">
            <div className="bg-white h-72 w-full ">
              <span className="menu block text-center my-5">
                {" "}
                {t("Consultation.YourNotes")}
              </span>
              <p className="m-3">
                {consultationDetails &&
                  consultationDetails.doctorNote &&
                  ReactHtmlParser(
                    ReactHtmlParser(consultationDetails.doctorNote)
                  )}
              </p>
            </div>
            <div
              className="bg-white w-full my-10"
              style={{ minHeight: "288px" }}
            >
              <span className="menu block text-center mb-5">
                {t("Consultation.PatientFoldersAndImages")}
              </span>
              {consultationDetails?.patientId?.documents &&
              !_.isEmpty(consultationDetails.patientId.documents) ? (
                consultationDetails.patientId.documents.length > 6 ? (
                  <div className="w-full h-40 mt-5">
                    <HorizontalScroll>
                      {consultationDetails?.patientId?.documents?.map((el) => {
                        if (
                          el.metadata &&
                          el.metadata.slice(0, 5) === "image"
                        ) {
                          return (
                            // <div className="relative">
                            <img
                              crossOrigin="anonymous"
                              referrerPolicy="origin"
                              src={
                                parseImage(el.filePath, el.patientId, el.type)
                                  .original
                              }
                              alt="patient-folder"
                              className="rounded-lg mx-3"
                              style={{
                                width: "120px",
                                height: "120px",
                              }}
                            />
                            // </div>
                          );
                        }
                      })}
                    </HorizontalScroll>
                  </div>
                ) : (
                  <div
                    className="flexCenter gap-5 mx-5 flex-wrap"
                    style={{ margin: "inherit" }}
                  >
                    {consultationDetails?.patientId?.documents?.map((el) => {
                      if (el.metadata && el.metadata.slice(0, 5) === "image") {
                        return (
                          <img
                            crossOrigin="anonymous"
                            referrerPolicy="origin"
                            src={
                              parseImage(el.filePath, el.patientId, el.type)
                                .original
                            }
                            alt="patient-folder"
                            className="rounded-lg"
                            style={{
                              width: "120px",
                              height: "120px",
                            }}
                          />
                        );
                      }
                    })}
                  </div>
                )
              ) : (
                <div className="flexCenter" style={{ minHeight: "220px" }}>
                  <span className="card-subtitle">
                    {t("Consultation.NoDocumentFound")}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
