import { v4 as uuidv4 } from "uuid";
// import moment from "../../../helper/moment";
import base64 from "base-64";
import utf8 from "utf8";
import moment from "../../../helper/moment";
export default class DMPApi {
  endpoint = "";

  constructor(devboxApiEndpoint) {
    this.endpoint = devboxApiEndpoint;
  }

  async lpsInfo() {
    try {
      const res = await fetch(`${this.endpoint}/dmp/lpsInfo`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      if (!data) throw new Error("expected json. got different content");

      if (!res.ok) {
        const error = (data && data.message) || res.status;
        console.log(error);
      }

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async nomenclatures(jeuxValeurs) {
    try {
      const res = await fetch(
        `${this.endpoint}/dmp/nomenclatures?jeuxValeurs=${jeuxValeurs}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      if (!data) throw new Error("expected json. got different content");

      if (!res.ok) {
        const error = (data && data.message) || res.status;
        console.log(error);
      }

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async typesNomenclaturesByClassCode() {
    try {
      const res = await fetch(
        `${this.endpoint}/dmp/nomenclatures/typesByClassCode`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      if (!data) throw new Error("expected json. got different content");

      if (!res.ok) {
        const error = (data && data.message) || res.status;
        console.log(error);
      }

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async parametres() {
    try {
      const res = await fetch(`${this.endpoint}/dmp/parametres`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      if (!data) throw new Error("expected json. got different content");

      if (!res.ok) {
        const error = (data && data.message) || res.status;
        console.log(error);
      }

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async td02Exist(request) {
    try {
      const res = await fetch(`${this.endpoint}/dmp/td02Exist`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      if (!data) throw new Error("expected json. got different content");

      if (!res.ok) {
        const error = (data && data.message) || res.status;
        console.log(error);
      }

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async td03AddAuthorization(request) {
    try {
      // const payload = {
      //   context: {
      //     author: {},
      //     insNirAutorite: "TEST",
      //     modeAcces: {
      //       acces: "NORMAL",
      //     },
      //   },
      //   request: {
      //     ins: "277076322082910",
      //     role: "STANDARD",
      //   },
      // };
      const res = await fetch(`${this.endpoint}/dmp/td03AddAuthorization`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      // if (!data) throw new Error("expected json. got different content");

      if (!res.ok) {
        const error = (data && data.message) || res.status;
        console.log(error);
      }

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async td03RemoveAuthorization(request) {
    try {
      // const payload = {
      //   context: {
      //     author: {},
      //     insNirAutorite: "TEST",
      //     modeAcces: {
      //       acces: "NORMAL",
      //     },
      //   },
      //   request: {
      //     ins: "277076322082910",
      //     role: "STANDARD",
      //   },
      // };
      const res = await fetch(`${this.endpoint}/dmp/td03RemoveAuthorization`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      // if (!data) throw new Error("expected json. got different content");

      if (!res.ok) {
        const error = (data && data.message) || res.status;
        console.log(error);
      }

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async td09TableauDeBordUrl(request) {
    try {
      const res = await fetch(`${this.endpoint}/dmp/td09TableauDeBordUrl`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      if (!data) throw new Error("expected json. got different content");

      if (!res.ok) {
        const error = (data && data.message) || res.status;
        console.log(error);
      }

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async td15aCreerAccesInternetPatient(request) {
    try {
      // const payload = {
      //   context: {
      //     author: {},
      //     insNirAutorite: "TEST",
      //     modeAcces: {
      //       acces: "NORMAL",
      //     },
      //   },
      //   request: {
      //     email: "hamdi.rahal@gmail.com",
      //     patient: {
      //       ins: "277076322082910",
      //       insAutorite: "1.2.250.1.213.1.4.10",
      //       internalId: null,
      //       civilite: null,
      //       nomPatronymique: "NESSI",
      //       prenom: "RUTH",
      //       nomUsuel: null,
      //       prenomUsuel: null,
      //       dateDeNaissance: "19770714",
      //       ordreDeNaissance: 0,
      //       paysDeNaissance: null,
      //       email: null,
      //       telephone: null,
      //       mobile: null,
      //       nirOuvrantDroit: null,
      //       adresse: null,
      //       sexe: "F",
      //       representantLegal: null,
      //     },
      //     telephone: null,
      //   },
      // };
      const res = await fetch(
        `${this.endpoint}/dmp/td15aCreerAccesInternetPatient`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(request),
        }
      );

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      if (!data) throw new Error("expected json. got different content");

      if (!res.ok) {
        const error = (data && data.message) || res.status;
        console.log(error);
      }

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async td15bAddCanalOTP(request) {
    try {
      // const payload = {
      //   context: {
      //     author: {},

      //     insNirAutorite: "TEST",
      //     modeAcces: {
      //       acces: "NORMAL",
      //       raison: null,
      //     },
      //   },
      //   request: {
      //     ins: "277076322082910",
      //     email: "hamdi.rahal@gmail.com",
      //     telephone: null,
      //   },
      // };
      const res = await fetch(`${this.endpoint}/dmp/td15bAddCanalOTP`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      if (!data) throw new Error("expected json. got different content");

      if (!res.ok) {
        const error = (data && data.message) || res.status;
        console.log(error);
      }

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async td15bRemoveCanalOTP(request) {
    try {
      // const payload = {
      //   context: {
      //     author: {},

      //     insNirAutorite: "TEST",
      //     modeAcces: {
      //       acces: "NORMAL",
      //       raison: null,
      //     },
      //   },
      //   request: {
      //     ins: "277076322082910",
      //     email: "hamdi.rahal@gmail.com",
      //     telephone: null,
      //   },
      // };
      const res = await fetch(`${this.endpoint}/dmp/td15bRemoveCanalOTP`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      if (!data) throw new Error("expected json. got different content");

      if (!res.ok) {
        const error = (data && data.message) || res.status;
        console.log(error);
      }

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async td15bUpdateCanalOTP(request) {
    try {
      const payload = {
        context: {
          author: {},
          insNirAutorite: "TEST",
          modeAcces: {
            acces: "NORMAL",
          },
        },
        request: {
          ins: "277076322082910",
          email: "hamdi.rahal@gmail.com",
          telephone: null,
        },
      };
      const res = await fetch(`${this.endpoint}/dmp/td15bUpdateCanalOTP`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json(request)
        : null;

      if (!data) throw new Error("expected json. got different content");

      if (!res.ok) {
        const error = (data && data.message) || res.status;
        console.log(error);
      }

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async td15dDeblocageAccesInternetPatient(request) {
    try {
      // const payload = {
      //   context: {
      //     author: {},
      //     insNirAutorite: "TEST",
      //     modeAcces: {
      //       acces: "NORMAL",
      //     },
      //   },
      //   request: {
      //     patient: {
      //       ins: "277076322082910",
      //       insAutorite: "1.2.250.1.213.1.4.10",
      //       internalId: null,
      //       civilite: null,
      //       nomPatronymique: "NESSI",
      //       prenom: "RUTH",
      //       nomUsuel: null,
      //       prenomUsuel: null,
      //       dateDeNaissance: "19770714",
      //       ordreDeNaissance: 0,
      //       paysDeNaissance: null,
      //       email: null,
      //       telephone: null,
      //       mobile: null,
      //       nirOuvrantDroit: null,
      //       adresse: null,
      //       sexe: "F",
      //       representantLegal: null,
      //     },
      //   },
      // };
      const res = await fetch(
        `${this.endpoint}/dmp/td15dDeblocageAccesInternetPatient`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(request),
        }
      );

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      if (!data) throw new Error("expected json. got different content");

      if (!res.ok) {
        const error = (data && data.message) || res.status;
        console.log(error);
      }

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async td21SubmitDocuments(request) {
    try {
      const res = await fetch(`${this.endpoint}/dmp/td21SubmitDocuments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      if (!data) throw new Error("expected json. got different content");

      if (!res.ok) {
        const error = (data && data.message) || res.status;
        console.log(error);
      }

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async td31bGetDocumentEntry(request) {
    try {
      // const payload = {
      //   context: {
      //     author: {},
      //     insNirAutorite: "TEST",
      //     modeAcces: {
      //       acces: "NORMAL",
      //     },
      //   },
      //   request: {
      //     submission: {
      //       // legalAuthenticator,
      //       patient,
      //     },
      //   },
      // };
      const res = await fetch(`${this.endpoint}/dmp/td31bGetDocumentEntry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      if (!data) throw new Error("expected json. got different content");

      if (!res.ok) {
        const error = (data && data.message) || res.status;
        console.log(error);
      }

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async td31Find(request) {
    try {
      // const payload = {
      //   context: {
      //     // author: {
      //     //   internalId: "123456",
      //     //   nom: "Durand",
      //     //   prenom: "Pierre",
      //     //   role: "10",
      //     //   secteurActivite: "SA43",
      //     //   specialite: "G15_10/SM28",
      //     //   structureSante: {
      //     //     nom: "Devcoop Bureau de Léognan",
      //     //   },
      //     // },
      //     // confidentialityCode: {
      //     //   code: "INVISIBLE_REPRESENTANTS_LEGAUX",
      //     // },
      //     insNirAutorite: "TEST",
      //     modeAcces: {
      //       acces: "NORMAL",
      //       raison: null,
      //     },
      //     statuses: [
      //       "APPROVED",
      //       "DEPRECATED",
      //       "SUBMITTED",
      //       "ARCHIVED",
      //       "DELETED",
      //     ],
      //   },
      //   request: {
      //     statuses: [
      //       "APPROVED",
      //       "DEPRECATED",
      //       "SUBMITTED",
      //       "ARCHIVED",
      //       "DELETED",
      //     ],

      //     ins: "277076322082910",
      //     query: "FindDocuments",
      //   },
      // };
      const res = await fetch(`${this.endpoint}/dmp/td31Find`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      if (!data) throw new Error("expected json. got different content");

      if (!res.ok) {
        const error = (data && data.message) || res.status;
        console.log(error);
      }

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async td32RetrieveContent(request) {
    try {
      // const payload = {
      //   context: {
      //     author: {},
      //     insNirAutorite: "TEST",
      //     modeAcces: {
      //       acces: "NORMAL",
      //       raison: "string",
      //     },
      //   },
      //   request: {
      //     ins: "277076322082910",
      //     document: {
      //       auteurs: [],
      //       content: "string",
      //       creationTime: "string",
      //       destinataires: [],
      //       entryUuid: "string",
      //       events: [
      //         {
      //           code: "string",
      //           codingScheme: "string",
      //           displayName: "string",
      //         },
      //       ],
      //       format: "string",
      //       hash: "string",
      //       legalAuthenticator: {},
      //       mimeType: "string",
      //       patient: patient,
      //       practiceSetting: "string",
      //       replacementOf: "string",
      //       replacementOfUniqueId: "string",
      //       repositoryUniqueId: "string",
      //       serviceStartTime: "string",
      //       serviceStopTime: "string",
      //       status: "APPROVED",
      //       title: "string",
      //       type: "string",
      //       uniqueId: "string",
      //       versionNumber: 0,
      //     },
      //   },
      // };
      const res = await fetch(`${this.endpoint}/dmp/td32RetrieveContent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      if (!data) throw new Error("expected json. got different content");

      if (!res.ok) {
        const error = (data && data.message) || res.status;
        console.log(error);
      }

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async td33updateConfidentiality(request) {
    try {
      // const payload = {
      //   context: {
      //     author: {
      //       internalId: "899700369665",
      //       nom: "DOC0036966",
      //       prenom: "KIT",
      //       secteurActivite: "SA01",
      //       structureSante: {
      //         idNational: "10B0176228",
      //         nom: "HOPITAL GENERIQUE  FIN VARI",
      //       },
      //       specialite: "G15_10/SM26",
      //     },
      //     confidentialityCode: null,
      //     modeAcces: {
      //       acces: "NORMAL",
      //       raison: null,
      //     },
      //   },
      //   request: {
      //     matriculeINS: {
      //       valeur: "277076322082910",
      //       identifiantSysteme: "1.2.250.1.213.1.4.10",
      //     },
      //     documentUuid: "be848a40-da96-11ed-bb6f-0050569e46a0",
      //     confidentialities: ["N", "MASQUE_PS"],
      //     contentType: "04",
      //   },
      // };
      const res = await fetch(
        `${this.endpoint}/dmp/td33updateConfidentiality`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(request),
        }
      );

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      // if (!data) throw new Error("expected json. got different content");

      if (!res.ok) {
        const error = (data && data.message) || res.status;
        console.log(error);
      }

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async td33updateStatus(request) {
    try {
      // const payload = {
      //   context: {
      //     author: {},
      //     insNirAutorite: "TEST",
      //     modeAcces: {
      //       acces: "NORMAL",
      //       raison: "string",
      //     },
      //   },
      //   request: {
      //     ins: "277076322082910",
      //     contentType: "10",
      //     documentUuid: "c2811791-1773-4325-9abf-a2f2d09ccfc9",
      //     newStatus: "APPROVED",
      //     oldStatus: "APPROVED",
      //   },
      // };
      const res = await fetch(`${this.endpoint}/dmp/td33updateStatus`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      if (!data) throw new Error("expected json. got different content");

      if (!res.ok) {
        const error = (data && data.message) || res.status;
        console.log(error);
      }

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async dmp2cda(author, content, patient, format, title) {
    try {
      const generateRandom = (maxLimit = 100) => {
        const rand1 = Math.random() * maxLimit;
        const rand2 = Math.random() * maxLimit;
        const rand3 = Math.random() * maxLimit;

        const rand = Math.floor(rand1 + rand2 + rand3); // 99

        return rand;
      };
      const request = {
        entryUuid: uuidv4(),
        yarn: `1.2.250.1.287.${generateRandom()}`,
        secteurActivite: author.secteurActivite,
        auteurs: [author],
        classCode: "11",
        comments: "KATOMI_Document",
        confidentialities: ["N"],
        content,
        // creationTime: "20230228080000", // moment().format("YYYYMMDDHHmmss"),
        creationTime: moment().subtract(1, "h").format("YYYYMMDDHHmmss"),
        events: [
          {
            code: "H33",
            codingScheme: "2.16.840.1.113883.6.3",
            displayName: "Décollement et déchirement de la rétine",
          },
        ],

        format,
        legalAuthenticator: author,
        patient,
        practiceSetting: "AMBULATOIRE",
        serviceStartTime: "20230228080000", // YYYYMMDDHHmmSS
        serviceStopTime: "20230228080000", // YYYYMMDDHHmmSS
        // type: "11488-4",
        title,
        type: "11490-0",
      };
      const res = await fetch(`${this.endpoint}/dmp/to/cda`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });
      const response = await res.text();
      const bytes = utf8.encode(response);
      const encoded = base64.encode(bytes);
      // console.log("====>", btoa(encodeURI(response)));
      // return btoa(response);
      return encoded;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async td31_32FindAndRetrieveContent(request) {
    try {
      const res = await fetch(
        `${this.endpoint}/dmp/td31_32FindAndRetrieveContent`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(request),
        }
      );

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      if (!data) throw new Error("expected json. got different content");

      if (!res.ok) {
        const error = (data && data.message) || res.status;
        console.log(error);
      }

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async td04ListDMPActifs(request) {
    try {
      const res = await fetch(`${this.endpoint}/dmp/td04ListDMPActifs`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      if (!data) throw new Error("expected json. got different content");

      if (!res.ok) {
        const error = (data && data.message) || res.status;
        console.log(error);
      }

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
