/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import Modal from "../../components/ModalUI";
import CreationSteps from "../CreationSteps";
import { getAllDoctorSurvey } from "../../api/survey";

import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";

import { userAtom } from "../../state/auth";
import Spinner from "../../components/Spinner";
import Button from "../../components/ButtonUI";
import CardSurvey from "./SurveyCard";

type Props = any;
const Forms: React.FC<Props> = ({ props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [user] = useAtom(userAtom);
  const [creationModelActive, setCreationModelActive] = useState(false);
  const [loader, setLoader] = useState(true);
  const [surveyList, setSurveyList] = useState([] as any[]);
  const [tempSurvey, setTempSurvey] = React.useState([]) as any;

  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  const [, setTotalResult] = React.useState(0);
  const [limit] = React.useState(10);
  const [, setWidthPage] = useState(document.body.clientWidth);
  const [showModalMobile, setShowModalMobile] = useState<boolean | Function>(
    false
  );
  const getAllSurveys = async () => {
    try {
      const res = await getAllDoctorSurvey(user?._id);
      if (res) {
        setSurveyList(res);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (
      !user?.extensions?.find((el) => el.name == "Mes questionnaires")?.active
    )
      history.push("/");
    getAllSurveys();
  }, []);

  React.useEffect(() => {
    if (!_.isEmpty(surveyList)) {
      setTotalResult(surveyList.length);
      setTotalPage(Math.ceil(surveyList.length / limit));
      setTempSurvey(
        surveyList
          .sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt))
          .slice((page - 1) * limit, (page - 1) * limit + limit)
      );
    }
  }, [page, surveyList]);

  useEffect(() => {
    setWidthPage(document.body.clientWidth);
    document.body.clientWidth < 900 && setShowModalMobile(true);
  }, [document.body.clientWidth]);

  return loader ? (
    <Spinner />
  ) : document.body.clientWidth < 900 ? (
    <Modal
      showModal={showModalMobile !== false}
      closeModal={() => setShowModalMobile(false)}
      width={"300px"}
    >
      <div className="m-4">
        <span>{t("Survey.NoAvailbleForMobile")}</span>
        <div className="w-full mt-8 flex justify-center items-center">
          <Button
            status="primary"
            width="50%"
            height="37px"
            className=""
            onClick={() => {
              history.push("/");
            }}
          >
            {t("Survey.Ok")}
          </Button>
        </div>
      </div>
    </Modal>
  ) : (
    <div className="my-patients px-2 md:px-0 ">
      <header className="flex justify-between md:items-center w-full flex-col md:flex-row">
        <h1 className="pageTitle">{t("Survey.MyQuestionnaires")}</h1>
      </header>

      <div className="flex justify-between items-center w-full mt-5 ">
        <div className="flex flex-col md:flex-row md:items-center gap-4">
          <div className="flex gap-2 flex-wrap "></div>
          <Button
            status="secondary"
            onClick={() => setCreationModelActive(true)}
            className="p-2"
          >
            <span className="mr-2">🧾</span>
            {t("Survey.NewSurvey")}
          </Button>
        </div>
        {surveyList && surveyList.length > 0 ? (
          <div>
            {surveyList.length} {t("Survey.ResultsFound")}
          </div>
        ) : (
          <div> {t("Survey.ResultsFound")}</div>
        )}
      </div>
      <div className={`patient-list flex flex-wrap gap-10 `}>
        {tempSurvey && tempSurvey.length !== 0 ? (
          tempSurvey.map((el, i) => (
            <CardSurvey
              patientDetails={el}
              openModal={false}
              surveyDetails={el}
              user={user}
              i={i}
            />
          ))
        ) : (
          <div className="w-full flexCenter mt-24">
            <h3> {t("Survey.NoSurveyFound")}</h3>
          </div>
        )}
      </div>
      <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 rounded-xl mt-5">
        <div className="flex-1 flex justify-between sm:hidden">
          <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
            {" "}
            {t("Survey.Previous")}{" "}
          </span>
          <span className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
            {" "}
            {t("Survey.Next")}{" "}
          </span>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              {t("Survey.PageNum")}
              <span className="font-medium">{page}</span>/
              <span className="font-medium">{totalPage}</span>
            </p>
          </div>
          <div>
            <nav
              className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
              aria-label="Pagination"
            >
              <span
                className="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                onClick={() => setPage(page - 1)}
              >
                <span className="sr-only"> {t("Survey.Previous")}</span>
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
              {Array.from({ length: totalPage }, (v, i) => i).map((el, i) => (
                <span
                  aria-current="page"
                  onClick={() => setPage(i + 1)}
                  className="cursor-pointer z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                >
                  {i + 1}
                </span>
              ))}

              <span
                className="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                onClick={() => setPage(page + 1)}
              >
                <span className="sr-only"> {t("Survey.Next")}</span>
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
            </nav>
          </div>
        </div>
      </div>
      <Modal
        showModal={creationModelActive}
        slidemodal={false}
        className="p-4"
        closeModal={() => {
          // When close modal reset component
          setCreationModelActive(false);
        }}
      >
        <CreationSteps />
      </Modal>
    </div>
  );
};

export default Forms;
