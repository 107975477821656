/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React from "react";
import {
  initiatePayment,
  checkPayment,
  updateBill,
  gatewayIFrame,
} from "../../api/paymee";

import makeToast from "../../components/Snackbar";

const Payment = ({ closeModal, selectedBill, user, setUser }) => {
  const [iframeToken, setIframeToken] = React.useState();
  const [, setIframesrc] = React.useState();
  const [showIframe, setShowIframe] = React.useState(false);

  const _initiatePayment = async () => {
    try {
      const res = await initiatePayment({
        vendor: 2341,
        amount: selectedBill.price,
        note: `bill ${selectedBill._id}`,
      });
      setIframeToken(res?.data?.token);
      setShowIframe(true);

      const resp = await gatewayIFrame(res?.data?.token);
      setIframesrc(resp);
      return res?.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const handleCheckPaymentEvent = async (event) => {
    if (
      event.data.event_id === "paymee.complete" &&
      iframeToken &&
      showIframe
    ) {
      //Execute Step 3

      const response = await checkPayment(iframeToken);
      if (response?.message == "Success") {
        const katomiTNBilled = await Promise.all(
          user.katomiTNBilled.map((el) =>
            el._id == selectedBill._id
              ? { ...el, billedAt: new Date(), extra: response }
              : el
          )
        );
        const updatedDoctor = await updateBill(katomiTNBilled);
        setUser({
          ...user,
          katomiTNBilled: updatedDoctor.katomiTNBilled,
        });

        makeToast("success", "Votre paiement à été traité avec succès");

        closeModal();
        setShowIframe(false);
      } else {
        makeToast("warning", "Veuillez vérifier vos informations de paiement ");
      }
    }
  };

  React.useEffect(() => {
    _initiatePayment();
  }, []);

  React.useEffect(() => {
    window.addEventListener("message", handleCheckPaymentEvent, false);
    return function cleanupListener() {
      window.removeEventListener("message", handleCheckPaymentEvent);
    };
  });

  return (
    <div>
      {showIframe ? (
        <iframe
          id="inlineFrameExample"
          title="Inline Frame Example"
          width="100%"
          height="400px"
          src={`https://sandbox.paymee.tn/gateway/${iframeToken}`}
        />
      ) : (
        <div className="h-64 w-64 flexCenter">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-purple-500" />
        </div>
      )}
    </div>
  );
};

export default Payment;
