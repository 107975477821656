import { useState } from "react";
import { useTranslation } from "react-i18next";
import { updatePassword } from "../../../api/auth";
import Button from "../../../components/ButtonUI";
import Icon from "../../../components/Icon";
import Input from "../../../components/FloatInput";
import makeToast from "../../../components/Snackbar";

const EditPassword = ({ closeModal }) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");

  const handleChangePassword = async () => {
    try {
      const regexPassword =
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{10,}$/;
      if (!regexPassword.test(confirmpassword))
        return makeToast("warning", t("SignUp.pswConstraint"));

      const { status, message } = await updatePassword({
        password,
        confirmpassword,
      });
      makeToast(status, message);
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="p-5">
      <div className="flex mb-10 w-full ">
        <h2 className="m-auto">{t("ProfilePatient.updatePassword")}</h2>
        <div className="float-right close-modal cursor-pointer">
          <Icon isIcon={true} name="close" onClick={closeModal} />
        </div>
      </div>
      <Input
        type="password"
        labelText={t("ProfilePatient.currentpassword")}
        placeholder={t("ProfilePatient.currentpassword")}
        className="edit-password"
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      />
      <Input
        type="password"
        labelText={t("ProfilePatient.newpassword")}
        placeholder={t("ProfilePatient.newpassword")}
        className="edit-password"
        onChange={(e) => {
          setConfirmPassword(e.target.value);
        }}
      />

      <p className="text-xs text-gray-400">
        {t("ProfilePatient.minimumCaract")}{" "}
        <span className="green-tick">✓</span>, {t("ProfilePatient.maj")}
        <span className="green-tick">✓</span>, {t("ProfilePatient.chiffre")}
        <span className="green-tick">✓</span>, {t("ProfilePatient.specialCarc")}
        <span className="green-tick">✓</span>
      </p>

      <div className="flexCenter my-5">
        <Button width="170px" height="50px" onClick={handleChangePassword}>
          {t("ProfilePatient.Save")}
        </Button>
      </div>
    </div>
  );
};

export default EditPassword;
