/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "../../../helper/moment";
import { FetchDocumentWithDoctorId, loadImage } from "../../../api/documents";
import Icon from "../../../components/Icon";
import { ReactComponent as WomanPatient } from "../../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../../assets/illustrations/illustration-homme-patient.svg";

import _ from "lodash";
import { handleSpeciality } from "../../../helper/handleSpeciality";

const MydoumentSlideModal = ({ closeModal, meetDetails }) => {
  const { t, i18n } = useTranslation() as any;
  const [images, setImages] = useState<any>([]);

  //Fetch Document filtred with doctorId and Patient Id
  const _FetchDocumentWithDoctorId = async () => {
    try {
      const response = await FetchDocumentWithDoctorId({
        patientId: meetDetails?.patientId,
        doctorId: meetDetails?.doctorId._id,
      });
      setImages(response.docs);
    } catch (error) {
      console.error(error);
    }
  };

  const parseImage = (filename, userId, type) => ({
    original: loadImage(userId, type, "thumbnail", filename),
  });

  useEffect(() => {
    _FetchDocumentWithDoctorId();
  }, []);

  return (
    <div className="">
      <div className="flex justify-end md:hidden ">
        <Icon
          isIcon={true}
          name="close"
          onClick={closeModal}
          className="cursor-pointer bg-opacity-50 bg-gray-200 rounded-xl"
        />
      </div>
      <div className="flex items-center justify-between md:space-x-10 space-x-1 shadow-xs p-3 mt-5 md:m-5 bg-gray-100 bg-opacity-70 rounded-xl">
        <div className="flex items-center space-x-3 md:space-x-7">
          <div className="rounded-lg  h-28 w-28 hidden md:block">
            {meetDetails.doctorId?.business_photo ? (
              <img
                crossOrigin="anonymous"
                referrerPolicy="origin"
                alt="profile"
                src={
                  meetDetails.doctorId?.business_photo.slice(0, 4) === "http"
                    ? meetDetails.doctorId?.business_photo
                    : loadImage(
                        meetDetails.doctorId?._id,
                        "profile",
                        "thumbnail",
                        meetDetails.doctorId?.business_photo
                      )
                }
                className=" h-28 w-28 rounded-lg shadow-md object-fill"
              />
            ) : meetDetails.doctorId?.sexe === "woman" ? (
              <WomanPatient className="  h-28 w-28 bg-gray-300" />
            ) : (
              <ManPatient className=" h-28 w-28 bg-gray-300" />
            )}
          </div>

          <div className="flex flex-col">
            <span className="card-title">
              {" "}
              {`${meetDetails.doctorId?.firstName} ${meetDetails.doctorId?.lastName}`}
            </span>
            <span className="card-subtitle">
              {" "}
              {handleSpeciality(
                meetDetails.doctorId?.speciality,
                i18n.language.includes("en")
              )}
            </span>
          </div>
        </div>
        <div className="flex md:space-x-5">
          <div className="flex flex-col w-36">
            <div className="flex card-subtitle justify-between items-center">
              <span>
                {moment(meetDetails.dateConsultation).format("DD MMM YYYY")}
              </span>
              <h3>|</h3>
              <span>
                {moment(meetDetails.dateConsultation).format("HH:mm")}
              </span>
            </div>
            <span className="box">{meetDetails.motif}</span>
          </div>
          <div className="hidden float-right close-modal-float cursor-pointer md:flex md:justify-center md:items-center ">
            <Icon isIcon={true} name="close" onClick={closeModal} />
          </div>
        </div>
      </div>

      <div className="p-3 m-5">
        <h2 className="custom-h2 uppercase">{t("ProfilePatient.Document")}</h2>
        {images && !_.isEmpty(images) && (
          <>
            <h3 className="uppercase my-3">{t("ProfilePatient.Images")}</h3>
            <div className="flex md:gap-5 gap-2 flex-wrap">
              {images.map((el) => {
                return (
                  <img
                    crossOrigin="anonymous"
                    referrerPolicy="origin"
                    src={
                      parseImage(el.filePath, el.patientId, el.type).original
                    }
                    alt="document katomi"
                    className="document-image rounded-xl"
                  />
                );
              })}
            </div>
          </>
        )}
        {meetDetails?.rapport && (
          <>
            <h3 className="uppercase mt-10">{t("ProfilePatient.Report")}</h3>
            <div className="flex space-x-10">
              <div className="report-box p-3 ">
                <span className=".card-title my-2">
                  <span className="font-bold">
                    {" "}
                    {t("ProfilePatient.ConsultationOf")}
                  </span>
                  <br />
                  <span>
                    {moment(meetDetails.dateConsultation).format(
                      "DD MMMM YYYY"
                    )}
                  </span>
                </span>

                <span className="block last-update  mt-7 mb-2">
                  {t("ProfilePatient.updatedAt")}
                  {"  "}
                  <span className="font-bold">
                    {moment(meetDetails.updatedAt).format(t("Moment.Z"))}
                  </span>
                </span>
                <div className="box max-w-max">{meetDetails.motif}</div>
              </div>
            </div>
          </>
        )}
        {meetDetails?.prescriptions &&
          !_.isEmpty(meetDetails?.prescriptions) && (
            <>
              <h3 className="uppercase mt-7">
                {t("ProfilePatient.Prescription")}
              </h3>
              <div className="flex space-x-10">
                <div className="report-box p-3 ">
                  <span className=".card-title my-2">
                    <span className="font-bold">
                      {" "}
                      {t("ProfilePatient.ConsultationOf")}
                    </span>
                    <br />
                    <span>
                      {moment(meetDetails.dateConsultation).format(
                        "DD MMMM YYYY"
                      )}
                    </span>
                  </span>

                  <span className="block last-update  mt-7 mb-2">
                    {t("ProfilePatient.updatedAt")}
                    {"  "}
                    <span className="font-bold">
                      {moment(meetDetails.updatedAt).format(t("Moment.Z"))}
                    </span>
                  </span>
                  <div className="box max-w-max">{meetDetails.motif}</div>
                </div>
              </div>
            </>
          )}
      </div>
    </div>
  );
};

export default MydoumentSlideModal;
