import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
/** API & HELPER */
import { userAtom } from "../../state/auth";
/**  COMPONENTS*/
import ModalResetAgenda from "./ModalReset";
import TeleconsultationsForm from "./Teleconsultations";
import Modal from "../ModalUI";
import Presentiel from "./Presentiel";
import makeToast from "../Snackbar";
import Acte from "./Act";
/** ASSETS */
import "./styles.scss";

interface Props {
  closeModal: any;
  modalReset: any;
  setModalReset: any;
}
const AgendaConfigForm: React.FC<Props> = ({
  closeModal,
  modalReset,
  setModalReset,
}) => {
  const { t } = useTranslation();
  const [user] = useAtom(userAtom) as any;
  const [openTabSecondary, setOpenTabSecondary] = useState(1);
  const [activeWorkLocation, setActiveWorkLocation] = useState(0) as any;
  const [selectedWorkLocation, setSelectedWorkLocation] = useState(
    user?.workingLocation[0]?.name
  ) as any;

  useEffect(() => {
    if (_.isEmpty(user.workingLocation)) {
      makeToast("warning", "Merci d'avoir configurer votre agenda");
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className="agenda-config-form flex flex-col items-center relative mb-5">
      <Modal showModal={modalReset}>
        <ModalResetAgenda
          closeModal={() => {
            setModalReset(false);
            closeModal();
          }}
        />
      </Modal>
      {/* <div
        className="absolute -bottom-2 right-2 cursor-pointer"
        onClick={() => setModalReset(true)}
      >
        <img crossOrigin = "anonymous"  referrerPolicy="origin" src={Reset} width="35" />
      </div> */}
      <h1 className="text-center hidden lg:block">{t("Agenda.timeSlot")}</h1>
      <h2 className="text-center block lg:hidden">{t("Agenda.timeSlot")}</h2>
      <div className="button-group-wrapper flex flex-row justify-between items-center">
        {!_.isEmpty(user.workingLocation) &&
          user.workingLocation.map((el, i) => (
            <button
              className={i === activeWorkLocation ? "active" : ""}
              type="button"
              onClick={() => {
                setActiveWorkLocation(i);
                setSelectedWorkLocation(user.workingLocation[i].name);
              }}
            >
              {el.name}
            </button>
          ))}
      </div>

      <div className="new-event-appointment-form  ">
        <div className="button-group-wrapper flex flex-row justify-between items-center ">
          <button
            type="button"
            className={openTabSecondary === 1 ? "active " : ""}
            onClick={() => setOpenTabSecondary(1)}
          >
            {t("Agenda.Presential")}
          </button>
          <button
            className={openTabSecondary === 2 ? "active" : ""}
            type="button"
            onClick={() => setOpenTabSecondary(2)}
          >
            {t("Agenda.Teleconsultation")}
          </button>
          <button
            type="button"
            className={openTabSecondary === 3 ? "active" : ""}
            onClick={() => setOpenTabSecondary(3)}
          >
            {t("Agenda.Acts")}
          </button>
        </div>
      </div>
      {openTabSecondary === 1 && (
        <Presentiel
          closeModal={closeModal}
          selectedWorkLocation={selectedWorkLocation}
        />
      )}
      {openTabSecondary === 2 &&
        (process.env.REACT_APP_KATOMI_TENANT === "katomiTN" ? (
          <div className="flexCenter h-40 font-medium">{t("Agenda.soon")}</div>
        ) : (
          <TeleconsultationsForm
            closeModal={closeModal}
            selectedWorkLocation={selectedWorkLocation}
          />
        ))}
      {openTabSecondary === 3 && (
        <Acte
          closeModal={closeModal}
          selectedWorkLocation={selectedWorkLocation}
        />
      )}
    </div>
  );
};

export default AgendaConfigForm;
