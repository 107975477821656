/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { editPatient } from "../../../api/patient";
import { userAtom } from "../../../state/auth";
import Button from "../../../components/ButtonUI";
import Icon from "../../../components/Icon";
import Select from "../../../components/Select";
import makeToast from "../../../components/Snackbar";

const MyHealthData = ({ closeModal }) => {
  const { t } = useTranslation() as any;
  const [user, setUser] = useAtom(userAtom) as any;
  const [size, setSize] = useState<string | undefined>(
    user?.size ? user?.size : "0"
  );
  const [weight, setWeight] = useState<string | undefined>(
    user?.weight ? user?.weight : "0"
  );
  const [imcIndice, setImcIndice] = useState("normale");
  const [smoking, setSmoking] = useState<any>(
    user?.smoking === true ? "Fumeur" : "Non fumeur"
  );

  const calIMC = () => {
    if (weight === "0" || size === "0") return "N/A";
    if (weight && size) return (+weight / Math.pow(+size / 100, 2)).toFixed(1);
    return "imc";
  };
  const handleEditHealthData = async () => {
    try {
      const values = {
        id: null,
        size,
        weight,
        smoking: smoking === "Fumeur",
      };
      values.id = user?._id;
      const response = await editPatient(values);
      setUser({ ...user, ...response });
      makeToast("success", t("ProfilePatient.SuccessUpdate"));
      closeModal();
    } catch (error) {
      console.error("[error] Edit Health Data :", error);
    }
  };
  const imcStatus = (weight: number, size: number) => {
    let imcStatus;
    if (weight && size) {
      const imc = (weight / Math.pow(size / 100, 2)).toFixed(2);
      if (+imc < 18.5) imcStatus = t("ProfilePatient.Underweight");
      else if (+imc >= 18.5 && +imc <= 24.99)
        imcStatus = t("ProfilePatient.Normal");
      else if (+imc >= 25 && +imc <= 29.99)
        imcStatus = t("ProfilePatient.Preoccupation");
      else if (+imc >= 30 && +imc <= 34.99)
        imcStatus = t("ProfilePatient.Obesity1");
      else if (+imc >= 35 && +imc <= 39.99)
        imcStatus = t("ProfilePatient.Obesity2");
      else if (+imc >= 40) imcStatus = t("ProfilePatient.Obesity3");
      return imcStatus;
    }
    return "N/A";
  };

  useEffect(() => {
    if (weight && size) {
      setImcIndice(imcStatus(+weight, +size));
    }
  }, [weight, size]);

  return (
    <div className=" px-3 py-5 ">
      <div className="flex mb-10 w-full ">
        <h2 className="m-auto">{t("ProfilePatient.EditMyHealthData")}</h2>
        <div className="float-right close-modal cursor-pointer">
          <Icon
            isIcon={true}
            name="close"
            onClick={closeModal}
            className="cursor-pointer"
          />
        </div>
      </div>

      <div className="flex w-full md:justify-between md:items-center gap-4 mb-5 flex-col md:flex-row">
        <span className="flex">
          {t("ProfilePatient.size")}
          <input
            type="text"
            className="text-sm text-blue-500 mx-2 font-bold w-8 border border-gray-100 h-7 px-1 rounded-md"
            value={size}
            onChange={(e) => setSize(e.target.value)}
          />
          {t("ProfilePatient.cm")}
        </span>
        <span className="flex">
          {t("ProfilePatient.Weight")}
          <input
            type="text"
            className="text-sm text-blue-500 mx-2 font-bold w-8 border border-gray-100 h-7 px-1 rounded-md"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          />
          {t("ProfilePatient.kg")}
        </span>
        <span className="md:flexCenter flex items-center">
          {t("ProfilePatient.BMI")}
          <span className="box-imc text-sm font-bold ml-1 imc-width">
            {calIMC()}
          </span>
        </span>
        <span className="flexCenter resultIMC bg-gray-100 pr-4 rounded-xl ">
          <Icon isIcon={true} name="imc" />
          <span>{imcIndice}</span>
        </span>
      </div>

      <Select
        options={[
          { value: "Fumeur", label: t("ProfilePatient.Smoker") },
          { value: "Non fumeur", label: t("ProfilePatient.NonSmoking") },
        ]}
        placeholder={t("ProfilePatient.IM")}
        className="w-full cursor-pointer"
        value={smoking}
        onChange={(e) => setSmoking(e.target.value)}
      />
      <div className="flexCenter mt-7">
        <Button width="170px" height="50px" onClick={handleEditHealthData}>
          {t("ProfilePatient.Save")}
        </Button>
      </div>
    </div>
  );
};

export default MyHealthData;
