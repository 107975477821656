export const  randomPics = [
    "https://katomi.co/assets/img/logo/katomi.png",
    "https://source.unsplash.com/EobQaSfI-EY/200x200",
    "https://source.unsplash.com/DK8jXx1B-1c/200x200",
    "https://source.unsplash.com/nnzkZNYWHaU/200x200",
    "https://source.unsplash.com/IJ0KiXl4uys/200x200",
    "https://source.unsplash.com/4nulm-JUYFo/200x200",
    "https://source.unsplash.com/TzvMJ6G0ink/200x200",
    "https://source.unsplash.com/bq1DigsQYt4/200x200",
    "https://source.unsplash.com/3I38NykIwBA/200x200",
  ]