import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HStack, Checkbox, InputGroup } from "@chakra-ui/react";
import { useField } from "../../../../hooks/FormGeneratorHooks";
import { FormGroup } from "../FormGroup";

const FieldCheckbox = (props: any) => {
  const { t } = useTranslation();
  const [checkedValues, setCheckedValues] = useState<any>([]);

  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    otherProps,
  } = useField(props);
  useEffect(() => {
    if (props.value) {
      setCheckedValues(props.value);
    }
  }, [props.value]);
  const { children, label, type, placeholder, helper, ...resta } = otherProps;
  function handleSelect(checkedValue) {
    let containsInChecked: boolean = checkedValues?.some(
      (value: any) => value.value === checkedValue.value
    );

    let removedFromCheckedValues = checkedValues?.filter(
      (value) => value.value !== checkedValue.value
    );

    let addToCheckedValues = [...(checkedValues ?? []), checkedValue];

    const newValues = containsInChecked
      ? removedFromCheckedValues
      : addToCheckedValues;

    setCheckedValues(newValues);
    let final = newValues.map((e) => e.value);
    if (Array.isArray(final)) return final.join(", ");
    else return final;
  }
  const [isTouched, setIsTouched] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted);
  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const formGroupProps = {
    errorMessage,
    helper: "",
    id,
    isRequired: props.required ? true : false,
    label: props.label,
    showError,
    name: props.name,

    ...resta,
  };

  return (
    <FormGroup {...formGroupProps}>
      <InputGroup>
        <HStack>
          <>
            {props.options ? (
              props.options
                .map((e) => {
                  return { label: e.label, value: e.label };
                })
                .map((item: any, idx: number) => {
                  let foundInCheckedValues = checkedValues.find((cv) => {
                    return cv.value === item.value;
                  });

                  return (
                    <>
                      {" "}
                      <Checkbox
                        fontSize="14px"
                        {...props}
                        key={`${idx}`}
                        isChecked={foundInCheckedValues ? true : false}
                        onChange={() => setValue(handleSelect(item))}
                        isDisabled={props.disabled ? true : false}
                        bg={foundInCheckedValues ? "blue" : "none"}
                        rounded="lg"
                        mr={2}
                      ></Checkbox>
                      {item.label}
                    </>
                  );
                })
            ) : (
              <> {t("Survey.NA")}</>
            )}
          </>
          {/* <pre>{JSON.stringify(checkedValues)}</pre> */}
        </HStack>
      </InputGroup>
      {children}
    </FormGroup>
  );
};

export default FieldCheckbox;
