import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { CSS2DRenderer } from "three/examples/jsm/renderers/CSS2DRenderer";

interface ISceneInit {
  fov: any;
  canvasWrapperRef: any;
  camera: any;
  scene: any;
  renderer: any;
  controls: any;
  labelRenderer: any;
}
export default class SceneInit implements ISceneInit {
  fov: any;
  canvasWrapperRef: any;
  camera: any;
  scene: any;
  renderer: any;
  controls: any;
  labelRenderer: any;

  constructor(canvasWrapperRef) {
    this.fov = 75;
    this.canvasWrapperRef = canvasWrapperRef;
    this.camera = undefined;
    this.scene = undefined;
    this.renderer = undefined;
    this.controls = undefined;
    this.labelRenderer = undefined;
  }

  initialize() {
    const sceneColor = 0x303030;

    const rect = this.canvasWrapperRef.current.getBoundingClientRect();

    this.camera = new THREE.PerspectiveCamera(
      this.fov,
      rect.width / rect.height,
      0.1,
      1000
    );
    this.camera.position.set(0, 30, 50);
    this.camera.layers.enableAll();

    this.scene = new THREE.Scene();
    this.scene.fog = new THREE.Fog(sceneColor, 1, 10000);
    //this.scene.add(new THREE.GridHelper(100,100));

    const getButtons = document.getElementById("Buttons") as HTMLElement;
    const buttonHeight = getButtons.clientHeight as Number;

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
    });
    this.renderer.setSize(rect.width, rect.height);
    this.renderer.setClearColor(sceneColor);
    this.canvasWrapperRef.current.appendChild(this.renderer.domElement);

    this.labelRenderer = new CSS2DRenderer();
    this.labelRenderer.setSize(rect.width, rect.height);
    this.labelRenderer.domElement.style.position = "absolute";
    this.labelRenderer.domElement.style.top = buttonHeight + "px";
    this.labelRenderer.domElement.style["margin-top"] = "1.75rem"; // NOTE: CHANGE IF ADDED MORE MARGIN AND PADDING TO PARENT UI
    this.canvasWrapperRef.current.appendChild(this.labelRenderer.domElement);

    //Contrôle caméra
    this.controls = new OrbitControls(
      this.camera,
      this.labelRenderer.domElement
    );
  }
}
