import axios from "axios";

import {
  ADD_NEW_LOGS,
  GET_ALL_LOGS_BY_DOCTORID,
  GET_LOGS_BY_ID,
  ADD_LOGS_DMP,
} from "../config/api";

export async function addNewLogs(payload) {
  try {
    const res = await axios.post(`${ADD_NEW_LOGS}`, payload);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getAllLogsByDoctorId(doctorID) {
  try {
    const res = await axios.get(`${GET_ALL_LOGS_BY_DOCTORID}/${doctorID}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getLogsById(logsId) {
  try {
    const res = await axios.get(`${GET_LOGS_BY_ID}/${logsId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function addlogsDMP(payload) {
  try {
    const res = await axios.post(ADD_LOGS_DMP, payload);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
