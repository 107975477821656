export const Specialist = {
  fr: {
    title: "Annuaire professionnel",
    Filters: "Filtres",
    NewSpecialist: "+ Nouveau spécialiste",
    ResultsFound: "résultats trouvés",
    SelectSpecialty: "sélectionner spécialité",
    Filter: "Filtrer",
    // ADD NEW SPECIALIST
    newSpecialist: "Nouveau Spécialiste",
    email: "Email",
    FirstName: "Prénom",
    LastName: "Nom",
    PhoneNumber: "Numéro de téléphone",

    Add: "Ajouter",
    Country: "Pays",
    FillEmail: "Veuillez remplir l'adresse email du spécialiste",
    FillFirstName: "Veuillez remplir le prénom du spécialiste",
    FillLastName: "Veuillez remplir le nom du spécialiste",
    SuccessSend: "Votre invitation a été envoyée avec succès",
    ExistDoctor:
      "Ce spécialiste existe déjà avec ce email ou numéro de téléphone",
    Error: "Une erreur s'est produite.Veuillez réessayer ultérieurement",
    //shareFolder
    RequestForTeleexpertise: "Demande de téléexpertise",
    AncientTreatments:"Traitements anciens",

    ErrorINS:
    "INS de ce patient n'est pas confirmé, vous ne pouvez pas partager son document",
    shareAbout: "Vous souhaitez partager des documents concernant :",
    wantToShare: "Vous souhaitez partager :",
    NoDocFound: "Ce patient n'a aucun document",
    NotreatmentFound: "Ce patient n'a aucun traitement en cours",
    NoOldtreatmentFound: " Ce patient n'a aucun ancien traitement",
    selectAll: "Tout séléctionner",
    IMAGES: "IMAGES",
    Documents: "Documents",
    Antecedents: "Antécédents",
    Treatment: "Traitement",
    Report: "Compte rendu",
    ConsultationOf: "Consultation du : ",
    UpdateAt: "Mise à jour le ",
    Prescription: "Prescription",
    Share: "Partager",
    inProgress: "En cours",
    AddObservations: "+ Ajouter une observation",
    observations: "observations",
    old: "Ancien",
    MedicalAntecedents: "Antécédents médicaux",
    SurgicalAntecedents: "Antécédents Chirurgicaux",
    NoMedicalAntecedentsFound:
      "Ce patient n'a aucun ancien antécédent médical.",
    NoChAntecedentsFound: "Ce patient n'a aucun ancien antécédent chirurgical.",
  },
  en: {
    
AncientTreatments:"Ancient treatments",
    title: "Professional directory",
    Filters: "Filters",
    NewSpecialist: "+ New Specialist",
    ResultsFound: "results found",
    SelectSpecialty: "select specialty",
    Filter: "Filter",
    // ADD NEW SPECIALIST
    newSpecialist: "New specialist",
    email: "Email",
    FirstName: "FirstName",
    LastName: "LastName",
    PhoneNumber: "Phone number",
    Add: "Add",
    Country: "Country",
    FillEmail: "Please fill in the email address of the specialist",
    FillFirstName: "Please fill in the specialist’s first name",
    FillLastName: "Please fill in the specialist’s last name",
    SuccessSend: "Your invitation has been sent successfully",
    ExistDoctor:
      "This specialist already exists with this email or phone number",
    Error: "An error has occurred. Please try again later",
    //shareFolder
    RequestForTeleexpertise: "Request for teleexpertise",
    ErrorINS:
      "INS of this patient is not confirmed, you cannot share his document",
    shareAbout: "You want to share documents about :",
    wantToShare: "You want to share :",
    NoDocFound: "This patient has no documents",
    NotreatmentFound: "This patient has no ongoing treatment",
    NoOldtreatmentFound: "This patient has no old treatment",
    selectAll: "Select all",
    IMAGES: "IMAGES",

    Documents: "Documents",
    Antecedents: "Antecedents",
    Treatment: "Treatment",
    Report: "Report",
    ConsultationOf: "Consultation of :",
    UpdateAt: "Updated at ",
    Prescription: "Prescription",
    Share: "Share",
    inProgress: "In progress",
    AddObservations: "+ Add an observation",
    observations: "observations",
    old: "Old",
    //antecedent
    MedicalAntecedents: "Medical antecedents",
    SurgicalAntecedents: "Surgical antecedents",
    NoMedicalAntecedentsFound:
      "This patient has no previous medical antecedents.",
    NoChAntecedentsFound: "This patient has no previous Surgical antecedents.",
  },
};
