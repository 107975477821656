import {
  InputGroup,
  Box,
  IconButton,
  VStack,
  Grid,
  GridItem,
  ModalFooter,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

import ButtonUI from "../../../ButtonUI";
import { Formiz } from "../StepperWrapper/Formiz";
import { FieldSelect } from "../../Elements/FieldSelect";
import { FieldInput } from "../../Elements/FieldInput";
import { SwitchInput } from "../../Elements/FieldSwitcher";

import { useForm } from "../../../../hooks/FormGeneratorHooks";
import { useRepeater } from "../../../../hooks/FormGeneratorHooks/useRepeater";
import { filterInputsForPage } from "../../../../helper/FormGeneratorHelper/conditionalLogic";
import { conditionsListExport } from "./conditionList";

const ConditionLogicForPage = (props) => {
  const intialValues = {
    name: props.step.sectionName,
    conditionLogic: props.step.conditionLogic
      ? props.step.conditionLogic
      : {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
  };
  const { t } = useTranslation();

  const form = useForm();
  const elements = props.elements ? filterInputsForPage(props.elements) : [];
  const conditionsList = conditionsListExport(t);
  const collection = useRepeater({
    name: "conditionLogic.conditions",
    form: form,
    initialValues: intialValues.conditionLogic.conditions,
  });

  const options =
    elements.length > 0
      ? elements.map((el) => {
          return { label: el.label, value: el.field_name };
        })
      : [];

  return (
    <Formiz initialValues={intialValues} connect={form}>
      <FieldInput
        id="name"
        name="name"
        placeholder={"Page " + (props.step.stepNbr + 1)}
        label={"Nom de la page"}
        value={intialValues.name}
      />
      <InputGroup name="conditionLogic">
        <VStack
          spacing={4}
          align="start"
          className="border p-4 rounded-lg w-full "
        >
          <Grid
            width={"60%"}
            templateColumns="repeat(2, 1fr)"
            alignItems={"baseline"}
            gap={1}
          >
            <GridItem w="170px" h="10">
              {" "}
              <span style={{ fontSize: "16px" }} className="font-bold ">
                {t("Survey.ConditionalLogic")}
              </span>
            </GridItem>
            <GridItem w="100%" h="10" className="flex  text-xs text-gray-500  ">
              <GridItem>
                <span className="p-2 card-subtitle">
                  {" "}
                  {t("Survey.Deactivate")}
                </span>
              </GridItem>
              <GridItem>
                {" "}
                <SwitchInput
                  value={intialValues.conditionLogic.status}
                  name="conditionLogic.status"
                  disabled={options.length < 1}
                />
              </GridItem>
              <GridItem>
                <span className="p-2 card-subtitle">
                  {t("Survey.Activate")}
                </span>
              </GridItem>
            </GridItem>
          </Grid>
          {form.values &&
            form.values.conditionLogic &&
            form.values.conditionLogic.status && (
              <>
                <Grid
                  templateColumns="repeat(5, 0.6fr)"
                  alignItems={"baseline"}
                  gap={0}
                >
                  <GridItem w={16} h="10">
                    <span>{t("Survey.Action")} </span>
                  </GridItem>
                  <GridItem w="100%" h="10">
                    <FieldSelect
                      w={32}
                      style={{ borderRadius: "10px", height: "auto" }}
                      name="conditionLogic.action"
                      dropdownStyle={{
                        borderRadius: "10px",
                        padding: "4%",
                      }}
                      className="border rounded-lg h-full  "
                      options={[
                        {
                          label: t("SurveyIntl.show"),
                          value: "S",
                        },
                        {
                          label: t("SurveyIntl.hide"),
                          value: "H",
                        },
                      ]}
                      value={intialValues.conditionLogic.action}
                    />
                  </GridItem>
                  <GridItem mx={1} h="10">
                    {" "}
                    <span className="mb-4 flexCenter ">
                      {" "}
                      {t("SurveyIntl.when")}{" "}
                    </span>
                  </GridItem>
                  <GridItem w={32} h="10">
                    {" "}
                    <FieldSelect
                      name="conditionLogic.operator"
                      style={{ borderRadius: "10px", height: "auto" }}
                      dropdownStyle={{
                        borderRadius: "10px",
                        padding: "4%",
                      }}
                      className="border rounded-lg h-full"
                      options={[
                        {
                          label: t("SurveyIntl.all"),
                          value: "all",
                        },
                        {
                          label: t("SurveyIntl.one"),
                          value: "one",
                        },
                      ]}
                      value={intialValues.conditionLogic.operator}
                    />
                  </GridItem>
                  <GridItem w={56} h="10">
                    {" "}
                    <p className="ml-3">
                      {" "}
                      {t("SurveyIntl.conditionsAreValid")}
                    </p>
                  </GridItem>
                </Grid>

                <Grid
                  templateColumns="repeat(3, 1fr)"
                  gap={1}
                  className="text-xs"
                >
                  {collection.keys.map((key, index) => (
                    <>
                      <GridItem>
                        <Box flex="1">
                          <FieldSelect
                            name={`conditionLogic.conditions[${index}].fieldToCompare`}
                            required
                            value={
                              intialValues.conditionLogic.conditions[index] &&
                              intialValues.conditionLogic.conditions[index]?.[
                                "fieldToCompare"
                              ]
                                ? intialValues.conditionLogic.conditions[
                                    index
                                  ]?.["fieldToCompare"]
                                : undefined
                            }
                            label="Champ à comparer "
                            options={options}
                            m="0"
                          />
                        </Box>
                      </GridItem>
                      <GridItem>
                        {" "}
                        <Box flex="1">
                          <FieldSelect
                            name={`conditionLogic.conditions[${index}].ifCondition`}
                            required
                            value={
                              intialValues.conditionLogic.conditions[index] &&
                              intialValues.conditionLogic.conditions[index]?.[
                                "ifCondition"
                              ]
                                ? intialValues.conditionLogic.conditions[
                                    index
                                  ]?.["ifCondition"]
                                : undefined
                            }
                            label="Condition"
                            m="0"
                            options={conditionsList}
                          />
                        </Box>
                      </GridItem>
                      <GridItem className="flex">
                        <Box flex="1">
                          <FieldInput
                            name={`conditionLogic.conditions[${index}].value`}
                            required
                            value={
                              intialValues.conditionLogic.conditions[index] &&
                              intialValues.conditionLogic.conditions[index]?.[
                                "value"
                              ]
                                ? intialValues.conditionLogic.conditions[
                                    index
                                  ]?.["value"]
                                : undefined
                            }
                            placeholder="Valeur"
                            w={32}
                            label="Valeur"
                            m="0"
                          />
                        </Box>{" "}
                        <Box pt="1.8rem" mx={0.5}>
                          <IconButton
                            size="sm"
                            aria-label="Delete"
                            icon={<DeleteIcon />}
                            onClick={() => {
                              collection.remove(index);
                            }}
                          />
                        </Box>
                      </GridItem>
                    </>
                  ))}
                  <Box>
                    {collection.length <= 20 && (
                      <>
                        {options.length > 1 && (
                          <ButtonUI
                            status="secondary"
                            className="p-2 text-xs w-48"
                            type="button"
                            onClick={() => collection.append()}
                          >
                            {" "}
                            {t("Survey.AddCondition")}{" "}
                          </ButtonUI>
                        )}
                      </>
                    )}
                  </Box>
                </Grid>
              </>
            )}
        </VStack>
      </InputGroup>
      <ModalFooter>
        <ButtonUI
          status="secondary"
          width="130px"
          height="50px"
          className=" float-right  py-2  mb-4 btn-delete "
          onClick={() => props.closeModal()}
        >
          Annuler
        </ButtonUI>
        <ButtonUI
          status="secondary"
          type="submit"
          width="130px"
          height="50px"
          className="ml-3 px-4 mb-4 py-2  float-left   "
          onClick={() => {
            if (form.isValid) {
              props.editStepConfig(form.values, props.step.stepNbr);
              props.closeModal();
            }
          }}
        >
          {t("Survey.Save")}
        </ButtonUI>
      </ModalFooter>
    </Formiz>
  );
};

export default ConditionLogicForPage;
