import React, { useState } from "react";
import TimePicker from "react-time-picker";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
/** API & HELPER */
import { setAgendaConfiguration } from "../../api/doctor";
/** COMPONENTS */
import Button from "../ButtonUI";
import SelectComp from "../Select";
import makeToast from "../Snackbar";
/** ASSETS */
import "./styles.scss";

interface Props {
  closeModal: any;
  selectedWorkLocation: string;
}

const duree = [
  { label: "15 min", value: "15" },
  { label: "30 min", value: "30" },
  { label: "45 min", value: "45" },
  { label: "1h", value: "60" },
];

const Acte = (props: Props) => {
  const { t, i18n } = useTranslation();
  const { closeModal, selectedWorkLocation } = props;
  const [selectedInput, setSelectedInput] = useState("");
  const [loadingBtn, setLoadingBtn] = React.useState<boolean>(false);
  const [officeWorkingHours, setOfficeWorkingHours] = useState<any>({
    start: "09:00",
    end: "18:00",
  });
  const [actDuration, setActDuration] = useState("30") as any;
  const [temp, setTemp] = useState(
    i18n.language.includes("en")
      ? [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ]
      : [
          "Lundi",
          "Mardi",
          "Mercredi",
          "Jeudi",
          "Vendredi",
          "Samedi",
          "Dimanche",
        ]
  );
  const DAYS_IN_WEEK = i18n.language.includes("en")
    ? [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ]
    : ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];

  const submitTimeSlots = async () => {
    try {
      setLoadingBtn(true);
      await setAgendaConfiguration({
        config: [
          {
            start: officeWorkingHours.start,
            end: officeWorkingHours.end,
            days: temp,
          },
        ],
        type: "act",
        timeSlotDuration: actDuration.slice(0.2),
        location: selectedWorkLocation,
      });
      makeToast("success", t("Agenda.SuccessUpdate"));
      setLoadingBtn(false);
      closeModal();
    } catch (error) {
      setLoadingBtn(false);
      makeToast("error", t("Agenda.ErrorTryLater"));
      console.error(error);
    }
  };

  return (
    <>
      <div className="workingDays flexCenter flex-wrap  gap-4 pt-10">
        {DAYS_IN_WEEK.map((day) => {
          return (
            <div
              className={classNames(
                "day flexCenter cursor-pointer",
                temp.includes(day) ? "active-box" : "disabled-box"
              )}
              onClick={() =>
                setTemp(
                  temp.includes(day)
                    ? temp.filter((e) => e !== day)
                    : [...temp, day]
                )
              }
            >
              <p>{day}</p>
            </div>
          );
        })}
        <Button
          status="secondary"
          width="222px"
          height="40px"
          onClick={() =>
            setTemp(
              i18n.language.includes("en")
                ? [
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                  ]
                : [
                    "Lundi",
                    "Mardi",
                    "Mercredi",
                    "Jeudi",
                    "Vendredi",
                    "Samedi",
                    "Dimanche",
                  ]
            )
          }
        >
          {t("Agenda.ResetWeek")}
        </Button>
      </div>
      <div className="plageHoraire flexCenter gap-x-2">
        <p> {t("Agenda.ActSlots")} </p>
        <div className="flexCenter">
          <div
            className="flexCenter sub-values"
            onClick={(e) => {
              setSelectedInput("TemperatureTime");
            }}
          >
            {selectedInput === "TemperatureTime" ? (
              <TimePicker
                onChange={(e) => {
                  if (e !== null)
                    setOfficeWorkingHours({
                      ...officeWorkingHours,
                      start: e,
                    });
                }}
                format="HH:mm"
                value={officeWorkingHours.start}
                onClockClose={() => {
                  setSelectedInput("");
                }}
                onClockOpen={() => {}}
              />
            ) : (
              <p className="value flexCenter cursor-pointer">
                {officeWorkingHours.start !== 0
                  ? officeWorkingHours.start
                  : "00:00"}
              </p>
            )}
          </div>
          <p>{t("Agenda.to")}</p>
          <div
            className="flexCenter sub-values"
            onClick={(e) => {
              setSelectedInput("TemperatureTime");
            }}
          >
            {selectedInput === "TemperatureTime" ? (
              <TimePicker
                onChange={(e) => {
                  if (e !== null)
                    setOfficeWorkingHours({
                      ...officeWorkingHours,
                      end: e,
                    });
                }}
                format="HH:mm"
                value={officeWorkingHours.end}
                onClockClose={() => {
                  setSelectedInput("");
                }}
                onClockOpen={() => {}}
              />
            ) : (
              <p className="value flexCenter cursor-pointer">
                {officeWorkingHours.end !== 0
                  ? officeWorkingHours.end
                  : "00:00"}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="flexCenter gap-5  items-center w-full my-5">
        <p> {t("Agenda.ActDuration")} </p>
        <SelectComp
          options={duree}
          setText={false}
          className="ml-4 cursor-pointer"
          name="morning"
          value={actDuration}
          onChange={(e) => {
            setActDuration(e.target.value);
          }}
        />
      </div>

      <div className="flexCenter my-5">
        <Button
          status="primary"
          width="170px"
          height="50px"
          onClick={submitTimeSlots}
          disabled={loadingBtn}
        >
          <div className="relative">
            {loadingBtn && (
              <div className="absolute h-full w-full flexCenter">
                <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-purple-500"></div>
              </div>
            )}
            {t("Agenda.Save")}
          </div>
        </Button>
      </div>
    </>
  );
};

export default Acte;
