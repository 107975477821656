import { FormLabel, Input } from "@chakra-ui/react";

const renderValueInput = (choix, setRule, newRule, t) => {
  switch (choix.rule) {
    case "isPattern":
      return (
        <>
          <FormLabel className="card-subtitle">
            {t("Survey.SearchedWord")}
          </FormLabel>
          <Input
            required
            fontSize={"14px"}
            onChange={(e) =>
              setRule({ ...newRule, parms: { valuePat: e.target.value } })
            }
          ></Input>
        </>
      );
    case "isLength":
      return (
        <>
          <FormLabel className="card-subtitle">
            {t("Survey.NumberOfCharacters")}
          </FormLabel>
          <Input
            required
            fontSize={"14px"}
            onChange={(e) =>
              setRule({
                ...newRule,
                parms: { islength: parseInt(e.target.value) },
              })
            }
            type={"number"}
          ></Input>
        </>
      );
    case "isMinLength":
      return (
        <>
          <FormLabel className="card-subtitle">
            {t("Survey.MinimumNumberOfCharacters")}
          </FormLabel>
          <Input
            onChange={(e) =>
              setRule({
                ...newRule,
                parms: { minLength: parseInt(e.target.value) },
              })
            }
            required
            fontSize={"14px"}
            type={"number"}
          ></Input>
        </>
      );
    case "isMaxLength":
      return (
        <>
          <FormLabel className="card-subtitle">
            {t("Survey.MaximumNumberOfCharacters")}
          </FormLabel>
          <Input
            required
            onChange={(e) => {
              setRule({
                ...newRule,
                parms: { maxLength: parseInt(e.target.value) },
              });
            }}
            type={"number"}
            fontSize={"14px"}
          ></Input>
        </>
      );
    case "isMinNumber":
      return (
        <>
          <FormLabel className="card-subtitle">
            {" "}
            {t("Survey.Minimum")}
          </FormLabel>
          <Input
            required
            onChange={(e) =>
              setRule({
                ...newRule,
                parms: { inf: parseInt(e.target.value) },
              })
            }
            type={"number"}
            fontSize={"14px"}
          ></Input>
        </>
      );
    case "isMaxNumber":
      return (
        <>
          <FormLabel className="card-subtitle">{t("Survey.Maximum")}</FormLabel>
          <Input
            required
            onChange={(e) =>
              setRule({
                ...newRule,
                parms: { sup: parseInt(e.target.value) },
              })
            }
            fontSize={"14px"}
            type={"number"}
          ></Input>
        </>
      );
    default:
      return <></>;
  }
};
export default renderValueInput;
