import { useState, useEffect } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { confirmEmail, resendVerificationCode } from "../../api/auth";
import Button from "../../components/ButtonUI";
import Input from "../../components/FloatInput";
import history from "../../history";
import makeToast from "../../components/Snackbar";
import "./styles.scss";

const Signin: React.FC = () => {
  const { t } = useTranslation() as any;
  const params = useParams() as any;
  const [counter, setCounter] = useState(60);
  const [interval, SetInterval] = useState() as any;

  useEffect(() => {
    if (counter > 0) {
      SetInterval(
        setInterval(() => {
          setCounter(counter - 1);
        }, 1000)
      );
      return () => clearInterval(interval);
    } else {
      SetInterval(null);
      clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  const onSubmit = async (values) => {
    try {
      const { code } = values;
      const response = await confirmEmail({ email: params.email, code });
      if (response.response === "SUCCESS") {
        makeToast("success", t("SignIn.compteActivated"));
        if (params.role === "doctor") history.push(`patient/login`);
        else history.push(`/login`);

        // if (password) {
        //   const responseLogin = await login({ email: params.email, password });
        //   if (responseLogin.status === "success") {
        //     makeToast("success", "Vous êtes maintenant connecté");
        //     setUser(response.user);
        //   } else history.push(`/check/user`);
        // } else history.push(`/check/user`);
      } else makeToast("warning", t("SignIn.IncorrectCode"));
    } catch (error) {
      makeToast("error", t("SignIn.ErrorSendingCode"));
      console.error("[error] Signin - onSubmit :", error);
    }
  };

  const _resendConfirmationEmail = async () => {
    if (counter > 0) return;
    try {
      //GET EMAIL ADRESS
      if (!params.email) {
        return makeToast("error", t("SignIn.ErrorSendingCode"));
      }
      const res = await resendVerificationCode({ email: params.email });
      if (res) makeToast("success", t("SignIn.EmailCodeSended"));
      setCounter(60);
    } catch (error) {
      makeToast("error", t("SignIn.ErrorSendingCode"));
      console.error("[error] Signin - onSubmit :", error);
    }
  };
  return (
    <div className="login min-h-screen flex justify-center items-center">
      <div className="absolute inset-0 katomiWterMark" />
      <div className="auth-login-Container  ">
        {/* <div className="logo-bloc flexRowCenter">
          <img crossOrigin = "anonymous"  referrerPolicy="origin" src={logo} alt="logo" />
          <p className="logo_text">Katomi</p>
        </div> */}
        <h1 className="h1 title flexRowCenter">{t("SignIn.CheckMail")}</h1>
        <h3 className="text-sm text-white text-center -mt-7 mb-5 max-w-md">
          {t("SignIn.EnterVerificationCode")}
        </h3>
        <div className="formLogin">
          <div className="">
            <Formik
              initialValues={{ code: "" }}
              onSubmit={onSubmit}
              // validationSchema={validationSchema}
            >
              {(props) => {
                const {
                  values,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props;

                return (
                  <>
                    <form onSubmit={handleSubmit} className="form-login">
                      <Input
                        id="code"
                        placeholder={t("SignIn.VerificationCode")}
                        labelText={t("SignIn.VerificationCode")}
                        type="text"
                        // error={errors.email}
                        value={values.code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inlineLabel={false}
                        onfocus={true}
                        autoComplete="off"
                      />

                      <div className="flexRowCenter">
                        <Button
                          type="submit"
                          disabled={
                            isSubmitting || Object.keys(errors).length > 0
                          }
                          status="primary"
                          className="btn"
                        >
                          {t("SignIn.Confirm")}
                        </Button>
                      </div>
                      <p
                        className="card-subtitle text-center mt-3"
                        onClick={_resendConfirmationEmail}
                      >
                        <span
                          className="cursor-pointer"
                          style={{ color: counter > 0 ? "" : "black" }}
                        >
                          {t("SignIn.ResendVerificationCode")} ({counter})
                        </span>
                      </p>
                    </form>
                  </>
                );
              }}
            </Formik>
          </div>
          {/* bloc  */}
        </div>
        {/* <div className="text-white mt-5 font-bold flexColCenter">
          <p
            className="create cursor-pointer"
            onClick={() => history.push("/check/user")}
          >
            se connecter
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default Signin;
