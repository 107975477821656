import { FieldArray, Formik } from "formik";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import Button from "../../ButtonUI";
import Input from "../../Input";
import FloatInput from "../../FloatInput";
import "./styles.scss";
import { addAntecedentChrirgicauxToPatientByDoctor } from "../../../api/antecedents";
import { useAtom } from "jotai";
import { userAtom } from "../../../state/auth";
import { ReactComponent as Close } from "../../../assets/icons/icon-close.svg";
import makeToast from "../../Snackbar";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { BsTrash } from "react-icons/bs";

registerLocale("fr", fr);
setDefaultLocale("fr");

interface Props {
  openModal: boolean;
  setOpenModal: Function;
  doctorId?: string;
  selectedPatient: string;
  setAntecedentsChrirugicalList: Function;
  antecedentsChrirugicalList: [] | any;
}
interface ITraitements {
  traitement: string;
}

const emptyTraitements = { traitement: "" };

const AddAntecedentChirurgicaux: React.FC<Props> = ({
  openModal,
  setOpenModal,
  selectedPatient,
  setAntecedentsChrirugicalList,
  antecedentsChrirugicalList,
}) => {
  const [user] = useAtom(userAtom);
  const [startDate, setStartDate] = useState();

  const { t } = useTranslation() as any;
  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute bg-white z-40 top-1/3   addAntecedentMedicalModal">
        <div className="relative ">
          <div className="flex justify-end mt-2 mr-2">
            <Button status="icon-secondary" onClick={() => setOpenModal(false)}>
              <Close />
            </Button>
          </div>

          <Formik
            initialValues={{
              nature: "Antécédent chirurgical",
              type: "",
              date: startDate,
              observation: "",
              traitements: [emptyTraitements],
            }}
            onSubmit={async (values, { resetForm }) => {
              if (!values.type)
                return makeToast(
                  "warning",
                  t("ModalFichePatient.selectSurgeryType")
                );
              if (!values.traitements?.some((el) => !!el.traitement))
                return makeToast(
                  "warning",
                  t("ModalFichePatient.EnterAtLeastOneIntervention")
                );

              const response = await addAntecedentChrirgicauxToPatientByDoctor({
                ...values,
                date: startDate,
                doctorId: user?._id,
                patientId: selectedPatient,
              });
              setOpenModal(false);
              if (response) {
                setAntecedentsChrirugicalList([
                  ...antecedentsChrirugicalList,
                  response.antecedentsChirugical,
                ]);
                if (response.message === "Success") {
                  makeToast(
                    "success",
                    t("ModalFichePatient.SuccessAddSurgicalHistory")
                  );
                } else {
                  makeToast(
                    "error",
                    t("ModalFichePatient.FailAddSurgicalHistory")
                  );
                }
              }
            }}
          >
            {(props) => {
              const {
                values,
                errors,
                isSubmitting,
                handleChange,
                // setFieldValue,
                handleBlur,
                handleSubmit,
              } = props;
              return (
                <form onSubmit={handleSubmit} className="flex-auto pb-4 px-8">
                  <div className="">
                    <h2 className="mb-8 text-center">
                      {" "}
                      {t("ModalFichePatient.AddASurgicalHistory")}
                    </h2>
                  </div>
                  <div className="flex space-x-10 mb-4">
                    <Input
                      name="type"
                      type="radio"
                      onChange={handleChange}
                      value="Chirurgie Générale"
                      labelText={t("ModalFichePatient.GeneralSurgery")}
                      labelProps={{ className: "pl-2" }}
                    />
                    <Input
                      name="type"
                      labelText={t("ModalFichePatient.PlasticSurgery")}
                      type="radio"
                      error={errors.type}
                      onChange={handleChange}
                      value="Chirurgie Esthétique"
                      labelProps={{ className: "pl-2" }}
                    />
                  </div>
                  <FieldArray name="traitements">
                    {({ push, remove }) => (
                      <div>
                        {values.traitements.map((pre: ITraitements, index) => (
                          <div
                            className="flex min-w-full items-center mt-4 mb-4"
                            key={`treatements-${index}`}
                          >
                            <FloatInput
                              id={`traitements[${index}].traitement`}
                              labelText={t("ModalFichePatient.Intervention")}
                              placeholder={t("ModalFichePatient.Intervention")}
                              type="text"
                              value={values.traitements[index].traitement}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              stylesDiv="flex-1"
                              withoutMB={true}
                            />
                            <div className="flex ml-4 space-x-2">
                              {values.traitements.length > 1 && (
                                <BsTrash
                                  onClick={() => {
                                    if (!isSubmitting) {
                                      remove(index);
                                    }
                                  }}
                                  size={25}
                                  className="cursor-pointer"
                                />
                              )}
                              <Button
                                disabled={isSubmitting}
                                onClick={() => push(emptyTraitements)}
                                width={"30px"}
                                height={"30px"}
                                type="button"
                              >
                                +
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </FieldArray>

                  <DatePicker
                    id="date"
                    locale="fr"
                    className="custom-Input mb-8"
                    selected={startDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => setStartDate(date)}
                    placeholderText={t("ModalFichePatient.interventionDate")}
                    autoComplete="off"
                    showYearDropdown
                  />
                  <FloatInput
                    id="observation"
                    labelText={t("ModalFichePatient.Observation")}
                    placeholder={"ModalFichePatient.EnterObservation"}
                    type="text"
                    value={values.observation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    labelProps={{ className: "w-full" }}
                  />
                  <div className="min-w-full grid justify-items-end">
                    <Button
                      type="submit"
                      disabled={isSubmitting || Object.keys(errors).length > 0}
                      width={"120px"}
                      height={"50px"}
                      status="primary"
                    >
                      {t("ModalFichePatient.Add")}
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddAntecedentChirurgicaux;
