import { SimpleGrid } from "@chakra-ui/react";
import { FieldInput } from "./FieldInput";
import { FormGroup } from "./FormGroup";
import { useField } from "../../../hooks/FormGeneratorHooks";
import { useTranslation } from "react-i18next";
import {
  isEmail,
  isNumber,
} from "../../../hooks/FormGeneratorHooks/validitions";

export const FullIdentity = (props) => {
  const { t } = useTranslation();
  const { errorMessage, id, otherProps } = useField(props);

  const {
    required,
    name,
    fullName,
    numTel,
    numSociale,
    email,
    rules,
    readOnly,
  } = props;
  const { children, label, options, helper, validMessage, ...rest } =
    otherProps;

  const showError = required;

  const formGroupProps = {
    errorMessage,
    helper,
    id,
    isRequired: false,
    label,
    showError,
    name,

    ...rest,
  };

  return (
    <FormGroup {...formGroupProps}>
      <SimpleGrid
        columns={{ base: 2 }}
        borderColor={"gray"}
        rounded={"lg"}
        borderWidth={1}
        p="4"
        spacing="4"
        mb="4"
      >
        {fullName && (
          <>
            <FieldInput
              disabled={readOnly ? true : false}
              name={`${name}.nom`}
              label={t("Survey.firstName")}
              placeholder="Doe"
              required={
                rules && rules[0] && rules[0].required
                  ? t("Survey.RequireField")
                  : false
              }
            />{" "}
            <FieldInput
              disabled={readOnly ? true : false}
              required={
                rules && rules[0] && rules[0].required
                  ? t("Survey.RequireField")
                  : false
              }
              name={`${name}.prenom`}
              label={t("Survey.firstName")}
              placeholder="Jhon"
            />
          </>
        )}
        {email && (
          <>
            <FieldInput
              disabled={readOnly ? true : false}
              name={`${name}.email`}
              type="email"
              label={t("Survey.Email")}
              placeholder="email@adresse.com"
              required={
                rules && rules[0] && rules[0].required
                  ? t("Survey.RequireField")
                  : false
              }
              validations={[
                { rule: isEmail(), message: t("Survey.NonValidMail") },
              ]}
            />
          </>
        )}
        {numTel && (
          <>
            <FieldInput
              disabled={readOnly ? true : false}
              type="number"
              name={`${name}.téléphone`}
              label={t("Survey.NumPhone")}
              placeholder="12345678"
              required={
                rules && rules[0] && rules[0].required
                  ? t("Survey.RequireField")
                  : false
              }
              validations={[
                {
                  rule: isNumber(),
                  message: t("Survey.FieldInvalid"),
                },
              ]}
            />
          </>
        )}
        {numSociale && (
          <>
            <FieldInput
              disabled={readOnly ? true : false}
              name={`${name}.N° de sécurité sociale`}
              label={t("Survey.NumSecSocial")}
              placeholder="1234567890"
              required={
                rules && rules[0] && rules[0].required
                  ? t("Survey.RequireField")
                  : false
              }
              validations={[
                {
                  rule: isNumber(),
                  message: t("Survey.FieldInvalid"),
                },
              ]}
            />
          </>
        )}
      </SimpleGrid>

      {children}
    </FormGroup>
  );
};
