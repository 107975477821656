import { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Divider, Center, Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import FieldCheckbox from "./FieldCheckBoxs";
import FieldRadio from "./FieldRadio";
import TextEditor from "./TextEditor";
import Signature from "./FieldSignature";
import { FieldInput } from "./FieldInput";
import { FieldSelect } from "./FieldSelect";
import { FieldUpload } from "./FieldUpload";
import { FieldTextarea } from "./FieldTextarea";
import { FieldUploadBase64 } from "./FieldUploadBase64";
import { FieldPickIdenticalImages } from "./FieldPickIdenticalImages";
import { FullIdentity } from "./FieldFullIdentity";
import { FullAdress } from "./FieldFullAdress";
import { FieldSlider } from "./FieldSlider";
import { FieldRating } from "./FieldRating";
import { TableField } from "./FieldTable";
import ConditionLogic from "../FormBuilder/PropertyEditor/LogicConditional";
import { validitionRules } from "../../../helper/FormGeneratorHelper/Utils";
import {
  isEmail,
  isNumber,
  isRequired,
} from "../../../hooks/FormGeneratorHooks/validitions";
import { randomPics } from "../../../helper/FormGeneratorHelper/randomListPhotos";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-datepicker/dist/react-datepicker.css";

const FormElement = ({ element, readOnly, form, ...props }) => {
  const { t } = useTranslation();
  const { dropEffect, type, id, label, field_name, rules, ...data } = element;
  const [selectedElm, setSelectedElm] = useState(
    props.selectedElm ? props.selectedElm : null
  );

  useEffect(() => {
    setSelectedElm(props.selectedElm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const renderElement = () => {
    switch (type) {
      case "Header":
        let htmlData: String = data.content;
        htmlData = htmlData.replaceAll("&lt;", "<");
        htmlData = htmlData.replaceAll("&gt;", ">");
        return <h1>{ReactHtmlParser(htmlData)}</h1>;

      case "Paragraph":
        let htmlDataParagraph: String = data.content;
        htmlDataParagraph = htmlDataParagraph.replaceAll("&lt;", "<");
        htmlDataParagraph = htmlDataParagraph.replaceAll("&gt;", ">");
        return <p>{ReactHtmlParser(htmlDataParagraph)}</p>;
      case "LineBreak": {
        const { content } = data;
        let htmlDataLineBreak: String = content;
        htmlDataLineBreak = htmlDataLineBreak.replaceAll("&lt;", "<");
        htmlDataLineBreak = htmlDataLineBreak.replaceAll("&gt;", ">");
        return (
          <Stack direction="row" p={2}>
            <Divider variant="solid" orientation="horizontal" />
            <Center>{ReactHtmlParser(htmlDataLineBreak)}</Center>
            <Divider orientation="horizontal" />
          </Stack>
        );
      }
      case "Email":
        return (
          <FieldInput
            {...data}
            disabled={readOnly ? true : false}
            required={rules && rules[0] && rules[0].required ? true : false}
            value={props.value}
            element={element}
            form={form}
            readOnly={readOnly}
            label={label}
            labelCol={{ className: "mx-2" }}
            key={field_name}
            name={field_name}
            rules={readOnly ? [] : rules}
            validations={
              rules && rules[0].required
                ? [
                    {
                      rule: isRequired(),
                      message: rules[0].message,
                    },
                    { rule: isEmail(), message: t("Survey.NonValidMail") },
                  ]
                : [{ rule: isEmail(), message: t("Survey.NonValidMail") }]
            }
          />
        );
      case "fullAdress":
        return (
          <FullAdress
            element={element}
            {...data}
            readOnly={readOnly ? true : false}
            value={props.value}
            form={form}
            label={label}
            key={field_name}
            name={field_name}
            rules={rules}
          />
        );

      case "Image": {
        if (data.src === "")
          data.src =
            "https://via.placeholder.com/728x90.png?text=Example+Image+";
        return (
          <div className="m-4" style={{ textAlign: "center" }}>
            <img
              crossOrigin="anonymous"
              referrerPolicy="origin"
              src={data.src.base64 ? data.src.base64 : data.src}
              alt={data.alt}
            />
          </div>
        );
      }
      case "TextInput":
        return (
          <FieldInput
            required={rules && rules[0] && rules[0].required ? true : false}
            type="text"
            value={props.value}
            element={element}
            form={form}
            readOnly={readOnly}
            label={label}
            labelCol={{ className: "mx-2" }}
            key={field_name}
            name={field_name}
            rules={readOnly ? [] : rules}
            className="w-1/2 "
            //helper="hello"
            validations={
              rules || field_name === "label" ? validitionRules(rules) : []
            }
            {...data}
          />
        );
      case "captcha":
        return (
          <FieldPickIdenticalImages
            mb="12"
            name={field_name}
            label={t("Survey.FieldIdenticalImages")}
            helper={t("Survey.select2identicalimg")}
            validMessage={t("Survey.the2identicalimg")}
            required={t("Survey.mustSelect2img")}
            validations={[
              {
                rule: (val) => (val || {}).selectedCount === 2,
                message: t("Survey.mustSelectSecondimg"),
              },
              {
                rule: (val) => (val || {}).isIdentical,
                message: t("Survey.imgAreNotIdentical"),
              },
            ]}
            options={randomPics}
          />
        );

      case "NumberInput":
        return (
          <FieldInput
            required={rules && rules[0] && rules[0].required ? true : false}
            value={props.value}
            type="number"
            element={element}
            form={form}
            readOnly={readOnly}
            label={label}
            labelCol={{ className: "mx-2" }}
            key={field_name}
            name={field_name}
            rules={readOnly ? [] : rules}
            validations={
              rules
                ? validitionRules(rules)
                : [
                    {
                      rule: isNumber(),
                      message: t("Survey.InvalidNumber"),
                    },
                  ]
            }
            {...data}
          />
        );

      case "Dropdown":
        return (
          <FieldSelect
            label={label}
            key={field_name}
            name={field_name}
            readOnly={readOnly}
            required={rules && rules[0].required ? true : false}
            validations={rules ? validitionRules(rules) : []}
            {...data}
          />
        );

      case "Checkboxes":
        return (
          <FieldCheckbox
            label={label}
            key={field_name}
            name={field_name}
            disabled={readOnly ? true : false}
            required={rules && rules[0] && rules[0].required ? true : false}
            validations={rules ? validitionRules(rules) : []}
            {...data}
          />
        );
      case "RadioButtons":
        return (
          <FieldRadio
            label={label}
            key={field_name}
            required={rules && rules[0] && rules[0].required ? true : false}
            name={field_name}
            disabled={readOnly ? true : false}
            validations={rules ? validitionRules(rules) : []}
            value={props.value}
            // direction={"column"}
            {...data}
          />
        );
      case "TableField":
        return (
          <TableField
            label={label}
            key={field_name}
            required={rules && rules[0] && rules[0].required ? true : false}
            name={field_name}
            disabled={readOnly ? true : false}
            validations={rules ? validitionRules(rules) : []}
            value={props.value}
            // direction={"column"}
            {...data}
          />
        );
      case "DatePicker":
        //error
        return (
          <FieldInput
            required={rules && rules[0] && rules[0].required ? true : false}
            type="date"
            element={element}
            form={form}
            readOnly={readOnly}
            label={label}
            labelCol={{ className: "mx-2" }}
            key={field_name}
            name={field_name}
            rules={readOnly ? [] : rules}
            validations={rules ? validitionRules(rules) : []}
            {...data}
          />
        );
      case "ident":
        return (
          <FullIdentity
            mb="12"
            name={field_name}
            label={label}
            rules={rules}
            helper={t("Survey.PersonalInformation")}
            {...data}
          />
        );

      case "TimePicker":
        return (
          <FieldInput
            required={rules && rules[0] && rules[0].required ? true : false}
            type="time"
            element={element}
            form={form}
            readOnly={readOnly}
            label={label}
            labelCol={{ className: "mx-2" }}
            key={field_name}
            name={field_name}
            rules={readOnly ? [] : rules}
            validations={rules ? validitionRules(rules) : []}
            {...data}
          />
        );
      case "conditionLogic":
        return (
          <ConditionLogic
            key={field_name}
            name={field_name}
            disabled={readOnly ? true : false}
            value={props.value}
            {...data}
            selectedElm={selectedElm}
            elements={props.elements}
            form={form}
          />
        );
      case "TextArea":
        return (
          <FieldTextarea
            element={element}
            required={rules && rules[0].required ? true : false}
            form={form}
            readOnly={readOnly}
            label={label}
            labelCol={{ className: "mx-2" }}
            key={field_name}
            name={field_name}
            rules={readOnly ? [] : rules}
            {...data}
            validations={rules ? validitionRules(rules) : []}
          />
        );
      case "Rating":
        return (
          <FieldRating
            element={element}
            required={rules && rules[0].required ? true : false}
            form={form}
            readOnly={readOnly}
            label={label}
            labelCol={{ className: "mx-2" }}
            key={field_name}
            name={field_name}
            rules={readOnly ? [] : rules}
            {...data}
            validations={rules ? validitionRules(rules) : []}
          />
        );
      case "Editor":
        return (
          <TextEditor
            el={selectedElm}
            defaultValue={props.value}
            name={field_name}
            {...data}
          />
        );
      case "Range":
        return (
          <FieldSlider
            required={rules && rules[0] && rules[0].required ? true : false}
            element={element}
            form={form}
            readOnly={readOnly}
            label={label}
            labelCol={{ className: "mx-2" }}
            key={field_name}
            name={field_name}
            rules={readOnly ? [] : rules}
            validations={
              rules && rules[0].required
                ? [
                    {
                      rule: isRequired(),
                      message: rules[0].message,
                    },
                  ]
                : []
            }
            {...data}
          />
        );

      case "Signature":
        return (
          <Signature
            readOnly={readOnly}
            disabled={readOnly ? true : false}
            key={field_name}
            name={field_name}
            label={label}
            validations={
              rules && rules[0].required
                ? [
                    {
                      rule: isRequired(),
                      message: rules[0].message,
                    },
                  ]
                : []
            }
            {...data}
          />
        );
      case "File":
        return (
          <FieldUpload
            label={label}
            key={field_name}
            name={field_name}
            readOnly={readOnly}
            validations={
              rules && rules[0].required
                ? [
                    {
                      rule: isRequired(),
                      message: rules[0].message,
                    },
                  ]
                : []
            }
            {...data}
          />
        );
      case "Photo":
        return (
          <FieldUpload
            disabled={readOnly ? true : false}
            label={label}
            key={field_name}
            name={field_name}
            readOnly={readOnly}
            validations={
              rules && rules[0].required
                ? [
                    {
                      rule: isRequired(),
                      message: rules[0].message,
                    },
                  ]
                : []
            }
            {...data}
            listType="picture-card"
          >
            <div>
              <div style={{ marginTop: 8 }}>
                <i className="far fa-images"></i> &nbsp; {t("Survey.Upload")}
              </div>
            </div>
          </FieldUpload>
        );
      case "Photo2":
        return (
          <FieldUploadBase64
            disabled={readOnly ? true : false}
            label={label}
            key={field_name}
            name={field_name}
            readOnly={readOnly}
            validations={
              rules && rules[0].required
                ? [
                    {
                      rule: isRequired(),
                      message: rules[0].message,
                    },
                  ]
                : []
            }
            {...data}
            listType="picture-card"
          >
            <div>
              <div style={{ marginTop: 8 }}>
                <i className="far fa-images"></i> &nbsp; {t("Survey.Upload")}
              </div>
            </div>
          </FieldUploadBase64>
        );

      default:
        return <div> {t("Survey.notYet")}</div>;
    }
  };
  return renderElement();
};

export default FormElement;
