/* eslint-disable eqeqeq */
import React from "react";
import _ from "lodash";
import classNames from "classnames";
import { updateDoctorData } from "../../api/doctor";
import { useAtom } from "jotai";
import { userAtom } from "../../state/auth";
import { useTranslation } from "react-i18next";
import widgetList from "./widgetList.json";

import "./styles.scss";

interface IProps {
  className?: any;
  closeWidegt?: any;
}

const WidgetLists: React.FC<IProps> = ({ className, closeWidegt }) => {
  const { i18n } = useTranslation();
  const [user, setUser] = useAtom(userAtom) as any;
  const AddRemoveWidget = async (name) => {
    const newWidgetStatus = user.dashboardConfig.map((el) =>
      el.name === name ? { ...el, active: !el.active } : el
    );
    const updatedUser = await updateDoctorData({
      dashboardConfig: newWidgetStatus,
    });
    setUser({ ...user, ...updatedUser });
  };
  return (
    <div
      className={classNames(
        "widget shadow-lg py-3 z-10 absolute top-16 md:right-0 bg-white min-w-max rounded-2xl w-96",
        className
      )}
      onBlur={closeWidegt}
    >
      {!_.isEmpty(user.dashboardConfig) &&
        user.dashboardConfig
          .filter(
            (elm) =>
              (elm.name !== "SurveyWidget" ||
                (elm.name === "SurveyWidget" &&
                  !!user?.extensions?.find(
                    (el) => el.name == "Mes questionnaires"
                  )?.active)) &&
              elm.disabled !== true &&
              (!elm.tenant ||
                elm.tenant === process.env.REACT_APP_KATOMI_TENANT)
          )
          .map(({ iconName, title, description, name, active }) => {
            return (
              <div
                className={classNames(
                  "flex mb-2 px-2 rounded-2xl cursor-pointer",
                  active ? "bg-gray-200 bg-opacity-50" : ""
                )}
                onClick={() => AddRemoveWidget(name)}
              >
                <div className="round-icon mx-3 my-auto flex-none flexCenter">
                  <img
                    crossOrigin="anonymous"
                    referrerPolicy="origin"
                    src={
                      iconName === "card-reader"
                        ? require(`../../assets/images/${iconName}.png`).default
                        : require(`../../assets/emoji/emoji-${iconName}.svg`)
                            .default
                    }
                    alt={iconName}
                    width={25}
                  />
                </div>
                <div className="flex-grow-0">
                  <h1 className="widget-title">
                    {" "}
                    {i18n.language.includes("en")
                      ? widgetList?.find((el) => el.name === name)?.titleEn
                      : title}
                  </h1>
                  <p className="text-xs widget-dsecription">
                    {i18n.language.includes("en")
                      ? widgetList?.find((el) => el.name === name)
                          ?.descriptionEn
                      : description}
                  </p>
                </div>
              </div>
            );
          })}
    </div>
  );
};

export default WidgetLists;
