/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { Pie as Pika } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as Toolepa,
  Legend as Legenda,
} from "chart.js";
import { useParams } from "react-router-dom";

import Button from "../../components/ButtonUI";
import Spinner from "../../components/Spinner";
import { downloadExcel, getSurveysStats } from "../../api/survey";
import history from "../../history";
import ExcelIcon from "../../assets/excel.png";

const SurveyStats: React.FC = () => {
  ChartJS.register(ArcElement, Toolepa, Legenda);

  const params: any = useParams();
  const [fieldsData, setFieldsData] = useState() as any[];
  const [patientResponse, setPatientResponse] = useState() as any[];
  const [affectedPatient, setAffectedPatient] = useState();
  const [mensPatientNbr, setMensPatientNbr] = useState(0);
  const [loading, setLoading] = useState(false);
  const { surveryId } = params;

  const _handleDownloadExcel = async () => {
    try {
      const blob = await downloadExcel(surveryId);
      // if (blob === "NO_RESPONSE")
      //   return makeToast(
      //     "warning",
      //     "Pas de réponse trouvée pour cette enquête."
      //   );
      // 2. Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `excel.xlsx`);
      // 3. Append to html page
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      link?.parentNode?.removeChild(link);
    } catch (error) {}
  };

  const getAllSurveysStats = async () => {
    try {
      const res = await getSurveysStats(surveryId);
      if (res) {
        if (res.questionData) setFieldsData(res.questionData);
        if (res.reponsesPerPatient) setPatientResponse(res.reponsesPerPatient);
        if (res.nbrPatiensaffected) setAffectedPatient(res.nbrPatiensaffected);
        if (res.mensNbr) setMensPatientNbr(res.mensNbr);
        setLoading(true);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getAllSurveysStats();
  }, []);
  const countRep = (tab, item, comp) => {
    let i = 0;
    tab.map((x) => {
      if (x[item] === comp) {
        i++;
      }
    });
    return i;
  };

  const datax = {
    labels: ["Homme", "Femme"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          mensPatientNbr,
          patientResponse && patientResponse.length > 0
            ? patientResponse.length - mensPatientNbr
            : 0,
        ],
        backgroundColor: ["rgba(54, 162, 235, 0.2)", "rgba(255, 99, 132, 0.2)"],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 2,
      },
    ],
  };

  return patientResponse && loading ? (
    <div
      className={
        patientResponse.length > 0
          ? " shadow-md  px-6 py-8 bg-white rounded-lg  border relative "
          : ""
      }
    >
      <img
        crossOrigin="anonymous"
        referrerPolicy="origin"
        src={ExcelIcon}
        alt="ExcelIcon"
        width={40}
        className="absolute -top-12 right-1 cursor-pointer p-2 border rounded-lg"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          _handleDownloadExcel();
        }}
      />
      {patientResponse.length < 1 ? (
        <div className=" flexCenter flex flex-col gap-y-4 justify-center mt-96  ">
          <span className="flexCenter">
            Aucune donnée à visualiser pour le moment
          </span>
          <Button
            status="secondary"
            className="p-2 w-24 flexCenter"
            onClick={() => history.goBack()}
          >
            Retour
          </Button>
        </div>
      ) : (
        <>
          {" "}
          <div className=" my-6   ">
            <h1 style={{ fontSize: "24px" }}>Vue d'ensemble</h1>
            <div className="mt-4 ">
              <div className=" grid gap-24 items-center sm:grid-cols-2 lg:grid-cols-2 ">
                <div className="">
                  <div className=" p-5 bg-white rounded-lg border  shadow-md flex justify-center items-center  space-x-4 relative">
                    <div>
                      <div className="flex items-center justify-center w-12 h-12 rounded-full bg-yellow-50 text-green-400">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19.7712 13.1046C20.7714 12.1044 21.3333 10.7478 21.3333 9.33333C21.3333 7.91885 20.7714 6.56229 19.7712 5.5621C18.771 4.5619 17.4145 4 16 4C14.5855 4 13.2289 4.5619 12.2288 5.5621C11.2286 6.56229 10.6667 7.91885 10.6667 9.33333C10.6667 10.7478 11.2286 12.1044 12.2288 13.1046C13.2289 14.1048 14.5855 14.6667 16 14.6667C17.4145 14.6667 18.771 14.1048 19.7712 13.1046Z"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M9.40033 21.4003C11.1507 19.65 13.5246 18.6667 16 18.6667C18.4753 18.6667 20.8493 19.65 22.5997 21.4003C24.35 23.1507 25.3333 25.5246 25.3333 28H6.66666C6.66666 25.5246 7.64999 23.1507 9.40033 21.4003Z"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <div>
                      <div className="text-gray-400">
                        Nombre de participants{" "}
                      </div>
                      <div className="text-2xl font-bold text-gray-900">
                        {affectedPatient}{" "}
                      </div>
                      <span
                        className="absolute right-2 bottom-2"
                        style={{ fontSize: "10px" }}
                      >
                        {patientResponse.length +
                          " répondant(e)s / " +
                          affectedPatient}{" "}
                      </span>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className=" p-5 bg-white rounded-lg shadow-md  border  flex justify-center items-center space-x-4 ">
                      <div>
                        <div className="flex items-center justify-center w-12 h-12 rounded-full bg-blue-100 text-blue-400">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.52325 6.61231C10.2911 5.20443 12.4206 4.32434 14.6667 4.07333V17.3333H27.9267C27.6757 19.5794 26.7956 21.7089 25.3877 23.4767C23.9798 25.2446 22.1013 26.5791 19.9685 27.3265C17.8357 28.0739 15.5351 28.2039 13.3317 27.7015C11.1282 27.1991 9.11142 26.0847 7.51336 24.4866C5.91529 22.8886 4.80094 20.8718 4.29854 18.6683C3.79614 16.4649 3.92612 14.1643 4.67352 12.0315C5.42092 9.89866 6.75535 8.0202 8.52325 6.61231Z"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M20 12H27.3173C26.7188 10.3128 25.7513 8.78047 24.4854 7.5146C23.2195 6.24873 21.6872 5.28125 20 4.68268V12Z"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <div>
                        <div className="text-gray-400">Taux de réponse</div>
                        <div className="text-2xl font-bold text-gray-900">
                          <p className=" card-title">
                            {affectedPatient &&
                              affectedPatient > 0 &&
                              (
                                (patientResponse.length / affectedPatient) *
                                100
                              ).toFixed(1)}{" "}
                            %
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" ">
                  <div className="p-5 bg-white rounded-lg shadow-md border w-full  flex items-center space-x-4">
                    <div>
                      <div className="flex items-center justify-center w-12 h-12 rounded-full bg-red-50 text-red-400">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="red"
                            d="M15 0v2h2.586l-3.399 3.399c-1.169-.875-2.615-1.399-4.187-1.399-3.866 0-7 3.134-7 7 0 3.526 2.609 6.434 6 6.92v2.08h-3v2h3v2h2v-2h3v-2h-3v-2.08c3.391-.486 6-3.395 6-6.92 0-1.572-.524-3.018-1.399-4.187l3.399-3.399v2.586h2v-6h-6zm-5 16c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="text-gray-400">Sexe de Participants</div>
                    <div className="text-gray-400  p-4 bg-white   w-72 ">
                      <Pika data={datax} />
                      <div className="flexCenter text-xs ">
                        <p className="m-1">
                          Homme :{" "}
                          {patientResponse && patientResponse.length > 0
                            ? (
                                (mensPatientNbr / patientResponse.length) *
                                100
                              ).toFixed(1)
                            : "N/A"}
                          %
                        </p>
                        <p className="m-1">
                          Femme :{" "}
                          {patientResponse && patientResponse.length > 0
                            ? (
                                ((patientResponse.length - mensPatientNbr) /
                                  patientResponse.length) *
                                100
                              ).toFixed(1)
                            : "N/A"}
                          %
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h1 style={{ fontSize: "24px" }}>Liste Patient/Question</h1>
          <table className="self-center shadow-md bg-white rounded-lg my-6  ">
            <th
              className="bg-blue-400 border px-2 py-2    color-white w-96 "
              key={"patient"}
            >
              Patients{" "}
            </th>
            {fieldsData.map((item) => {
              return (
                item.header !== "Adresse complète" &&
                item.header !== "Identité" && (
                  <th
                    className="bg-blue-400 border px-1 text-left py-2 color-white font-medium  w-96"
                    key={item.key}
                  >
                    {item.header}
                  </th>
                )
              );
            })}

            {patientResponse.map((item, k) => {
              return (
                <tr className="border py-2  ">
                  <td
                    style={{ width: "130px" }}
                    className="font-bold p-2 bg-blue-200  "
                  >
                    {item.patientName}
                  </td>
                  {fieldsData.map((x, i) => {
                    return (
                      x.header !== "Adresse complète" &&
                      x.header !== "Identité" && (
                        <td className="  px-1 py-2 border text-center ">
                          {item[x.key] ? item[x.key] : "N/A"}
                        </td>
                      )
                    );
                  })}
                </tr>
              );
            })}
          </table>
          <h1 style={{ fontSize: "24px" }}>Liste Question/Patient</h1>
          <div className="shadow-md    my-6 bg-white rounded-lg border w-min">
            <div className="w-max">
              <table className="self-center shadow-md bg-white  rounded-lg">
                <th className="bg-blue-400 border px-2 py-2    color-white ">
                  Questions
                </th>
                {patientResponse.map((elm) => {
                  return (
                    <th className="bg-blue-200 border px-2 py-2 m-2 ">
                      {elm.patientName}
                    </th>
                  );
                })}

                {fieldsData.map((x, i) => {
                  return (
                    x.header !== "Adresse complète" &&
                    x.header !== "Identité" && (
                      <tr>
                        <td className="bg-blue-400 color-white p-2 mx-2 border font-medium ">
                          {x.header}
                        </td>
                        {patientResponse.map((ex) => {
                          return (
                            <td className="p-2 mx-2 border">
                              {ex[x.key] ? ex[x.key] : " N/A "}
                            </td>
                          );
                        })}
                      </tr>
                    )
                  );
                })}
              </table>
            </div>
          </div>
          <div className="overflow-x-auto p-4 bg-white">
            <h1 style={{ fontSize: "24px" }}> Par question</h1>
            {fieldsData.map((item, i) => {
              let opts = [] as any[];
              if (
                item.type === "RadioButtons" ||
                item.type === "Checkboxes" ||
                item.type === "Dropdown"
              ) {
                // item.options.map((opt)=>data.labels.push(opt))

                item.options.map((op) => {
                  opts.push({
                    name: op.label,
                    "Nombre de patients par choix": countRep(
                      patientResponse,
                      item.key,
                      op.label
                    ),
                  });
                });
              }
              return item.type === "RadioButtons" ||
                item.type === "Checkboxes" ||
                item.type === "Dropdown" ? (
                <div
                  className="border rounded-lg p-2 mx-2 my-4 "
                  style={{ background: "#f5f5f9" }}
                >
                  <p className="card-title ">
                    {" "}
                    {i + 1}- {item.header} :
                  </p>
                  <BarChart
                    width={500}
                    height={300}
                    data={opts}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis allowDecimals={false} />
                    <Tooltip />
                    <Legend />

                    <Bar
                      dataKey="Nombre de patients par choix"
                      fill="#4565f6"
                    />
                  </BarChart>
                </div>
              ) : (
                <div
                  className="border rounded-lg p-2 mx-2 my-4 "
                  style={{ background: "#f5f5f9" }}
                >
                  <p className=" card-title ">
                    {i + 0}- {item.header} :{" "}
                  </p>
                  {patientResponse.map((res) => {
                    return (
                      <span className="flex">
                        <p className="text-blue-400 text-sm">
                          {res.patientName + " : "}
                        </p>
                        <p className="text-xs mx-2 flexCenter">
                          {res[item.key] ? res[item.key] : " N/A "}
                        </p>
                      </span>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default SurveyStats;
