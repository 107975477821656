export const AddPatient = {
  fr: {
    //index

    addExistPatient:
      "Ce patient dispose déjà d'un compte sur notre plateforme, souhaitez-vous l'ajouter à votre liste de patients ?",
    NewPatient: "Nouveau patient",
    Injection: "INS - Importer fichier XML",
    AllRequiredFields: "Veuillez remplir tous les champs obligatoires",
    RequireEmail: "Veuillez entrer un email valide ",
    RequireSocialSecNumber:
      "Numéro de sécurité social doit comporter 15 chiffres",
    LimitTier: "Vous avez atteint la limite des patients de votre palier",
    SucessAdd: "Patient ajouté avec succès",
    ExistPatient: "Ce Patient existe déjà.",
    PSWContraint:
      "Le nom du patient doit être écrit en majuscules et ne contenir aucun caractère spécial à l'exception d'une apostrophe ou d'un tiret",
    BirthContraint: "Le code lieu de naissance doit être numérique",
    MultipleIdentitiesFound:
      "Plusieurs identitées trouvées. Merci de saisir le code du lieu de naissance afin d'affiner la recherche.",
    SuccessINS: "INS patient identifiée avec succès",
    NoIdentityFound: "Aucune identité trouvée, modifiez votre recherche",
    //step0
    SearchByCard: "Recherche par carte vitale",
    SearchByLine: "Recherche par trait",
    //step2
    firstNameS2: "Prénoms (séparés par une virgule)",
    birthName: "Nom de naissance",
    pays: "pays",
    paysBirth: "pays de naissance",
    regions: "Régions",
    regionsBirth: "Régions de naissance",
    departmentsBirth: "Départements de naissance",
    communeBirth: "Commune de naissance",
    GouvernoratBirth: "Gouvernorat de naissance",
    DelegationBirth: "Délégation de naissance",
    departments: "Départements",
    commune: "Commune",
    Gouvernorat: "Gouvernorat",
    Delegation: "Délégation",
    placeOfBirth: "Votre lieu de naissance (code insee commune)",
    dateOfBirth: "Date de naissance (JJMMAAAA)",
    YourSex: "Votre sexe",
    INSNumber: "Votre matricule INS (0000000000000)",
    VerificationINS: "Vérification INS",
    INSExist: "Matricule INS existe déja",
    SI: "Identité douteuse",
    FI: "Identité fictive",
    VI: "Identité valide",
    INV: "Identité non vérifié",
    FillAll: "Veuillez remplir tous les champs obligatoires",
    INSRequire: "Veuillez remplir matricule INS",
    PasswordContraint:
      "Le nom du patient doit être écrit en majuscules et ne contenir aucun caractère spécial à l'exception d'une apostrophe ou d'un tiret",
    BirthPlaceContraint: "Le code lieu de naissance doit être numérique",
    //step3
    FirstName: "Prénom",
    LastName: "Nom",
    BirthDate: "Date de naissance (JJ/MM/AAAA)",
    email: "Email",
    Sexe: "Sexe",
    F: "Féminin",
    M: "Masculin",
    Pregnancy: "Grossesse",
    WeekNumber: "Nombre de semaine",
    PhoneNumber: "Numéro de téléphone",
    Address: "Adresse",
    postalCode: "Code postal",
    psw: "Mot de passe",
    next: "Suivant",
    save: "Enregistrer",
    SocialSecurityNumber: "Votre numéro de sécurité sociale (15 Chiffres)",
    TreatingDoctor: "Médecin traitant",
    PhoneTreatingDoctor: "Téléphone médecin traitant",
    AddressTreatingDoctor: "Adresse médecin traitant",
    Handicap: "Handicap",
    Physical: "Moteur",
    Mental: "Mental",
    Helping: "Aidant",
    Trait: "INS - Recherche par trait",
    TestINS: "(tests INS) Injection XML",
    adressOfBirth: "Adresse de naissance",
    actualData: "Coordonnées actuelle",
    Error: "Une erreur s'est produite, Veuillez réessayer plus tard",
    clearInjection: "Réinitialiser injection XML",
    successClear: "Injection réinitialisée avec succès",
  },
  en: {
    //index
    clearInjection: "Clear injection",
    successClear: "Clear injection succeeded",

    actualData: "Current coordinates",
    Error: "An error has occurred, please try again later",
    adressOfBirth: "Birth address",
    regionsBirth: "Regions of birth",
    paysBirth: "country of birth",
    addExistPatient:
      "This patient already has an account on our platform, would you like to add him to your patient list?",
    TestINS: "TEST INS",

    Handicap: "Handicap",
    Physical: "Physical",
    Mental: "Mental",
    Helping: "Helping",
    NewPatient: "New patient",
    Injection: "INS - Import XML file",
    Trait: "INS - Search by line",
    AllRequiredFields: "Please fill in all required fields",
    RequireEmail: "Please enter a valid email",
    RequireSocialSecNumber: "Social security number must be 15 digits",
    LimitTier: "You have reached the limit of patients in your tier",
    SucessAdd: "Patient successfully added",
    ExistPatient: "This patient already exists.",
    PSWContraint:
      "The patient’s name must be capitalized and contain no special characters except an apostrophe or dash",
    BirthContraint: "Place of birth code must be numeric",
    MultipleIdentitiesFound:
      "Multiple identities found. Please enter the place of birth code to refine the search.",
    SuccessINS: "INS successfully identified patient",
    NoIdentityFound: "No identity found, edit your search",

    //step0
    SearchByCard: "Search by 'Carte Vitale'",
    SearchByLine: "Search by traits",
    //step2
    firstNameS2: "First names (separated by a comma)",
    birthName: "Birth name",
    pays: "country",
    regions: "regions",
    departments: "departments",
    commune: "commune",
    Gouvernorat: "Governorate",
    Delegation: "Delegation",
    departmentsrBirth: "departments of birth",
    communerBirth: "Commune of birth",
    GouvernoratrBirth: "Governorate of birth",
    DelegationrBirth: "Delegation of birth",
    placeOfBirth: "Your place of birth (code insee commune)",
    dateOfBirth: "Date of Birth (DDMMYYYY)",
    YourSex: "Your sex",
    INSNumber: "Your INS number (00000000000000000)",
    VerificationINS: "Verification INS",
    INSExist: "INS number already exists",
    SI: "Suspicious identity",
    FI: "Fictitious identity",
    VI: "Valid Identity",
    INV: "Not verified identity",
    FillAll: "Please fill in all required fields",
    INSRequire: "Please fill in INS number",

    PasswordContraint:
      "The patient’s name must be capitalized and contain no special characters except an apostrophe or dash",
    BirthPlaceContraint: "Place of birth code must be numeric",
    //step3
    FirstName: "FirstName",
    LastName: "LastName",
    BirthDate: "Date of Birth (DD/MM/YYYY)",
    Sexe: "Sexe",
    F: "Feminine",
    M: "Male",
    Pregnancy: "Pregnancy",
    PhoneNumber: "Phone number",
    WeekNumber: "Number of weeks",
    Address: "Address",
    postalCode: "Postal code",
    email: "Email",
    psw: "Password",
    next: "Next",
    save: "Save",
    SocialSecurityNumber: "Your social security number",
    TreatingDoctor: "Treating doctor",
    PhoneTreatingDoctor: "Treating doctor phone",
    AddressTreatingDoctor: "Address doctor phone",
  },
};
