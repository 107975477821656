import { useTranslation } from "react-i18next";
import { FieldArray, Formik } from "formik";
import { useState } from "react";
import Button from "../../ButtonUI";
import Input from "../../Input";
import FloatInput from "../../FloatInput";
import "./styles.scss";
// import schemaAntecedentMedicaux from "./schemaAntecedentMedicaux";
import { addAntecedentMedicalToPatientByDoctor } from "../../../api/antecedents";
import { useAtom } from "jotai";
import { userAtom } from "../../../state/auth";
// import { User } from "../../../types/user";
import { ReactComponent as Close } from "../../../assets/icons/icon-close.svg";
import SelectComp from "../../Select";
import makeToast from "../../Snackbar";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import moment from "../../../helper/moment";
import { BsTrash } from "react-icons/bs";

registerLocale("fr", fr);
setDefaultLocale("fr");

interface Props {
  openModal: boolean;
  setOpenModal: Function;
  doctorId?: string;
  selectedPatient: string;
  setAntecedentsList: Function;
  antecedentsList: [] | any;
}
interface ITraitements {
  traitement: string;
}

const emptyTraitements = { traitement: "" };

const AddAntecedentMedicaux: React.FC<Props> = ({
  openModal,
  setOpenModal,
  selectedPatient,
  setAntecedentsList,
  antecedentsList,
}) => {
  const { t } = useTranslation() as any;
  const [user] = useAtom(userAtom);
  const [startDate, setStartDate] = useState();

  const dropdownOptions = [
    {
      value: "Diabéte",
      label: t("ModalFichePatient.Diabetes"),
      labelEn: "Diabete",
    },
    {
      value: "Maladie Cardiovasculaire",
      label: t("ModalFichePatient.CardiovascularDisease"),
      labelEn: "Cardiovascular Disease",
    },
    {
      value: "Maladie Autoimmune",
      label: t("ModalFichePatient.AutoimmuneDisease"),
      labelEn: "Autoimmune disease",
    },
    {
      value: "Herpes ou Bouton de fièvre",
      label: t("ModalFichePatient.HerpesOrFeverBlister"),
      labelEn: "Herpes or Fever Blister",
    },
    { value: "Autres", label: t("ModalFichePatient.Other"), labelEn: "Other" },
  ];

  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute bg-white z-40 top-1/3   addAntecedentMedicalModal">
        <div className="relative ">
          <div className="absolute right-0 mt-1 mr-1">
            <Button status="icon-secondary" onClick={() => setOpenModal(false)}>
              <Close />
            </Button>
          </div>
        </div>

        <Formik
          initialValues={{
            nature: "Antécédent Médical",
            type: "",
            date: startDate,
            observation: "",
            traitements: [emptyTraitements],
            otherType: "",
          }}
          onSubmit={async (values, { resetForm }) => {
            if (!values.nature)
              return makeToast(
                "warning",
                t("ModalFichePatient.ChooseTypeAntecedent")
              );
            if (!values.type) {
              return values.nature === "Antécédent Médical"
                ? makeToast(
                    "warning",
                    t("ModalFichePatient.SelectMedicalAntecedent")
                  )
                : makeToast(
                    "warning",
                    t("ModalFichePatient.FillAllRequireFields")
                  );
            }
            if (values.type === "Autres" && !values.otherType)
              return makeToast(
                "warning",
                t("ModalFichePatient.ChooseTypeOfAntecedent")
              );
            const response = await addAntecedentMedicalToPatientByDoctor({
              ...values,
              date: moment(startDate).format(),
              doctorId: user && user._id,
              patientId: selectedPatient,
            });
            setOpenModal(false);
            if (response) {
              setAntecedentsList([...antecedentsList, response.antecedents]);
              if (response.message === "Success") {
                values.nature === "Antécédent Médical"
                  ? makeToast(
                      "success",
                      t("ModalFichePatient.SuccessMedicalAntecdent")
                    )
                  : makeToast(
                      "success",
                      t("ModalFichePatient.SuccessAddedAllergy")
                    );
              } else {
                makeToast("error", t("ModalFichePatient.AddHistoryField"));
              }
            }

            //   const newAntecedent: IAntecedents = {
            //     ...values,
            //     _id: antecedentList.length,
            //   };
            // setAntecedentList([...antecedentList, newAntecedent]);
            // resetForm();
          }}
          // validationSchema={schemaAntecedentMedicaux}
        >
          {(props) => {
            const {
              values,
              // errors,
              isSubmitting,
              handleChange,
              setFieldValue,
              handleBlur,
              handleSubmit,
            } = props;

            return (
              <form onSubmit={handleSubmit} className="flex-auto py-7 px-8">
                <div className="flex flex-wrap items-baseline mt-2">
                  <h2 className="mb-8 text-center mt-4">
                    {t("ModalFichePatient.AddHistory")}
                  </h2>
                </div>

                <div className="relative">
                  <div className="flex space-x-10 mb-4">
                    <Input
                      name="nature"
                      type="radio"
                      onChange={(e) => {
                        // handleChange(e);
                        setFieldValue("nature", e.target.value);
                      }}
                      value="Antécédent Médical"
                      labelText={t("ModalFichePatient.MedicalHistory")}
                      labelProps={{ className: "pl-2" }}
                      checked={values.nature === "Antécédent Médical"}

                      // defaultChecked
                    />
                    <Input
                      name="nature"
                      labelText={t("ModalFichePatient.Allergy")}
                      type="radio"
                      // error={errors.type}
                      onChange={(e) => {
                        // handleChange(e);
                        setFieldValue("nature", e.target.value);
                      }}
                      checked={values.nature === "Allergie"}
                      value="Allergie"
                      labelProps={{ className: "pl-2" }}
                    />
                  </div>
                </div>
                {values.nature === "Antécédent Médical" ? (
                  <>
                    <SelectComp
                      id="type"
                      // error={errors.type}
                      value={values.type}
                      options={dropdownOptions}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      text={t("ModalFichePatient.SelectMedicalAntecedent")}
                    />
                    {values.type === "Autres" && (
                      <Input
                        name="otherType"
                        placeholder={t("ModalFichePatient.medicalAntecedent")}
                        type="text"
                        // error={errors.type}
                        value={values.otherType}
                        onChange={handleChange}
                        className="w-full rounded-lg mt-5"
                        labelProps={{ className: "pl-2" }}
                      />
                    )}
                  </>
                ) : (
                  <Input
                    name="type"
                    placeholder={t("ModalFichePatient.Allergy")}
                    type="text"
                    // error={errors.type}
                    value={values.type}
                    onChange={handleChange}
                    className="w-full rounded-lg"
                    labelProps={{ className: "pl-2" }}
                  />
                )}
                {/*  */}
                <DatePicker
                  id="date"
                  locale="fr"
                  className="custom-Input my-4"
                  selected={startDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => setStartDate(date)}
                  placeholderText={t("ModalFichePatient.DateOfOnsetOfIllness")}
                  autoComplete="off"
                  showYearDropdown
                />

                <FieldArray name="traitements">
                  {({ push, remove }) => (
                    <div>
                      {values.traitements.map((tra: ITraitements, index) => (
                        <div
                          className="flex min-w-full items-center mt-4 mb-4"
                          key={`treatements-${index}`}
                        >
                          <FloatInput
                            id={`traitements[${index}].traitement`}
                            labelText={t("ModalFichePatient.Treatment")}
                            placeholder={t("ModalFichePatient.Treatment")}
                            type="text"
                            //error={errors.prescription[index].medicaement}
                            value={values.traitements[index].traitement}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            stylesDiv="flex-1"
                            withoutMB={true}
                          />
                          <div className="flex ml-4 space-x-2">
                            {values.traitements.length > 1 && (
                              <BsTrash
                                onClick={() => {
                                  if (!isSubmitting) {
                                    remove(index);
                                  }
                                }}
                                size={25}
                                className="cursor-pointer"
                              />
                            )}
                            <Button
                              disabled={isSubmitting}
                              type="button"
                              onClick={() => push(emptyTraitements)}
                              width={"30px"}
                              height={"30px"}
                            >
                              +
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </FieldArray>
                <FloatInput
                  id="observation"
                  labelText="Observation"
                  placeholder={t("ModalFichePatient.EnterObservation")}
                  type="text"
                  //error={errors.observation}
                  value={values.observation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelProps={{ className: "w-full" }}
                />

                <div className="min-w-full grid justify-items-end">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => handleSubmit()}
                    width={"120px"}
                    height={"50px"}
                    status="primary"
                  >
                    {t("ModalFichePatient.Add")}
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddAntecedentMedicaux;
