/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import * as stripeConfig from "../../config/stripe";
import AddMofiyTarif from "./modal/Tarif";
import { User } from "../../types/user";
import { userAtom } from "../../state/auth";
import { useAtom } from "jotai";
import { AllCards } from "../../components/PaymentCards";
import Icon from "../../components/Icon";
import Modal from "../../components/ModalUI";

const prepareWidth = (i) => {
  switch (i) {
    case 6:
      return 60;
    case 7:
      return 45;
    default:
      return 30;
  }
};

const Tarifs: React.FC<User | any> = () => {
  const { t } = useTranslation() as any;
  const [openModal, setOpenModal] = useState(false);
  const [user] = useAtom<User | null>(userAtom);

  const STRIPE_CLIENT_ID = stripeConfig.STRIPE_CLIENT_ID;

  const prepaeStripeExpressURL = (user) => {
    const scope = "read_write";
    const responseType = "code";
    const prefilledURL = "https://demo-v2.katomi.co";

    return `https://connect.stripe.com/express/oauth/authorize?client_id=${STRIPE_CLIENT_ID}&state=${user._id}&scope=${scope}&response_type=${responseType}&stripe_user[email]=${user.email}&stripe_user[url]=${prefilledURL}&suggested_capabilities[]=transfers`;
  };

  return (
    <div className="tarifs">
      <div className="headerContainer flex items-center relative">
        <Icon name="wallet" isIcon={true} className="svgClass" />
        <p className="header"> {t("Profile.PRICESANDPAYMENTS")}</p>
        <p
          className="absolute right-0	modifier"
          onClick={() => setOpenModal(true)}
        >
          {t("Profile.Update")}
        </p>
      </div>
      <div className="consultationsDetails">
        <ul>
          {user?.pricing == 0 || user?.pricing ? (
            <li>
              <div className="grid grid-cols-4  leading-4">
                <div className="col-span-3">
                  {t("Profile.NormalConsultation")}
                </div>
                <div className="flex justify-end">
                  {user.pricing} {user.currency}
                </div>
              </div>
            </li>
          ) : (
            <></>
          )}
          {user?.pricingNight == 0 || user?.pricingNight ? (
            <li>
              <div className="grid grid-cols-4  leading-4">
                <div className="col-span-3">
                  {t("Profile.NightConsultation")}
                </div>
                <div className="flex justify-end">
                  {user.pricingNight} {user.currency}
                </div>
              </div>
            </li>
          ) : (
            <></>
          )}
          {user?.pricingWeekEnd == 0 || user?.pricingWeekEnd ? (
            <li>
              <div className="grid grid-cols-4  leading-4">
                <div className="col-span-3">
                  {t("Profile.weekendConsultation")}
                </div>
                <div className="flex justify-end">
                  {user.pricingWeekEnd} {user.currency}
                </div>
              </div>
            </li>
          ) : (
            <></>
          )}
          {user?.pricingTeleconsultation == 0 ||
          user?.pricingTeleconsultation ? (
            <li>
              <div className="grid grid-cols-4  leading-4">
                <div className="col-span-3">
                  {t("Profile.teleConsultation")}
                </div>
                <div className="flex justify-end">
                  {user.pricingTeleconsultation} {user.currency}
                </div>
              </div>
            </li>
          ) : (
            <></>
          )}
          {!_.isEmpty(user?.otherPrice) &&
            user?.otherPrice?.map((el) => (
              <li>
                <div className="grid grid-cols-4  leading-4">
                  <div className="col-span-3">{el.tarifLabel}</div>
                  <div className="flex justify-end">
                    {el.price} {user.currency}
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
      {_.isEmpty(user?.stripeAccount) &&
        process.env.REACT_APP_KATOMI_TENANT === "katomiTN" && (
          <div className="flexColCenter">
            <div className="flexCenter flex-wrap my-5 cursor-pointer">
              {AllCards.map((CardComponent, i) => (
                <CardComponent
                  key={i.toString()}
                  style={{
                    width: prepareWidth(i),
                    height: 30,
                    margin: "0 8px",
                  }}
                />
              ))}
            </div>
            <a
              href={prepaeStripeExpressURL(user)}
              target="_blank"
              rel="noreferrer"
            >
              {t("Profile.Stripe")}
            </a>
          </div>
        )}

      <Modal closeModal={() => setOpenModal(false)} showModal={openModal}>
        <AddMofiyTarif openModal={openModal} setOpenModal={setOpenModal} />
      </Modal>
    </div>
  );
};

export default Tarifs;
