import _ from "lodash";
import { useTranslation } from "react-i18next";
import moment from "../../helper/moment";
import Button from "../ButtonUI";
import Icon from "../Icon";

interface Props {
  display: any;
  setModalTraitement: any;
  setTreatementType: any;
  setDisplay: any;
  endTreatment: any;
  treatementEnCours: any;
  setSelectedObservationId: any;
  setObservationModal: any;
  setTypeObservation: any;
  setShowObservationsModal: any;
  treatementsAncien: any;
}

const Traitements = (props: Props) => {
  const {
    display,
    setModalTraitement,
    setTreatementType,
    // setDisplay,
    treatementEnCours,
    endTreatment,
    setSelectedObservationId,
    setObservationModal,
    setTypeObservation,
    setShowObservationsModal,
    treatementsAncien,
  } = props;
  const { t } = useTranslation() as any;
  return (
    <>
      <div
        className={`flex justify-between items-center  ${
          display ? "title-taches" : "title-taches-inside-block"
        }`}
      >
        <div className="sub-title">
          <p> {t("MyPatient.ongoing")}</p>
        </div>
        <div className="flex">
          <Button
            status="secondary"
            className="btnAddtache"
            width={"146px"}
            height={"35px"}
            onClick={() => {
              setModalTraitement(true);
              setTreatementType && setTreatementType("En cours");
            }}
          >
            {t("MyPatient.add")}
          </Button>
        </div>
      </div>
      <div className="taches flex">
        {treatementEnCours && treatementEnCours.length > 0 ? (
          // <HorizontalScroll

          //   style={{
          //     height: "100%",
          //     width: "100%",
          //   }}
          // >
          //   {
          treatementEnCours
            .sort((a, b) => +new Date(b.updatedAt) - +new Date(a.updatedAt))
            .map((traitement) => {
              return (
                <div className="tache my-1">
                  <div className="flex justify-between items-center header">
                    <div className="flex sub-header">
                      <p>{traitement.type}</p>
                    </div>
                    <div className="relative treatment-option">
                      <Icon
                        name="troisPoints"
                        isIcon={true}
                        className="cursor-pointer "
                      />
                      <div className="absolute top-8 p-3 rounded-lg bg-white shadow hidden end-treatment">
                        <p
                          className="cursor-pointer"
                          onClick={() => {
                            endTreatment(traitement);
                          }}
                        >
                          {t("MyPatient.End")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <p className="subTitle">
                    {t("MyPatient.UpdateAt")}
                    <strong>{` ${moment(traitement.updatedAt).format(
                      t("Moment.Z")
                    )} `}</strong>
                  </p>
                  <div className="flex tacheboxContainer">
                    {traitement["medicament"] &&
                      traitement["medicament"].map((medic) => {
                        return (
                          <div className="tache-box max-w-max flex">
                            <Icon
                              name="capsule"
                              isIcon={false}
                              width={14}
                              height={16}
                            />
                            <p>{medic}</p>
                          </div>
                        );
                      })}
                  </div>
                  <p className="addObservation menu flexCenter traitementbtn">
                    {_.isEmpty(traitement.observationsDoctor) ? (
                      <p
                        className="addObservation menu flexCenter cursor-pointer"
                        onClick={() => {
                          setSelectedObservationId(traitement._id);
                          setObservationModal(true);
                        }}
                      >
                        {" "}
                        {t("MyPatient.AddObservation")}
                      </p>
                    ) : (
                      <div className="flexCenter">
                        <div
                          className="menu addObservation"
                          onClick={() => {
                            setTypeObservation({
                              typeObservation: "treatements",
                              idObservation: traitement._id,
                            });
                            setShowObservationsModal(true);
                          }}
                        >
                          {traitement.observationsDoctor.length}{" "}
                          {t("MyPatient.Observations")}
                        </div>
                        <div
                          className="menu ml-6 addObservation cursor-pointer"
                          onClick={() => {
                            setSelectedObservationId(traitement._id);
                            setObservationModal(true);
                          }}
                        >
                          {" "}
                          {t("MyPatient.add2")}
                        </div>
                      </div>
                    )}
                  </p>
                  {/* {traitement.observationsDoctor.length > 0 ? (
                              <div className="flexCenter">
                                <div className="menu addObservation">
                                  {traitement.observationsDoctor.length}{" "}
                                  observations
                                </div>
                                <div className="menu ml-6 addObservation">
                                  Ajouter
                                </div>
                              </div>
                            ) : (
                              <p
                                className="addObservation menu flexCenter traitementbtn cursor-pointer"
                                onClick={() => setObservationModal(true)}
                              >
                                + Ajouter une observation
                              </p>
                            )} */}
                </div>
              );
            })
        ) : (
          //     }
          // </HorizontalScroll>
          <div className="flexCenter w-full anEmptyBox">
            <p className="card-subtitle">
              {" "}
              {t("MyPatient.NoCurrentTreatement")}
            </p>
          </div>
        )}
      </div>
      <div
        className={`flex justify-between items-center  ${
          display ? "title-taches" : "title-taches-inside-block"
        }`}
      >
        <div className="sub-title">
          <p> {t("MyPatient.Old")}</p>
        </div>
        <div className="flex">
          <Button
            status="secondary"
            // style={{ transition: "all .15s ease" }}
            className="btnAddtache"
            width={"146px"}
            height={"35px"}
            onClick={() => {
              setModalTraitement(true);
              setTreatementType && setTreatementType("Ancien");
            }}
          >
            {t("MyPatient.add")}
          </Button>
        </div>
      </div>
      <div className="taches flex">
        {treatementsAncien && treatementsAncien.length > 0 ? (
          // <HorizontalScroll
          //   style={{
          //     height: "100%",
          //     width: "100%",
          //   }}
          // >
          //   {
          treatementsAncien
            .sort((a, b) => +new Date(b.updatedAt) - +new Date(a.updatedAt))
            .map((traitement) => {
              return (
                <div className="tache my-1">
                  <div className="flex justify-between items-center header">
                    <div className="flex sub-header">
                      <p>{traitement.type}</p>
                    </div>
                    {/* <div>
                                  <Icon name="troisPoints" isIcon={true} />
                                </div> */}
                  </div>
                  <p className="subTitle">
                    {t("MyPatient.UpdateAt")}
                    <strong>{` ${moment(traitement.updatedAt).format(
                      t("Moment.Z")
                    )}`}</strong>
                  </p>
                  <div className="flex tacheboxContainer">
                    {traitement["medicament"] &&
                      traitement["medicament"].map((medic) => {
                        return (
                          <div className="tache-box max-w-max flex">
                            <Icon
                              name="capsule"
                              isIcon={false}
                              width={14}
                              height={16}
                            />
                            <p>{medic}</p>
                          </div>
                        );
                      })}
                  </div>
                  <p className="addObservation menu flexCenter traitementbtn">
                    {_.isEmpty(traitement?.observationsDoctor) ? (
                      <p
                        className="addObservation menu flexCenter cursor-pointer"
                        onClick={() => {
                          setSelectedObservationId(traitement._id);
                          setObservationModal(true);
                        }}
                      >
                        {t("MyPatient.AddObservation")}
                      </p>
                    ) : (
                      <div className="flexCenter">
                        <div
                          className="menu addObservation"
                          onClick={() => {
                            setTypeObservation({
                              typeObservation: "treatements",
                              idObservation: traitement._id,
                            });
                            setShowObservationsModal(true);
                          }}
                        >
                          {traitement.observationsDoctor.length}{" "}
                          {t("MyPatient.Observations")}
                        </div>
                        <div
                          className="menu ml-6 addObservation cursor-pointer"
                          onClick={() => {
                            setSelectedObservationId(traitement._id);
                            setObservationModal(true);
                          }}
                        >
                          {t("MyPatient.add2")}
                        </div>
                      </div>
                    )}
                  </p>
                </div>
              );
            })
        ) : (
          //   }
          // </HorizontalScroll>
          <div className="flexCenter w-full anEmptyBox">
            <p className="card-subtitle">
              {" "}
              {t("MyPatient.NoPreviousTreatement")}
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default Traitements;
