import React from "react";
import { useTranslation } from "react-i18next";
import { FieldArray, Formik } from "formik";
import _ from "lodash";
import Button from "../../../components/ButtonUI";
import Input from "../../../components/FloatInput";
import SelectComp from "../../../components/Select";
import "./styles.scss";
import { addLocations } from "../../../api/doctor";
import { useAtom } from "jotai";
import { userAtom } from "../../../state/auth";
import makeToast from "../../../components/Snackbar";
import { User } from "../../../types/user";
import Icon from "../../../components/Icon";
import { BsTrash } from "react-icons/bs";
interface Props {
  openModal: boolean;
  setOpenModal: Function;
  doctorId: string;
}
// interface IAddress {
//   location: boolean;
//   //availibility: Function;
// }

const emptyLocations = {
  location: "",
  name: "",
  type: "Cabinet",
  color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
  tel: "",
  postalCode: "",
  ville: "",
};

const AddressDoctorProfile: React.FC<Props> = ({
  openModal,
  setOpenModal,
  doctorId,
}) => {
  const { t } = useTranslation() as any;
  // const [loading, setLoading] = useState(true);
  const [user, setUser] = useAtom<User | null>(userAtom) as any;
  const onSubmitForm = async (values) => {
    try {
      for (let lct of values.workingLocation) {
        if (
          !lct.location ||
          !lct.name ||
          !lct.tel ||
          !lct.postalCode ||
          !lct.ville ||
          !lct.type ||
          !lct.color
        ) {
          return makeToast("warning", t("Profile.FillAllField"));
        }
      }

      setOpenModal(!openModal);
      const newUser = await addLocations({ ...values, doctorId });
      setUser({ ...user, ...newUser });
      if (newUser._id) {
        makeToast("success", t("Profile.SuccessAddConsultation"));
      } else {
        makeToast("error", t("Profile.FailedUpdateConsultatio"));
      }
    } catch (err) {
      console.log(
        "[error] La mise à jour  de vos consultations  -on Submit :",
        err
      );
    }
  };
  return (
    <div className="cursusAdd">
      <div className="flex justify-end cursor-pointer pt-5 pr-5">
        <Icon
          isIcon={true}
          name="close"
          onClick={() => setOpenModal(false)}
          className="bg-gray-100 rounded-xl cursor-pointer hover:bg-gray-200"
        />
      </div>
      <Formik
        initialValues={{
          workingLocation: _.isEmpty(user?.workingLocation)
            ? [emptyLocations]
            : user?.workingLocation,
        }}
        onSubmit={() => {}}
        //validationSchema={cursusSchema}
      >
        {(props) => {
          const {
            values,
            // errors,
            // isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            // setFieldValue,
          } = props;

          return (
            <form
              onSubmit={handleSubmit}
              className="flex-auto pb-7 px-8 min-w-full"
            >
              <div className="flex flex-wrap items-baseline">
                <h1 className="mb-10">{t("Profile.MyConsultations")}</h1>
              </div>
              <div className="flexCol w-full">
                <FieldArray name="workingLocation">
                  {({ push, remove }) => (
                    <div>
                      {values.workingLocation.map((loc: any, index) => (
                        <div className="flex form-cursus flex-col md:flex-row items-center justify-center mb-5">
                          <Input
                            id={`workingLocation.${index}.name`}
                            placeholder={`${t("Profile.ConsultationName")}`}
                            labelText={`${t("Profile.ConsultationName")}`}
                            type="text"
                            // error={values.workingLocation[index].location}
                            value={values.workingLocation[index].name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inlineLabel={false}
                            onfocus={true}
                            withoutMB
                          />
                          <Input
                            id={`workingLocation.${index}.location`}
                            placeholder={`${t("Profile.ConsultationAddress")}`}
                            labelText={`${t("Profile.ConsultationAddress")}`}
                            type="text"
                            // error={values.workingLocation[index].location}
                            value={values.workingLocation[index].location}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inlineLabel={false}
                            onfocus={true}
                            withoutMB
                          />
                          <Input
                            id={`workingLocation.${index}.ville`}
                            placeholder={`${t("Profile.ConsultationCity")}`}
                            labelText={`${t("Profile.ConsultationCity")}`}
                            type="text"
                            // error={values.workingLocation[index].location}
                            value={values.workingLocation[index].ville}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inlineLabel={false}
                            onfocus={true}
                            withoutMB
                          />
                          <Input
                            id={`workingLocation.${index}.postalCode`}
                            placeholder={`${t(
                              "Profile.ConsultationPostalCode"
                            )}`}
                            labelText={`${t("Profile.ConsultationPostalCode")}`}
                            type="text"
                            // error={values.workingLocation[index].location}
                            value={values.workingLocation[index].postalCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inlineLabel={false}
                            onfocus={true}
                            withoutMB
                          />
                          <Input
                            id={`workingLocation.${index}.tel`}
                            placeholder={`${t("Profile.ConsultationPhone")}`}
                            labelText={`${t("Profile.ConsultationPhone")}`}
                            type="text"
                            // error={values.workingLocation[index].location}
                            value={values.workingLocation[index].tel}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inlineLabel={false}
                            onfocus={true}
                            withoutMB
                          />

                          <SelectComp
                            id={`workingLocation.${index}.type`}
                            options={[
                              { value: "Cabinet", label: t("Profile.Office") },
                              {
                                value: "Clinique",
                                label: t("Profile.Clinical"),
                              },
                              {
                                value: "Hôpital",
                                label: t("Profile.Hospital"),
                              },
                            ]}
                            className="md:ml-4 md:mb-0  w-28 h-14"
                            setText={false}
                            value={values.workingLocation[index].type}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <input
                            id={`workingLocation.${index}.color`}
                            type="color"
                            name={`workingLocation.${index}.color`}
                            className="md:ml-4 h-14"
                            value={values.workingLocation[index].color}
                            onChange={handleChange}
                          />
                          {/* <Button
                            onClick={() => remove(index)}
                            className="ml-2 hidden md:inline"
                            status="secondary"
                            type="button"
                            width="45px"
                            height="45px"
                          >
                            -
                          </Button> */}
                          <BsTrash
                            onClick={() => remove(index)}
                            size={30}
                            className="ml-3 cursor-pointer"
                          />
                        </div>
                      ))}
                      <div className="buttons flexCenter">
                        <Button
                          type="button"
                          width="150px"
                          height="45px"
                          onClick={() =>
                            push({
                              location: "",
                              name: "",
                              type: "Cabinet",
                              color: `#${Math.floor(
                                Math.random() * 16777215
                              ).toString(16)}`,
                              tel: "",
                              postalCode: "",
                              ville: "",
                            })
                          }
                          status="secondary"
                          className="mb-5 md:mb-0 border-dashed mt-4"
                        >
                          + Ajouter
                        </Button>
                      </div>
                    </div>
                  )}
                </FieldArray>
              </div>
              <div className="flex justify-between gap-10 mt-3">
                <Button
                  status="secondary"
                  width="100px"
                  height="50px"
                  className="mb-4 btn-delete"
                  onClick={() => setOpenModal(false)}
                >
                  {t("Profile.Cancel")}
                </Button>
                <Button
                  status="secondary"
                  width="100px"
                  height="50px"
                  className="mb-4"
                  type="submit"
                  onClick={() => onSubmitForm(values)}
                >
                  {t("Profile.Save")}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddressDoctorProfile;
