interface IShape {
    color : any
    points : any
    size : any
    label : any
    shape : any
}
export default class Shape implements IShape{
    color : any
    points : any
    size : any
    label : any
    shape : any
    
    constructor(color, points, size, shape){
        this.color = color;
        this.points = points;
        this.size = size;
        this.label = undefined;
        this.shape = shape;
    }
    getSize(){return Math.round(this.size*100)/100;}
    getPoints(){return this.points;}
    addLabel(label){this.label = label;}
}