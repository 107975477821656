/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import Button from "../../components/ButtonUI";
import Input from "../../components/FloatInput";
import "./styles.scss";
import history from "../../history";
import { forgetPassword, resetPassword } from "../../api/auth";
import makeToast from "../../components/Snackbar";
import voir from "../../assets/images/voir.png";

const ForgetPassword: React.FC = () => {
  const { t } = useTranslation() as any;
  const [counter, setCounter] = useState(10);
  const [interval, SetInterval] = useState() as any;
  const [codeSended, setCodeSended] = useState(false);
  const [passwordFormType1, setPasswordFormType1] = useState("password");
  const [passwordFormType2, setPasswordFormType2] = useState("password");

  useEffect(() => {
    if (counter > 0) {
      SetInterval(
        setInterval(() => {
          setCounter(counter - 1);
        }, 1000)
      );
      return () => clearInterval(interval);
    } else {
      SetInterval(null);
      clearInterval(interval);
    }
  }, [counter]);
  return (
    <div className="login min-h-screen flexCenter forgetPassword">
      <div className="absolute inset-0 katomiWterMark" />
      <div className="auth-login-Container mt-4 ">
        {/* <div className="logo-bloc-katomi flexRowCenter space-x-3 ">
          <img crossOrigin = "anonymous"  referrerPolicy="origin" src={logo} alt="logo" />
          <p className="logo_text">Katomi</p>
        </div> */}

        <div className="flexColCenter">
          <h1 className="title flexCenter text-center">
            {t("SignIn.ResetYourPassword")}
          </h1>
          <h3 className="text-sm text-white text-center -mt-7 mb-5 max-w-md">
            {!codeSended
              ? t("SignIn.EnterMailToReset")
              : t("SignIn.EnterVerificationCode")}
          </h3>
        </div>

        <div className="bg-white rounded-2xl p-5 form-forget-password flexCenter ">
          {/* start */}
          <Formik
            initialValues={{
              email: "",
              verificationCode: "",
              password: "",
              confirmPassword: "",
            }}
            onSubmit={async (values) => {
              try {
                if (!codeSended) {
                  /**
                   * Send Code de verication by email
                   */
                  const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                  if (!validEmailRegex.test(values.email))
                    return makeToast("warning", t("SignIn.EnterValidEmail"));
                  const response = await forgetPassword({
                    email: values.email,
                  });
                  if (response?.message === "Verifier votre email")
                    return makeToast("warning", t("SignIn.CheckYourEmail"));
                  if (response?.status === "success") {
                    makeToast("success", t("SignIn.confirmationCodeSent"));
                    setCodeSended(true);
                  }
                } else {
                  /**
                   * Verif code sent && reset password
                   */

                  const regexPassword =
                    /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{10,}$/;

                  if (!regexPassword.test(values.password))
                    return makeToast("warning", t("SignIn.pswConstraint"));
                  if (values.password !== values.confirmPassword)
                    return makeToast(
                      "warning",
                      t("SignIn.pswConfirmedConstraint")
                    );
                  const resetResponse = await resetPassword({
                    email: values.email,
                    verificationCode: values.verificationCode,
                    newPassword: values.password,
                  });
                  if (resetResponse.status === "success") {
                    makeToast("success", t("SignIn.SuccessReset"));
                    history.push("/check/user");
                  } else {
                    makeToast("error", t("SignIn.ErrorSendEmail"));
                  }
                }
              } catch (error) {
                makeToast("warning", t("SignIn.ErrorSendEmail"));
              }
            }}
          >
            {(props) => {
              const {
                values,
                // errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props;

              return (
                <>
                  <form onSubmit={handleSubmit} className="w-11/12">
                    {codeSended ? (
                      <>
                        <Input
                          id="verificationCode"
                          placeholder={t("SignIn.YourVerificationCode")}
                          labelText={t("SignIn.YourVerificationCode")}
                          type="text"
                          value={values.verificationCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inlineLabel={false}
                          onfocus={true}
                        />
                        <div className="relative">
                          <Input
                            className="pr-10"
                            id="password"
                            type={passwordFormType1}
                            labelText={t("SignIn.NewPSW")}
                            placeholder={t("SignIn.NewPSW")}
                            onChange={handleChange}
                            value={values.password}
                            onBlur={handleBlur}
                            inlineLabel={false}
                            onfocus={true}
                          />
                          <img
                            crossOrigin="anonymous"
                            referrerPolicy="origin"
                            src={voir}
                            alt="voir"
                            className="absolute right-3 top-6 w-4 h-3 cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              setPasswordFormType1(
                                passwordFormType1 === "password"
                                  ? "text"
                                  : "password"
                              );
                            }}
                          />
                        </div>
                        <div className="relative">
                          <Input
                            className="pr-10"
                            id="confirmPassword"
                            type={passwordFormType2}
                            labelText={t("SignIn.ConfirmNewPSW")}
                            placeholder={t("SignIn.ConfirmNewPSW")}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inlineLabel={false}
                            onfocus={true}
                          />
                          <img
                            crossOrigin="anonymous"
                            referrerPolicy="origin"
                            src={voir}
                            alt="voir"
                            className="absolute right-3 top-6 w-4 h-3 cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              setPasswordFormType2(
                                passwordFormType2 === "password"
                                  ? "text"
                                  : "password"
                              );
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <Input
                        id="email"
                        placeholder={t("SignIn.YourMail")}
                        labelText={t("SignIn.YourMail")}
                        type="text"
                        // error={errors.email}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inlineLabel={false}
                        onfocus={true}
                      />
                    )}

                    <div className="flexRowCenter">
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        status="primary"
                        className="btn mt-3"
                        width="250px"
                        height="40px"
                      >
                        {codeSended
                          ? t("SignIn.ResetYourPassword")
                          : t("SignIn.Confirm")}
                      </Button>
                    </div>
                  </form>
                </>
              );
            }}
          </Formik>

          {/* bloc  */}
        </div>
        {/* <div className="create-Account flexColCenter">
          <p className="check-account">Vous avez déjà un compte ?</p>
          <p
            className="create cursor-pointer"
            onClick={() => history.push("/check/user")}
          >
            Se connecter{" "}
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default ForgetPassword;
