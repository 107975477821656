/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import classNames from "classnames";
import { useParams, useHistory } from "react-router-dom";
import { convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import moment from "../../helper/moment";
import {
  editConsultationById,
  getConsultationById,
} from "../../api/consultation";
import { checkTaskStatus } from "../../api/task";
import { generateTasksForDoctor } from "../../api/task";
import { getPatientById } from "../../api/patient";
import { IPatient } from "../../types/patient";
import EndConsultationModal from "./EndConsultationModal";
import Modal from "../../components/ModalUI";
import Button from "../../components/ButtonUI";
import PatientInfo from "../../components/SharedPatientDetails";
import PrescritionModal from "../../components/PrescriptionModal";
import Spinner from "../../components/Spinner";
import Icon from "../../components/Icon";
import makeToast from "../../components/Snackbar";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const Consultation = () => {
  const { t } = useTranslation() as any;
  const params: any = useParams();
  const history: any = useHistory();
  const { idConsultation } = params;
  const [modal, setModal] = useState(false);
  const [statusTask, setStatusTask] = useState() as any;
  const [prescritionModal, setPrescritionModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [consultationDetails, setconsultationDetails] = useState<any>();
  const [autoSaveAt, setAutoSaveAt] = useState(Date.now());
  const [endConsultationModal, setEndConsultationModal] = useState(false);
  const [editorState, setEditorState] = useState<any>();
  const [patientData, setPatientData] = useState<IPatient>();

  const onEditorStateChange = async (newState) => {
    setEditorState(newState);
    setAutoSaveAt(Date.now());
    //TODO change it with onBlur
    try {
      await editConsultationById(idConsultation, {
        doctorNote: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      });
    } catch (error) {}
  };

  const generateDoctorTasks = async () => {
    if (consultationDetails) {
      const { patientId, motif } = consultationDetails;
      await generateTasksForDoctor(idConsultation, patientId?._id, motif);
    }
  };

  const _getConsultationById = async () => {
    setLoading(true);
    try {
      const response = await getConsultationById(idConsultation);
      setLoading(false);
      setconsultationDetails(response.consultation);
    } catch (error) {
      setLoading(false);
    }
  };

  const _getPatientData = async () => {
    setLoading(true);
    try {
      const response = await getPatientById(consultationDetails.patientId._id);
      setPatientData(response?.patient);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const _checkTaskStatus = async () => {
    try {
      const response = await checkTaskStatus(idConsultation);
      setStatusTask(response);
    } catch (error) {}
  };

  useEffect(() => {
    _getConsultationById();
  }, []);

  useEffect(() => {
    if (consultationDetails) _getPatientData();
    if (consultationDetails && consultationDetails.status === "done") {
      makeToast("error", t("Consultation.consultationOver"));
      history.push("/");
    }
  }, [consultationDetails]);

  useEffect(() => {
    _checkTaskStatus();
  }, [prescritionModal, endConsultationModal]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      {patientData && (
        <div className={classNames(!modal && "hidden", "xl:block")}>
          <PatientInfo
            setOpenModal={setModal}
            patientData={patientData}
            setPatientData={setPatientData}
            selectedPatient={patientData._id}
            setModal={() => {}}
            consultaionPage={!modal}
          />
        </div>
      )}

      <Modal
        showModal={prescritionModal}
        closeModal={() => {
          setPrescritionModal(false);
          setEndConsultationModal(true);
        }}
      >
        <PrescritionModal
          closeModal={() => {
            setPrescritionModal(false);
            setEndConsultationModal(true);
          }}
          consultationDetails={consultationDetails}
          // redirection
          // patientData={patientData}
        />
      </Modal>
      <Modal
        showModal={endConsultationModal}
        closeModal={() => setPrescritionModal(false)}
      >
        <EndConsultationModal
          closeModal={() => setEndConsultationModal(false)}
          idConsultation={idConsultation}
          statusTask={statusTask}
          openPrescriptionModal={() => setPrescritionModal(true)}
        />
      </Modal>
      <div className="px-3 xl:px-0 w-full xl:w-1/2 h-screen">
        <div className="flex justify-between flex-col xl:flex-row">
          <div>
            <h2>
              {t("Consultation.NoteFromConsultation")}{" "}
              {moment(consultationDetails?.dateConsultation).format(
                "DD MMMM yyyy"
              )}
            </h2>
            {consultationDetails?.motif && (
              <span className="box">{consultationDetails?.motif}</span>
            )}
          </div>
          <div className="flex items-center mt-5 xl:mt-0 space-x-5">
            <Button
              width="119px"
              height="50px"
              onClick={() => {
                setEndConsultationModal(true);
                generateDoctorTasks();
              }}
            >
              {t("Consultation.End")}
            </Button>
            <Icon
              className="cursor-pointer block xl:hidden"
              isIcon={true}
              name="fiche-patient"
              onClick={() => setModal(!modal)}
            />
          </div>
        </div>
        <div className="mt-5 mb-3 bg-white rounded-lg h-3/4">
          <Editor
            editorState={editorState}
            wrapperClassName="consultation-editor"
            editorClassName="consultation-editor"
            onEditorStateChange={onEditorStateChange}
          />
        </div>
        <div className="text-center">
          <span className="card-subtitle">
            {t("Consultation.autoSaveAt")} {moment(autoSaveAt).format("hh:mm")}✓
          </span>
        </div>
      </div>
    </>
  );
};

export default Consultation;
