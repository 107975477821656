import { Link } from "react-router-dom";
import Button from "../../components/Button";

interface IProps {
  headersTable: string[];
  contentTable: object[];
  deleteFromArray: Function;
  edit?: Boolean;
  consultationHistorique?: Boolean;
  editName?: string;
  nameConsultation?: string;
  supprimer?: Boolean;
  linkEdit?: string;
  viewList?: boolean;
  nameList?: string;
}

const Table: React.FC<IProps> = ({
  supprimer,
  nameConsultation,
  editName,
  headersTable,
  contentTable,
  deleteFromArray,
  edit,
  linkEdit,
  consultationHistorique,
  viewList,
  nameList,
}: IProps) => {
  const deleteItem = (id) => {
    deleteFromArray(id);
  };

  return (
    <table className="w-full py-7 px-12	 table-auto">
      <thead className="justify-between">
        <tr className="bg-gray-200">
          {headersTable.map((name: string) => {
            return (
              <th className="pl-8 pr-4 py-2">
                <span className="text-sm font-mono">{name}</span>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="bg-gray-200">
        {contentTable.map((element: object, index: number) => {
          return (
            <tr className="bg-white border-4 border-gray-200">
              {Object.entries(element)
                .reverse()
                .map(([key, value], index) => {
                  const length = Object.keys(element).length - 1;

                  return index < length && key !== "_id" ? (
                    <td className="px-4 py-2 Center">
                      <p>{value}</p>
                    </td>
                  ) : length === index ? (
                    <td className="flex px-4 py-2">
                      {supprimer && (
                        <Button
                          type="submit"
                          status="danger"
                          onClick={() => deleteItem(key === "_id" && value)}
                          className="w-full px-4 py-2"
                        >
                          Supprimer
                        </Button>
                      )}
                      {edit && (
                        <Button
                          type="submit"
                          status="warning"
                          className="w-full px-4 py-2"
                        >
                          <Link
                            to={{
                              pathname: linkEdit
                                ? linkEdit + "/" + value.toString()
                                : `/patient/consultation/add/${value}`,
                              state: element,
                            }}
                          >
                            {editName}
                          </Link>
                        </Button>
                      )}
                      {consultationHistorique && (
                        <Button
                          type="submit"
                          status="info"
                          className="w-full px-4 py-2"
                        >
                          <Link to={`/patient/${value}/consultations`}>
                            {nameConsultation}
                          </Link>
                        </Button>
                      )}
                      {viewList && (
                        <Button
                          type="submit"
                          status="success"
                          className="w-full px-4 py-2"
                        >
                          <Link
                            to={{
                              pathname: `/patient/listConsultation/${value}`,
                              state: element,
                            }}
                          >
                            {nameList}
                          </Link>
                        </Button>
                      )}
                    </td>
                  ) : null;
                })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
