/* eslint-disable eqeqeq */
/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import moment from "../../helper/moment";
import classNames from "classnames";
import Tooltip from "../toolTip";
import _ from "lodash";
import { userAtom } from "../../state/auth";
import { getOpenTimeSlots } from "../../api/doctor";
import Icon from "../Icon";
import makeToast from "../Snackbar";
import Reset from "../../assets/reset.svg";
import Handshake from "../../assets/images/poigne-de-main.png";
import Syringe from "../../assets/images/syringe.png";
// import { ReactComponent as Tick } from "../../assets/emoji/emoji-tick.svg";

interface IProps {
  withResult?: boolean;
  openTimeSlots?: any;
  handlePickDate: Function;
  doctorDetails: any;
  maxCol?: number;
  firstOpenTimeSlot?: Function;
  cls?: string;
  teleconsultation?: boolean;
}

const Index: React.FC<IProps> = ({
  withResult,
  openTimeSlots: data,
  handlePickDate,
  doctorDetails,
  maxCol,
  firstOpenTimeSlot,
  teleconsultation,
  cls,
}) => {
  const { t } = useTranslation();
  const [user] = useAtom(userAtom) as any;
  const [openTimeSlots, setOpenTimeSlots] = useState(data || []) as any;
  const [loadingTimeSlot, setloadingTimeSlot] = useState(false);
  const [pickedDate, setPickedDate] = useState() as any;
  // resize time Slots
  const [startX, setStartX] = useState(0);
  const [endX, setEndX] = useState(maxCol || 5);
  const [endY, setEndY] = useState(false);

  const _getOpenTimeSlots = async () => {
    try {
      setloadingTimeSlot(true);
      const response = await getOpenTimeSlots(doctorDetails?.agendaId);

      if (response?.data) {
        const groups = response.data.reduce((groups, el) => {
          const date = moment(el.startTime).format("YYYY-MM-DD");
          if (!groups[date]) {
            groups[date] = [];
          }

          groups[date].push(el);
          return groups;
        }, {});

        // Edit: to add it in the array format instead
        const groupArrays = Object.keys(groups).map((date) => ({
          date,
          data: groups[date],
        }));

        setOpenTimeSlots(
          groupArrays
            .filter(
              (el) =>
                !_.isEmpty(el.data) &&
                !el?.data?.every((el) => el.eventStatus === "booked") &&
                !el?.data?.every((el) => el.eventType === "off") &&
                moment(el.date + " " + "23:59").isAfter(moment())
            )
            .sort(
              (a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf()
            )
        );
      }
      setloadingTimeSlot(false);
    } catch (error) {
      console.error(error);
    }
  };

  const filterFirstBookedTimeSlot = (arr) => {
    let test = false;
    let rslt = [] as any;

    if (!arr || _.isEmpty(arr)) return rslt;
    for (const slot of arr) {
      if (test) rslt.push(slot);
      else if (slot.eventStatus === "open") {
        test = true;
        rslt.push(slot);
      }
    }
    return rslt;
  };

  const handleFirstOpenTimeSlot = () => {
    for (const openTimeSlot of openTimeSlots)
      for (const slotOfTheDay of openTimeSlot.data)
        if (
          slotOfTheDay.eventStatus === "open" &&
          moment(slotOfTheDay.startTime).isAfter(moment())
        )
          return slotOfTheDay.startTime;
    return null;
  };

  useEffect(() => {
    if (!data || _.isEmpty(data)) {
      _getOpenTimeSlots();
    } else {
      setOpenTimeSlots(
        data.filter(
          (el) =>
            !_.isEmpty(el.data) &&
            !el?.data?.every((el) => el.eventStatus === "booked") &&
            !el?.data?.every((el) => el.eventType === "off") &&
            moment(el.date + " " + "23:59").isAfter(moment())
        )
      );
    }
  }, [data]);

  useEffect(() => {
    if (!_.isEmpty(openTimeSlots) && firstOpenTimeSlot)
      firstOpenTimeSlot(handleFirstOpenTimeSlot());
  }, [openTimeSlots]);

  return loadingTimeSlot ? (
    <div className="h-36 flexCenter">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
    </div>
  ) : pickedDate && withResult ? (
    <>
      <div className="my-5">
        <p className="md:flex md:items-center md:justify-center">
          {t("Agenda.meetingMedicalon")}
          <span className="bg-gray-200 rounded-lg p-2 text-blue-500 font-bold text-xs ml-2">
            {moment(pickedDate.time).format("DD/MM/YYYY")}
          </span>
          <span className="mx-2"> {t("Agenda.to")} </span>
          <span className="bg-gray-200 rounded-lg p-2 text-blue-500 font-bold text-xs">
            {moment(pickedDate.time).format("HH:mm")}
          </span>
          <span
            className="ml-3 cursor-pointer inline-block"
            onClick={() => setPickedDate()}
          >
            <Tooltip
              tooltipText={t("Agenda.updateSlot")}
              position="left"
              clsText={{ width: "180px" }}
            >
              <img
                crossOrigin="anonymous"
                referrerPolicy="origin"
                src={Reset}
                width={17}
                alt="reset"
              />
            </Tooltip>
          </span>
        </p>
      </div>
    </>
  ) : _.isEmpty(openTimeSlots) ? (
    <h3 className="my-2">
      {user?.role == "DOCTOR" ? t("Agenda.ConfigAgenda") : t("Agenda.NoTS")}
    </h3>
  ) : (
    <div className={`${cls}`}>
      <div className="flex my-2">
        <Icon
          isIcon={true}
          name="direction-gauche"
          className="cursor-pointer"
          onClick={() => {
            if (startX > 0) {
              setStartX(startX - 1);
              setEndX(endX - 1);
            }
          }}
        />
        <div
          className={classNames(
            "flex-1 flex justify-between",
            endY && "max-h-80 overflow-y-scroll"
          )}
        >
          {openTimeSlots &&
            !_.isEmpty(openTimeSlots) &&
            openTimeSlots.slice(startX, endX).map((el) => (
              <div className="w-20 flex flex-col items-center">
                <span className="card-subtitle text-xs">
                  {moment(el.date).format("dddd")}
                </span>
                <span className="card-title">
                  {moment(el.date).format("D MMM")}
                </span>
                {filterFirstBookedTimeSlot(
                  el.data.sort(
                    (a, b) =>
                      new Date(a.startTime).valueOf() -
                      new Date(b.startTime).valueOf()
                  )
                )
                  .filter(({ startTime }) =>
                    moment(startTime).isAfter(moment())
                  )
                  .slice(0, endY ? el.data.length - 1 : 3)
                  .map(({ startTime, eventStatus, eventType, id }) => (
                    <span
                      className={` my-2 relative cursor-pointer ${
                        eventStatus === "open"
                          ? "bg-blue-500 text-white rounded-lg p-2"
                          : "box"
                      }`}
                      onClick={() => {
                        if (eventStatus === "open") {
                          if (user.role === "PATIENT") {
                            if (
                              teleconsultation !== undefined &&
                              teleconsultation &&
                              eventType !== "teleconsultation"
                            ) {
                              makeToast(
                                "warning",
                                t("Agenda.teleconsultationSlotRequire")
                              );
                            } else if (
                              teleconsultation !== undefined &&
                              !teleconsultation &&
                              eventType !== "regular"
                            ) {
                              makeToast(
                                "warning",
                                t("Agenda.consultationSlotRequire")
                              );
                            } else {
                              handlePickDate(
                                {
                                  date: el.date,
                                  time: startTime,
                                  type: eventType,
                                  timeslotId: id,
                                },
                                doctorDetails,
                                eventType
                              );

                              setPickedDate({
                                date: el.date,
                                time: startTime,
                                type: eventType,
                                timeslotId: id,
                              });
                            }
                          }
                          if (user.role === "DOCTOR") {
                            handlePickDate(
                              {
                                date: el.date,
                                time: startTime,
                                type: eventType,
                                timeslotId: id,
                              },
                              doctorDetails,
                              eventType
                            );

                            setPickedDate({
                              date: el.date,
                              time: startTime,
                              type: eventType,
                              timeslotId: id,
                            });
                          }
                        } else
                          makeToast("warning", t("Agenda.SlotNotAvailble"));
                      }}
                    >
                      {moment(startTime).format("HH:mm")}
                      {eventType === "teleconsultation" && (
                        <span className="absolute -right-1 -top-1">
                          <Icon isIcon={true} name="camera" />
                        </span>
                      )}
                      {eventType === "regular" && (
                        <span className="absolute -right-1 -top-1">
                          <img
                            crossOrigin="anonymous"
                            referrerPolicy="origin"
                            src={Handshake}
                            alt="presentiel"
                            width="29"
                            style={{
                              filter:
                                "invert(0%) sepia(8%) saturate(7500%) hue-rotate(33deg) brightness(95%) contrast(107%)",
                            }}
                          />
                        </span>
                      )}
                      {eventType === "act" && (
                        <span className="absolute -right-1 -top-1">
                          <img
                            crossOrigin="anonymous"
                            referrerPolicy="origin"
                            src={Syringe}
                            alt="act"
                            width="15"
                          />
                        </span>
                      )}
                    </span>
                  ))}
              </div>
            ))}
        </div>
        <Icon
          isIcon={true}
          name="direction droite"
          className="cursor-pointer"
          onClick={() => {
            if (endX < openTimeSlots.length) {
              setStartX(startX + 1);
              setEndX(endX + 1);
            }
          }}
        />
      </div>
      {!endY && (
        <div className="flex items-center">
          <span
            className="show-more-hour cursor-pointer"
            onClick={() => {
              setEndY(true);
            }}
          >
            {t("Agenda.MoreTS")}
          </span>
          <hr className="flex-1 px-4" />
          <Icon
            isIcon={true}
            name="plus-horaire"
            className="cursor-pointer"
            onClick={() => {
              setEndY(true);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Index;
