import { useEffect, useState } from "react";
import {
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
} from "@chakra-ui/react";
import { useField } from "../../../hooks/FormGeneratorHooks";
import { FormGroup } from "./FormGroup";

export const FieldInput = (props) => {
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    isValidating,
    resetKey,
    setValue,
    value,
    otherProps,
  } = useField(props);

  const { required, name } = props;

  const { children, label, type, placeholder, helper, ...rest } = otherProps;
  const [isTouched, setIsTouched] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted);
  useEffect(() => {
    setValue(props.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const formGroupProps = {
    errorMessage,
    helper: isTouched ? helper : "",
    id,
    isRequired: !!required,
    label,
    showError,
    name,

    ...rest,
  };

  return (
    <FormGroup {...formGroupProps}>
      <InputGroup>
        <Input
          borderWidth={props.readOnly ? 2 : 1}
          disabled={props.readOnly ? props.readOnly : false}
          type={type || "text"}
          id={id}
          value={value ?? ""}
          onChange={(e) => setValue(e.target.value)}
          onBlur={() => setIsTouched(true)}
          fontSize="14px"
          _invalid={{ borderColor: "Red" }}
          className=" focus:border-blue-500 focus:shadow-sm w-full "
          aria-invalid={showError}
          aria-describedby={!isValid ? `${id}-error` : undefined}
          placeholder={placeholder}
        />
        {(isTouched || isSubmitted) && isValidating && (
          <InputRightElement>
            <Spinner size="sm" flex="none" />
          </InputRightElement>
        )}
      </InputGroup>
      {children}
    </FormGroup>
  );
};
