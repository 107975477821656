export const lot1: any = [
  {
    //1- 1010663220754	60	1.2.250.1.213.1.4.8		D'ARTAGNAN DE L'HERAULT	PIERRE-ALAIN GUNTHER	M	2001-06-17	63220
    //2- 160012B020777	13	1.2.250.1.213.1.4.8		ADRTROIS	TOUSSAINT	M	1960-01-01	2B020
    //3- 2751263220749	74	1.2.250.1.213.1.4.8		ADRUN	ZOE	F	1975-12-31	63220
    //4- 2770763220829	10	1.2.250.1.213.1.4.8		NESSI	RUTH ISABELLE	F	1977-07-14	63220
    //5- 1100863220830	60	1.2.250.1.213.1.4.8		NESSI	MICHELANGELO ANTHONY	M	2010-08-07	63220
    //6- 1100863220831	59	1.2.250.1.213.1.4.8		DE VINCI	DONATELLO THIERRY MICHEL	M	2010-08-07	63220
    //7- 1100863220832	58	1.2.250.1.213.1.4.8		DE VINCI	RAPHAEL	M	2010-08-07	63220
    //8- 1140263220833	56	1.2.250.1.213.1.4.8		DE VINCI	LEONARDO PIERO	M	2014-02-01	63220
    //9- 1090763220834	89	1.2.250.1.213.1.4.8		ECETINSI	PIERRE-ALAIN MURIEL FLORIANT	M	2009-07-14	63220
    //10- 2360663220836	56	1.2.250.1.213.1.4.8		TCHITCHI	CATARINA BELLA	F	1936-06-21	63220
    //11- 180032B020401	23	1.2.250.1.213.1.4.8		CORSE	ANTHONY	M	1980-03-02	2B020
    //12- 1750163220748		1.2.250.1.213.1.4.8		ADRUN	BAPTISTE		1975-01-01
    //13- 1750163220748	46	1.2.250.1.213.1.4.8		ADRUN	BAPTISTE	M	1975-01-01
    //14- 1090763220888	35	1.2.250.1.213.1.4.9		PROVISOIRE	MATRICULE	M	2009-07-01	63220
    //15- 2770763220829	10	1.2.250.1.213.1.4.8		NESSI	NATHALIE	F	1977-07-14	63220
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "60",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "1010663220754",
      },
      traitsIdentite: {
        dateNaissance: "2001-06-17",
        lieuNaissance: "63220",
        nomFamille: "D'ARTAGNAN DE L'HERAULT",
        prenomActeNaissance: "PIERRE-ALAIN GUNTHER",
        prenomUtilise: "PIERRE-ALAIN GUNTHER",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "13",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "160012B020777",
      },
      traitsIdentite: {
        dateNaissance: "1960-01-01",
        lieuNaissance: "2B020",
        nomFamille: "ADRTROIS",
        prenomActeNaissance: "TOUSSAINT",
        prenomUtilise: "TOUSSAINT",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "74",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "2751263220749",
      },
      traitsIdentite: {
        dateNaissance: "1975-12-31",
        lieuNaissance: "63220",
        nomFamille: "ADRUN",
        prenomActeNaissance: "ZOE",
        prenomUtilise: "ZOE",
        sexeAdministratif: "F",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "10",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "2770763220829",
      },
      traitsIdentite: {
        dateNaissance: "1977-07-14",
        lieuNaissance: "63220",
        nomFamille: "NESSI",
        prenomActeNaissance: "RUTH ISABELLE",
        prenomUtilise: "RUTH ISABELLE",
        sexeAdministratif: "F",
      },
    },
  },

  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "60",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "1100863220830",
      },
      traitsIdentite: {
        dateNaissance: "2010-08-07",
        lieuNaissance: "63220",
        nomFamille: "NESSI",
        prenomActeNaissance: "MICHELANGELO ANTHONY",
        prenomUtilise: "MICHELANGELO ANTHONY",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "59",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "1100863220831",
      },
      traitsIdentite: {
        dateNaissance: "2010-08-07",
        lieuNaissance: "63220",
        nomFamille: "DE VINCI",
        prenomActeNaissance: "DONATELLO THIERRY MICHEL",
        prenomUtilise: "DONATELLO THIERRY MICHEL",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "58",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "1100863220832",
      },
      traitsIdentite: {
        dateNaissance: "2010-08-07",
        lieuNaissance: "63220",
        nomFamille: "DE VINCI",
        prenomActeNaissance: "RAPHAEL",
        prenomUtilise: "RAPHAEL",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "56",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "1140263220833",
      },
      traitsIdentite: {
        dateNaissance: "2014-02-01",
        lieuNaissance: "63220",
        nomFamille: "DE VINCI",
        prenomActeNaissance: "LEONARDO PIERO",
        prenomUtilise: "LEONARDO PIERO",
        sexeAdministratif: "M",
      },
    },
  },

  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "89",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "1090763220834",
      },
      traitsIdentite: {
        dateNaissance: "2009-07-14",
        lieuNaissance: "63220",
        nomFamille: "ECETINSI",
        prenomActeNaissance: "PIERRE-ALAIN MURIEL FLORIANT",
        prenomUtilise: "PIERRE-ALAIN MURIEL FLORIANT",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "56",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "2360663220836",
      },
      traitsIdentite: {
        dateNaissance: "1936-06-21",
        lieuNaissance: "63220",
        nomFamille: "TCHITCHI",
        prenomActeNaissance: "CATARINA BELLA",
        prenomUtilise: "CATARINA BELLA",
        sexeAdministratif: "F",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "23",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "180032B020401",
      },
      traitsIdentite: {
        dateNaissance: "1980-03-02",
        lieuNaissance: "2B020",
        nomFamille: "CORSE",
        prenomActeNaissance: "ANTHONY",
        prenomUtilise: "ANTHONY",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "1750163220748",
      },
      traitsIdentite: {
        dateNaissance: "1975-01-01",
        lieuNaissance: "",
        nomFamille: "ADRUN",
        prenomActeNaissance: "BAPTISTE",
        prenomUtilise: "BAPTISTE",
        sexeAdministratif: "",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "46",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "1750163220748",
      },
      traitsIdentite: {
        dateNaissance: "1975-01-01",

        nomFamille: "ADRUN",
        prenomActeNaissance: "BAPTISTE",
        prenomUtilise: "BAPTISTE",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "35",
        identifiantSysteme: "1.2.250.1.213.1.4.9",
        valeur: "1090763220888",
      },
      traitsIdentite: {
        dateNaissance: "2009-07-01",
        lieuNaissance: "63220",
        nomFamille: "PROVISOIRE",
        prenomActeNaissance: "MATRICULE",
        prenomUtilise: "MATRICULE",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "10",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "2770763220829",
      },
      traitsIdentite: {
        dateNaissance: "1977-07-14",
        lieuNaissance: "63220",
        nomFamille: "NESSI",
        prenomActeNaissance: "NATHALIE",
        prenomUtilise: "NATHALIE",
        sexeAdministratif: "F",
      },
    },
  },
];

export const MLot1 = [
  //1- 1010663220754	60	1.2.250.1.213.1.4.8		D'ARTAGNAN DE L'HERAULT	PIERRE-ALAIN GUNTHER	M	2001-06-17	63220
  //2- 160012B020777	13	1.2.250.1.213.1.4.8		ADRTROIS	TOUSSAINT	M	1960-01-01	2B020
  //3- 2751263220749	74	1.2.250.1.213.1.4.8		ADRUN	ZOE	F	1975-12-31	63220
  //4- 1100863220830	60	1.2.250.1.213.1.4.8		NESSI	MICHELANGELO ANTHONY	M	2010-08-07	63220
  //5- 1100863220831	59	1.2.250.1.213.1.4.8		DE VINCI	DONATELLO THIERRY MICHEL	M	2010-08-07	63220
  //6- 1100863220832	58	1.2.250.1.213.1.4.8		DE VINCI	RAPHAEL	M	2010-08-07	63220
  //7- 1140263220833	56	1.2.250.1.213.1.4.8		DE VINCI	LEONARDO PIERO	M	2014-02-01	63220
  //8- 1090763220834	89	1.2.250.1.213.1.4.8		ECETINSI	PIERRE-ALAIN MURIEL FLORIANT	M	2009-07-14	63220
  //9- 2360663220836	56	1.2.250.1.213.1.4.8		TCHITCHI	CATARINA BELLA	F	1936-06-21	63220
  //10- 160012B020777	13	1.2.250.1.213.1.4.8		CORSE	ANTHONY	M	1980-03-02	2B020
  //11- 1750163220748	46	1.2.250.1.213.1.4.8		ADRUN	BAPTISTE	M	1975-01-01
  //12- 1990163220752	30	1.2.250.1.213.1.4.9		PROVISOIRE	MATRICULE	M	2009-07-01	63220

  //13- 2770763220829	10	1.2.250.1.213.1.4.8		NESSI	NATHALIE	F	1977-07-14	63220

  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "60",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "1010663220754",
      },
      traitsIdentite: {
        dateNaissance: "2001-06-17",
        lieuNaissance: "63220",
        nomFamille: "D'ARTAGNAN DE L'HERAULT",
        prenomActeNaissance: "PIERRE-ALAIN GUNTHER",
        prenomUtilise: "PIERRE-ALAIN GUNTHER",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "13",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "160012B020777",
      },
      traitsIdentite: {
        dateNaissance: "1960-01-01",
        lieuNaissance: "2B020",
        nomFamille: "ADRTROIS",
        prenomActeNaissance: "TOUSSAINT",
        prenomUtilise: "TOUSSAINT",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "74",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "2751263220749",
      },
      traitsIdentite: {
        dateNaissance: "1975-12-31",
        lieuNaissance: "63220",
        nomFamille: "ADRUN",
        prenomActeNaissance: "ZOE",
        prenomUtilise: "ZOE",
        sexeAdministratif: "F",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "60",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "1100863220830",
      },
      traitsIdentite: {
        dateNaissance: "2010-08-07",
        lieuNaissance: "63220",
        nomFamille: "NESSI",
        prenomActeNaissance: "MICHELANGELO ANTHONY",
        prenomUtilise: "MICHELANGELO ANTHONY",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "59",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "1100863220831",
      },
      traitsIdentite: {
        dateNaissance: "2010-08-07",
        lieuNaissance: "63220",
        nomFamille: "DE VINCI",
        prenomActeNaissance: "DONATELLO THIERRY MICHEL",
        prenomUtilise: "DONATELLO THIERRY MICHEL",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "58",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "1100863220832",
      },
      traitsIdentite: {
        dateNaissance: "2010-08-07",
        lieuNaissance: "63220",
        nomFamille: "DE VINCI",
        prenomActeNaissance: "RAPHAEL",
        prenomUtilise: "RAPHAEL",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "56",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "1140263220833",
      },
      traitsIdentite: {
        dateNaissance: "2014-02-01",
        lieuNaissance: "63220",
        nomFamille: "DE VINCI",
        prenomActeNaissance: "LEONARDO PIERO",
        prenomUtilise: "LEONARDO PIERO",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "89",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "1090763220834",
      },
      traitsIdentite: {
        dateNaissance: "2009-07-14",
        lieuNaissance: "63220",
        nomFamille: "ECETINSI",
        prenomActeNaissance: "PIERRE-ALAIN MURIEL FLORIANT",
        prenomUtilise: "PIERRE-ALAIN MURIEL FLORIANT",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "56",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "2360663220836",
      },
      traitsIdentite: {
        dateNaissance: "1936-06-21",
        lieuNaissance: "63220",
        nomFamille: "TCHITCHI",
        prenomActeNaissance: "CATARINA BELLA",
        prenomUtilise: "CATARINA BELLA",
        sexeAdministratif: "F",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "13",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "160012B020777",
      },
      traitsIdentite: {
        dateNaissance: "1980-03-02",
        lieuNaissance: "2B020",
        nomFamille: "CORSE",
        prenomActeNaissance: "ANTHONY",
        prenomUtilise: "ANTHONY",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "46",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "1750163220748",
      },
      traitsIdentite: {
        dateNaissance: "1975-01-01",
        lieuNaissance: "",
        nomFamille: "ADRUN",
        prenomActeNaissance: "BAPTISTE",
        prenomUtilise: "BAPTISTE",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "30",
        identifiantSysteme: "1.2.250.1.213.1.4.9",
        valeur: "1990163220752",
      },
      traitsIdentite: {
        dateNaissance: "2009-07-01",
        lieuNaissance: "63220",
        nomFamille: "PROVISOIRE",
        prenomActeNaissance: "MATRICULE",
        prenomUtilise: "MATRICULE",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "10",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "2770763220829",
      },
      traitsIdentite: {
        dateNaissance: "1977-07-14",
        lieuNaissance: "63220",
        nomFamille: "NESSI",
        prenomActeNaissance: "NATHALIE",
        prenomUtilise: "NATHALIE",
        sexeAdministratif: "F",
      },
    },
  },
];

export const MLot2 = [
  //1- 1010663220754	60	1.2.250.1.213.1.4.8		D'ARTAGNAN DE L'HERAULT	PIERRE-ALAIN GUNTHER	M	2001-06-17	63220
  //2- 160012B020777	13	1.2.250.1.213.1.4.8		ADRTROIS	TOUSSAINT	M	1960-01-01	2B020
  //3- 2751263220749	74	1.2.250.1.213.1.4.8		ADRUN	ZOE	F	1975-12-31	63220
  //4- 2770763220829	10	1.2.250.1.213.1.4.8		NESSI	RUTH ISABELLE	F	1977-07-14	63220
  //5- 1100863220830	60	1.2.250.1.213.1.4.8		NESSI	MICHELANGELO ANTHONY	M	2010-08-07	63220
  //6- 1100863220831	59	1.2.250.1.213.1.4.8		DE VINCI	DONATELLO THIERRY MICHEL	M	2010-08-07	63220
  //7- 1100863220832	58	1.2.250.1.213.1.4.8		DE VINCI	RAPHAEL	M	2010-08-07	63220
  //8- 1140263220833	56	1.2.250.1.213.1.4.8		DE VINCI	LEONARDO PIERO	M	2014-02-01	63220
  //9- 1090763220834	89	1.2.250.1.213.1.4.8		ECETINSI	PIERRE-ALAIN MURIEL FLORIANT	M	2009-07-14	63220
  //10- 2360663220836	56	1.2.250.1.213.1.4.8		TCHITCHI	CATARINA BELLA	F	1936-06-21	63220
  //11- 1750163220748	46	1.2.250.1.213.1.4.8		ADRUN	BAPTISTE	M	1975-01-01
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "60",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "1010663220754",
      },
      traitsIdentite: {
        dateNaissance: "2001-06-17",
        lieuNaissance: "63220",
        nomFamille: "D'ARTAGNAN DE L'HERAULT",
        prenomActeNaissance: "PIERRE-ALAIN GUNTHER",
        prenomUtilise: "PIERRE-ALAIN GUNTHER",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "13",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "160012B020777",
      },
      traitsIdentite: {
        dateNaissance: "1960-01-01",
        lieuNaissance: "2B020",
        nomFamille: "ADRTROIS",
        prenomActeNaissance: "TOUSSAINT",
        prenomUtilise: "TOUSSAINT",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "74",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "2751263220749",
      },
      traitsIdentite: {
        dateNaissance: "1975-12-31",
        lieuNaissance: "63220",
        nomFamille: "ADRUN",
        prenomActeNaissance: "ZOE",
        prenomUtilise: "ZOE",
        sexeAdministratif: "F",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "10",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "2770763220829",
      },
      traitsIdentite: {
        dateNaissance: "1977-07-14",
        lieuNaissance: "63220",
        nomFamille: "NESSI",
        prenomActeNaissance: "RUTH ISABELLE",
        prenomUtilise: "RUTH ISABELLE",
        sexeAdministratif: "F",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "60",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "1100863220830",
      },
      traitsIdentite: {
        dateNaissance: "2010-08-07",
        lieuNaissance: "63220",
        nomFamille: "NESSI",
        prenomActeNaissance: "MICHELANGELO ANTHONY",
        prenomUtilise: "MICHELANGELO ANTHONY",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "59",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "1100863220831",
      },
      traitsIdentite: {
        dateNaissance: "2010-08-07",
        lieuNaissance: "63220",
        nomFamille: "DE VINCI",
        prenomActeNaissance: "DONATELLO THIERRY MICHEL",
        prenomUtilise: "DONATELLO THIERRY MICHEL",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "58",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "1100863220832",
      },
      traitsIdentite: {
        dateNaissance: "2010-08-07",
        lieuNaissance: "63220",
        nomFamille: "DE VINCI",
        prenomActeNaissance: "RAPHAEL",
        prenomUtilise: "RAPHAEL",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "56",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "1140263220833",
      },
      traitsIdentite: {
        dateNaissance: "2014-02-01",
        lieuNaissance: "63220",
        nomFamille: "DE VINCI",
        prenomActeNaissance: "LEONARDO PIERO",
        prenomUtilise: "LEONARDO PIERO",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "89",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "1090763220834",
      },
      traitsIdentite: {
        dateNaissance: "2009-07-14",
        lieuNaissance: "63220",
        nomFamille: "ECETINSI",
        prenomActeNaissance: "PIERRE-ALAIN MURIEL FLORIANT",
        prenomUtilise: "PIERRE-ALAIN MURIEL FLORIANT",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "56",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "2360663220836",
      },
      traitsIdentite: {
        dateNaissance: "1936-06-21",
        lieuNaissance: "63220",
        nomFamille: "TCHITCHI",
        prenomActeNaissance: "CATARINA BELLA",
        prenomUtilise: "CATARINA BELLA",
        sexeAdministratif: "F",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "46",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "1750163220748",
      },
      traitsIdentite: {
        dateNaissance: "1975-01-01",
        lieuNaissance: "",
        nomFamille: "ADRUN",
        prenomActeNaissance: "BAPTISTE",
        prenomUtilise: "BAPTISTE",
        sexeAdministratif: "M",
      },
    },
  },
];

export const MLot3 = [
  // 1010663220754	60	1.2.250.1.213.1.4.8		D'ARTAGNAN DE L'HERAULT	PIERRE-ALAIN GUNTHER	M	2001-06-17	63220
  // 160012B020777	13	1.2.250.1.213.1.4.8		ADRTROIS	TOUSSAINT	M	1960-01-01	2B020
  // 2751263220749	74	1.2.250.1.213.1.4.8		ADRUN	ZOE	F	1975-12-31	63220
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "60",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "1010663220754",
      },
      traitsIdentite: {
        dateNaissance: "2001-06-17",
        lieuNaissance: "63220",
        nomFamille: "D'ARTAGNAN DE L'HERAULT",
        prenomActeNaissance: "PIERRE-ALAIN GUNTHER",
        prenomUtilise: "PIERRE-ALAIN GUNTHER",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "13",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "160012B020777",
      },
      traitsIdentite: {
        dateNaissance: "1960-01-01",
        lieuNaissance: "2B020",
        nomFamille: "ADRTROIS",
        prenomActeNaissance: "TOUSSAINT",
        prenomUtilise: "TOUSSAINT",
        sexeAdministratif: "M",
      },
    },
  },
  {
    context: {
      structure: {
        identifiantFacturation: "123456789",
      },
      auteur: {},
    },
    ins: {
      matriculeINS: {
        cle: "74",
        identifiantSysteme: "1.2.250.1.213.1.4.8",
        valeur: "2751263220749",
      },
      traitsIdentite: {
        dateNaissance: "1975-12-31",
        lieuNaissance: "63220",
        nomFamille: "ADRUN",
        prenomActeNaissance: "ZOE",
        prenomUtilise: "ZOE",
        sexeAdministratif: "F",
      },
    },
  },
];
