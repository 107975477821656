/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import moment from "../../../../helper/moment";
import Spinner from "../../../../components/Spinner";
import Modal from "../../../../components/ModalUI";
import ReactHtmlParser from "react-html-parser";
import { loadImage } from "../../../../api/documents";
import Icon from "../../../../components/Icon";
import ShowObservations from "../../../MyPatietns/modal/showObservations";
import AddObservation from "../../../../components/SharedPatientDetails/modal/addObservation";
import { useTranslation } from "react-i18next";

const ShareFolderModal = ({ user, sharedFolderDetails }) => {
  const { t } = useTranslation() as any;
  const [typeObservation, setTypeObservation] = useState({
    typeObservation: "",
    idObservation: "",
  });
  const [observationModal, setObservationModal] = useState(false);
  const [showObservationsModal, setShowObservationsModal] = useState(false);
  const [selectedObservationId, setSelectedObservationId] = useState("");
  const [typeOfAddObservation, setTypeOfAddObservation] = useState("");

  const [loading] = useState<boolean>(false);
  const [antecedentsList, setAntecedentsList] = useState([]) as any;
  const [antecedentsChrirugicalList, setAntecedentsChrirugicalList] = useState(
    [] as any
  );
  const [reports, setReports] = useState<any>([]);
  const [prescriptions, setPrescriptions] = useState<any>([]);
  // const [patientDetails, setPatientDetails] = useState<any>();
  const [images, setImages] = useState<any>();
  const [previewReportModal, setPreviewReportModal] = useState({
    status: false,
    rapport: "",
  });

  const [treatementEnCours, setTreatementEnCours] = useState([]) as any;
  const [treatementsAncien, setTreatementsAncien] = useState([]) as any;

  // useEffect(() => {
  //   async function _getPatientById() {
  //     setLoading(true);
  //     const response = await getPatientById(user._id);
  //     setLoading(false);
  //   }
  //   _getPatientById();
  // }, []);

  const parseImage = (filename, userId, type) => ({
    original: loadImage(userId, type, "thumbnail", filename),
  });

  useEffect(() => {
    sharedFolderDetails?.prescriptions &&
      sharedFolderDetails?.prescriptions.length > 0 &&
      setPrescriptions(sharedFolderDetails.prescriptions);

    sharedFolderDetails?.reports &&
      sharedFolderDetails?.reports.length > 0 &&
      setReports(sharedFolderDetails.reports);

    sharedFolderDetails?.images &&
      sharedFolderDetails?.images.length > 0 &&
      setImages(sharedFolderDetails.images);

    sharedFolderDetails?.antecedents &&
      sharedFolderDetails?.antecedents.length > 0 &&
      setAntecedentsList(sharedFolderDetails?.antecedents);

    sharedFolderDetails?.antecedentsChirugical &&
      sharedFolderDetails?.antecedentsChirugical.length > 0 &&
      setAntecedentsChrirugicalList(sharedFolderDetails?.antecedentsChirugical);

    if (sharedFolderDetails?.treatements) {
      let tCours = [] as any;
      let tAncien = [] as any;
      sharedFolderDetails?.treatements.forEach((e) => {
        if (e.traitement === "Traitement en cours") {
          tCours.push(e);
        } else {
          tAncien.push(e);
        }
      });
      setTreatementEnCours(tCours);
      setTreatementsAncien(tAncien);
    }
  }, [sharedFolderDetails]);

  const [parsedImages, setParsedImages] = useState([]) as any;
  useEffect(() => {
    if (images) {
      const imgs = [] as any;
      images.map((img) => {
        imgs.push(parseImage(img.filePath, img.patientId, img.type));
      });
      setParsedImages(imgs);
    }
  }, [images]);
  return loading ? (
    <Spinner />
  ) : (
    <>
      <Modal
        showModal={previewReportModal?.status}
        closeModal={() =>
          setPreviewReportModal({
            status: false,
            rapport: "",
          })
        }
      >
        <div className="p-5">
          <p>{ReactHtmlParser(ReactHtmlParser(previewReportModal?.rapport))}</p>
        </div>
      </Modal>
      <div className="shared-folder  w-full h-full m-2 flex flex-col pr-3">
        <div className="flex flex-col justify-end h-full m-3 space-y-3">
          <div className="h-full">
            {images && images.length > 0 && (
              <div className="mt-10">
                <div className="flex justify-between">
                  <h3>{t("Specialist.IMAGES")}</h3>
                </div>
                <div className="flexCenter flex-wrap gap-4 mt-7">
                  {parsedImages?.map((el) => {
                    // if (el.metadata && el.metadata.slice(0, 5) === "image") {
                    return (
                      <div className="relative">
                        <img
                          crossOrigin="anonymous"
                          referrerPolicy="origin"
                          src={
                            el.original
                            // process.env.REACT_APP_KATOMI_API_URL + el.filePath
                          }
                          alt="patient-folder"
                          className="rounded-lg"
                          style={{
                            width: "120px",
                            height: "120px",
                          }}
                        />
                      </div>
                    );
                    // }
                  })}
                </div>
              </div>
            )}
            {/* COMPTE RENDU */}
            {reports?.length > 0 && (
              <div className="mt-10">
                <div className="flex justify-between">
                  <h3 className="uppercase">{t("Specialist.Report")}</h3>
                </div>
                <div className="flex flex-wrap justify-between mt-7">
                  {reports.map((el) => (
                    <div
                      className="relative report-card shadow-md"
                      onClick={() => {
                        setPreviewReportModal({ status: true, ...el });
                      }}
                    >
                      <span className="card-title block">
                        {t("Specialist.ConsultationOf")}
                      </span>
                      <span className="block font-normal">
                        {moment(el.dateConsultation).format("DD MMMM YYYY")}
                      </span>
                      <span className="block updated-report">
                        {t("Specialist.UpdateAt")}{" "}
                        {moment(el.updatedAt).format(t("Moment.Z"))}
                      </span>
                      <div className="box max-w-max ">{el.motif}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* PRESCRIPTION */}
            {prescriptions?.length > 0 && (
              <div className="mt-10">
                <div className="flex justify-between">
                  <h3 className="uppercase">{t("Specialist.Prescription")}</h3>
                </div>
                <div className="flex flex-wrap justify-between mt-7">
                  {prescriptions.map((el) => (
                    <div className="relative report-card shadow-md">
                      <span className="card-title block">
                        {t("Specialist.ConsultationOf")}
                      </span>
                      <span className="block font-normal">
                        {moment(el.dateConsultation).format("DD MMMM YYYY")}
                      </span>
                      <span className="block updated-report">
                        Mise à jour le{" "}
                        {moment(el.updatedAt).format(t("Moment.Z"))}
                      </span>
                      <div className="box max-w-max ">{el.motif}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* ANTECEDENT MEDICAUX */}
            {antecedentsList?.length > 0 && (
              <>
                <h3 className="mb-5">{t("Specialist.MedicalAntecedents")}</h3>
                <div className="flex flex-wrap gap-5">
                  {antecedentsList.map((med) => (
                    <div className="card-antecedent">
                      <div className="flex justify-between">
                        <div>
                          <p className="card-title">{med.nature}</p>
                          <span className="block">{med.type}</span>
                        </div>
                      </div>
                      <p className="subTitle">{med.subTitle}</p>
                      <p className="card-subtitle">
                        {t("Specialist.UpdateAt")}{" "}
                        <span className="font-bold">
                          {moment(med.date).format(t("Moment.Z"))}
                        </span>
                      </p>
                      <div className="flex gap-2 my-2">
                        {med["traitements"] &&
                          med["traitements"].map((medic) => (
                            <div className="box max-w-max flex items-center space-x-2">
                              <Icon
                                name="capsule"
                                isIcon={false}
                                width={14}
                                height={16}
                              />
                              <p>{medic.traitement}</p>
                            </div>
                          ))}
                      </div>
                      {med.to ? (
                        <p className="addObservation menu flexCenter">
                          {t("Specialist.AddObservations")}
                        </p>
                      ) : (
                        <div className="flexCenter">
                          <div
                            className="menu addObservation cursor-pointer blue-text"
                            onClick={() => {
                              setTypeObservation({
                                typeObservation: "antecedents",
                                idObservation: med._id,
                              });
                              setShowObservationsModal(true);
                            }}
                          >
                            {med.observationsDoctor.length}{" "}
                            {t("Specialist.observations")}
                          </div>
                          <div
                            className="menu ml-6 addObservation cursor-pointer blue-text"
                            onClick={() => {
                              setSelectedObservationId(med._id);
                              setObservationModal(true);
                              setTypeOfAddObservation("antecedents");
                            }}
                          >
                            {t("Specialist.Add")}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}

            {/* ANTECEDENT CHIRURGICAUX */}
            {antecedentsChrirugicalList?.length > 0 && (
              <>
                <h3 className="mb-5 uppercase">
                  {" "}
                  {t("Specialist.SurgicalAntecedents")}
                </h3>

                <div className="flex flex-wrap gap-5 mt-3">
                  {antecedentsChrirugicalList.map((med) => (
                    <div className="card-antecedent">
                      <div className="flex justify-between">
                        <div>
                          <p className="card-title">{med.nature}</p>
                          <span className="block">{med.type}</span>
                        </div>

                        {/* <Icon name="troisPoints" isIcon={true} /> */}
                      </div>
                      <p className="subTitle">{med.subTitle}</p>
                      <p className="card-subtitle">
                        {t("Specialist.UpdateAt")}{" "}
                        <span className="font-bold">
                          {moment(med.date).format(t("Moment.Z"))}
                        </span>
                      </p>
                      {med.to ? (
                        <p className="addObservation menu flexCenter blue-text">
                          {"+  Ajouter une observation"}
                        </p>
                      ) : (
                        <div className="flexCenter">
                          <div
                            className="menu addObservation cursor-pointer blue-text"
                            onClick={() => {
                              setTypeObservation({
                                typeObservation: "antecedentChirurgicaux",
                                idObservation: med._id,
                              });
                              setShowObservationsModal(true);
                            }}
                          >
                            {med.observationsDoctor.length}{" "}
                            {t("Specialist.observations")}
                          </div>
                          <div
                            className="menu ml-6 addObservation cursor-pointer blue-text"
                            onClick={() => {
                              setSelectedObservationId(med._id);
                              setObservationModal(true);
                              setTypeOfAddObservation("antecedentsChirugical");
                            }}
                          >
                            {t("Specialist.Add")}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}

            {/* TREATMENTS EN COURS */}
            {treatementEnCours?.length > 0 && (
              <>
                <h3 className="mb-5 uppercase">{t("Specialist.Treatment")}</h3>
                <div className="flex flex-wrap gap-5">
                  {treatementEnCours &&
                    treatementEnCours.length > 0 &&
                    treatementEnCours.map((traitement) => (
                      <div className="card-traitement relative report-card ">
                        <div className="flex justify-between items-center">
                          <div className="flex card-title">
                            <p>{traitement.type}</p>
                          </div>
                        </div>
                        <p className="card-subtitle">
                          {t("Specialist.UpdateAt")}
                          <strong>{` ${moment(traitement.updatedAt).format(
                            t("Moment.V")
                          )}`}</strong>
                        </p>
                        <div className="flex">
                          {traitement["medicament"] &&
                            traitement["medicament"].map((medic) => {
                              return (
                                <div className="max-w-max flex">
                                  <Icon
                                    name="capsule"
                                    isIcon={false}
                                    width={14}
                                    height={16}
                                  />
                                  <p>{medic}</p>
                                </div>
                              );
                            })}
                        </div>
                        <p className="addObservation menu flexCenter traitementbtn blue-text">
                          {traitement.observationsDoctor.length === 0 ? (
                            <p
                              className="addObservation menu flexCenter cursor-pointer"
                              onClick={() => {
                                setSelectedObservationId(traitement._id);
                                setObservationModal(true);
                              }}
                            >
                              {t("Specialist.AddObservations")}
                            </p>
                          ) : (
                            <div className="flexCenter">
                              <div
                                className="menu addObservation cursor-pointer"
                                onClick={() => {
                                  setTypeObservation({
                                    typeObservation: "treatements",
                                    idObservation: traitement._id,
                                  });
                                  setShowObservationsModal(true);
                                }}
                              >
                                {traitement.observationsDoctor.length}{" "}
                                {t("Specialist.observations")}
                              </div>
                              <div
                                className="menu ml-6 addObservation cursor-pointer"
                                onClick={() => {
                                  setSelectedObservationId(traitement._id);
                                  setObservationModal(true);
                                }}
                              >
                                {t("Specialist.Add")}
                              </div>
                            </div>
                          )}
                        </p>
                      </div>
                    ))}
                </div>
              </>
            )}

            {/* TREATMENTS EN ANCIEN */}
            {treatementsAncien?.length > 0 &&
              treatementsAncien.map((traitement) => {
                return (
                  <>
                    <h3 className="mb-5 uppercase">
                      {" "}
                      {t("Specialist.AncientTreatments")}
                    </h3>
                    <div className="card-traitement relative report-card mt-5">
                      <div className="flex justify-between items-center">
                        <div className="flex card-title">
                          <p>{traitement.type}</p>
                        </div>
                        {/* <div>
                          <Icon name="troisPoints" isIcon={true} />
                        </div> */}
                      </div>
                      <p className="card-subtitle">
                        {t("Specialist.UpdateAt")}
                        <strong>{` ${moment(traitement.updatedAt).format(
                          t("Moment.V")
                        )}`}</strong>
                      </p>
                      <div className="flex">
                        {traitement["medicament"] &&
                          traitement["medicament"].map((medic) => {
                            return (
                              <div className="max-w-max flex">
                                <Icon
                                  name="capsule"
                                  isIcon={false}
                                  width={14}
                                  height={16}
                                />
                                <p>{medic}</p>
                              </div>
                            );
                          })}
                      </div>
                      <p className="addObservation menu flexCenter traitementbtn blue-text">
                        {traitement.observationsDoctor.length &&
                        traitement.observationsDoctor.length === 0 ? (
                          <p
                            className="addObservation menu flexCenter"
                            onClick={() => {
                              setSelectedObservationId(traitement._id);
                              setObservationModal(true);
                            }}
                          >
                            {t("Specialist.AddObservations")}
                          </p>
                        ) : (
                          <div className="flexCenter">
                            <div
                              className="menu addObservation cursor-pointer"
                              onClick={() => {
                                setTypeObservation({
                                  typeObservation: "treatements",
                                  idObservation: traitement._id,
                                });
                                setShowObservationsModal(true);
                              }}
                            >
                              {traitement.observationsDoctor.length}{" "}
                              {t("Specialist.observations")}
                            </div>
                            <div
                              className="menu ml-6 addObservation cursor-pointer"
                              onClick={() => {
                                setSelectedObservationId(traitement._id);
                                setObservationModal(true);
                              }}
                            >
                              {t("Specialist.Add")}
                            </div>
                          </div>
                        )}
                      </p>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
        {showObservationsModal && (
          <ShowObservations
            setOpenModal={setShowObservationsModal}
            selectedPatient={sharedFolderDetails.patient}
            openModal={observationModal}
            typeObservationObject={typeObservation}
            patientId={sharedFolderDetails.patient}
          />
        )}
        {observationModal && (
          <AddObservation
            setOpenModal={setObservationModal}
            selectedPatient={sharedFolderDetails.patient}
            selectedObservationId={selectedObservationId}
            openModal={observationModal}
            typeOfAddObservation={typeOfAddObservation}
            setAntecedentsList={setAntecedentsList}
            setAntecedentsChrirugicalList={setAntecedentsChrirugicalList}
            antecedentsList={antecedentsList}
            antecedentsChrirugicalList={antecedentsChrirugicalList}
            setTypeOfAddObservation={setTypeOfAddObservation}
          />
        )}
      </div>
    </>
  );
};

export default ShareFolderModal;
