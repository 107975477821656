import CloseModal from "./CloseModal";
import classNames from "classnames";

export default function Modal({
  closeModal,
  showModal,
  children,
  slideModal,
  closeIcon,
  closeAbsolute,
  customWidth,
}: any) {
  return showModal ? (
    slideModal ? (
      <div className="fixed inset-0 z-50 overflow-y-auto ">
        <div
          className="fixed inset-0 w-full h-full bg-black opacity-50 "
          onClick={closeModal}
        />
        <div className="flex justify-end min-h-screen py-4 pr-2 ">
          <div
            className={classNames(
              "relative w-full  h-slide-modal  bg-white rounded-2xl shadow-lg overflow-auto ml-2 md:ml-0",
              customWidth ? customWidth : "max-w-2xl"
            )}
          >
            {closeIcon && <CloseModal toggle={closeModal} />}
            {children}
          </div>
        </div>
      </div>
    ) : (
      <div className="fixed inset-0 z-50 overflow-y-auto ">
        <div
          className="fixed inset-0 w-full h-full bg-black opacity-50 "
          onClick={closeModal}
        />
        <div className="flex items-center min-h-screen px-4 py-8">
          <div className="relative p-4 mx-auto bg-white rounded-md shadow-lg border-4">
            {closeIcon && (
              <CloseModal toggle={closeModal} absolute={closeAbsolute} />
            )}
            {children}
          </div>
        </div>
      </div>
    )
  ) : null;
}
