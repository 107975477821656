import React, { useEffect } from "react";
import _ from "lodash";
import classNames from "classnames";
import { useAtom } from "jotai";
import TimePicker from "react-time-picker";
import { v4 as uuidv4 } from "uuid";
/** API & HELPER */
import { userAtom } from "../../state/auth";
import { setAgendaConfiguration } from "../../api/doctor";
import IAgendaConfigItem from "../../types/IAgendaConfigItem";
/** COMPONENTS */
import Button from "../ButtonUI";
import SelectComp from "../Select";
import makeToast from "../Snackbar";
import { useTranslation } from "react-i18next";

interface Props {
  closeModal: any;
  selectedWorkLocation: string;
}

const duree = [
  { label: "15 min", value: "15" },
  { label: "30 min", value: "30" },
  { label: "45 min", value: "45" },
  { label: "1h", value: "60" },
];

const TeleconsultationsForm = (props: Props) => {
  const { t, i18n } = useTranslation();
  const { closeModal, selectedWorkLocation } = props;
  const [user, setUser] = useAtom(userAtom) as any;
  const [refreshConfigurations, setRefreshConfigurations] =
    React.useState<boolean>(false);
  const [loadingBtn, setLoadingBtn] = React.useState<boolean>(false);
  const [selectedInput, setSelectedInput] = React.useState("");
  const [duration, setDuration] = React.useState(30);
  const [configurations, setConfigurations] = React.useState<object>({
    [uuidv4()]: {
      start: "09:00",
      end: "18:00",
      days: ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"],
    },
  });
  const DAYS_IN_WEEK = i18n.language.includes("en")
    ? [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ]
    : ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];

  // const handleDaysBeforSubmit = d => {
  //   switch (d) {
  //     case "Monday":
  //       return "Lundi"
  //     case "Tuesday":
  //       return "Mardi"
  //     case "Wednesday":
  //       return "Mercredi"
  //     case "Thursday":
  //       return "Jeudi"
  //     case "Friday":
  //       return "Vendredi"
  //     case "Saturday":
  //       return "Samedi"
  //     case "Sunday":
  //       return "Dimanche"

  //     default:
  //       return d;
  //   }
  // }

  const submitTimeSlots = async () => {
    try {
      setLoadingBtn(true);
      const config: IAgendaConfigItem[] = Object.values(configurations);
      await setAgendaConfiguration({
        config,
        type: "teleconsultation",
        timeSlotDuration: duration,
        location: selectedWorkLocation,
      });
      makeToast(
        "success",
        "La configuration de votre agenda a été bien mise à jour"
      );

      setUser({
        ...user,
        AgendaConfigTeleconsultation: { config, timeSlotDuration: duration },
      });

      setLoadingBtn(false);
      closeModal();
    } catch (error) {
      setLoadingBtn(false);
      makeToast(
        "error",
        "Quelque chose s'est mal passé. Veuillez réessayer plus tard"
      );
      console.error(error);
    }
  };

  useEffect(() => {
    let config = {};
    if (!_.isEmpty(user?.AgendaConfigTeleconsultation)) {
      // eslint-disable-next-line array-callback-return
      user?.AgendaConfigTeleconsultation.config.map((el) => {
        config = {
          ...config,
          [uuidv4()]: {
            start: el.start,
            end: el.end,
            days: el.days,
          },
        };
      });
      setDuration(user?.AgendaConfigTeleconsultation?.timeSlotDuration);
      setConfigurations(config);
    }
    setRefreshConfigurations(!refreshConfigurations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //force refresh
  useEffect(() => {
    setConfigurations(configurations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshConfigurations]);

  return (
    <div className="">
      {Object.keys(configurations).map((confItemKey) => {
        return (
          <div className="relative">
            <div className="flex justify-end mt-4">
              <div
                className=" rounded-lg bg-gray-200 bg-opacity-50 cursor-pointer px-2 py-1"
                onClick={() => {
                  let tempConfigurations = configurations;
                  delete tempConfigurations[confItemKey];
                  setConfigurations(tempConfigurations);
                  setRefreshConfigurations(!refreshConfigurations);
                }}
              >
                X
              </div>
            </div>
            <div className="workingDays flexCenter flex-wrap gap-4">
              {DAYS_IN_WEEK.map((day) => {
                return (
                  <div
                    className={classNames(
                      "day flexCenter cursor-pointer",
                      configurations[confItemKey].days.includes(day)
                        ? "active-box"
                        : "disabled-box"
                    )}
                    onClick={() =>
                      setConfigurations({
                        ...configurations,
                        [confItemKey]: {
                          ...configurations[confItemKey],
                          days: configurations[confItemKey].days.includes(day)
                            ? configurations[confItemKey].days.filter(
                                (d) => day !== d
                              )
                            : [...configurations[confItemKey].days, day],
                        },
                      })
                    }
                  >
                    <p>{day}</p>
                  </div>
                );
              })}
              {configurations[confItemKey].days.length < 7 ? (
                <Button
                  status="secondary"
                  width="222px"
                  height="40px"
                  // style={{ padding: "0 10px" }}
                  onClick={() => {
                    setConfigurations({
                      ...configurations,
                      [confItemKey]: {
                        ...configurations[confItemKey],
                        days: DAYS_IN_WEEK,
                      },
                    });
                  }}
                >
                  {t("Agenda.ResetWeek")}
                </Button>
              ) : null}
            </div>
            <div className="plageHoraire flexCenter gap-x-2  ">
              <p> {t("Agenda.RangeTeleconsultation")}</p>
              <div className="flexCenter">
                <div
                  className="flexCenter sub-values"
                  onClick={() => {
                    setSelectedInput(confItemKey + "-TemperatureTime");
                  }}
                >
                  {selectedInput === confItemKey + "-TemperatureTime" ? (
                    <TimePicker
                      onChange={(start) => {
                        if (start !== null)
                          setConfigurations({
                            ...configurations,
                            [confItemKey]: {
                              ...configurations[confItemKey],
                              start,
                            },
                          });
                      }}
                      format="HH:mm"
                      value={configurations[confItemKey].start}
                      onClockClose={() => {
                        setSelectedInput("");
                      }}
                      onClockOpen={() => {}}
                    />
                  ) : (
                    <p className="value flexCenter cursor-pointer">
                      {configurations[confItemKey].start !== 0
                        ? configurations[confItemKey].start
                        : "00:00"}
                    </p>
                  )}
                </div>
                <p>{t("Agenda.to")}</p>
                <div
                  className="flexCenter sub-values"
                  onClick={() => {
                    setSelectedInput(confItemKey + "-TemperatureTime");
                  }}
                >
                  {selectedInput === confItemKey + "-TemperatureTime" ? (
                    <TimePicker
                      onChange={(end) => {
                        if (end !== null)
                          setConfigurations({
                            ...configurations,
                            [confItemKey]: {
                              ...configurations[confItemKey],
                              end,
                            },
                          });
                      }}
                      format="HH:mm"
                      value={configurations[confItemKey].end}
                      onClockClose={() => {
                        setSelectedInput("");
                      }}
                      onClockOpen={() => {}}
                    />
                  ) : (
                    <p className="value flexCenter cursor-pointer">
                      {configurations[confItemKey].end !== 0
                        ? configurations[confItemKey].end
                        : "00:00"}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div className="flexCenter ">
        <div
          className="dashedButton flexCenter"
          onClick={() =>
            setConfigurations({
              ...configurations,
              [uuidv4()]: {
                start: "09:00",
                end: "18:00",
                days: ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"],
              },
            })
          }
        >
          <p className="menu"> {t("Agenda.NewTeleconsultationSlot")} </p>
        </div>
      </div>

      <div className="rdvTimeContainer">
        <div className="flexCenter gap-5  items-center w-full">
          <p> {t("Agenda.DurationOfTeleconsultations")} </p>
          <SelectComp
            options={duree}
            defaultValue="30"
            setText={false}
            className="ml-4"
            value={duration.toString(10)}
            onChange={(e) => setDuration(parseInt(e.target.value))}
          />
        </div>
      </div>
      <div className="flexCenter my-5">
        <Button
          status="primary"
          width="170px"
          height="50px"
          onClick={submitTimeSlots}
          disabled={loadingBtn}
        >
          <div className="relative">
            {loadingBtn && (
              <div className="absolute h-full w-full flexCenter">
                <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-purple-500"></div>
              </div>
            )}
            {t("Agenda.Save")}
          </div>
        </Button>
      </div>
    </div>
  );
};

export default TeleconsultationsForm;
