/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef } from "react";
import { useParams } from "react-router";

import "./3DTake.scss";
import * as THREE from "three";
import SceneInit from "./classes/SceneInit";
import Graph from "./classes/Graph";
import Shape from "./classes/Shape";

import { PLYLoader } from "three/examples/jsm/loaders/PLYLoader";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";
import { Vector3 } from "three";

import { useLocation } from "react-router-dom";
import Button from "../../ButtonUI";
import { FieldSlider } from "../../SurveyGenerator/Elements/FieldSlider";
import { GET_PLY } from "../../../config/api";

function Scene({ selectedModel }) {
  const canvasWrapperRef: any = useRef();

  //const location : any = useLocation();
  const framesNbr = selectedModel?.framesProcessed;

  //const params : any = useParams();

  const api = GET_PLY + "/";

  let scene: any = undefined;
  let camera: any = undefined;
  let controls: any = undefined;
  let renderer: any = undefined;
  let labelRenderer: any = undefined;
  let boundingbox: any = undefined;

  let modelFileName = selectedModel?.name;

  let intersects = [] as any;
  let pointer = new THREE.Vector2() as any;
  let points = [] as any; //va contenir 2 points que l'utilisateur a choisi
  let spheres = [] as any; //va contenir les spheres de selection
  let meshPoints = [] as any; //va contenir les points en vector3 du mesh
  //let pathPoints = [] as any;                //points sur lesquels on va effectuer la recherche (obsolete)
  let shapes = [] as any;
  let graph = new Graph() as any;
  let originalColors = new Map() as any;
  let selectedShape = undefined as any; //forme sélecitonnée par l'utilisateur

  const selectedColor = new THREE.Color(1, 0.9, 0);
  let opacity = 0.5;
  let mesh: any = undefined;

  /// réinitialise les variables au chargement d'un nouvel objet
  function initVariables() {
    intersects = [];
    pointer = new THREE.Vector2() as any;
    points = [] as any;
    spheres = [] as any;
    meshPoints = [] as any;
    //pathPoints = [] as any;
    shapes = [] as any;
    graph = new Graph() as any;
    originalColors = new Map() as any;
    selectedShape = undefined as any;
  }

  /// Calcule le chemin ou la surface selon le nombre de points sélectionnés
  function compute(points) {
    if (points.length === 2) {
      computePath(points);
      createLabel(shapes[shapes.length - 1]);
    } else if (points.length > 2) {
      computeSurface(points);
      createLabel(shapes[shapes.length - 1]);
    }
  }

  function computePath(points) {
    const positions = mesh.geometry.getAttribute("position");

    console.log(
      "distance straight line ",
      points[0].distanceTo(points[1]),
      " cm"
    );

    //conversion de l'ensemble des points du mesh en graphe non orienté, avec aretes, poids
    if (!graph.initialized) {
      graph.getGraphFromMesh(mesh, meshPoints);
    }

    //calcul du chemin le plus court avec dijkstra, on obtient tous les points du chemin
    const path = graph.findPathDijkstra(points[0], points[1]);
    let idPoints: any = [];
    for (let i = 0; i < positions.count; i++) {
      //pour tous les points du mesh, on regarde lesquels font partie de la forme
      if (path.includes(meshPoints[i])) {
        idPoints.push(i);
      }
    }
    const shapeColor = new THREE.Color(Math.random() * 0xffffff);
    const shape: any = new Shape(
      shapeColor,
      idPoints,
      graph.getDistancePath(),
      "line"
    );
    shapes.push(shape);
    drawShape(shape);

    //trace une ligne invisible selectionable
    const lineGeometry = new THREE.BufferGeometry().setFromPoints(path);
    const lineMaterial = new THREE.LineBasicMaterial();
    let line = new THREE.Line(lineGeometry, lineMaterial);
    line.visible = false;
    line.name = "Ligne";
    line.userData.parentShape = shape;
    mesh.add(line);
    shape.line = line;

    spheres.forEach((sphere) => {
      mesh.remove(sphere);
    });
    spheres.splice(0);

    points.splice(0);
  }

  // fait la somme des aires de tous les triangles à l'intérieur du polygone
  function computeSurface(polygon) {
    let v: any = [];
    let area = 0;

    // tableau des indices de points formant 3 à 3 les triangles du mesh
    let indices: any = mesh.geometry.index.array;
    let len = Math.floor(indices.length / 3.0);
    let idPoints: any = [];
    for (let i = 0; i < len; i++) {
      v[0] = meshPoints[indices[3 * i]];
      v[1] = meshPoints[indices[3 * i + 1]];
      v[2] = meshPoints[indices[3 * i + 2]];

      // si les 3 points sont dans le polygone, le triangle est dans le polygone
      if (
        point_in_polygon(v[0], points) &&
        point_in_polygon(v[1], points) &&
        point_in_polygon(v[2], points)
      ) {
        let sp =
          (v[0].distanceTo(v[1]) +
            v[0].distanceTo(v[2]) +
            v[1].distanceTo(v[2])) /
          2; //semi-perimeter

        //formule de héron pour avoir l'aire
        area += Math.sqrt(
          sp *
            (sp - v[0].distanceTo(v[1])) *
            (sp - v[0].distanceTo(v[2])) *
            (sp - v[1].distanceTo(v[2]))
        );
        idPoints.push(indices[3 * i]);
        idPoints.push(indices[3 * i + 1]);
        idPoints.push(indices[3 * i + 2]);
      }
    }

    const shapeColor = new THREE.Color(Math.random() * 0xffffff);
    const shape: any = new Shape(shapeColor, idPoints, area, "polygon");
    shapes.push(shape);
    drawShape(shape);

    spheres.forEach((sphere) => {
      mesh.remove(sphere);
    });
    spheres.splice(0);

    polygon.splice(0);
  }

  function point_in_polygon(point, polygon) {
    let inside = false;
    let n = polygon.length;
    for (let i = 0, j = n - 1; i < n; j = i, i++) {
      const u0 = polygon[i];
      const u1 = polygon[j]; //current Edge

      if (
        (u0.y <= point.y && point.y < u1.y) || // U1 is above the ray, U0 is on or below the ray
        (u1.y <= point.y && point.y < u0.y)
      ) {
        // U0 is above the ray, U1 is on or below the ray
        // find x-intersection of current edge with the ray.
        // Only consider edge crossings on the ray to the right of P.
        let x = u0.x + ((point.y - u0.y) * (u1.x - u0.x)) / (u1.y - u0.y);
        if (x > point.x) inside = !inside;
      }
    }
    return inside;
  }

  /// Pour une shape créée, on lui donne un label contenant la distance/la surface de celle-ci
  function createLabel(shape) {
    const shapeDiv = document.createElement("div");
    shapeDiv.className = "label";
    if (shape.shape === "line") shapeDiv.textContent = shape.getSize() + " cm";
    else shapeDiv.textContent = shape.getSize() + " cm²";

    shapeDiv.style.fontSize = "18px";
    shapeDiv.style.color = "white";
    shapeDiv.style.backgroundColor = "black";
    shapeDiv.style.opacity = "0.5";

    const shapeLabel = new CSS2DObject(shapeDiv);
    const points = shape.getPoints();

    // on va positionner le label au milieu de la shape
    let pos = new Vector3();
    pos.add(meshPoints[points[0]]);
    for (let i = 1; i < points.length; i++) {
      pos.add(meshPoints[points[i]]);
    }
    pos.multiplyScalar(1 / points.length);

    shapeLabel.position.copy(pos);
    shapeLabel.position.y += 0.1;

    shape.addLabel(shapeLabel);
    mesh.add(shapeLabel);
    shapeLabel.layers.set(0);
  }

  ///mets les points du mesh dans l'ordre en coordonnées 3D dans meshPoints
  function convertPointsToWorld(localPoints) {
    let worldPoints: any = [];
    for (let i = 0; i < localPoints.count; i++) {
      let p = new THREE.Vector3().fromBufferAttribute(localPoints, i);
      mesh.localToWorld(p);
      worldPoints.push(p);
    }
    return worldPoints;
  }

  function reloadObject() {
    // si un mesh était déjà chargé on le retire
    if (mesh) {
      mesh.clear();
      scene.remove(mesh);
    }
    mesh = undefined;

    const loader = new PLYLoader();
    loader
      .setWithCredentials(true)
      .setRequestHeader({ "x-no-compression": true, Accept: "text/plain" })
      .load(
        api + modelFileName,
        (geometry) => {
          const material = new THREE.MeshBasicMaterial({
            vertexColors: true,
            side: THREE.DoubleSide,
          });
          mesh = new THREE.Mesh(geometry, material);
          mesh.castShadow = true;
          mesh.receiveShadow = true;

          mesh.position.set(0, 0, 0);
          mesh.name = "Mesh";

          scene.add(mesh);
          mesh.layers.enableAll();
          boundingbox.setFromObject(mesh);

          camera.position.copy(mesh.position);
          controls.target = boundingbox.getCenter(new THREE.Vector3());

          meshPoints = convertPointsToWorld(geometry.getAttribute("position"));
        },
        (xhr) => {},
        (err) => {}
      );
  }

  //-------------------- FONCTIONS DESSINS

  //dessine une forme et rempli la map originalColors si besoin
  function drawShape(shape) {
    const colors = mesh.geometry.getAttribute("color");
    let ogColor: any = undefined;
    let newColor: any = undefined;

    shape.points.forEach((id) => {
      if (originalColors.has(id) === false) {
        originalColors.set(id, [
          colors.getX(id),
          colors.getY(id),
          colors.getZ(id),
        ]);
      }
      ogColor = originalColors.get(id);
      newColor = shape.color;

      mesh.geometry.attributes.color.setXYZ(
        id,
        (newColor.r - ogColor[0]) * opacity + ogColor[0],
        (newColor.g - ogColor[1]) * opacity + ogColor[1],
        (newColor.b - ogColor[2]) * opacity + ogColor[2]
      );
    });
    mesh.geometry.attributes.color.needsUpdate = true;
  }

  function isInAShape(index) {
    return shapes.some((shape: any) => {
      if (shape.points.includes(index)) {
        selectedShape = shape;
        return true;
      }
    });
  }

  //redessine toutes les formes sur le modèle
  function redrawAll() {
    shapes.forEach((shape) => {
      drawShape(shape);
    });

    if (selectedShape !== undefined) {
      selectedShape.points.forEach((idPoint) => {
        mesh.geometry.attributes.color.setXYZ(
          idPoint,
          selectedColor.r,
          selectedColor.g,
          selectedColor.b
        );
      });
    }
    mesh.geometry.attributes.color.needsUpdate = true;
  }

  function eraseShape(shape) {
    let originalColor: any = undefined;

    shape.points.forEach((idPoint) => {
      originalColor = originalColors.get(idPoint);
      mesh.geometry.attributes.color.setXYZ(
        idPoint,
        originalColor[0],
        originalColor[1],
        originalColor[2]
      );
    });
    mesh.remove(shape.label);

    if (shape.line !== undefined) mesh.remove(shape.line);

    shapes.splice(shapes.indexOf(shape), 1);

    if (selectedShape === shape) selectedShape = undefined;

    redrawAll();
  }

  //efface toutes les formes tracées sur le modèle
  function eraseAll() {
    originalColors.forEach((color, id) => {
      mesh.geometry.attributes.color.setXYZ(id, color[0], color[1], color[2]);
    });
    mesh.geometry.attributes.color.needsUpdate = true;

    shapes.splice(0);
    originalColors.clear();

    while (mesh.children.length) {
      mesh.remove(mesh.children[0]);
    }
  }

  useEffect(() => {
    const threeScene = new SceneInit(canvasWrapperRef);
    threeScene.initialize();

    scene = threeScene.scene;
    camera = threeScene.camera;
    controls = threeScene.controls;
    renderer = threeScene.renderer;
    labelRenderer = threeScene.labelRenderer;
    boundingbox = new THREE.Box3();

    reloadObject();

    scene.userData.opacity = 50;
    const opacityElement: any = document.getElementById("opacity");
    opacityElement.addEventListener("input", (event: any) => {
      scene.userData.opacity = event.target.value;
      opacity = event.target.value / 100;
      redrawAll();
    });

    const inputModelNumber: any = document.getElementById("rangenumber");
    const modelElement: any = document.getElementById("model");
    modelElement.value = 0;
    inputModelNumber.value = 0;
    modelElement.addEventListener("change", (event) => {
      modelFileName = selectedModel?.name + "/" + event.target.value;
      reloadObject();
      initVariables();
    });
    modelElement.addEventListener("input", (event) => {
      inputModelNumber.value = event.target.value;
    });

    inputModelNumber.addEventListener("change", (event) => {
      modelFileName = selectedModel?.name + "/" + event.target.value;
      reloadObject();
      initVariables();
    });

    let raycaster = new THREE.Raycaster();
    raycaster.params.Line.threshold = 0.25;

    canvasWrapperRef.current.addEventListener("click", pickPoint);
    function pickPoint(event) {
      // quand on maintient CTRL et qu'on clique, on pose un point
      console.log(
        `event.altKey = ${event.altKey} | typeof mesh = ${typeof mesh}`
      );
      if (event.altKey) {
        if (typeof mesh !== "undefined") {
          const rect = renderer.domElement.getBoundingClientRect();

          const halfCanvasW = canvasWrapperRef.current.clientWidth / 2;
          const halfCanvasH = canvasWrapperRef.current.clientHeight / 2;

          const x = event.offsetX - halfCanvasW;
          const y = event.offsetY - halfCanvasH;

          pointer.x = x / halfCanvasW;
          pointer.y = -y / halfCanvasH;

          console.log("[debug] okure - pickPoint : ", {
            clientWidth: canvasWrapperRef.current.clientWidth,
            clientHeight: canvasWrapperRef.current.clientHeight,
            halfCanvasW,
            halfCanvasH,
            x,
            y,
            rect,
            pointer,
            click: {
              x: event.offsetX,
              y: event.offsetY,
            },
          });

          raycaster.setFromCamera(pointer, camera);
          intersects = raycaster.intersectObjects([mesh], true);

          if (intersects.length > 0) {
            // Clique sur une ligne
            if (intersects[0].object.name === "Ligne") {
              selectedShape = intersects[0].object.userData.parentShape;
              redrawAll();
            }
            // Clique sur une sphere
            else if (intersects[0].object.name === "Selection") {
              const selectedSphere = intersects[0].object;

              const id = points.indexOf(selectedSphere.userData.point);

              spheres.splice(spheres.indexOf(selectedSphere), 1);
              mesh.remove(selectedSphere);
            }
            //clique sur le mesh
            else {
              const selectedPointId = intersects[0].face.a;

              if (isInAShape(selectedPointId)) {
                redrawAll();
              } else {
                const sphereGeometry = new THREE.SphereGeometry(0.1);
                const sphereMaterial = new THREE.MeshBasicMaterial({
                  color: 0xff0000,
                });
                const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);

                sphere.position.copy(intersects[0].point);
                sphere.userData.point = meshPoints[selectedPointId];
                sphere.name = "Selection";
                sphere.layers.enableAll();

                mesh.add(sphere);
                spheres.push(sphere);

                points.push(meshPoints[selectedPointId]);
                selectedShape = undefined;
                redrawAll();
              }
            }
          }
        }
      }
      controls.enabled = true;
      event.stopPropagation();
    }

    //reset de la scene, calcul du chemin/de la surface avec entrée
    canvasWrapperRef.current.addEventListener(
      "keydown",
      onDocumentKeyDown,
      false
    );
    function onDocumentKeyDown(event) {
      let keyCode = event.which;

      if (keyCode === 82 || keyCode === 114) {
        //si on appuie sur R, on replace le modèle et la camera
        camera.position.copy(mesh.position);
        controls.target = boundingbox.getCenter(new THREE.Vector3());

        eraseAll();
      } else if (event.key === "Enter") {
        compute(points);
      } else if (event.key === "Delete") {
        if (selectedShape !== undefined) {
          eraseShape(selectedShape);
        }
      }
      event.stopPropagation();
      event.preventDefault();
    }

    //Quand on entre en sélection de point (CTRL), pas de mouvement de camera
    canvasWrapperRef.current.addEventListener("mousedown", onMousehold, false);
    function onMousehold(event) {
      if (event.altKey === true) controls.enabled = false;
      event.stopPropagation();
    }

    //Resize de la scene quand on resize la fenêtre
    window.addEventListener("resize", onWindowResize, false);
    function onWindowResize() {
      camera.aspect =
        canvasWrapperRef.current.clientWidth /
        canvasWrapperRef.current.clientHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(
        canvasWrapperRef.current.clientWidth,
        canvasWrapperRef.current.clientHeight
      );
      labelRenderer.setSize(
        canvasWrapperRef.current.clientWidth,
        canvasWrapperRef.current.clientHeight
      );
    }
    onWindowResize();

    const render = () => {
      window.requestAnimationFrame(render);

      if (controls.enabled) controls.update();

      renderer.render(scene, camera);
      labelRenderer.render(scene, camera);
    };

    render();
  });
  return (
    <div className="Prise3D">
      <div id="Buttons">
        <div className="space-x-4 mb-4">
          <Button
            status={"secondary"}
            className="w-48 h-12"
            onClick={() => compute(points)}
          >
            Calculer chemin/surface
          </Button>
          <Button
            status={"secondary"}
            className="w-48 h-12"
            onClick={() => camera.layers.toggle(0)}
          >
            Cacher/afficher valeurs
          </Button>
          <Button
            status={"secondary"}
            className="w-48 h-12"
            onClick={() => eraseShape(selectedShape)}
          >
            Supprimer forme
          </Button>
        </div>
        <div>
          <span>0</span>
          <input
            type="range"
            id="model"
            name="model"
            min="0"
            max={framesNbr - 1}
            step="1"
            style={{ maxHeight: "1px" }}
          />
          <span>{framesNbr - 1}</span>
          <label id="modelLabel" htmlFor="model">
            Modele
          </label>
          <input type="number" id="rangenumber" min="0" max={framesNbr - 1} />
          {/* <FieldSlider
            //required={rules && rules[0] && rules[0].required ? true : false}
            //1element={element}
            //form={form}
            readOnly={false}
            label={"Frame image"}
            labelCol={{ className: "mx-2" }}
            key={"Range"}
            name={"range"}
            //rules={readOnly ? [] : rules}
            // validations={
            //   rules && rules[0].required
            //     ? [
            //         {
            //           rule: isRequired(),
            //           message: rules[0].message,
            //         },
            //       ]
            //     : []
            // }
            //{...data}
          /> */}
        </div>
        <div>
          <input
            type="range"
            id="opacity"
            name="opacity"
            min="0"
            max="100"
            step="1"
          />
          <label htmlFor="opacity">Opacité des formes</label>
        </div>
      </div>
      <div id="instructions">
        <p>
          Cliquez en maintenant la touche ALT pour placer des points sur le
          modèle.
        </p>
        <p></p>
      </div>
      <div style={{ height: "70vh" }} ref={canvasWrapperRef} />
    </div>
  );
}

export default Scene;
