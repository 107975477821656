/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import classNames from "classnames";
import _ from "lodash";

import moment from "../../../../helper/moment";
import { loadImage } from "../../../../api/documents";

import Button from "../../../../components/ButtonUI";
import Modal from "../../../../components/ModalUI";
import Spinner from "../../../../components/Spinner";
import Select from "../../../../components/Select";
import ConcentementModal from "./Concentement";
import Antecedents from "./Antecedents";
import Traitements from "./Traitements";
import makeToast from "../../../../components/Snackbar";
import { useTranslation } from "react-i18next";

const ShareFolderModal = ({
  getPatientById,
  user,
  specialistDetails,
  socket,
  generateNewShareFolder,
  setPreviewReportModal,
  setOpenPreviewPrescription,
}) => {
  const { t } = useTranslation() as any;

  const [patients, setPatients] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [checkedAllImages, setCheckedAllImages] = useState<boolean>(false);
  const [typeShareFolder, setTypeShareFolder] = useState<String>("");
  const [patientsNameList, setPatientsNameList] = useState<any>([]);
  const [reports, setReports] = useState<any>([]);
  const [prescriptions, setPrescriptions] = useState<any>([]);
  const [patientsName, setPatientName] = useState<String>("");
  const [patientDetails, setPatientDetails] = useState<any>();
  //SHARED DOC
  const [imageToShare, setImageToShare] = useState<any>([]);
  const [reportToShare, setReportToShare] = useState<any>([]);
  const [prescriptionToShare, setPrescriptionToShare] = useState<any>([]);
  const [sharedTraitement, setSharedTraitement] = useState([]) as any;
  const [sharedAntedent, setSharedAntecedent] = useState([]) as any;
  const [sharedAntedentCh, setSharedAntecedentCh] = useState([]) as any;

  const [concentementModal, setConcentementModal] = useState(false);

  // const parseImage = (filename, userId, type) => ({
  //   original: loadImage(userId, type, "thumbnail", filename),
  // });

  useEffect(() => {
    async function _getPatientById() {
      setLoading(true);
      const response = await getPatientById(user._id);
      setPatients(response?.myPatients?.patients);
      setLoading(false);
    }
    _getPatientById();
  }, []);

  useEffect(() => {
    if (patients && patients.length > 0) {
      setPatientsNameList(
        patients.map((el) => ({
          name: el.firstName + " " + el.lastName,
          id: el._id,
        }))
      );
    }
  }, [patients]);

  useEffect(() => {
    if (patientDetails) {
      setPrescriptions([]);
      setReports([]);
      if (
        patientDetails.consultations &&
        patientDetails.consultations.length > 0
      ) {
        const tempreports = [] as any;
        const tempPrescriptions = [] as any;
        patientDetails.consultations.map((el) => {
          if (el.rapport) {
            tempreports.push({ ...el, id: _.uniqueId("report_") });
          }
          if (el.prescriptions && el.prescriptions.length > 0) {
            el.prescriptions.map((pr) => {
              tempPrescriptions.push({
                ...el,
                ...pr,
                id: _.uniqueId("prescription_"),
              });
            });
          }
        });
        setPrescriptions(tempPrescriptions);
        setReports(tempreports);
      }
    }
  }, [patientDetails]);

  const handleShareFolder = async () => {
    try {
      setConcentementModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const ConfirmedHandleShareFolder = async () => {
    try {
      const sharedFolder = {
        patient: patientDetails?._id,
        sharedBy: user._id,
        SharedTo: specialistDetails._id,
        prescriptions: prescriptionToShare || [],
        reports: reportToShare || [],
        images: imageToShare || [],
        antecedents: sharedAntedent || [],
        antecedentsChirugical: sharedAntedentCh || [],
        Treatement: sharedTraitement || [],
      };
      if (
        process.env.REACT_APP_KATOMI_TENANT === "katomi" &&
        patientDetails?.INS?.INSStaus !== "IdQualified"
      )
        return makeToast("warning", t("Specialist.ErrorINS"));

      generateNewShareFolder(sharedFolder);
    } catch (error) {
      console.error(error);
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="shared-folder  w-full h-full m-2 flex flex-col pr-3 relative">
      <Modal
        className="max-w-lg"
        showModal={concentementModal}
        closeModal={() => setConcentementModal(false)}
      >
        <ConcentementModal
          confirmSHareFolder={ConfirmedHandleShareFolder}
          closeModal={() => setConcentementModal(false)}
        />
      </Modal>

      <div className="flex flex-col justify-end h-full md:m-3 space-y-3">
        <div className="h-full">
          <h2>{t("Specialist.RequestForTeleexpertise")} </h2>
          <div className="flex justify-between gap-y-4 md:gap-y-0 md:items-center pr-2 flex-col md:flex-row">
            <span className="courant-text">{t("Specialist.shareAbout")}</span>
            <div className="select-wrapper w-full">
              <Select
                options={patientsNameList?.map((e) => ({
                  value: e.id,
                  label: e.name,
                }))}
                text="Patient"
                setText={true}
                className="w-full rounded-full px-3 text-gray-400 "
                onChange={(e) => {
                  if (e.target.value !== "Patient") {
                    setPatientName(e.target.value);
                    setPatientDetails(
                      patients?.find((el) => el._id === e.target.value)
                    );
                  }
                }}
              />
            </div>
          </div>
          {patientsName && (
            <>
              <div className="flex items-center  mt-4 md:mt-10 flex-col md:flex-row">
                <span className="courant-text max-w-max mr-2">
                  {t("Specialist.wantToShare")}
                </span>
                <div className="flex flex-1 justify-between">
                  <span
                    className={`box-share-forder cursor-pointer ${
                      typeShareFolder === "Documents" && " box-selected"
                    }`}
                    onClick={() => {
                      setTypeShareFolder("Documents");
                    }}
                  >
                    {t("Specialist.Documents")}
                  </span>
                  <span
                    className={`box-share-forder cursor-pointer ${
                      typeShareFolder === "Antécédents" && " box-selected"
                    }`}
                    onClick={() => {
                      setTypeShareFolder("Antécédents");
                    }}
                  >
                    {t("Specialist.Antecedents")}
                  </span>
                  <span
                    className={`box-share-forder cursor-pointer ${
                      typeShareFolder === "Traitement" && " box-selected"
                    }`}
                    onClick={() => {
                      setTypeShareFolder("Traitement");
                    }}
                  >
                    {t("Specialist.Treatment")}
                  </span>
                </div>
              </div>
              {typeShareFolder === "Documents" && (
                <>
                  {!_.isEmpty(patientDetails?.documents) ? (
                    <div className="mt-10">
                      <div className="flex justify-between items-center">
                        <h3>{t("Specialist.IMAGES")}</h3>
                        <Button
                          status="secondary"
                          height="40px"
                          className="min-w-max px-3"
                          onClick={(e) => {
                            e.preventDefault();
                            if (!checkedAllImages) {
                              const tempImageToShare =
                                patientDetails?.documents?.map((el) => {
                                  if (
                                    el.metadata &&
                                    el.metadata.slice(0, 5) === "image"
                                  ) {
                                    return el._id;
                                  }
                                });
                              setImageToShare(tempImageToShare);
                            } else setImageToShare([]);
                            setCheckedAllImages(!checkedAllImages);
                          }}
                        >
                          <div className="flex items-center space-x-1">
                            <div className="">
                              <label className="container-checkbox">
                                <input
                                  type="checkbox"
                                  checked={
                                    patientDetails?.documents?.map((el) => {
                                      if (
                                        el.metadata &&
                                        el.metadata.slice(0, 5) === "image"
                                      ) {
                                        return el._id;
                                      }
                                    }).length === imageToShare?.length
                                  }
                                  onChange={(e) => {
                                    e.preventDefault();
                                    if (!checkedAllImages) {
                                      const tempImageToShare =
                                        patientDetails?.documents?.map((el) => {
                                          if (
                                            el.metadata &&
                                            el.metadata.slice(0, 5) === "image"
                                          ) {
                                            return el._id;
                                          }
                                        });
                                      setImageToShare(tempImageToShare);
                                    } else setImageToShare([]);

                                    setCheckedAllImages(!checkedAllImages);
                                  }}
                                />
                                <span
                                  className="checkmark"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (!checkedAllImages) {
                                      const tempImageToShare =
                                        patientDetails?.documents?.map((el) => {
                                          if (
                                            el.metadata &&
                                            el.metadata.slice(0, 5) === "image"
                                          ) {
                                            return el._id;
                                          }
                                        });
                                      setImageToShare(tempImageToShare);
                                    } else setImageToShare([]);

                                    setCheckedAllImages(!checkedAllImages);
                                  }}
                                ></span>
                              </label>
                            </div>
                            <span> {t("Specialist.selectAll")}</span>
                          </div>
                        </Button>
                      </div>

                      <div className="flexCenter flex-wrap gap-4 mt-7">
                        {patientDetails?.documents?.map((el) => {
                          if (
                            el.metadata &&
                            el.metadata.slice(0, 5) === "image"
                          ) {
                            return (
                              <div
                                className={classNames(
                                  "relative rounded-xl",
                                  imageToShare.length > 0 &&
                                    imageToShare.includes(el._id) &&
                                    "border-blue"
                                )}
                                key={el._id}
                              >
                                <img
                                  crossOrigin="anonymous"
                                  referrerPolicy="origin"
                                  // src={
                                  //   process.env.REACT_APP_KATOMI_API_URL +
                                  //   el.filePath
                                  // }img.filePath, img.patientId, img.type
                                  src={loadImage(
                                    el.patientId,
                                    el.type,
                                    "thumbnail",
                                    el.filePath
                                  )}
                                  alt="patient-folder"
                                  className="rounded-lg"
                                  style={{
                                    width: "120px",
                                    height: "120px",
                                  }}
                                />
                                <div className="absolute top-2 right-2 ">
                                  <label className="container-checkbox ">
                                    <input
                                      type="checkbox"
                                      checked={imageToShare.includes(el._id)}
                                      onChange={() => {
                                        imageToShare &&
                                        imageToShare.length > 0 &&
                                        imageToShare.includes(el._id)
                                          ? setImageToShare(
                                              imageToShare
                                                .slice()
                                                .filter((elm) => elm !== el._id)
                                            )
                                          : setImageToShare([
                                              ...imageToShare,
                                              el._id,
                                            ]);
                                      }}
                                    />
                                    <span
                                      className="checkmark"
                                      onClick={() => {
                                        imageToShare &&
                                        imageToShare.length > 0 &&
                                        imageToShare.includes(el._id)
                                          ? setImageToShare(
                                              imageToShare
                                                .slice()
                                                .filter((elm) => elm !== el._id)
                                            )
                                          : setImageToShare([
                                              ...imageToShare,
                                              el._id,
                                            ]);
                                      }}
                                    ></span>
                                  </label>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  ) : (
                    <div className="share-folder-document flexCenter w-full h-24 mt-5 rounded-xl">
                      <p className="card-subtitle">
                        {t("Specialist.NoDocFound")}
                      </p>
                    </div>
                  )}
                  {/* COMPTE RENDU */}
                  {reports && reports.length > 0 && (
                    <div className="mt-10 ">
                      <div className="flex justify-between">
                        <h3 className="uppercase"> {t("Specialist.Report")}</h3>
                      </div>
                      <div className="flex flex-wrap justify-between mt-7 gap-2 max-h-96 overflow-auto ">
                        {reports.map((el) => (
                          <div
                            className={classNames(
                              "relative report-card shadow-md",
                              _.isArray(reportToShare) &&
                                reportToShare.some((elm) => elm.id === el.id) &&
                                "border-blue"
                            )}
                            onClick={() => {
                              setPreviewReportModal({ status: true, ...el });
                            }}
                            key={el._id}
                          >
                            <span className="card-title block">
                              {t("Specialist.ConsultationOf")}
                            </span>
                            <span className="block font-normal text-primary-blue">
                              {moment(el.dateConsultation).format(
                                "DD MMMM YYYY"
                              )}
                            </span>
                            <span className="block updated-report">
                              {t("Specialist.UpdateAt")}{" "}
                              {moment(el.updatedAt).format(t("Moment.Z"))}
                            </span>
                            <div className="box max-w-max ">{el.motif}</div>
                            <div className="absolute top-5 right-5">
                              <label className="container-checkbox ">
                                <input
                                  type="checkbox"
                                  checked={reportToShare.some(
                                    (elm) => elm.id === el.id
                                  )}
                                  // onClick={(e) => e.stopPropagation()}
                                  // onChange={(e) => {
                                  //   if (
                                  //     _.isArray(reportToShare) &&
                                  //     reportToShare.some(
                                  //       (elm) => elm.id === el.id
                                  //     )
                                  //   ) {
                                  //     const tempReportToShare =
                                  //       reportToShare.filter(
                                  //         (elm) => elm.id !== el.id
                                  //       );
                                  //     setReportToShare(tempReportToShare);
                                  //   } else
                                  //     setReportToShare([...reportToShare, el]);
                                  // }}
                                />
                                <span
                                  className="checkmark"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    if (
                                      _.isArray(reportToShare) &&
                                      reportToShare.some(
                                        (elm) => elm.id === el.id
                                      )
                                    ) {
                                      const tempReportToShare =
                                        reportToShare.filter(
                                          (elm) => elm.id !== el.id
                                        );
                                      setReportToShare(tempReportToShare);
                                    } else
                                      setReportToShare([...reportToShare, el]);
                                  }}
                                ></span>
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* PRESCRIPTION */}
                  {prescriptions && prescriptions.length > 0 && (
                    <div className="mt-10">
                      <div className="flex justify-between">
                        <h3> {t("Specialist.Prescription")}</h3>
                      </div>
                      <div className="flex flex-wrap justify-between mt-7 gap-2 max-h-96 overflow-auto ">
                        {prescriptions.map((el) => (
                          <div
                            // className="relative report-card shadow-md"
                            className={classNames(
                              "relative report-card shadow-md",
                              _.isArray(prescriptionToShare) &&
                                prescriptionToShare.some(
                                  (elm) => elm.id === el.id
                                ) &&
                                "border-blue"
                            )}
                            // onClick={() => setOpenPreviewPrescription(el)}
                            key={el._id}
                          >
                            <span className="card-title block">
                              {t("Specialist.ConsultationOf")}
                            </span>
                            <span className="block font-normal text-primary-blue">
                              {moment(el.dateConsultation).format(
                                "DD MMMM YYYY"
                              )}
                            </span>
                            <span className="block updated-report">
                              {t("Specialist.UpdateAt")}{" "}
                              {moment(el.updatedAt).format(t("Moment.Z"))}
                            </span>
                            <div className="box max-w-max ">{el.motif}</div>
                            <div className="absolute top-5 right-5">
                              <label className="container-checkbox ">
                                <input
                                  type="checkbox"
                                  checked={prescriptionToShare.some(
                                    (elm) => elm.id === el.id
                                  )}
                                  onChange={(e) => {
                                    // e.preventDefault();
                                    if (
                                      _.isArray(prescriptionToShare) &&
                                      prescriptionToShare.some(
                                        (elm) => elm.id === el.id
                                      )
                                    ) {
                                      const tempPrescriptionToShare =
                                        prescriptionToShare.filter(
                                          (elm) => elm.id !== el.id
                                        );
                                      setPrescriptionToShare(
                                        tempPrescriptionToShare
                                      );
                                    } else {
                                      setPrescriptionToShare([
                                        ...prescriptionToShare,
                                        el,
                                      ]);
                                    }
                                  }}
                                />
                                <span
                                  className="checkmark"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (
                                      _.isArray(prescriptionToShare) &&
                                      prescriptionToShare.some(
                                        (elm) => elm.id === el.id
                                      )
                                    ) {
                                      const tempPrescriptionToShare =
                                        prescriptionToShare.filter(
                                          (elm) => elm.id !== el.id
                                        );
                                      setPrescriptionToShare(
                                        tempPrescriptionToShare
                                      );
                                    } else {
                                      setPrescriptionToShare([
                                        ...prescriptionToShare,
                                        el,
                                      ]);
                                    }
                                  }}
                                ></span>
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {((patientDetails?.documents &&
                    patientDetails?.documents.length > 0) ||
                    (reports && reports.length > 0) ||
                    (prescriptions && prescriptions.length > 0)) && (
                    <Button
                      className="py-3 my-5 w-full"
                      onClick={() => handleShareFolder()}
                      disabled={
                        _.isEmpty(imageToShare) &&
                        _.isEmpty(reportToShare) &&
                        _.isEmpty(prescriptionToShare) &&
                        _.isEmpty(sharedTraitement) &&
                        _.isEmpty(sharedAntedent) &&
                        _.isEmpty(sharedAntedentCh)
                      }
                    >
                      {t("Specialist.Share")}
                    </Button>
                  )}
                </>
              )}
              {typeShareFolder === "Traitement" && (
                <Traitements
                  patientData={patientDetails}
                  ShareTraitements={handleShareFolder}
                  sharedTraitement={sharedTraitement}
                  setSharedTraitement={setSharedTraitement}
                  disabled={
                    _.isEmpty(imageToShare) &&
                    _.isEmpty(reportToShare) &&
                    _.isEmpty(prescriptionToShare) &&
                    _.isEmpty(sharedTraitement) &&
                    _.isEmpty(sharedAntedent) &&
                    _.isEmpty(sharedAntedentCh)
                  }
                />
              )}
              {typeShareFolder === "Antécédents" && (
                <Antecedents
                  patientData={patientDetails}
                  ShareAntecedent={handleShareFolder}
                  sharedAntedent={sharedAntedent}
                  sharedAntedentCh={sharedAntedentCh}
                  setSharedAntecedent={setSharedAntecedent}
                  setSharedAntecedentCh={setSharedAntecedentCh}
                  disabled={
                    _.isEmpty(imageToShare) &&
                    _.isEmpty(reportToShare) &&
                    _.isEmpty(prescriptionToShare) &&
                    _.isEmpty(sharedTraitement) &&
                    _.isEmpty(sharedAntedent) &&
                    _.isEmpty(sharedAntedentCh)
                  }
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShareFolderModal;
