/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import _ from "lodash";
import { getAllMyPatientsByDoctorId } from "../../api/doctor";
import { getWaitingTasksOfPatient } from "../../api/task";
import { userAtom } from "../../state/auth";
import { previousState } from "../../state/previousPageState";
import { IPatient } from "../../types/patient";
import Chat from "../../components/SharedChat";
import AddPatientKoreTN from "./AddPatientKoreTN";
import CardPatient from "./CardPatient";
import ConfirmAddPatient from "./ConfirmModalAddPatient";
import AddExistPatient from "./modal/AddExistPatient";
import Button from "../../components/ButtonUI";
import Modal from "../../components/ModalUI";
import PaginationComponenet from "../../components/Pagination";
import PatientInfo from "../../components/SharedPatientDetails";
import makeToast from "../../components/Snackbar";
import Spinner from "../../components/Spinner";
import AddPatient from "./AddPatient";
import FilterModal from "./modal/filterModal";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/Pagination2";
import "./styles.scss";
import Checkbox from "../../components/Checkbox";
import CardDMPPatient from "./CardDmpPatient";
import { cpsDataAtom } from "../../state/cardCpsData";
import moment from "../../helper/moment";
import { CPS, DMP } from "../../api/devbox";
import Icon from "../../components/Icon";
import SelectComp from "../../components/Select";

// import { lot1, MLot1, MLot2, MLot3 } from "./AddPatient/HELPER_INSI";
// import AddAntecedentChirurgicaux from "../../components/SharedPatientDetails/modal/AddAntecedentsChirurgicaux";
// import AddTraitement from "../../components/SharedPatientDetails/modal/addTraitement";
// import AddAntecedentsMedicaux from "../../components/SharedPatientDetails/modal/AddAntecedentsMedicaux";
// import { antecedentsChirurgicaux } from "../../components/SharedPatientDetails/fakeData";

const MyPatients: React.FC = () => {
  // const addNewPatient = () => {};

  // const PatientOfTheMonth = () => {
  //   const totalPatients = 24;
  //   const NewPatientForthisMonth = 14;
  //   return `${totalPatients} patients dont ${NewPatientForthisMonth} depuis le 1er ${moment().format(
  //     "MMMM"
  //   )}`;
  // };
  const { t, i18n } = useTranslation() as any;

  const [chatWithId, setChatWithId] = useState() as any;

  const [modal, setModal] = useState(false);
  const [refresh, setRefresh] = useState();
  const [addExistPatientModal, setAddExistPatientModal] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [checkDMPPatientList, setCheckDMPPatientList] = useState(false);
  const [DMPPatientList, setDMPPatientList] = useState([]) as any;

  const [modalAddConfirm, setModalAddConfirm] = useState(false);
  const [data, dataSet] = useState<any>(null);
  const [user] = useAtom(userAtom);
  const [previousStates, setPreviousStates] = useAtom(previousState) as any;
  const [selectedPatient, setSelectedPatient] = useState("");
  const [modalFilter, setModalFilter] = useState(false);
  const [search, setSearch] = useState(false);
  const [patientData, setPatientData] = useState<IPatient>();
  const [chatModal, setChatModal] = useState(false);
  const [filterOptionModal, setFilterOptionModal] = useState(false);

  //Pagination Document
  const [pageDocument, setPageDocument] = useState(1);
  const [totalPageDocument] = useState(1);
  // const [, setTotalResultDocument] = useState(0);
  const [limitDocument] = useState(10);

  const [filerObject, setFilterObject] = useState<any>({
    name: "",
    pays: "",
    region: "",
    departement: "",
    commune: "",
    gouvernorat: "",
    delegation: "",
  });
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [totalResult, setTotalResult] = useState(0);
  const [patientAdded, setPatientAdded] = useState(false);
  const [colorIndex, setColorIndex] = useState(1);
  const location = useLocation();
  const history = useHistory();

  const fetchMyAPI = async (resetPage?) => {
    setLoading(true);

    const pageTofetch = filerObject.name || resetPage ? 1 : page;
    const obj = { ...filerObject, name: location.state };

    let response = await getAllMyPatientsByDoctorId(
      user?._id,
      pageTofetch,
      limit,
      obj
    );

    if (response?.status !== "Success") {
      makeToast("error", t("MyPatient.SuccessLoadPatient"));
    }
    dataSet(
      _.isEmpty(response.myPatients.docs) ? [] : response.myPatients.docs
    );
    setTotalResult(response?.myPatients?.totalDocs);
    setTotalPage(response?.myPatients?.totalPages);
    setPage(response?.myPatients?.page);
    setLoading(false);
  };

  useEffect(() => {
    if (search || page || patientAdded || refresh) {
      fetchMyAPI(false);
    }
    if (search) {
      setSearch(false);
    }
    if (patientAdded) {
      setPatientAdded(false);
      makeToast("success", t("MyPatient.SuccessAddingPatient"));
      setFilterObject({
        name: "",
        pays: "",
        region: "",
        departement: "",
        commune: "",
        gouvernorat: "",
        delegation: "",
      });
      setModal(true);
    }
  }, [search, page, patientAdded, refresh]);

  useEffect(() => {
    if (location.state) {
      setFilterObject({ ...filerObject, name: location.state });
      history.replace({});
    }
    if (location.state) {
      fetchMyAPI(true);
    }
  }, [location.state, filerObject]);

  useEffect(() => {
    async function fetchMyAPI() {
      let response: any = await getWaitingTasksOfPatient(
        selectedPatient,
        user?._id
      );
      const { patient, tasks } = response;
      if (response.status !== "Success") {
        makeToast("error", "Chargement des patients a échoué.");
      }
      setPatientData({ ...patient, tasks });
    }
    if (selectedPatient) {
      fetchMyAPI();
      setModal(true);
      setPatientAdded(false);
    }
  }, [selectedPatient]);

  useEffect(() => {
    if (previousStates?.patientFile?.patientId) {
      setSelectedPatient(previousStates?.patientFile?.patientId);
      setPreviousStates({
        patientFile: {
          patientId: null,
          openTab: previousStates?.patientFile?.openTab || null,
        },
      });
    }
    if (previousStates?.filePatientTasks?.patientId) {
      setSelectedPatient(previousStates?.filePatientTasks?.patientId);
      setPreviousStates({
        filePatientTasks: {
          patientId: null,
          openTab: previousStates?.filePatientTasks?.openTab || null,
        },
      });
    }
  }, [previousStates]);

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const [author, setAuthor] = useState() as any;

  const [cpsCardInfo, setCpsCardInfo] = useAtom(cpsDataAtom);
  const [searchType, setSearchType] = useState("LAST_AUTORIZATION");
  const [from, setFrom] = useState("2023-01-01T15:16:10Z");
  const Options = [
    {
      label: "LAST_AUTORIZATION",
      value: "LAST_AUTORIZATION",
    },
    {
      label: "LAST_DOC",
      value: "LAST_DOC",
    },
  ];

  const handleListDmp = async () => {
    try {
      setLoading(true);
      const payload = {
        context: {
          author,
          modeAcces: {
            acces: "NORMAL",
          },
        },
        request: {
          from: moment(from).format(),
          searchType,
        },
      };
      const rsp = await DMP.td04ListDMPActifs(payload);
      setLoading(false);
      setDMPPatientList(rsp?.patients);
    } catch (error) {
      setLoading(false);
    }
  };

  // when click on patient DMP check author if not check card & devebox

  useEffect(() => {
    const myAPI = async () => {
      try {
        if (checkDMPPatientList && !author) {
          setLoading(true);
          const responseReaderInfo = await CPS.readerInfo(
            false,
            i18n.language.includes("en")
          );
          if (!responseReaderInfo) return;
          // exit if devbox is closed
          const doctorData = await CPS.readCard();
          // exit if card dosent exist

          setCpsCardInfo(doctorData);
          setAuthor({
            internalId: doctorData?.infoPs?.idNatPs,
            nom: doctorData?.namePs?.nomPatronymique,
            prenom: doctorData?.namePs?.prenomUsuel,
            secteurActivite:
              doctorData?.activitiesPs[`${doctorData?.selectedActivity}`]
                ?.structSecActRpps,
            structureSante: {
              idNational:
                doctorData?.activitiesPs[`${doctorData?.selectedActivity}`]
                  ?.structIdNat,
              nom: doctorData?.activitiesPs[`${doctorData?.selectedActivity}`]
                ?.structRaisSoc,
            },
            specialite: "G15_10/SM26",
          });
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    };
    myAPI();
  }, [checkDMPPatientList]);

  useEffect(() => {
    if (cpsCardInfo && !author) {
      setAuthor({
        internalId: cpsCardInfo?.infoPs?.idNatPs,
        nom: cpsCardInfo?.namePs?.nomPatronymique,
        prenom: cpsCardInfo?.namePs?.prenomUsuel,
        secteurActivite:
          cpsCardInfo?.activitiesPs[`${cpsCardInfo?.selectedActivity}`]
            ?.structSecActRpps,
        structureSante: {
          idNational:
            cpsCardInfo?.activitiesPs[`${cpsCardInfo?.selectedActivity}`]
              ?.structIdNat,
          nom: cpsCardInfo?.activitiesPs[`${cpsCardInfo?.selectedActivity}`]
            ?.structRaisSoc,
        },
        specialite: "G15_10/SM26",
      });
    }
  }, [cpsCardInfo]);

  useEffect(() => {
    if (author && _.isEmpty(DMPPatientList) && checkDMPPatientList) {
      handleListDmp();
    }
  }, [author, checkDMPPatientList]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <Modal
        showModal={filterOptionModal}
        closeModal={() => setFilterOptionModal(false)}
      >
        <div className="p-10 w-96">
          <h3 className="text-center mb-5">
            Liste des DMP Autorisés et leurs dernières activités{" "}
          </h3>

          <p className="mb-5">Depuis : </p>
          <input
            type="datetime-local"
            name="meeting-time"
            value={from}
            onChange={(e: any) => {
              setFrom(e.target.value);
            }}
          />
          <SelectComp
            labelText="Type de recherche"
            text="Type de recherche"
            className="px-3"
            labelProps={{ className: "w-62" }}
            options={Options}
            onChange={(e) => {
              setSearchType(e.target.value);
            }}
          />
          <Button
            status="primary"
            width="180px"
            height="35px"
            className="mt-5"
            onClick={() => {
              setCheckDMPPatientList(true);
              handleListDmp();
            }}
            disabled={loading}
          >
            {loading && (
              <div className="absolute flexCenter inset-0">
                <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-purple-500" />
              </div>
            )}
            Rechercher
          </Button>
        </div>
      </Modal>
      <div className="my-patients px-2 md:px-0 ">
        <header className="flex justify-between md:items-center w-full flex-col md:flex-row">
          <h1 className="pageTitle"> {t("MyPatient.myPatient")} </h1>
          <Button
            status="secondary"
            onClick={() => setModalFilter(true)}
            className="btnAddFiltre"
            width={"146px"}
            height={"35px"}
          >
            {t("MyPatient.btnFilter")}
          </Button>
        </header>
        <div className="flex justify-between items-center w-full mt-5 ">
          <div className="flex flex-col md:flex-row md:items-center gap-4">
            <div className="flex gap-2 flex-wrap ">
              {filerObject &&
                Object.values(filerObject).length > 0 &&
                Object.values(filerObject)
                  .filter((el) => el !== null && el !== undefined && el !== "")
                  .map((el) => (
                    <span className="box mx-2 flexCenter">
                      {el}
                      <button
                        className="cros"
                        onClick={() => {
                          const key = getKeyByValue(filerObject, el) || "";
                          setFilterObject({
                            ...filerObject,
                            [key]: undefined,
                          });
                          setSearch(true);
                        }}
                      >
                        X
                      </button>
                    </span>
                  ))}
            </div>
            <Button
              status="secondary"
              onClick={() => setModalAdd(true)}
              className="btnAddPatient"
            >
              {t("MyPatient.btnAddpatient")}
            </Button>
            <Button
              status="primary"
              onClick={() => {
                setCheckDMPPatientList((x) => !x);
              }}
              className="btnAddPatient flex items-center"
            >
              <span className="mx-2">Patient DMP</span>
              <Checkbox
                checked={checkDMPPatientList}
                onChange={() => {
                  setCheckDMPPatientList((x) => !x);
                }}
              />

              <Icon
                isIcon
                name="reglage-haut_droite"
                style={{
                  filter:
                    "invert(100%) sepia(4%) saturate(17%) hue-rotate(110deg) brightness(107%) contrast(104%)",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setFilterOptionModal(true);
                }}
              />
            </Button>
          </div>
          {totalResult ? (
            <div>
              {checkDMPPatientList ? DMPPatientList.length : totalResult}{" "}
              {t("MyPatient.ResultsFound")}
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          className={`patient-list flex flex-wrap gap-10  ${
            totalResult === 0 ? "mb-32" : ""
          }`}
        >
          {checkDMPPatientList ? (
            DMPPatientList.length > 0 ? (
              DMPPatientList.slice(
                (pageDocument - 1) * limitDocument,
                (pageDocument - 1) * limitDocument + limitDocument
              ).map((el, i) => {
                // test if INS exist so show katomi card patient // or (open modal add this patient to kore or just show dmp)
                return (
                  <CardDMPPatient
                    openModal={modal}
                    setOpenModal={setModal}
                    setColorIndex={setColorIndex}
                    setSelectedPatient={setSelectedPatient}
                    selectedPatient={selectedPatient}
                    patientDetails={el}
                    user={user}
                    i={i}
                  />
                );
              })
            ) : (
              <div className="w-full flexCenter mt-24">
                <h3>{t("MyPatient.NoPatientFound")}</h3>
              </div>
            )
          ) : data && data.length !== 0 ? (
            data.map((el, i) => (
              <CardPatient
                patientDetails={el}
                openModal={modal}
                setOpenModal={setModal}
                setChatModal={setChatModal}
                setSelectedPatient={setSelectedPatient}
                selectedPatient={selectedPatient}
                setColorIndex={setColorIndex}
                setChatWithId={setChatWithId}
                user={user}
                i={i}
              />
            ))
          ) : (
            <div className="w-full flexCenter mt-24">
              <h3>{t("MyPatient.NoPatientFound")}</h3>
            </div>
          )}
        </div>
        {checkDMPPatientList
          ? DMPPatientList?.length > 10 && (
              <Pagination
                page={pageDocument}
                totalPage={totalPageDocument}
                setPage={setPageDocument}
                totalResult={DMPPatientList?.length}
              />
            )
          : totalResult > 10 && (
              <PaginationComponenet
                page={page}
                setPage={(i) => setPage(i)}
                totalPage={totalPage}
                totalDocs={totalResult}
              />
            )}
        {modal && patientData && (
          <PatientInfo
            setOpenModal={() => {
              setModal(false);
              setSelectedPatient("");
              setPatientData({} as any);
            }}
            patientData={patientData}
            colorIndex={colorIndex}
            selectedPatient={selectedPatient}
            setModal={setModal}
            setPatientData={setPatientData}
            setPatientAdded={setPatientAdded}
          />
        )}
        {modalAdd &&
          (process.env.REACT_APP_KATOMI_TENANT === "katomi" ? (
            <AddPatient
              openModal={modalAdd}
              setOpenModal={setModalAdd}
              setPatientAdded={setPatientAdded}
              setSelectedPatient={setSelectedPatient}
              setPatientData={setPatientData}
              setOpenModalInfoPatient={setModal}
              addExistUser={(e) => {
                setAddExistPatientModal(true);
              }}
              setRefresh={setRefresh}
            />
          ) : (
            <AddPatientKoreTN
              openModal={modalAdd}
              setOpenModal={setModalAdd}
              setPatientAdded={setPatientAdded}
              setSelectedPatient={setSelectedPatient}
              setPatientData={setPatientData}
              setOpenModalInfoPatient={setModal}
              addExistUser={(e) => {
                setAddExistPatientModal(true);
              }}
              setRefresh={setRefresh}
            />
          ))}
        {modalAddConfirm && (
          <ConfirmAddPatient
            openModal={modalAddConfirm}
            setOpenModal={setModalAddConfirm}
            setOpenModalAddPatient={setModalAdd}
          />
        )}
        {modalFilter && (
          <FilterModal
            openModal={modalFilter}
            setOpenModal={setModalFilter}
            filerObject={filerObject}
            setFilterObject={setFilterObject}
            search={search}
            setSearch={setSearch}
          />
        )}
      </div>
      <Chat
        chatWithId={chatWithId}
        closeChatModal={() => setChatModal(false)}
        showModal={chatModal}
      />
      <Modal
        showModal={addExistPatientModal}
        closeModal={() => setAddExistPatientModal(false)}
      >
        <AddExistPatient
          closeModal={() => setAddExistPatientModal(false)}
          idPatient={selectedPatient}
          setOpenModalInfoPatient={setModal}
          setOpenModal={setModalAdd}
          setPatientAdded={setPatientAdded}
        />
      </Modal>
    </>
  );
};

export default MyPatients;
