export const KATOMI_API_ENDPOINT = `${process.env.REACT_APP_KATOMI_API_URL}v${process.env.REACT_APP_KATOMI_API_VERSION}`;

//AUTH
export const USER_VERIFY_URL = `${KATOMI_API_ENDPOINT}/auth`;
export const CONFIRM_EMAIL = `${USER_VERIFY_URL}/confirm-email`;
export const USER_UPDATE_PASSWORD = `${USER_VERIFY_URL}/update-password`;
export const REFRESH_TOKEN = `${USER_VERIFY_URL}/refresh-token`;
export const GET_USER_BY_ID = `${USER_VERIFY_URL}/get-user-byId`;
export const FORGET_PASSWORD = `${USER_VERIFY_URL}/forget-password`;
export const RESET_PASSWORD = `${USER_VERIFY_URL}/reset-password`;
export const RESEND_CONFIRMATION_CODE = `${USER_VERIFY_URL}/resendConfirmationCode`;
export const DOCTOR_SAVE_PSC_ID = `${USER_VERIFY_URL}/savePscId`;
export const DOCTOR_REGISTER_WITH_PSC = `${USER_VERIFY_URL}/Register-with-psc`;

//AGENDA ENDPOINTS
const AGENDA_ENDPOINT = `${KATOMI_API_ENDPOINT}/agenda`;
export const DOCTOR_ADD_INDISPONIBILITE = `${AGENDA_ENDPOINT}/indisponibilite`;
export const DOCTOR_GET_ALL_BOOKING = `${AGENDA_ENDPOINT}/all-booking`;
export const DOCTOR_RESET_AGENDA = `${AGENDA_ENDPOINT}/reset-agenda`;
export const DELETE_TIME_SLOT_WITH_ID = `${AGENDA_ENDPOINT}/deleteTimeSlot`;

//PATIENT ENDPOINTS
const PATIENT_ENDPOINT = `${KATOMI_API_ENDPOINT}/patients`;
export const PATIENT_LOGOUT_URL = `${PATIENT_ENDPOINT}/auth/logout`;
export const PATIENT_GET_MY_DOCTORS = `${PATIENT_ENDPOINT}/myDoctors`;
export const PATIENT_GET_FILTRED_DOCTORS = `${PATIENT_ENDPOINT}/getSpecialists`;
export const PATIENT_GET_UPCOMING_CONSULTATION = `${PATIENT_ENDPOINT}/getUpcomingConsultation`;
export const PATIENT_OLD_DONE_CONSULTATION = `${PATIENT_ENDPOINT}/getOlDDoneConsultation`;
export const PATIENT_GET_DOCTOR_SPECIALITY_LIST = `${PATIENT_ENDPOINT}/allSpeciality`;
export const DOCTOR_ADD_PATIENT_URL = `${PATIENT_ENDPOINT}/add`;
export const DOCTOR_GET_ALL_PATIENTS_URL = `${PATIENT_ENDPOINT}/patients`;
export const DOCTOR_GET_PATIENT_BY_ID_URL = `${PATIENT_ENDPOINT}/`;
export const DOCTOR_EDIT_PATIENT_BY_ID_URL = `${PATIENT_ENDPOINT}/edit`;
export const DOCTOR_GET_PATIENT_BY_ID_OBSERVATIONS_URL = `${PATIENT_ENDPOINT}/observations`;
export const PATIENT_SIGNUP = `${PATIENT_ENDPOINT}/signup`;
export const PATIENT_LOGIN = `${PATIENT_ENDPOINT}/login`;
export const PATIENT_VERIFY_SMS = `${PATIENT_ENDPOINT}/verify`;
export const PATIENT_SIGNUP_PATIENTELO = `${PATIENT_ENDPOINT}/addPatientPatientelo`;
export const PATIENT_GET_TREATING_DOCTOR = `${PATIENT_ENDPOINT}/my-treating-doctor`;
export const PATIENT_GET_ALL_DOCUMENT = `${PATIENT_ENDPOINT}/get-all-documents`;
export const PATIENT_BOOK_MEETING = `${PATIENT_ENDPOINT}/book-meeting`;
export const PATIENT_ME = `${PATIENT_ENDPOINT}/me`;
export const PATIENT_UPDATE_PROFILE = `${PATIENT_ENDPOINT}/update-patient`;
export const PATIENT_SEARCH = `${PATIENT_ENDPOINT}/search`;
export const PATIENT_SEARCH_OTHER_DOCTORS = `${PATIENT_ENDPOINT}/getOtherDoctors`;
export const PATIENT_SET_STRIPE_CUSTOMER = `${PATIENT_ENDPOINT}/setStripeCustomer`;
export const PATIENT_SETUP_INTENT = `${PATIENT_ENDPOINT}/setup-intent`;

// DOCTORS ENDPOINTS
const DOCTOR_ENDPOINT = `${KATOMI_API_ENDPOINT}/doctors`;
export const DOCTOR_LOGOUT_URL = `${DOCTOR_ENDPOINT}/auth/logout`;
export const DOCTOR_REGISTER_URL = `${DOCTOR_ENDPOINT}/auth/register`;
export const DOCTOR_LOGIN_URL = `${DOCTOR_ENDPOINT}/auth/login`;
export const DOCTOR_VERIFY_URL = `${DOCTOR_ENDPOINT}/auth`;
export const DOCTOR_PRE_REGISTER_URL = `${DOCTOR_ENDPOINT}/preRegisterDoctor`;
export const DOCTOR_GET_MY_PATIENTS2 = `${DOCTOR_ENDPOINT}/myPatients2`;
export const DOCTOR_GET_ALL_SPECIALIST = `${DOCTOR_ENDPOINT}/`;
export const DOCTOR_GET_PATIENT_WITH_ID = `${DOCTOR_ENDPOINT}/myPatient`;
export const DOCTOR_ADD_CURSUS = `${DOCTOR_ENDPOINT}/addCursus`;
export const DOCTOR_ADD_MODIFY_TARIFS = `${DOCTOR_ENDPOINT}/addOrModifyTarifs`;
export const DOCTOR_ADD_MODIFY_DESCRIPTION = `${DOCTOR_ENDPOINT}/addOrModifyDescription`;
export const DOCTOR_ADD_MODIFY_SITES = `${DOCTOR_ENDPOINT}/addOrModifySites`;
export const DOCTOR_ADD_EXPERTISE = `${DOCTOR_ENDPOINT}/addExpertise`;
export const DOCTOR_ADD_MODIFY_LANGUE = `${DOCTOR_ENDPOINT}/addOrModifyLangues`;
export const DOCTOR_GET_MY_PATIENTS = `${DOCTOR_ENDPOINT}/myPatients`;
export const DOCTOR_GET_MY_PATIENTS_FILTRED = `${DOCTOR_ENDPOINT}/myPatientsFilter`;
export const DOCTOR_DELETE_EXPERTISE = `${DOCTOR_ENDPOINT}/deleteExpertise`;
export const DOCTOR_FORGET_PASSWORD = `${DOCTOR_ENDPOINT}/forgetPassword`;
export const DOCTOR_UPDATE_INFO = `${DOCTOR_ENDPOINT}/updatePatientInfo`;
export const DOCTOR_UPDATE_INS_DATA = `${DOCTOR_ENDPOINT}/updateINSDataByDoctor`;
export const DOCTOR_GET_OPEN_TIME_SLOTS = `${DOCTOR_ENDPOINT}/open-time-slots`;
export const DOCTOR_GET_OPEN_TIME_SLOTS_WITH_CONSULTATION = `${DOCTOR_ENDPOINT}/open-time-slots-and-consultaion`;
export const DOCTOR_ADD_MODIFY_LOCATION = `${DOCTOR_ENDPOINT}/addOrModifyLocation`;
export const DOCTOR_SET_AGENDA_CONFIG = `${DOCTOR_ENDPOINT}/agenda-config`;
export const DOCTOR_GET_CONTRIES = `${DOCTOR_ENDPOINT}/getContriesList`;
export const DOCTOR_UPDATE_DATA = `${DOCTOR_ENDPOINT}/updateData`;
export const DOCTOR_Add_SPECIALIST = `${DOCTOR_ENDPOINT}/inviteNewDoctorWithMail`;
export const DOCTOR_CANCEL_CONSULTATION_BETWEEN_TIME_RANGE = `${DOCTOR_ENDPOINT}/doctorCancelConsultationBetweenTimeRange`;
export const DOCTOR_ADD_EXIST_PATIENT = `${DOCTOR_ENDPOINT}/add-exist-patient`;
export const DOCTOR_GET_ALL_PATIENT_NAME = `${DOCTOR_ENDPOINT}/getPatientsName`;
export const CHECK_CONSULTATION_BETWEEN_TIME_RANGE = `${DOCTOR_ENDPOINT}/checkConsultationBetweenTimeRange`;
export const UPDATE_BILL = `${DOCTOR_ENDPOINT}/updateBill`;
export const DOCTOR_SEEN_NOTIFICATION = `${DOCTOR_ENDPOINT}/notification`;
export const GET_ALL_MATRICULE_INS = `${DOCTOR_ENDPOINT}/getPatientsMaticuleINS`;
export const DOCTOR_UPDATE_DATA_WITH_PSC = `${DOCTOR_ENDPOINT}/updateDataWithPSC`;
export const DOCTOR_GET_USER_INFO_PSC = `${DOCTOR_ENDPOINT}/getUserInfoPsc`;

// CONSULTATIONS ENDPOINTS
const CONSULTATION_ENDPOINT = `${KATOMI_API_ENDPOINT}/consultations`;
export const CHECK_ROOM_VALIDITY = `${CONSULTATION_ENDPOINT}/checkRoomValidity`;
export const Delete_CONSULTATION_BETWEEN_TIME_RANGE = `${CONSULTATION_ENDPOINT}/deleteConsultationBeteweenTimeRange`;
export const DOCTOR_ADD_CONSULTATION_URL = `${CONSULTATION_ENDPOINT}/add`;
export const GET_CONSULTATION_BY_ID = (id) =>
  `${CONSULTATION_ENDPOINT}/get-consultation-by-id/${id}`;
export const DOCTOR_GET_CONSULTATIONS_OF_THE_DAY = `${CONSULTATION_ENDPOINT}/doctor-list-consultaion-of-the-day`;
export const DOCTOR_GET_CONSULTATION_OF_PATIENT_BYID_URL = `${CONSULTATION_ENDPOINT}/`;
export const DOCTOR_ADD_CONSULTATION_KATOMI = `${CONSULTATION_ENDPOINT}/doctor-create-consultation`;
export const DOCTOR_UPDATE_CONSULTATION_BY_ID = (id) =>
  `${CONSULTATION_ENDPOINT}/${id}`;
export const UPCOMING_RDV_DOCTOR_PATIENT = `${CONSULTATION_ENDPOINT}/incomingRdv`;
export const ADD_NEW_PRESCRIPTION_OR_REPORT = `${CONSULTATION_ENDPOINT}/addNewPrescriptionOrReport`;
export const UPDATE_PRESCRIPTION = `${CONSULTATION_ENDPOINT}/updatePrescription`;
export const UPDATE_CONSULTATION_DMP = `${CONSULTATION_ENDPOINT}/updateConsultationDMP`;

// MOTIFS ENDPOINTS
const MOTIF_ENDPOINT = `${KATOMI_API_ENDPOINT}/motifs`;
export const DOCTOR_ADD_MOTIF_URL = `${MOTIF_ENDPOINT}/add`;
export const DOCTOR_UPDATE_MOTIF_URL = `${MOTIF_ENDPOINT}`;
export const DOCTOR_DELETE_MOTIF_URL = `${MOTIF_ENDPOINT}/delete`;
export const DOCTOR_GET_ALL_MOTIF_URL = `${MOTIF_ENDPOINT}`;

// TASKSDOCTOR ENDPOINTS
export const TASK_DOCTOR_ENDPOINT = `${KATOMI_API_ENDPOINT}/tasks-doctor`;
export const GENERATE_TASKS_URL = `${TASK_DOCTOR_ENDPOINT}/generate-tasks`;
export const GET_WAITING_TASKS_OF_DAY_URL = `${TASK_DOCTOR_ENDPOINT}/get-waiting-tasks-of-day`;
export const GET_ALL_TASKS_URL = `${TASK_DOCTOR_ENDPOINT}/get-all-tasks`;
export const GET_TASKS_DOCTOR_PATIENT_URL = `${TASK_DOCTOR_ENDPOINT}/`;
export const GET_TASKS_BY_ID_URL = `${TASK_DOCTOR_ENDPOINT}/task`;
export const GET_UNDONE_TASK = (patientId, doctorId) =>
  `${TASK_DOCTOR_ENDPOINT}/undoneTask/${patientId}/${doctorId}`;
export const GET_PUT_DELETE_TASK_URL = (id) => `${TASK_DOCTOR_ENDPOINT}/${id}`;
export const PUT_PRESCRIPTION_TASK_URL = (id) =>
  `${TASK_DOCTOR_ENDPOINT}/prescription/${id}`;
export const GET_STATUS_TASKS = (id) =>
  `${TASK_DOCTOR_ENDPOINT}/task-status/${id}`;

// CHAT ENDPOINTS
export const GET_OLD_MESSAGES = (chatRoomID) =>
  `${KATOMI_API_ENDPOINT}/message/getAll?chatroom=${chatRoomID}`;
export const GET_UNREAD_MSG = `${KATOMI_API_ENDPOINT}/message/unreadMsg`;

// DOCUMENTS ENDPOINTS
export const DOCUMENTS_ENDPOINT = `${KATOMI_API_ENDPOINT}/documents`;
export const DOCUMENTS_LOAD_ENDPOINT = `${KATOMI_API_ENDPOINT}/documents`;
export const REMOVE_PATIENT_PROFILE_PICTURE = `${DOCUMENTS_ENDPOINT}/removePatientProfilePicture`;
export const ADD_DOCUMENTS_BY_PATIENT = `${DOCUMENTS_ENDPOINT}/addDocsPatient`;
export const ADD_SIGNATURE_DOCTOR = `${DOCUMENTS_ENDPOINT}/addSigntureDoctor`;
export const ADD_PHOTO_DOCTOR = `${DOCUMENTS_ENDPOINT}/addPhotoDoctor`;
export const ADD_PHOTO_PATIENT = `${DOCUMENTS_ENDPOINT}/addPhotoPatient`;
export const FETCH_DOCUMENTS_BY_PATIENT_AND_DOCTOR = `${DOCUMENTS_ENDPOINT}/fetchDocumentWithDoctorAndPatient`;
export const REMOVE_DOC = `${DOCUMENTS_ENDPOINT}/remove`;
export const REMOVE_DOCTURE_SIGNATURE = `${DOCUMENTS_ENDPOINT}/remove-signature`;
export const REMOVE_DOCTURE_PROFILE_PICTURE = `${DOCUMENTS_ENDPOINT}/removeDoctorProfilePicture`;
export const ADD_CARDPRO_DOCTOR = `${DOCUMENTS_ENDPOINT}/add-cardProd`;
export const REMOVE_DOCTURE_CARDPRO = `${DOCUMENTS_ENDPOINT}/removeCardPro`;
export const UPDATE_DOC = `${DOCUMENTS_ENDPOINT}/updateDoc`;

// TREATEMENTS ENDPOINTS
export const TREATEMENT_ENDPOINT = `${KATOMI_API_ENDPOINT}/treatements`;
export const ADD_TREATEMENS_TO_PATIENT_BY_DOCTOR = `${TREATEMENT_ENDPOINT}/addTreatementToPatientByDoctor`;
export const ADD_OBSERVATION_TO_PATIENT_BY_DOCTOR = `${TREATEMENT_ENDPOINT}/addObservation`;
export const END_TREATMENT = `${TREATEMENT_ENDPOINT}/end-treatement`;

// MEDICAL ANTECEDENT ENDPOINTS
export const ANTECEDENT_ENDPOINT = `${KATOMI_API_ENDPOINT}/antecedents`;
export const ADD_MEDICAL_ANTECDENTS_TO_PATIENT_BY_DOCTOR = `${ANTECEDENT_ENDPOINT}/addAntecedentMedicalToPatientByDoctor`;
export const ADD_CHIRURGICAUX_ANTECDENTS_TO_PATIENT_BY_DOCTOR = `${ANTECEDENT_ENDPOINT}/addAntecedentChirurgicauxToPatientByDoctor`;
export const ADD_OBSERVATION_ANTECDENTS_TO_PATIENT_BY_DOCTOR = `${ANTECEDENT_ENDPOINT}/addObservation`;
export const GET_FILTRED_ANTECEDENT_BY_PATIENT = `${ANTECEDENT_ENDPOINT}/filter`;

// TELECONSULTATION ENDPOINTS
export const GET_CONFIG_TELECONSULTATION = `${KATOMI_API_ENDPOINT}/teleconsultation/config`;
export const GET_CONSULTATION_BY_DOCTOR_AND_PATIENT_ID = `${KATOMI_API_ENDPOINT}/consultations/get-by-doctorandpatientid`;
export const PATIENT_UPDATE_CONSULTATION = `${KATOMI_API_ENDPOINT}/consultations/update-consultation-date`;
export const DOCTOR_UPDATE_CONSULTATION = `${KATOMI_API_ENDPOINT}/consultations/doctor-update-date-consultation`;
export const ADD_ACTE = `${KATOMI_API_ENDPOINT}/consultations/add-acte`;
export const DOCTOR_ADD_INDISPONIBILITY_OR_HOLIDAY = `${KATOMI_API_ENDPOINT}/agenda/indisponibilite-timeslots`;
export const SAVE_CALLER_SOCKET_ID = `${KATOMI_API_ENDPOINT}/teleconsultation/setSocketId`;
export const GET_TELECONSULTATION_HISTORY = `${KATOMI_API_ENDPOINT}/consultations/get-teleconsultations-history`;
export const TELECONSULTATION_CANCEL_BILLING = `${KATOMI_API_ENDPOINT}/teleconsultation/cancel-teleconsultation`;
export const TELECONSULTATION_REFUND_BILLING = `${KATOMI_API_ENDPOINT}/teleconsultation/refund-teleconsultation`;
export const TELECONSULTATION_INIT_BILLING = `${KATOMI_API_ENDPOINT}/teleconsultation/bill-teleconsultation`;
export const END_TELECONSULTATION = `${KATOMI_API_ENDPOINT}/teleconsultation/end-teleconsultaion`;

// MESSAGES ENDPOINTS
export const MESSAGE_ENDPOINT = `${KATOMI_API_ENDPOINT}/message`;
export const SEND_CONSULTATION_BY_PATIENT = `${MESSAGE_ENDPOINT}/sendConsultationToDoctorByPatient`;
export const MESSAGE_PATIENTS_LIST = `${MESSAGE_ENDPOINT}/patientList`;
export const MESSAGE_DOCTORS_LIST = `${MESSAGE_ENDPOINT}/doctorList`;

// SHARE FOLDER ENDPOINTS
export const SHARE_FOLDER = `${KATOMI_API_ENDPOINT}/sharefolder`;
export const GENERATE_NEW_SHARE_FOLDER = `${SHARE_FOLDER}/generate-new-sharefolder`;
export const GET_SHARE_FOLDER_BY_ID = (id) =>
  `${SHARE_FOLDER}/get-sharefolder-byid/${id}`;

// SHARETASK ENDPOINTS
export const GENERATE_NEW_TASK_FOLDER = `${KATOMI_API_ENDPOINT}/shareTask/generate-new-taskfolder`;
export const GET_TASK_FOLDER_BYID = `${KATOMI_API_ENDPOINT}/get-taskfolder-byid`;

// PAYMEE
export const LIVE_URL = "https://app.paymee.tn";
export const SANDBOX_URL = "https://sandbox.paymee.tn";

export const INITIATE_PAYMENT = `${LIVE_URL}/api/v1/payments/create`;
export const INITIATE_PAYMENT_SANDBOX = `${KATOMI_API_ENDPOINT}/paymee`;

export const LOAD_GATEWAY = (token) => `${LIVE_URL}/gateway/${token}`;
export const LOAD_GATEWAY_SANDBOX = (token) =>
  `${KATOMI_API_ENDPOINT}/paymee/gateway/${token}`;

export const CHECK_PAYMENT = (token) =>
  `${LIVE_URL}/api/v1/payments/${token}/check`;
export const CHECK_PAYMENT_SANDBOX = (token) =>
  `${KATOMI_API_ENDPOINT}/paymee/${token}`;

// PALIER ENDPOINTS
export const GET_ALL_PALIER = `${KATOMI_API_ENDPOINT}/palier`;

// SURVEY ENDPOINT
export const GET_SURVEY_BY_PATIENTID = `${PATIENT_ENDPOINT}/getAllSurveyByPatient`;
export const GET_ALL_SURVEY_DOCTOR = `${DOCTOR_ENDPOINT}/getAllSurvey`;
export const DOCTOR_ASSIGN_SURVEY = `${DOCTOR_ENDPOINT}/doctorAssignSurvey`;
export const PATIENT_RESPONSE_SURVEY = `${PATIENT_ENDPOINT}/updateSurvey`;
export const DOWNLOAD_SURVEY_RESULT = `${PATIENT_ENDPOINT}/downloadSurveyResult`;
export const DOCTOR_GET_ALL_SURVEYID = `${DOCTOR_ENDPOINT}/getAllPatientBySurveyId`;
export const DOCTOR_CREATE_SURVEY = `${DOCTOR_ENDPOINT}/createSurvey`;
export const DOCTOR_UPDATE_SURVEY = `${DOCTOR_ENDPOINT}/updateSurvey`;
export const DOCTOR_GET_SURVEY = `${DOCTOR_ENDPOINT}/survery`;
export const SURVEY_GET_STATS = `${DOCTOR_ENDPOINT}/stats`;
export const GET_ALL_DOCTOR_SURVEY = `${DOCTOR_ENDPOINT}/gatAllSurverysDoctors`;

// EXTENSION
export const UPDATE_EXTENSIONS = `${DOCTOR_ENDPOINT}/updateExtension`;

//PDF TEMPLATE ENDPOINT
const PDF_TEMPLATE_ENDPOINT = `${KATOMI_API_ENDPOINT}/pdf`;

export const ADD_PDF_TO_DOCTOR = `${PDF_TEMPLATE_ENDPOINT}/addTemplate`;
export const GET_DOCTORS_TEMPLATE_PDF = `${PDF_TEMPLATE_ENDPOINT}/getAllPdf`;
export const GET_DOCTORS_TEMPLATE_PDF_PAGINAtION = `${PDF_TEMPLATE_ENDPOINT}/getAllPdfPagination`;
export const GET_TEMPLATE_BY_ID = `${PDF_TEMPLATE_ENDPOINT}/getById`;
export const UPDATE_DOCTORS_TEMPLATE_PDF = `${PDF_TEMPLATE_ENDPOINT}/updatePdf`;

// INS LOGS
const LOGS_ENDPOINT = `${KATOMI_API_ENDPOINT}/logsINS`;
export const ADD_NEW_LOGS = `${LOGS_ENDPOINT}/addLogs`;
export const GET_ALL_LOGS_BY_DOCTORID = `${LOGS_ENDPOINT}/getAllLogs`;
export const GET_LOGS_BY_ID = `${LOGS_ENDPOINT}/getById`;

const LOGS_DMP_ENDPOINT = `${KATOMI_API_ENDPOINT}/logsDMP`;
export const ADD_LOGS_DMP = `${LOGS_DMP_ENDPOINT}/addlogsDMP`;

// OKURE
export const GET_DOCTORS_3D_MODELS = `${KATOMI_API_ENDPOINT}/okure`;
export const GET_PLY = `${KATOMI_API_ENDPOINT}/okure/ply`;

//PSC
export const PSC_ENDPOINT = `${KATOMI_API_ENDPOINT}/psc`;
export const PSC_GET_TOKEN = `${PSC_ENDPOINT}/getToken`;
export const PSC_USER_INFO = `${PSC_ENDPOINT}/getUserInfo`;
export const PSC_REFRESH_TOKEN = `${PSC_ENDPOINT}/getRefreshToken`;
