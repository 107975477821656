import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import { useIntl } from "react-intl";
import { resolver } from "../../../helper/FormGeneratorHelper/conditionalLogic";
import { FormElement } from "../Elements";

const FormRenderer = ({ elements, form, ...props }) => {
  const { t } = useTranslation();
  const [formValue, setFormValue] = useState(props.values);
  useEffect(() => {
    setFormValue(props.values);
  }, [props.values]);
  // const intl = useIntl();

  return (
    <div className="bg-white p-4 rounded-lg m-1 ">
      {elements.length > 0 ? (
        elements.map((element) => {
          return element.type !== "LineBreak" ? (
            resolver(element.conditionLogic, formValue) && (
              <FormElement
                form={form}
                key={element.id}
                element={element}
                readOnly={false}
              />
            )
          ) : (
            <FormElement
              form={form}
              key={element.id}
              element={element}
              readOnly={false}
            />
          );
        })
      ) : (
        <span className="text-gray-400 flexCenter ">
          {t("SurveyIntl.noItmesMsg")}{" "}
        </span>
      )}
    </div>
  );
};

export default FormRenderer;
