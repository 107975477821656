import { useState } from "react";
import Button from "../../../components/ButtonUI";
import Select from "../../../components/Select";
// import { userAtom } from "../../../state/auth";
// import countriesTn from "../../../config/constants/countriesTn.json";
import { ReactComponent as Close } from "../../../assets/icons/icon-close.svg";
import { useTranslation } from "react-i18next";

export interface IProps {
  openModal: boolean;
  setOpenModal: Function;
  filerObject: object;
  setFilterObject: Function;
  search: boolean;
  setSearch;
}

const FilterModal: React.FC<IProps> = ({
  openModal,
  setOpenModal,
  filerObject,
  setFilterObject,
  search,
  setSearch,
}) => {
  const { t } = useTranslation() as any;
  const [paysState, setPaysState] = useState("") as any;
  // const [regionState, setRegionState] = useState([]) as any;
  // const [selectedRegionState, setSelectedRegionState] = useState() as any;
  // const [departementState, setDepartementState] = useState([]) as any;
  // const [selectedDepartementState, setSelectedDepartementState] =
  //   useState() as any;
  // const [communesState, setCommunesState] = useState([]) as any;
  // const [selectedCommunesState, setSelectedCommunesState] = useState() as any;

  // const [villeTnState, setVilleTnState] = useState("");
  // const [delegationTn, setDelegationTn] = useState([]) as any;
  // const [delegationTnState, setDelegationTnState] = useState("");

  // const _getRegion = async () => {
  //   try {
  //     fetch("https://geo.api.gouv.fr/regions")
  //       .then((response) => response.json())
  //       .then((data) =>
  //         setRegionState(
  //           data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
  //         )
  //       );
  //   } catch (error) {

  //   }
  // };
  // const _getDepartementState = async () => {
  //   try {
  //     fetch(
  //       `https://geo.api.gouv.fr/regions/${selectedRegionState?.code}/departements`
  //     )
  //       .then((response) => response.json())
  //       .then((data) =>
  //         setDepartementState(
  //           data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
  //         )
  //       );
  //   } catch (error) {

  //   }
  // };

  // const _getCommunesState = async () => {
  //   try {
  //     fetch(
  //       `https://geo.api.gouv.fr/departements/${selectedDepartementState?.code}/communes`
  //     )
  //       .then((response) => response.json())
  //       .then((data) =>
  //         setCommunesState(
  //           data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
  //         )
  //       );
  //   } catch (error) {

  //   }
  // };

  // // Init
  // useEffect(() => {
  //   _getRegion();
  // }, []);

  // // Init
  // useEffect(() => {
  //   if (selectedRegionState?.code) _getDepartementState();
  // }, [selectedRegionState]);

  // // Init
  // useEffect(() => {
  //   if (selectedDepartementState?.code) _getCommunesState();
  // }, [selectedDepartementState]);

  // // Init
  // useEffect(() => {
  //   if (villeTnState) {
  //     setDelegationTn(
  //       countriesTn[villeTnState].map((el) => ({
  //         ...el,
  //         value: el.delegation,
  //         label: el.delegation,
  //       }))
  //     );
  //   }
  // }, [villeTnState]);

  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute bg-white z-40 top-1/3 flexCente cursusAdd">
        <div className="flex justify-end">
          <div className="absolute right-0 mt-2 mr-2">
            <Button status="icon-secondary" onClick={() => setOpenModal(false)}>
              <Close />
            </Button>
          </div>
        </div>
        <div className="flex flex-col item-center space-y-3  px-10 pb-5 mt-12">
          <div className="flexCenter space-x-5"></div>
          <div className="">
            <Select
              labelText="Pays"
              className="w-full px-3"
              labelProps={{ className: "w-40" }}
              value={paysState}
              options={[
                { label: t("ModalFichePatient.Tunisia"), value: "Tunisie" },
                { label: t("ModalFichePatient.France"), value: "France" },
              ]}
              onChange={(e) => {
                setPaysState(e.target.value);
                setFilterObject({
                  ...filerObject,
                  pays: e.target.value,
                });
              }}
            />
          </div>
          {/* <div>
            {paysState === "France" && (
              <>
                <Select
                  labelText="Régions"
                  className="w-full px-3"
                  labelProps={{ className: "w-40" }}
                  value={selectedRegionState ? selectedRegionState.value : ""}
                  options={regionState}
                  onChange={(e) => {
                    setSelectedRegionState(
                      regionState.find((elm) => elm.value == e.target.value)
                    );
                    setFilterObject({
                      ...filerObject,
                      region: e.target.value,
                    });
                  }}
                />
                {selectedRegionState?.value && (
                  <Select
                    labelText="Départements"
                    className="w-full px-3"
                    labelProps={{ className: "w-40" }}
                    value={
                      selectedDepartementState
                        ? selectedDepartementState.value
                        : ""
                    }
                    options={departementState}
                    onChange={(e) => {
                      setSelectedDepartementState(
                        departementState.find(
                          (elm) => elm.value == e.target.value
                        )
                      );
                      setFilterObject({
                        ...filerObject,
                        departement: e.target.value,
                      });
                    }}
                  />
                )}
                {selectedDepartementState?.value && (
                  <Select
                    labelText="Commune"
                    className="w-full px-3"
                    labelProps={{ className: "w-40" }}
                    value={
                      selectedCommunesState ? selectedCommunesState.value : ""
                    }
                    options={communesState}
                    onChange={(e) => {
                      setSelectedCommunesState(
                        communesState.find((elm) => elm.value == e.target.value)
                      );
                      setFilterObject({
                        ...filerObject,
                        commune: e.target.value,
                      });
                    }}
                  />
                )}
              </>
            )}
            {paysState === "Tunisie" && (
              <>
                <Select
                  labelText="Gouvernorat"
                  className="w-full px-3"
                  labelProps={{ className: "w-40" }}
                  value={villeTnState}
                  options={Object.keys(countriesTn).map((elm) => ({
                    label: elm,
                    value: elm,
                  }))}
                  onChange={(e) => {
                    setVilleTnState(e.target.value);
                    setFilterObject({
                      ...filerObject,
                      gouvernorat: e.target.value,
                    });
                  }}
                />

                {villeTnState && (
                  <Select
                    labelText="Délégation"
                    className="w-full px-3"
                    labelProps={{ className: "w-40" }}
                    value={delegationTnState}
                    options={delegationTn}
                    onChange={(e) => {
                      setDelegationTnState(e.target.value);
                      setFilterObject({
                        ...filerObject,
                        delegation: e.target.value,
                      });
                    }}
                  />
                )}
              </>
            )}
          </div> */}

          <Button
            status="secondary"
            height="50px"
            onClick={() => {
              setSearch(true);
              setOpenModal(false);
            }}
          >
            {t("ModalFichePatient.Filter")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
