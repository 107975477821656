import React from "react";
import _ from "lodash";
//** helper & API & FakeData */
import moment from "../../helper/moment";
import { colorAvatar } from "./fakeData";
import { loadImage } from "../../api/documents";
//** Assets */
import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";
import { ReactComponent as IconMessage } from "../../assets/icons/icon-message.svg";
import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";
import phone from "../../assets/images/phone.png";
import mail from "../../assets/images/mail.png";
import home from "../../assets/images/home.png";
import handicap from "../../assets/images/handicap.png";

import birthday from "../../assets/images/birthday.png";
import Button from "../ButtonUI";
import { useTranslation } from "react-i18next";
type Props = {
  patientData: any;
  colorIndex: any;
  consultaionPage: any;
  setSwitchToChatModal: any;
  setOpenModal: any;
  setOpenTab: any;
};

const Header = (props: Props) => {
  const {
    patientData,
    colorIndex,
    consultaionPage,
    setOpenModal,
    setSwitchToChatModal,
    setOpenTab,
  } = props;
  const { t } = useTranslation() as any;
  const handleHandicap = (name) => {
    switch (name) {
      case "Mental":
        return t("AddPatient.Physical");
      case "Moteur":
        return t("AddPatient.Mental");
      case "Aidant":
        return t("AddPatient.Helping");
      default:
        return "";
    }
  };

  return (
    <div className="flex header justify-between	">
      <div className="infos flex w-full justify-between md:mr-2">
        <div className="flex sub-infos">
          <div
            className="photo"
            style={{
              backgroundColor: patientData?.photo
                ? ""
                : colorAvatar[(colorIndex || 1) % colorAvatar.length],
            }}
          >
            {patientData?.photo ? (
              <img
                crossOrigin="anonymous"
                referrerPolicy="origin"
                src={loadImage(
                  patientData?._id,
                  "profile",
                  "lossless",
                  patientData?.photo
                )}
                alt="patient"
              />
            ) : patientData.sexe === "woman" ? (
              <WomanPatient />
            ) : (
              <ManPatient />
            )}
          </div>
          <div className="details">
            <div className="flex justify-between">
              <div className="">
                <p className="name capitalize mb-2">
                  {patientData.firstName} {patientData.lastName}
                </p>

                {patientData.birthDate &&
                  patientData.birthDate !== "Invalid Date" && (
                    <div className="flex birth-header items-center">
                      <div className="sub-birth flexCenter img hidden md:block">
                        <img
                          crossOrigin="anonymous"
                          referrerPolicy="origin"
                          src={birthday}
                          alt="birthday"
                        />
                      </div>
                      <div className="">
                        <p>
                          {moment(patientData.birthDate).format("L")} (
                          {moment(Date.now()).year() -
                            moment(patientData.birthDate).year()}{" "}
                          {t("MyPatient.year")})
                        </p>
                      </div>
                    </div>
                  )}
              </div>
              <div className="imcBox"></div>
            </div>
            <div className="flex flex-col md:flex-row justify-between phonemail gap-x-6 flex-wrap">
              <div className="flex ">
                <div className="flexCenter img hidden md:block">
                  <img
                    crossOrigin="anonymous"
                    referrerPolicy="origin"
                    src={phone}
                    alt="phone"
                    width="15.5px"
                    height="15.5px"
                  />
                </div>
                <div>
                  {patientData.phoneSuffixe && `${patientData.phoneSuffixe} `}
                  {patientData.phone}
                </div>
              </div>
              <div className="flex md:justify-between">
                <div className="flexCenter img">
                  <img
                    crossOrigin="anonymous"
                    referrerPolicy="origin"
                    src={mail}
                    alt="mail"
                    width="15.5px"
                    height="15.5px"
                  />
                </div>
                <div>{patientData.email}</div>
              </div>
            </div>

            <div className="flex">
              <div className="flex">
                <div className="flexCenter img">
                  {patientData.address && (
                    <img
                      crossOrigin="anonymous"
                      referrerPolicy="origin"
                      src={home}
                      alt="home"
                      width="14.64px"
                      height="14.64px"
                    />
                  )}
                </div>

                <div>
                  {patientData.address && `${patientData.address}`}
                  {patientData.postalCode && `, ${patientData.postalCode}`}
                </div>
              </div>
            </div>

            {!_.isEmpty(patientData?.handicap) && (
              <div className="flex">
                <div className="flexCenter mt-1">
                  <div className="flexCenter img">
                    <img
                      crossOrigin="anonymous"
                      referrerPolicy="origin"
                      src={handicap}
                      alt="handicap"
                      width="18px"
                      height="18px"
                    />
                  </div>
                  <div>
                    {patientData?.handicap.map((el) => (
                      <span>{handleHandicap(el)}</span>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {patientData?.semaineNBR && (
              <div className="flex mb-2">
                <div className="flexCenter mt-1">
                  <p>
                    {`${t("MyPatient.Pregnancy")} : ${
                      patientData?.semaineNBR
                    } ${t("MyPatient.weeks")}`}{" "}
                  </p>
                </div>
              </div>
            )}

            <div className="flex mb-2">
              <div className="flexCenter mt-1">
                {patientData?.numSecSocial && (
                  <div className="flexCenter img">
                    {t("MyPatient.SocialSecurityNumber")}
                  </div>
                )}
                <div>{patientData?.numSecSocial}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="patient-icons flex flex-col justify-between items-center ">
          {/* <Button status="icon-secondary">
          <ThreeDots />
        </Button> */}
          <div className="block md:hidden">
            {!consultaionPage && (
              <div>
                <Button
                  status="icon-secondary"
                  onClick={() => setOpenModal(false)}
                >
                  <Close />
                </Button>
              </div>
            )}
          </div>
          <Button
            status="icon-secondary"
            className="bg-white"
            onClick={() => {
              setSwitchToChatModal(true);
              // setOpenModal(false);
            }}
          >
            <IconMessage />
          </Button>
          {process.env.REACT_APP_KATOMI_TENANT === "katomi" && (
            <>
              <img
                crossOrigin="anonymous"
                referrerPolicy="origin"
                src={require("../../assets/fichePatientTabs/ins.svg").default}
                alt="INS"
                width={25}
                height={25}
                style={{ maxWidth: "inherit" }}
                className="block mb-2 cursor-pointer"
                onClick={() => setOpenTab(8)}
              />
              <img
                crossOrigin="anonymous"
                referrerPolicy="origin"
                src={require("../../assets/fichePatientTabs/dmpc.svg").default}
                alt="INS"
                width={25}
                height={25}
                style={{ maxWidth: "inherit" }}
                className="block mb-2 cursor-pointer"
                onClick={() => setOpenTab(9)}
              />
            </>
          )}
        </div>
      </div>
      <div className=" hidden md:block">
        {!consultaionPage && (
          <div>
            <Button status="icon-secondary" onClick={() => setOpenModal(false)}>
              <Close />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
