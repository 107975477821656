/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Image, Transformer } from "react-konva";

const ImageComponenet = ({
  listData,
  setListData,
  id,
  data,
  setSelectedInputId,
  selectedInputId,
  isSelected,
  setIselected,
  setDataSelectedObj,
  setShowModalImageOption,
}) => {
  const [img, setImg] = useState(null) as any;
  const shapeRef = React.useRef() as any;
  const trRef = React.useRef() as any;
  const [loadGrid, setLoadGrid] = useState(false);

  useEffect(() => {
    const newImage = new window.Image();
    newImage.src = data.b64;
    newImage.onload = () => {
      // setState will redraw layer
      // because "image" property is changed
      setImg(newImage);
      setLoadGrid((prevState) => true);
    };
    return () => {
      setLoadGrid(false);
      setImg(null);
    };
  }, []);

  useEffect(() => {
    if (isSelected && data.id === selectedInputId) {
      trRef?.current?.nodes([shapeRef?.current]);
      trRef?.current?.getLayer().batchDraw();
    }
  }, [isSelected, selectedInputId]);

  return loadGrid ? (
    <>
      <Image
        onDragMove={(e) => {
          const node = shapeRef.current;
          const CopyOfData = listData;
          // eslint-disable-next-line eqeqeq
          let foundIndex = listData.findIndex((x) => x.id == data.id);
          CopyOfData[foundIndex] = {
            ...listData[foundIndex],
            x: node.getX(),
            y: node.getY(),
          };
          setListData([...CopyOfData]);
        }}
        draggable
        opacity={data.opacity / 10}
        image={img}
        x={data.x}
        y={data.y}
        width={data.width}
        height={data.height}
        onTransformEnd={(e) => {
          setSelectedInputId(data.id);
          if (selectedInputId === data.id) {
            const node = shapeRef.current;
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();
            node.scaleX(1);
            node.scaleY(1);

            const getImageObj = listData.find((e) => e.id === selectedInputId);
            const copyData = listData.filter((e) => e.id !== selectedInputId);
            setListData([
              ...copyData,
              {
                ...getImageObj,
                x: node.x(),
                y: node.y(),
                width: Math.max(5, node.width() * scaleX),
                height: Math.max(node.height() * scaleY),
              },
            ]);
          }
        }}
        visible={true}
        onDblClick={(e) => {
          setListData([...listData.filter((e) => e.id !== id)]);
        }}
        //   ref={node => {
        //     this.imageNode = node;
        //   }}
        ref={shapeRef}
        onClick={(e) => {
          setIselected(true);
          setSelectedInputId(data.id);
          setDataSelectedObj(data);
          setShowModalImageOption(true);
        }}
      />
      {isSelected && selectedInputId === data.id && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  ) : (
    <></>
  );
};

export default ImageComponenet;
