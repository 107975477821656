import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/ButtonUI";
import Input from "../../../components/Input";
import "./styles.scss";
import sitesShcema from "./schemas/sitesShcema";
import { addOrModifySites } from "../../../api/doctor";
import { Formik } from "formik";
import { User } from "../../../types/user";
import { userAtom } from "../../../state/auth";
import { useAtom } from "jotai";
import makeToast from "../../../components/Snackbar";
import Icon from "../../../components/Icon";

interface Props {
  openModal: boolean;
  setOpenModal: Function;
  doctorId?: string;
}

export const ModifySites: React.FC<Props> = ({ openModal, setOpenModal }) => {
  const { t } = useTranslation() as any;
  const [user, setUser] = useAtom(userAtom);
  const addSite = async (values) => {
    try {
      const newUser = await addOrModifySites({ ...values, user });
      const { linkedin, facebook, instagram, website } = newUser;
      setUser({
        ...user,
        linkedin,
        facebook,
        instagram,
        website,
      } as User);
      setOpenModal(!openModal);
      if (newUser._id) {
        makeToast("success", t("Profile.SuccessUpdate"));
      } else {
        makeToast("error", t("Profile.FailedUpdate"));
      }
    } catch (err) {}
  };
  return (
    <div className=" cursusAdd modalSites">
      <div className="flex justify-end cursor-pointer pr-5 pt-5">
        <Icon
          isIcon={true}
          name="close"
          onClick={() => setOpenModal(!openModal)}
          className="bg-gray-100 rounded-xl cursor-pointer hover:bg-gray-200"
        />
      </div>
      <Formik
        initialValues={{
          linkedin: "" || user?.linkedin,
          facebook: "" || user?.facebook,
          instagram: "" || user?.instagram,
          website: "" || user?.website,
        }}
        onSubmit={async (values) => {
          try {
            const newUser = await addOrModifySites({ ...values, user });
            const { linkedin, facebook, instagram, website } = newUser;
            setUser({
              ...user,
              linkedin,
              facebook,
              instagram,
              website,
            } as User);
            setOpenModal(!openModal);
            if (newUser._id) {
              makeToast("success", t("Profile.SuccessUpdate"));
            } else {
              makeToast("error", t("Profile.FailedUpdate"));
            }
          } catch (err) {}
        }}
        validationSchema={sitesShcema}
      >
        {(props) => {
          const {
            values,
            // errors,
            // isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;

          return (
            <form
              onSubmit={handleSubmit}
              className="flex-auto pb-7 px-8 min-w-full	"
            >
              <div className="flex flex-wrap items-baseline">
                <h1 className="mb-10">{t("Profile.SITESAndNETWORKS")}</h1>
              </div>
              <div className="">
                <Input
                  id="website"
                  placeholder="Exemple  www.centre-sante-laplaine.com"
                  //error={values.pricing}
                  icon={"laptop"}
                  value={values.website}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inlineLabel={false}
                  onfocus={true}
                  isIcon={false}
                  className="w-full"
                />
                <Input
                  id="facebook"
                  placeholder="Exemple @dr-aurelie-martin"
                  //error={values.pricing}
                  icon={"facebook"}
                  value={values.facebook}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inlineLabel={false}
                  onfocus={true}
                  isIcon={false}
                  className="w-full"
                />
                <Input
                  id="linkedin"
                  placeholder="Exemple  /in/aurelien-martin-52791207/"
                  //error={values.pricing}
                  icon={"linkedin"}
                  value={values.linkedin}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inlineLabel={false}
                  onfocus={true}
                  isIcon={false}
                  className="w-full"
                />
                <Input
                  id="instagram"
                  placeholder="Exemple  @aureliemartin"
                  //error={values.pricing}
                  icon={"instagram"}
                  value={values.instagram}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inlineLabel={false}
                  onfocus={true}
                  isIcon={false}
                  className="w-full custom-Input mb-2.5"
                />
              </div>
              <Button
                status="secondary"
                width="100px"
                height="50px"
                className="float-left mb-4 btn-delete"
                onClick={() => setOpenModal(false)}
              >
                {t("Profile.Cancel")}
              </Button>
              <Button
                status="secondary"
                width="100px"
                height="50px"
                className="float-right mb-4"
                onClick={() => addSite(values)}
              >
                {t("Profile.Save")}
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ModifySites;
