/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAtom } from "jotai";
import moment from "../../helper/moment";

/** API & HELPER */
import { getTaskByIdPrescreiption } from "../../api/task";
import history from "../../history";
import { loadImage } from "../../api/documents";
import { User } from "../../types/user";
import { userAtom } from "../../state/auth";
/** COMPONENTS */
import Button from "../ButtonUI";
import Icon from "../Icon";
import TransmettreDocumentPDF from "./TransmettreDocument";
import ChatModal from "../SharedChat";

import Tooltip from "../toolTip";
/** ASSETS */
import "./styles.scss";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import DataToJSPDFComponent from "../TemplatePDFCreator/pdfToJsPdfButtons";
import DEFAULT_TEMPALTE from "../PreviewRapport/defaultTemplate.json";
import { printCharacters } from "../TemplatePDFCreator/Helper/functions";
import { getTemplateById } from "../../api/pdfTemalpte";

const Index: React.FC<any> = (): any => {
  // const { windowDimenion } = useScreenSize();
  const [openChatModal, setOpenCHatModal] = useState<boolean>(false);
  const [chatWithId, setChatWithId] = useState<string>("");
  const [, setModalInfo] = useState(true);
  const [task, setTask] = useState(null) as any;
  const { taskId, prescriptionId } = useParams() as any;
  const [user] = useAtom<User | null>(userAtom);
  const [, setDoctorSignature] = useState("");
  const [modalTransmettreDoc, setModalTransmettreDoc] = useState(false);
  const [editorState, setEditorState] = useState<any>();
  const [linkPdf, setLinkPdf] = useState();
  const [sharedY] = useState();
  const [defaultTemplate] = useState({ ...DEFAULT_TEMPALTE }) as any;
  const [selectedTemplate, setSelectedTemplate] = useState({}) as any;
  const [loading, setLoading] = useState(false);

  const convertStringToEditorState = (prescreptionList) => {
    if (prescreptionList) {
      const contentBlock = htmlToDraft(prescreptionList);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );

        setEditorState(EditorState.createWithContent(contentState));
      }
    }
  };

  const parseImage = (url) => ({
    original: loadImage(
      user?.role === "DOCTOR" ? user?._id : task?.doctor?._id,
      "signature",
      "thumbnail",
      url
    ),
  });

  useEffect(() => {
    async function getTaskById() {
      try {
        const response = await getTaskByIdPrescreiption(taskId);
        setTask(response?.task);
      } catch (error) {
        console.error(error);
      }
    }
    getTaskById();
  }, [taskId]);

  useEffect(() => {
    async function getSpecifiedTemplate() {
      const findPrescreption = task?.consultation?.prescriptions.find(
        (e) => e._id === prescriptionId
      );
      if (findPrescreption?.templatePdfId) {
        const response = await getTemplateById(findPrescreption?.templatePdfId);
        setSelectedTemplate({ ...response.data });
      }
      setLoading(true);
    }
    if (task) {
      getSpecifiedTemplate();
    }
  }, [task]);

  useEffect(() => {
    if (task) {
      if (user?.role === "DOCTOR") {
        setDoctorSignature(parseImage(user?.signaturePath).original);
      } else {
        setDoctorSignature(parseImage(task?.doctor?.signaturePath).original);
      }
      let collectedPrecriptionData = "";
      task.consultation.prescriptions.map((e) => {
        e.prescriptions.map((d) => {
          collectedPrecriptionData += `${d.medicament} ${d.dosage} <br>`;
          // collectedPrecriptionData += ` ${d.medicament} ${d.dosage} `;
        });
      });
      convertStringToEditorState(collectedPrecriptionData);
    }
  }, [task]);

  return (
    loading && (
      <>
        <ChatModal
          chatWithId={chatWithId}
          showModal={openChatModal}
          closeChatModal={() => setOpenCHatModal(false)}
        />
        <div className="preview-Page">
          <div className="flex justify-between mb-10">
            <div className="header flex">
              <div className="flexCenter">
                <Icon
                  name="direction-gauche"
                  isIcon={true}
                  transform="scale(0.6)"
                  color="#A6A9C8"
                  className="cursor-pointer"
                  onClick={history.goBack}
                />
              </div>
              <div className="flexCenter header-msg">
                <h3>
                  Préscription médicale -{" "}
                  {task ? moment(task.dateConsultation).format("LL") : ""} -
                  {task?.doctor &&
                    `Dr ${task?.doctor?.firstName} ${task?.doctor?.lastName}`}
                </h3>
              </div>
            </div>
            <div className="flex gap-2">
              <DataToJSPDFComponent
                listData={
                  selectedTemplate?.listData || defaultTemplate?.listData
                }
                editorState={editorState}
                printCharacters={printCharacters}
                sharedY={sharedY}
                setShowModal={() => {}}
                templateName={
                  selectedTemplate?.templateName || defaultTemplate.templateName
                }
                modalType={
                  selectedTemplate?.modalType || defaultTemplate.modalType
                }
                isPatient={user?.role !== "DOCTOR"}
                setLinkPdf={setLinkPdf}
                linkPdf={linkPdf}
                isPrescreption={true}
              />
              {user?.role === "PATIENT" && (
                <Tooltip tooltipText="Partager" position="left">
                  <Button
                    status="secondary"
                    onClick={() => {
                      setModalTransmettreDoc(true);
                      setModalInfo(true);
                    }}
                  >
                    <Icon isIcon={true} name="export" />
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
          {/* {ordonnance} */}

          <div className="w-full flexCenter ">
            {linkPdf && (
              <embed
                type="application/pdf"
                style={{
                  minHeight: 1200,
                  minWidth: "100%",
                }}
                src={`${linkPdf}#toolbar=0`}
              />
            )}
          </div>
        </div>
        {/* {modalInfo && task && user?.role === "PATIENT" && (
        <InfoModal
          setModal={setModalInfo}
          openModal={modalInfo}
          data={task}
          openModalTransmettre={modalTransmettreDoc}
          setModalTransmettre={setModalTransmettreDoc}
        />
      )} */}
        {modalTransmettreDoc && (
          <TransmettreDocumentPDF
            openModal={modalTransmettreDoc}
            setModal={setModalTransmettreDoc}
            taskId={task._id}
            doctorIdOfConsultation={task.doctor._id}
            prescriptionId={prescriptionId}
            setChatWithId={(e) => {
              setChatWithId(e);
              setOpenCHatModal(true);
            }}
          />
        )}
      </>
    )
  );
};

export default Index;
