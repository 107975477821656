import { Formik } from "formik";
import { useAtom } from "jotai";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
//**Helper & API */
import currencyList from "../../../config/constants/currency.json";
import { addOrModifyTarifs } from "../../../api/doctor";
import { userAtom } from "../../../state/auth";
import { User } from "../../../types/user";
//**Component */
import Select from "../../../components/Select";
import Button from "../../../components/ButtonUI";
import Input from "../../../components/FloatInput";
import makeToast from "../../../components/Snackbar";
import Icon from "../../../components/Icon";
//**Assets */
import "./styles.scss";
// import { BsTrash } from "react-icons/bs";

interface Props {
  openModal: boolean;
  setOpenModal: Function;
  doctorId?: string;
}

// const OtherConsultationPrice = {
//   tarifLabel: "",
//   price: "",
// };

const AddMofiyTarif: React.FC<Props> = ({ openModal, setOpenModal }) => {
  const { t } = useTranslation() as any;
  const [user, setUser] = useAtom(userAtom) as any;
  const updateTarif = async (values) => {
    try {
      values.otherPrice = values.otherPrice.filter(
        (el) => !!el.tarifLabel && !!el.price
      );
      const newUser = await addOrModifyTarifs({ ...values, user });
      const {
        pricing,
        pricingNight,
        pricingWeekEnd,
        otherPrice,
        currency,
        pricingTeleconsultation,
      } = newUser;

      setUser({
        ...user,
        currency,
        pricing,
        pricingNight,
        pricingWeekEnd,
        pricingTeleconsultation,
        otherPrice,
      } as User);
      setOpenModal(!openModal);
      if (newUser._id) {
        makeToast("success", t("Profile.SuccessModificationPrice"));
      } else {
        makeToast("error", t("Profile.FailedModificationPrice"));
      }
    } catch (err) {}
  };
  return (
    <div className="h-add-tarif-modal">
      <div className="flex justify-end cursor-pointer pt-5 pr-5 ">
        <div className="bg-gray-200 bg-opacity-50 rounded">
          <Icon
            isIcon={true}
            name="close"
            onClick={() => setOpenModal(!openModal)}
          />
        </div>
      </div>
      <Formik
        initialValues={{
          currency: user?.currency || "EUR",
          otherPrice: !_.isEmpty(user?.otherPrice)
            ? user?.otherPrice
            : [
                {
                  tarifLabel: "",
                  price: "",
                },
              ],
          pricingNight:
            user?.pricingNight !== undefined ? user?.pricingNight : 0,
          pricingWeekEnd:
            user?.pricingWeekEnd !== undefined ? user?.pricingWeekEnd : 0,
          pricingTeleconsultation:
            user?.pricingTeleconsultation !== undefined
              ? user?.pricingTeleconsultation
              : 0,
          pricing: user?.pricing !== undefined ? user?.pricing : 0,
        }}
        onSubmit={async (values) => {
          try {
            values.otherPrice = values.otherPrice.filter(
              (el) => !!el.tarifLabel && !!el.price
            );
            const newUser = await addOrModifyTarifs({ ...values, user });
            const {
              pricing,
              pricingNight,
              pricingWeekEnd,
              otherPrice,
              currency,
            } = newUser;

            setUser({
              ...user,
              currency,
              pricing,
              pricingNight,
              pricingWeekEnd,
              otherPrice,
            } as User);
            setOpenModal(!openModal);
            if (newUser._id) {
              makeToast("success", t("Profile.SuccessModificationPrice"));
            } else {
              makeToast("error", t("Profile.FailedModificationPrice"));
            }
          } catch (err) {}
        }}
        // validationSchema={tarifSchema}
      >
        {(props) => {
          const {
            values,
            // errors,
            // isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;

          return (
            <form
              onSubmit={handleSubmit}
              className="flex-auto pb-7 px-8 min-w-ful"
            >
              <div className="flex flex-wrap items-baseline">
                <h1 className="mb-10"> {t("Profile.PRICESANDPAYMENTS")}</h1>
              </div>
              <h3> {t("Profile.Currency")}</h3>
              <div className="mt-2">
                <Select
                  id="currency"
                  options={currencyList.map((el) => ({
                    label: `${el.code} - ${el.name} `,
                    value: el.code,
                  }))}
                  placeholder={t("Profile.Currency")}
                  value={values.currency}
                  setText={false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="w-full mb-2.5 rounded-lg border-gray-200 outline-none px-3"
                />
                <h3 className="mb-3">{t("Profile.ConsultationPrice")}</h3>
                <Input
                  id="pricing"
                  placeholder={t("Profile.NormalConsultationPrice")}
                  labelText={t("Profile.NormalConsultationPrice")}
                  type="text"
                  //error={values.pricing}
                  value={values.pricing}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inlineLabel={false}
                  onfocus={true}
                />

                <Input
                  id="pricingNight"
                  placeholder={t("Profile.NightConsultationPrice")}
                  labelText={t("Profile.NightConsultationPrice")}
                  type="text"
                  //error={values.pricingNight}
                  value={values.pricingNight}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inlineLabel={false}
                  onfocus={true}
                />
                <Input
                  id="pricingWeekEnd"
                  placeholder={t("Profile.WeekEndConsultationPrice")}
                  labelText={t("Profile.WeekEndConsultationPrice")}
                  type="text"
                  //error={values.pricingWeekEnd}
                  value={values.pricingWeekEnd}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inlineLabel={false}
                  onfocus={true}
                />
                <Input
                  id="pricingTeleconsultation"
                  placeholder={t("Profile.teleConsultationPrice")}
                  labelText={t("Profile.teleConsultationPrice")}
                  type="text"
                  //error={values.pricingTeleconsultation}
                  value={values.pricingTeleconsultation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inlineLabel={false}
                  onfocus={true}
                />
              </div>
              {/* <h3 className="my-7">{t("Profile.OtherConsultationType")}</h3>
              <FieldArray name="otherPrice">
                {({ push, remove }) => (
                  <>
                    {values.otherPrice.map((element, index) => (
                      <div key={`otherPrice-${index}`} className="flex">
                        <div className="w-9/12">
                          <Input
                            id={`otherPrice[${index}].tarifLabel`}
                            placeholder={t("Profile.ConsultationType")}
                            labelText={t("Profile.ConsultationType")}
                            type="text"
                            value={values.otherPrice[index].tarifLabel}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inlineLabel={false}
                            onfocus={true}
                          />
                          <Input
                            id={`otherPrice[${index}].price`}
                            placeholder={t("Profile.ConsultationPrice")}
                            labelText={t("Profile.ConsultationPrice")}
                            type="text"
                            value={values.otherPrice[index].price}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inlineLabel={false}
                            onfocus={true}
                          />
                        </div>
                        <div className="flexCenter w-3/12 space-x-1">
                          {values.otherPrice.length > 1 && (
                            <BsTrash
                              onClick={() => {
                                if (!isSubmitting) {
                                  remove(index);
                                }
                              }}
                              size={28}
                              className="cursor-pointer"
                            />
                          )}

                          <Button
                            disabled={isSubmitting}
                            type="button"
                            onClick={() => push(OtherConsultationPrice)}
                            width={"40px"}
                            height={"40px"}
                          >
                            +
                          </Button>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </FieldArray> */}
              <div className="mb-4 mt-10 flex justify-around">
                <Button
                  status="secondary"
                  width="100px"
                  height="50px"
                  className="btn-delete"
                  onClick={() => setOpenModal(false)}
                >
                  {t("Profile.Cancel")}
                </Button>
                <Button
                  status="secondary"
                  width="100px"
                  height="50px"
                  className=""
                  onClick={() => updateTarif(values)}
                >
                  {t("Profile.Save")}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddMofiyTarif;
