import { Tag, Image } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import moment from "../../../helper/moment";
const ViewElement = ({ element, value, ...props }) => {
  const { t } = useTranslation() as any;
  const { type, ...data } = element;

  const renderElement = () => {
    switch (type) {
      case "TextInput":
      case "NumberInput":
      case "TimePicker":
      case "Dropdown":
      case "RadioButtons":
        return <div>{value ? value : t("Survey.NA")}</div>;

      case "File":
        return (
          <>
            {value?.map((item, index) => (
              <div key={index}>
                <a href={item.url} target="_blank" rel="noreferrer">
                  {item.name}
                </a>
              </div>
            ))}
          </>
        );
      case "Photo":
        return <span>{value && value.name ? value.name : t("Survey.NA")}</span>;
      case "Rating":
        return (
          <div className="">
            {value ? value / parseInt(data.count) : t("Survey.NA")}
          </div>
        );
      case "Signature":
        return value ? (
          <Image width={200} src={value} />
        ) : (
          <p>{t("Survey.NA")}</p>
        );
      case "Range":
        //   const marks = { [data.min]: data.minLabel, [data.max]: data.maxLabel };
        //  const { minLabel, maxLabel, ...rangeProps } = data;
        return value ? (
          <span>
            {value} / {data.max}
          </span>
        ) : (
          <span>{t("Survey.NA")}</span>
        );
      case "Checkboxes":
        return <div>{value ? value : t("Survey.NA")}</div>;
      case "Tags":
        return value ? (
          <div>
            {value?.map((v, index) => (
              <Tag key={index}>{v}</Tag>
            ))}
          </div>
        ) : (
          <div>{t("Survey.NA")}</div>
        );
      case "DatePicker":
        return (
          <div>
            {value ? moment(value).format(data.format) : t("Survey.NA")}
          </div>
        );

      case "TextArea":
        return value ? (
          <pre className="truncate">{value}</pre>
        ) : (
          <pre>{t("Survey.NA")}</pre>
        );
      case "Email":
        return value ? <pre>{value}</pre> : <pre>{t("Survey.NA")}</pre>;
      case "ident":
        return (
          <span>
            {value && (
              <>
                {" "}
                <p>
                  {t("Survey.Name")}{" "}
                  {value.nom ? value.nom + " " + value.prenom : t("Survey.NA")}
                </p>
                <p>
                  {" "}
                  {t("Survey.Email")}{" "}
                  {value.email ? value.email : t("Survey.NA")}
                </p>
                <p>
                  {t("Survey.NumTel")}{" "}
                  {value["téléphone"] ? value["téléphone"] : t("Survey.NA")}
                </p>
                <p>
                  {t("Survey.NumSocial")}{" "}
                  {value["téléphone"]
                    ? value["N° de sécurité sociale"]
                    : t("Survey.NA")}
                </p>
              </>
            )}
          </span>
        );
      case "fullAdress":
        return (
          <span>
            {value && (
              <>
                {" "}
                <p>
                  {t("Survey.PostalAddress")} :{" "}
                  {value.adresse ? value.adresse : t("Survey.NA")}
                </p>
                <p>
                  {t("Survey.PostalCode")} :{" "}
                  {value.zipCode ? value.zipCode : t("Survey.NA")}
                </p>
                <p>
                  {t("Survey.City")}{" "}
                  {value.ville ? value.ville : t("Survey.NA")}
                </p>
                <p>
                  {t("Survey.Country")}{" "}
                  {value.pays ? value.pays : t("Survey.NA")}
                </p>
                <p>
                  {t("Survey.Department")}{" "}
                  {value.departement ? value.departement : t("Survey.NA")}
                </p>
              </>
            )}
          </span>
        );
      case "TableField":
        return (
          <span>
            {value ? (
              <>
                {Object.entries(value).map((item) => {
                  return (
                    <div className="grid grid-cols-2  gap-2 text-sm  px-2 py-1 border border-0.5 ">
                      <p className="text-left"> {item[0]}</p>
                      <p className="border-l-2 text-center">
                        {" "}
                        {item[1] && item[1] !== "" ? item[1] : t("Survey.NA")}
                      </p>
                    </div>
                  );
                })}
              </>
            ) : (
              <p>{t("Survey.NA")}</p>
            )}
          </span>
        );
      default:
        return <></>;
    }
  };
  return <div className="w-1/2">{renderElement()}</div>;
};

export default ViewElement;
