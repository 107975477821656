import config from "../../config/constants/devbox.config.json";
import CPSApi from "./cps";
import INSApi from "./ins";
import DMPApi from "./dmp";

const DEVBOX_ENDPOINT = `http://localhost:${config.devboxPort}`;

export const CPS = new CPSApi(DEVBOX_ENDPOINT);
export const INS = new INSApi(DEVBOX_ENDPOINT);
export const DMP = new DMPApi(DEVBOX_ENDPOINT);
