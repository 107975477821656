import { useState } from "react";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { editPatient } from "../../../api/patient";
import { userAtom } from "../../../state/auth";
import Button from "../../../components/ButtonUI";
import Icon from "../../../components/Icon";
import Input from "../../../components/FloatInput";

interface IEmail {
  id: string | undefined;
  email: String;
}

const EditEmailModal = ({ closeModal }) => {
  const { t } = useTranslation() as any;
  const [user, setUser] = useAtom(userAtom);
  const [newEmail, setNewEmail] = useState("");
  const handleEditEmail = async () => {
    try {
      let values: IEmail = { id: user?._id, email: newEmail };
      const response = await editPatient(values);
      setUser(response);
    } catch (error) {}
  };
  return (
    <div className="p-5">
      <div className="flex mb-10 w-full ">
        <h2 className="m-auto"> {t("ProfilePatient.updateEmail")}</h2>
        <div className="float-right close-modal">
          <Icon isIcon={true} name="close" onClick={closeModal} />
        </div>
      </div>

      <Input
        type="email"
        labelText={t("ProfilePatient.CurrentEmail")}
        placeholder={t("ProfilePatient.CurrentEmail")}
        className="edit-email"
        value={user?.email}
        disabled
      />
      <Input
        type="email"
        labelText={t("ProfilePatient.NewEmail")}
        placeholder={t("ProfilePatient.NewEmail")}
        onChange={(e) => setNewEmail(e.target.value)}
      />

      <div className="flexCenter my-5">
        <Button width="170px" height="50px" onClick={handleEditEmail}>
          {t("ProfilePatient.Save")}
        </Button>
      </div>
    </div>
  );
};

export default EditEmailModal;
