import { useState } from "react";
import { useAtom } from "jotai";
import _ from "lodash";
import { userAtom } from "../../state/auth";
import moment from "../../helper/moment";
/**
 * helper & API
 */
import useScreenSize from "../../hooks/ScreenSize";
import { doctorUpdateConsultationDate } from "../../api/consultation";
/**
 * Component
 */
import DoctorTimeSlot from "../DoctorTimeSlots";
import Button from "../ButtonUI";
import Icon from "../Icon";
import makeToast from "../Snackbar";
/**
 * Assets
 */
import { ReactComponent as AgendaIcon } from "../../assets/icons/icon-agenda.svg";
import { useTranslation } from "react-i18next";

interface IMeetDetails {
  id: string;
  timeSlotId: string;
  doctorId: any;
  motif: string;
  type: string;
  item: any;
  consultationId: string;
  comment?: string;
}

interface Props {
  meetDetails: IMeetDetails;
  closeModal: any;
}

const Index = (props: Props) => {
  const { t } = useTranslation() as any;
  const { closeModal, meetDetails } = props;
  const { windowDimenion } = useScreenSize();
  const [user] = useAtom(userAtom) as any;
  const [changeMeetDetail, setChangeMettDetail] = useState() as any;
  const [loadingBtn, setLoadingBtn] = useState(false);
  const handleChangeMeet = async () => {
    try {
      if (!changeMeetDetail || _.isEmpty(changeMeetDetail)) return closeModal();

      setLoadingBtn(true);
      const result = await doctorUpdateConsultationDate({
        timeSlotId: changeMeetDetail?.timeslotId,
        consultationId: meetDetails?.item?.consultationId,
        dateConsultation: changeMeetDetail?.time,
        oldTimeSlotId: meetDetails?.item?.id,
        patientId: meetDetails?.item?.patientId?._id,
      });
      if (result) makeToast("success", t("DoctorMoveMeeting.SuccessUpdate"));
      closeModal();
      setLoadingBtn(false);
    } catch (error) {
      setLoadingBtn(false);
      closeModal();
      makeToast("error", t("DoctorMoveMeeting.SomethingWentWrong"));
    }
  };

  return (
    <div>
      <>
        <div className="p-5">
          <div className="flex">
            <h2 className="m-auto">{t("DoctorMoveMeeting.YourMeeting")}</h2>
            <div className="float-right close-modal cursor-pointer">
              <Icon isIcon={true} name="close" onClick={closeModal} />
            </div>
          </div>
          <div className="flex items-center ">
            <AgendaIcon className="agenda-icon mr-3 md:mr-0 hidden md:block" />
            <span className="card-subtitle">
              {t("DoctorMoveMeeting.DateAppointment")}{" "}
              <span>
                {moment(meetDetails?.item?.startTime).format(t("Moment.Z"))}
              </span>
            </span>
          </div>

          {/* Comment */}
          {meetDetails?.item?.comment && (
            <span className="block mt-5">
              {" "}
              {t("DoctorMoveMeeting.Comment")} {meetDetails?.item?.comment}
            </span>
          )}
          {/* Book New Meet */}
          {(!changeMeetDetail || _.isEmpty(changeMeetDetail)) && (
            <span className="block mt-5">
              {" "}
              {t("DoctorMoveMeeting.ChangeTS")}
            </span>
          )}
          <DoctorTimeSlot
            withResult
            handlePickDate={(pickedDate, doctorDetails, eventType) => {
              setChangeMettDetail(pickedDate);
            }}
            maxCol={windowDimenion.winWidth > 768 ? 5 : 3}
            doctorDetails={user}
          />
          {/* End Book New Meet */}
          {
            meetDetails.type === "teleconsultation" && (
              <span className="card-subtitle">
                {" "}
                {t("DoctorMoveMeeting.ConsultationTeleconsultation")}
              </span>
            )
            //  : (
            //   <div className="flex items-center ">
            //     <Icon isIcon={true} name="event-cabinet" />
            //     <span className="card-subtitle">Lieu de votre rendez-vous :</span>
            //     <span className="max-w-xs ml-2">{user?.address}</span>
            //   </div>
            // )
          }
          {/* 
        <div className="flex items-center">
          <Icon isIcon={true} name="event-cabinet" />
          <span className="card-subtitle">Lieu de votre rendez-vous : </span>          
          <span className="max-w-xs"> {meetDetails.doctorId?.address}</span>
        </div> */}
          <div className="flexCenter my-5">
            <Button
              width="170px"
              height="50px"
              onClick={handleChangeMeet}
              disabled={loadingBtn}
            >
              {changeMeetDetail && !_.isEmpty(changeMeetDetail) ? (
                <div className="relative">
                  {loadingBtn && (
                    <div className="absolute h-full w-full flexCenter">
                      <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-purple-500"></div>
                    </div>
                  )}
                  {t("DoctorMoveMeeting.Confirm")}
                </div>
              ) : (
                t("DoctorMoveMeeting.Close")
              )}
            </Button>
          </div>
        </div>
      </>
    </div>
  );
};

export default Index;
