/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import ReactHtmlParser from "react-html-parser";
import { userAtom } from "../../../state/auth";
import Spinner from "../../../components/Spinner";
import { getConsultationByDoctorIdAndPatientId } from "../../../api/consultation";

interface Props {
  closeModal: any;
  selectedDoctor: any;
}
const CompteRendu: React.FC<Props> = ({ closeModal, selectedDoctor }) => {
  const [loading, setLoading] = useState(false);
  const [consultation, setConsultation] = useState() as any;

  const [user] = useAtom(userAtom) as any;

  const _getConsultationDetails = async () => {
    try {
      setLoading(true);
      const response = await getConsultationByDoctorIdAndPatientId(
        selectedDoctor._id,
        user?._id
      );
      setConsultation(response?.consultation);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error("Cant Fech consultation:", error);
    }
  };

  useEffect(() => {
    if (selectedDoctor?._id) {
      _getConsultationDetails();
    }
  }, [selectedDoctor]);

  return loading ? (
    <Spinner />
  ) : (
    <div className="patient-compte-rendu">
      {/* <div className="flex items-center  header-container ">
        <div className="flex justify-between w-full header-chat">
          <div className="flex items-center space-x-5">
            <div className="avatarMedecinChat">
              {selectedDoctor?.business_photo &&
              selectedDoctor?.business_photo.slice(0, 4) === "http" ? (
                <div className="avatar-doctor ">
                  <img crossOrigin = "anonymous"  referrerPolicy="origin"
                    src={selectedDoctor?.business_photo}
                    alt="doctor avatar"
                    className="object-cover rounded-lg w-full h-full"
                  />
                </div>
              ) : selectedDoctor?.business_photo ? (
                <img crossOrigin = "anonymous"  referrerPolicy="origin"
                  src={loadImage(
                    selectedDoctor?._id,
                    "profile",
                    "thumbnail",
                    selectedDoctor?.business_photo
                  )}
                  alt="patient"
                  className="object-cover rounded-lg w-full h-full"
                />
              ) : selectedDoctor.sexe === "woman" ? (
                <WomanPatient />
              ) : (
                <ManPatient />
              )}
            </div>
            <div className="card-title">
              <span className="card-title">
                {`${selectedDoctor?.firstName} ${selectedDoctor?.lastName}`}{" "}
              </span>
              <p className="card-subtitle ">{selectedDoctor?.speciality}</p>
            </div>
          </div>
          <div className="folder-container"></div>
        </div>
        <div className="flex space-x-10 cros">
          <IconCloseModal onClick={closeModal} />
        </div>
      </div> */}
      {!consultation || !consultation?.rapport ? (
        <div className="flexCenter h-full">
          <h3>Pas de compte rendu trouvé avec ce médecin</h3>
        </div>
      ) : (
        <>
          <h1 className="text-center">Compte rendu</h1>
          <div className="flex flex-col data-container">
            <div className="motifs-container">
              <h3>Motif</h3>
              <div className="flex motifs">
                <div className="boxSpecialite flexCenter">
                  <p>{consultation?.motif}</p>
                </div>
                {/* {motifs.map((motif) => {
                  return (
                    <div className="boxSpecialite flexCenter">
                      <p>{motif}</p>
                    </div>
                  );
                })} */}
              </div>
            </div>
            <div className="conclusion">
              {consultation?.rapport &&
                ReactHtmlParser(ReactHtmlParser(consultation?.rapport))}
            </div>
            {/* <div className="questions-container">
              <h3>Questions & Examens</h3>
              <div className="questions">
                <ul>
                  {questions.map((question) => {
                    return (
                      <li>
                        <li className="flex">
                          <div className="flexCenter questionContainer">
                            <div className="rounded-full point "></div>
                            {question}
                          </div>
                        </li>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="conclusion">
              <h3>Conclusion</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Phasellus vel pellentesque nulla. Sed lectus nisl, aliquet a
                turpis porttitor, maximus blandit nisl. Donec commodo
                scelerisque.
              </p>
            </div> */}
          </div>
          {/* <div className="flexCenter buttons">
            <Button status="secondary" className="flexCenter">
              <div className="flexCenter voir">
                <img crossOrigin = "anonymous"  referrerPolicy="origin" src={voir} alt="katomi" width="18" height="11" />{" "}
                <p className="ml-2">Voir la prescription associée</p>
              </div>
            </Button>
            <Button status="secondary" className="svg-class">
              <Icon name="telecharger" isIcon={true} />
            </Button>
            <Button status="secondary">
              <Icon name="export" isIcon={true} />
            </Button>
          </div> */}
        </>
      )}
    </div>
  );
};

export default CompteRendu;
