import { convertToRaw } from "draft-js";
import { useTranslation } from "react-i18next";
import jsPDF from "jspdf";
import { eduNsw } from "./fonts/eduNsw";

import { FONT_SIZE } from "./Helper/constants";
import { ItemKonvaToJsPDF } from "./Helper/functions";
import { userAtom } from "../../state/auth";
import { useAtom } from "jotai";
import { ItemsData } from "./interfaces/interface";
import makeToast from "../Snackbar";
import { useLocation } from "react-router-dom";
import ButtonUI from "../ButtonUI";
import Voir from "../../assets/images/voir.png";
import { updateDoctortemplate } from "../../api/pdfTemalpte";
import history from "../../history";

const SavePdfComponent = ({
  listData,
  editorState,
  printCharacters,
  sharedY,
  templateName,
  modalType,
  isPatient,
  setLinkPdf,
  linkPdf,
  AddTemplate,
  update,
}) => {
  const { t } = useTranslation() as any;

  const location = useLocation() as any;
  const [user] = useAtom(userAtom);

  const _onClickBtnPreview = async () => {
    const pdf = new jsPDF("p", "mm", "a4", true);
    pdf.addFileToVFS("EduNSWACTFoundation-Regular.ttf", eduNsw);
    pdf.addFileToVFS("Ubuntu-Regular.ttf", "normal");
    //pdf.addFileToVFS("DancingScript-VariableFont_wght.ttf", DANCING_FONT);
    /*var callAddFont = function () {
      this.addFileToVFS('DancingScript-VariableFont_wght-normal.ttf', font);
      this.addFont('DancingScript-VariableFont_wght-normal.ttf', 'DancingScript-VariableFont_wght', 'normal');
      };
      jsPDF.API.events.push(['addFonts', callAddFont])*/
    let numberPage = 0;
    pdf.setFontSize(14).setFont("arial", "normal", 500);
    const multiplier = 2.9999777779423864;
    const itemPDF = [] as any;
    const redactionItem = [] as any;

    // eslint-disable-next-line array-callback-return
    listData.map((lD: any) => {
      if (lD.type !== "redaction") {
        itemPDF.push(lD);
      } else {
        redactionItem.push(lD);
      }
    });
    // eslint-disable-next-line array-callback-return
    redactionItem.map((e: ItemsData, index) => {
      if (
        e.type === "redaction" &&
        e.spec === "add" &&
        editorState !== undefined
      ) {
        ItemKonvaToJsPDF(itemPDF, multiplier, pdf, numberPage, user, null);
        numberPage += 1;
        let x = e.x / multiplier + 5;
        let y = e.y / multiplier;
        const endX = pdf.internal.pageSize.width - x * 2;

        pdf.setDrawColor("#fff");
        pdf.setLineWidth(1);
        pdf.line(x, y - 10, x, y + 200);
        pdf.line(endX, y - 10, endX, y + 200);
        const blocks = convertToRaw(editorState.getCurrentContent()).blocks;

        // let arrOfTextData = [];
        let sumRows = 0;
        let rowLeftOnPage = 0;
        // eslint-disable-next-line array-callback-return
        blocks.map((block, index) => {
          let textWithSeparator = "";
          let addLengthToText = 0;
          const { inlineStyleRanges, text, data } = block;

          textWithSeparator += text;
          if (inlineStyleRanges !== 0) {
            // eslint-disable-next-line array-callback-return
            inlineStyleRanges.map((insr) => {
              const { offset, length, style } = insr;
              switch (style) {
                case "BOLD":
                  textWithSeparator = [
                    textWithSeparator.slice(0, offset + addLengthToText),
                    "**",
                    textWithSeparator.slice(offset + addLengthToText),
                  ].join("");

                  addLengthToText += 2;
                  textWithSeparator = [
                    textWithSeparator.slice(
                      0,
                      length + offset + addLengthToText
                    ),
                    "**",
                    textWithSeparator.slice(length + offset + addLengthToText),
                  ].join("");

                  addLengthToText += 2;

                  break;
                case "ITALIC":
                  textWithSeparator = [
                    textWithSeparator.slice(0, offset),
                    "//",
                    textWithSeparator.slice(offset),
                  ].join("");

                  addLengthToText += 2;

                  textWithSeparator = [
                    textWithSeparator.slice(
                      0,
                      length + offset + addLengthToText
                    ),
                    "//",
                    textWithSeparator.slice(length + offset + addLengthToText),
                  ].join("");
                  addLengthToText += 2;

                  break;
                default:
                  break;
              }
            });
          }

          let charsMapLength = 0;
          let position = 0;
          let isBold = false;
          let isItalic = false;

          const splitTextWithoutBoldMarks = pdf.splitTextToSize(text, endX, {
            fontSize: 13,
          });

          const textRows = splitTextWithoutBoldMarks.map((row, i) => {
            const splitRow = row.split("");
            const chars = splitRow.map((char, j) => {
              position = charsMapLength + j + i;

              let currentChar = textWithSeparator.charAt(position);

              if (currentChar === "*" || currentChar === "/") {
                const spyNextChar = textWithSeparator.charAt(position + 1);
                const spyDoubleNextChar = textWithSeparator.charAt(
                  position + 3
                );

                if (spyNextChar === "*" || currentChar === "/") {
                  // double asterix marker exist on these position's so we toggle the bold state
                  isBold =
                    spyDoubleNextChar === "*" || currentChar === "*"
                      ? !isBold
                      : isBold;
                  isItalic =
                    spyDoubleNextChar === "/" || currentChar === "/"
                      ? !isItalic
                      : isItalic;
                  currentChar =
                    isBold && isItalic
                      ? textWithSeparator.charAt(position + 4)
                      : textWithSeparator.charAt(position + 2);

                  // now we remove the markers, so loop jumps to the next real printable char
                  let removeMarks = textWithSeparator.split("");
                  spyDoubleNextChar === "/" || spyDoubleNextChar === "*"
                    ? removeMarks.splice(position, 4)
                    : removeMarks.splice(position, 2);

                  textWithSeparator = removeMarks.join("");
                }
              }

              return {
                char: currentChar,
                bold: isBold,
                italic: isItalic,
              };
            });

            charsMapLength += splitRow.length;

            return { ...chars };
          });
          const endOfPage = listData.find(
            (e) => e.type === "redaction" && e.spec === "end"
          );
          let totalRows = Math.floor(
            (endOfPage.y / 3 - (y + sumRows * 10)) / 10
          );
          rowLeftOnPage = totalRows;
          if (textRows.length <= totalRows + 1 || totalRows === -1) {
            sumRows += 1;
            rowLeftOnPage -= 1;
            if (rowLeftOnPage === -1) {
              pdf.addPage("a4");
              y = e.y / multiplier;
              sumRows = 0;
              rowLeftOnPage = totalRows - 1;

              ItemKonvaToJsPDF(
                itemPDF,
                multiplier,
                pdf,
                numberPage,
                user,
                null
              );
              numberPage += 1;
              printCharacters(
                pdf,
                textRows,
                y + sumRows * 10,
                x,
                FONT_SIZE,
                10,
                data,
                text,
                //sumRows,
                listData,
                sharedY
              );
            } else {
              printCharacters(
                pdf,
                textRows,
                y + sumRows * 10,
                x,
                FONT_SIZE,
                10,
                data,
                text,
                //sumRows,
                listData,
                sharedY
              );
            }
          } else {
            for (let i = 0; i <= textRows.length; i++) {
              sumRows += 1;
              rowLeftOnPage -= 1;
              printCharacters(
                pdf,
                textRows.slice(i, i + 1),
                y + sumRows * 10,
                x,
                FONT_SIZE,
                10,
                data,
                text,
                //sumRows,
                listData,
                sharedY
              );
              if (
                (totalRows % i === 0 || i % totalRows === 0) &&
                rowLeftOnPage === 0
              ) {
                pdf.addPage("a4");

                y = e.y / multiplier;
                sumRows = 0;
                rowLeftOnPage = totalRows - 1;
                ItemKonvaToJsPDF(
                  itemPDF,
                  multiplier,
                  pdf,
                  numberPage,
                  user,
                  null
                );
                numberPage += 1;
              }
            }
          }
        });
      } else {
        if (index === 0) {
          ItemKonvaToJsPDF(itemPDF, multiplier, pdf, numberPage, user, null);
        }
      }
      // pdf.internal.write(0, "Tw");
    });
    const pageCount = pdf.internal.pages;

    for (let i = 1; i < pageCount.length; i++) {
      pdf.setPage(i);
      pdf.setFontSize(10);
      pdf.text(
        `Page ${i} sur ${pageCount.length - 1}`,
        550 / multiplier,
        870 / multiplier
      );
    }
    let iframe = "";
    if (isPatient) {
      const urlToOpen = pdf.output("datauri");
      iframe =
        "<iframe width='100%' height='100%' src='" + urlToOpen + "'></iframe>";
      setLinkPdf(urlToOpen);

      if (location.pathname.includes("previewCompteRendu")) {
        let x = window.open() as any;
        x.document.open();
        x.document.write(iframe);
        x.document.close();
      }
    } else {
      const urlToOpen = pdf.output("datauri");
      const iframe =
        "<iframe width='100%' height='100%' src='" + urlToOpen + "'></iframe>";
      setLinkPdf(urlToOpen);
      if (!location.pathname.includes("previewCompteRendu")) {
        let x = window.open() as any;
        x.document.open();
        x.document.write(iframe);
        x.document.close();
      }
      // pdf.fromHTML(string);
      // pdf.save("pdf");
    }
  };

  return update ? (
    <div className="flexCenter">
      <button
        className="btnPDF btnSpace"
        onClick={async (e) => {
          const response = await updateDoctortemplate(
            location?.state?.selectedTemplate?._id,
            { listData: listData }
          );
          if (response) {
            makeToast("success", t("TemplatePDF.SuccessSaveTemplate"));
            history.push("/pdf");
          }
        }}
      >
        {t("TemplatePDF.EditAndSave")}
      </button>
      <ButtonUI
        status={"secondary"}
        height="47px"
        width="130px"
        className="p-2 mx-1 flex items-center gap-2"
        onClick={(e) => {
          _onClickBtnPreview();
        }}
      >
        <img
          crossOrigin="anonymous"
          referrerPolicy="origin"
          src={Voir}
          alt="voir"
          className="w-4 h-3 cursor-pointer"
        />
        {t("TemplatePDF.Preview")}
      </ButtonUI>
    </div>
  ) : (
    <div className="flex ">
      <ButtonUI
        width="130px"
        height="47px"
        status="primary"
        className="p-2  "
        onClick={(e) => {
          AddTemplate();
        }}
      >
        {t("TemplatePDF.Save")}
      </ButtonUI>
      <ButtonUI
        status={"secondary"}
        height="47px"
        width="130px"
        className="p-2 mx-1 flex items-center gap-2"
        onClick={(e) => {
          _onClickBtnPreview();
        }}
      >
        <img
          crossOrigin="anonymous"
          referrerPolicy="origin"
          src={Voir}
          alt="voir"
          className="w-4 h-3 cursor-pointer"
        />
        {t("TemplatePDF.Preview")}
      </ButtonUI>
    </div>
  );
};

export default SavePdfComponent;
