/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import _ from "lodash";

import { userAtom } from "../../state/auth";
import { getAllDoctors } from "../../api/doctor";
import AddDoctor from "./CardSpecialist/modal/AddDoctor";
import Modal from "../../components/ModalUI";
import Spinner from "../../components/Spinner";
import Button from "../../components/ButtonUI";
import Pagination from "../../components/Pagination";
import CardPatient from "./CardSpecialist";
import FilterModal from "./filterModal";

import "./styles.scss";

const Specialist = () => {
  const { t } = useTranslation();

  const [user] = useAtom(userAtom);
  const [addDoctorModal, setAddDoctorModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [specialist, setSpecialist] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit] = useState(10);
  const [search, setSearch] = useState(false);

  const [filerObject, setFilterObject] = useState<any>({
    name: "",
    pays: "",
    region: "",
    departement: "",
    commune: "",
    gouvernorat: "",
    delegation: "",
    speciality: "",
  });
  const [totalResult, setTotalResult] = useState(0);

  const _getSpecialist = async (page) => {
    try {
      if (user) {
        setLoading(true);
        const response = await getAllDoctors(page, limit, filerObject);
        setSpecialist(response?.doctors?.docs);
        setTotalResult(response?.doctors?.totalDocs);
        setTotalPage(response?.doctors?.totalPages);
        setPage(response?.doctors?.page);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      console.error("Error Fetch specialist :", error);
    }
  };

  const handleAddNewSpecialist = () => setAddDoctorModal(true);

  useEffect(() => {
    _getSpecialist(page);
  }, [page]);

  useEffect(() => {
    if (search) _getSpecialist(page);
  }, [filerObject, search]);

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  return loading ? (
    <Spinner />
  ) : (
    <>
      {filterModal && (
        <FilterModal
          openModal={filterModal}
          setOpenModal={setFilterModal}
          filerObject={filerObject}
          setFilterObject={setFilterObject}
          search={search}
          setSearch={setSearch}
        />
      )}
      <Modal
        showModal={addDoctorModal}
        closeModal={() => {
          setAddDoctorModal(false);
        }}
      >
        <AddDoctor
          closeModal={() => {
            setAddDoctorModal(false);
          }}
        />
      </Modal>

      <div className="specialist mx-2 md:mx-0">
        <header className="flex justify-between md:items-center w-full flex-col md:flex-row ">
          <div>
            <h1>{t("Specialist.title")}</h1>
            {/* <h3 className="card-subtitle hidden md:block max-w-screen-sm">
              Grâce à votre annuaire professionnel, vous pouvez inviter
              directement un confrère à rejoindre votre réseau afin d'échanger
              ou partager un dossier de patient.
            </h3> */}
          </div>
          <Button
            status="secondary"
            width="200px"
            height="50px"
            onClick={() => {
              setFilterObject({
                name: "",
                pays: "",
                region: "",
                departement: "",
                commune: "",
                gouvernorat: "",
                delegation: "",
              });
              setSearch(false);
              setFilterModal(true);
            }}
          >
            {t("Specialist.Filters")}{" "}
          </Button>
        </header>
        <div className="flex justify-between md:items-center w-full mt-5 flex-col md:flex-row ">
          <div className="flex md:space-x-3 flex-col md:items-center md:flex-row md:space-y-3 ">
            <div className="md:flexCenter gap-2 flex-wrap ">
              <div className="flex gap-2 flex-wrap mb-3">
                {filerObject &&
                  Object.values(filerObject).length > 0 &&
                  Object.values(filerObject)
                    .filter(
                      (el) => el !== null && el !== undefined && el !== ""
                    )
                    .map((el) => (
                      <span className="box mx-2 flexCenter">
                        {el}
                        <button
                          className="cros"
                          onClick={() => {
                            const key = getKeyByValue(filerObject, el) || "";
                            setFilterObject({
                              ...filerObject,
                              [key]: undefined,
                            });
                            setSearch(true);
                          }}
                        >
                          {"  X"}
                        </button>
                      </span>
                    ))}
              </div>
              <Button
                width="200px"
                height="50px"
                status="secondary"
                onClick={handleAddNewSpecialist}
              >
                {t("Specialist.NewSpecialist")}
              </Button>
            </div>
          </div>
          <div className="mt-7 md:mt-0">
            {totalResult} {t("Specialist.ResultsFound")}
          </div>
        </div>
        <div className="card-container mt-3 md:mt-10">
          {specialist &&
            !_.isEmpty(specialist) &&
            specialist.map((el, i) => (
              <CardPatient specialistDetails={el} key={el._id} i={i} />
            ))}
        </div>
        {totalResult > 10 && (
          <Pagination
            page={page}
            setPage={(i) => setPage(i)}
            totalPage={totalPage}
            totalDocs={totalResult}
          />
        )}
      </div>
    </>
  );
};

export default Specialist;
