import React, { useEffect, useState } from "react";
import { Select } from "@chakra-ui/react";
import { FormGroup } from "./FormGroup";
import { useField } from "../../../hooks/FormGeneratorHooks";

export const FieldSelect = (props) => {
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    otherProps,
  } = useField(props);
  const { required, name } = props;
  const {
    children,
    label,
    options = [],
    placeholder,
    helper,
    ...rest
  } = otherProps;
  const [isTouched, setIsTouched] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);
  useEffect(() => {
    setValue(props.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formGroupProps = {
    errorMessage,
    helper,
    id,
    isRequired: !!required,
    label,
    showError,
    name,
    ...rest,
  };

  return (
    <FormGroup {...formGroupProps}>
      <Select
        borderWidth={props.readOnly ? 2 : 1}
        multiple={props.Multiple ? true : false}
        id={id}
        fontSize="14px"
        _invalid={{ borderColor: "Red" }}
        className="border border-gray-400 focus:border-blue-500 focus:shadow-sm w-full "
        value={value || ""}
        onBlur={() => setIsTouched(true)}
        aria-invalid={showError}
        aria-describedby={!isValid ? `${id}-error` : undefined}
        placeholder={placeholder ? placeholder : " "}
        onChange={(e) => setValue(e.target.value)}
        disabled={props.readOnly ? props.readOnly : false}
        // fontSize="14px"
      >
        {(options || []).map((item) => (
          <option
            key={item.value}
            value={item.value}
            disabled={item.disabled ? true : false}
            style={{ fontSize: "14px" }}
          >
            {item.label || item.value}
          </option>
        ))}
      </Select>
      {children}
    </FormGroup>
  );
};
