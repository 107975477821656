import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import Button from "../../ButtonUI";
import { ReactComponent as Close } from "../../../assets/icons/icon-close.svg";

export interface IProps {
  openModal: boolean;
  setOpenModal: Function;
  selectedCompteRendu: any;
}

const DisplayRaport: React.FC<IProps> = ({
  openModal,
  setOpenModal,
  selectedCompteRendu,
}) => {
  const { t } = useTranslation() as any;
  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute bg-white z-40 top-1/3   addTraitementModal p-4">
        <div className="relative ">
          <div className="absolute right-0 mt-2 mr-2">
            <Button status="icon-secondary" onClick={() => setOpenModal(false)}>
              <Close />
            </Button>
          </div>
        </div>
        <div>
          <div className="flex flex-wrap items-baseline">
            <h1 className="mb-10">{t("ModalFichePatient.DoctorReport")}</h1>
          </div>
        </div>
        <div>
          {selectedCompteRendu?.rapport &&
          ReactHtmlParser(selectedCompteRendu?.rapport)[0].length > 9
            ? ReactHtmlParser(ReactHtmlParser(selectedCompteRendu.rapport))
            : t("ModalFichePatient.EmptyReport")}
        </div>
      </div>
    </div>
  );
};

export default DisplayRaport;
