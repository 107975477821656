/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import classNames from "classnames";

import moment from "../../../../helper/moment";
import Button from "../../../../components/ButtonUI";
import Icon from "../../../../components/Icon";
import ShowObservations from "../../../MyPatietns/modal/showObservations";
import AddObservation from "../../../../components/SharedPatientDetails/modal/addObservation";
import { useTranslation } from "react-i18next";

const Antecedents = ({
  patientData,
  ShareAntecedent,
  sharedAntedent,
  sharedAntedentCh,
  setSharedAntecedent,
  setSharedAntecedentCh,
  disabled,
}) => {
  const { t } = useTranslation() as any;
  const [typeObservation, setTypeObservation] = useState({
    typeObservation: "",
    idObservation: "",
  });
  const [observationModal, setObservationModal] = useState(false);
  const [showObservationsModal, setShowObservationsModal] = useState(false);
  const [selectedObservationId, setSelectedObservationId] = useState("");
  const [typeOfAddObservation, setTypeOfAddObservation] = useState("");
  const [antecedentsList, setAntecedentsList] = useState([] as any);
  const [antecedentsChrirugicalList, setAntecedentsChrirugicalList] = useState(
    [] as any
  );
  // const [sharedAntedent, setSharedAntecedent] = useState([]) as any;
  // const [sharedAntedentCh, setSharedAntecedentCh] = useState([]) as any;

  useEffect(() => {
    setAntecedentsList(patientData.antecedents);
    setAntecedentsChrirugicalList(patientData.antecedentsChirugical);
  }, []);

  const handleShareAntecedent = () => {
    ShareAntecedent(sharedAntedent, sharedAntedentCh);
  };

  return (
    <div className="share-folder-antecedent">
      <div className="flex justify-between title-taches">
        <div className="sub-title mb-2">
          <h3>{t("Specialist.MedicalAntecedents")}</h3>
        </div>
      </div>
      <div className="flex flex-wrap gap-2 max-h-96 overflow-auto ">
        {antecedentsList && antecedentsList.length > 0 ? (
          antecedentsList.map((med) => (
            <div
              className={classNames(
                "card-antecedent relative ",
                sharedAntedent?.length > 0 &&
                  sharedAntedent.includes(med._id) &&
                  "border-blue"
              )}
            >
              <div className="absolute top-2 right-2 ">
                <label className="container-checkbox ">
                  <input
                    type="checkbox"
                    checked={sharedAntedent.includes(med._id)}
                    onChange={() => {
                      sharedAntedent &&
                      sharedAntedent.length > 0 &&
                      sharedAntedent.includes(med._id)
                        ? setSharedAntecedent(
                            sharedAntedent
                              .slice()
                              .filter((elm) => elm !== med._id)
                          )
                        : setSharedAntecedent([...sharedAntedent, med._id]);
                    }}
                  />
                  <span
                    className="checkmark"
                    onClick={() => {
                      sharedAntedent &&
                      sharedAntedent.length > 0 &&
                      sharedAntedent.includes(med._id)
                        ? setSharedAntecedent(
                            sharedAntedent
                              .slice()
                              .filter((elm) => elm !== med._id)
                          )
                        : setSharedAntecedent([...sharedAntedent, med._id]);
                    }}
                  ></span>
                </label>
              </div>
              <div className="flex justify-between">
                <div>
                  <p className="card-title">{med.nature}</p>
                  <span className="block">{med.type}</span>
                </div>

                {/* <Icon name="troisPoints" isIcon={true} /> */}
              </div>
              <p className="subTitle">{med.subTitle}</p>
              <p className="card-subtitle">
                {t("Specialist.UpdateAt")}
                <span className="font-bold">
                  {moment(med.date).format(t("Moment.Z"))}
                </span>
              </p>
              <div className="flex gap-2 my-2">
                {med["traitements"] &&
                  med["traitements"].map((medic) => (
                    <div className="box max-w-max flex items-center space-x-2">
                      <Icon
                        name="capsule"
                        isIcon={false}
                        width={14}
                        height={16}
                      />
                      <p>{medic.traitement}</p>
                    </div>
                  ))}
              </div>
              {med.to ? (
                <p className="addObservation menu flexCenter">
                  {t("Chat.AddObservation")}
                </p>
              ) : (
                <div className="flexCenter">
                  <div
                    className="menu addObservation cursor-pointer blue-text"
                    onClick={() => {
                      setTypeObservation({
                        typeObservation: "antecedents",
                        idObservation: med._id,
                      });
                      setShowObservationsModal(true);
                    }}
                  >
                    {med.observationsDoctor.length}{" "}
                    {t("Specialist.observations")}
                  </div>
                  <div
                    className="menu ml-6 addObservation cursor-pointer blue-text"
                    onClick={() => {
                      setSelectedObservationId(med._id);
                      setObservationModal(true);
                      setTypeOfAddObservation("antecedents");
                    }}
                  >
                    {t("Specialist.Add")}
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="flexCenter w-full anEmptyBox">
            <p className="card-subtitle">
              {t("Specialist.NoMedicalAntecedentsFound")}
            </p>
          </div>
        )}
      </div>
      <div className="flex justify-between mt-5">
        <div className="sub-title">
          <h3>{t("Specialist.SurgicalAntecedents")}</h3>
        </div>
        {/* <div className="flex">
          <Button
            status="secondary"
            className="btnAddtache"
            width={"146px"}
            height={"35px"}
            // onClick={() =>
            //   setAddAntecendentChrirgicauxModal &&
            //   setAddAntecendentChrirgicauxModal(true)
            // }
          >
            + Ajouter
          </Button>
        </div> */}
      </div>
      <div className="flex flex-wrap gap-2 mt-3 max-h-96 overflow-auto ">
        {antecedentsChrirugicalList && antecedentsChrirugicalList.length > 0 ? (
          antecedentsChrirugicalList.map((med) => (
            <div
              className={classNames(
                "card-antecedent relative",
                sharedAntedentCh?.length > 0 &&
                  sharedAntedentCh.includes(med._id) &&
                  "border-blue"
              )}
            >
              <div className="absolute top-2 right-2 ">
                <label className="container-checkbox ">
                  <input
                    type="checkbox"
                    checked={sharedAntedentCh.includes(med._id)}
                    onChange={() => {
                      sharedAntedentCh &&
                      sharedAntedentCh.length > 0 &&
                      sharedAntedentCh.includes(med._id)
                        ? setSharedAntecedentCh(
                            sharedAntedentCh
                              .slice()
                              .filter((elm) => elm !== med._id)
                          )
                        : setSharedAntecedentCh([...sharedAntedentCh, med._id]);
                    }}
                  />
                  <span
                    className="checkmark"
                    onClick={() => {
                      sharedAntedentCh &&
                      sharedAntedentCh.length > 0 &&
                      sharedAntedentCh.includes(med._id)
                        ? setSharedAntecedentCh(
                            sharedAntedentCh
                              .slice()
                              .filter((elm) => elm !== med._id)
                          )
                        : setSharedAntecedentCh([...sharedAntedentCh, med._id]);
                    }}
                  ></span>
                </label>
              </div>
              <div className="flex justify-between">
                <div>
                  <p className="card-title">{med.nature}</p>
                  <span className="block">{med.type}</span>
                </div>

                {/* <Icon name="troisPoints" isIcon={true} /> */}
              </div>
              <p className="subTitle">{med.subTitle}</p>
              <p className="card-subtitle">
                {" "}
                {t("Specialist.UpdateAt")}
                <span className="font-bold">
                  {moment(med.date).format(t("Moment.Z"))}
                </span>
              </p>
              {med.to ? (
                <p className="addObservation menu flexCenter blue-text">
                  {t("Chat.AddObservation")}
                </p>
              ) : (
                <div className="flexCenter">
                  <div
                    className="menu addObservation cursor-pointer blue-text"
                    onClick={() => {
                      setTypeObservation({
                        typeObservation: "antecedentChirurgicaux",
                        idObservation: med._id,
                      });
                      setShowObservationsModal(true);
                    }}
                  >
                    {med.observationsDoctor.length}{" "}
                    {t("Specialist.observations")}
                  </div>
                  <div
                    className="menu ml-6 addObservation cursor-pointer blue-text"
                    onClick={() => {
                      setSelectedObservationId(med._id);
                      setObservationModal(true);
                      setTypeOfAddObservation("antecedentsChirugical");
                    }}
                  >
                    {t("Specialist.Add")}
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="flexCenter w-full anEmptyBox">
            <p className="card-subtitle">
              {t("Specialist.NoChAntecedentsFound")}
            </p>
          </div>
        )}

        <Button
          className="py-3 my-5 w-full"
          onClick={handleShareAntecedent}
          disabled={disabled}
        >
          {t("Specialist.Share")}
        </Button>
      </div>
      {showObservationsModal && (
        <ShowObservations
          setOpenModal={setShowObservationsModal}
          selectedPatient={patientData._id}
          openModal={observationModal}
          typeObservationObject={typeObservation}
          patientId={patientData._id}
        />
      )}
      {observationModal && (
        <AddObservation
          setOpenModal={setObservationModal}
          selectedPatient={patientData._id}
          selectedObservationId={selectedObservationId}
          openModal={observationModal}
          typeOfAddObservation={typeOfAddObservation}
          setAntecedentsList={setAntecedentsList}
          setAntecedentsChrirugicalList={setAntecedentsChrirugicalList}
          antecedentsList={antecedentsList}
          antecedentsChrirugicalList={antecedentsChrirugicalList}
          setTypeOfAddObservation={setTypeOfAddObservation}
        />
      )}
    </div>
  );
};

export default Antecedents;
