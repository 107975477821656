import axios from "axios";
import {
  GENERATE_TASKS_URL,
  GET_WAITING_TASKS_OF_DAY_URL,
  GET_ALL_TASKS_URL,
  GET_PUT_DELETE_TASK_URL,
  PUT_PRESCRIPTION_TASK_URL,
  GET_TASKS_DOCTOR_PATIENT_URL,
  GET_TASKS_BY_ID_URL,
  GET_UNDONE_TASK,
  GET_STATUS_TASKS,
} from "../config/api";

export const checkTaskStatus = async (consultationId) => {
  try {
    const res = await axios.get(`${GET_STATUS_TASKS(consultationId)}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getWaitingTaskDoctorOfDay = async () => {
  try {
    const res = await axios.get(`${GET_WAITING_TASKS_OF_DAY_URL}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// Generate tasks for doctor after consultations
// params id_consultation | id_patient
export const generateTasksForDoctor = async (
  consultationId,
  patientId,
  motif
) => {
  try {
    const res = await axios.post(
      `${GENERATE_TASKS_URL}`,
      {
        consultationId,
        patientId,
        motif,
      },
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllTasks = async () => {
  try {
    const res = await axios.get(`${GET_ALL_TASKS_URL}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getTaskById = async (id) => {
  try {
    const res = await axios.get(`${GET_PUT_DELETE_TASK_URL}/${id}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteTaskById = async (id) => {
  try {
    const res = await axios.get(`${GET_PUT_DELETE_TASK_URL}/${id}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateTaskById = async (id, updatedTask) => {
  try {
    const res = await axios.post(
      `${GET_PUT_DELETE_TASK_URL}/${id}`,
      updatedTask,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updatePrescriptionTaskByConsultationId = async (id) => {
  try {
    const res = await axios.put(`${PUT_PRESCRIPTION_TASK_URL}/${id}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getWaitingTasksOfPatient = async (patientId, doctorId) => {
  try {
    const res = await axios.get(
      `${GET_TASKS_DOCTOR_PATIENT_URL}${patientId}/${doctorId}`,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getTaskByIdPrescreiption = async (taskId) => {
  try {
    const res = await axios.get(`${GET_TASKS_BY_ID_URL}/${taskId}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUndoneTask = async (patientId, doctorId) => {
  try {
    const res = await axios.get(`${GET_UNDONE_TASK(patientId, doctorId)}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
