export const MyPatient = {
  fr: {
    btnFilter: "Filtre patient",
    btnAddpatient: "+ Nouveau patient",
    myPatient: "Mes patients",
    ResultsFound: "résultats trouvés",
    SuccessLoadPatient: "Chargement des patients à échouhé.",
    SuccessAddingPatient: "Patient ajouté avec succès.",
    NoConsultation: "Aucune consultation",
    ConsultationOf: "Consultation du ",
    UnverifiedINS: "INS non vérifié",
    SocialSecurityNumber: "Numéro de securité social",
    Surveys: "Questionnaires",
    Static: "Graphiques",
    Constants: "Constantes",
    Documents: "Documents",
    MedicalHistory: "Antécédents",
    Treatments: "Traitements",
    History: "Historique",
    noHistory: "Ce patient n'a aucun historique.",
    Prescription: "Prescription",
    // Données générales
    GeneralData: "Données générales",
    lastUpdate: "Dernière mise à jour le : ",
    UpdateAt: "Mise à jour le ",
    size: "Taille ",
    weight: "Poids",
    IMC: "IMC : ",
    PoidsANDHauteur: "Poids & Hauteur",
    FCANDTA: "FC & TA",
    Saturation: "Saturation",
    Temperature: "Temperature",
    //Constantes vitales
    ConstantesVitales: "Constantes vitales",
    FC: "FC",
    TA: "TA",
    Systole: "Systole",
    Diastole: "Diastole",
    //Température
    TakenAt: "Prise à :",
    TC: "T°C",
    hours: "heures",
    //TAB Document
    NoReport: "Ce patient n'a aucun compte rendu.",
    NoPrescription: "Ce patient n'a aucune prescription.",
    NoMdel: "Ce patient n'a aucun Modele 3D.",
    Report: "Compte rendu",
    Prescriptions: "Prescriptions",
    add: "+ Ajouter",
    add2: "Ajouter",
    Model3D: "Models 3D",
    Name: "Nom",
    NumberImage: "Nombre d'images",
    // Tab antecedent
    MedicalHistoryOrAllergies: "Antécédents médicaux ou allergies",
    AddObservation: "+  Ajouter une observation",

    SurgicalHistory: "Antécédents Chirurgicaux",
    Observations: "observations",
    NoMedicalHistory: "Ce patient n'a aucun ancien antécédent médical.",
    NoSurgicalHistory: "Ce patient n'a aucun ancien antécédent chirurgical.",

    //Images
    Images: "Images",
    Organic: "Biologique",
    Radiological: "Radiologique",
    Others: "Autres",
    NoDoC: "Ce patient n'a aucun document.",

    //Survey
    successAssigned: "Le questionnaire a réussi à être attribué.",
    Date: "Date",
    status: "statut",
    NoSurvey: "Ce patient n'a aucun questionnaire",
    selectSurvey: "Sélectionnez un questionnaire",
    Confirm: "Confirmer",
    pending: "en attente",
    preview: "aperçu",
    Write: "Rédiger",

    SurveyForSurvey: "Rechercher un questionnaire",
    EnterSurvey: "Saisissez un questionnaire",
    Prescribe: "Prescrire",
    Consult: "Consulter",
    NoTask: "Aucune tâche",
    End: "Terminer",
    ongoing: "En cours",
    NoCurrentTreatement: "Ce patient n'a aucun traitement en cours",
    NoPreviousTreatement: "Ce patient n'a aucun ancien traitement",
    Old: "Ancien",

    YourTasks: "Vos tâches à réaliser",
    FailLoad: "Chargement de patient a échouhé",
    PatientUpdateFailed: "Mise à jour du patient a échoué.",
    SuccessUpdate: "La mise à jour du patient a été bien enregistré",
    placeholderFindDoctor: "Médecin, spécialité, etc",
    NoDocFound: "pas de medecin trouvé",
    Caregivers: "Mes soignants",

    NoPatientFound: "Aucun patient trouvé",
    year: "ans",
    Pregnancy: "Grossesse",
    weeks: "semaines",
  },
  en: {
    year: "year",
    Pregnancy: "Pregnancy",
    weeks: "weeks",

    Caregivers: "My Caregivers",
    btnFilter: "Patient filter",
    btnAddpatient: "+ New patient",
    ResultsFound: "results found",
    myPatient: "My patients",
    UpdateAt: "Updated at ",
    SuccessLoadPatient: "Patient loading failed.",
    SuccessAddingPatient: "Patient added successfully.",
    NoConsultation: "No consultations yet",
    ConsultationOf: "Consultation of ",
    UnverifiedINS: "Unverified INS",
    SocialSecurityNumber: "Social security number",
    Surveys: "Surveys",
    Static: "graphic",
    Constants: "Constants",
    Documents: "Documents",
    MedicalHistory: "Medical history",
    Treatments: "Treatments",
    History: "History",
    noHistory: "This patient has no history",
    Prescription: "Prescription",
    // Données générales
    GeneralData: "General Data",
    lastUpdate: "Last updated on : ",
    size: "Height ",
    weight: "Weight",
    IMC: "IMC : ",
    Temperature: "Temperature",
    Saturation: "Saturation",
    FCANDTA: "FC & TA",
    PoidsANDHauteur: "weight & Height",
    //Constantes vitales
    ConstantesVitales: "Vital constants",
    FC: "FC",
    TA: "TA",
    Diastole: "Diastole",
    Systole: "Systole",
    //Température
    TC: "T°C",
    TakenAt: "Taken at :",
    hours: "hours",
    //TAB Document
    NoReport: "This patient has no report.",
    NoPrescription: "This patient has no prescription.",
    NoMdel: "This patient has no 3D Model.",
    Report: "Report",
    Prescriptions: "Prescriptions",
    add: "+ Add",
    add2: "Add",
    Model3D: "Models 3D",
    Name: "Name",
    NumberImage: "Number of frames",

    // Tab antecedent
    MedicalHistoryOrAllergies: "Medical history or allergies",
    AddObservation: "+  Add an observation",
    SurgicalHistory: "Surgical history",
    Observations: "observations",
    NoMedicalHistory: "This patient has no previous medical history.",
    NoSurgicalHistory: "This patient has no previous surgical history.",

    //Image
    Images: "Images",
    Organic: "Organic",
    Radiological: "Radiological",
    Others: "Others",
    NoDoC: "This patient has no documents.",

    //Survey
    successAssigned: "The survey was successfully assigned.",
    Date: "Date",
    status: "Status",
    NoSurvey: "This patient has no questionnaire",
    selectSurvey: "Select survey",
    Confirm: "Confirm",
    pending: "pending",
    preview: "preview",
    SurveyForSurvey: "Search for a survey",
    EnterSurvey: "Enter a survey",
    Write: "Write",
    Prescribe: "Prescribe",
    Consult: "Consult",
    NoTask: "No task",
    End: "Save",
    NoCurrentTreatement: "This patient has no current treatment",
    NoPreviousTreatement: "This patient has no previous treatment",
    Old: "Previous",

    YourTasks: "Your tasks",
    FailLoad: "Patient Load Echoed",
    PatientUpdateFailed: "Patient update failed.",
    SuccessUpdate: "Patient update has been successfully saved",
    placeholderFindDoctor: "Doctor, specialty, etc.",
    NoDocFound: "No doctor found",

    NoPatientFound: "No patients found",
    ongoing: "Ongoing",
  },
};
