import {
  DOCTOR_ADD_INDISPONIBILITE,
  DOCTOR_ADD_INDISPONIBILITY_OR_HOLIDAY,
  DOCTOR_GET_ALL_BOOKING,
  DOCTOR_CANCEL_CONSULTATION_BETWEEN_TIME_RANGE,
  CHECK_CONSULTATION_BETWEEN_TIME_RANGE,
  Delete_CONSULTATION_BETWEEN_TIME_RANGE,
  DOCTOR_RESET_AGENDA,
  DELETE_TIME_SLOT_WITH_ID,
} from "../config/api";
import axios from "axios";

export const addHoliday = async (values) => {
  try {
    const res = await axios.post(DOCTOR_ADD_INDISPONIBILITE, values, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllBooking = async () => {
  try {
    const res = await axios.get(DOCTOR_GET_ALL_BOOKING, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const AddAFKOrHoliday = async (timeStart, timeEnd) => {
  try {
    const res = await axios.post(
      DOCTOR_ADD_INDISPONIBILITY_OR_HOLIDAY,
      { timeStart, timeEnd },
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const cancelConsultationBetweenTimeRange = async (
  timeStart,
  timeEnd
) => {
  try {
    const res = await axios.post(
      DOCTOR_CANCEL_CONSULTATION_BETWEEN_TIME_RANGE,
      { timeStart, timeEnd },
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const checkIfExistConsultationBetweenTimeRange = async (
  timeStart,
  timeEnd
) => {
  try {
    const res = await axios.post(
      CHECK_CONSULTATION_BETWEEN_TIME_RANGE,
      { timeStart, timeEnd },
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const resetAgenda = async () => {
  try {
    const res = await axios.post(
      DOCTOR_RESET_AGENDA,
      {},
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const DeleteExistConsultationBetweenTimeRange = async (
  timeStart,
  timeEnd
) => {
  try {
    const res = await axios.post(
      Delete_CONSULTATION_BETWEEN_TIME_RANGE,
      { timeStart, timeEnd },
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deletetimeSlotWithId = async (timeSlotId) => {
  try {
    const res = await axios.post(
      DELETE_TIME_SLOT_WITH_ID,
      { timeSlotId },
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
