import { useState } from "react";
import { INS } from "../../../api/devbox";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Button from "../../../components/ButtonUI";
import { INSType } from "../../../helper/insHelper";
import makeToast from "../../../components/Snackbar";
import "./styles.scss";
import { useAtom } from "jotai";
import { userAtom } from "../../../state/auth";
import { cpsDataAtom } from "../../../state/cardCpsData";

const Step0 = ({
  setSteps,
  setFieldValue,
  getRegionAndDepartementFromCommune,
  setStartDate,
  setValueConfirmed,
}) => {
  const [user] = useAtom(userAtom);
  const [cpsData] = useAtom(cpsDataAtom) as any;
  const [loading, setLoading] = useState(false) as any;
  const { t } = useTranslation() as any;

  const searchWithCard = async () => {
    try {
      setLoading(true);
      // if (!cpsData) {
      //   setLoading(true);
      //   const cpsInfo = await CPS.readCard();
      //   setLoading(false);
      //   setCpsData(cpsInfo);
      // }
      const idFacturation =
        cpsData?.activitiesPs[`${cpsData?.selectedActivity}`]?.facturationInfo
          ?.idFacturation || user?.idFacturationCps;
      const responseINS = await INS.searchWithCard(idFacturation);
      if (responseINS?.compteRendu?.valeur === "00") {
        setFieldValue("lastVerified", moment());
        setFieldValue("responseINS", responseINS);
        responseINS?.traitsIdentite?.prenomActeNaissance &&
          setFieldValue(
            "firstName",
            responseINS?.traitsIdentite?.prenomActeNaissance
          );
        responseINS?.traitsIdentite?.nomFamille &&
          setFieldValue("lastName", responseINS?.traitsIdentite?.nomFamille);
        responseINS?.traitsIdentite?.nomFamille &&
          setFieldValue("NameINS", responseINS?.traitsIdentite?.nomFamille);
        responseINS?.traitsIdentite?.prenomActeNaissance &&
          setFieldValue(
            "firstNameINS",
            responseINS?.traitsIdentite?.prenomActeNaissance
          );
        responseINS?.traitsIdentite?.lieuNaissance &&
          setFieldValue(
            "birthAddressINS",
            responseINS?.traitsIdentite?.lieuNaissance
          );
        setFieldValue("country", "France");
        if (responseINS?.traitsIdentite?.lieuNaissance)
          getRegionAndDepartementFromCommune(
            responseINS?.traitsIdentite?.lieuNaissance
          );
        setFieldValue("matriculeINS", responseINS?.insActif?.valeur);
        setFieldValue(
          "identifiantSysteme",
          INSType[responseINS?.insActif?.identifiantSysteme]
        );

        setStartDate(new Date(responseINS?.traitsIdentite?.dateNaissance));
        setFieldValue(
          "sexe",
          responseINS?.traitsIdentite?.sexeAdministratif === "M"
            ? ["man"]
            : ["woman"]
        );
        setValueConfirmed(true);
        setSteps(3);
      } else if (responseINS?.compteRendu?.valeur === "01") {
        makeToast("warning", "Aucune identite trouvée");
      } else if (
        responseINS?.errorMessage ===
        "La carte du bénéficiaire sélectionné n'est plus présente (Erreur : 110)"
      ) {
        makeToast(
          "warning",
          "La carte du bénéficiaire sélectionné n'est plus présente"
        );
      } else {
        makeToast(
          "warning",
          "Quelque chose s'est mal passé. Veuillez réessayer"
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      makeToast(
        "warning",
        "Veuillez faire en sorte que DevBox soit en cours d'exécution sur votre machine, et votre carte CPS soit inserer."
      );

      console.log("🚀 ~ file: Step1.tsx:70 ~ searchWithCard ~ error", error);
    }
  };

  // const getCpsData = async () => {
  //   if (!cpsData) {
  //     setLoading(true);
  //     const cpsInfo = await CPS.readCard();
  //     setLoading(false);
  //     setCpsData(cpsInfo);
  //   }
  // };
  // useEffect(() => {
  //   getCpsData();
  // }, []);

  return loading ? (
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
  ) : (
    <>
      <div className="w-full flex gap-3">
        <Button
          status="secondary"
          className="btn-nouveau-pat relative"
          onClick={searchWithCard}
          disabled={loading}
        >
          {t("AddPatient.SearchByCard")}
          {loading && (
            <div className="absolute flexCenter inset-0">
              <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-purple-500" />
            </div>
          )}
        </Button>
        <Button
          status="primary"
          className="btn-nouveau-pat"
          onClick={() => {
            setSteps(2);
          }}
        >
          {t("AddPatient.SearchByLine")}
        </Button>
      </div>
      {process.env.REACT_APP_KATOMI_ENV !== "production" && (
        <Button
          status="primary"
          className="btn-nouveau-pat mt-10"
          onClick={() => {
            setSteps(2.1);
          }}
        >
          {t("AddPatient.TestINS")}
        </Button>
      )}
    </>
  );
};

export default Step0;
