import moment from "../../../helper/moment";
import { useTranslation } from "react-i18next";
import Icon from "../../../components/Icon";

interface Props {
  closeModal: any;
  selectedAntecedent: object | any;
}

const ObservationModal = (props: Props) => {
  const { t } = useTranslation() as any;
  const { closeModal, selectedAntecedent } = props;
  return (
    <div className="p-3">
      <div className="flex justify-end ">
        <div className="cursor-pointer rounded-xl bg-gray-200 bg-opacity-50">
          <Icon isIcon={true} name="close" onClick={closeModal} />
        </div>
      </div>
      <div className="text-center">
        <h1>{t("MyFolder.Observations")}</h1>
        <span className="card-subtitle">
          {t("MyFolder.UpdatedAt")}{" "}
          <span className="font-bold">
            {moment(selectedAntecedent.updatedAt).format("LL à HH:mm")}{" "}
          </span>
          <br />
          {t("MyFolder.ByThe")} {` `}
          <span className="font-bold">
            {t("MyFolder.Dr")} {` ${selectedAntecedent?.doctorId?.firstName}`}{" "}
            <span className="uppercase">{`${selectedAntecedent?.doctorId?.lastName}`}</span>
          </span>
        </span>
      </div>
      <div className="mt-10">
        {selectedAntecedent?.observationsDoctor.length !== 0 ? (
          selectedAntecedent?.observationsDoctor.map((el) => (
            <div>
              <hr className="text-gray-100" />
              <p className="courant-text my-5">{el.message}</p>
              <hr className="text-gray-100" />
            </div>
          ))
        ) : (
          <div className="w-full flexCenter h-64">
            {t("MyFolder.NoObservation")}
          </div>
        )}
      </div>
    </div>
  );
};

export default ObservationModal;
