/* eslint-disable react-hooks/exhaustive-deps */
import classnames from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import Button from "../../components/ButtonUI";
import Icon from "../../components/Icon";
import Modal from "../../components/ModalUI";
import Tabs from "../../components/Tabs";
import EndConsultationModal from "./Modals/EndConsultationModal";

import ChatTeleConsultation from "./ChatTeleConsultation";
import ChatTeleConsultationModal from "./Modals/ChatModal";
import NoteModal from "./Modals/NoteModal";

import { generateTasksForDoctor } from "../../api/task";
import { ReactComponent as IconMessage } from "../../assets/icons/icon-message.svg";
import Pen from "../../assets/images/pen.png";
import PatientInfo from "../../components/SharedPatientDetails";
import VideoCallContainer from "./VideoCallContainer";

import {
  editConsultationById,
  getConsultationById,
} from "../../api/consultation";
import moment from "../../helper/moment";
import { getPatientById } from "../../api/patient";
import { checkTaskStatus } from "../../api/task";

import PrescriptionModal from "../../components/PrescriptionModal";
import makeToast from "../../components/Snackbar";

import "./styles.scss";

const TeleConsultation = () => {
  const { t } = useTranslation() as any;
  const params: any = useParams();
  const history: any = useHistory();
  const { idConsultation } = params;
  const [patientData, setPatientData] = useState() as any;
  const [patientDataModal, setPatientDataModal] = useState(false);
  const [note, setNote] = useState("");
  const [, setLoading] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [tabsIndice, setTabIndice] = useState(0);
  const [endConsultationModal, setEndConsultationModal] = useState(false);
  const [prescritionModal, setPrescritionModal] = useState(false);
  const [noteModal, setNoteModal] = useState(false);
  const [chatModal, setChatModal] = useState(false);
  const [consultationDetails, setconsultationDetails] = useState<any>();
  const [statusTask, setStatusTask] = useState() as any;

  const [autoSaveAt, setAutoSaveAt] = useState(Date.now());
  const toggleFullScreen = () => setFullScreen(fullScreen ? false : true);

  const _getConsultationById = async () => {
    setLoading(true);
    try {
      const response = await getConsultationById(idConsultation);
      setLoading(false);
      setconsultationDetails(response?.consultation);
    } catch (error) {
      setLoading(false);
    }
  };

  const _getPatientData = async () => {
    setLoading(true);
    try {
      const response = await getPatientById(consultationDetails.patientId._id);
      setPatientData(response?.patient);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const generateDoctorTasks = async () => {
    if (consultationDetails) {
      const { patientId, motif } = consultationDetails;
      await generateTasksForDoctor(idConsultation, patientId, motif);
    }
  };

  const onEditorStateChange = async (e) => {
    setAutoSaveAt(Date.now());
    try {
      setNote(e.target.value);
      await editConsultationById(idConsultation, {
        doctorNote: e.target.value,
      });
    } catch (error) {}
  };

  const _checkTaskStatus = async () => {
    try {
      const response = await checkTaskStatus(idConsultation);
      setStatusTask(response);
    } catch (error) {}
  };

  useEffect(() => {
    _getConsultationById();
  }, []);

  useEffect(() => {
    if (consultationDetails) _getPatientData();

    if (consultationDetails && consultationDetails.status === "done") {
      makeToast("error", t("Teleconsultation.AutoSaved"));
      history.push("/");
    }
  }, [consultationDetails]);

  useEffect(() => {
    _checkTaskStatus();
  }, [prescritionModal, endConsultationModal]);

  return (
    <>
      {patientDataModal && (
        <PatientInfo
          setOpenModal={setPatientDataModal}
          patientData={patientData}
          setPatientData={setPatientData}
          selectedPatient={patientData._id}
          setModal={setPatientDataModal}
        />
      )}
      <Modal
        showModal={prescritionModal}
        closeModal={() => {
          setPrescritionModal(false);
          setEndConsultationModal(true);
        }}
      >
        <PrescriptionModal
          closeModal={() => {
            setPrescritionModal(false);
            setEndConsultationModal(true);
          }}
          consultationDetails={consultationDetails}
        />
      </Modal>
      <Modal
        showModal={endConsultationModal}
        closeModal={() => setEndConsultationModal(false)}
      >
        <EndConsultationModal
          closeModal={() => setEndConsultationModal(false)}
          idConsultation={idConsultation}
          statusTask={statusTask}
          openPrescriptionModal={() => setPrescritionModal(true)}
        />
      </Modal>
      <div className="block lg:hidden">
        <Modal
          showModal={noteModal}
          closeModal={() => {
            setNoteModal(false);
          }}
        >
          <NoteModal
            autoSaveAt={autoSaveAt}
            closeModal={() => {
              setNoteModal(false);
            }}
            onEditorStateChange={onEditorStateChange}
            note={note}
          />
        </Modal>
      </div>
      <div className="block lg:hidden">
        <Modal
          showModal={chatModal}
          closeModal={() => {
            setChatModal(false);
          }}
        >
          <ChatTeleConsultationModal
            chatroomId={consultationDetails?._id}
            chatWithId={consultationDetails?.patientId?._id}
          />
        </Modal>
      </div>
      <div className="teleconsultation flex align-baseline pl-2 md:pl-0 -mt-10">
        <div className={fullScreen ? "w-full " : "w-full lg:w-2/3 xxl:w-3/4 "}>
          <header className="w-full flex gap-4 lg:items-center lg:justify-between flex-col lg:flex-row ">
            <div>
              <h2>
                {`${consultationDetails?.patientId?.firstName} ${consultationDetails?.patientId?.lastName}`}
              </h2>
              <p className="px-3 py-2 max-w-max motif-border card-subtitle">
                {consultationDetails?.motif}
              </p>
            </div>
            <div className="flex items-center space-x-3">
              <Button
                className="hidden lg:block"
                status="secondary"
                width="158px"
                height="50px"
                onClick={() => {
                  setPatientDataModal(true);
                }}
              >
                {t("Teleconsultation.SeeFile")}
              </Button>
              <Icon
                className="cursor-pointer block lg:hidden"
                isIcon={true}
                name="fiche-patient"
                onClick={() => setPatientDataModal(true)}
              />

              <IconMessage
                className="cursor-pointer block lg:hidden"
                onClick={() => {
                  setChatModal(true);
                }}
              />
              <img
                crossOrigin="anonymous"
                referrerPolicy="origin"
                src={Pen}
                alt="pen"
                onClick={() => setNoteModal(true)}
                className="w-5 h-5 block lg:hidden cursor-pointer "
              />

              <Button
                status="primary"
                width="50px"
                height="50px"
                className="lg:flex lg:justify-center lg:items-center hidden   "
                onClick={toggleFullScreen}
              >
                {fullScreen ? (
                  <Icon isIcon={true} name="fleches-vs" />
                ) : (
                  <Icon
                    isIcon={true}
                    name="fleche-blanc"
                    style={{
                      filter:
                        "invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%)",
                    }}
                  />
                )}
              </Button>
            </div>
          </header>
          <div className="m-1 lg:m-5  flex-col">
            <VideoCallContainer
              idConsultation={idConsultation}
              endCall={() => {
                setEndConsultationModal(true);
                generateDoctorTasks();
              }}
            />
          </div>
        </div>
        <div
          className={classnames(
            fullScreen ? "hidden" : "hidden lg:block lg:w-1/3 xxl:w-1/4"
          )}
        >
          <div
            className={classnames(
              "tele-right-slide relative shadow",
              "bg-white rounded-2xl ml-4 pt-5 "
            )}
          >
            <Tabs
              className="m-auto"
              menu={[{ name: "Notes" }, { name: "Chat" }]}
              tabIndex={(i) => setTabIndice(i)}
            />
            {tabsIndice === 0 ? (
              <div>
                <textarea
                  placeholder={t("Teleconsultation.WriteNote")}
                  className="h-full w-full p-5"
                  value={note}
                  onChange={(e) => {
                    onEditorStateChange(e);
                  }}
                />
              </div>
            ) : (
              <ChatTeleConsultation
                chatroomId={consultationDetails?._id}
                chatWithId={consultationDetails?.patientId?._id}
              />
            )}
          </div>
          {tabsIndice === 0 ? (
            <p className="card-subtitle text-center mt-2">
              {t("Teleconsultation.AutoSaved")}{" "}
              {moment(autoSaveAt).format("hh:mm")} ✓
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default TeleConsultation;
