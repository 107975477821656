import KatomiTN from "./indexTN";
import KatomiFR from "./indexFR";

const index = (props) => {
  return process.env.REACT_APP_KATOMI_TENANT === "katomiTN" ? (
    <KatomiTN {...props} />
  ) : (
    <KatomiFR {...props} />
  );
};

export default index;
