export const SignIn = {
  fr: {
    login: "Se connecter",
    im: "Je suis",
    doctor: "un professionel de santé",
    patient: "un patient",
    email: "Email",
    psw: "Mot de passe",
    voir: "voir",
    forgetPassword: "Mot de passe oublié?",
    orPSC: "Ou se connecter avec",
    rememberMe: "Se souvenir de moi",
    haventCompte: "Vous n'avez pas de compte ?",
    createCompte: "Créer un compte",
    ErrorMsgConfirmedEmail: "Veuillez confirmer votre adresse e-mail",
    SuccessConnectedMsg: "Vous êtes maintenant connecté",
    ErrorMsgIncorrectCredential: "Votre email ou mot de passe sont incorrects.",
    compteActivated: "Votre compte a été activé avec succès",
    IncorrectCode: "Le code saisi est invalide",
    ErrorSendingCode:
      "Un problème est survenu lors de l'envoi du code de vérification",
    EmailCodeSended: "un email contenant un code de validation a été envoyé",
    CheckMail: "Vérifier votre email",
    EnterVerificationCode:
      "Saisissez le code de vérification que vous avez reçu par email",
    VerificationCode: "Code de vérification",
    Confirm: "Confirmer",
    ResendVerificationCode: "Renvoyer le code de vérification",
    ResetYourPassword: "Réinitialisez votre mot de passe",
    EnterMailToReset:
      "Afin de recevoir le lien de réinitialisation de votre mot de passe, veuillez saisir l'adresse e-mail avec laquelle votre compte a été créé.",
    EnterValidEmail: "Veuillez entrer un email valide",
    CheckYourEmail: "Veuillez Verifier votre email",
    confirmationCodeSent:
      "Un code de confirmation a étè envoyé vers votre compte",
    pswConstraint:
      "Le mot de passe doit contenir au minimum 10 caractères, à savoir : au moins une lettre minuscule et une lettre majuscule, un caractère spécial et un chiffre",
    pswConfirmedConstraint: "Les mots de passe ne correspondent pas",
    PasswordChangeRequired:
      "Afin de garantir la sécurité de votre compte, merci de mettre à jour votre mot de passe.",

    ChangeTempPasswordTitle: "Votre nouveau mot de passe",
    ChangeTempPasswordDesc:
      "Vous vous êtes connecté en utilisant un mot de passe temporaire, veuillez saisir le mot de passe que vous voulez utiliser dorénavant.",
    SuccessChangePassword:
      "Votre mot de passe a été mis à jour avec succès. Merci de vous reconnecter.",
    ErrorChangePassword:
      "Une erreur s'est produite lors de la mise à jour de votre mot de passe. Merci de réessayer ultérieurement ou contacter le support.",
    SuccessReset: "Votre mot de passe a été réinitialisée",
    ErrorSendEmail: "Un problème est survenu lors d'envoie de l'email",
    YourVerificationCode: "Votre code de vérification",
    NewPSW: "Votre nouveau mot de passe",
    ConfirmNewPSW: "Confimer votre nouveau mot de passe",
    YourMail: "Votre email",
    loginPSC: "Login avec Pro Santé Connect ",
  },
  en: {
    loginPSC: "Login with Pro Health Connect",

    login: "Login",
    im: "I'm",
    doctor: "a doctor",
    patient: "a patient",
    email: "Email",
    psw: "Password",
    voir: "see",
    forgetPassword: "Forgot your password ?",
    orPSC: "Or sign in using:",
    rememberMe: "Remember me",
    haventCompte: "You do not have an account ?",
    createCompte: "Create an account",
    ErrorMsgConfirmedEmail: "Please confirm your email address",
    SuccessConnectedMsg: "You are now connected",
    ErrorMsgIncorrectCredential: "Your email or password are incorrect.",
    compteActivated: "Your account has been successfully activated",
    IncorrectCode: "Le code saisi est invalide",
    ErrorSendingCode: "There was a problem sending the verification code",
    EmailCodeSended: "an email containing a validation code has been sent",
    CheckMail: "Check your email",
    EnterVerificationCode: "Enter the verification code you received by email",
    VerificationCode: "Verification code",
    Confirm: "Confirm",
    ResendVerificationCode: "Resend verification code",
    ResetYourPassword: "Reset your password",
    EnterMailToReset:
      "To receive your password reset link, please enter the email address with which your account was created",
    EnterValidEmail: "Please enter a valid email",
    CheckYourEmail: "Please check your email",
    confirmationCodeSent: "A confirmation code has been sent to your account",
    pswConstraint:
      "The password must contain at least 10 characters, namely: at least one lowercase letter and one uppercase letter, one special character and one number",
    pswConfirmedConstraint: "Passwords do not match",
    PasswordChangeRequired:
      "To ensure your account's security, please update your password.",
    ChangeTempPasswordTitle: "Your new password",
    ChangeTempPasswordDesc:
      "You have logged in using a temporary password, please choose the password you want to use to login from now on.",
    SuccessChangePassword:
      "Your password has been updated successfully. Please login again.",
    ErrorChangePassword:
      "An error occured while updating your password, please try again later or contact the support.",
    SuccessReset: "Your password has been reset",
    ErrorSendEmail: "A problem occurred while sending the email",
    YourVerificationCode: "Your verification code",
    NewPSW: "Your new password",
    ConfirmNewPSW: "Confirm your new password",
    YourMail: "Your email",
  },
};
