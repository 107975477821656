/* eslint-disable array-callback-return */
import imageToBase64 from "image-to-base64/browser";
import { useAtom } from "jotai";
import _ from "lodash";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { DMP } from "../../../api/devbox";
import { loadImage } from "../../../api/documents";
import pen from "../../../assets/images/pen.png";
import { ReactComponent as SendFile } from "../../../assets/sendFile.svg";
import moment from "../../../helper/moment";
import { userAtom } from "../../../state/auth";
import Icon from "../../Icon";
// import { ReactComponent as Tick } from "../../../assets/emoji/tck.svg";
import { updateDocument } from "../../../api/documents";
import { getWaitingTasksOfPatient } from "../../../api/task";
import Modal from "../../Modal";
import makeToast from "../../Snackbar";
// import AddNewDMPFileModal from "./Modal/AddNewDMP";
import OptionSendDMP from "./Modal/OptionSendDMP";

// import base64 from "base-64";
// import utf8 from "utf8";
import { ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { updateConsultationDMP } from "../../../api/consultation";
import DEFAULT_TEMPALTE from "../../PreviewRapport/defaultTemplate.json";
import { generatePDFBase64 } from "../../TemplatePDFCreator/Helper/generatePDFbase64";
import { getTemplateById } from "../../../api/pdfTemalpte";
const PatientDocumentModal = ({
  setPatientData,
  setPatientDocModal,
  patientData,
  author,
  patientDMPData,
  setRefreshDMP,
  refreshDMP,
}) => {
  const [user] = useAtom(userAtom);
  const [loading, setLoading] = React.useState(false);
  const [refreshPatient, setRefreshPatient] = React.useState(false);
  // const [addNewDMPModal, setAddNewDMPModal] = React.useState(false);
  const [openModalOptionSend, setOpenModalOptionSend] = React.useState(false);

  const [documentsList, setDocumentsList] = React.useState([]) as any;

  const [prescriptions, setPresecriptionList] = React.useState([]) as any;

  const [parsedImages, setParsedImages] = React.useState([]) as any;

  const [defaultTemplate] = React.useState({ ...DEFAULT_TEMPALTE }) as any;

  const [selectedFile, setSelectedFile] = React.useState(null) as any;

  const AddNewDMPFile = async (content, format) => {
    try {
      setLoading(true);
      const patient = {
        internalId: patientData?._id,
        prenom:
          patientData?.INS?.responseINS.traitsIdentite?.prenomActeNaissance,
        matriculeINS: patientDMPData?.matriculeINS,
        nomUsuel: patientData?.INS?.responseINS.traitsIdentite?.nomFamille,
        dateDeNaissance:
          patientData?.INS?.responseINS.traitsIdentite?.dateNaissance
            .split("-")
            .join(""),
        ordreDeNaissance: 0,
        sexe: patientData?.INS?.responseINS.traitsIdentite?.sexeAdministratif,
      };
      const contentCDA = await DMP.dmp2cda(author, content, patient, format);
      const requestAddNewDMPFile = {
        context: {
          author,
          modeAcces: {
            acces: "NORMAL",
          },
          confidentialityCode: {
            valeur: "INVISIBLE_REPRESENTANTS_LEGAUX",
            identifiantNomenclature: "1.2.250.1.213.1.1.4.13",
            libelle: "Non visible par les représentants légaux du patient",
          },
        },
        request: {
          submission: {
            title: "Katomi/Kore/1.4/Hamdi Rahal",
            contentType: "04",
            patient,
            documents: [
              {
                auteurs: [author],
                dmpcType: "wrapped",
                classCode: "11",
                comments: "Document Wrapped soumis via katomi",
                confidentialities: ["N", "INVISIBLE_REPRESENTANTS_LEGAUX"],
                isCdaR2N3: true,
                format,
                content: contentCDA,
              },
            ],
          },
        },
      };
      const response = await DMP.td21SubmitDocuments(requestAddNewDMPFile);
      setRefreshPatient(!refreshPatient);
      setRefreshDMP(!refreshDMP);
      setLoading(false);
      makeToast(
        "success",
        `L'ajout de dossier patient a été effectué avec succès.`
      );
      return response;
    } catch (error) {
      makeToast("error", `L'ajout de dossier patient a echoué.`);
      setLoading(false);
    }
  };

  // const CardRapport = ({ itemId, rdv }) =>
  //   rdv?.rapport?.textRapport ? (
  //     <div
  //       className="tache my-1 cursor-pointer mx-3 border"
  //       onClick={() => {}}
  //       key={rdv?._id}
  //     >
  //       <div className="flex justify-between items-center header">
  //         <div className="flex sub-header items-center gap-2">
  //           <img crossOrigin = "anonymous"  referrerPolicy="origin" src={pen} alt="pen" width="20px" height="20px" />
  //           <p>
  //             Consultation du : <br />
  //             <span className="text-primary-blue">
  //               {moment(rdv.dateConsultation).format("LL")}
  //             </span>
  //           </p>
  //         </div>
  //       </div>
  //       <p className="subTitle-doc">
  //         Mise à jour le {moment(rdv.updatedAt).format("DD MMMM YYYY à HH:mm")}
  //       </p>
  //       <div className="tache-box max-w-max break-all">{rdv.motif}</div>
  //     </div>
  //   ) : (
  //     <></>
  //   );

  const CardPrescription = ({ itemId, consultation, index }) => {
    return (
      <div className="my-1 cursor-pointer mx-3 relative  bg-white p-3 rounded-xl w-64">
        <div className="flex justify-between items-center header ">
          <div className="flex sub-header">
            <div className="flex items-center gap-2">
              <Icon name={"capsule"} isIcon={false} height={20} width={20} />
              <p>
                <span className="font-bold">Consultation du :</span> <br />
                <span className="text-primary-blue">
                  {moment(consultation.dateConsultation).format("LL")}
                </span>
              </p>
            </div>
          </div>
        </div>
        <p className="text-xs text-gray-500">
          Mise à jour le{" "}
          {moment(consultation.updatedAt).format("DD MMMM YYYY à HH:mm")}
        </p>
        {/* <div className="flex justify-between">
          <span className="text-sm px-2 py-1 rounded bg-gray-200 mt-2 block max-w-max">
            {consultation.motif}
          </span>
          <span
            className="font-bold text-xl rotate-90 transform-gpu"
            onClick={() => setOpenModalOptionSend(true)}
          >
            . . .
          </span>
        </div> */}
        {loading ? (
          <div className="absolute right-3 top-3">
            <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-purple-500"></div>
          </div>
        ) : true ||
          _.isEmpty(
            consultation?.consultation?.prescriptions?.find(
              (e) => e._id === consultation.id
            )?.dmp
          ) ? (
          <SendFile
            className="absolute right-3 top-3"
            onClick={async () => {
              let selectedTemplate;
              const findPrescreption =
                consultation?.consultation?.prescriptions?.find(
                  (e) => e._id === consultation.id
                );

              if (findPrescreption?.templatePdfId) {
                const response = await getTemplateById(
                  findPrescreption?.templatePdfId
                );
                selectedTemplate = { ...response.data };
              }

              let collectedPrecriptionData = "";
              let contentState = null;
              findPrescreption?.prescriptions?.map((e) => {
                collectedPrecriptionData += `${e.medicament} ${e.dosage} <br>`;
              });

              if (collectedPrecriptionData) {
                const contentBlock = htmlToDraft(collectedPrecriptionData);
                if (contentBlock) {
                  contentState = ContentState.createFromBlockArray(
                    contentBlock.contentBlocks
                  );
                }
              }

              const pdfb64 = (await generatePDFBase64(
                selectedTemplate?.listData || defaultTemplate?.listData,
                EditorState.createWithContent(contentState),
                consultation?.consultation,
                true,
                user
              )) as any;
              setSelectedFile({
                content: pdfb64?.replace(
                  /^data:application\/(pdf);base64,/,
                  ""
                ),
                format: "urn:ihe:iti:xds-sd:pdf:2008",
                patientData,
                patientDMPData,
                author,
              });

              setOpenModalOptionSend((x) => !x);
            }}
            // onClick={async () => {
            //   let selectedTemplate;
            //   const findPrescreption =
            //     consultation?.consultation?.prescriptions?.find(
            //       (e) => e._id === consultation.id
            //     );

            //   if (findPrescreption?.templatePdfId) {
            //     const response = await getTemplateById(
            //       findPrescreption?.templatePdfId
            //     );
            //     selectedTemplate = { ...response.data };
            //   }

            //   let collectedPrecriptionData = "";
            //   let contentState = null;
            //   findPrescreption?.prescriptions?.map((e) => {
            //     collectedPrecriptionData += `${e.medicament} ${e.dosage} <br>`;
            //   });

            //   if (collectedPrecriptionData) {
            //     const contentBlock = htmlToDraft(collectedPrecriptionData);
            //     if (contentBlock) {
            //       contentState = ContentState.createFromBlockArray(
            //         contentBlock.contentBlocks
            //       );
            //     }
            //   }

            //   const pdfb64 = (await generatePDFBase64(
            //     selectedTemplate?.listData || defaultTemplate?.listData,
            //     EditorState.createWithContent(contentState),
            //     consultation?.consultation,
            //     true,
            //     user
            //   )) as any;
            //   const responseDMpFile = await AddNewDMPFile(
            //     pdfb64?.replace(/^data:application\/(pdf);base64,/, ""),
            //     "urn:ihe:iti:xds-sd:pdf:2008"
            //   );

            //   await updatePrescription({
            //     consultationId: consultation?.consultation?._id,
            //     dmp: responseDMpFile,
            //     prescriptionId: consultation.id,
            //   });
            // }}
          />
        ) : (
          <span className="absolute right-3 top-3 text-green-500">✔</span>
        )}
      </div>
    );
  };

  /** Prescription */
  React.useEffect(() => {
    if (patientData.tasks) {
      const newArrayPrescription = [] as any;
      patientData.tasks.map((task) => {
        if (
          task?.consultation?.prescriptions &&
          task?.consultation?.prescriptions.length !== 0 &&
          task?.status === "done" &&
          task?.type === "prescription"
        ) {
          task?.consultation?.prescriptions?.map((prescription) => {
            newArrayPrescription.push({ ...task, id: prescription._id });
          });
        }
      });

      setPresecriptionList(newArrayPrescription);
    }
  }, [patientData]);

  /** Tasks */
  React.useEffect(() => {
    async function fetchMyAPI() {
      let tasks = null;
      let response = await getWaitingTasksOfPatient(
        patientData?._id,
        user?._id
      );

      if (response?.patient?.documents)
        setDocumentsList(response?.patient?.documents);
      if (response?.tasks) tasks = response.tasks;
      if (response?.status !== "Success") {
        makeToast("warning", "Chargement de patient a échouhé.");
      }
      setPatientData({ ...patientData, tasks });
    }
    if (patientData) {
      fetchMyAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPatient]);

  React.useEffect(() => {
    if (patientData) {
      const imgs = [] as any;
      documentsList.map((img) => {
        imgs.push(
          loadImage(patientData?._id, img.type, "thumbnail", img.filePath)
        );
      });
      setParsedImages(imgs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsList]);

  return (
    <div>
      {/* <Modal
        showModal={addNewDMPModal}
        closeModal={() => setAddNewDMPModal(false)}
      >
        <AddNewDMPFileModal />
      </Modal> */}
      <Modal
        showModal={openModalOptionSend}
        closeModal={() => setOpenModalOptionSend(false)}
      >
        <OptionSendDMP
          selectedFile={selectedFile}
          closeModal={() => setOpenModalOptionSend(false)}
          setRefreshPatient={setRefreshPatient}
          setRefreshDMP={setRefreshDMP}
        />
      </Modal>
      <div className="flex items-center mb-3 cursor-pointer">
        <Icon
          name="direction-gauche"
          width={50}
          height={50}
          isIcon={true}
          onClick={() => setPatientDocModal(false)}
        />
        {/* <h3 className="m-auto ">Partage DMP</h3> */}
      </div>
      {!_.isEmpty(prescriptions) && (
        <>
          <h3>Prescription</h3>
          <div className="flex flex-wrap items-center ">
            {prescriptions
              ?.sort(
                (a, b) =>
                  +new Date(b.dateConsultation) - +new Date(a.dateConsultation)
              )
              .filter(
                (cslt) =>
                  cslt?.prescriptions?.length !== 0 && cslt?.status === "done"
              )
              .map((consultation, i) => (
                <CardPrescription
                  itemId={consultation?._id} // NOTE: itemId is required for track items
                  key={consultation?._id}
                  consultation={consultation}
                  index={i}
                />
              ))}
          </div>
        </>
      )}

      {!_.isEmpty(parsedImages) && (
        <>
          <h3>Images</h3>
          <div className="flex gap-2">
            {parsedImages.map((uri, i) => (
              <div className="relative max-w-max">
                <img
                  crossOrigin="anonymous"
                  referrerPolicy="origin"
                  src={uri}
                  width="150px"
                  height="150px"
                  onClick={() => {}}
                  alt="document"
                />
                {loading ? (
                  <div className="absolute right-3 top-3">
                    <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-purple-500"></div>
                  </div>
                ) : !documentsList[i]?.dmp ? (
                  <SendFile
                    className="absolute top-1 right-1 cursor-pointer"
                    onClick={async () => {
                      try {
                        const response = await imageToBase64(uri); // Path to the image
                        const responseDMpFile = await AddNewDMPFile(
                          response,
                          "urn:ihe:iti-fr:xds-sd:jpeg:2010"
                        );
                        await updateDocument({
                          documentId: documentsList[i]?._id,
                          dmp: responseDMpFile,
                        });
                      } catch (error) {
                        console.log("[DEBUG ERROR ]", error);
                      }
                    }}
                  />
                ) : (
                  <span className="absolute right-3 top-3 text-green-500">
                    ✔
                  </span>
                )}
              </div>
            ))}
          </div>
        </>
      )}

      {/** Compte rendu */}
      {!_.isEmpty(patientData?.consultations?.filter((csl) => csl.rapport)) && (
        <>
          <h3>Consultation</h3>
          <div className="flex flex-wrap items-center ">
            {patientData?.consultations
              ?.sort(
                (a, b) =>
                  +new Date(b.dateConsultation) - +new Date(a.dateConsultation)
              )
              .map((rdv, i) => {
                return (
                  rdv?.rapport?.textRapport && (
                    <div className="my-1 cursor-pointer mx-3 relative w-64  bg-white p-3 rounded-xl">
                      {loading ? (
                        <div className="absolute right-3 top-3">
                          <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-purple-500"></div>
                        </div>
                      ) : !rdv?.rapport?.dmp ? (
                        <SendFile
                          className="absolute top-3 right-3 cursor-pointer"
                          onClick={async () => {
                            const contentBlock = htmlToDraft(
                              ReactHtmlParser(
                                String(rdv?.rapport?.textRapport)
                              )[0]
                            );

                            const contentState =
                              ContentState.createFromBlockArray(
                                contentBlock.contentBlocks
                              );

                            const pdfb64 = (await generatePDFBase64(
                              rdv?.rapport?.templatePdfId?.listData ||
                                defaultTemplate?.listData,
                              EditorState.createWithContent(contentState),
                              rdv,
                              false,
                              user
                            )) as any;
                            const responseDMpFile = await AddNewDMPFile(
                              pdfb64?.replace(
                                /^data:application\/(pdf);base64,/,
                                ""
                              ),
                              "urn:ihe:iti:xds-sd:pdf:2008"
                            );

                            await updateConsultationDMP({
                              consultationId: rdv?._id,
                              dmp: responseDMpFile,
                            });
                          }}
                        />
                      ) : (
                        <span className="absolute right-3 top-3 text-green-500">
                          ✔
                        </span>
                      )}
                      <div className="flex justify-between items-center header">
                        <div className="flex sub-header items-center gap-2">
                          <img
                            crossOrigin="anonymous"
                            referrerPolicy="origin"
                            src={pen}
                            alt="pen"
                            width="20px"
                            height="20px"
                          />
                          <p>
                            <span className="font-bold">Consultation du :</span>{" "}
                            <br />
                            <span className="text-primary-blue">
                              {moment(rdv.dateConsultation).format("LL")}
                            </span>
                          </p>
                        </div>
                        <div></div>
                      </div>

                      <p className="text-xs text-gray-500">
                        Mise à jour le{" "}
                        {moment(rdv.updatedAt).format("DD MMMM YYYY à HH:mm")}
                      </p>
                      <span className="text-sm px-2 py-1 rounded bg-gray-200 mt-2 block max-w-max">
                        {rdv.motif}
                      </span>
                    </div>
                  )
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

export default PatientDocumentModal;
