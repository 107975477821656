export const Profile = {
  fr: {
    successUpdatePicture:
      "La mise à jour de votre photo de profil a été effectuée avec succès",
    ConfirmDeletePicture:
      "Voulez-vous vraiment supprimer votre photo de profile ?",
    setPaymentAccount:
      "Vous devez configurer votre compte de paiement pour naviguer dans la plateforme",
    ConfigParameter:
      "Merci de bien vouloir ajouter vos paramètres de consultation",
    Dr: "Dr",
    SpokenLanguages: "LANGUES PARLÉES",
    Add: "+ Ajouter",
    Update: "Modifier",
    confirm: "Valider",
    Cancel: "Annuler",
    Delete: "Supprimer",
    Save: "Enregistrer",
    Next: "Suivant",
    Subscription: "Abonnement",
    LevelInProgress: "Palier en cours :",
    patientNumber: "Nombre de patient enregistré :",
    patientNumberByMonth: "Nombre de patient mensuel :",
    lvlPrice: "Tarif de palier :",
    TND: "TND",
    perMonth: "/mois",
    //AddCursus
    CURSUS: "CURSUS",
    fillAll: "Tous les champs sont obligatoires",
    successAddCursus: "Cursus universitaire ajouté avec succès.",
    failAddCursus: "Ajout cursus universitaire a échouhé.",
    universityCurriculum: "Cursus universitaire",
    DegreeName: "Nom du diplôme",
    UniversityName: "Nom de l'université",
    AcademicYear: "Année universitaire",
    NoCurriculum: "Vous n'avez pas encore ajouté votre cursus universitaire",
    //AddExpertise
    EXPERTISES: "EXPERTISES",
    fillExpertise: "Veuillez saisir votre expertise",
    successAddExpertise: "Expertise ajoutée avec succès.",
    failedAddExpertise: "L'ajout de l'expertise a échouhé.",
    AddExpertise: "Ajouter un expertise",
    WriteURExpertise: "Ecrivez votre expertise ...",
    SuccessDeleteExpertise: "Expertise est supprimée avec succès.",
    FailDeleteExpertise: "Suppression d'expertise a échouhé.",

    //Add Language
    SuccessAddLanguage: "Langues modifiée avec succès.",
    FailedAddLanguage: "Ajout langues a échouhé.",
    AddLanguage: " Ajouter une langue",
    //Add Presentation
    SuccessAddPresentation: "Présentation ajoutée avec succès.",
    FailedAddPresentation: "L'ajout de présentation a échouhé.",
    Presentation: "Présentation",
    WriteDescription: "Ecrivez votre description ...",
    NoPresentation: "Vous n'avez pas encore ajoutée votre présentation.",
    //Add Consultation Location // Address
    FillAllField: "Veuillez remplir tous les champs",
    SuccessAddConsultation:
      "La mise à jour de vos consultations a été effectuée avec succès",
    FailedUpdateConsultatio: "La mise à jour de vos consultations  a échouhé.",
    Consultations: "Consultations",
    MyConsultations: "Mes sites de consultations",
    StripeConfig: "Configuration Stripe",
    ConsultationName: "Nom",
    ConsultationAddress: "Adresse",
    ConsultationCity: "Ville",
    ConsultationPostalCode: "code postal",
    ConsultationPhone: "téléphone",
    //Edit Data
    SuccessChange: "Votre changement a été sauvegardé avec succès",
    name: "Prénom",
    lastName: "Nom de famille",
    //Card Pro

    SuccessAddCardPro: "carte professionnelle ajoutée avec succès.",
    uploadBusinessCard:
      "Glissez ou déposez votre carte professionnelle ou cliquez ici.",
    ConfirmDeleteProCard:
      "Voulez-vous vraiment supprimer votre carte professionnelle ?",
    CardPro: "Carte pro",
    CardProfessional: "Carte professionnelle",

    //Edit signature
    Signature: "Signature",
    uploadSignature: "Glissez ou déposez votre signature ou cliquez ici.",
    SuccessAddSignature: "Signature ajoutée avec succès.",
    ConfirmDeleteSignature: " Voulez-vous vraiment supprimer votre signature ?",
    //Edit site
    SITESAndNETWORKS: "SITES & RÉSEAUX",
    SuccessUpdate: "Réseaux sociaux modifiés avec succès.",
    FailedUpdate: "Réseaux sociaux a échouhé.",
    updateSocialNetwork: "Modifier vos réseaux sociaux",
    //Edit speciality
    updateSpeciality: "Modifiez votre spécialité",
    SuccessUpdateSpeciality: "Spécialité modifiée avec succès",
    // TARIFS & PAIEMENTS
    PRICESANDPAYMENTS: "Tarifs de consultations",
    SuccessModificationPrice: "Tarifs modifiés avec succès.",
    FailedModificationPrice: "Modification tarifs a échouhé.",
    EditAddPrice: "Modifier/Ajouter vos tarifs",
    Currency: "Devise",
    Stripe: "Create my Stripe Express account",
    NormalConsultation: "Consultation normal",
    NightConsultation: "Consultation pendant la nuit",
    NormalConsultationPrice: "Prix consultation normal",
    weekendConsultation: "Consultation pendant le weekend et jours fériés",
    NightConsultationPrice: "Prix consultation du nuit",
    WeekEndConsultationPrice: "Prix consultation pendant le weekEnd",
    teleConsultationPrice: "Prix téléconsultation",
    OtherConsultationType: "Autre type de consultation",
    ConsultationType: "Type de consultation",
    ConsultationPrice: "Prix consultation",
    uploadXML: "Glisser ou déposez votre fichier xml ou cliquez ici.",

    //LOCATION
    Name: "Nom",
    Address: "Adresse",
    City: "Ville",
    Phone: "Téléphone",
    Type: "Type",
    Color: "Couleur",

    Office: "Cabinet",
    Clinical: "Clinique",
    Hospital: "Hôpital",

    AddConsultationSite: "Ajouter un site",

    StripeConfigMessage:
      "Afin de vous permettre une rémunération sur vos téléconsultations, il est nécessaire que vous configuriez votre compte Stripe qui servira à traiter ces paiements.",

    Configure: "Configurer",
    Country: "Pays",
    //LandingPage
    landingPage: "Consulter ma page publique",
    copied: "Lien copié",
    copyLink: "Copier le lien ",
    consultationSite: "sites de consultation",
    teleConsultation: "Téléconsultation",
    idFacturationCps: "identifiant facturation CPS",
    psc: "Pro Santé Connect",
    connectPsc: "Connecter mon compte Katomi à Pro Santé Connect",
    connectedPsc: "Votre compte Katomi est bien relié à Pro Santé Connect. ID",
  },
  en: {
    connectPsc: "Connect my account Katomi to Pro Health Connect",
    connectedPsc: "My account Katomi connected to Pro Health Connect. ID",
    psc: "Pro Health Connect",
    idFacturationCps: "Id Billing CPS",
    consultationSite: "consultation sites",
    teleConsultationPrice: "Teleconsultation price",
    teleConsultation: "Teleconsultation",
    Country: "Country",
    successUpdatePicture: "Your profile photo has been updated successfully",
    ConfirmDeletePicture: "Do you really want to delete your profile photo",
    setPaymentAccount:
      "You need to set up your payment account to navigate the platform",
    ConfigParameter: "Please add your consultation parameters",
    Delete: "Delete",
    SpokenLanguages: "SPOKEN LANGUAGES",
    Add: "+ Add",
    Update: "Update",
    confirm: "Confirm",
    Cancel: "Cancel",
    Save: "Save",
    Dr: "Dr",
    LevelInProgress: "Level in progress :",
    patientNumber: "Number of patients registered :",
    patientNumberByMonth: "Monthly patient count :",
    lvlPrice: "Level price :",
    Subscription: "Subscription",
    TND: "TND",
    perMonth: "/moonth",
    CURSUS: "CURRICULUM",

    //AddCursus
    fillAll: "Please fill in all required fields",
    successAddCursus: "University curriculum successfully added.",
    failAddCursus: "Addition university curriculum failed.",
    universityCurriculum: "University curriculum",
    DegreeName: "Degree Name",
    UniversityName: "University Name",
    AcademicYear: "Academic Year",
    NoCurriculum: "You have not yet added your university curriculum",
    //AddExpertise
    fillExpertise: "Please enter your expertise",
    successAddExpertise: "Expertise successfully added.",
    failedAddExpertise: "The addition of expertise failed.",
    AddExpertise: "Add expertise",
    WriteURExpertise: "Write your expertise ...",
    SuccessDeleteExpertise: "Expertise successfully deleted.",
    FailDeleteExpertise: "Delete expertise failed.",
    EXPERTISES: "EXPERTISES",
    //Add Language
    SuccessAddLanguage: "Languages modified successfully.",
    FailedAddLanguage: "Adding languages has failed.",
    AddLanguage: "Add language",
    //Add Presentation
    SuccessAddPresentation: "Presentation successfully added.",
    FailedAddPresentation: "The addition of presentation failed",
    Presentation: "Presentation",
    WriteDescription: "Write your description ...",
    NoPresentation: "You haven't added your presentation yet.",
    //Add Consultation Location // Address
    FillAllField: "Please fill in all fields",
    SuccessAddConsultation: "Your consultations have been successfully updated",
    FailedUpdateConsultatio: "The update of your consultations has failed.",
    Consultations: "Consultations",
    MyConsultations: "Consultations sites",
    StripeConfig: "Stripe configuration",
    ConsultationName: "Name",
    ConsultationAddress: "Address",
    ConsultationCity: "City",
    ConsultationPostalCode: "Postal code",
    ConsultationPhone: "Phone",
    //Edit Data
    SuccessChange: "Your change has been saved successfully",
    name: "FirstName",
    lastName: "lastName",
    // CardPro
    SuccessAddCardPro: "Business card successfully added.",
    uploadBusinessCard: "Drag or drop your business card or click here.",
    uploadXML: "Drag or drop your xml file or click here.",
    ConfirmDeleteProCard: "Do you really want to delete your professional card",
    CardPro: "Pro card",
    CardProfessional: "Professional card",

    //Edit signature
    Signature: "Signature",
    uploadSignature: "Drag or drop your signature or click here.",
    SuccessAddSignature: "Signature added successfully.",
    ConfirmDeleteSignature: "Do you really want to delete your signature",

    //Edit site

    SITESAndNETWORKS: "SITES & NETWORKS",
    SuccessUpdate: "Social networks successfully modified.",
    FailedUpdate: "Update Social Media has failed.",
    updateSocialNetwork: "Change your social networks",
    //Edit updateSpeciality
    updateSpeciality: "Change your specialty",
    SuccessUpdated: "Specialty updated successfully",
    // TARIFS & PAIEMENTS
    PRICESANDPAYMENTS: "Prices for consultations",
    SuccessModificationPrice: "price updated successfully.",
    FailedModificationPrice: "Update price failed",
    EditAddPrice: "Edit/Add your prices",
    Currency: "Currency",
    NormalConsultation: "Normal consultation",
    NightConsultation: "Night consultation",
    NormalConsultationPrice: "Normal consultation price",
    weekendConsultation: "Consultation during weekends and public holidays",
    Stripe: "Créer mon compte Stripe Express",

    NightConsultationPrice: "Night consultation price",
    WeekEndConsultationPrice: "WeekEnd consultation price",
    OtherConsultationType: "Other consultation type",
    ConsultationType: "Consultation type",
    ConsultationPrice: "Consultation price",
    //LOCATION
    Name: "Name",
    Address: "Address",
    City: "City",
    Phone: "Phone",
    Type: "Type",
    Color: "Color",

    Office: "Office",
    Clinical: "Clinic",
    Hospital: "Hospital",

    AddConsultationSite: "Add new site",

    StripeConfigMessage:
      "In order to be able to receive payment for your teleconsultations, it is necessary for you to set up your Stripe account, which will be used to process these payments.",
    Configure: "Configure",
    //LANDINGPAGE
    landingPage: "Visit my public page",
    copied: "Copied",
    copyLink: "Copy link",
  },
};
