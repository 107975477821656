import { useState } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import TimePicker from "react-time-picker";
import SelectComp from "../../../Select";
import fr from "date-fns/locale/fr";
import Input from "../../../Input";
import {
  dropdownClasseOptions,
  dropdownQueryOptions,
  dropdownStatusOptions,
} from "../helper";
import Button from "../../../ButtonUI";
import moment from "../../../../helper/moment";
import _ from "lodash";
registerLocale("fr", fr);
setDefaultLocale("fr");

type Props = {
  handleFilter: any;
  closeModal: any;
  nomenclatures: any;
};

const FindOption = (props: Props) => {
  // const [secretConnection, setSecretConnection] = useState(false);
  const [statuses, setStatuses] = useState<string>("APPROVED");
  const [query, setQuery] = useState("FindDocuments");
  const [classCode, setClassCode] = useState("");
  const [typeCode, setTypeCode] = useState("");
  const [submissionTimeFromDate, setsubmissionTimeFromDate] = useState("");
  const [submissionTimeFromTime, setsubmissionTimeFromTime] = useState("");

  const [submissionTimeToDate, setsubmissionTimeToDate] = useState("");
  const [submissionTimeToTime, setsubmissionTimeToTime] = useState("");

  const [author, setAuthor] = useState(false);

  const [confidentialityCode, setConfidentialityCode] = useState<string[]>([]);

  // const [practiceSettingCode, setPracticeSettingCode] = useState<string[]>([]);
  // const [creationTimeFrom, setCreationTimeFrom] = useState("");
  // const [creationTimeTo, setCreationTimeTo] = useState("");
  // const [serviceStartTimeFrom, setServiceStartTimeFrom] = useState("");
  // const [serviceStartTimeTo, setServiceStartTimeTo] = useState("");
  // const [serviceStopTimeFrom, setServiceStopTimeFrom] = useState("");
  // const [serviceStopTimeTo, setServiceStopTimeTo] = useState("");
  // const [healthcareFacilityTypeCode, setHealthcareFacilityTypeCode] = useState<
  //   string[]
  // >([]);
  // const [eventCodeList, setEventCodeList] = useState<object[]>([]); //{valeur:"",identifiantNomenclature:"",label:""}

  const resetFilter = () => {
    setStatuses("APPROVED");
    setQuery("FindDocuments");
    setClassCode("");
    setTypeCode("");
    setsubmissionTimeFromDate("");
    setsubmissionTimeFromTime("");
    setsubmissionTimeToDate("");
    setsubmissionTimeToTime("");
  };

  const handleFilter = async () => {
    props.closeModal();
    await props.handleFilter(
      true,
      author, //    auteur
      null, //classCode
      _.isEmpty(confidentialityCode) ? null : confidentialityCode, //confidentialityCode
      null, //creationTimeFrom
      null, //creationTimeTo
      submissionTimeFromDate && submissionTimeFromTime
        ? `${moment(submissionTimeFromDate).format("YYYYMMDD")}${moment(
            submissionTimeFromTime,
            "HH:mm"
          ).format("HHmm")}`
        : null, // submissionTimeFrom
      submissionTimeToDate && submissionTimeToTime
        ? `${moment(submissionTimeToDate).format("YYYYMMDD")}${moment(
            submissionTimeToTime,
            "HH:mm"
          ).format("HHmm")}`
        : null, // submissionTimeTo
      query, //
      null, // serviceStartTimeFrom
      null, // serviceStartTimeTo
      null, // serviceStopTimeFrom
      null, // serviceStopTimeTo
      [statuses], //statuses
      typeCode ? [typeCode] : [] //typeCode
    );
  };

  console.log(
    "🚀 ~ file: FindOption.tsx:27 ~ FindOption ~ classCode:",
    classCode
  );
  console.log(
    "🚀 ~ file: FindOption.tsx:29 ~ FindOption ~ typeCode:",
    typeCode
  );

  return (
    <div className="p-5">
      <h3 className="font-bold mb-4">Paramètres des filtres </h3>
      <hr className="-mt-2 mb-5" />
      <div className="flex gap-10" style={{ width: "80vw" }}>
        <div className="w-2/3">
          <p className="font-bold">Type de recherche : {query}</p>
          <SelectComp
            value={query}
            options={dropdownQueryOptions}
            onChange={(e) => setQuery(e.target.value)}
            onBlur={(e) => setQuery(e.target.value)}
            className="w-full border-blue-500"
            setText={false}
          />
          <p className="font-bold">Statut du document : {statuses}</p>
          <SelectComp
            value={statuses}
            options={dropdownStatusOptions}
            onChange={(e) => setStatuses(e.target.value)}
            onBlur={(e) => setStatuses(e.target.value)}
            className="w-full border-blue-500"
            setText={false}
          />

          <p className="font-bold">Classe : {classCode}</p>
          <SelectComp
            value={classCode}
            options={dropdownClasseOptions}
            //options={props.nomenclatures
            // ?.sort((a, b) => {
            //   const nameA = a.displayName.toUpperCase(); // ignore upper and lowercase
            //   const nameB = b.displayName.toUpperCase(); // ignore upper and lowercase
            //   if (nameA < nameB) {
            //     return -1;
            //   }
            //   if (nameA > nameB) {
            //     return 1;
            //   }

            //   // names must be equal
            //   return 0;
            // })
            // ?.map((el) => ({
            //   value: el.valeur,
            //   label: el.displayName,
            // }))}
            onChange={(e) => setClassCode(e.target.value)}
            onBlur={(e) => setClassCode(e.target.value)}
            className="w-full border-blue-500"
            setText={false}
          />

          <p className="font-bold">Type : {typeCode}</p>
          <SelectComp
            value={typeCode}
            options={
              classCode
                ? dropdownClasseOptions
                    ?.find((el) => el.value === classCode)
                    ?.options.map((el) => ({
                      label: `${el.label} ( ${el.value} )`,
                      value: el.value,
                    })) || []
                : []
            }
            onChange={(e) => setTypeCode(e.target.value)}
            onBlur={(e) => setTypeCode(e.target.value)}
            className="w-full border-blue-500"
            setText={false}
          />

          <p className="font-bold">
            Soumission :{JSON.stringify(submissionTimeFromDate)} -{" "}
            {JSON.stringify(submissionTimeFromTime)} -{" "}
            {/* {submissionTimeToDate} - {submissionTimeToTime} */}
          </p>
          <div className="flexCenter md:flex-row mb-5 md:mb-auto gap-10">
            <DatePicker
              id="date"
              locale="fr"
              className="border border-blue-500 px-3 py-2 rounded-lg w-full"
              selected={submissionTimeFromDate}
              dateFormat="dd/MM/yyyy"
              onChange={setsubmissionTimeFromDate}
              placeholderText={"Soumission du"}
              autoComplete="off"
              showYearDropdown
            />
            {/* <div className="flexCenter sub-values-act "> */}
            <TimePicker
              id="timeStart"
              value={submissionTimeFromTime}
              format="HH:mm"
              onChange={setsubmissionTimeFromTime}
              className="border border-blue-500"
              onClockClose={() => {
                //setSelectedInput("");
              }}
              onClockOpen={() => {}}
            />
            {/* </div> */}
            <DatePicker
              id="date"
              locale="fr"
              className=" border border-blue-500 px-3 py-2 rounded-lg w-full"
              selected={submissionTimeToDate}
              dateFormat="dd/MM/yyyy"
              onChange={setsubmissionTimeToDate}
              placeholderText={"Soumission au"}
              autoComplete="off"
              showYearDropdown
            />
            {/* <div className="flexCenter sub-values-act "> */}
            <TimePicker
              id="timeStart"
              value={submissionTimeToTime}
              format="HH:mm"
              className="border border-blue-500"
              onChange={setsubmissionTimeToTime}
              onClockClose={() => {
                //setSelectedInput("");
              }}
              onClockOpen={() => {}}
            />
            {/* </div> */}
          </div>
        </div>
        <div className="w-1/3">
          <p className="font-bold">
            Visibililé : {JSON.stringify(confidentialityCode)}
          </p>
          <Input
            name="visibility"
            type="checkbox"
            onChange={() => {
              confidentialityCode.includes("INVISIBLE_PATIENT")
                ? setConfidentialityCode((x) =>
                    x.filter((el) => el !== "INVISIBLE_PATIENT")
                  )
                : setConfidentialityCode([
                    ...confidentialityCode,
                    "INVISIBLE_PATIENT",
                  ]);
            }}
            checked={confidentialityCode.includes("INVISIBLE_PATIENT")}
            value="treating"
            labelText="Non visible par le patient"
            labelProps={{ className: "check" }}
            className="my-2"
          />
          <Input
            name="visibility"
            type="checkbox"
            value="treating"
            onChange={() => {
              confidentialityCode.includes("MASQUE_PS")
                ? setConfidentialityCode((x) =>
                    x.filter((el) => el !== "MASQUE_PS")
                  )
                : setConfidentialityCode([...confidentialityCode, "MASQUE_PS"]);
            }}
            checked={confidentialityCode.includes("MASQUE_PS")}
            labelText="Masqué aux professionnels de santé"
            labelProps={{ className: "check" }}
            className="my-2"
          />
          <Input
            name="visibility"
            type="checkbox"
            value="treating"
            labelText="Non visible pour les représentants légaux"
            onChange={() => {
              confidentialityCode.includes("INVISIBLE_REPRESENTANTS_LEGAUX")
                ? setConfidentialityCode((x) =>
                    x.filter((el) => el !== "INVISIBLE_REPRESENTANTS_LEGAUX")
                  )
                : setConfidentialityCode([
                    ...confidentialityCode,
                    "INVISIBLE_REPRESENTANTS_LEGAUX",
                  ]);
            }}
            checked={confidentialityCode.includes(
              "INVISIBLE_REPRESENTANTS_LEGAUX"
            )}
            labelProps={{ className: "check" }}
            className="my-2"
          />
          <p className="font-bold">Mes document Transférés :</p>
          <Input
            name="authDPS"
            type="checkbox"
            onChange={() => {
              setAuthor((x) => !x);
            }}
            labelText="Afficher uniquement mes documents"
            labelProps={{ className: "check" }}
            className="my-2 "
          />
          <div className="flex flex-col gap-5 mt-5">
            <Button
              status="primary"
              className="bg-red-500"
              height="32px"
              onClick={resetFilter}
            >
              X Rénitialiser les filtres
            </Button>
            <Button status="primary" height="32px" onClick={handleFilter}>
              ✔ Appliquer les filtres
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindOption;
