import makeToast from "../../../components/Snackbar";

export default class INSApi {
  endpoint: string = "";

  constructor(devboxApiEndpoint: string) {
    this.endpoint = devboxApiEndpoint;
  }

  async searchWithoutCard(payload, returnWithouthandleError?) {
    try {
      const res = await fetch(`${this.endpoint}/insi/v2/rechercheSansVitale`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "insi.lpsInfo.numeroAutorisation": "KRK17IM22JW0",
          "insi.lpsInfo.nom": "KORE",
          "insi.lpsInfo.version": "1.4",
        },
        body: JSON.stringify(payload),
      });

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      if (returnWithouthandleError) return data;
      if (!data) throw new Error("expected json. got different content");

      if (!res.ok) {
        const error = (data && data.message) || res.status;
        throw new Error(error);
      }

      return data;
    } catch (error) {
      if (returnWithouthandleError) return;
      return Promise.reject(error);
    }
  }

  async searchWithCard(idFacturation) {
    try {
      // query: auteur.identifiantInterne;
      // auteur.identifiantNational;
      // structure.identifiantFacturation;
      // structure.identifiantNational;
      const res = await fetch(
        `${this.endpoint}/insi/v2/rechercheAvecVitale?structure.identifiantFacturation=${idFacturation}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "insi.lpsInfo.numeroAutorisation": "KRK17IM22JW0",
            "insi.lpsInfo.nom": "KORE",
            "insi.lpsInfo.version": "1.4",
          },
          // body: JSON.stringify(payload),
        }
      );

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      if (!data) throw new Error("expected json. got different content");

      // if (!res.ok) {
      //   const error = (data && data.errorMessage) || res.status;
      //   throw new Error(error);
      // }

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async searchWithCardPost(payload) {
    try {
      const res = await fetch(`${this.endpoint}/insi/v2/rechercheAvecVitale`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "insi.lpsInfo.numeroAutorisation": "KRK17IM22JW0",
          "insi.lpsInfo.nom": "KORE",
          "insi.lpsInfo.version": "1.4",
        },
        body: JSON.stringify(payload),
      });

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      if (!data) throw new Error("expected json. got different content");

      // if (!res.ok) {
      //   const error = (data && data.errorMessage) || res.status;
      //   throw new Error(error);
      // }

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async verification(payload) {
    // const fakeData = {
    //   context: {
    //     auteur: {
    //       identifiantInterne: "2001079664",
    //       identifiantNational: "2001079664",
    //     },
    //     structure: {
    //       identifiantFacturation: "2001079664",
    //       identifiantNational: "2001079664",
    //     },
    //   },
    //   ins: {
    //     matriculeINS: {
    //       cle: "10",
    //       identifiantSysteme: "1.2.250.1.213.1.4.8",
    //       valeur: "2770763220829",
    //     },
    //     traitsIdentite: {
    //       dateNaissance: "14071977",
    //       lieuNaissance: null,
    //       nomFamille: "De Vinci",
    //       prenomActeNaissance: "Ruth",
    //       prenomUtilise: "Ruth",
    //       sexeAdministratif: "M",
    //     },
    //   },
    // };

    try {
      const res = await fetch(`${this.endpoint}/insi/v2/verification`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "insi.lpsInfo.numeroAutorisation": "KRK17IM22JW0",
          "insi.lpsInfo.nom": "KORE",
          "insi.lpsInfo.version": "1.4",
        },
        body: JSON.stringify(payload),
      });

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      // if (!data) throw new Error("expected json. got different content");

      // if (!res.ok) {
      //   const error = (data && data.message) || res.status;
      //   throw new Error(error);
      // }
      if(data.errorMessage)
        makeToast("error", data.errorMessage);
      else if(data.compteRendu && data.compteRendu.valeur === "01")
        makeToast("error", data.compteRendu.libelle || "Echech de la vérification");
      else
        makeToast("success", "Vérification effectué avec succès");
      return data;
    } catch (error) {
      console.log(error);
      // return Promise.reject(error);
    }
  }

  // INJECTION XML
  async injection(payload) {
    try {
      await fetch(`${this.endpoint}/cnda/insi/inject`, {
        method: "POST",
        body: payload,
        headers: {
          "insi.lpsInfo.numeroAutorisation": "KRK17IM22JW0",
          "insi.lpsInfo.nom": "KORE",
          "insi.lpsInfo.version": "1.4",
        },
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async verificationLot(payload) {
    try {
      const res = await fetch(
        `${this.endpoint}/insi/v2/verification/lot/depot`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // "insi.lpsInfo.numeroAutorisation": "KRK17IM22JW0",
            // "insi.lpsInfo.nom": "KORE",
            // "insi.lpsInfo.version": "1.4",
          },
          body: JSON.stringify(payload),
        }
      );

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      return data;
    } catch (error) {
      console.log(error);
      // return Promise.reject(error);
    }
  }

  async verificationLotWithResult(idLot) {
    try {
      const payload = {
        context: {
          structure: {
            identifiantFacturation: "123456789",
          },
          auteur: {},
        },
        idLot,
      };
      const res = await fetch(
        `${this.endpoint}/insi/v2/verification/lot/resultat`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // "insi.lpsInfo.numeroAutorisation": "KRK17IM22JW0",
            // "insi.lpsInfo.nom": "KORE",
            // "insi.lpsInfo.version": "1.4",
          },
          body: JSON.stringify(payload),
        }
      );

      const data = res.headers.get("content-type")?.includes("application/json")
        ? await res.json()
        : null;

      return data;
    } catch (error) {
      console.log(error);
      // return Promise.reject(error);
    }
  }
  async clearInjection() {
    try {
      await fetch(`${this.endpoint}/cnda/insi/clear`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "insi.lpsInfo.numeroAutorisation": "KRK17IM22JW0",
          "insi.lpsInfo.nom": "KORE",
          "insi.lpsInfo.version": "1.4",
        },
      });
      return "success";
      // const data = res.headers.get("content-type")?.includes("application/json")
      //   ? await res.json()
      //   : null;

      // if (!data) throw new Error("expected json. got different content");

      // if (!res.ok) {
      //   const error = (data && data.errorMessage) || res.status;
      //   throw new Error(error);
      // }

      // return data;
    } catch (error) {
      console.log(
        "🚀 ~ file: index.ts:272 ~ INSApi ~ clearInjection ~ error:",
        error
      );
      return Promise.reject(error);
    }
  }
}
