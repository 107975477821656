/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useAtom } from "jotai";
import { Formik } from "formik";
import { statusINS, INSType } from "../../../helper/insHelper";
import moment from "../../../helper/moment";
import { INS, CPS } from "../../../api/devbox";
import { addNewLogs } from "../../../api/logs";
import countriesTn from "../../../config/constants/countriesTn.json";
import CountryWithPhoneSuffix from "../../../config/constants/pays.json";
import { signupPatientPatientelo } from "../../../api/patient";
import { userAtom } from "../../../state/auth";
import { cpsDataAtom } from "../../../state/cardCpsData";
import validationSchema from "../validateSchema";
import Button from "../../../components/ButtonUI";
import makeToast from "../../../components/Snackbar";
import { ReactComponent as Close } from "../../../assets/icons/icon-close.svg";
import requireInput from "../../../assets/images/require.png";
import "../styles.scss";

import Step0 from "./Step0";
import Step2 from "./Step2";
import Step2Test from "./Step2_Test";

import Step3 from "./Step3";
import { useTranslation } from "react-i18next";

interface IProps {
  openModal: Boolean;
  setOpenModal: Function;
  message?: String;
  type?: String;
  url?: string;
  setPatientAdded: boolean | any;
  setSelectedPatient: Function;
  setPatientData: Function;
  setOpenModalInfoPatient: Function;
  addExistUser: Function;
  setRefresh?: any;
}

const AddPatient: React.FC<IProps> = ({
  openModal,
  setOpenModal,
  setPatientAdded,
  setSelectedPatient,
  setPatientData,
  setOpenModalInfoPatient,
  addExistUser,
  setRefresh,
}) => {
  const { t, i18n } = useTranslation() as any;
  const [user, setUser] = useAtom(userAtom) as any;
  const [cpsData, setCpsData] = useAtom(cpsDataAtom) as any;
  const [startDate, setStartDate] = useState() as any;
  const [startDateINS, setStartDateINS] = useState();
  const [loadingBtn, setLoadingbtn] = useState(false);
  const [valueConfirmed, setValueConfirmed] = useState(false);
  const [loadingINS, setLoadingINS] = useState(false);
  const [paysState] = useState("France");
  const [regionState, setRegionState] = useState([]) as any;
  const [selectedRegionState, setSelectedRegionState] = useState() as any;
  const [departementState, setDepartementState] = useState([]) as any;
  const [selectedDepartementState, setSelectedDepartementState] =
    useState() as any;
  const [communesState, setCommunesState] = useState([]) as any;
  const [selectedCommunesState, setSelectedCommunesState] = useState() as any;

  const [villeTnState, setVilleTnState] = useState("");
  const [delegationTn, setDelegationTn] = useState([]) as any;
  const [delegationTnState, setDelegationTnState] = useState("");
  const [loadAdress, setLoadAdress] = useState() as any;

  const [steps, setSteps] = useState(0);

  const _getRegion = async () => {
    try {
      fetch("https://geo.api.gouv.fr/regions")
        .then((response) => response.json())
        .then((data) =>
          setRegionState(
            data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
          )
        );
    } catch (error) {}
  };

  const _getDepartementState = async () => {
    try {
      fetch(
        `https://geo.api.gouv.fr/regions/${selectedRegionState?.code}/departements`
      )
        .then((response) => response.json())
        .then((data) =>
          setDepartementState(
            data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
          )
        );
    } catch (error) {}
  };

  const _getCommunesState = async () => {
    try {
      fetch(
        `https://geo.api.gouv.fr/departements/${selectedDepartementState?.code}/communes`
      )
        .then((response) => response.json())
        .then((data) =>
          setCommunesState(
            data.map((el) => ({ label: el.nom, value: el.nom, code: el.code }))
          )
        );
    } catch (error) {}
  };

  const _getRegionAndDepartementFromCommune = async (codeCommune) => {
    try {
      fetch(`https://geo.api.gouv.fr/communes/${codeCommune}`)
        .then((response) => response.json())
        .then(async (data) => {
          setSelectedRegionState(
            regionState.find((elm) => elm.code == data?.codeRegion)
          );
          setLoadAdress(data);
        });
    } catch (error) {}
  };

  useEffect(() => {
    if (loadAdress && departementState) {
      setSelectedDepartementState(
        departementState.find((elm) => elm.code == loadAdress?.codeDepartement)
      );
    }
    if (loadAdress && communesState) {
      setSelectedCommunesState(
        communesState.find((elm) => elm.code == loadAdress?.code)
      );
    }
  }, [loadAdress, selectedRegionState, departementState, communesState]);

  // // Init
  useEffect(() => {
    _getRegion();
  }, []);

  // // Init
  useEffect(() => {
    if (selectedRegionState?.code) _getDepartementState();
  }, [selectedRegionState]);

  // // Init
  useEffect(() => {
    if (selectedDepartementState?.code) _getCommunesState();
  }, [selectedDepartementState]);

  // // Init
  useEffect(() => {
    if (villeTnState) {
      setDelegationTn(
        countriesTn[villeTnState].map((el) => ({
          ...el,
          value: el.delegation,
          label: el.delegation,
        }))
      );
    }
  }, [villeTnState]);

  const handleAddPatient = async (values) => {
    try {
      const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (
        !values.email ||
        !values.lastName ||
        !values.firstName ||
        !values.sexe ||
        // !values.phone ||
        // !values.country ||
        // !values.adresse ||
        // !values.numSecSocial ||
        // !values.postalCode ||
        !startDate
      ) {
        return makeToast("warning", t("AddPatient.AllRequiredFields"));
      }
      if (!validEmailRegex.test(values.email))
        return makeToast("warning", t("AddPatient.RequireEmail"));
      // if (values.numSecSocial?.length !== 15)
      //   return makeToast("warning", t("AddPatient.ControlnumSecSocial"));

      values.addressDetails = {
        country: paysState,
        region: selectedRegionState?.value,
        departement: selectedDepartementState?.value,
        commune: selectedCommunesState?.value,
        gouvernement: villeTnState,
        delegation: delegationTnState,
      };

      if (startDate) values.birthDate = startDate;
      values.phoneSuffixe = CountryWithPhoneSuffix.find(
        (el) => el.name === values.country
      )?.dial_code as any;
      setLoadingbtn(true);
      //INS Values
      if (process.env.REACT_APP_KATOMI_TENANT === "katomi") {
        values.INS = {
          INSStaus: statusINS(
            values.identity,
            !_.isEmpty(values.responseINS?.insActif)
          ),

          INSValue: {
            dateNaissance: moment(startDateINS).format("DDMMYYYY"),
            lieuNaissance: values.birthAddressINS || null,
            nomNaissance: values.NameINS || values.lastName,
            prenom: values.firstNameINS || values.firstName,
            sexe: values.INSSexe
              ? values.INSSexe
              : values?.sexe?.includes("man")
              ? "M"
              : values?.sexe?.includes("woman")
              ? "F"
              : "",
            prenoms: values.firstNameINS
              ? values.firstNameINS.split(",")
              : values.firstName.split(","),
            context: {
              structure: {
                identifiantFacturation: user.idFacturationCps,
              },
              auteur: {},
            },
            lastVerifiedDate: values.lastVerified,
            matriculeINS: values.matriculeINS,
            identifiantSysteme: values.identifiantSysteme,
            Idfictional: values.Idfictional,
            IDDoting: values.IDDoting,
          },
          responseINS: values.responseINS,
        };
      }

      const response = await signupPatientPatientelo({
        ...values,
        doctor: user?._id,
      });
      if (response?.message === "Max Patient Limit Reached") {
        makeToast("warning", t("AddPatient.LimitTier"));
        setOpenModal(!openModal);
        setLoadingbtn(false);
        return;
      }

      if (response?.response === "Patient already exist") {
        addExistUser(response.patient);
        setSelectedPatient(response.patient._id);
        setPatientData(response.patient);
        setLoadingbtn(false);

        return;
      }
      if (response.message !== "Failure") {
        makeToast("success", t("AddPatient.SucessAdd"));
        setOpenModal(!openModal);
        setSelectedPatient(response.patient._id);
        setPatientAdded(true);
        setRefresh(uuidv4());
        setPatientData(response.patient);
        setUser({ ...user, ...response.doctor });
        setOpenModalInfoPatient(true);
        setLoadingbtn(false);
      }
    } catch (error) {
      setLoadingbtn(false);
      makeToast("error", t("AddPatient.Error"));
    }
  };

  const handleVerifyINS = async (values, setFieldValue) => {
    try {
      //   const regexNom = /^[A-Z-' ]+$/; // majuscule + ' + - + white space

      setLoadingINS(true);
      let cpsInfo;
      const readerInfo = await CPS.readerInfo(
        false,
        i18n.language.includes("en")
      );
      if (!readerInfo) return setLoadingINS(false);
      if (!cpsData) {
        cpsInfo = await CPS.readCard();
        setCpsData(cpsInfo);
      }

      if (
        (startDateINS || startDate) &&
        (values.NameINS || values.lastName) &&
        (values.firstNameINS || values.firstName) &&
        (values.INSSexe || !_.isEmpty(values?.sexe))
      ) {
        const INSPaylaod = {
          dateNaissance: startDateINS
            ? moment(startDateINS).format("DDMMYYYY")
            : moment(startDate).format("DDMMYYYY"),
          lieuNaissance: values.birthAddressINS
            ? values.birthAddressINS
            : undefined,
          nomNaissance: values.NameINS || values.lastName,
          prenom: values.firstNameINS || values.firstName,
          sexe: values.INSSexe
            ? values.INSSexe
            : values?.sexe?.includes("man")
            ? "M"
            : values?.sexe?.includes("woman")
            ? "F"
            : "",
          prenoms: values.firstNameINS
            ? values.firstNameINS.split(",")
            : values.firstName.split(" "),

          context: {
            structure: {
              identifiantFacturation:
                // cpsInfo?.activitiesPs[
                //   `${cpsInfo?.selectedActivity}`
                // ]?.facturationInfo?.idFacturation?.slice(0, 9) ||
                user.idFacturationCps,
            },
            auteur: {},
          },
          matriculeINS: values.matriculeINS,
          Idfictional: values.Idfictional,
          IDDoting: values.IDDoting,
        };
        // if (!regexNom.test(values.NameINS)) {
        //   setLoadingINS(false);
        //   return makeToast("warning", t("AddPatient.PSWContraint"));
        // }
        //  else if (
        //   values?.birthAddressINS &&
        //   !regexLieux.test(values?.birthAddressINS)
        // ) {
        //   setLoadingINS(false);
        //   return makeToast("warning", t("AddPatient.BirthContraint"));
        // }

        const responseINS = await INS.searchWithoutCard(INSPaylaod, true);
        const logsPayload = {
          type: "READ_WITHOUT_CARD",
          doctorId: user?._id,
          infoCardUsed: cpsInfo,
          body: INSPaylaod,
          resposnse: responseINS || "FAIL",
        };
        await addNewLogs(logsPayload);
        if (responseINS?.compteRendu?.valeur == "02") {
          setLoadingINS(false);
          return makeToast("warning", t("AddPatient.MultipleIdentitiesFound"));
        } else if (responseINS?.insActif?.valeur) {
          makeToast("success", t("AddPatient.SuccessINS"));

          setFieldValue("lastVerified", moment());
          setFieldValue("responseINS", responseINS);
          responseINS?.traitsIdentite?.prenomActeNaissance &&
            setFieldValue(
              "firstName",
              responseINS?.traitsIdentite?.prenomActeNaissance
            );
          responseINS?.traitsIdentite?.nomFamille &&
            setFieldValue("lastName", responseINS?.traitsIdentite?.nomFamille);
          responseINS?.traitsIdentite?.nomFamille &&
            setFieldValue("NameINS", responseINS?.traitsIdentite?.nomFamille);
          responseINS?.traitsIdentite?.prenomActeNaissance &&
            setFieldValue(
              "firstNameINS",
              responseINS?.traitsIdentite?.prenomActeNaissance
            );
          responseINS?.traitsIdentite?.lieuNaissance &&
            setFieldValue(
              "birthAddressINS",
              responseINS?.traitsIdentite?.lieuNaissance
            );
          setFieldValue("country", "France");
          if (responseINS?.traitsIdentite?.lieuNaissance)
            _getRegionAndDepartementFromCommune(
              responseINS?.traitsIdentite?.lieuNaissance
            );
          setFieldValue("matriculeINS", responseINS?.insActif?.valeur);
          setFieldValue(
            "identifiantSysteme",
            INSType[responseINS?.insActif?.identifiantSysteme]
          );

          setStartDate(new Date(responseINS?.traitsIdentite?.dateNaissance));
          setFieldValue(
            "sexe",
            responseINS?.traitsIdentite?.sexeAdministratif === "M"
              ? ["man"]
              : ["woman"]
          );
          setValueConfirmed(true);
        } else {
          makeToast("warning", t("AddPatient.NoIdentityFound"));
        }
      } else {
        setLoadingINS(false);
        return makeToast("warning", t("AddPatient.AllRequiredFields"));
      }
      setLoadingINS(false);
    } catch (error) {
      setLoadingINS(false);
    }
  };

  const handleVerifyINSWithMultipleName = async (values, setFieldValue) => {
    try {
      setLoadingINS(true);
      let cpsInfo;
      const readerInfo = await CPS.readerInfo(
        false,
        i18n.language.includes("en")
      );
      if (!readerInfo) return setLoadingINS(false);

      if (!cpsData) {
        cpsInfo = await CPS.readCard();
        setCpsData(cpsInfo);
      }

      if (
        (startDateINS || startDate) &&
        (values.NameINS || values.lastName) &&
        (values.firstNameINS || values.firstName) &&
        (values.INSSexe || !_.isEmpty(values?.sexe))
      ) {
        // start treatement
        const names = [...values.firstNameINS.split(" "), values.firstNameINS];
        let successFound = false;
        for (const el of names) {
          if (successFound) return;
          const INSPaylaod = {
            dateNaissance: startDateINS
              ? moment(startDateINS).format("DDMMYYYY")
              : moment(startDate).format("DDMMYYYY"),
            lieuNaissance: values.birthAddressINS
              ? values.birthAddressINS
              : undefined,
            nomNaissance: values.NameINS || values.lastName,
            prenom: el,
            sexe: values.INSSexe
              ? values.INSSexe
              : values?.sexe?.includes("man")
              ? "M"
              : values?.sexe?.includes("woman")
              ? "F"
              : "",
            prenoms: [el],

            context: {
              structure: {
                identifiantFacturation:
                  // cpsInfo?.activitiesPs[
                  //   `${cpsInfo?.selectedActivity}`
                  // ]?.facturationInfo?.idFacturation?.slice(0, 9) ||
                  user.idFacturationCps,
              },
              auteur: {},
            },
            matriculeINS: values.matriculeINS,
            Idfictional: values.Idfictional,
            IDDoting: values.IDDoting,
          };
          const responseINS = await INS.searchWithoutCard(INSPaylaod, true);
          const logsPayload = {
            type: "READ_WITHOUT_CARD",
            doctorId: user?._id,
            infoCardUsed: cpsInfo,
            body: INSPaylaod,
            resposnse: responseINS || "FAIL",
          };
          await addNewLogs(logsPayload);
          if (responseINS?.compteRendu?.valeur == "02") {
            setLoadingINS(false);
            successFound = true;
            return makeToast(
              "warning",
              t("AddPatient.MultipleIdentitiesFound")
            );
          } else if (responseINS?.insActif?.valeur) {
            successFound = true;

            makeToast("success", t("AddPatient.SuccessINS"));

            setFieldValue("lastVerified", moment());
            setFieldValue("responseINS", responseINS);
            responseINS?.traitsIdentite?.prenomActeNaissance &&
              setFieldValue(
                "firstName",
                responseINS?.traitsIdentite?.prenomActeNaissance
              );
            responseINS?.traitsIdentite?.nomFamille &&
              setFieldValue(
                "lastName",
                responseINS?.traitsIdentite?.nomFamille
              );
            responseINS?.traitsIdentite?.nomFamille &&
              setFieldValue("NameINS", responseINS?.traitsIdentite?.nomFamille);
            responseINS?.traitsIdentite?.prenomActeNaissance &&
              setFieldValue(
                "firstNameINS",
                responseINS?.traitsIdentite?.prenomActeNaissance
              );
            responseINS?.traitsIdentite?.lieuNaissance &&
              setFieldValue(
                "birthAddressINS",
                responseINS?.traitsIdentite?.lieuNaissance
              );
            setFieldValue("country", "France");
            if (responseINS?.traitsIdentite?.lieuNaissance)
              _getRegionAndDepartementFromCommune(
                responseINS?.traitsIdentite?.lieuNaissance
              );
            setFieldValue("matriculeINS", responseINS?.insActif?.valeur);
            setFieldValue(
              "identifiantSysteme",
              INSType[responseINS?.insActif?.identifiantSysteme]
            );

            setStartDate(new Date(responseINS?.traitsIdentite?.dateNaissance));
            setFieldValue(
              "sexe",
              responseINS?.traitsIdentite?.sexeAdministratif === "M"
                ? ["man"]
                : ["woman"]
            );
            setValueConfirmed(true);
          }
        }
        if (!successFound)
          makeToast("warning", t("AddPatient.NoIdentityFound"));
        // await Promise.all(
        //   names.map(async (el) => {
        //     const INSPaylaod = {
        //       dateNaissance: startDateINS
        //         ? moment(startDateINS).format("DDMMYYYY")
        //         : moment(startDate).format("DDMMYYYY"),
        //       lieuNaissance: values.birthAddressINS
        //         ? values.birthAddressINS
        //         : undefined,
        //       nomNaissance: values.NameINS || values.lastName,
        //       prenom: el,
        //       sexe: values.INSSexe
        //         ? values.INSSexe
        //         : values?.sexe?.includes("man")
        //         ? "M"
        //         : values?.sexe?.includes("woman")
        //         ? "F"
        //         : "",
        //       prenoms: [el],

        //       context: {
        //         structure: { identifiantFacturation: "123456789" },
        //         auteur: {},
        //       },
        //       matriculeINS: values.matriculeINS,
        //       Idfictional: values.Idfictional,
        //       IDDoting: values.IDDoting,
        //     };
        //     const responseINS = await INS.searchWithoutCard(INSPaylaod, true);
        //     const logsPayload = {
        //       type: "READ_WITHOUT_CARD",
        //       doctorId: user?._id,
        //       infoCardUsed: cpsInfo,
        //       body: INSPaylaod,
        //       resposnse: responseINS || "FAIL",
        //     };
        //     await addNewLogs(logsPayload);
        //     if (responseINS?.compteRendu?.valeur == "02") {
        //       setLoadingINS(false);
        //       return makeToast(
        //         "warning",
        //         t("AddPatient.MultipleIdentitiesFound")
        //       );
        //     } else if (responseINS?.insActif?.valeur) {
        //       makeToast("success", t("AddPatient.SuccessINS"));

        //       setFieldValue("lastVerified", moment());
        //       setFieldValue("responseINS", responseINS);
        //       responseINS?.traitsIdentite?.prenomActeNaissance &&
        //         setFieldValue(
        //           "firstName",
        //           responseINS?.traitsIdentite?.prenomActeNaissance
        //         );
        //       responseINS?.traitsIdentite?.nomFamille &&
        //         setFieldValue(
        //           "lastName",
        //           responseINS?.traitsIdentite?.nomFamille
        //         );
        //       responseINS?.traitsIdentite?.nomFamille &&
        //         setFieldValue(
        //           "NameINS",
        //           responseINS?.traitsIdentite?.nomFamille
        //         );
        //       responseINS?.traitsIdentite?.prenomActeNaissance &&
        //         setFieldValue(
        //           "firstNameINS",
        //           responseINS?.traitsIdentite?.prenomActeNaissance
        //         );
        //       responseINS?.traitsIdentite?.lieuNaissance &&
        //         setFieldValue(
        //           "birthAddressINS",
        //           responseINS?.traitsIdentite?.lieuNaissance
        //         );
        //       setFieldValue("country", "France");
        //       if (responseINS?.traitsIdentite?.lieuNaissance)
        //         _getRegionAndDepartementFromCommune(
        //           responseINS?.traitsIdentite?.lieuNaissance
        //         );
        //       setFieldValue("matriculeINS", responseINS?.insActif?.valeur);
        //       setFieldValue(
        //         "identifiantSysteme",
        //         INSType[responseINS?.insActif?.identifiantSysteme]
        //       );

        //       setStartDate(
        //         new Date(responseINS?.traitsIdentite?.dateNaissance)
        //       );
        //       setFieldValue(
        //         "sexe",
        //         responseINS?.traitsIdentite?.sexeAdministratif === "M"
        //           ? ["man"]
        //           : ["woman"]
        //       );
        //       setValueConfirmed(true);
        //     } else {
        //       makeToast("warning", t("AddPatient.NoIdentityFound"));
        //     }
        //   })
        // )
      } else {
        setLoadingINS(false);
        return makeToast("warning", t("AddPatient.AllRequiredFields"));
      }
      setLoadingINS(false);
    } catch (error) {
      console.log(error);
      setLoadingINS(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div
        className={`absolute ${
          steps === 0 ? "flexCenter flex-col" : "inset-y-0 right-0"
        }  form-add-patient  z-40 transition ease-in duration-700 patient-modal-info`}
        //   onClick={() => setOpenModal(!openModal)}
      >
        <div className="pb-10 relative md:w-full sm:4/5">
          <Button
            status="icon-secondary"
            className="absolute right-3 top-3 "
            onClick={() => setOpenModal(!openModal)}
          >
            <Close />
          </Button>
          <div className="title">
            <h1 className=" flexCenter">{t("AddPatient.NewPatient")}</h1>
          </div>

          <div className="form-container flexCenter">
            <Formik
              initialValues={{
                email: "",
                lastName: "",
                firstName: "",
                birthDate: "",
                sexe: [] as any,
                handicap: [],
                phone: "",
                adresse: "",
                numSecSocial: "",
                medecin: "",
                telMedecin: "",
                addressMedecin: "",
                postalCode: "",
                country: "",
                pregnant: false,
                semaineNBR: "",
                addressDetails: {},
                matriculeINS: "" as any,
                identifiantSysteme: "" as any,
                INSSexe: "",
                NameINS: "",
                firstNameINS: "",
                birthAddressINS: "",
                birthDateINS: "",
                INS: {},
                responseINS: {},
                INSStaus: "",
                lastVerified: "",
                Idfictional: false as any,
                IDDoting: false as any,
                identity: "idvalid" as any,
              }}
              onSubmit={async (values) => {
                try {
                } catch (error) {
                  makeToast("[error] ", error);
                }
              }}
              validationSchema={validationSchema}
            >
              {(props) => {
                const {
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                } = props;

                return (
                  <form
                    onSubmit={handleSubmit}
                    className="form-login flexCenter flex-col fixingColor"
                  >
                    {steps === 0 && (
                      <Step0
                        setSteps={setSteps}
                        setFieldValue={setFieldValue}
                        getRegionAndDepartementFromCommune={
                          _getRegionAndDepartementFromCommune
                        }
                        setStartDate={setStartDate}
                        setValueConfirmed={setValueConfirmed}
                      />
                    )}

                    {/* Search par carte vitale
                    {steps === 1 && (
                      <>
                        <Step1
                          setFieldValue={setFieldValue}
                          getRegionAndDepartementFromCommune={
                            _getRegionAndDepartementFromCommune
                          }
                          setStartDate={setStartDate}
                          setValueConfirmed={setValueConfirmed}
                          setSteps={setSteps}
                        />
                      </>
                    )} */}
                    {/* Search par trait */}
                    {steps === 2 && (
                      <Step2
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        valueConfirmed={valueConfirmed}
                        selectedRegionState={selectedRegionState}
                        setSelectedRegionState={setSelectedRegionState}
                        regionState={regionState}
                        selectedDepartementState={selectedDepartementState}
                        setSelectedDepartementState={
                          setSelectedDepartementState
                        }
                        departementState={departementState}
                        selectedCommunesState={selectedCommunesState}
                        setSelectedCommunesState={setSelectedCommunesState}
                        setFieldValue={setFieldValue}
                        communesState={communesState}
                        startDateINS={startDateINS}
                        setStartDateINS={setStartDateINS}
                        loadingINS={loadingINS}
                        handleVerifyINS={handleVerifyINS}
                        handleVerifyINSWithMultipleName={
                          handleVerifyINSWithMultipleName
                        }
                        setSteps={setSteps}
                        setStartDate={setStartDate}
                        loadingBtn={loadingBtn}
                      />
                    )}
                    {steps === 2.1 &&
                      process.env.REACT_APP_KATOMI_ENV !== "production" && (
                        <Step2Test
                          user={user}
                          values={values}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          valueConfirmed={valueConfirmed}
                          selectedRegionState={selectedRegionState}
                          setSelectedRegionState={setSelectedRegionState}
                          regionState={regionState}
                          selectedDepartementState={selectedDepartementState}
                          setSelectedDepartementState={
                            setSelectedDepartementState
                          }
                          departementState={departementState}
                          selectedCommunesState={selectedCommunesState}
                          setSelectedCommunesState={setSelectedCommunesState}
                          setFieldValue={setFieldValue}
                          communesState={communesState}
                          startDateINS={startDateINS}
                          setStartDateINS={setStartDateINS}
                          loadingINS={loadingINS}
                          handleVerifyINS={handleVerifyINS}
                          setSteps={setSteps}
                          setStartDate={setStartDate}
                          loadingBtn={loadingBtn}
                        />
                      )}
                    {steps === 3 && (
                      <Step3
                        errors={errors}
                        values={values}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        valueConfirmed={valueConfirmed}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        requireInput={requireInput}
                        selectedRegionState={selectedRegionState}
                        setSelectedRegionState={setSelectedRegionState}
                        regionState={regionState}
                        selectedDepartementState={selectedDepartementState}
                        setSelectedDepartementState={
                          setSelectedDepartementState
                        }
                        departementState={departementState}
                        selectedCommunesState={selectedCommunesState}
                        setSelectedCommunesState={setSelectedCommunesState}
                        communesState={communesState}
                        villeTnState={villeTnState}
                        countriesTn={countriesTn}
                        setVilleTnState={setVilleTnState}
                        delegationTnState={delegationTnState}
                        delegationTn={delegationTn}
                        setDelegationTnState={setDelegationTnState}
                      />
                    )}
                    {steps === 3 && (
                      <div className="flexCenter">
                        <Button
                          status="primary"
                          className="btn mb-2.5"
                          onClick={() => handleAddPatient(values)}
                          disabled={loadingBtn || loadingINS}
                        >
                          <div className="relative">
                            {(loadingBtn || loadingINS) && (
                              <div className="absolute h-full w-full flexCenter">
                                <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-purple-500"></div>
                              </div>
                            )}
                            {t("AddPatient.save")}
                          </div>
                        </Button>
                      </div>
                    )}
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPatient;
