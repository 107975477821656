import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import Icon from "../../components/Icon";
import { User } from "../../types/user";
import AddressDoctorProfile from "./modal/Address";
import { userAtom } from "../../state/auth";
import { useAtom } from "jotai";
import Modal from "../../components/Modal";

const Location: React.FC<User | any> = ({ userProps }) => {
  const { t } = useTranslation() as any;
  const [user] = useAtom(userAtom) as any;
  const [modalModifyLocation, setModalModifyLocation] = useState(false);
  return (
    <>
      <Modal
        closeModal={() => setModalModifyLocation(false)}
        showModal={modalModifyLocation}
      >
        <AddressDoctorProfile
          doctorId={userProps._id}
          setOpenModal={setModalModifyLocation}
          openModal={modalModifyLocation}
        />
      </Modal>

      <div className="row-span-1 location">
        <div className="headerContainer flex items-center relative">
          <Icon name="point" isIcon={false} className="svgClass" />
          <p className="header">{t("Profile.consultationSite")}</p>
          <p
            className="absolute right-0	modifier"
            onClick={() => setModalModifyLocation(true)}
          >
            {t("Profile.Update")}
          </p>
        </div>
        {!_.isEmpty(user?.workingLocation) && (
          <table className="table-fixed w-full">
            <thead>
              <tr>
                <th className="text-sm text-center pl-3">
                  {" "}
                  {t("Profile.Name")}
                </th>
                <th className="text-sm text-center"> {t("Profile.Address")}</th>
                <th className="text-sm text-center"> {t("Profile.City")}</th>
                {/* <th>Code postal</th> */}
                <th className="text-sm text-center hide-col-mobile">
                  {" "}
                  {t("Profile.Phone")}
                </th>
                <th className="text-sm text-center hide-col-mobile capitalize">
                  {" "}
                  {t("Profile.Type")}
                </th>
                <th className="text-sm text-center hide-col-mobile">
                  {" "}
                  {t("Profile.Color")}
                </th>
              </tr>
            </thead>
            <tbody>
              {user?.workingLocation.map((location) => (
                <tr>
                  <td className="text-center text-sm pl-3">{location?.name}</td>
                  <td className="text-center text-sm">{location?.location}</td>
                  <td className="text-center text-sm ">{location?.ville}</td>
                  {/* <td className="text-center">{location?.postalCode}</td> */}
                  <td className="text-center text-sm hide-col-mobile" text-sm>
                    {location?.tel}
                  </td>
                  <td className="text-center text-sm hide-col-mobile" text-sm>
                    {location?.type}
                  </td>
                  <td className="text-center text-sm hide-col-mobile">
                    <input type="color" value={location?.color} disabled />
                  </td>
                </tr>
              ))}
            </tbody>{" "}
          </table>
        )}
      </div>
    </>
  );
};

export default Location;
