import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { AuthentificationCheck } from "./config/constants/i18n/AuthentificationCheck";
import { SignIn } from "./config/constants/i18n/SignIn";
import { SignUp } from "./config/constants/i18n/SignUp";
import { DashboardDoctor } from "./config/constants/i18n/DashboardDoctor";
import { DashboardPatient } from "./config/constants/i18n/DashboardPatient";
import { Moment } from "./config/constants/i18n/Moment";
import { Agenda } from "./config/constants/i18n/Agenda";
import { MyPatient } from "./config/constants/i18n/MyPatient";
import { Consultation } from "./config/constants/i18n/Consultation";
import { AddPatient } from "./config/constants/i18n/AddPatient";
import { Specialist } from "./config/constants/i18n/Specialist";
import { Component } from "./config/constants/i18n/Component";
import { Profile } from "./config/constants/i18n/Profile";
import { SideBarMenu } from "./config/constants/i18n/SideBarMenu";
import { Chat } from "./config/constants/i18n/Chat";
import { Extension } from "./config/constants/i18n/Extension";
import { TemplatePDF } from "./config/constants/i18n/TemplatePDF";
import { AddPrescription } from "./config/constants/i18n/AddPrescription";
import { MyTeleconsultation } from "./config/constants/i18n/MyTeleconsultation";
import { Survey } from "./config/constants/i18n/Survey";
import SurveyIntlfr from "./config/constants/locales/fr-fr.json";
import SurveyIntlen from "./config/constants/locales/en-us.json";
import { ModalFichePatient } from "./config/constants/i18n/AllModalFichePatient";
import { ProfilePatient } from "./config/constants/i18n/ProfilePatient";
import { Segure } from "./config/constants/i18n/Segure";
import { MyDoctors } from "./config/constants/i18n/MyDoctors";
import { MyFolder } from "./config/constants/i18n/MyFolder";
import { Teleconsultation } from "./config/constants/i18n/Teleconsultation";
import { DoctorMoveMeeting } from "./config/constants/i18n/DoctorMoveMeeting";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: "fr",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          // here we will place our translations...
          AuthentificationCheck: AuthentificationCheck.en,
          SignIn: SignIn.en,
          SignUp: SignUp.en,
          DashboardDoctor: DashboardDoctor.en,
          Moment: Moment.en,
          Agenda: Agenda.en,
          MyPatient: MyPatient.en,
          Consultation: Consultation.en,
          AddPatient: AddPatient.en,
          Specialist: Specialist.en,
          Component: Component.en,
          Profile: Profile.en,
          SideBarMenu: SideBarMenu.en,
          Chat: Chat.en,
          Extension: Extension.en,
          TemplatePDF: TemplatePDF.en,
          AddPrescription: AddPrescription.en,
          MyTeleconsultation: MyTeleconsultation.en,
          Survey: Survey.en,
          SurveyIntl: SurveyIntlen,
          ModalFichePatient: ModalFichePatient.en,
          DashboardPatient: DashboardPatient.en,
          ProfilePatient: ProfilePatient.en,
          Segure: Segure.en,
          MyDoctors: MyDoctors.en,
          MyFolder: MyFolder.en,
          DoctorMoveMeeting: DoctorMoveMeeting.en,
          Teleconsultation: Teleconsultation.en,
        },
      },
      fr: {
        translation: {
          // here we will place our translations...
          AuthentificationCheck: AuthentificationCheck.fr,
          SignIn: SignIn.fr,
          SignUp: SignUp.fr,
          DashboardDoctor: DashboardDoctor.fr,
          Moment: Moment.fr,
          Agenda: Agenda.fr,
          MyPatient: MyPatient.fr,
          Consultation: Consultation.fr,
          AddPatient: AddPatient.fr,
          Specialist: Specialist.fr,
          Component: Component.fr,
          Profile: Profile.fr,
          SideBarMenu: SideBarMenu.fr,
          Chat: Chat.fr,
          Extension: Extension.fr,
          TemplatePDF: TemplatePDF.fr,
          AddPrescription: AddPrescription.fr,
          MyTeleconsultation: MyTeleconsultation.fr,
          Survey: Survey.fr,
          SurveyIntl: SurveyIntlfr,
          ModalFichePatient: ModalFichePatient.fr,
          DashboardPatient: DashboardPatient.fr,
          ProfilePatient: ProfilePatient.fr,
          Segure: Segure.fr,
          MyDoctors: MyDoctors.fr,
          MyFolder: MyFolder.fr,
          DoctorMoveMeeting: DoctorMoveMeeting.fr,
          Teleconsultation: Teleconsultation.fr,
        },
      },
    },
  });

export default i18n;
