/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { useField } from "../../../../hooks/FormGeneratorHooks";
import { FormGroup } from "../FormGroup";
import "./wyswyg.css";

const TextEditor = (props) => {
  const { t } = useTranslation();
  const [editorState, setEditorState] = useState(null) as any;
  const { el, onChange, defaultValue } = props;
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    setValue,
    valueDebounced,
    otherProps,
  } = useField(props);
  const { required, name } = props;
  const { children, label, options, helper, validMessage, ...rest } =
    otherProps;
  const { selectedImages: selectedImagesDebounced } = valueDebounced || {
    selectedImages: [],
  };
  const showError =
    !isValid && (selectedImagesDebounced.length >= 2 || isSubmitted);

  const formGroupProps = {
    errorMessage,
    helper,
    id,
    isRequired: !!required,
    label,
    showError,
    name,
    ...rest,
  };

  const onEditorStateChange = async (newState) => {
    setEditorState(newState);

    const html = draftToHtml(convertToRaw(newState.getCurrentContent()))
      .replace(/<p>/g, "")
      .replace(/<\/p>/g, "")
      .replace(/&nbsp;/g, " ")
      .replace(/(?:\r\n|\r|\n)/g, " ");
    onChange?.(html);
    setValue(html);
  };
  useEffect(() => {
    let htmlData: String = defaultValue;
    htmlData = htmlData.replaceAll("&lt;", "<");
    htmlData = htmlData.replaceAll("&gt;", ">");
    const contentBlocks = convertFromHTML(htmlData);

    const contentState = ContentState.createFromBlockArray(
      contentBlocks.contentBlocks,
      contentBlocks.entityMap
    );
    setEditorState(EditorState.createWithContent(contentState));
  }, [el]);

  return (
    <FormGroup {...formGroupProps}>
      <span style={{ fontSize: "16px" }} className="font-bold ">
        {t("Survey.TextZone")}
      </span>
      <Editor
        editorState={editorState}
        localization={{
          locale: "fr",
        }}
        wrapperClassName="react-wysiwyg-typescript-wrapper"
        editorClassName="react-wysiwyg-typescript-editor"
        toolbarClassName="react-wysiwyg-typescript-toolbar"
        popupClassName="rdw-image-modal"
        onEditorStateChange={onEditorStateChange}
        placeholder="..."
        editorStyle={{ height: "130px", padding: "8px" }}
      />
    </FormGroup>
  );
};
export default TextEditor;
