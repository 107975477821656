import { Redirect, RouteProps } from "react-router";
import Signin from "../pages/Signin";
import Signup from "../pages/signup";
import ConfirmEmail from "../pages/ConfirmEmail";
import SigninPatient from "../pages/SigninPatient";
import SignupPatient from "../pages/SignupPatient";
import AuthentificationCheck from "../pages/AuthentificationCheck";
import ForgetPassword from "../pages/ForgetPassword";
import ExternalBokking from "../pages/ExternalBooking";
import PscLogin from "../pages/PSCLogin";
import ChangeTempPassword from "../pages/ChangeTempPassword";

const forceRedirect = () => {
  switch (window.location.origin) {
    case process.env.REACT_APP_KATOMI_PATIENT_ENTRY_URL:
      return patientGuestRoutes;
    case process.env.REACT_APP_KATOMI_DOCTOR_ENTRY_URL:
      return doctorGuestRoutes;
    default:
      return guestRoutes;
  }
};

const guestRoutes: RouteProps[] = [
  {
    path: "/login",
    component: Signin,
    exact: true,
  },
  {
    path: "/confirm-email/:email",
    component: ConfirmEmail,
    exact: true,
  },
  {
    path: "/register",
    component: Signup,
    exact: true,
  },
  {
    path: "/patient/login",
    component: SigninPatient,
    exact: true,
  },
  {
    path: "/patient/password-change",
    component: ChangeTempPassword,
    exact: true,
  },
  {
    path: "/patient/inscription/routage",
    component: SignupPatient,
    exact: true,
  },
  {
    path: "/check/user",
    component: AuthentificationCheck,
    exact: true,
  },
  {
    path: "/forgetPassword",
    component: ForgetPassword,
    exact: true,
  },
  {
    path: "/",
    component: PscLogin,
    exact: true,
  },
  {
    path: "/rdv",
    component: ExternalBokking,
    exact: true,
  },
  { render: () => <Redirect to="/check/user" /> },
];

const doctorGuestRoutes: RouteProps[] = [
  {
    path: "/login",
    component: Signin,
    exact: true,
  },
  {
    path: "/confirm-email/:email",
    component: ConfirmEmail,
    exact: true,
  },
  {
    path: "/register",
    component: Signup,
    exact: true,
  },
  {
    path: "/forgetPassword",
    component: ForgetPassword,
    exact: true,
  },
  { render: () => <Redirect to="/login" /> },
];

const patientGuestRoutes: RouteProps[] = [
  {
    path: "/confirm-email/:email",
    component: ConfirmEmail,
    exact: true,
  },
  {
    path: "/patient/login",
    component: SigninPatient,
    exact: true,
  },
  {
    path: "/patient/inscription/routage",
    component: SignupPatient,
    exact: true,
  },
  {
    path: "/forgetPassword",
    component: ForgetPassword,
    exact: true,
  },
  {
    path: "/rdv",
    component: ExternalBokking,
    exact: true,
  },

  { render: () => <Redirect to="/patient/login" /> },
];

export default forceRedirect();
