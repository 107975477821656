import { atom } from "jotai";
import { Form } from "../types/IForm";
const intialFormConfig : Form = {
    sections: [{task_data:[],stepNbr: 0, sectionName: 'Page 1'}],
    name: "",
    description: "",
    type:"classic"
}

export const formAtom = atom<Form>(intialFormConfig);
