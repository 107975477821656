import { useState, useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import moment from "../../helper/moment";
import DoctorTimeSlots from "../DoctorTimeSlots";
import { loadImage } from "../../api/documents";
import { patientBookMeeting } from "../../api/patient";
import Button from "../ButtonUI";
import Icon from "../Icon";
import Select from "../Select";
import FloatInput from "../FloatInput";
import makeToast from "../Snackbar";
import phone from "../../assets/images/phone.png";
import { ReactComponent as Tick } from "../../assets/emoji/emoji-tick.svg";
import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";
import { handleSpeciality } from "../../helper/handleSpeciality";

const TakeMeet = ({
  closeModal,
  picketDateReservation,
  selectSpecialist,
  takeMeetModal,
  openConfirmTeleconsultationModal,
  openTimeSlots,
  openConfirmMeetModal,
  maxRow,
}) => {
  const { t, i18n } = useTranslation() as any;
  const [teleconsultation, setTeleconsultation] = useState(false);
  const [motifConsultation, setMotifConsultation] = useState("");
  const [pickedDate, setPickedDate] = useState(picketDateReservation);
  const [btnSubmitStatusLoading, setbtnSubmitStatusLoading] = useState(false);

  const handlePickDate = (pickedDate) => {
    setPickedDate(pickedDate);
  };

  // Set Initial values
  useEffect(() => {
    setPickedDate(picketDateReservation);
  }, [picketDateReservation]);

  const _handleTakeMeetPresentiel = async () => {
    try {
      setbtnSubmitStatusLoading(true);
      const response = await patientBookMeeting({
        dateConsultation: moment.utc(pickedDate?.time).local(),
        motif: motifConsultation,
        doctorId: selectSpecialist._id,
        type: teleconsultation ? "teleconsultation" : "presentiel",
        timeSlotId: pickedDate?.timeslotId,
      });
      setbtnSubmitStatusLoading(false);
      if (response.message === "Max Patient Limit Reached") {
        makeToast("warning", t("MyDoctors.ErrorMaxReached"));
        closeModal();
        return;
      }
      if (response) {
        openConfirmMeetModal(response?.consultation);
        closeModal();
      }
    } catch (error) {
      setbtnSubmitStatusLoading(false);
      console.error(error);
    }
  };

  return (
    <div className="md:p-5 drawer-width">
      <div className="flex justify-between">
        <div className="flex flex-1 items-center space-x-4  p-4 mb-2 rounded-2xl">
          <div className="h-20 w-20  rounded-lg">
            {selectSpecialist?.business_photo ? (
              <img
                crossOrigin="anonymous"
                referrerPolicy="origin"
                alt="profile"
                src={
                  selectSpecialist?.business_photo.slice(0, 4) === "http"
                    ? selectSpecialist?.business_photo
                    : loadImage(
                        selectSpecialist?._id,
                        "profile",
                        "thumbnail",
                        selectSpecialist?.business_photo
                      )
                }
                className="w-20 h-20 rounded-full shadow-md object-fill"
              />
            ) : selectSpecialist?.sexe === "woman" ? (
              <WomanPatient className="bg-gray-300 h-20 w-20 rounded-full" />
            ) : (
              <ManPatient className="bg-gray-300 h-20 w-20 rounded-full" />
            )}
          </div>
          <div className="flex flex-col">
            <span className="card-title">{`${selectSpecialist?.firstName} ${selectSpecialist?.lastName}`}</span>
            <span className="card-subtitle">
              {handleSpeciality(
                selectSpecialist?.speciality,
                i18n.language.includes("en")
              )}
            </span>
          </div>
        </div>
        <Icon
          isIcon={true}
          name="close"
          onClick={closeModal}
          className="close-modal"
        />
      </div>

      <h3 className="uppercase mb-2">{t("MyDoctors.MakingAppointments")}</h3>
      <Select
        options={[
          {
            label: t("MyDoctors.Face2Face"),
            value: "presentiel",
          },
          {
            label: t("MyDoctors.Teleconsultation"),
            value: "teleconsultation",
          },
        ]}
        setText={false}
        className={classNames(
          "w-full px-2",
          !_.isEmpty(pickedDate) || process.env.REACT_APP_KATOMI_TENANT
            ? "cursor-not-allowed"
            : "cursor-pointer"
        )}
        defaultValue={
          pickedDate
            ? pickedDate.type === "teleconsultation"
              ? "teleconsultation"
              : "presentiel"
            : "presentiel"
        }
        disabled={
          !_.isEmpty(pickedDate) ||
          process.env.REACT_APP_KATOMI_TENANT === "katomiTN"
        }
        onChange={(e) => {
          if (e.target.value === "teleconsultation") setTeleconsultation(true);
          else setTeleconsultation(false);
        }}
      ></Select>

      {teleconsultation ? (
        <>
          {/* consultation Motif */}
          <h3 className="uppercase mt-7 mb-3">
            {" "}
            {t("MyDoctors.MotifMeeting")}
          </h3>
          <FloatInput
            placeholder={t("MyDoctors.ReasonForConsultation")}
            labelText={t("MyDoctors.ReasonForConsultation")}
            type="text"
            value={motifConsultation}
            onChange={(e) => setMotifConsultation(e.target.value)}
            onBlur={(e) => setMotifConsultation(e.target.value)}
            className="w-full mb-2.5 rounded-lg border-gray-200 outline-none"
            inlineLabel={false}
            onfocus={true}
          />

          {/* Book New Meet */}
          {pickedDate && !_.isEmpty(pickedDate) ? (
            <>
              <div className="my-5">
                <p className="flex items-center">
                  {t("MyDoctors.MedicalAppointmentFor")}
                  <span className="bg-gray-200 rounded-lg p-2 text-blue-500 font-bold text-xs ml-2">
                    {moment(pickedDate.time).format("DD/MM/YYYY")}
                  </span>
                  <span className="mx-2">{t("MyDoctors.at")} </span>
                  <span className="bg-gray-200 rounded-lg p-2 text-blue-500 font-bold text-xs">
                    {moment(pickedDate.time).format("HH:mm")}
                  </span>
                  <Icon isIcon={false} name="tick" className="ml-3" />
                </p>
              </div>

              {/* Confirmation book meeting*/}
              <div className="ml-5">
                <p className="text-sm font-bold">{t("MyDoctors.info1")}</p>
                <p>
                  {" "}
                  {t("MyDoctors.info2")}
                  <br /> {t("MyDoctors.info3")}
                </p>
              </div>

              <div className="flexCenter flex-col space-y-5 my-7">
                <Button
                  height="50px"
                  width="220px"
                  onClick={() => {
                    if (!motifConsultation)
                      return makeToast("warning", t("MyDoctors.EnterMotif"));
                    if (!pickedDate?.time)
                      return makeToast("warning", t("MyDoctors.EnterDate"));
                    openConfirmTeleconsultationModal({
                      dateConsultation: pickedDate?.time,
                      timeSlotId: pickedDate?.timeslotId,
                      motif: motifConsultation,
                      doctorId: selectSpecialist._id,
                      type: teleconsultation
                        ? "teleconsultation"
                        : "presentiel",
                    });
                  }}
                  disabled={btnSubmitStatusLoading}
                >
                  <div className="relative">
                    {btnSubmitStatusLoading && (
                      <div className="absolute h-full w-full flexCenter">
                        <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-purple-500"></div>
                      </div>
                    )}
                    {t("MyDoctors.TakeMeeting")}
                  </div>
                </Button>

                <p className="menu text-gray-500" onClick={() => closeModal()}>
                  {" "}
                  {t("MyDoctors.Cancel")}{" "}
                </p>
              </div>
            </>
          ) : (
            <DoctorTimeSlots
              withResult={true}
              handlePickDate={handlePickDate}
              doctorDetails={selectSpecialist}
              maxCol={maxRow}
              cls=""
              teleconsultation={teleconsultation}
            />
          )}
        </>
      ) : (
        <>
          <div className="rounded bg-gray-100 flex mt-10">
            <div className=" flex justify-center pl-10 flex-col space-y-3 py-3">
              {selectSpecialist?.address && (
                <div className="flex">
                  <Icon name="localisation" isIcon={true} />

                  <div className="ml-2">
                    <p className="text-sm font-bold w-52">
                      {selectSpecialist?.address}
                    </p>
                  </div>
                </div>
              )}
              <div>
                {selectSpecialist?.phoneNumber && (
                  <div className="flex">
                    <img
                      crossOrigin="anonymous"
                      referrerPolicy="origin"
                      src={phone}
                      alt="phone"
                      className="w-4 h-4"
                    />

                    <span className="text-sm ml-2">
                      {selectSpecialist?.phoneSuffix}{" "}
                      {selectSpecialist?.phoneNumber}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* consultation Motif */}
          <h3 className="uppercase mt-7 mb-3">{t("MyDoctors.MotifMeeting")}</h3>
          <FloatInput
            placeholder={t("MyDoctors.ReasonForConsultation")}
            labelText={t("MyDoctors.ReasonForConsultation")}
            type="text"
            value={motifConsultation}
            onChange={(e) => setMotifConsultation(e.target.value)}
            onBlur={(e) => setMotifConsultation(e.target.value)}
            className="w-full mb-2.5 rounded-lg border-gray-200 outline-none"
            inlineLabel={false}
            onfocus={true}
          />

          {/* consultation date */}
          <h3 className="uppercase mt-7 mb-3">{t("MyDoctors.Date")}</h3>
          {!motifConsultation && (!pickedDate || _.isEmpty(pickedDate)) && (
            <p className="my-3 card-subtitle">
              {t("MyDoctors.SelectMotifToShowAvailibility")}
            </p>
          )}

          {/* Book New Meet */}
          {pickedDate && !_.isEmpty(pickedDate) ? (
            <>
              <div className="my-5 hidden md:block">
                <p className="flex items-center">
                  {" "}
                  {t("MyDoctors.MedicalAppointmentFor")}
                  <span className="bg-gray-200 rounded-lg p-2 text-blue-500 font-bold text-xs ml-2">
                    {moment(pickedDate.time).format("DD/MM/yyyy")}
                  </span>
                  <span className="mx-2">{t("MyDoctors.at")} </span>
                  <span className="bg-gray-200 rounded-lg p-2 text-blue-500 font-bold text-xs">
                    {moment(pickedDate.time).format("HH:mm")}
                  </span>
                  <Icon isIcon={false} name="tick" className="ml-3" />
                </p>
              </div>

              <div className="my-5 block md:hidden">
                <p className="inline ">
                  {t("MyDoctors.MedicalAppointmentFor")}
                  <span className="bg-gray-200 rounded-lg p-2 text-blue-500 font-bold text-xs ml-2">
                    {moment(pickedDate.time).format("DD/MM/yyyy")}
                  </span>
                  <span className="mx-2">{t("MyDoctors.at")} </span>
                  <span className="bg-gray-200 rounded-lg p-2 text-blue-500 font-bold text-xs">
                    {moment(pickedDate.time).format("HH:mm")}
                  </span>
                  <Tick className="ml-3 inline" />
                </p>
              </div>
            </>
          ) : (
            <>
              {motifConsultation && (
                <DoctorTimeSlots
                  withResult={true}
                  handlePickDate={handlePickDate}
                  doctorDetails={selectSpecialist}
                  maxCol={maxRow}
                  cls=""
                  teleconsultation={teleconsultation}
                />
              )}
            </>
          )}

          {/* Confirmation book meeting*/}
          <div className="md:ml-5">
            <p className="text-sm font-bold">{t("MyDoctors.info1")}</p>
            <p>
              {t("MyDoctors.info2")}
              <br />
              {t("MyDoctors.info3")}
            </p>
          </div>

          <div className="flexCenter flex-col space-y-5 my-7">
            <Button
              height="50px"
              width="220px"
              onClick={() => {
                if (!motifConsultation)
                  return makeToast("warning", t("MyDoctors.EnterMotif"));
                if (!pickedDate?.time)
                  return makeToast("warning", t("MyDoctors.EnterDate"));
                _handleTakeMeetPresentiel();
              }}
              disabled={btnSubmitStatusLoading}
            >
              <div className="relative">
                {btnSubmitStatusLoading && (
                  <div className="absolute h-full w-full flexCenter">
                    <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-purple-500"></div>
                  </div>
                )}
                {t("MyDoctors.TakeMeeting")}
              </div>
            </Button>

            <p
              className="menu text-gray-500 cursor-pointer"
              onClick={closeModal}
            >
              {t("MyDoctors.Cancel")}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default TakeMeet;
