/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import Button from "../../components/ButtonUI";
import Input from "../../components/FloatInput";
import "./styles.scss";
import makeToast from "../../components/Snackbar";
import { login } from "../../api/patient";
import voir from "../../assets/images/voir.png";

const ChangeTempPassword: React.FC = () => {
  const { t } = useTranslation() as any;
  const history = useHistory();
  const [passwordFormType1, setPasswordFormType1] = useState("password");
  const [passwordFormType2, setPasswordFormType2] = useState("password");

  useEffect(() => {
    const navParams: any = history.location.state;
    if (!navParams?.lastLoginParams) {
      history.replace("/patient/login");
    }
  }, [history]);

  return (
    <div className="login min-h-screen flexCenter forgetPassword">
      <div className="absolute inset-0 katomiWterMark" />
      <div className="auth-login-Container mt-4 ">
        {/* <div className="logo-bloc-katomi flexRowCenter space-x-3 ">
          <img crossOrigin = "anonymous"  referrerPolicy="origin" src={logo} alt="logo" />
          <p className="logo_text">Katomi</p>
        </div> */}

        <div className="flexColCenter">
          <h1 className="title flexCenter text-center">
            {t("SignIn.ChangeTempPasswordTitle")}
          </h1>
          <h3 className="text-sm text-white text-center -mt-7 mb-5 max-w-md">
            {t("SignIn.ChangeTempPasswordDesc")}
          </h3>
        </div>

        <div className="bg-white rounded-2xl p-5 form-forget-password flexCenter ">
          {/* start */}
          <Formik
            initialValues={{
              password: "",
              confirmPassword: "",
            }}
            onSubmit={async (values) => {
              try {
                const regexPassword =
                  /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{10,}$/;

                if (!regexPassword.test(values.password))
                  return makeToast("warning", t("SignIn.pswConstraint"));
                if (values.password !== values.confirmPassword)
                  return makeToast(
                    "warning",
                    t("SignIn.pswConfirmedConstraint")
                  );

                const resetResponse = await login({
                  ...(history.location.state as any)?.lastLoginParams,
                  newPassword: values.password,
                });

                if (resetResponse.status === "success") {
                  makeToast("success", t("SignIn.SuccessChangePassword"));
                  history.push("/patient/login");
                } else {
                  makeToast("error", t("SignIn.ErrorChangePassword"));
                }
              } catch (error) {
                makeToast("error", t("SignIn.ErrorChangePassword"));
              }
            }}
          >
            {(props) => {
              const {
                values,
                // errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props;

              return (
                <>
                  <form onSubmit={handleSubmit} className="w-11/12">
                    <>
                      <div className="relative">
                        <Input
                          className="pr-10"
                          id="password"
                          type={passwordFormType1}
                          labelText={t("SignIn.NewPSW")}
                          placeholder={t("SignIn.NewPSW")}
                          onChange={handleChange}
                          value={values.password}
                          onBlur={handleBlur}
                          inlineLabel={false}
                          onfocus={true}
                        />
                        <img
                          crossOrigin="anonymous"
                          referrerPolicy="origin"
                          src={voir}
                          alt="voir"
                          className="absolute right-3 top-6 w-4 h-3 cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault();
                            setPasswordFormType1(
                              passwordFormType1 === "password"
                                ? "text"
                                : "password"
                            );
                          }}
                        />
                      </div>
                      <div className="relative">
                        <Input
                          className="pr-10"
                          id="confirmPassword"
                          type={passwordFormType2}
                          labelText={t("SignIn.ConfirmNewPSW")}
                          placeholder={t("SignIn.ConfirmNewPSW")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inlineLabel={false}
                          onfocus={true}
                        />
                        <img
                          crossOrigin="anonymous"
                          referrerPolicy="origin"
                          src={voir}
                          alt="voir"
                          className="absolute right-3 top-6 w-4 h-3 cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault();
                            setPasswordFormType2(
                              passwordFormType2 === "password"
                                ? "text"
                                : "password"
                            );
                          }}
                        />
                      </div>
                    </>

                    <div className="flexRowCenter">
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        status="primary"
                        className="btn mt-3"
                        width="250px"
                        height="40px"
                      >
                        {t("SignIn.Confirm")}
                      </Button>
                    </div>
                  </form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ChangeTempPassword;
