/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { InputGroup, Switch } from "@chakra-ui/react";
import { FormGroup } from "./FormGroup";
import { useField } from "../../../hooks/FormGeneratorHooks";

export const SwitchInput = (props) => {
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    otherProps,
  } = useField(props);

  const { required, name } = props;

  const { children, label, type, placeholder, helper, ...rest } = otherProps;
  const [isTouched, setIsTouched] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted);
  useEffect(() => {
    setValue(props.value);
  }, []);

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const formGroupProps = {
    errorMessage,
    helper,
    id,
    isRequired: !!required,
    label,
    showError,
    name,
    ...rest,
  };

  return (
    <FormGroup {...formGroupProps}>
      <InputGroup className="-mb-4 ">
        <Switch
          name={name}
          defaultIsChecked={props.value}
          onChange={(e) => setValue(e.target.checked)}
          // onBlur={() => setIsTouched(true)}
          className={value ? "switch__track" : ""}
          colorScheme="none"
          size="md"
          isDisabled={props.disabled ? true : false}
        />
      </InputGroup>
      {children}
    </FormGroup>
  );
};
