import React from "react";

const StyleComponent = ({ listData, setListData, selectedInputId }) => {
  const [selectedStyle, setSelectedStyle] = React.useState([]) as any;
  const changeStyle = (target) => {
    const getTextObj = listData.find((e) => e.id === selectedInputId);
    const copyData = listData.filter((e) => e.id !== selectedInputId);
    setListData([
      ...copyData,
      {
        ...getTextObj,
        [target]: !getTextObj[target],
      },
    ]);
  };
  return (
    <>
      <div>
        <button
          style={{ width: "40px", height: "40px" }}
          onClick={() => {
            changeStyle("i");
            setSelectedStyle((state) =>
              state.includes("i")
                ? state.filter((e) => e !== "i")
                : [...state, "i"]
            );
          }}
          className={selectedStyle.includes("i") && "bg-gray-200 rounded-xl"}
        >
          I
        </button>
      </div>
      <div>
        <button
          onClick={() => {
            changeStyle("b");
            setSelectedStyle((state) =>
              state.includes("b")
                ? state.filter((e) => e !== "b")
                : [...state, "b"]
            );
          }}
          style={{ width: "40px", height: "40px" }}
          className={selectedStyle.includes("b") && "bg-gray-200 rounded-xl"}
        >
          B
        </button>
      </div>
      <div>
        <button
          onClick={() => {
            changeStyle("u");
            setSelectedStyle((state) =>
              state.includes("u")
                ? state.filter((e) => e !== "u")
                : [...state, "u"]
            );
          }}
          style={{ width: "40px", height: "40px" }}
          className={selectedStyle.includes("u") && "bg-gray-200 rounded-xl"}
        >
          U
        </button>
      </div>
    </>
  );
};

export default StyleComponent;
