import { Box, Grid, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ButtonUI from "../../../ButtonUI";
import { PageLayout } from "./PageLayout";
import { useForm } from "../../../../hooks/FormGeneratorHooks";

export const MultiStepsLayout = ({
  children,
  submitLabel = "Submit",
  ...props
}) => {
  const { t } = useTranslation() as any;
  const form = useForm({ subscribe: "form" });
  const hasSteps = !!form?.steps?.length;

  return (
    <PageLayout {...props}>
      <form noValidate onSubmit={hasSteps ? form.submitStep : form.submit}>
        {children}

        {hasSteps && (
          <Grid templateColumns="1fr 2fr 1fr" alignItems="center">
            {!form.isFirstStep && (
              <Button
                rounded="lg"
                bg={"white"}
                className="mr-3 px-4 py-2 w-auto btn-secondary "
                onClick={() => form.prevStep()}
              >
                <p className="font-normal"> {t("Survey.Previous")}</p>
              </Button>
            )}
            <Box
              gridColumn="2"
              textAlign="center"
              fontSize="sm"
              color="gray.500"
            >
              {t("Survey.Page")} {(form.currentStep?.index ?? 0) + 1} /{" "}
              {form.steps?.length}
            </Box>
            <ButtonUI
              status="primary"
              className="mr-3 px-4 py-2 w-auto "
              type="submit"
              disabled={
                (form.isLastStep ? !form.isValid : !form.isStepValid) &&
                form.isStepSubmitted
              }
            >
              {form.isLastStep ? submitLabel : "Suivant"}
            </ButtonUI>
          </Grid>
        )}
      </form>
    </PageLayout>
  );
};
