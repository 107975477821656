/* eslint-disable eqeqeq */
/* eslint-disable no-useless-concat */
/* eslint-disable no-eval */
/* eslint-disable array-callback-return */
import JsBarcode from "jsbarcode";
import { v4 as uuidv4 } from "uuid";
import QRCode from "qrcode";
import FakeKeyPatient from "../StaticData/FakeKeyPatient.json";
import FakeKeyDoctor from "../StaticData/FakeKeyDoctor.json";
import { SPACING_FONT_SIZE_By_Y } from "./constants";
import moment from "../../../helper/moment";

/**
 * This function is triggred when you click button of tool box
 * @param item
 * @param listData
 * @function setListData
 * @function setUrlCodeQR
 */
export const AddItemToCanvas = (item, listData, setListData, setUrlCodeQR) => {
  if (item.type === "QrCode") {
    if (!listData.find((e) => e.type === "QrCode")) {
      setListData([
        ...listData,
        {
          ...item,
          id: uuidv4(),
          b64: textToBase64qrcode("sample text", setUrlCodeQR),
        },
      ]);
    } else {
      alert(`You can only add 1 ${item.type}`);
    }
  } else if (item.type === "redaction") {
    if (item.spec === "end") {
      if (!listData.find((e) => item.spec === e.spec && item.type === e.type)) {
        setListData([
          ...listData,
          {
            ...item,
            id: uuidv4(),
          },
        ]);
      } else {
        alert(`You can only add 1 ${item.type}`);
      }
    } else {
      if (!listData.find((e) => item.spec === e.spec && item.type === e.type)) {
        setListData([
          ...listData,
          {
            ...item,
            id: uuidv4(),
          },
        ]);
      } else {
        alert(`You can only add 1 ${item.type}`);
      }
    }
  } else if (item.type === "BarCode") {
    setListData([
      ...listData,
      {
        ...item,
        id: uuidv4(),
        b64: textToBase64Barcode("sample text"),
      },
    ]);
  } else {
    setListData([...listData, { ...item, id: uuidv4() }]);
  }
};
export const TextAreaEdit = (e, setInputValue) => setInputValue(e.target.value);
export const checkDeselect = (
  e,
  setSelectedInputId,
  setIselected,
  setDataSelectedObj
) => {
  const clickedOnEmpty = e.target === e.target.getStage();
  if (clickedOnEmpty) {
    setSelectedInputId("");
    setIselected(false);
    setDataSelectedObj({});
  }
};
export const textToBase64qrcode = (text, setUrlCodeQR) => {
  let canvas = document.createElement("canvas");
  let b64;
  QRCode.toDataURL(canvas, text, function (error) {
    if (error) console.error(error);
    b64 = canvas.toDataURL("image/png");
  });
  setUrlCodeQR(b64);
  return b64;
};
export const textToBase64qrcodeRenderUrlPatient = (text) => {
  let canvas = document.createElement("canvas");
  let b64;
  QRCode.toDataURL(canvas, text, function (error) {
    if (error) console.error(error);
    b64 = canvas.toDataURL("image/png");
  });
  return b64;
};
export const textToBase64Barcode = (text) => {
  var canvas = document.createElement("canvas");
  JsBarcode(canvas, text, {
    format: "CODE128",
    displayValue: false,
  });
  const b64 = canvas.toDataURL("image/png");
  return b64;
};
export const handleColorChange = (
  color,
  setTextColor,
  listData,
  selectedInputId,
  setListData
) => {
  setTextColor(color.hex);
  const getTextToResizeObj = listData.find((e) => e.id === selectedInputId);
  const copyData = listData.filter((e) => e.id !== selectedInputId);
  setListData([
    ...copyData,
    {
      ...getTextToResizeObj,
      color: color.hex,
    },
  ]);
};
/**
 * this function is used where a line text is needed to write on JSPDF
 * @param doc
 * @param textObject
 * @param startY
 * @param startX
 * @param fontSize
 * @param lineSpacing
 * @param data
 * @param text
 * @param listData
 * @param sharedY
 */
export const printCharacters = (
  doc,
  textObject,
  startY,
  startX,
  fontSize,
  lineSpacing,
  data,
  text,
  //sumRows,
  listData,
  sharedY
) => {
  let options = {};

  switch (data["text-align"]) {
    case data["text-align"] === "center":
      options = {
        ...options,
        align: "center",
      };
      break;
    case data["text-align"] === "right":
      options = {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ...options,
        align: "right",
      };
      break;
    default:
      break;
  }

  const startXCached = startX;
  let startYCached = startY;
  const boldStr = "bold";
  const normalStr = "normal";

  textObject.map((row) => {
    //totalRows += 1;

    let widthRow = doc.getTextWidth(text);
    if (data["text-align"] === "center") {
      startX = (doc.internal.pageSize.width - widthRow) / 2;
    }

    Object.entries(row).map(([key, value]: any) => {
      doc.setFont(
        "Arial",
        value.italic ? "italic" : normalStr,
        value.bold ? boldStr : normalStr
      );

      doc.setFontSize(12).text(value.char, startX, startYCached);
      startX = startX + doc.getStringUnitWidth(value.char) * 4.67;
    });
    startX = startXCached;
    startYCached += lineSpacing;
  });
  //return {startY}
};

const getTextFromStaticPatientOrDoctor = (t, data, role, FakeData) => {
  let newChaine = "";
  const d = t.indexOf("{{");
  const f = t.indexOf("}}");
  let formatedNewText = t.slice(d + 2, f);
  if (
    t.includes("{{") &&
    t.includes("}}") &&
    t.includes(".") &&
    // t.includes(role) &&
    data
  ) {
    let getRealBDField = FakeData[formatedNewText].split(".") || [];
    if (getRealBDField.includes("INS")) {
      newChaine +=
        (eval("data" + "." + getRealBDField.join("?.")) !== undefined
          ? eval("data" + "." + getRealBDField.join("?."))
          : "N/A") + " ";
    } else {
      newChaine += (data[getRealBDField.join(".")] ?? "") + " ";
    }
  } else if (
    t.includes("{{") &&
    t.includes("}}") &&
    // t.includes(role) &&
    !t.includes("INS") &&
    data
  ) {
    let getRealBDField = FakeData[formatedNewText] || [];
    if (getRealBDField.includes("birthDate")) {
      newChaine += moment(data[getRealBDField]).format("YYYY/MM/DD") + " ";
    } else if (getRealBDField.includes("sexe")) {
      data[getRealBDField] === "woman"
        ? (newChaine += "Femme" + " ")
        : (newChaine += "Homme" + " ");
    } else {
      data[getRealBDField] && (newChaine += data[getRealBDField] + " ");
    }
  } else {
    newChaine += t + " ";
  }
  return newChaine;
};
export const ItemKonvaToJsPDF = (
  itemPDF,
  multiplier,
  pdf,
  numberPage,
  docInfoConnected,
  patientInfo
) => {
  itemPDF.map(async (e) => {
    if (e.type === "text") {
      let stringFromJson = "";
      e.title.split(" ").map((t) => {
        if (t !== "") {
          if (t.includes("PATIENT")) {
            stringFromJson += getTextFromStaticPatientOrDoctor(
              t,
              patientInfo,
              "PATIENT",
              FakeKeyPatient
            );
          } else {
            stringFromJson += getTextFromStaticPatientOrDoctor(
              t,
              docInfoConnected,
              "DOCTEUR",
              FakeKeyDoctor
            );
          }
        }
      });
      stringFromJson.trim();
      const splitTextMarks = pdf.splitTextToSize(
        stringFromJson,
        e.width / multiplier,
        {
          fontSize: e.size,
        }
      );
      let position = 0;
      let charsMapLength = 0;
      const mapRows = splitTextMarks.map((row, i) => {
        const splitRow = row.split("");
        const chars = splitRow.map((char, j) => {
          position = charsMapLength + j + i;
          let currentChar = stringFromJson.charAt(position);

          return { char: currentChar };
        });
        charsMapLength += splitRow.length;
        return { ...chars };
      });
      let startX = e.x;
      let startXCached = e.x;
      let startY = e.y;

      /* switch(e.size){
        case 32:
          if(e.y<25) startY=26
          break;
        case 28:
          if(e.y<20) startY=22
          break;
          case 28:
            if(e.y<16) startY=16
            break;
            case 24:
              if(e.y<18) startY=18
              break;
              case 20:
                if(e.y<20) startY=9
                break;
        default:
          break;
      }*/

      const textWidth = pdf.getTextWidth(stringFromJson);
      const textH = pdf.getLineHeight();

      if (e.repeatText) {
        mapRows.map((row) => {
          Object.entries(row).map(([key, value]: any) => {
            pdf.setFontSize(e.size ? Math.trunc(e.size) : 12);
            pdf.setTextColor(e.color ? e.color : "#000");

            if (e.fontFamilly === "Ubuntu") {
              pdf.addFont(
                "/fonts/Ubuntu-Regular.ttf",
                "Ubuntu-Regular",
                "Ubuntu-Regular-normal",
                "normal"
              );
            } else if (e.fontFamilly === "EduNSWACTFoundation") {
              pdf.addFont(
                "EduNSWACTFoundation-Regular.ttf",
                "EduNSWACTFoundation",
                "normal"
              );
            } else if (e.fontFamilly === "DancingScript") {
              pdf.addFont(
                "DancingScript-VariableFont_wght.ttf",
                "DancingScript-VariableFont_wght",
                "normal"
              );
            }
            pdf.setFont(
              e.fontFamilly ? e.fontFamilly : "Arial",
              e.i ? "italic" : "",
              e.b ? "bold" : ""
            );
            const lineY = startY + textH / 2;
            if (!isNaN(lineY)) {
              pdf.text(startX / multiplier, lineY / multiplier, value.char);
            }
            //after color reset
            pdf.setTextColor("#000");
            //pdf.text(value.char, startX, startY);
            startX = startX + pdf.getStringUnitWidth(value.char) * e.size;
          });
          const colors = hexToRgbA(e.color);
          let debut = colors.indexOf("(") + 1;
          let fin = colors.indexOf(")");
          let rgbColors = colors.slice(debut, fin).split(",");
          pdf.setDrawColor(
            parseInt(rgbColors[0]),
            parseInt(rgbColors[1]),
            parseInt(rgbColors[2])
          );
          pdf.setLineWidth(0.25);
          e.u &&
            pdf.line(
              e.x / multiplier,
              (e.y + textH) / multiplier,
              e.x / multiplier + textWidth,
              (e.y + textH) / multiplier
            );
          startX = startXCached;
          startY += SPACING_FONT_SIZE_By_Y[e.size];
        });
        pdf.setFont("Arial", "", "");
        pdf.setTextColor("#000");
      } else if (!e.repeatText && numberPage < 1) {
        mapRows.map((row) => {
          Object.entries(row).map(([key, value]: any) => {
            pdf.setFontSize(e.size ? Math.trunc(e.size) : 12);
            pdf.setTextColor(e.color ? e.color : "#000");
            pdf
              .setFont(
                e.fontFamilly ? e.fontFamilly : "Arial",
                e.i ? "italic" : "",
                e.bold ? "bold" : ""
              )
              .text(startX / multiplier, startY / multiplier, value.char);
            //after color reset
            pdf.setTextColor("#000");
            //pdf.text(value.char, startX, startY);
            startX = startX + pdf.getStringUnitWidth(value.char) * e.size;
          });
          pdf.setDrawColor(0);
          pdf.setLineWidth(0.25);
          e.u &&
            pdf.line(
              e.x / multiplier,
              (e.y + textH) / multiplier,
              e.x / multiplier + textWidth,
              (e.y + textH) / multiplier
            );
          startX = startXCached;
          startY += 10;
        });
        //after color reset
        pdf.setTextColor("#000");
      }
    } else if (e.type === "QrCode" || e.type === "BarCode") {
      const rendredQrCodeOfConsultation = textToBase64qrcodeRenderUrlPatient(
        window.location.href
      );
      pdf.addImage(
        e.type === "QrCode" ? rendredQrCodeOfConsultation : e.b64,
        "png",
        e.x / multiplier,
        e.y / multiplier,
        e.width / multiplier,
        e.height / multiplier
      );
    } else if (e.type === "image") {
      const corsImageModified = new Image();
      corsImageModified.crossOrigin = "Anonymous";
      corsImageModified.src = e.url;
      pdf.saveGraphicsState();
      pdf.setGState(new pdf.GState({ opacity: e.opacity / 10 }));
      pdf.addImage(
        e.b64 || e.url,
        "PNG",
        e.x / multiplier,
        e.y / multiplier,
        e.width / multiplier,
        e.height / multiplier,
        "",
        "FAST"
      );
      pdf.restoreGraphicsState();
    } else {
      if (e.type !== "redaction") {
        pdf.setFontSize(e.font ? Math.trunc(e.font) : 12);
        pdf.text(e.x / multiplier, e.y / multiplier, e.title);
      }
    }
  });
};

const hexToRgbA = (hex) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)"
    );
  } else {
    c = "#000000".substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)"
    );
  }
};
