/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useEffect, useRef, useState } from "react";
import { isEmpty } from "lodash";
import io from "socket.io-client";
import Peer from "simple-peer";
import Icon from "../../components/Icon";
import { FiVideoOff, FiVideo } from "react-icons/fi";
import { AiOutlineAudioMuted, AiOutlineAudio } from "react-icons/ai";
import { useAtom } from "jotai";
import { userAtom } from "../../state/auth";

const KatomiEndPoint =
  process.env.REACT_APP_KATOMI_API_URL || "http://localhost:8080";

const Video = (props) => {
  const { peer, setSelectedVideo, peerID, selectedVideo } = props;
  const ref = useRef() as any;

  useEffect(() => {
    peer?.on("stream", (stream) => {
      ref.current.srcObject = stream;
    });
    if (peer?.streams?.length > 0 && ref.current) {
      ref.current.srcObject = peer.streams[0];
    }
  }, [peer, ref]);

  return ref ? (
    <video
      playsInline
      autoPlay
      ref={ref}
      onClick={setSelectedVideo}
      className={peerID == selectedVideo ? "fullVideo" : ""}
    />
  ) : null;
};

const Room = (props) => {
  const [user] = useAtom(userAtom) as any;
  const [peers, setPeers] = useState([]) as any;
  const [audioFlag, setAudioFlag] = useState(true);
  const [videoFlag, setVideoFlag] = useState(true);
  const [selectedVideo, setSelectedVideo] = useState("my");

  const [userUpdate, setUserUpdate] = useState([]) as any;
  const socketRef = useRef() as any;
  const userVideo = useRef() as any;
  const peersRef = useRef([]) as any;
  const roomID = props.idConsultation;
  const videoConstraints = {
    minAspectRatio: 1.333,
    minFrameRate: 60,
    height: window.innerHeight / 1.8,
    width: window.innerWidth / 2,
  };

  const socketIO = io as any;
  useEffect(() => {
    socketRef.current = socketIO.connect(KatomiEndPoint, {
      withCredentials: true,
    });
    createStream();
    return () => socketRef?.current?.on("disconnect", () => {});
  }, []);

  function createStream() {
    navigator.mediaDevices
      .getUserMedia({ video: videoConstraints, audio: true })
      .then((stream) => {
        userVideo.current.srcObject = stream;
        socketRef.current.emit("join room", roomID);
        socketRef.current.on("all users", (users, usersName) => {
          const NewPeers = [] as any;
          users.forEach((userID) => {
            const peer = createPeer(userID, socketRef.current.id, stream);
            // peersRef.current.push({
            //   peerName:
            //     usersName?.find((el) => el.socketId === userID)?.fullName || "",
            //   peerUserId:
            //     usersName?.find((el) => el.socketId === userID)?.userId || "",
            //   peerID: userID,
            //   peer,
            // });
            NewPeers.push({
              peerName:
                usersName?.find((el) => el.socketId === userID)?.fullName || "",
              peerUserId:
                usersName?.find((el) => el.socketId === userID)?.userId || "",
              peerID: userID,
              peer,
            });
          });
          setPeers(NewPeers);
        });
        socketRef.current.on("user joined", (payload) => {
          const peer = addPeer(payload.signal, payload.callerID, stream);
          // peersRef.current.push({
          //   peerName: payload.fullName,
          //   peerUserId: payload.userId,
          //   peerID: payload.callerID,
          //   peer,
          // });
          const peerObj = {
            peer,
            peerID: payload.callerID,
            peerName: payload.fullName,
            peerUserId: payload.userId,
          };
          setPeers((users) => [...users, peerObj]);
        });

        socketRef.current.on("user left", ({ id, room }) => {
          const peerObj = peersRef.current.find((p) => p.peerID === id);
          if (peerObj) {
            peerObj.peer.destroy();
          }
          let filtredPeers = peersRef.current.filter((p) => p.peerID !== id);
          if (!isEmpty(room))
            filtredPeers = peersRef.current.filter((p) =>
              room.includes(p.peerID)
            );
          // peersRef.current = filtredPeers;
          // setPeers(filtredPeers);
          setPeers((prevPeers) => {
            return filtredPeers;
          });
        });

        socketRef.current.on("receiving returned signal", (payload) => {
          try {
            const item = peersRef.current.find((p) => p.peerID === payload.id);
            item.peer.signal(payload.signal);
          } catch (error) {
            console.log(error);
          }
        });

        socketRef.current.on("change", (payload) => {
          setUserUpdate(payload);
        });
      });
  }

  function createPeer(userToSignal, callerID, stream) {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream,
    });

    peer.on("signal", (signal) => {
      socketRef.current.emit("sending signal", {
        userToSignal,
        callerID,
        signal,
        fullName: `${user.firstName} ${user.lastName}`,
        userId: user._id,
      });
    });

    return peer;
  }

  function addPeer(incomingSignal, callerID, stream) {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream,
    });

    peer.on("signal", (signal) => {
      socketRef.current.emit("returning signal", { signal, callerID });
    });

    peer.signal(incomingSignal);

    return peer;
  }

  function handleControlVideo() {
    if (userVideo.current.srcObject) {
      userVideo.current.srcObject.getTracks().forEach(function (track) {
        if (track.kind === "video") {
          if (track.enabled) {
            socketRef.current.emit("change", [
              ...userUpdate,
              {
                id: socketRef.current.id,
                videoFlag: false,
                audioFlag,
              },
            ]);
            track.enabled = false;
            setVideoFlag(false);
          } else {
            socketRef.current.emit("change", [
              ...userUpdate,
              {
                id: socketRef.current.id,
                videoFlag: true,
                audioFlag,
              },
            ]);
            track.enabled = true;
            setVideoFlag(true);
          }
        }
      });
    }
  }

  function handleControlAudio() {
    if (userVideo.current.srcObject) {
      userVideo.current.srcObject.getTracks().forEach(function (track) {
        if (track.kind === "audio") {
          if (track.enabled) {
            socketRef.current.emit("change", [
              ...userUpdate,
              {
                id: socketRef.current.id,
                videoFlag,
                audioFlag: false,
              },
            ]);
            track.enabled = false;
            setAudioFlag(false);
          } else {
            socketRef.current.emit("change", [
              ...userUpdate,
              {
                id: socketRef.current.id,
                videoFlag,
                audioFlag: true,
              },
            ]);
            track.enabled = true;
            setAudioFlag(true);
          }
        }
      });
    }
  }

  useEffect(() => {
    peersRef.current = peers;
  }, [peers, peersRef]);

  return (
    <div className="webrtc">
      <div className="control-call-container">
        <div
          className="w-14 h-14 rounded-full bg-white shado shadow-black  flexCenter cursor-pointer relative"
          onClick={handleControlVideo}
        >
          {videoFlag ? <FiVideo size={22} /> : <FiVideoOff size={22} />}
        </div>

        <div
          className="w-14 h-14 rounded-full bg-red-500 shadow-black flexCenter cursor-pointer relative"
          onClick={props.endCall}
        >
          <Icon
            isIcon={true}
            name="tel"
            style={{
              filter:
                "invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%)",
            }}
          />
        </div>
        <div
          className="w-14 h-14 rounded-full bg-white shado shadow-black  flexCenter cursor-pointer relative"
          onClick={handleControlAudio}
        >
          {audioFlag ? (
            <AiOutlineAudio size={22} />
          ) : (
            <AiOutlineAudioMuted size={22} />
          )}
        </div>
      </div>
      <div className={selectedVideo === "my" ? "w-full" : "relative max-w-max"}>
        <div className="relative max-w-max">
          <span className="absolute top-3 right-3 rounded-xl S bg-gray-700 bg-opacity-20  shadow-xl font-bold px-3 py-1 z-50">
            {`${user.firstName} ${user.lastName}`}
          </span>
          <video
            muted
            ref={userVideo}
            autoPlay
            playsInline
            onClick={() => setSelectedVideo("my")}
            className={selectedVideo === "my" ? "fullVideo" : ""}
          />
        </div>
      </div>

      {peers.map((peer: any, index) => {
        let audioFlagTemp = true;
        let videoFlagTemp = true;
        console.log(
          "🚀 ~ file: VideoCallContainer.tsx:332 ~ {peers.map ~ peer:",
          peers,
          peers.length
        );
        if (userUpdate) {
          userUpdate.forEach((entry) => {
            if (peer && peer.peerID && peer.peerID === entry.id) {
              audioFlagTemp = entry.audioFlag;
              // videoFlagTemp = entry.videoFlag;
            }
          });
        }
        return (
          <div
            className={
              peer.peerID == selectedVideo
                ? "relative -order-1 w-full"
                : "relative"
            }
          >
            <div className="relative">
              <span className="absolute top-3 right-3 rounded-md bg-gray-100 bg-opacity-50 shadow-xl font-bold px-3 py-1 z-50">
                {peer.peerName}
              </span>
              <Video
                peer={peer.peer}
                key={peer.peerID}
                selectedVideo={selectedVideo}
                peerID={peer.peerID}
                setSelectedVideo={() => setSelectedVideo(peer.peerID)}
              />
              {!audioFlagTemp && (
                <div
                  className="w-7 h-7 rounded-full bg-white shadow-black flexCenter cursor-pointer absolute top-3 left-3"
                  onClick={props.endCall}
                >
                  <AiOutlineAudioMuted size={11} />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Room;
