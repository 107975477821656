/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useAtom } from "jotai";
import moment from "../../helper/moment";
import { userAtom } from "../../state/auth";
import { socketAtom } from "../../state/socket";
import Button from "../../components/ButtonUI";
import { getOldMessages } from "../../api/chat";
// import WomanDoctor from "../../assets/doctor.png";
import "../Teleconsultation/styles.scss";
import { useTranslation } from "react-i18next";
interface IProps {
  chatWithId: string;
  consultationDetails: any;
}

const ChatTeleConsultation: React.FC<IProps> = ({
  chatWithId,
  consultationDetails,
}) => {
  const { t } = useTranslation() as any;
  const blockMessageRef = useRef<any>();
  const [user] = useAtom<any>(userAtom);
  const [socket] = useAtom<any>(socketAtom);
  const [messages, setMessages] = useState<any>([]);
  const [displayMessage, setDisplayMessage] = useState<any>([]);
  const messageRef = useRef<any>();
  const chatroomId = consultationDetails?._id;

  const sendMessage = () => {
    if (socket) {
      socket.emit("teleConsultationChatroomMessage", {
        sentFrom: user?._id,
        sentTo: chatWithId,
        message: messageRef.current.value,
        onModelFrom: "Patient",
        onModelTo: "Doctor",
        chatroomId,
      });
      messageRef.current.value = "";
    }
  };

  // TODO Remove this setInterval & fixe socket
  // useEffect(() => {
  //   SetInterval(
  //     setInterval(async () => {
  //       setCounter(counter + 1);
  //       const response = await getOldMessages(chatroomId);
  //       if (!_.isEqual(response, messages)) setMessages(response);
  //     }, 1000)
  //   );
  //   return () => clearInterval(interval);
  // }, [counter]);

  //get All Old Messages
  useEffect(() => {
    async function _getOldMessage() {
      const response = await getOldMessages(chatroomId);
      setMessages(response);
    }
    _getOldMessage();
  }, [chatroomId]);

  // get RealTime New Messages
  useEffect(() => {
    if (socket) {
      socket.on("newTempMessage", (message) => {
        const newMessages = [...messages, message];
        setMessages(newMessages);
      });
    }

    //eslint-disable-next-line
  }, [messages, socket]);

  // Group Message By Date
  useEffect(() => {
    if (messages && messages.length > 0) {
      const groups = messages.reduce((groups, el) => {
        if (!groups[el.date]) {
          groups[el.date] = [];
        }
        groups[el.date].push(el);
        return groups;
      }, {});
      const groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          data: groups[date],
        };
      });
      setDisplayMessage(groupArrays);
    }
  }, [messages]);

  //Socket Join Chat Room
  useEffect(() => {
    if (socket) {
      socket.emit("joinRoom", {
        chatroomId,
      });
    }
    return () => {
      //Component Unmount
      if (socket) {
        socket.emit("leaveRoom", {
          chatroomId,
        });
      }
    };
    //eslint-disable-next-line
  }, [socket]);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    blockMessageRef.current?.scrollIntoView({});
  }, [messages, displayMessage, blockMessageRef]);

  return (
    <div className="chat-box w-full h-full m-2 flex flex-col pr-3 chat-teleconsultation ">
      <div
        className="flex flex-col justify-end h-full  m-3 space-y-3"
        style={{ maxHeight: "67vh" }}
      >
        <div className=" overflow-y-auto ">
          {displayMessage &&
            displayMessage.length > 0 &&
            displayMessage.map((el, i) => (
              <div className="relative" key={i}>
                {/* <span className="absolute card-subtitle">Dernier message</span> */}
                <hr
                  className="hr-center-text flex-1"
                  data-content={moment().format("dddd DD MMMM")}
                />
                {el.data.map(
                  (
                    { from, date, type, message, folder, avatar, ...rest },
                    index
                  ) => (
                    <div key={index}>
                      <div
                        className={`flex items-center space-x-2 my-2  ${
                          rest?.sentFrom == user?._id && "flex-row-reverse "
                        }`}
                      >
                        {rest?.sentFrom != user?._id && (
                          <span className="avatar-chat">
                            {/* <img crossOrigin = "anonymous"  referrerPolicy="origin" src={WomanDoctor} alt="doctor" /> */}
                          </span>
                        )}
                        {type === "text" && (
                          <span
                            className={`message-chat max-w-max ${
                              rest?.sentFrom == user?._id &&
                              "text-right p-2 you"
                            }`}
                          >
                            {message}
                          </span>
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
            ))}
          <div ref={blockMessageRef}></div>
        </div>
      </div>
      <div className="flex items-center px-2 mb-4">
        <input
          type="text"
          name="chat"
          className="input-chat-box w-full mx-2 pl-5"
          style={{ height: "45px" }}
          placeholder={t("Chat.TypeMsg")}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              sendMessage();
            }
          }}
          ref={messageRef}
        />
        <Button className="p-2" onClick={sendMessage}>
          {t("Chat.Envoyer")}
        </Button>
      </div>
    </div>
  );
};
export default ChatTeleConsultation;
