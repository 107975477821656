/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";

import ButtonUI from "../../../ButtonUI";
import { RenderField } from "./RenderField";
import { Formiz } from "../StepperWrapper/Formiz";

import { useForm } from "../../../../hooks/FormGeneratorHooks";

import { ReactComponent as Close } from "../../../../assets/icons/icon-close.svg";
import { useTranslation } from "react-i18next";

const PropertyEditor = (props: any) => {
  const { t } = useTranslation() as any;
  const { element, show, onClose, onSubmit, elements } = props;
  const { type, id, dropEffect, field_name, ...editableProps } = element || {
    type: null,
    id: null,
    dropEffecct: null,
    field_name: null,
  };
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(id ? id : null);

  const form = useForm();

  useEffect(() => {
    setSelected(id);
    form.setFieldsValues(editableProps);
  }, [id]);

  useEffect(() => {
    if (!visible) onClose?.();
  }, [visible, onClose]);
  useEffect(() => {
    if (show) setVisible(show);
  }, [show]);

  const handleSubmit = (values) => {
    if (values.options) {
      values.options = values.options.map((i) => {
        return { label: i.label, value: i.label };
      });
    }
    onSubmit?.(values);
    setVisible(false);
  };

  return (
    <Drawer
      isOpen={visible}
      placement="right"
      onClose={() => setVisible(false)}
      size="lg"
    >
      <DrawerOverlay />
      <DrawerContent className="rounded-2xl  " overflowY={"scroll"}>
        <ButtonUI
          status="icon-secondary"
          onClick={() => setVisible(false)}
          className="absolute right-1 top-0 "
        >
          <Close />
        </ButtonUI>
        <DrawerHeader
          style={{ fontSize: "26px" }}
          borderBottomWidth="1px"
          className="my-4"
        >
          {t("Survey.EditProperties")}
        </DrawerHeader>
        <Formiz
          key={form.resetKey}
          connect={form}
          onValidSubmit={handleSubmit}
          initialValues={editableProps}
        >
          <form noValidate onSubmit={form.submit}>
            <DrawerBody overflowX={"hidden"}>
              {Object.keys(editableProps).map((name) => {
                return RenderField({
                  name,
                  value: editableProps[name],
                  form: form,
                  elements,
                  selectedElm: selected,
                  t: t,
                  setField: form.setFieldsValues,
                });
              })}
            </DrawerBody>

            <DrawerFooter borderTopWidth="1px" justifyContent={"space-between"}>
              <ButtonUI
                className="py-2 px-8  btn-delete "
                onClick={(e) => setVisible(false)}
                status="secondary"
                type="button"
              >
                {t("SurveyIntl.cancelButton")}
              </ButtonUI>
              <ButtonUI
                height="42px"
                status="primary"
                className="py-2 px-8  h-10   "
                disabled={!form.isValid}
                type="submit"
              >
                {t("SurveyIntl.submitButton")}
              </ButtonUI>
            </DrawerFooter>
          </form>
        </Formiz>
      </DrawerContent>
    </Drawer>
  );
};
export default PropertyEditor;
