/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import _ from "lodash";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { getAllPatientName } from "../../api/doctor";
import Button from "../../components/ButtonUI";
import { doctorAssignSurvey, getAllPatientBySurveyId } from "../../api/survey";
import makeToast from "../../components/Snackbar";
import { ReactComponent as IconSearch } from "../../assets/icons/icon-search.svg";
import Input from "../../components/Input";
type Props = {
  survey: any;
  closeModal: any;
};

const AssignModal = (props: Props) => {
  const { t } = useTranslation() as any;
  const [patientName, setPatientName] = React.useState("");
  const [patientList, setPatientList] = React.useState([]) as any;
  const [selectedPatient, setSelectedPatient] = React.useState([]) as any;
  const [assignedPatient, setAssignedPatient] = React.useState([]) as any;
  const [loading, setLoading] = React.useState(false);

  const _getAllPatientName = async () => {
    try {
      setLoading(true);
      const [response] = await getAllPatientName();
      if (!_.isEmpty(response?.patients)) setPatientList(response?.patients);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const _doctorAssignSurvey = async () => {
    setLoading(true);
    await doctorAssignSurvey({
      patientId: selectedPatient,
      surveyId: props.survey?._id,
    });
    makeToast("success", t("Survey.successSendSurvey"));
    props.closeModal();
    setLoading(false);
  };

  const _getAllPatientBySurveyId = async () => {
    try {
      setLoading(true);
      const response = await getAllPatientBySurveyId(props.survey?._id);
      if (!_.isEmpty(response)) {
        setAssignedPatient(response.map((el) => el?._id));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    _getAllPatientName();
    _getAllPatientBySurveyId();
  }, []);

  return loading ? (
    <div className="flexCenter h-full">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500" />
    </div>
  ) : (
    <div className="pt-3 pr-3 pl-5 h-full">
      <h3 className="my-3"> {t("Survey.SendSurvey")}</h3>
      <div className="flex justify-between ">
        <div className="relative flex w-full my-4 flex-wrap  ">
          <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 bg-transparent rounded text-base items-center justify-center w-8 pl-1 py-1">
            <IconSearch />
          </span>
          <Input
            type="text"
            placeholder={t("Survey.FindPatient")}
            width="w-full"
            onChange={(e) => setPatientName(e.target.value)}
            className={classNames(
              "rounded-xl px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white text-sm outline-none focus:border-blue-500  border-blue-100 w-full pl-10"
            )}
          />
        </div>
        <Button
          status="secondary"
          height="49px"
          className="min-w-max px-3  my-4 "
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <div className="flex items-center  space-x-1">
            <div className="">
              <label className="container-checkbox">
                <input
                  type="checkbox"
                  checked={
                    selectedPatient.length ===
                    patientList?.filter(
                      (el) => !assignedPatient.includes(el._id)
                    ).length
                  }
                  // onChange={(e) => {
                  //   e.preventDefault();
                  //   selectedPatient.length === patientList?.length
                  //     ? setSelectedPatient([])
                  //     : setSelectedPatient(patientList.map((elm) => elm._id));
                  // }}
                />
                <span
                  className="checkmark"
                  onClick={(e) => {
                    e.preventDefault();
                    selectedPatient.length ===
                    patientList?.filter(
                      (el) => !assignedPatient.includes(el._id)
                    ).length
                      ? setSelectedPatient([])
                      : setSelectedPatient(
                          patientList
                            .filter(
                              (el) =>
                                (el.firstName
                                  ?.toLowerCase()
                                  .includes(patientName?.toLowerCase()) ||
                                  el.lastName
                                    ?.toLowerCase()
                                    .includes(patientName?.toLowerCase())) &&
                                !assignedPatient.includes(el._id)
                            )
                            .map((elm) => elm._id)
                        );
                  }}
                ></span>
              </label>
            </div>
            <span>{t("Survey.SelectAll")}</span>
          </div>
        </Button>
      </div>
      {!_.isEmpty(patientList) && (
        <div className="flex flex-col gap-3 mt-5 h-form-custom overflow-y-scroll">
          {patientList
            .filter(
              (el) =>
                el.firstName
                  ?.toLowerCase()
                  .includes(patientName?.toLowerCase()) ||
                el.lastName?.toLowerCase().includes(patientName?.toLowerCase())
            )
            .map((el) => (
              <>
                <div className="flex gap-3 items-center">
                  <label className="container-checkbox ">
                    <input
                      type="checkbox"
                      checked={
                        assignedPatient.includes(el._id) ||
                        selectedPatient.includes(el._id)
                      }
                      disabled={assignedPatient.includes(el._id)}
                    />
                    <span
                      className={
                        assignedPatient.includes(el._id)
                          ? "checkmark-disabled"
                          : "checkmark"
                      }
                      onClick={() => {
                        if (!assignedPatient.includes(el._id))
                          setSelectedPatient(
                            selectedPatient.includes(el._id)
                              ? selectedPatient.filter((elm) => el._id !== elm)
                              : selectedPatient.concat(el._id)
                          );
                      }}
                    ></span>
                  </label>
                  <p
                    className={
                      assignedPatient.includes(el._id)
                        ? "text-xl text-disabled"
                        : "text-xl"
                    }
                  >
                    {el.firstName} {el.lastName}
                  </p>
                </div>
              </>
            ))}
        </div>
      )}
      {!_.isEmpty(
        patientList?.filter(
          (el) =>
            el.firstName?.toLowerCase().includes(patientName?.toLowerCase()) ||
            el.lastName?.toLowerCase().includes(patientName?.toLowerCase())
        )
      ) && (
        <div className="flexCenter">
          <Button
            className=" my-10"
            height="45px"
            width="120px"
            onClick={_doctorAssignSurvey}
          >
            {t("Survey.Assign")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default AssignModal;
