import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/ButtonUI";
import TextArea from "../../../components/TextArea";
import "./styles.scss";
import presentationSchema from "./schemas/presentationSchema";
import { addOrModifyDescription } from "../../../api/doctor";
import { Formik } from "formik";
import { User } from "../../../types/user";
import { userAtom } from "../../../state/auth";
import { useAtom } from "jotai";
import makeToast from "../../../components/Snackbar";
import Icon from "../../../components/Icon";

interface Props {
  openModal: boolean;
  setOpenModal: Function;
  doctorId?: string;
}

export const AddPresentation: React.FC<Props> = ({
  openModal,
  setOpenModal,
}) => {
  const { t } = useTranslation() as any;
  const [user, setUser] = useAtom(userAtom);
  const updatePresentation = async (values) => {
    try {
      const newUser = await addOrModifyDescription({ ...values, user });
      const { description } = newUser;
      setUser({
        ...user,
        description,
      } as User);
      setOpenModal(!openModal);
      if (newUser._id) {
        makeToast("success", t("Profile.SuccessAddPresentation"));
      } else {
        makeToast("error", t("Profile.FailedAddPresentation"));
      }
    } catch (err) {}
  };
  return (
    <div className="descriptionWidth">
      <div className="flex justify-end cursor-pointer mt-5 mr-5 ">
        <Icon
          isIcon={true}
          name="close"
          onClick={() => setOpenModal(!openModal)}
          className="bg-gray-100 rounded-xl cursor-pointer hover:bg-gray-200"
        />
      </div>
      <Formik
        initialValues={{
          description: "" || user?.description,
        }}
        onSubmit={async (values) => {
          try {
            const newUser = await addOrModifyDescription({ ...values, user });
            const { description } = newUser;
            setUser({
              ...user,
              description,
            } as User);
            setOpenModal(!openModal);
            if (newUser._id) {
              makeToast("success", t("Profile.SuccessAddPresentation"));
            } else {
              makeToast("error", t("Profile.FailedAddPresentation"));
            }
          } catch (err) {}
        }}
        validationSchema={presentationSchema}
      >
        {(props) => {
          const {
            values,
            // errors,
            // isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;

          return (
            <form
              onSubmit={handleSubmit}
              className="flex-auto pb-7 px-8 min-w-full	"
            >
              <div className="flex flex-wrap items-baseline">
                <h1 className="mb-10"> {t("Profile.Presentation")}</h1>
              </div>
              <div className="">
                <TextArea
                  id="description"
                  placeholder={t("Profile.WriteDescription")}
                  //error={values.pricing}
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inlineLabel={false}
                  onfocus={true}
                  // stylesDiv="w-"
                />
              </div>
              <Button
                status="secondary"
                width="100px"
                type="button"
                height="50px"
                className="float-left mb-4 btn-delete"
                onClick={() => setOpenModal(false)}
              >
                {t("Profile.Cancel")}
              </Button>
              <Button
                status="secondary"
                width="100px"
                height="50px"
                className="float-right mb-4"
                onClick={() => updatePresentation(values)}
              >
                {t("Profile.Save")}
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddPresentation;
