import React, { useState } from "react";

/**
 * Helper & Api
 */
import moment from "../../helper/moment";
import history from "../../history";
import { downloadExcel } from "../../api/survey";

/**
 * Component
 */
import Modal from "../../components/ModalUI";
import AssignModal from "./AssignModal";

/**
 * Assets
 */

import { ReactComponent as Survery } from "../../assets/illustrations/survey.svg";
import ExcelIcon from "../../assets/excel.png";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const colorAvatar = [
  "#ccd2ff",
  "#d5f0f7",
  "#ffecba",
  "#fff7ab",
  "#ffccd2",
  "#ffe2c8",
];

const CardSurvey: React.FC<any> = ({ patientDetails, surveyDetails, i }) => {
  const { t } = useTranslation() as any;
  const [showModal, setShowModal] = useState(false);
  const [selectedSurvey] = useState() as any;

  const _handleDownloadExcel = async () => {
    try {
      const blob = await downloadExcel(surveyDetails?._id);
      // if (blob === "NO_RESPONSE")
      //   return makeToast(
      //     "warning",
      //     "Pas de réponse trouvée pour cette enquête."
      //   );
      // 2. Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `excel.xlsx`);
      // 3. Append to html page
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      link?.parentNode?.removeChild(link);
    } catch (error) {}
  };

  return (
    <>
      <Modal
        showModal={showModal}
        slidemodal
        closeModal={() => setShowModal(false)}
      >
        <AssignModal
          survey={selectedSurvey}
          closeModal={() => setShowModal(false)}
        />
      </Modal>

      <div
        className={`relative cursor-pointer hover:shadow card-patient justify-between bg-white `}
        style={{ zIndex: 0 }}
        onClick={() => {
          if (surveyDetails._id) {
            history.push(`creation/${surveyDetails._id}`);
          }
        }}
      >
        <div className="flex ">
          <div
            className="rounded-full h-12 w-12 flexCenter mx-5 my-auto"
            style={{
              backgroundColor: patientDetails?.photo
                ? ""
                : colorAvatar[i % colorAvatar.length],
            }}
          >
            <Survery width={37} />
          </div>
          <div className="details">
            <div className="flex-grow ">
              <p className="card-title capitalize">{surveyDetails.name}</p>
              <p className="card-subtitle">
                {t("DashboardDoctor.lastUpdate")}{" "}
                <span>
                  {moment(surveyDetails.updatedAt).format(t("Moment.Z"))}
                </span>
              </p>

              {surveyDetails.description !== "" ? (
                <p className="box-motif max-w-350 ">
                  {surveyDetails.description}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div
          className="patient-icons gap-4 mr-5 mt-3"
          style={{
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <Button
            status="icon-secondary"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowModal(true);
              setSelectedSurvey(surveyDetails);
            }}
          ></Button> */}
          {/* <img crossOrigin = "anonymous"  referrerPolicy="origin"
            src={SurveyIcon}
            alt="SurveyIcon"
            width={35}
            className="rounded-full"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowModal(true);
              setSelectedSurvey(surveyDetails);
            }}
          /> */}
          <img
            crossOrigin="anonymous"
            referrerPolicy="origin"
            src={ExcelIcon}
            alt="ExcelIcon"
            width={35}
            className=""
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              _handleDownloadExcel();
            }}
          />
          {/* <Button
            status="icon-secondary"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              _handleDownloadExcel();
            }}
          >
            Excel
          </Button> */}
        </div>
      </div>
    </>
  );
};

export default CardSurvey;
