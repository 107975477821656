import { useAtom } from "jotai";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { userAtom } from "../../state/auth";
import ModifySignature from "./modal/ModifySignature";
import Modal from "../../components/ModalUI";
import { loadImage, deleteSignature } from "../../api/documents";
import { ReactComponent as SignatureIcon } from "../../assets/signature.svg";
import Icon from "../../components/Icon";
import Button from "../../components/ButtonUI";

const Signature: React.FC = () => {
  const { t } = useTranslation() as any;
  const [user, setUser] = useAtom(userAtom) as any;
  const [modalConfirmDeletePicture, setModalConfirmDeletePicture] =
    useState(false);
  const [modalModifySignature, setModalModifySignature] = useState(false);

  const hanldeDelete = async () => {
    try {
      await deleteSignature();
      setUser({ ...user, signaturePath: null });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        showModal={modalConfirmDeletePicture}
        closeModal={() => setModalConfirmDeletePicture(false)}
      >
        <>
          <div className="flex justify-end">
            <div className="bg-gray-200 rounded-xl bg-opacity-70 cursor-pointer">
              <Icon
                isIcon={true}
                name="close"
                onClick={() => setModalConfirmDeletePicture(false)}
              />
            </div>
          </div>
          <div className="p-5">
            <h3 className="mb-4">{t("Profile.ConfirmDeleteSignature")}</h3>
            <div className="flexCenter space-x-3">
              <Button
                status="secondary"
                height="40px"
                width="120px"
                onClick={() => setModalConfirmDeletePicture(false)}
              >
                {t("Profile.Cancel")}
              </Button>
              <Button
                height="40px"
                width="120px"
                onClick={() => {
                  hanldeDelete();
                  setModalConfirmDeletePicture(false);
                }}
              >
                {t("Profile.confirm")}
              </Button>
            </div>
          </div>
        </>
      </Modal>

      {modalModifySignature && (
        <ModifySignature setModalModifySignature={setModalModifySignature} />
      )}
      <div className="signature">
        <div className="headerContainer flex items-center justify-between relative pl-10">
          {/* <img crossOrigin = "anonymous"  referrerPolicy="origin" src={langue} className="svgClass" alt="chat" /> */}
          <div className="flexCenter space-x-1">
            <SignatureIcon className="w-7" />
            <p className="header"> {t("Profile.Signature")}</p>
          </div>
          <div className="flexCenter space-x-1">
            <p
              className="	modifier"
              onClick={() => setModalModifySignature(true)}
            >
              {t("Profile.Update")}
            </p>
            <p
              className="modifier hiver:text-red-500"
              onClick={() => setModalConfirmDeletePicture(true)}
            >
              {t("Profile.Delete")}
            </p>
          </div>
        </div>
        <div className="flexCenter mt-2 mb-2">
          {user?.signaturePath && (
            <img
              crossOrigin="anonymous"
              referrerPolicy="origin"
              src={loadImage(
                user._id,
                "signature",
                "lossless",
                user.signaturePath
              )}
              alt="signature"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Signature;
