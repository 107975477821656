import axios from "axios";

import { DOCTOR_ADD_MOTIF_URL,DOCTOR_DELETE_MOTIF_URL,DOCTOR_GET_ALL_MOTIF_URL,DOCTOR_UPDATE_MOTIF_URL } from "../config/api";

export async function addMotif(values) {
  try {
    const res = await axios.post(DOCTOR_ADD_MOTIF_URL, values, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getAllByDoctor(id) {
  try {
    const res = await axios.get(`${DOCTOR_GET_ALL_MOTIF_URL}/${id}`, {
      withCredentials: true,
    });

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteMotifDoctor(id) {
    try {
      const res = await axios.delete(`${DOCTOR_DELETE_MOTIF_URL}/${id}`, {
        withCredentials: true,
      });
  
      return res.data;
    } catch (error) {
      return Promise.reject(error);
    }
}

export async function updateMotifDoctor(values) {
    try {
      const res = await axios.post(`${DOCTOR_UPDATE_MOTIF_URL}/update/${values.id}`, values, {
        withCredentials: true,
      });
  
      return res.data;
    } catch (error) {
      return Promise.reject(error);
    }
}
