export const statusINS = (identity, INSI) => {
    if (identity === "IDDoting" || identity === "Idfictional")
      return "IdProvisional";
    else if (identity === "idvalid" && !INSI) return "IdValidated";
    else if (identity === "idvalid" && INSI) return "IdQualified";
    else if (identity !== "idvalid" && INSI) return "IdRecovered";
    else if (identity !== "idvalid" && !INSI) return "IdProvisional";
    else return "IdProvisional";
  };

export const INSType = {
    "1.2.250.1.213.1.4.2": "INS_C",
    "1.2.250.1.213.1.4.7": "INS_C_DEMO",
    "1.2.250.1.213.1.4.6": "INS_C_TEST",
    "1.2.250.1.213.1.4.9": "INS_NIA",
    "1.2.250.1.213.1.4.8": "INS_NIR",
    "1.2.250.1.213.1.4.11": "INS_NIR_DEMO",
    "1.2.250.1.213.1.4.10": "INS_NIR_TEST",
  };

export const dropdownSexeOptions = [
    { value: "M", label: "M" },
    { value: "F", label: "F" },
  ];