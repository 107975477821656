import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "../../helper/moment";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
// import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface Props {
  patientData: any;
}

const Chart = (props: Props) => {
  const { t } = useTranslation();
  const { patientData } = props;
  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: t("MyPatient.PoidsANDHauteur"),
      },
    },
  };
  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: t("MyPatient.FCANDTA"),
      },
    },
  };
  const options3 = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: t("MyPatient.Saturation"),
      },
    },
  };
  const options4 = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: t("MyPatient.Temperature"),
      },
    },
  };

  const data1 = {
    labels: patientData?.statistic?.weight?.map((el) =>
      moment(el.createdAt).format("L")
    ),
    datasets: [
      {
        label: "Poid",
        data: patientData?.statistic.weight.map((el) => el.value),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Hauteur",
        data: patientData?.statistic.size.map((el) => el.value),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const data2 = {
    labels: patientData?.statistic?.pouls?.map((el) =>
      moment(el.createdAt).format("L")
    ),
    datasets: [
      {
        label: "FC",
        data: patientData?.statistic.pouls.map((el) => el.value),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "TA SYSTOLE",
        data: patientData?.statistic.pouls.map((el) => el.temp),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "TA DIASTOLE",
        data: patientData?.statistic.pouls.map((el) => el.perMinute),
        borderColor: "rgb(52, 199, 164)",
        backgroundColor: "rgba(52, 199, 164, 0.5)",
      },
    ],
  };

  const data3 = {
    labels: patientData?.statistic?.saturation?.map((el) =>
      moment(el.createdAt).format("L")
    ),
    datasets: [
      {
        label: "Saturation",
        data: patientData?.statistic.saturation.map((el) => el.value),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const data4 = {
    labels: patientData?.statistic?.temperature?.map((el) =>
      moment(el.createdAt).format("L")
    ),
    datasets: [
      {
        label: "Temperature",
        data: patientData?.statistic.temperature.map((el) => el.degree),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  return (
    <div className="space-y-5">
      <Line options={options1} data={data1} />
      <Line options={options2} data={data2} />
      <Line options={options3} data={data3} />
      <Line options={options4} data={data4} />
    </div>
  );
};

export default Chart;
