export const conditionsListExport = (t) => {
  return [
    {
      label: t("SurveyIntl.equal"),
      value: "equal",
    },
    {
      label: t("SurveyIntl.notEqual"),
      value: "not_equal",
    },
    {
      label: t("SurveyIntl.equal_or_superior"),
      value: "equal_or_superior",
    },
    {
      label: t("SurveyIntl.equal_or_inferior"),
      value: "equal_or_inferior",
    },
    {
      label: t("SurveyIntl.pattern"),
      value: "pattern",
    },
  ];
};
