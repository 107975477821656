import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";
import { ReactComponent as IconNotifConnexion } from "../../../assets/icons/icon-notif-connexion.svg";
import Button from "../../../components/Button";
import moment from "../../../helper/moment";
import Camera from "../../../assets/images/camera.png";
import Handshake from "../../../assets/images/poigne-de-main.png";

import "./styles.scss";

type CardStatus = "current" | "default";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & { status?: CardStatus; meetDeatils: any; key: any };

const CardPatientsOfTheDay: React.FC<Props> = ({
  status,
  meetDeatils: { patientId, motif, dateConsultation, statusOnline, _id, type },
  className,
  ...props
}) => {
  const { t } = useTranslation() as any;
  const history = useHistory();
  const handleClick = () => {
    if (status === "current") {
      if (type === "teleconsultation") history.push(`/teleconsultation/${_id}`);
      else history.push(`/consultation/${_id}`);
    }
  };
  return (
    <div
      className={classNames(
        className,
        "flex flex-col justify-between relative",
        status === "current"
          ? "card-patients-of-day-current"
          : "card-patients-of-day-default shadow-md bg-white rounded-lg"
      )}
      {...props}
    >
      <div>
        <div className="flex items-center justify-between">
          <div className="flexCenter">
            <h1 className="card-title ml-3">{`${patientId?.firstName} ${patientId?.lastName}`}</h1>
            {statusOnline && <IconNotifConnexion />}
          </div>
          {type === "teleconsultation" ? (
            <img
              crossOrigin="anonymous"
              referrerPolicy="origin"
              src={Camera}
              alt="teleconsultation"
              width={45}
              style={{
                filter:
                  status === "current"
                    ? " invert(100%) sepia(0%) saturate(7484%) hue-rotate(53deg) brightness(94%) contrast(110%)"
                    : "invert(32%) sepia(89%) saturate(2276%) hue-rotate(221deg) brightness(98%) contrast(97%)",
              }}
            />
          ) : (
            <img
              crossOrigin="anonymous"
              referrerPolicy="origin"
              src={Handshake}
              alt="presentiel"
              width={60}
              style={{
                filter:
                  status === "current"
                    ? " invert(100%) sepia(0%) saturate(7484%) hue-rotate(53deg) brightness(94%) contrast(110%)"
                    : "invert(32%) sepia(89%) saturate(2276%) hue-rotate(221deg) brightness(98%) contrast(97%)",
              }}
            />
          )}
        </div>
        <div
          className={classNames(
            "box max-w-max ml-3 ",
            status === "current" && "color-white"
          )}
        >
          {motif}
        </div>
      </div>

      <div>
        <p
          className={`mb-3 ml-3 ${
            status === "current" ? "text-white" : "text-black"
          }`}
        >
          {t("DashboardDoctor.Today")} <span className="opacity-50 ">| </span>
          {moment(dateConsultation).format("HH:mm")}
        </p>
        {status === "current" && (
          //  moment(dateConsultation).isAfter(moment().subtract(15, "minutes")) &&
          //  moment(dateConsultation).isBefore(moment().add(15, "minutes")) &&
          <Button
            status="basic"
            className={`rounded-lg w-full bg-white bg-opacity-20 hover:border-blue-500 hover-text-blue ${
              status === "current"
                ? "text-white"
                : "text-blue-500 border-blue-500"
            }`}
            onClick={handleClick}
          >
            <span className="">{t("DashboardDoctor.Start")}</span>
          </Button>
        )}
      </div>
    </div>
  );
};

CardPatientsOfTheDay.defaultProps = {
  status: "default",
};
export default CardPatientsOfTheDay;
