export const MyFolder = {
  fr: {
    Documents: "Documents",
    Antecedents: "Antécédents",
    Treatments: "Traitements",
    Historical: "Historique",
    MyFolder: "Mon dossier",

    GeneralData: "Données Générales",
    LastUpdatedOn: "Dernière mise à jour le :",
    size: "taille :",
    cm: "cm",
    Weight: "Poids :",
    kg: "kg",
    BMI: "IMC :",
    IM: "Je suis",

    AddDocument: "+ Ajouter un document",
    Biology: "Biologie",
    Radiology: "Radiologie",
    Other: "Autres",
    NoDocument: "Vous n'avez aucun document",
    ReportAt: "Consultation du : ",
    UpdatedAt: "Mise à jour le",
    by: "par",
    le: "le",
    myself: "moi-meme",
    Dr: ": Dr ",
    AllMyDoctors: "Tous mes medecins",
    NoAntecedent: "Vous n'avez aucun antécédent",
    ByThe: "par le",
    observation: "observation",

    NoHistory: "Vous n'avez aucun historique",
    Historyappointments: "Historique de mes rendez-vous passés",
    motif: "motif :",
    documents: "documents",
    SuccessAddPicture: "Image ajoutée avec succès.",
    AddDoc: "Ajoutez un document",
    DragAndDrop: "Glissez et déposez les images ou cliquez ici.",
    Save: "Enregistrer",
    Prescription: "Prescription",
    Report: "Compte rendu",
    NoDoc: "Vous n'avez aucun document pour cette consultation",
    slectDoctor: "Sélectionnez votre medecin",
    selectChoice: "Sélectionnez votre choix",

    AllAntecedent: "Tous les antécédents médicaux et chirurgicaux",
    MedicalBackground: "Antécédents médicaux",
    SurgicalHistory: "Antécédents chirurgicaux",

    AntecedentDate: "Date antécédent",
    SelectDate: "Sélectionnez une date",
    Today: "Aujourd'hui",
    last7Day: "Du 7 jours dernier",
    last30Day: "Du 30 jours dernier",
    Filter: "Filtrer",

    NoObservation: "Pas d'observation.",
    Observations: "Observations",
  },
  en: {
    Documents: "Documents",
    Antecedents: "Antecedents",
    Treatments: "Treatments",
    Historical: "history",
    MyFolder: "My files",

    GeneralData: "General data",
    LastUpdatedOn: "Last updated on:",
    size: "size :",
    cm: "cm",
    Weight: "Weight:",
    kg: "kg",
    BMI: "BMI:",
    IM: "Im",
    AddDocument: "+ Add a document",
    Biology: "Biology",
    Radiology: "Radiology",
    Other: "Other",
    NoDocument: "You have no document",
    ReportAt: "Report at : ",
    UpdatedAt: "Updated at",
    by: "by",
    le: "",
    myself: "myself",
    Dr: ": Dr ",
    AllMyDoctors: "Tous mes medecins",
    NoAntecedent: "You have no antecedent",
    ByThe: "by the",
    observation: "observation",

    NoHistory: "You have no history",
    Historyappointments: "History of my past appointments",
    motif: "motif :",
    documents: "documents",
    SuccessAddPicture: "Image added successfully.",
    AddDoc: "Add a document",

    DragAndDrop: "Drag and drop images or click here.",
    Save: "Save",
    Prescription: "Prescription",
    Report: "Report",
    NoDoc: "You have no documents for this consultation",

    slectDoctor: "Select your doctor",
    selectChoice: "Select your choice",
    AllAntecedent: "All medical and surgical history",
    MedicalBackground: "Medical background",
    SurgicalHistory: "Surgical history",

    AntecedentDate: "Antecedent date",
    SelectDate: "Select a date",

    Today: "Today",
    last7Day: "From the last 7 days",
    last30Day: "From the last 30 days",
    Filter: "Filtrer",
    NoObservation: "No observations.",
    Observations: "Observations",
  },
};
